import React, { Component } from "react";
import firebase from "firebase/app";
import HeaderPaper from "./common/headerPaper";
import Timer from "./common/timer";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";

class ScheduledQuiz extends Component {
  state = {
    quizPack: {},
    timeRemaining: 0,
    showStartQuiz: false,
  };

  componentDidMount() {
    const quizId = this.props.match.params.quizid;
    // console.log("quiz id", quizId);

    sessionStorage.removeItem("timeUp");

    firebase
      .firestore()
      .collection("scheduled")
      .doc(quizId)
      .onSnapshot((doc) => {
        // console.log(doc.data())
        const quizPack = doc.data();
        const startTime = quizPack.startDate;
        const currentTime = Date.now();
        let timeRemaining = (startTime - currentTime) / 60000;
        if (timeRemaining < 0) {
          const elapsedTime = ((startTime - currentTime) / 1000) * -1;
          const remainingTimeToExpiry = 86400000 - elapsedTime;
          const remainingHours = Math.floor(
            (remainingTimeToExpiry / 3600) % 24
          );
          const remainingMinutes = Math.floor(
            (remainingTimeToExpiry / 60) % 60
          );
          // console.log(remainingHours);
          // console.log(remainingMinutes);
          this.setState({
            timeToExpiry: {
              remainingHours: remainingHours,
              remainingMinutes: remainingMinutes,
            },
          });
        }
        // const timeRemaining = 0.3;
        // console.log(timeRemaining);

        this.setState({ quizPack, timeRemaining });
      });
  }

  setTimeToExpiry() {
    const { quizPack } = this.state;
    const startTime = quizPack.startDate;
    const currentTime = Date.now();

    const elapsedTime = ((startTime - currentTime) / 1000) * -1;
    const remainingTimeToExpiry = 86400000 - elapsedTime;
    const remainingHours = Math.floor((remainingTimeToExpiry / 3600) % 24);
    const remainingMinutes = Math.floor((remainingTimeToExpiry / 60) % 60);
    // console.log(remainingHours);
    // console.log(remainingMinutes);
    this.setState({
      timeToExpiry: {
        remainingHours: remainingHours,
        remainingMinutes: remainingMinutes,
      },
    });
  }

  showStartQuiz = () => {
    this.setTimeToExpiry();
    this.setState({ showStartQuiz: true });
    clearInterval(this.watchTimer);
  };

  checkTimerEnd = () => {
    if (sessionStorage.getItem("timeUp")) {
      // console.log("timeUp");
      // timeUp();
      clearInterval(this.watchTimer);
      sessionStorage.removeItem("timeUp");
      this.showStartQuiz();
    }

    const { showStartQuiz } = this.state;
    if (showStartQuiz) {
      this.setState({ timeRemaining: 86400000 });
    }
  };

  watchTimer = setInterval(this.checkTimerEnd, 1000);

  goToQuiz = () => {
    const { quizPack } = this.state;
    localStorage.setItem("currentScheduled", JSON.stringify(quizPack));
    window.location = "/quiz-preview/scheduled";
  };

  render() {
    const { quizPack, timeRemaining, showStartQuiz, timeToExpiry } = this.state;

    return (
      <React.Fragment>
        <HeaderPaper headerTitle={quizPack.title} />

        <Grid
          item
          container
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: "20px 0 30px 0",
          }}
        >
          {quizPack.questions && !showStartQuiz && (
            <Timer type="scheduled" timeLength={timeRemaining} />
          )}
        </Grid>

        {showStartQuiz && (
          <Grid>
            <Typography
              style={{
                padding: "auto",
                margin: "auto",
                textAlign: "center",
              }}
              variant="body1"
            >
              <em>
                <span style={{ fontWeight: "bold" }}>
                  {timeToExpiry.remainingHours} hrs,{" "}
                  {timeToExpiry.remainingMinutes} mins
                </span>{" "}
                until this schedule is no longer available
              </em>
            </Typography>

            <div
              style={{
                textAlign: "center",
                margin: "20px auto",
                padding: "auto",
              }}
            >
              <Typography component="p" variant="h6" color="primary">
                It's Time for Your Session. Hurry Up!
              </Typography>
            </div>
            <br />

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.goToQuiz}
              >
                Let's Go!
              </Button>
            </div>
          </Grid>
        )}

        {!showStartQuiz && (
          <Grid>
            <div style={{ display: "flex", margin: "50px" }}>
              <Typography component="p" variant="h6">
                Your Session was scheduled for{" "}
                <em style={{ color: "#3f51b5" }}>{quizPack.dateString}</em>.
              </Typography>
            </div>
            <div style={{ display: "flex", margin: "50px" }}>
              <Typography component="p" variant="h6">
                Scheduled by:{" "}
                <em style={{ color: "#3f51b5" }}>{quizPack.scheduledBy}</em>
              </Typography>
            </div>
            <br />

            {/* <div
                        style={{ display: "flex", justifyContent: "center"}}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.goToQuiz}
                            >
                                Let's Go!
                            </Button>
                        </div> */}
          </Grid>
        )}
      </React.Fragment>
    );
  }
}

export default ScheduledQuiz;
