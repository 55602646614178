import React from "react";
import firebase from "firebase/app";
import { toast } from "react-toastify";
import PreLoader from "./common/preLoader";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Joi from "joi-browser";
import Form from "./common/form";
import { getOsceSubjects } from "../utils/getCategories";
import { getOsceTypes } from "../utils/getCategories";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import CreateIcon from "@material-ui/icons/Create";

class EditOsce extends Form {
  state = {
    osce: {},
    segments: [],
    showEditDetail: false,
    showEditOsce: false,
    ShowAddNewSegment: false,
    docId: "",
    data: {
      title: "",
      subject: "",
      type: "",
    },
    errors: {},
    segmentData: {
      segmentTitle: "",
      item1: "",
      technique1: "",
      significance1: "",
      explanation1: "",
      item2: "",
      technique2: "",
      significance2: "",
      explanation2: "",
      item3: "",
      technique3: "",
      significance3: "",
      explanation3: "",
      item4: "",
      technique4: "",
      significance4: "",
      explanation4: "",
      item5: "",
      technique5: "",
      significance5: "",
      explanation5: "",
      item6: "",
      technique6: "",
      significance6: "",
      explanation6: "",
      item7: "",
      technique7: "",
      significance7: "",
      explanation7: "",
      item8: "",
      technique8: "",
      significance8: "",
      explanation8: "",
      item9: "",
      technique9: "",
      significance9: "",
      explanation9: "",
      item10: "",
      technique10: "",
      significance10: "",
      explanation10: "",
    },
    segmentErrors: {},
    currentSegment: "",
    submitting: false,
  };

  schema = {
    title: Joi.string().min(3).max(200).required().label("Title"),
    subject: Joi.string().required().label("Subject"),
    type: Joi.string().required().label("Type"),
  };

  segmentDetails = [
    { id: "segmentTitle", name: "Segment Title" },
    { id: "item1", name: "Item 1 (required)" },
    { id: "technique1", name: "Item 1 Technique" },
    { id: "significance1", name: "Item 1 Significance" },
    { id: "item2", name: "Item 2" },
    { id: "technique2", name: "Item 2 Technique" },
    { id: "significance2", name: "Item 2 Significance" },
    { id: "item3", name: "Item 3" },
    { id: "technique3", name: "Item 3 Technique" },
    { id: "significance3", name: "Item 3 Significance" },
    { id: "item4", name: "Item 4" },
    { id: "technique4", name: "Item 4 Technique" },
    { id: "significance4", name: "Item 4 Significance" },
    { id: "item5", name: "Item 5" },
    { id: "technique5", name: "Item 5 Technique" },
    { id: "significance5", name: "Item 5 Significance" },
    { id: "item6", name: "Item 6" },
    { id: "technique6", name: "Item 6 Technique" },
    { id: "significance6", name: "Item 6 Significance" },
    { id: "item7", name: "Item 7" },
    { id: "technique7", name: "Item 7 Technique" },
    { id: "significance7", name: "Item 7 Significance" },
    { id: "item8", name: "Item 8" },
    { id: "technique8", name: "Item 8 Technique" },
    { id: "significance8", name: "Item 8 Significance" },
    { id: "item9", name: "Item 9" },
    { id: "technique9", name: "Item 9 Technique" },
    { id: "significance9", name: "Item 9 Significance" },
    { id: "item10", name: "Item 10" },
    { id: "technique10", name: "Item 10 Technique" },
    { id: "significance10", name: "Item 10 Significance" },
  ];

  segmentDetailsWithExplanation = [
    { id: "segmentTitle", name: "Segment Title" },
    { id: "item1", name: "Item 1 (required)" },
    { id: "explanation1", name: "Item 1 Explanation" },
    { id: "item2", name: "Item 2" },
    { id: "explanation2", name: "Item 2 Explanation" },
    { id: "item3", name: "Item 3" },
    { id: "explanation3", name: "Item 3 Explanation" },
    { id: "item4", name: "Item 4" },
    { id: "explanation4", name: "Item 4 Explanation" },
    { id: "item5", name: "Item 5" },
    { id: "explanation5", name: "Item 5 Explanation" },
    { id: "item6", name: "Item 6" },
    { id: "explanation6", name: "Item 6 Explanation" },
    { id: "item7", name: "Item 7" },
    { id: "explanation7", name: "Item 7 Explanation" },
    { id: "item8", name: "Item 8" },
    { id: "explanation8", name: "Item 8 Explanation" },
    { id: "item9", name: "Item 9" },
    { id: "explanation9", name: "Item 9 Explanation" },
    { id: "item10", name: "Item 10" },
    { id: "explanation10", name: "Item 10 Explanation" },
  ];

  componentDidMount() {
    const osceId = this.props.match.params.osceId;

    firebase
      .firestore()
      .collection("osces")
      .doc(osceId)
      .onSnapshot((doc) => {
        const osce = doc.data();
        console.log(osce);
        const docId = doc.id;
        const data = {
          title: osce.title,
          subject: osce.subject,
          type: osce.type,
        };
        const segments = osce.segments;
        this.setState({ osce, segments, data, docId });
      });
  }

  doSubmit = () => {
    const { docId, data } = this.state;
    // Call the server
    const title = data.title.trim();
    const subject = data.subject.trim();
    const type = data.type.trim();

    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    firebase
      .firestore()
      .collection("osces")
      .doc(docId)
      .update({
        title: title,
        subject: subject,
        type: type,
      })
      .then(() => {
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        // window.location.reload();
        toast.success("Successfully Updated OSCE!");
      })
      .catch((error) => {
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        console.error("Error adding document: ", error);
        const errorMessage = error.message;
        toast.error(errorMessage);
        return;
      });
  };

  doEditSegmentSubmit = () => {
    const { segmentData, currentSegment, osce, docId } = this.state;
    const lastModified = Date.now();

    let osceList = [...osce.segments];
    const segmentIndex = currentSegment - 1;

    const {
      segmentTitle,
      item1,
      technique1,
      significance1,
      explanation1,
      item2,
      technique2,
      significance2,
      explanation2,
      item3,
      technique3,
      significance3,
      explanation3,
      item4,
      technique4,
      significance4,
      explanation4,
      item5,
      technique5,
      significance5,
      explanation5,
      item6,
      technique6,
      significance6,
      explanation6,
      item7,
      technique7,
      significance7,
      explanation7,
      item8,
      technique8,
      significance8,
      explanation8,
      item9,
      technique9,
      significance9,
      explanation9,
      item10,
      technique10,
      significance10,
      explanation10,
    } = segmentData;

    if (segmentTitle.length < 3 || item1.length < 3) {
      toast.error("Segement Title & Item 1 must not be empty!");
      return;
    }

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    let newSegment = {
      segmentTitle: segmentTitle,
      segmentNo: currentSegment,
      lastModified: lastModified,
      items: [
        {
          content: item1,
          technique: technique1,
          significance: significance1,
          explanation: explanation1,
          id: "1",
        },
        {
          content: item2,
          technique: technique2,
          significance: significance2,
          explanation: explanation2,
          id: "2",
        },
        {
          content: item3,
          technique: technique3,
          significance: significance3,
          explanation: explanation3,
          id: "3",
        },
        {
          content: item4,
          technique: technique4,
          significance: significance4,
          explanation: explanation4,
          id: "4",
        },
        {
          content: item5,
          technique: technique5,
          significance: significance5,
          explanation: explanation5,
          id: "5",
        },
        {
          content: item6,
          technique: technique6,
          significance: significance6,
          explanation: explanation6,
          id: "6",
        },
        {
          content: item7,
          technique: technique7,
          significance: significance7,
          explanation: explanation7,
          id: "7",
        },
        {
          content: item8,
          technique: technique8,
          significance: significance8,
          explanation: explanation8,
          id: "8",
        },
        {
          content: item9,
          technique: technique9,
          significance: significance9,
          explanation: explanation9,
          id: "9",
        },
        {
          content: item10,
          technique: technique10,
          significance: significance10,
          explanation: explanation10,
          id: "10",
        },
      ],
    };

    osceList[segmentIndex] = newSegment;
    // console.log("segments list", osceList);

    firebase
      .firestore()
      .collection("osces")
      .doc(docId)
      .update({
        segments: osceList,
        lastModified: lastModified,
      })
      .then((doc) => {
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting, showEditLine: false });
        // window.location.reload();
        toast.success("Successfully Added / Updated Segment.");
      })
      .catch((error) => {
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        const errorMessage = error.message;
        toast.error(errorMessage);
      });
  };

  editDetail = () => {
    const showEditDetail = !this.state.showEditDetail;
    this.setState({ showEditDetail });
  };

  editSegment = (segment, segmentType) => {
    const showEditOsce = this.state.showEditOsce;
    const currentSegment = segment.segmentNo;

    if (segmentType === "newSegment") {
      this.setState({ ShowAddNewSegment: !this.state.ShowAddNewSegment });
    }

    this.setState({
      showEditOsce: !showEditOsce,
      showEditLine: false,
      currentSegment,
      segmentData: {
        segmentTitle: segment.segmentTitle,
        item1: segment.items[0].content,
        technique1: segment.items[0].technique,
        significance1: segment.items[0].significance,
        explanation1: segment.items[0].explanation,
        item2: segment.items[1].content,
        technique2: segment.items[1].technique,
        significance2: segment.items[1].significance,
        explanation2: segment.items[1].explanation,
        item3: segment.items[2].content,
        technique3: segment.items[2].technique,
        significance3: segment.items[2].significance,
        explanation3: segment.items[2].explanation,
        item4: segment.items[3].content,
        technique4: segment.items[3].technique,
        significance4: segment.items[3].significance,
        explanation4: segment.items[3].explanation,
        item5: segment.items[4].content,
        technique5: segment.items[4].technique,
        significance5: segment.items[4].significance,
        explanation5: segment.items[4].explanation,
        item6: segment.items[5].content,
        technique6: segment.items[5].technique,
        significance6: segment.items[5].significance,
        explanation6: segment.items[5].explanation,
        item7: segment.items[6].content,
        technique7: segment.items[6].technique,
        significance7: segment.items[6].significance,
        explanation7: segment.items[6].explanation,
        item8: segment.items[7].content,
        technique8: segment.items[7].technique,
        significance8: segment.items[7].significance,
        explanation8: segment.items[7].explanation,
        item9: segment.items[8].content,
        technique9: segment.items[8].technique,
        significance9: segment.items[8].significance,
        explanation9: segment.items[8].explanation,
        item10: segment.items[9].content,
        technique10: segment.items[9].technique,
        significance10: segment.items[9].significance,
        explanation10: segment.items[9].explanation,
        item11: segment.items[10] ? segment.items[10].content : "",
        technique11: segment.items[10] ? segment.items[10].technique : "",
        significance11: segment.items[10] ? segment.items[10].significance : "",
        explanation11: segment.items[10] ? segment.items[10].explanation : "",
      },
    });
  };

  subjects = getOsceSubjects();
  types = getOsceTypes();

  makeEditLineShow = (segment, lineType, itemId) => {
    // console.log(segment, lineType);
    const { showEditLine } = this.state;

    this.setState({
      showEditLine: !showEditLine,
      lineToEditType: lineType,
      currentSegment: segment.segmentNo,
      currentItem: itemId,
      showEditOsce: false,
      segmentData: {
        segmentTitle: segment.segmentTitle,
        item1: segment.items[0].content,
        technique1: segment.items[0].technique,
        significance1: segment.items[0].significance,
        explanation1: segment.items[0].explanation,
        item2: segment.items[1].content,
        technique2: segment.items[1].technique,
        significance2: segment.items[1].significance,
        explanation2: segment.items[1].explanation,
        item3: segment.items[2].content,
        technique3: segment.items[2].technique,
        significance3: segment.items[2].significance,
        explanation3: segment.items[2].explanation,
        item4: segment.items[3].content,
        technique4: segment.items[3].technique,
        significance4: segment.items[3].significance,
        explanation4: segment.items[3].explanation,
        item5: segment.items[4].content,
        technique5: segment.items[4].technique,
        significance5: segment.items[4].significance,
        explanation5: segment.items[4].explanation,
        item6: segment.items[5].content,
        technique6: segment.items[5].technique,
        significance6: segment.items[5].significance,
        explanation6: segment.items[5].explanation,
        item7: segment.items[6].content,
        technique7: segment.items[6].technique,
        significance7: segment.items[6].significance,
        explanation7: segment.items[6].explanation,
        item8: segment.items[7].content,
        technique8: segment.items[7].technique,
        significance8: segment.items[7].significance,
        explanation8: segment.items[7].explanation,
        item9: segment.items[8].content,
        technique9: segment.items[8].technique,
        significance9: segment.items[8].significance,
        explanation9: segment.items[8].explanation,
        item10: segment.items[9].content,
        technique10: segment.items[9].technique,
        significance10: segment.items[9].significance,
        explanation10: segment.items[9].explanation,
        item11: segment.items[10] ? segment.items[10].content : "",
        technique11: segment.items[10] ? segment.items[10].technique : "",
        significance11: segment.items[10] ? segment.items[10].significance : "",
        explanation11: segment.items[10] ? segment.items[10].explanation : "",
      },
    });
  };

  // doSubmitLine = (segment, lineType) => {
  //   console.log(segment, lineType);
  // };

  render() {
    const {
      osce,
      segments,
      showEditDetail,
      showEditOsce,
      ShowAddNewSegment,
      currentSegment,
      submitting,
      showEditLine,
      lineToEditType,
      currentItem,
    } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>Edit OSCE - Acabest</title>
        </Helmet>
        {osce === {} || submitting ? (
          <PreLoader />
        ) : (
          <div>
            <Grid>
              <Paper style={{ backgroundColor: "#9ea8dd" }}>
                <Typography
                  color="primary"
                  style={{
                    textTransform: "capitalize",
                    textAlign: "center",
                  }}
                  variant="h6"
                  component="h6"
                >
                  Editting - {osce.title}
                </Typography>
              </Paper>
            </Grid>

            <Button
              onClick={() =>
                this.editSegment(
                  {
                    segmentNo: osce.segments.length + 1,
                    segmentTitle: "",
                    items: [
                      {
                        content: "",
                        technique: "",
                        significance: "",
                        explanation: "",
                        id: "1",
                      },
                      {
                        content: "",
                        technique: "",
                        significance: "",
                        explanation: "",
                        id: "2",
                      },
                      {
                        content: "",
                        technique: "",
                        significance: "",
                        explanation: "",
                        id: "3",
                      },
                      {
                        content: "",
                        technique: "",
                        significance: "",
                        explanation: "",
                        id: "4",
                      },
                      {
                        content: "",
                        technique: "",
                        significance: "",
                        explanation: "",
                        id: "5",
                      },
                      {
                        content: "",
                        technique: "",
                        significance: "",
                        explanation: "",
                        id: "6",
                      },
                      {
                        content: "",
                        technique: "",
                        significance: "",
                        explanation: "",
                        id: "7",
                      },
                      {
                        content: "",
                        technique: "",
                        significance: "",
                        explanation: "",
                        id: "8",
                      },
                      {
                        content: "",
                        technique: "",
                        significance: "",
                        explanation: "",
                        id: "9",
                      },
                      {
                        content: "",
                        technique: "",
                        significance: "",
                        explanation: "",
                        id: "10",
                      },
                    ],
                  },
                  "newSegment"
                )
              }
              fullWidth
              variant="contained"
              color="secondary"
            >
              Add New Segment
            </Button>
            {ShowAddNewSegment && (
              <Container
                component="main"
                maxWidth="md"
                style={{
                  margin: "30px auto",
                  width: "95%",
                  backgroundColor: "#fff",
                  boxShadow:
                    "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                  padding: "4rem 2rem",
                  borderRadius: "10px",
                }}
              >
                <CssBaseline />
                <div>
                  {osce.type !== "summary" && ShowAddNewSegment && (
                    <form onSubmit={this.handleAddSegmetsSubmit}>
                      <br />
                      {this.segmentDetails.map((segmentDetail) => (
                        <div
                          key={segmentDetail.id}
                          style={{
                            border: "1px solid #969aa2",
                            marginBottom: "20px",
                            borderRadius: "5px 5px 0px 0px",
                          }}
                        >
                          <p style={{ padding: "0 10px", margin: "0" }}>
                            {segmentDetail.name}
                          </p>
                          <Divider />
                          <CKEditor
                            editor={InlineEditor}
                            data={""}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              const segmentData = { ...this.state.segmentData };
                              segmentData[segmentDetail.id] = data;
                              this.setState({ segmentData });
                            }}
                          />
                        </div>
                      ))}

                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={this.doEditSegmentSubmit}
                      >
                        Submit
                      </Button>
                    </form>
                  )}

                  {osce.type === "summary" && ShowAddNewSegment && (
                    <form onSubmit={this.handleAddSegmetsSubmit}>
                      <br />
                      {this.segmentDetailsWithExplanation.map(
                        (segmentDetail) => (
                          <div
                            key={segmentDetail.id}
                            style={{
                              border: "1px solid #969aa2",
                              marginBottom: "20px",
                              borderRadius: "5px 5px 0px 0px",
                            }}
                          >
                            <p style={{ padding: "0 10px", margin: "0" }}>
                              {segmentDetail.name}
                            </p>
                            <Divider />
                            <CKEditor
                              editor={InlineEditor}
                              data={""}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                const segmentData = {
                                  ...this.state.segmentData,
                                };
                                segmentData[segmentDetail.id] = data;
                                this.setState({ segmentData });
                              }}
                            />
                          </div>
                        )
                      )}

                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={this.doEditSegmentSubmit}
                      >
                        Submit
                      </Button>
                    </form>
                  )}
                </div>
              </Container>
            )}
            <br />

            <h3 style={{ margin: "10px" }}>
              <strong>OSCE Details</strong>
            </h3>
            <Button
              onClick={this.editDetail}
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
            >
              Edit a Detail
            </Button>
            {showEditDetail && (
              <Grid
                item
                md={9}
                lg={6}
                style={{ margin: "auto", padding: "10px" }}
              >
                <form onSubmit={this.handleSubmit}>
                  {this.renderTextField("title", "Title")}

                  {this.renderSelect(
                    "subject",
                    "Select Subject",
                    this.subjects
                  )}
                  {this.renderSelect("type", "Select Type", this.types)}
                  {this.renderSubmitButton("Submit")}
                </form>
              </Grid>
            )}

            <h5 style={{ margin: "10px" }}>
              <strong>Title -</strong> {osce.title}
            </h5>
            <h5 style={{ margin: "10px" }}>
              <strong>Subject -</strong> {osce.subject}
            </h5>
            <h5 style={{ margin: "10px" }}>
              <strong>Type -</strong> {osce.type}
            </h5>
            <br />
            <br />
            <br />

            <h3 style={{ margin: "10px" }}>
              <strong>Segments</strong>
            </h3>

            <Grid item lg={8} style={{ margin: "15px" }}>
              {segments.map((segment) => (
                <div key={segment.segmentNo}>
                  <div id={`${segment.segmentNo}segment`} />
                  <h5 style={{ display: "flex" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginRight: "5px",
                      }}
                    >
                      {segment.segmentNo}.
                    </span>
                    {ReactHtmlParser(segment.segmentTitle)}
                    {
                      <CreateIcon
                        // component={Button}

                        onClick={() =>
                          this.makeEditLineShow(
                            segment,
                            "title",
                            segment.segmentNo
                          )
                        }
                        style={{ marginLeft: "10px", color: "blue" }}
                      />
                    }
                  </h5>

                  {showEditLine &&
                    lineToEditType === "title" &&
                    currentSegment === segment.segmentNo && (
                      <div>
                        <div
                          style={{
                            border: "1px solid #969aa2",
                            marginBottom: "20px",
                            borderRadius: "5px 5px 0px 0px",
                          }}
                        >
                          <p style={{ padding: "0 10px", margin: "0" }}>
                            Segment Title
                          </p>
                          <Divider />
                          <CKEditor
                            editor={InlineEditor}
                            data={segment.segmentTitle}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              const segmentData = { ...this.state.segmentData };
                              segmentData.segmentTitle = data;
                              this.setState({ segmentData });
                            }}
                          />
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ padding: "5px  50px" }}
                            onClick={this.doEditSegmentSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    )}

                  <ul>
                    {segment.items.map(
                      (item) =>
                        item.content && (
                          <div key={item.id}>
                            <ul>
                              <li>
                                <div
                                  style={{
                                    fontSize: "19px",
                                    fontWeight: "bolder",
                                    display: "flex",
                                  }}
                                >
                                  {ReactHtmlParser(item.content)}
                                  <CreateIcon
                                    onClick={() =>
                                      this.makeEditLineShow(
                                        segment,
                                        "itemContent",
                                        item.id
                                      )
                                    }
                                    style={{
                                      marginLeft: "10px",
                                      color: "blue",
                                    }}
                                  />
                                </div>
                                {showEditLine &&
                                  lineToEditType === "itemContent" &&
                                  currentSegment === segment.segmentNo &&
                                  currentItem === item.id && (
                                    <div>
                                      <div
                                        style={{
                                          border: "1px solid #969aa2",
                                          marginBottom: "20px",
                                          borderRadius: "5px 5px 0px 0px",
                                        }}
                                      >
                                        <p
                                          style={{
                                            padding: "0 10px",
                                            margin: "0",
                                          }}
                                        >
                                          {`item ${item.id}`}
                                        </p>
                                        <Divider />
                                        <CKEditor
                                          editor={InlineEditor}
                                          data={item.content}
                                          onChange={(event, editor) => {
                                            const data = editor.getData();
                                            const segmentData = {
                                              ...this.state.segmentData,
                                            };
                                            segmentData[`item${item.id}`] =
                                              data;
                                            this.setState({ segmentData });
                                          }}
                                        />
                                      </div>
                                      <div style={{ textAlign: "center" }}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          style={{ padding: "5px  50px" }}
                                          onClick={this.doEditSegmentSubmit}
                                        >
                                          Submit
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                                {item.technique && (
                                  <div>
                                    <strong>Technique</strong>
                                    <CreateIcon
                                      onClick={() =>
                                        this.makeEditLineShow(
                                          segment,
                                          "itemTechnique",
                                          item.id
                                        )
                                      }
                                      style={{
                                        marginLeft: "10px",
                                        color: "blue",
                                      }}
                                    />
                                    {ReactHtmlParser(item.technique)}
                                    {showEditLine &&
                                      lineToEditType === "itemTechnique" &&
                                      currentSegment === segment.segmentNo &&
                                      currentItem === item.id && (
                                        <div>
                                          <div
                                            style={{
                                              border: "1px solid #969aa2",
                                              marginBottom: "20px",
                                              borderRadius: "5px 5px 0px 0px",
                                            }}
                                          >
                                            <p
                                              style={{
                                                padding: "0 10px",
                                                margin: "0",
                                              }}
                                            >
                                              {`item ${item.id} Technique`}
                                            </p>
                                            <Divider />
                                            <CKEditor
                                              editor={InlineEditor}
                                              data={item.technique}
                                              onChange={(event, editor) => {
                                                const data = editor.getData();
                                                const segmentData = {
                                                  ...this.state.segmentData,
                                                };
                                                segmentData[
                                                  `technique${item.id}`
                                                ] = data;
                                                this.setState({
                                                  segmentData,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div style={{ textAlign: "center" }}>
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              style={{ padding: "5px  50px" }}
                                              onClick={this.doEditSegmentSubmit}
                                            >
                                              Submit
                                            </Button>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                )}
                                {item.significance && (
                                  <div>
                                    <strong>Significance</strong>
                                    <CreateIcon
                                      onClick={() =>
                                        this.makeEditLineShow(
                                          segment,
                                          "itemSignificance",
                                          item.id
                                        )
                                      }
                                      style={{
                                        marginLeft: "10px",
                                        color: "blue",
                                      }}
                                    />
                                    {ReactHtmlParser(item.significance)}
                                    {showEditLine &&
                                      lineToEditType === "itemSignificance" &&
                                      currentSegment === segment.segmentNo &&
                                      currentItem === item.id && (
                                        <div>
                                          <div
                                            style={{
                                              border: "1px solid #969aa2",
                                              marginBottom: "20px",
                                              borderRadius: "5px 5px 0px 0px",
                                            }}
                                          >
                                            <p
                                              style={{
                                                padding: "0 10px",
                                                margin: "0",
                                              }}
                                            >
                                              {`item ${item.id} Significance`}
                                            </p>
                                            <Divider />
                                            <CKEditor
                                              editor={InlineEditor}
                                              data={item.significance}
                                              onChange={(event, editor) => {
                                                const data = editor.getData();
                                                const segmentData = {
                                                  ...this.state.segmentData,
                                                };
                                                segmentData[
                                                  `significance${item.id}`
                                                ] = data;
                                                this.setState({
                                                  segmentData,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div style={{ textAlign: "center" }}>
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              style={{ padding: "5px  50px" }}
                                              onClick={this.doEditSegmentSubmit}
                                            >
                                              Submit
                                            </Button>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                )}
                                {item.explanation && (
                                  <div>
                                    <strong>Explanation</strong>
                                    <CreateIcon
                                      onClick={() =>
                                        this.makeEditLineShow(
                                          segment,
                                          "itemExplanation",
                                          item.id
                                        )
                                      }
                                      style={{
                                        marginLeft: "10px",
                                        color: "blue",
                                      }}
                                    />
                                    {ReactHtmlParser(item.explanation)}
                                    {showEditLine &&
                                      lineToEditType === "itemExplanation" &&
                                      currentSegment === segment.segmentNo &&
                                      currentItem === item.id && (
                                        <div>
                                          <div
                                            style={{
                                              border: "1px solid #969aa2",
                                              marginBottom: "20px",
                                              borderRadius: "5px 5px 0px 0px",
                                            }}
                                          >
                                            <p
                                              style={{
                                                padding: "0 10px",
                                                margin: "0",
                                              }}
                                            >
                                              {`item ${item.id} Expalanation`}
                                            </p>
                                            <Divider />
                                            <CKEditor
                                              editor={InlineEditor}
                                              data={item.explanation}
                                              onChange={(event, editor) => {
                                                const data = editor.getData();
                                                const segmentData = {
                                                  ...this.state.segmentData,
                                                };
                                                segmentData[
                                                  `explanation${item.id}`
                                                ] = data;
                                                this.setState({
                                                  segmentData,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div style={{ textAlign: "center" }}>
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              style={{ padding: "5px  50px" }}
                                              onClick={this.doEditSegmentSubmit}
                                            >
                                              Submit
                                            </Button>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                )}
                              </li>
                              <br />
                              <br />
                            </ul>
                          </div>
                        )
                    )}
                  </ul>

                  <Button
                    onClick={() => this.editSegment(segment)}
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "10px" }}
                  >
                    Edit this Segment
                  </Button>
                  {showEditOsce && currentSegment === segment.segmentNo && (
                    <Grid
                      item
                      md={9}
                      lg={6}
                      style={{ margin: "auto", padding: "10px" }}
                    >
                      <form>
                        <br />
                        <div
                          style={{
                            border: "1px solid #969aa2",
                            marginBottom: "20px",
                            borderRadius: "5px 5px 0px 0px",
                          }}
                        >
                          <p style={{ padding: "0 10px", margin: "0" }}>
                            Segment Title
                          </p>
                          <Divider />
                          <CKEditor
                            editor={InlineEditor}
                            data={segment.segmentTitle}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              const segmentData = { ...this.state.segmentData };
                              segmentData.segmentTitle = data;
                              this.setState({ segmentData });
                            }}
                          />
                        </div>

                        {segment.items.map((item) => (
                          <div
                            key={item.id}
                            style={{
                              border: "1px solid #969aa2",
                              marginBottom: "20px",
                              borderRadius: "5px 5px 0px 0px",
                            }}
                          >
                            <p
                              style={{ padding: "0 10px", margin: "0" }}
                            >{`item ${item.id}`}</p>
                            <Divider />
                            <CKEditor
                              editor={InlineEditor}
                              data={item.content}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                const segmentData = {
                                  ...this.state.segmentData,
                                };
                                segmentData[`item${item.id}`] = data;
                                this.setState({ segmentData });
                              }}
                            />

                            {osce.type !== "summary" && (
                              <div>
                                <p
                                  style={{ padding: "0 10px", margin: "0" }}
                                >{`item ${item.id} Technique`}</p>
                                <Divider />
                                <CKEditor
                                  editor={InlineEditor}
                                  data={item.technique}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    const segmentData = {
                                      ...this.state.segmentData,
                                    };
                                    segmentData[`technique${item.id}`] = data;
                                    this.setState({ segmentData });
                                  }}
                                />
                              </div>
                            )}

                            {osce.type !== "summary" && (
                              <div>
                                <p
                                  style={{ padding: "0 10px", margin: "0" }}
                                >{`item ${item.id} Significance`}</p>
                                <Divider />
                                <CKEditor
                                  editor={InlineEditor}
                                  data={item.significance}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    const segmentData = {
                                      ...this.state.segmentData,
                                    };
                                    segmentData[`significance${item.id}`] =
                                      data;
                                    this.setState({ segmentData });
                                  }}
                                />
                              </div>
                            )}

                            {osce.type === "summary" && (
                              <div>
                                <p
                                  style={{ padding: "0 10px", margin: "0" }}
                                >{`item ${item.id} Explanation`}</p>
                                <Divider />
                                <CKEditor
                                  editor={InlineEditor}
                                  data={item.explanation}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    const segmentData = {
                                      ...this.state.segmentData,
                                    };
                                    segmentData[`explanation${item.id}`] = data;
                                    this.setState({ segmentData });
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        ))}

                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={this.doEditSegmentSubmit}
                        >
                          Submit
                        </Button>
                      </form>
                    </Grid>
                  )}
                  <br />
                  <br />
                  <br />
                </div>
              ))}
            </Grid>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default EditOsce;
