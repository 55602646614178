import React from "react";
import Grid from "@material-ui/core/Grid";
// import Paper from '@material-ui/core/Paper';
// import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Form from "./common/form";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Joi from "joi-browser";
import Button from "@material-ui/core/Button";
import { Divider } from "@material-ui/core";
import { toast } from "react-toastify";
import firebase from "firebase/app";
import TextInputField from "./common/textInputField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import PreLoader from "./common/preLoader";
import TextField from "@material-ui/core/TextField";
import {
  getSubdivisions,
  getSubjects,
  getTopics,
} from "../utils/getCategories";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

class QuestionBank extends Form {
  state = {
    user: {},
    data: {
      subject: "",
      subdivision: "",
      topic: "",
    },
    errors: {},
    showCustom: false,
    showByTopic: false,
    customSubjects: [],
    customSystems: [],
    questions: [],
    numberOfQuestions: 0,
    scheduleTitle: "",
    showSelectSubjects: false,
    showSelectSystems: false,
    submitting: false,
    includeSeen: false,
    customGroup: false,
    dateScheduled: "2022-02-09T10:30",
    showScheduledQuizLink: false,
    scheduledLink: "",
    grantAccess: false,
    forceSignIn: false,
    makeGoPremium: false,
    subjects: [],
    subjectsWithRelevantContent: [],
  };

  schema = {
    // school: Joi.string().required().label("School"),
    subject: Joi.string().required().label("Subject"),
    subdivision: Joi.string().required().label("System/Subdivision"),
    topic: Joi.string().required().label("Topic"),
  };

  componentDidMount() {
    const userId = localStorage.getItem("currentUserId");
    const statistics = JSON.parse(localStorage.getItem("statistics"));

    // GETTING THE SUBJECTS LIST
    const subjects = getSubjects(statistics.subjects);
    const findSubjectsWithRelevantContent = subjects.filter(
      (subject) => subject.noOfQuestions > 0
    );
    const subjectsWithRelevantContent = getSubjects(
      findSubjectsWithRelevantContent
    );

    this.setState({ subjects, subjectsWithRelevantContent });

    if (userId) {
      firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .onSnapshot((doc) => {
          const user = doc.data();

          if (user.volunteer && user.volunteer.length > 0) {
            this.setState({ grantAccess: true });
          }

          this.setState({ user });
          if (user) {
            firebase
              .firestore()
              .collection("premiumUsers")
              .doc(user.email)
              .onSnapshot((doc) => {
                // console.log(doc.data());
                const user = doc.data();

                if (
                  user &&
                  user.email.length > 3 &&
                  user.subscription.type === "basic"
                ) {
                  // console.log("give this one permission");
                  this.setState({ grantAccess: true });
                }

                firebase
                  .auth()
                  .currentUser.getIdTokenResult()
                  .then((idTokenResult) => {
                    // console.log(idTokenResult.claims);
                    // Confirm the user is an Admin.
                    if (
                      idTokenResult.claims.admin &&
                      idTokenResult.claims.admin === true
                    ) {
                      // console.log("an admin")
                      this.setState({ grantAccess: true });
                    } else if (
                      idTokenResult.claims.editor &&
                      idTokenResult.claims.editor.length > 0
                    ) {
                      this.setState({ grantAccess: true });
                    } else if (
                      idTokenResult.claims.moderator &&
                      idTokenResult.claims.moderator.features &&
                      idTokenResult.claims.moderator.features.length > 0
                    ) {
                      this.setState({ grantAccess: true });
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              });
          }
        });
    }
  }

  systems = () => {
    const { subject } = this.state.data;
    const { customSubjects, showByTopic, subjectsWithRelevantContent } =
      this.state;
    if (showByTopic) {
      const subjectData = subjectsWithRelevantContent.filter(
        (relevantSubject) => relevantSubject.id === subject
      )[0];

      let relevantSubdivisions = [];
      for (let i = 0; i < subjectData.subdivisions.length; i++) {
        const relevantTopics = subjectData.subdivisions[i].topics.filter(
          (theTopic) => theTopic.noOfQuestions > 4
        );
        console.log(relevantTopics);
        if (relevantTopics.length > 0) {
          relevantSubdivisions.push(subjectData.subdivisions[i]);
        }
      }
      subjectData.subdivisions = relevantSubdivisions;
      return getSubdivisions([subject], [subjectData]);
    }

    const subjectsWithRelevantSubdivisions = [];

    for (let x = 0; x < customSubjects.length; x++) {
      const subjectData = subjectsWithRelevantContent.filter(
        (relevantSubject) => relevantSubject.id === customSubjects[x]
      )[0];
      const relevantSubdivisions = subjectData.subdivisions.filter(
        (theSubdivision) => theSubdivision.noOfQuestions > 0
      );
      subjectData.subdivisions = relevantSubdivisions;
      subjectsWithRelevantSubdivisions.push(subjectData);
    }

    return getSubdivisions(customSubjects, subjectsWithRelevantContent);
  };

  topics = () => {
    const { subject, subdivision } = this.state.data;
    const subjectsWithRelevantContent = [
      ...this.state.subjectsWithRelevantContent,
    ];

    let subjectData = subjectsWithRelevantContent.filter(
      (relevantSubject) => relevantSubject.id === subject
    )[0];
    let subdivisionData = subjectData.subdivisions.filter(
      (theSubdivision) => theSubdivision.id === subdivision
    )[0] || { topics: [] };
    const relevantTopics = subdivisionData.topics.filter(
      (theTopic) => theTopic.noOfQuestions > 4
    );
    console.log(relevantTopics);
    subdivisionData.topics = relevantTopics;
    const newSubjectData = {
      ...subjectData,
      subdivisions: [subdivisionData],
    };

    return getTopics(subject, subdivision, [newSubjectData]);
  };

  doSubmit = () => {
    const { data } = this.state;
    // const school = data.school;
    const subject = data.subject;

    window.location.assign(`/questions/${subject}/`);
  };

  showCustom = (type) => {
    const { showCustom, customGroup } = this.state;

    if (type === "group") {
      this.setState({ customGroup: !customGroup });
    }

    this.setState({ showCustom: !showCustom });
  };

  showByTopic = () => {
    const { showByTopic } = this.state;

    this.setState({ showByTopic: !showByTopic });
  };

  handleSubjectChange = (event) => {
    let customSubjects = [...this.state.customSubjects];
    // const { grantAccess } = this.state;
    const subject = event.currentTarget;

    if (customSubjects.length < 1) {
      this.setState({ customSystems: [] });
    }

    if (subject.checked) {
      // if (!grantAccess && customSubjects.length === 1) {
      //   toast.info("You may select a maximum of 1 subject on the FREE PLAN");
      //   subject.checked = false;
      //   return;
      // }
      if (customSubjects.length === 4) {
        toast.info("You may select a maximum of 4 subjects");
        subject.checked = false;
        return;
      }

      customSubjects.push(subject.value);
      this.setState({ customSubjects });
    } else if (!subject.checked) {
      const index = customSubjects.indexOf(subject.value);
      if (index > -1) {
        customSubjects.splice(index, 1);
        this.setState({ customSubjects });
      }
    }
    // console.log(customSubjects);
  };

  handleSystemChange = (event) => {
    const system = event.currentTarget;
    let customSystems = new Set(this.state.customSystems);
    // console.log(customSystems);
    let customSystemsArray = Array.from(customSystems);

    if (system.checked) {
      if (customSystemsArray.length === 6) {
        toast.info("You may select a maximum of 6 systems");
        system.checked = false;
        return;
      }

      customSystems.add(system.value);
      customSystemsArray = Array.from(customSystems);
      this.setState({ customSystems: customSystemsArray });
    } else if (!system.checked) {
      const index = customSystemsArray.indexOf(system.value);
      if (index > -1) {
        customSystemsArray.splice(index, 1);
        this.setState({ customSystems: customSystemsArray });
      }
    }
    // console.log(customSystems);
  };

  handleIncludeSeenChange = (event) => {
    const includeSeen = event.currentTarget;

    if (includeSeen.checked) {
      this.setState({ includeSeen: true });
    } else if (!includeSeen.checked) {
      this.setState({ includeSeen: false });
    }
  };

  handleDatePickChange = (event) => {
    const dateScheduled = event.currentTarget.value;

    this.setState({ dateScheduled });
  };

  getCustomQuestions = (e) => {
    e.preventDefault();
    const {
      customSubjects,
      customSystems,
      includeSeen,
      user,
      customGroup,
      showByTopic,
    } = this.state;
    const { subject, subdivision, topic } = this.state.data;
    const { subjects } = this.state;

    firebase.auth().onAuthStateChanged((authUser) => {
      if (!authUser) {
        toast.error("Please Login to Access this feature");
        this.setState({ forceSignIn: true });
        // console.log("user not signed in");
        return;
      } else if (
        (authUser && authUser.email && !user.userSubjects) ||
        (authUser &&
          authUser.email &&
          user.userSubjects &&
          !user.userSubjects.length > 0)
      ) {
        toast.warn(
          "Please go to the dashboard to complete your account to continue"
        );
        this.setState({ forceAccountComplete: true });
        return;
      } else {
        if (!showByTopic && customSubjects.length === 0) {
          toast.error("You must select at least one Subject");
          return;
        }
        if (!showByTopic && customSystems.length === 0) {
          toast.error("You must select at least one System");
          return;
        }

        // //Launch the preloader while submitting
        // const submitting = this.state.submitting;
        // this.setState({ submitting: !submitting });

        if (showByTopic) {
          const subjectofTopic = subjects.filter(
            (theSubject) => theSubject.id === subject
          )[0];
          const systemOfTopic = subjectofTopic.subdivisions.filter(
            (theSubdivision) => theSubdivision.id === subdivision
          )[0];
          const topicItself = systemOfTopic.topics.filter(
            (theTopic) => theTopic.id === topic
          )[0];
          const noOfQuestionsFound = topicItself.noOfQuestions;

          this.setState({ noOfQuestionsFound, foundQuestions: true });
          if (customGroup) {
            this.setState({ showScheduleButton: true });
          }
        } else {
          let noOfQuestionsFound = 0;
          let userTotalSeenQues = 0;
          for (let x = 0; x < customSubjects.length; x++) {
            const subjectOfConcern = subjects.filter(
              (theSubject) => theSubject.id === customSubjects[x]
            )[0];
            const systemsOfConcern = subjectOfConcern.subdivisions.filter(
              (theSubdivision) => customSystems.includes(theSubdivision.id)
            );
            for (let y = 0; y < systemsOfConcern.length; y++) {
              noOfQuestionsFound += systemsOfConcern[y].noOfQuestions;
              const userSeenSystemQues = user.seenQuestions.filter(
                (theSeenQue) =>
                  theSeenQue.subject === customSubjects[x] &&
                  theSeenQue.system === systemsOfConcern[y].id
              );
              userTotalSeenQues += userSeenSystemQues.length;
            }
          }
          if (!includeSeen) {
            noOfQuestionsFound -= userTotalSeenQues;
          }
          this.setState({ noOfQuestionsFound, foundQuestions: true });
          if (customGroup) {
            this.setState({ showScheduleButton: true });
          }
        }
      }
    });
  };

  handleNoQuesChange = (event) => {
    const numberOfQuestions = Number(event.currentTarget.value);
    // console.log("No of questions", numberOfQuestions)

    this.setState({ numberOfQuestions });
  };

  handleScheduleTitleChange = (event) => {
    const scheduleTitle = event.currentTarget.value;

    this.setState({ scheduleTitle });
  };

  handleStartSession = (e) => {
    const { grantAccess } = this.state;
    e.preventDefault();
    const {
      numberOfQuestions,
      customGroup,
      dateScheduled,
      scheduleTitle,
      noOfQuestionsFound,
      showByTopic,
      customSubjects,
      customSystems,
      includeSeen,
      user,
      subjects,
    } = this.state;
    const { subject, subdivision, topic } = this.state.data;

    firebase.auth().onAuthStateChanged((authUser) => {
      if (!authUser) {
        toast.error("Please Login to Access this feature");
        this.setState({ forceSignIn: true });
        // console.log("user not signed in");
        return;
      } else if (
        (authUser && authUser.email && !user.userSubjects) ||
        (authUser &&
          authUser.email &&
          user.userSubjects &&
          !user.userSubjects.length > 0)
      ) {
        toast.warn(
          "Please go to the dashboard to complete your account to continue"
        );
        this.setState({ forceAccountComplete: true });
        return;
      } else {
        if (
          !grantAccess &&
          user &&
          user.seenQuestions &&
          user.seenQuestions.length > 50
        ) {
          toast.warning(
            "Sorry! You have reached your free questions limit. Get a PREMIUM Account to continue."
          );
          this.setState({ makeGoPremium: true });
          return;
        }

        if (!showByTopic && customSubjects.length === 0) {
          toast.error("You must select at least one Subject");
          return;
        }
        if (!showByTopic && customSystems.length === 0) {
          toast.error("You must select at least one System");
          return;
        }

        // //Launch the preloader while submitting
        // const submitting = this.state.submitting;
        // this.setState({ submitting: !submitting });

        if (showByTopic) {
          const subjectofTopic = subjects.filter(
            (theSubject) => theSubject.id === subject
          )[0];
          const systemOfTopic = subjectofTopic.subdivisions.filter(
            (theSubdivision) => theSubdivision.id === subdivision
          )[0];
          const topicItself = systemOfTopic.topics.filter(
            (theTopic) => theTopic.id === topic
          )[0];
          const noOfQuestionsFound = topicItself.noOfQuestions;

          this.setState({ noOfQuestionsFound, foundQuestions: true });
          if (customGroup) {
            this.setState({ showScheduleButton: true });
          }
        } else {
          let noOfQuestionsFound = 0;
          let userTotalSeenQues = 0;
          for (let x = 0; x < customSubjects.length; x++) {
            const subjectOfConcern = subjects.filter(
              (theSubject) => theSubject.id === customSubjects[x]
            )[0];
            const systemsOfConcern = subjectOfConcern.subdivisions.filter(
              (theSubdivision) => customSystems.includes(theSubdivision.id)
            );
            for (let y = 0; y < systemsOfConcern.length; y++) {
              noOfQuestionsFound += systemsOfConcern[y].noOfQuestions;
              const userSeenSystemQues = user.seenQuestions.filter(
                (theSeenQue) =>
                  theSeenQue.subject === customSubjects[x] &&
                  theSeenQue.system === systemsOfConcern[y].id
              );
              userTotalSeenQues += userSeenSystemQues.length;
            }
          }
          if (!includeSeen) {
            noOfQuestionsFound -= userTotalSeenQues;
          }
          this.setState({ noOfQuestionsFound, foundQuestions: true });
          if (customGroup) {
            this.setState({ showScheduleButton: true });
          }
        }
      }
    });

    // const subQuestions = questions.slice(0, numberOfQuestions);
    const quizTime = numberOfQuestions * 1.2;
    let questions = [];
    let toBeSpliced = [];

    if (numberOfQuestions > noOfQuestionsFound) {
      toast.error(
        `The maximum number of questions available is ${noOfQuestionsFound}`
      );
      return;
    } else if (numberOfQuestions < 1) {
      toast.error("You must choose at least one question to proceed");
      return;
    }

    if (customGroup && !scheduleTitle) {
      toast.error("You must enter a Title for your schedule");
      return;
    }

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    if (showByTopic) {
      firebase
        .firestore()
        .collection("questions")
        .where("subject", "==", subject)
        .where("system", "==", subdivision)
        .where("topic", "==", topic)
        .limit(numberOfQuestions)
        .get()
        .then((querySnapshot) => {
          let questions = [];
          querySnapshot.forEach((doc) => {
            const question = doc.data();
            questions.push(question);
          });
          for (let i = 0; i < questions.length; i++) {
            questions[i].queNo = i + 1;
          }

          const quizPack = {
            title: "Custom Session",
            quizId: "custom",
            quizTime: quizTime,
            questions: questions,
          };

          const submitting = this.state.submitting;
          this.setState({ submitting: !submitting });
          localStorage.setItem("currentCustom", JSON.stringify(quizPack));
          window.location = `/quiz-preview/custom`;
        });
    } else {
      let subjectsSeenQuesArray = [];
      let subjectsUnseenQuesArray = [];
      const subjectsOfConcern = subjects.filter((theSubject) =>
        customSubjects.includes(theSubject.id)
      );
      for (let x = 0; x < subjectsOfConcern.length; x++) {
        subjectsSeenQuesArray[x] = 0;
        subjectsUnseenQuesArray[x] = 0;
        subjectsUnseenQuesArray[x] += subjectsOfConcern[x].noOfQuestions;
        // console.log(subjectsOfConcern[x].noOfQuestions);

        const systemsOfConcern = subjectsOfConcern[x].subdivisions.filter(
          (theSubdivision) => customSystems.includes(theSubdivision.id)
        );
        for (let y = 0; y < systemsOfConcern.length; y++) {
          const systemSeenQues = user.seenQuestions.filter(
            (theQuestion) =>
              theQuestion.subject === subjectsOfConcern[x].id &&
              theQuestion.system === systemsOfConcern[y].id
          );
          subjectsSeenQuesArray[x] += systemSeenQues.length;
        }
        subjectsUnseenQuesArray[x] -= subjectsSeenQuesArray[x];
      }

      let totalNoOfUnseenQuestions = 0;
      for (let x = 0; x < subjectsUnseenQuesArray.length; x++) {
        totalNoOfUnseenQuestions += subjectsUnseenQuesArray[x];
      }

      let subjectAvailableQuesRatiosArray = [];
      for (let x = 0; x < subjectsUnseenQuesArray.length; x++) {
        const ratio = Math.round(
          (subjectsUnseenQuesArray[x] / totalNoOfUnseenQuestions) *
            numberOfQuestions
        );
        subjectAvailableQuesRatiosArray.push(ratio);
      }

      for (let y = 0; y < customSubjects.length; y++) {
        const subjectLimit =
          subjectAvailableQuesRatiosArray[y] + subjectsSeenQuesArray[y] + 1;
        //the 1 is to cater for any unexpected rounding of numbers

        firebase
          .firestore()
          .collection("questions")
          .where("subject", "==", customSubjects[y])
          .where("system", "in", customSystems)
          .limit(subjectLimit)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const question = doc.data();
              // console.log(question);
              questions.push(question);
            });
            if (y === customSubjects.length - 1) {
              if (!includeSeen) {
                // console.log("don't include seen");
                if (user.seenQuestions) {
                  const userSeenQues = user.seenQuestions;
                  const seenQuestionsIdArray = [];
                  for (let i = 0; i < userSeenQues.length; i++) {
                    seenQuestionsIdArray.push(userSeenQues[i].id);
                  }
                  for (let i = 0; i < questions.length; i++) {
                    if (seenQuestionsIdArray.includes(questions[i].id)) {
                      toBeSpliced.push(questions[i]);
                    }
                  }
                }
              }

              for (let i = 0; i < toBeSpliced.length; i++) {
                const index = questions.indexOf(toBeSpliced[i]);
                questions.splice(index, 1);
              }

              //Remove any extra questions
              questions.splice(numberOfQuestions, questions.length + 1);

              const quizPack = {
                title: "Custom Session",
                quizId: "custom",
                quizTime: quizTime,
                questions: questions,
              };

              this.setState({ foundQuestions: true });

              if (!customGroup) {
                localStorage.setItem("currentCustom", JSON.stringify(quizPack));
                const submitting = this.state.submitting;
                this.setState({ submitting: !submitting });
                window.location = `/quiz-preview/custom`;
              } else if (customGroup) {
                const userId = localStorage.getItem("currentUserId");

                const actualDate = new Date(dateScheduled);
                const dateToMilli = Date.parse(actualDate);

                quizPack.title = scheduleTitle;
                quizPack.startDate = dateToMilli;
                quizPack.quizId = "scheduled";
                quizPack.scheduledBy = `${user.firstName} ${user.lastName}`;
                quizPack.schedulerId = userId;
                quizPack.dateString = actualDate.toString();
                const docId = `${user.firstName.replace(
                  /\s/g,
                  "-"
                )}-${Math.floor(Math.random(1) * (9999 - 1000 + 1) + 1000)}`;
                quizPack.scheduleId = docId;
                firebase
                  .firestore()
                  .collection("scheduled")
                  .doc(docId)
                  .set(quizPack)
                  .then(() => {
                    const submitting = this.state.submitting;
                    this.setState({ submitting: !submitting });
                    toast.success(
                      "Session scheduled successfully. Find details below"
                    );
                    const scheduledLink = `https://acabest.com/scheduled-quiz/${docId}/`;
                    this.setState({
                      showScheduledQuizLink: true,
                      scheduledLink,
                    });
                  });
              }
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    }

    // //Launch the preloader while submitting
    // const submitting = this.state.submitting;
    // this.setState({ submitting: !submitting });

    // for (let i = 0; i < subQuestions.length; i++) {
    //   subQuestions[i].queNo = i + 1;
    // }

    // console.log("old questions", questions);
    // console.log("new questions", subQuestions);

    // const quizPack = {
    //   title: "Custom Session",
    //   quizId: "custom",
    //   quizTime: quizTime,
    //   questions: subQuestions,
    // };

    // if (!customGroup) {
    //   localStorage.setItem("currentCustom", JSON.stringify(quizPack));
    //   // const submitting = this.state.submitting;
    //   // this.setState({ submitting: !submitting });
    //   window.location = `/quiz-preview/custom`;
    // } else if (customGroup) {
    //   const user = JSON.parse(localStorage.getItem("currentUser"));
    //   const userId = localStorage.getItem("currentUserId");

    //   const actualDate = new Date(dateScheduled);
    //   const dateToMilli = Date.parse(actualDate);

    //   quizPack.title = scheduleTitle;
    //   quizPack.startDate = dateToMilli;
    //   quizPack.quizId = "scheduled";
    //   quizPack.scheduledBy = `${user.firstName} ${user.lastName}`;
    //   quizPack.schedulerId = userId;
    //   quizPack.dateString = actualDate.toString();
    //   const docId = `${user.firstName.replace(/\s/g, "-")}-${Math.floor(
    //     Math.random(1) * (9999 - 1000 + 1) + 1000
    //   )}`;
    //   quizPack.scheduleId = docId;

    //   firebase
    //     .firestore()
    //     .collection("scheduled")
    //     .doc(docId)
    //     .set(quizPack)
    //     .then(() => {
    //       // const submitting = this.state.submitting;
    //       // this.setState({ submitting: !submitting });
    //       toast.success("Session scheduled successfully. Find details below");
    //       const scheduledLink = `https://acabest.com/scheduled-quiz/${docId}/`;
    //       this.setState({ showScheduledQuizLink: true, scheduledLink });
    //     });
    // }
  };

  showSelectSubjects = () => {
    const { showSelectSubjects } = this.state;

    this.setState({ showSelectSubjects: !showSelectSubjects });
    if (!showSelectSubjects) {
      this.setState({ customSubjects: [], customSystems: [] });
    }
  };

  showSelectSystems = () => {
    const { showSelectSystems } = this.state;

    this.setState({ showSelectSystems: !showSelectSystems });
    if (!showSelectSystems) {
      this.setState({ customSystems: [] });
    }
  };

  copySharedLink = () => {
    const linkText = document.getElementById("shared-link");
    linkText.select();
    linkText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    // console.log("copied link", linkText.value)
    toast.success("invitation link copied to clipboard");
  };

  renderScheduledDate = () => {
    const { dateScheduled } = this.state;
    const date = new Date(dateScheduled);
    return (
      <Typography>
        Your Session has been scheduled for <strong>{date.toString()}</strong>
      </Typography>
    );
  };

  resetThings = () => {
    window.location.reload();
  };

  render() {
    const {
      questions,
      foundQuestions,
      showCustom,
      showSelectSubjects,
      showSelectSystems,
      submitting,
      customGroup,
      showScheduleButton,
      dateScheduled,
      showScheduledQuizLink,
      scheduledLink,
      customSubjects,
      showByTopic,
      includeSeen,
      data,
      forceSignIn,
      forceAccountComplete,
      makeGoPremium,
      subjects,
      subjectsWithRelevantContent,
      noOfQuestionsFound,
    } = this.state;
    const classes = useStyles;

    return (
      <React.Fragment>
        <Helmet>
          <title>Question Bank - Acabest</title>
        </Helmet>

        <br />
        <Typography variant="h4" component="h1" style={{ textAlign: "center" }}>
          Question Bank
        </Typography>
        <br />

        {submitting || subjects.length < 1 ? (
          <PreLoader />
        ) : (
          <div>
            <div
              style={{ margin: "auto", marginTop: "5px", textAlign: "center" }}
            >
              <Button
                disabled={showCustom || showByTopic}
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.showCustom}
              >
                Start a custom session
              </Button>
              <br />
              <br />
              <Button
                disabled={showCustom || showByTopic}
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => this.showCustom("group")}
              >
                Schedule a Session
              </Button>
              <p style={{ width: "220px", margin: "auto", fontSize: "12px" }}>
                You can share the schedule link with friends
              </p>
              <br />

              <Button
                disabled={showCustom}
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => this.showByTopic()}
              >
                Find By High-Yield Topics
              </Button>
              <p style={{ width: "220px", margin: "auto", fontSize: "12px" }}>
                Questions from Topics that most frequently show up in exams
              </p>
            </div>

            {forceSignIn && (
              <div style={{ margin: "30px", textAlign: "center" }}>
                <Typography variant="body1">
                  Please{" "}
                  <Button
                    component={Link}
                    variant="contained"
                    color="secondary"
                    to="/login/questionbank"
                  >
                    Sign In
                  </Button>{" "}
                  to Continue.
                </Typography>
                <Typography variant="caption">
                  If you are alredy signed in, then simply refresh the page
                </Typography>
              </div>
            )}
            {forceAccountComplete && (
              <div style={{ margin: "30px", textAlign: "center" }}>
                <Typography variant="body1">
                  Please{" "}
                  <Button
                    component={Link}
                    variant="contained"
                    color="secondary"
                    to="/dashboard"
                  >
                    Go to Dashboard
                  </Button>{" "}
                  to complete your account. Use the reset button(or refresh
                  page) to reflect changes if your account is actually complete.
                </Typography>
              </div>
            )}
            {makeGoPremium && (
              <div style={{ margin: "30px", textAlign: "center" }}>
                <br />
                <Button
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to={`/pricing`}
                >
                  Go Premium
                </Button>
                <br />
                <Typography variant="caption">
                  Get a premium account to see the rest of this OSCE.
                </Typography>
              </div>
            )}

            {(showScheduledQuizLink || showCustom || showByTopic) && (
              <div style={{ float: "right", margin: "10px 50px" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.resetThings}
                >
                  Reset
                </Button>
              </div>
            )}

            {foundQuestions && (
              <Container
                component="main"
                maxWidth="sm"
                style={{
                  margin: "30px auto",
                  width: "95%",
                  background:
                    "linear-gradient(225deg, rgb(63 81 181 / 86%), rgb(0 123 255 / 12%) 70.71%)",
                  boxShadow:
                    "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                  padding: "4rem 2rem",
                  borderRadius: "10px",
                }}
              >
                {showScheduledQuizLink && (
                  <div>
                    {this.renderScheduledDate()}
                    <br />
                    <Typography>Invitation Link:</Typography>
                    <br />
                    <input
                      type="text"
                      value={scheduledLink}
                      style={{ width: "80%" }}
                      id="shared-link"
                      readOnly
                    />
                    <Typography>
                      Share this link with your friends to invite them to take
                      the session with you.
                    </Typography>
                    <br /> <br />
                    <Button
                      // disabled={showCustom}
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={this.copySharedLink}
                    >
                      Click Here to Copy Link
                    </Button>
                    <br />
                    <br />
                    <Typography>
                      <a
                        href="/my-scheduled"
                        style={{
                          textDecoration: "underline !important",
                          color: "blue",
                        }}
                      >
                        Click here
                      </a>{" "}
                      to see your Scheduled sessions
                    </Typography>
                  </div>
                )}
                {!showScheduledQuizLink && (
                  <div className={classes.paper}>
                    <Grid
                      justifyContent="center"
                      alignItems="center"
                      item
                      container
                    >
                      {!customGroup && (
                        <Typography
                          component="h1"
                          variant="body1"
                          style={{ marginLeft: "10px" }}
                        >
                          {noOfQuestionsFound === 1
                            ? `Found ${noOfQuestionsFound} question. How many would you like to take now?`
                            : `Found ${noOfQuestionsFound} questions. How many would you like to take now?`}
                          {}
                        </Typography>
                      )}
                      {customGroup && (
                        <Typography
                          component="h1"
                          variant="body1"
                          style={{ marginLeft: "10px" }}
                        >
                          {noOfQuestionsFound === 1
                            ? `Found ${noOfQuestionsFound} question. How many would you like to schedule for?`
                            : `Found ${noOfQuestionsFound} questions. How many would you like to schedule for?`}
                          {}
                        </Typography>
                      )}
                    </Grid>
                    <form
                      className={classes.form}
                      onSubmit={this.handleStartSession}
                    >
                      <TextInputField
                        variant="outlined"
                        margin="normal"
                        label="No Of Questions"
                        onChange={this.handleNoQuesChange}
                        type="number"
                      />
                      <br />

                      {customGroup && (
                        <div>
                          <TextField
                            id="datetime-local"
                            label="Select date and Time"
                            type="datetime-local"
                            // defaultValue={"2021-03-24T10:30"}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={dateScheduled}
                            onChange={this.handleDatePickChange}
                          />
                          <br />
                          <br />

                          <TextInputField
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            label="Schedule Title"
                            onChange={this.handleScheduleTitleChange}
                            type="text"
                          />
                          <br />
                          <br />
                        </div>
                      )}

                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        {showScheduleButton ? "Schedule" : "Let's Go"}
                      </Button>
                    </form>
                  </div>
                )}
              </Container>
            )}

            {!foundQuestions && showCustom && (
              <Container
                component="main"
                maxWidth="sm"
                style={{
                  margin: "30px auto",
                  width: "95%",
                  background:
                    "linear-gradient(225deg, rgb(63 81 181 / 86%), rgb(0 123 255 / 12%) 70.71%)",
                  boxShadow:
                    "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                  padding: "4rem 2rem",
                  borderRadius: "10px",
                }}
              >
                <div className={classes.paper}>
                  <Grid
                    justifyContent="center"
                    alignItems="center"
                    item
                    container
                  >
                    <form
                      className={classes.form}
                      onSubmit={this.getCustomQuestions}
                    >
                      <div style={{ marginBottom: "20px" }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={this.showSelectSubjects}
                        >
                          Select Subject(s){" "}
                          {showSelectSubjects ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </Button>
                        {showSelectSubjects &&
                          subjectsWithRelevantContent.map((subject) => (
                            <div
                              key={subject.id}
                              style={{
                                margin: "5px auto",
                                padding: "5px auto",
                              }}
                            >
                              <input
                                style={{ marginRight: "10px" }}
                                type="checkbox"
                                name={subject.id}
                                id={`${subject.name}-${subject.id}`}
                                value={subject.id}
                                onChange={this.handleSubjectChange}
                              />
                              <label htmlFor={`${subject.name}-${subject.id}`}>
                                {subject.name}
                              </label>
                            </div>
                          ))}
                        <br />
                        <br />
                        <Divider />
                      </div>

                      {customSubjects.length > 0 && (
                        <div style={{ marginBottom: "20px" }}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.showSelectSystems}
                          >
                            Select System(s) / Subdivision(s){" "}
                            {showSelectSystems ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </Button>
                          {showSelectSystems &&
                            this.systems().map((system) => (
                              <div
                                key={system.id}
                                style={{
                                  margin: "5px auto",
                                  padding: "5px auto",
                                }}
                              >
                                <input
                                  style={{ marginRight: "10px" }}
                                  type="checkbox"
                                  name={system.id}
                                  id={`${system.name}-${system.id}`}
                                  value={system.id}
                                  onChange={this.handleSystemChange}
                                />
                                <label htmlFor={`${system.name}-${system.id}`}>
                                  {system.name}
                                </label>
                              </div>
                            ))}
                          <br />
                          <br />
                          <Divider />
                        </div>
                      )}

                      <div style={{ marginBottom: "20px" }}>
                        <div
                          style={{ margin: "5px auto", padding: "5px auto" }}
                        >
                          <input
                            style={{ marginRight: "10px" }}
                            type="checkbox"
                            name="include-seen"
                            id="include-seen"
                            value="yes"
                            checked={includeSeen}
                            onChange={this.handleIncludeSeenChange}
                          />
                          <label htmlFor="include-seen">
                            Include Questions I've Seen
                          </label>
                        </div>
                      </div>

                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        GO
                      </Button>
                    </form>
                  </Grid>
                </div>
              </Container>
            )}

            {!foundQuestions && !showCustom && showByTopic && (
              <Container
                component="main"
                maxWidth="sm"
                style={{
                  margin: "30px auto",
                  width: "95%",
                  background:
                    "linear-gradient(225deg, rgb(63 81 181 / 86%), rgb(0 123 255 / 12%) 70.71%)",
                  boxShadow:
                    "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                  padding: "4rem 2rem",
                  borderRadius: "10px",
                }}
              >
                {/* <CssBaseline /> */}
                <div className={classes.paper}>
                  <Grid
                    justifyContent="center"
                    alignItems="center"
                    item
                    container
                  >
                    <Typography
                      component="h1"
                      variant="h5"
                      style={{ marginLeft: "10px" }}
                    >
                      Find Questions By Topic
                    </Typography>
                  </Grid>
                  <form
                    className={classes.form}
                    onSubmit={this.getCustomQuestions}
                  >
                    {/* {this.renderSelect(
                          "school",
                          "Select School",
                          this.schools
                        )} */}
                    {this.renderSelect(
                      "subject",
                      "Select Subject",
                      subjectsWithRelevantContent
                    )}
                    {data.subject &&
                      this.renderSelect(
                        "subdivision",
                        "Select System/Subdivision",
                        this.systems()
                      )}
                    {data.subject &&
                      data.subdivision &&
                      this.renderSelect("topic", "Select Topic", this.topics())}

                    {this.renderSubmitButton("Go")}
                  </form>
                </div>
              </Container>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default QuestionBank;
