import React, { Component } from "react";
import HeaderPaper from "./common/headerPaper";
import Grid from "@material-ui/core/Grid";
import { Button, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";

class Accme extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>ACCME - Acabest</title>
        </Helmet>

        <HeaderPaper headerTitle="#ACCME OCTOBER '24" />

        <Grid
          container
          // item
          xs={9}
          lg={6}
          style={{ margin: "30px auto", textAlign: "justify" }}
        >
          <Grid>
            <Typography>
              <strong>
                Acabest brings you The ACCME (Acabest Crash Course for MDC Exam)
                for October 2024.
              </strong>
            </Typography>
            <br />
            <Typography>
              It's a 3 month long intensive course starting from 14th July. The
              program covers both stage 1 (MCQs) as well as stage 2 (Clinical
              Pictures and OSCE Clinical examinations and procedures).
            </Typography>
            <br />
            <Typography>
              Stage 1 and Stage 2 unmanned (clinical pictures) will be done
              online while Stage 2 OSCE will be in-person, in small groups,
              using mannequins at our mini skills lab.
            </Typography>
            <br />
            <Typography style={{ fontSize: "13px", color: "grey" }}>
              {/* <a
              href="https://youtu.be/FWVbh3DFLkg?si=PFVx_Eip6EeKRrBy"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "underline", color: "blue" }}
            >
              Click here
            </a>{" "}
            to watch a sample of our ACCME Paediatrics MCQs online session for
            June 2023 on Youtube.
            <br />
            <br /> */}
              {/* <a
                href="https://wa.me/message/RA3ZX3V2YX5ZB1"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: "10px auto" }}
                >
                  Register! / Make Enquiries
                </Button>
              </a>
              <br /> */}
              <br />
            </Typography>
          </Grid>
          <h2 style={{ fontSize: "23px" }}>What will we be covering?</h2>
          <Typography>
            For both Stage 1 and Stage 2 unmanned, we'll be covering the
            following disciplines;{" "}
            <strong>
              Medicine, Surgery, Paediatrics, Obstetrics and Gynaecology, Public
              Health, and Basic Sciences.
            </strong>
          </Typography>{" "}
          <br />
          <Typography>
            For stage 1, there are 3 materials you will use. First,{" "}
            <strong>pre-recorded sessions from our previous program</strong>.
            Second, you will be joining live online sessions to solve MCQs with
            in-depth explanation with a target of{" "}
            <strong>over 1200+ MCQs</strong>. Third, you will be given premium
            access to Acabest website which contains{" "}
            <strong>over 3,200 MCQs with answers and explanations</strong>.
          </Typography>
          <br />
          <Typography>
            Three resources will be used for stage 2 as well. First,{" "}
            <strong>In-person sessions at our mini-skills lab</strong> at
            Odorkor, Accra. Second, <strong>pre-recorded sessions</strong> from
            our previous course for the unmanned OSCE and Data interpretation.
            Third, <strong>Online sessions</strong> for discussing and
            practicing histories and for discussing manned OSCE station follow
            up questions.
          </Typography>
          <br />
          <Typography>
            For the in-persion sessions, we'll go through Medicine and
            Paediatric <strong>examination for all systems.</strong> In addition
            to these, we'll cover{" "}
            <strong>neonatal resuscitation and newborn examination</strong> for
            Paediatrics.
          </Typography>{" "}
          <br />
          <Typography>
            {" "}
            For Obs & Gynae, we'll cover{" "}
            <strong>
              examination of gravid uterus, gynae abdominal exam and pelvic exam
            </strong>
            . For Surgery, we'll cover the 5 short cases;{" "}
            <strong>
              thyroid, breast, ulcer, superficial swellings and hernia exam.
            </strong>
          </Typography>{" "}
          <br />
          <Typography>
            We'll also look at the common procedures encountered in the exams
            such as IV cannulation (for IV fluids or sample), urethral
            catheterization, urine dipstick, RBS, NG tube insertion, neonatal
            resuscitation, vaginal and cervical swabs.
          </Typography>{" "}
          <br />
          <Typography>
            You'll have 3 sessions per week at the skills lab, each lasting 3
            hours. 2 of these sessions will be with a tutor while the third will
            be on your own as a group to practice the skills you've acquired in
            the week. You will have all the resources available to practice as
            many times as you need to master the skills.
            <br />
            <br />
          </Typography>
          <br />
          <Grid>
            <h2 style={{ fontSize: "23px" }}>Will a Mock exam be organized?</h2>
            <Typography>
              A mock exam will be organized towards the end of the program. A
              mock for stage 1 and stage 2 unmanned is confirmed but we are
              still considering the possibility of a mock for the manned OSCE as
              it requires a lot more resources.
            </Typography>
            <Typography>
              A mock is expensive to organize, but it will come at no additional
              cost for those already on the program. Others will be allowed to
              join at a cost which will be communicated later.
            </Typography>
            <br />
          </Grid>
          <br />
          <Grid>
            <h2 style={{ fontSize: "23px" }}>
              Is there any accomodation arrangement?
            </h2>
            <Typography>
              Since many candidates that joined our previous program requested
              for accomodation, we are currently working on securing
              accomodation for those who may need it. We can't really tell the
              cost yet but the average cost for 2 in a room hostels around are
              from 700 to 900 per month depending on the resources available.
              Let us know if you'll need acccomodation.
            </Typography>
          </Grid>
          <br />
          <Grid>
            <h2 style={{ fontSize: "23px" }}>How much does it cost?</h2>
            <Typography>
              <strong>Stage 1 & 2: GHC 1,500 per month</strong>
            </Typography>
            <Typography>Stage 1 only: GHC 700 per month</Typography>
            <Typography>Stage 2 only: GHC 1,100 per month</Typography>
            <Typography>
              You can register for only stage 1 or 2 or both. Registration for
              either or both comes with access to Acabest Premium for 1 year at
              no additional cost.
            </Typography>
            <Typography>
              <strong>Note:</strong> The slots are limited for the Stage 2.
            </Typography>
            <br />
            <Typography>
              Click on the button below to WhatsApp us to initiate your
              registration or make enquiries.
            </Typography>

            <br />
            <br />
            <a
              href="https://wa.me/message/RA3ZX3V2YX5ZB1"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: "10px auto" }}
              >
                Register! / Make Enquiries
              </Button>
            </a>
            <br />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Accme;
