import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import notFound from "../img/notFound.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  heroImgContent: {
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
    // padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
  doctorsImgContainer: {
    textAlign: "center",
    position: "relative",
    padding: "50px",
  },
}));

class NotFound extends React.Component {
  render() {
    const classes = useStyles;

    return (
      <div>
        <Container
          component="main"
          maxWidth="sm"
          style={{
            margin: "30px auto",
            width: "95%",
            backgroundColor: "#fff",
            boxShadow:
              "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
            padding: "4rem 2rem",
            borderRadius: "10px",
          }}
        >
          <p style={{ textAlign: "center", fontSize: "20px" }}>oops...</p>
          <h1 style={{ textAlign: "center" }}>PAGE NOT FOUND</h1>
          <br />
          <Grid className={classes.doctorsImgContainer}>
            <img
              className="doctors-image"
              src={notFound}
              alt="under construction"
            />
          </Grid>
          <br />
          <br />
          <div style={{ textAlign: "center" }}>
            <a
              style={{
                color: "blue",
                textDecoration: "underline",
                fontSize: "20px",
              }}
              href="/home"
            >
              Go Home
            </a>
          </div>
        </Container>
      </div>
    );
  }
}

export default NotFound;
