import React from "react";
import firebase from "firebase/app";
import { getOsceSubjects, getSubjects } from "../utils/getCategories";
import { Button, Grid, Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import ReactToPrint from "react-to-print";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import PreLoader from "./common/preLoader";

class Statistics extends React.Component {
  state = {
    subjects: [],
    questionsTableData: [],
    oscesTableData: [],
    removePagination: false,
    statToShow: "both",
    isAdmin: false,
    submitting: false,
  };
  componentDidMount() {
    const subjects = getSubjects();
    const osceSubjects = getOsceSubjects();
    this.setState({ subjects });
    // console.log(subjects);
    const questionsTableData = [];
    const oscesTableData = [];

    // const userId = localStorage.getItem("currentUserId");

    firebase.auth().onAuthStateChanged((user) => {
      const userEmail = user.email;
      if (userEmail) {
        this.setState({ signedIn: true });
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            // console.log(idTokenResult.claims)
            // Confirm the user is an Admin.
            if (
              idTokenResult.claims.admin &&
              idTokenResult.claims.admin === true
            ) {
              // console.log("an admin")
              this.setState({ isAdmin: true });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });

    // firebase
    //   .firestore()
    //   .collection("users")
    //   .doc(userId)
    //   .onSnapshot((doc) => {

    //   });

    const getAllStatistics = firebase
      .firestore()
      .collection("statistics")
      .doc("all")
      .get()
      .then((doc) => {
        // console.log(doc.data());
        const statistics = doc.data();
        const subjects = doc.data().subjects;
        const osceTypes = doc.data().osceTypes;
        const general = doc.data().general;
        for (let i = 0; i < subjects.length; i++) {
          for (let x = 0; x < subjects[i].subdivisions.length; x++) {
            for (
              let y = 0;
              y < subjects[i].subdivisions[x].topics.length;
              y++
            ) {
              if (x === 0 && y === 0) {
                questionsTableData.push({
                  subject: `${subjects[i].name}(${subjects[i].noOfQuestions})`,
                  system: `${subjects[i].subdivisions[x].name}(${subjects[i].subdivisions[x].noOfQuestions})`,
                  topic: subjects[i].subdivisions[x].topics[y].name,
                  done: subjects[i].subdivisions[x].topics[y].noOfQuestions,
                  toBeDone:
                    subjects[i].subdivisions[x].topics[y].noOfQuestions < 10
                      ? 10 - subjects[i].subdivisions[x].topics[y].noOfQuestions
                      : 0,
                });
              } else if (y === 0) {
                questionsTableData.push({
                  subject: "",
                  system: `${subjects[i].subdivisions[x].name}(${subjects[i].subdivisions[x].noOfQuestions})`,
                  topic: subjects[i].subdivisions[x].topics[y].name,
                  done: subjects[i].subdivisions[x].topics[y].noOfQuestions,
                  toBeDone:
                    subjects[i].subdivisions[x].topics[y].noOfQuestions < 10
                      ? 10 - subjects[i].subdivisions[x].topics[y].noOfQuestions
                      : 0,
                });
              } else {
                questionsTableData.push({
                  subject: "",
                  system: "",
                  topic: subjects[i].subdivisions[x].topics[y].name,
                  done: subjects[i].subdivisions[x].topics[y].noOfQuestions,
                  toBeDone:
                    subjects[i].subdivisions[x].topics[y].noOfQuestions < 10
                      ? 10 - subjects[i].subdivisions[x].topics[y].noOfQuestions
                      : 0,
                });
              }
            }
          }
        }

        for (let i = 0; i < osceSubjects.length + 1; i++) {
          const subjectData = {};

          if (i === osceSubjects.length) {
            for (let x = 0; x < osceTypes.length; x++) {
              if (i === osceSubjects.length) {
                subjectData.subject = "Total";
                subjectData[osceTypes[x].id] = osceTypes[x].noOfOsces;
                subjectData.total = general.osces;
                if (x === osceTypes.length - 1) {
                  oscesTableData.push(subjectData);
                }
              }
            }
          } else {
            for (let x = 0; x < osceTypes.length; x++) {
              const subjectId = osceSubjects[i].id;
              const subject = osceTypes[x].subjects.filter(
                (subject) => subject.id === subjectId
              )[0];
              subjectData.subject = subject.name;
              subjectData[osceTypes[x].id] = subject.noOfOsces;
              subjectData.total = subjects.filter(
                (subject) => subject.id === subjectId
              )[0].noOfOsces;
              if (x === osceTypes.length - 1) {
                oscesTableData.push(subjectData);
              }
            }
          }
        }

        this.setState({ subjects: statistics.subjects });
      });

    Promise.all([getAllStatistics]).then(() => {
      this.setState({ questionsTableData, oscesTableData });
    });
  }

  questionsTableColumns = [
    {
      name: "Subject",
      selector: (row) => row.subject,
      // sortable: true,
    },
    {
      name: "System",
      selector: (row) => row.system,
      // sortable: true,
    },
    {
      name: "Topic",
      selector: (row) => row.topic,
      // sortable: true,
    },
    {
      name: "Done",
      selector: (row) => row.done,
      sortable: true,
    },
    {
      name: "To Be Done",
      selector: (row) => row.toBeDone,
      sortable: true,
    },
  ];

  oscesTableColumns = [
    {
      name: "Subject",
      selector: (row) => row.subject,
      // sortable: true,
    },
    {
      name: "History",
      selector: (row) => row.history,
      sortable: true,
    },
    {
      name: "Examination",
      selector: (row) => row.examination,
      sortable: true,
    },
    {
      name: "Viva & Others",
      selector: (row) => row.summary,
      sortable: true,
    },
    {
      name: "Total",
      selector: (row) => row.total,
      sortable: true,
    },
  ];

  updateStatistics = () => {
    const subjects = getSubjects();
    console.log(subjects);
    let all = {};
    all.general = {
      flashcards: 0,
      osces: 0,
      questions: 0,
      subjects: 0,
      threeDModels: 0,
      topics: 0,
    };

    this.setState({ submitting: true });

    all.osceTypes = [
      {
        noOfOsces: 0,
        id: "examination",
        name: "Examination",
        subjects: [
          {
            noOfOsces: 0,
            noOfUsers: 0,
            id: "medicine",
            level: "clinical",
            name: "Medicine",
          },
          {
            noOfOsces: 0,
            noOfUsers: 0,
            id: "obstetrics_and_gynecology",
            level: "clinical",
            name: "Obstetrics & Gynecology",
          },
          {
            noOfOsces: 0,
            noOfUsers: 0,
            id: "pediatrics",
            level: "clinical",
            name: "Pediatrics",
          },
          {
            noOfOsces: 0,
            noOfUsers: 0,
            id: "surgery",
            level: "clinical",
            name: "Surgery",
          },
        ],
      },
      {
        noOfOsces: 0,
        id: "history",
        name: "History",
        subjects: [
          {
            noOfOsces: 0,
            noOfUsers: 0,
            id: "medicine",
            level: "clinical",
            name: "Medicine",
          },
          {
            noOfOsces: 0,
            noOfUsers: 0,
            id: "obstetrics_and_gynecology",
            level: "clinical",
            name: "Obstetrics & Gynecology",
          },
          {
            noOfOsces: 0,
            noOfUsers: 0,
            id: "pediatrics",
            level: "clinical",
            name: "Pediatrics",
          },
          {
            noOfOsces: 0,
            noOfUsers: 0,
            id: "surgery",
            level: "clinical",
            name: "Surgery",
          },
        ],
      },
      {
        noOfOsces: 0,
        id: "summary",
        name: "Topic Summary",
        subjects: [
          {
            noOfOsces: 0,
            noOfUsers: 0,
            id: "medicine",
            level: "clinical",
            name: "Medicine",
          },
          {
            noOfOsces: 0,
            noOfUsers: 0,
            id: "obstetrics_and_gynecology",
            level: "clinical",
            name: "Obstetrics & Gynecology",
          },
          {
            noOfOsces: 0,
            noOfUsers: 0,
            id: "pediatrics",
            level: "clinical",
            name: "Pediatrics",
          },
          {
            noOfOsces: 0,
            noOfUsers: 0,
            id: "surgery",
            level: "clinical",
            name: "Surgery",
          },
        ],
      },
    ];

    all.subjects = subjects;

    let getGeneralStats = {};
    let getOsceTypesStats = {};
    let getOsceSubjectsStats = {};
    let getSubjectStats = {};
    let getSystemStats = {};
    let getTopicstats = {};

    getGeneralStats = firebase
      .firestore()
      .collection("statistics")
      .doc("general")
      .get()
      .then((doc) => {
        const oldgeneral = doc.data();
        all.general = oldgeneral;
      })
      .catch((error) => {
        console.log(error.message);
      });

    const osceTypes = all.osceTypes;
    for (let i = 0; i < osceTypes.length; i++) {
      const osceTypeId = osceTypes[i].id;
      getOsceTypesStats = firebase
        .firestore()
        .collection("statistics/osceTypes/osceTypeData")
        .doc(osceTypeId)
        .get()
        .then((doc) => {
          const oldOsceType = doc.data();
          const osceType = {
            ...all.osceTypes[i],
            noOfOsces: oldOsceType.noOfOsces,
          };
          all.osceTypes[i] = osceType;
        })
        .catch((error) => {
          console.log(error.message);
        });
    }

    for (let i = 0; i < osceTypes.length; i++) {
      const osceTypeId = osceTypes[i].id;
      // console.log(osceTypeId);
      for (let x = 0; x < osceTypes[i].subjects.length; x++) {
        const subjectId = osceTypes[i].subjects[x].id;
        // console.log(subjectId);
        getOsceSubjectsStats = firebase
          .firestore()
          .collection(
            `statistics/osceTypes/osceTypeData/${osceTypeId}/subjects`
          )
          .doc(subjectId)
          .get()
          .then((doc) => {
            const oldSubject = doc.data();
            // console.log(oldSubject);
            const subject = {
              ...all.osceTypes[i].subjects[x],
            };
            subject.noOfOsces = oldSubject.noOfOsces;
            subject.noOfUsers = oldSubject.noOfUsers;
            all.osceTypes[i].subjects[x] = subject;
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    }

    for (let i = 0; i < subjects.length; i++) {
      const subjectId = subjects[i].id;
      getSubjectStats = firebase
        .firestore()
        .collection(`statistics/subjects/subjectData`)
        .doc(subjectId)
        .get()
        .then((doc) => {
          const oldSubject = doc.data();
          const subject = {
            ...all.subjects[i],
            noOfOsces: oldSubject.noOfOsces,
            noOfFlashcards: oldSubject.noOfFlashcards,
            noOfQuestions: oldSubject.noOfQuestions,
            noOf3DModels: oldSubject.noOf3DModels,
            noOfUsers: oldSubject.noOfUsers,
          };
          all.subjects[i] = subject;
        })
        .catch((error) => {
          console.log(error.message);
        });
    }

    for (let i = 0; i < subjects.length; i++) {
      const subjectId = subjects[i].id;
      for (let x = 0; x < subjects[i].subdivisions.length; x++) {
        const systemId = subjects[i].subdivisions[x].id;
        getSystemStats = firebase
          .firestore()
          .collection(`statistics/subjects/subjectData/${subjectId}/systems`)
          .doc(systemId)
          .get()
          .then((doc) => {
            const oldSystem = doc.data();
            const system = {
              ...all.subjects[i].subdivisions[x],
              noOfOsces: oldSystem.noOfOsces,
              noOfFlashcards: oldSystem.noOfFlashcards,
              noOfQuestions: oldSystem.noOfQuestions,
              noOf3DModels: oldSystem.noOf3DModels,
              noOfUsers: oldSystem.noOfUsers,
            };
            all.subjects[i].subdivisions[x] = system;
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    }

    for (let i = 0; i < subjects.length; i++) {
      const subjectId = subjects[i].id;
      for (let x = 0; x < subjects[i].subdivisions.length; x++) {
        const systemId = subjects[i].subdivisions[x].id;
        for (let y = 0; y < subjects[i].subdivisions[x].topics.length; y++) {
          const topicId = subjects[i].subdivisions[x].topics[y].id;

          getTopicstats = firebase
            .firestore()
            .collection(
              `statistics/subjects/subjectData/${subjectId}/systems/${systemId}/topics`
            )
            .doc(topicId)
            .get()
            .then((doc) => {
              const oldTopic = doc.data();
              const topic = {
                ...all.subjects[i].subdivisions[x].topics[y],
                noOfOsces: oldTopic.noOfOsces,
                noOfFlashcards: oldTopic.noOfFlashcards,
                noOfQuestions: oldTopic.noOfQuestions,
                noOf3DModels: oldTopic.noOf3DModels,
                noOfUsers: oldTopic.noOfUsers,
              };
              all.subjects[i].subdivisions[x].topics[y] = topic;
            })
            .catch((error) => {
              console.log(error.message);
            });
        }
      }
    }

    Promise.all([
      getGeneralStats,
      getOsceTypesStats,
      getOsceSubjectsStats,
      getSubjectStats,
      getSystemStats,
      getTopicstats,
    ]).then(() => {
      // console.log(all);
      firebase
        .firestore()
        .collection("statistics")
        .doc("all")
        .set(all)
        .then(() => {
          toast.success("Successfully updated the statistics");
          this.setState({ submitting: false });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  doRemovePagination = () => {
    const { removePagination } = this.state;
    this.setState({ removePagination: !removePagination });
  };

  showParticularStats = (type) => {
    this.setState({ statToShow: type });
  };

  render() {
    const {
      questionsTableData,
      removePagination,
      oscesTableData,
      statToShow,
      isAdmin,
      signedIn,
      submitting,
    } = this.state;
    return (
      <div>
        <Helmet>
          <title>Content Statistics - Acabest</title>
          <meta name="description" content="Admin Access Only." />
        </Helmet>
        {submitting ? (
          <PreLoader />
        ) : (
          <div>
            {signedIn && isAdmin && (
              <div>
                <Grid container>
                  <Grid item style={{ margin: "20px auto" }}>
                    <Button
                      variant="contained"
                      onClick={this.doRemovePagination}
                      size="small"
                      style={{
                        margin: "auto 10px",
                        color: "#fff",
                        backgroundColor: "#005c80",
                      }}
                    >
                      {removePagination
                        ? "Add Pagination"
                        : "Remove Pagination"}
                    </Button>
                    <ReactToPrint
                      trigger={() => {
                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                        // to the root node of the returned component as it will be overwritten.
                        return (
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{
                              margin: "auto 10px",
                              color: "#fff",
                              backgroundColor: "#008000",
                            }}
                          >
                            Print Stats
                          </Button>
                        );
                      }}
                      content={() => this.componentRef}
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={this.updateStatistics}
                      size="small"
                      style={{
                        margin: "10px 10px",
                      }}
                    >
                      Update Statistics
                    </Button>
                  </Grid>
                  <Grid item style={{ margin: "10px auto" }}>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      style={{ margin: "auto 10px" }}
                      onClick={() => this.showParticularStats("questions")}
                    >
                      MCQ Stats
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      style={{ margin: "auto 10px" }}
                      onClick={() => this.showParticularStats("osce")}
                    >
                      OSCE Stats
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      style={{ margin: "auto 10px" }}
                      onClick={() => this.showParticularStats("both")}
                    >
                      Show Both
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  style={{ padding: "20px", backgroundColor: "#eeebeb" }}
                  ref={(el) => (this.componentRef = el)}
                >
                  {(statToShow === "both" || statToShow === "questions") && (
                    <Grid
                      style={{
                        boxShadow:
                          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                      }}
                      id="questions-table"
                    >
                      {questionsTableData.length > 0 && !removePagination && (
                        <DataTable
                          columns={this.questionsTableColumns}
                          data={questionsTableData}
                          pagination
                        />
                      )}
                      {questionsTableData.length > 0 && removePagination && (
                        <DataTable
                          columns={this.questionsTableColumns}
                          data={questionsTableData}
                        />
                      )}
                    </Grid>
                  )}
                  <br />
                  <br />
                  {(statToShow === "both" || statToShow === "osce") && (
                    <Grid
                      style={{
                        boxShadow:
                          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                      }}
                      id="osces-table"
                    >
                      {oscesTableData.length > 0 && !removePagination && (
                        <DataTable
                          columns={this.oscesTableColumns}
                          data={oscesTableData}
                          pagination
                        />
                      )}
                      {oscesTableData.length > 0 && removePagination && (
                        <DataTable
                          columns={this.oscesTableColumns}
                          data={oscesTableData}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
              </div>
            )}
            {signedIn && !isAdmin && (
              <div>
                <Grid container style={{ margin: "30px" }}>
                  Sorry You do not have permission to access this page.
                </Grid>
              </div>
            )}
            {!signedIn && (
              <div style={{ margin: "30px" }}>
                <Typography variant="body1">
                  Please{" "}
                  <Button
                    component={Link}
                    variant="contained"
                    color="primary"
                    to="/login"
                  >
                    Sign In
                  </Button>{" "}
                  to Continue.
                </Typography>
                <Typography variant="caption">
                  If you are alredy signed in, then simply refresh the page
                </Typography>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Statistics;
