import React from "react";
import PreLoader from "./common/preLoader";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Form from "./common/form";
import Container from "@material-ui/core/Container";
import Joi from "joi-browser";
import { Divider } from "@material-ui/core";
import { toast } from "react-toastify";
import firebase from "firebase/app";
import {
  getSubdivisions,
  getSubjects,
  getTopics,
  getTopicTitle,
} from "../utils/getCategories";
import { CardContent, Card, CardActions } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class SpacedRepetition extends Form {
  state = {
    submitting: false,
    data: {
      subject: "",
      subdivision: "",
      topic: "",
    },
    errors: {},
    questions: [],
    user: {},
    showAddTopic: false,
    repetitionTopics: [],
    grantAccess: false,
    makeGoPremium: false,
  };

  componentDidMount() {
    const userId = localStorage.getItem("currentUserId");
    const statistics = JSON.parse(localStorage.getItem("statistics"));

    // GETTING THE SUBJECTS LIST
    const subjects = getSubjects(statistics.subjects);
    const findSubjectsWithRelevantContent = subjects.filter(
      (subject) => subject.noOfQuestions > 0
    );
    const subjectsWithRelevantContent = getSubjects(
      findSubjectsWithRelevantContent
    );

    this.setState({ subjects, subjectsWithRelevantContent });

    if (userId) {
      firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .onSnapshot((doc) => {
          // console.log(doc.data().seenQuestions);
          const repetitionTopics = doc.data().repetitionTopics;
          this.setState({ user: doc.data(), repetitionTopics });
        });
    }

    let hasAccesLevel = false;

    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.email) {
        //FOR CHECKING CUSTOM STATES
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            // console.log(idTokenResult.claims)
            if (
              idTokenResult.claims.admin &&
              idTokenResult.claims.admin === true
            ) {
              hasAccesLevel = true;
            }
            if (
              idTokenResult.claims.editor &&
              idTokenResult.claims.editor.length > 0
            ) {
              const features = idTokenResult.claims.editor;
              // console.log(features)
              if (features.includes("automatedRevision")) {
                hasAccesLevel = true;
              }
            }

            if (userId) {
              firebase
                .firestore()
                .collection("premiumUsers")
                .doc(user.email)
                .onSnapshot((doc) => {
                  // console.log(doc.data());
                  const premiumUser = doc.data();

                  if (
                    (premiumUser &&
                      premiumUser.email.length > 3 &&
                      premiumUser.subscription.type === "basic") ||
                    hasAccesLevel
                  ) {
                    this.setState({ grantAccess: true });
                  } else {
                    this.setState({ grantAccess: false });
                  }
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }

  schema = {
    subject: Joi.string().required().label("Subject"),
    subdivision: Joi.string().required().label("System/Subdivision"),
    topic: Joi.string().required().label("Topic"),
  };

  systems = () => {
    const { subject } = this.state.data;
    const { subjectsWithRelevantContent } = this.state;

    const subjectData = subjectsWithRelevantContent.filter(
      (relevantSubject) => relevantSubject.id === subject
    )[0];
    let relevantSubdivisions = [];
    for (let i = 0; i < subjectData.subdivisions.length; i++) {
      const relevantTopics = subjectData.subdivisions[i].topics.filter(
        (theTopic) => theTopic.noOfQuestions > 4
      );
      console.log(relevantTopics);
      if (relevantTopics.length > 0) {
        relevantSubdivisions.push(subjectData.subdivisions[i]);
      }
    }
    subjectData.subdivisions = relevantSubdivisions;
    return getSubdivisions([subject], [subjectData]);
  };

  topics = () => {
    const { subject, subdivision } = this.state.data;
    const { subjectsWithRelevantContent } = this.state;

    let subjectData = subjectsWithRelevantContent.filter(
      (relevantSubject) => relevantSubject.id === subject
    )[0];
    let subdivisionData = subjectData.subdivisions.filter(
      (theSubdivision) => theSubdivision.id === subdivision
    )[0] || { topics: [] };
    const relevantTopics = subdivisionData.topics.filter(
      (theTopic) => theTopic.noOfQuestions > 4
    );
    subdivisionData.topics = relevantTopics;
    const newSubjectData = {
      ...subjectData,
      subdivisions: [subdivisionData],
    };

    return getTopics(subject, subdivision, [newSubjectData]);
  };

  resetThings = () => {
    window.location.reload();
  };

  findQuestions = (event) => {
    event.preventDefault();
    // console.log("finding repetition questions");

    const { user, grantAccess, subjects } = this.state;
    // console.log(user);

    // if (!user) {
    //   toast.error("Please Login to Access this feature");
    //   this.setState({ showSignIn: true });
    //   return;
    // }

    firebase.auth().onAuthStateChanged((authUser) => {
      if (!authUser) {
        toast.error("Please Login to continue");
        this.setState({ showSignIn: true });
        // console.log("user not signed in");
        return;
      } else if (
        (authUser && authUser.email && !user.userSubjects) ||
        (authUser &&
          authUser.email &&
          user.userSubjects &&
          !user.userSubjects.length > 0)
      ) {
        toast.warn(
          "Please go to the dashboard to complete your account to continue"
        );
        this.setState({ forceAccountComplete: true });
        return;
      } else {
        if (
          !grantAccess &&
          user.seenQuestions &&
          user.seenQuestions.length > 49
        ) {
          toast.warning(
            "Sorry! You have reached your free questions limit. Get a PREMIUM Account to continue."
          );
          this.setState({ makeGoPremium: true });
          return;
        }

        const { subject, subdivision, topic } = this.state.data;
        let questions = [...this.state.questions];
        const currentUserId = localStorage.getItem("currentUserId");

        //Launch the preloader while submitting
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });

        firebase
          .firestore()
          .collection("questions")
          .where("subject", "==", subject)
          .where("system", "==", subdivision)
          .where("topic", "==", topic)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const question = doc.data();
              questions.push(question);
            });
            //   if(user.seenQuestions) {
            //     // console.log(user.seenQuestions)
            //     const userSeenQues = user.seenQuestions
            //     for(let i=0; i<questions.length; i++) {
            //       if(userSeenQues.includes(questions[i].id)) {
            //         toBeSpliced.push(questions[i]);
            //       }

            //     }
            //   }

            if (questions.length < 1) {
              toast.error(
                "Sorry there isn't enough questions to apply Automated Revision for this topic"
              );
              const submitting = this.state.submitting;
              this.setState({ submitting: !submitting });
              return;
            }

            const noOfQuestionsForNow = Math.floor(questions.length * 0.4);
            const questionsForNow = questions.slice(0, noOfQuestionsForNow);

            if (user.repetitionTopics) {
              const currentTopic = user.repetitionTopics.filter(
                (repTopic) => repTopic.id === topic
              )[0];
              if (!currentTopic) {
                const repetitionTopics = user.repetitionTopics;
                repetitionTopics.push({ id: topic, progress: 0 });
                // console.log(repetitionTopics);

                // this.setState({foundQuestions: true, questions, })
                for (let i = 0; i < questionsForNow.length; i++) {
                  questionsForNow[i].queNo = i + 1;
                }
                const quizTime = questionsForNow.length * 1.2;
                const quizPack = {
                  title: getTopicTitle(topic),
                  topic: topic,
                  quizId: "spacedRepetition",
                  quizTime: quizTime,
                  questions: questionsForNow,
                  currentTopic: {
                    id: topic,
                    progress: 0,
                    subject: subject,
                    subdivision: subdivision,
                    quesFrom: noOfQuestionsForNow,
                  },
                };
                localStorage.setItem(
                  "currentSpacedRepetition",
                  JSON.stringify(quizPack)
                );
                const submitting = this.state.submitting;
                this.setState({ submitting: !submitting });

                window.location = `/quiz-preview/spaced-repetition`;

                // firebase.firestore().collection("users").doc(currentUserId).update({repetitionTopics: repetitionTopics})
                // .then(() => {

                // }).catch(err => {
                //     console.log(err.message);
                //     return;
                // })
              } else {
                toast.error("This topic has already being added!");
                const submitting = this.state.submitting;
                this.setState({ submitting: !submitting });

                return;
              }
            } else {
              const repetitionTopics = [{ id: topic, progress: 0 }];

              firebase
                .firestore()
                .collection("users")
                .doc(currentUserId)
                .update({ repetitionTopics: repetitionTopics })
                .then(() => {
                  // console.log("update successful");
                  this.setState({ foundQuestions: true, questions });
                  for (let i = 0; i < questions.length; i++) {
                    questions[i].queNo = i + 1;
                  }
                  const quizTime = questions.length * 1.2;
                  const quizPack = {
                    title: topic,
                    topic: topic,
                    quizId: "spacedRepetition",
                    quizTime: quizTime,
                    questions: questions,
                    currentTopic: {
                      id: topic,
                      progress: 0,
                      subject: subject,
                      subdivision: subdivision,
                      quesFrom: noOfQuestionsForNow,
                    },
                  };
                  localStorage.setItem(
                    "currentSpacedRepetition",
                    JSON.stringify(quizPack)
                  );
                  const submitting = this.state.submitting;
                  this.setState({ submitting: !submitting });

                  window.location = `/quiz-preview/spaced-repetition`;
                })
                .catch((err) => {
                  console.log(err.message);
                  return;
                });
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    });
  };

  renderDueTopic = () => {
    const { user, subjects } = this.state;

    if (user && user.repetitionTopics) {
      // console.log(user.repetitionTopics);
      const userTopics = user.repetitionTopics;

      const dueCards = userTopics.filter(
        (card) => card.dueDate < Date.now() && card.progress < 3
      );
      if (dueCards.length < 1) {
        return (
          <Grid item style={{ margin: "0 auto" }}>
            You have no topics due for revision and quizing
          </Grid>
        );
      }
      return dueCards.map((card) => (
        <Grid
          item
          xs={7}
          sm={5}
          lg={3}
          key={card.id}
          style={{ margin: "0 auto" }}
        >
          <Card variant="outlined" style={{ margin: "20px" }}>
            <CardContent>
              <Typography variant="body1">
                {getTopicTitle(card.id, getSubjects())}
              </Typography>
              <Divider /> <br />
              <Typography variant="body1">
                Last score: {card.lastScore}%
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => this.retakeQuiz(card)}
                size="small"
                variant="outlined"
                color="primary"
              >
                Retake Quiz
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ));
    }
  };

  showAddTopic = () => {
    const { showAddTopic } = this.state;
    this.setState({ showAddTopic: !showAddTopic });
  };

  retakeQuiz = (card) => {
    const { subjects } = this.state;
    let questions = [...this.state.questions];
    // const currentUserId = localStorage.getItem("currentUserId");

    // Launch the preloader
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    firebase
      .firestore()
      .collection("questions")
      .where("subject", "==", card.subject)
      .where("system", "==", card.subdivision)
      .where("topic", "==", card.id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const question = doc.data();
          questions.push(question);
        });

        let noOfQuestionsSoFar;
        let questionsForNow;
        if (card.progress === 1) {
          noOfQuestionsSoFar = Math.floor(questions.length * 0.8);
          questionsForNow = questions.slice(card.quesFrom, noOfQuestionsSoFar);
        } else if (card.progress === 2) {
          questionsForNow = questions;
        } else {
          toast.error("Something went wrong");
        }

        // console.log("questions for now", questionsForNow);

        for (let i = 0; i < questionsForNow.length; i++) {
          questionsForNow[i].queNo = i + 1;
        }
        const quizTime = questionsForNow.length * 1.2;
        const quizPack = {
          title: getTopicTitle(card.id),
          topic: card.id,
          quizId: "spacedRepetition",
          quizTime: quizTime,
          questions: questionsForNow,
          currentTopic: {
            id: card.id,
            progress: card.progress,
            subject: card.subject,
            subdivision: card.subdivision,
            quesFrom: noOfQuestionsSoFar,
          },
        };
        // console.log(quizPack)
        localStorage.setItem(
          "currentSpacedRepetition",
          JSON.stringify(quizPack)
        );
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });

        window.location = `/quiz-preview/spaced-repetition`;
      });
  };

  render() {
    const {
      submitting,
      data,
      repetitionTopics,
      showAddTopic,
      showSignIn,
      forceAccountComplete,
      makeGoPremium,
      subjectsWithRelevantContent,
    } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Automated Revision - Acabest</title>
        </Helmet>

        <br />
        <Typography variant="h4" component="h1" style={{ textAlign: "center" }}>
          Automated Revision
        </Typography>
        <br />

        {submitting ? (
          <PreLoader />
        ) : (
          <div>
            <div className="spaced-rep-text">
              <p>
                This tool automatically reschedules a topic for revision based
                on your performance in the quiz. Helps you to retain what you
                learn better.
                {/* <a href="#https://google.com" style={{ color: "blue" }}> Click Here</a> to learn more or  */}{" "}
                Add a topic to continue.
              </p>
              <p>Note: This tool is only available for high-yield topics</p>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={this.showAddTopic}
              >
                Add New Topic
              </Button>
              {showSignIn && (
                <div style={{ margin: "30px" }}>
                  <Typography variant="body1">
                    Please{" "}
                    <Button
                      component={Link}
                      variant="contained"
                      color="secondary"
                      to="/login/spaced-repetition"
                    >
                      Sign In
                    </Button>{" "}
                    to Continue.
                  </Typography>
                  <Typography variant="caption">
                    If you are alredy signed in, then simply refresh the page
                  </Typography>
                </div>
              )}
              {forceAccountComplete && (
                <div style={{ margin: "30px", textAlign: "center" }}>
                  <Typography variant="body1">
                    Please{" "}
                    <Button
                      component={Link}
                      variant="contained"
                      color="secondary"
                      to="/dashboard"
                    >
                      Go to Dashboard
                    </Button>{" "}
                    to complete your account.
                  </Typography>
                </div>
              )}
              {makeGoPremium && (
                <div>
                  <br />
                  <Typography>Get a premium account to continue!</Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to={`/pricing`}
                  >
                    Go Premium
                  </Button>
                  <br />
                  <br />
                </div>
              )}
            </div>

            {showAddTopic && (
              <Container
                component="main"
                maxWidth="sm"
                style={{
                  margin: "30px auto",
                  width: "95%",
                  background:
                    "linear-gradient(225deg, rgb(63 81 181 / 86%), rgb(0 123 255 / 12%) 70.71%)",
                  boxShadow:
                    "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                  padding: "4rem 2rem",
                  borderRadius: "10px",
                }}
              >
                <div style={{ float: "right", margin: "2px 10px" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={this.resetThings}
                  >
                    Reset
                  </Button>
                </div>
                <div>
                  <Grid
                    justifyContent="center"
                    alignItems="center"
                    item
                    container
                  >
                    <Typography
                      component="h1"
                      variant="h5"
                      style={{ marginLeft: "10px" }}
                    >
                      Select Topic
                    </Typography>
                  </Grid>
                  <form onSubmit={this.findQuestions}>
                    {/* {this.renderSelect(
                                "school",
                                "Select School",
                                this.schools
                            )} */}
                    {this.renderSelect(
                      "subject",
                      "Select Subject",
                      subjectsWithRelevantContent
                    )}
                    {data.subject &&
                      this.renderSelect(
                        "subdivision",
                        "Select System/Subdivision",
                        this.systems()
                      )}
                    {data.subject &&
                      data.subdivision &&
                      this.renderSelect("topic", "Select Topic", this.topics())}

                    {this.renderSubmitButton("Go")}
                  </form>
                </div>
              </Container>
            )}

            <br />
            <br />
            <Grid item id="due-for-revising" style={{ margin: "30px 10px" }}>
              <Grid style={{ textAlign: "center" }}>
                <Typography variant="h6">
                  Topics Due for Revision and Quizing
                </Typography>
              </Grid>
              <Grid container>{repetitionTopics && this.renderDueTopic()}</Grid>
            </Grid>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default SpacedRepetition;
