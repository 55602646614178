import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Button from "@material-ui/core/Button";
import firebase from "firebase/app";
import { toast } from "react-toastify";
import {
  getSubdivisions,
  getSubjects,
  getTopics,
} from "../utils/getCategories";
import PreLoader from "./common/preLoader";
import { Helmet } from "react-helmet";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Divider } from "@material-ui/core";
import levenshtein from "js-levenshtein";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReactHtmlParser from "react-html-parser";
// import { getOsceSubjects, getOsceTypes } from "../utils/getCategories";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

class QuizUpload extends Form {
  state = {
    data: {
      subject: "",
      subdivision: "",
      topic: "",
    },
    errors: {},
    showAddQuestions: false,
    quesData: {
      question: "",
      optionA: "",
      optionB: "",
      optionC: "",
      optionD: "",
      optionE: "",
      answer: "",
      answerExplanation: "",
    },
    continueToQuestions: false,
    openRepeatedQuestion: false,
    repeatedQuestion: {},
    subjects: [],
  };

  schema = {
    subject: Joi.string().required().label("Subject"),
    subdivision: Joi.string().required().label("System/Subdivision"),
    topic: Joi.string().required().label("Topic"),
  };

  componentDidMount() {
    const tutor = JSON.parse(localStorage.getItem("currentUser"));
    const tuturName = `${tutor.lastName}, ${tutor.firstName}`;
    const tutorId = localStorage.getItem("currentUserId");

    const statistics = JSON.parse(localStorage.getItem("statistics"));

    // GETTING THE SUBJECTS LIST
    const subjects = getSubjects(statistics.subjects);
    const findSubjectsWithRelevantContent = subjects.filter(
      (subject) => subject.noOfQuestions > 0
    );
    const subjectsWithRelevantContent = getSubjects(
      findSubjectsWithRelevantContent
    );

    this.setState({ subjects, subjectsWithRelevantContent });

    const tutorObj = { name: tuturName, id: tutorId };
    this.setState({ tutor: tutorObj });
  }

  doSubmit = () => {
    const submitBtn = document.getElementById("submit-btn");
    const continueBtn = document.getElementById("continue-btn");
    const { subject, subdivision, topic } = this.state.data;

    submitBtn.style.display = "none";
    continueBtn.style.display = "block";

    firebase
      .firestore()
      .collection("questions")
      .where("subject", "==", subject)
      .where("system", "==", subdivision)
      .where("topic", "==", topic)
      .get()
      .then((querySnapshot) => {
        let allQuestionsOfSameTopic = [];
        querySnapshot.forEach((doc) => {
          const question = doc.data();
          allQuestionsOfSameTopic.push(question);
        });
        // console.log(allQuestionsOfSameTopic);
        this.setState({ allQuestionsOfSameTopic });
      })
      .catch((error) => {
        console.log(error);
      });

    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.email) {
        //FOR CHECKING CUSTOM STATES
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            console.log(idTokenResult.claims);
            if (
              idTokenResult.claims.admin &&
              idTokenResult.claims.admin === true
            ) {
              this.setState({ continueToQuestions: true });
            } else if (
              idTokenResult.claims.editor &&
              idTokenResult.claims.editor.length > 0
            ) {
              const features = idTokenResult.claims.editor;
              if (features.includes("questionbank")) {
                this.setState({ continueToQuestions: true });
              } else {
                toast.error("You do not have the permission to set Questions");
                continueBtn.style.display = "none";
                this.setState({
                  continueToQuestions: false,
                  resetThings: true,
                });

                return;
              }
            } else if (
              idTokenResult.claims.moderator &&
              idTokenResult.claims.moderator.features &&
              idTokenResult.claims.moderator.features.length > 0
            ) {
              const features = idTokenResult.claims.moderator.features;
              const subjects = idTokenResult.claims.moderator.subjects;
              // console.log(features)
              // console.log(subjects)

              if (
                features.includes("questionbank") &&
                subjects.includes(subject)
              ) {
                this.setState({ continueToQuestions: true });
              } else {
                toast.error(
                  "You do not have the permission to set Questions on this subject"
                );
                continueBtn.style.display = "none";
                this.setState({
                  continueToQuestions: false,
                  resetThings: true,
                });
                return;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });

    // JUST FOR GETTING THE SUBJECTS LIST TO THE DATABASE... TO BE REMOVED IMMEDIATELY AFTER
    // const subjects = getOsceSubjects();
    // const osceTypes = getOsceTypes();
    // console.log(osceTypes)
    // for(let x=0; x<osceTypes.length; x++) {
    //   // const systems = getSubdivisions([osceTypes[x].id]);
    //   // console.log(systems);

    //   for(let i=0; i<subjects.length; i++) {
    //     firebase.firestore().collection(`statistics/osceTypes/osceTypeData/${osceTypes[x].id}/subjects`).doc(subjects[i].id)
    //     .set({
    //       noOfOsces: 0,
    //       noOfUsers: 0,
    //     })
    //     .then(() => {
    //       console.log(subjects[x].id);
    //     }).catch(error => {
    //       console.log(error.message)
    //     })
    //   }

    //   }

    //JUST FOR GETTING THE TOPICS LIST TO THE DATABASE... TO BE REMOVED IMMEDIATELY AFTER
    // const subjects = getSubjects();
    // console.log(subjects)
    // for(let x=0; x<subjects.length; x++) {
    //   const systems = getSubdivisions([subjects[x].id]);
    //   console.log(systems);

    //   for(let i=0; i<systems.length; i++) {

    //     const topics = getTopics(subjects[x].id, systems[i].id)
    //     console.log(topics);

    //     for(let y=0; y<topics.length; y++) {
    //       firebase.firestore().collection(`statistics/subjects/subjectData/${subjects[x].id}/systems/${systems[i].id}/topics`).doc(topics[y].id)
    //       .set({
    //         noOfQuestions: 0,
    //         noOfOsces: 0,
    //         noOf3DModels: 0,
    //         noOfUsers: 0,
    //         noOfFlashcards: 0,
    //       })
    //       .then(() => {
    //         console.log(systems[x].id);
    //       }).catch(error => {
    //         console.log(error.message)
    //       })

    //     }

    //   }

    //   }
  };

  doQueAddSubmit = () => {
    const { allQuestionsOfSameTopic } = this.state;
    const { question } = this.state.quesData;

    const levenshteinScores = [];
    allQuestionsOfSameTopic.forEach((compareQuestion) => {
      // console.log("compare question", compareQuestion);
      // console.log("question", question);
      const score = levenshtein(compareQuestion.question, question);
      console.log("score", score);
      if (score < 30) {
        this.setState({ repeatedQuestion: compareQuestion });
        this.setState({ openRepeatedQuestion: true });
        return;
      }
      levenshteinScores.push(score);
    });
    console.log(levenshteinScores);

    this.contiueSubmittingQuestion();
  };

  contiueSubmittingQuestion = () => {
    const { data, quesData } = this.state;
    const subject = data.subject;
    const system = data.subdivision;
    const topic = data.topic;
    const queId = Date.now().toString();
    const tutor = this.state.tutor;

    const question = quesData.question;
    const optionA = quesData.optionA;
    const optionB = quesData.optionB;
    const optionC = quesData.optionC;
    const optionD = quesData.optionD;
    const optionE = quesData.optionE;
    const answerId = quesData.answer;
    const answerExplanation = quesData.answerExplanation;

    const itemsArray = [
      { content: question, name: "Question" },
      { content: optionA, name: "Option A" },
      { content: optionB, name: "option B" },
      { content: optionC, name: "option C" },
      { content: optionD, name: "option D" },
      { content: optionE, name: "option E" },
      { content: answerExplanation, name: "Answer Explanation" },
    ];

    if (this.checkForEmptySpaces(itemsArray) === "stop") {
      // console.log("there's a stop");
      return;
    }

    console.log(question);

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    let newQue = {
      question: question,
      options: [
        { content: optionA, id: "a" },
        { content: optionB, id: "b" },
        { content: optionC, id: "c" },
        { content: optionD, id: "d" },
        { content: optionE, id: "e" },
      ],
      answer: {
        explanation: answerExplanation,
        id: answerId,
      },
      subject: subject,
      system: system,
      topic: topic,
      id: queId,
      tutor: tutor,
    };

    const addQuestion = firebase
      .firestore()
      .collection("questions")
      .doc(queId)
      .set(newQue);

    const incrementSubjectStats = firebase
      .firestore()
      .collection(`statistics/subjects/subjectData`)
      .doc(subject)
      .update({
        noOfQuestions: firebase.firestore.FieldValue.increment(1),
      });
    const incrementSystemStats = firebase
      .firestore()
      .collection(`statistics/subjects/subjectData/${subject}/systems`)
      .doc(system)
      .update({
        noOfQuestions: firebase.firestore.FieldValue.increment(1),
      });
    const incrementTopicStats = firebase
      .firestore()
      .collection(
        `statistics/subjects/subjectData/${subject}/systems/${system}/topics`
      )
      .doc(topic)
      .update({
        noOfQuestions: firebase.firestore.FieldValue.increment(1),
      });
    const incrementGeneralStats = firebase
      .firestore()
      .collection(`statistics`)
      .doc("general")
      .update({
        questions: firebase.firestore.FieldValue.increment(1),
      });

    Promise.all([
      addQuestion,
      incrementSubjectStats,
      incrementSystemStats,
      incrementTopicStats,
      incrementGeneralStats,
    ])
      .then(() => {
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        toast.success("Question Added");
        this.setState({
          quesData: {
            question: "",
            optionA: "",
            optionB: "",
            optionC: "",
            optionD: "",
            optionE: "",
            answer: "",
            answerExplanation: "",
          },
        });
      })
      .catch((error) => {
        toast.error("Something went wrong!");
        console.log(error.message);
      });
  };

  handleAddQuesSubmit = (e) => {
    e.preventDefault();
    const { quesData } = this.state;
    const { question, optionA, optionB, answer, answerExplanation } = quesData;

    if (
      !answer ||
      question.length < 8 ||
      optionA.length < 8 ||
      optionB.length < 8 ||
      answerExplanation.length < 8
    ) {
      toast.error(
        "Question, Correct Answer, Option A, Option B and Answer Explanation must not be empty"
      );
      return;
    }

    this.doQueAddSubmit();
  };

  systems = () => {
    const { subject } = this.state.data;
    const { subjects } = this.state;

    const subjectData = subjects.filter(
      (relevantSubject) => relevantSubject.id === subject
    )[0];
    const subdivisions = subjectData.subdivisions;
    subjectData.subdivisions = subdivisions;
    return getSubdivisions([subject], [subjectData]);
  };
  topics = () => {
    const { subject, subdivision } = this.state.data;
    const { subjects } = this.state;

    let subjectData = subjects.filter(
      (relevantSubject) => relevantSubject.id === subject
    )[0];
    let subdivisionData = subjectData.subdivisions.filter(
      (theSubdivision) => theSubdivision.id === subdivision
    )[0] || { topics: [] };
    const topics = subdivisionData.topics;
    subdivisionData.topics = topics;
    const newSubjectData = {
      ...subjectData,
      subdivisions: [subdivisionData],
    };

    return getTopics(subject, subdivision, [newSubjectData]);
  };

  resetThings = () => {
    window.location.reload();
  };

  goToAddQuestions = (e) => {
    e.preventDefault();
    const submitBtn = document.getElementById("submit-btn");
    const continueBtn = document.getElementById("continue-btn");
    const { subject } = this.state.data;

    submitBtn.style.display = "none";
    continueBtn.style.display = "block";

    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.email) {
        //FOR CHECKING CUSTOM STATES
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            // console.log(idTokenResult.claims)
            if (
              idTokenResult.claims.admin &&
              idTokenResult.claims.admin === true
            ) {
              this.setState({
                showAddQuestions: true,
                continueToQuestions: true,
              });
              continueBtn.style.display = "none";
            } else if (
              idTokenResult.claims.editor &&
              idTokenResult.claims.editor.length > 0
            ) {
              const features = idTokenResult.claims.editor;
              if (features.includes("questionbank")) {
                this.setState({
                  showAddQuestions: true,
                  continueToQuestions: false,
                });
                continueBtn.style.display = "none";
              } else {
                toast.error("You do not have the permission to set Questions");
                continueBtn.style.display = "none";
                this.setState({
                  continueToQuestions: false,
                  resetThings: true,
                });

                return;
              }
            } else if (
              idTokenResult.claims.moderator &&
              idTokenResult.claims.moderator.features &&
              idTokenResult.claims.moderator.features.length > 0
            ) {
              const features = idTokenResult.claims.moderator.features;
              const subjects = idTokenResult.claims.moderator.subjects;
              // console.log(features)
              // console.log(subjects)

              if (
                features.includes("questionbank") &&
                subjects.includes(subject)
              ) {
                this.setState({
                  showAddQuestions: true,
                  continueToQuestions: false,
                });
                continueBtn.style.display = "none";
              } else {
                toast.error(
                  "You do not have the permission to set Questions on this subject"
                );
                continueBtn.style.display = "none";
                this.setState({
                  continueToQuestions: false,
                  resetThings: true,
                });
                return;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
    // this.setState({ showAddQuestions: true, continueToQuestions: false });
  };

  // for rendering the CKEditor inputs
  questionDetails = [
    { name: "Question", id: "question" },
    { name: "Option A", id: "optionA" },
    { name: "Option B", id: "optionB" },
    { name: "Option C", id: "optionC" },
    { name: "Option D", id: "optionD" },
    { name: "Option E", id: "optionE" },
    // {name: "Correct Answer", id: "answer"},
    // {name: "Answer Explanation", id: "answerExplanation"},
  ];

  answerOptions = [
    { name: "A" },
    { name: "B" },
    { name: "C" },
    { name: "D" },
    { name: "E" },
    // {name: "TT"},
    // {name: "FF"},
    // {name: "TF"},
    // {name: "FT"},
    // {name: "TTT"},
    // {name: "TTF"},
    // {name: "TFF"},
    // {name: "FFF"},
    // {name: "FTT"},
    // {name: "FFT"},
    // {name: "FFF"},
    // {name: "TFT"},
    // {name: "FTF"},
    { name: "TTTT" },
    { name: "TTTF" },
    { name: "TTFF" },
    { name: "TFFF" },
    { name: "FFFF" },
    { name: "FTTT" },
    { name: "FFTT" },
    { name: "FFFT" },
    { name: "FTFT" },
    { name: "TFTF" },
    { name: "FTFF" },
    { name: "FFTF" },
    { name: "FTTF" },
    { name: "TFFT" },
    { name: "TTTTT" },
    { name: "TTTTF" },
    { name: "TTTFT" },
    { name: "TTFTT" },
    { name: "TFTTT" },
    { name: "FTTTT" },
    { name: "TTTFF" },
    { name: "TTFFT" },
    { name: "TFFTT" },
    { name: "FFTTT" },
    { name: "TFTFT" },
    { name: "TFTTF" },
    { name: "FTFTT" },
    { name: "TTFTF" },
    { name: "TTFFF" },
    { name: "TFFFT" },
    { name: "FFFTT" },
    { name: "FTTFF" },
    { name: "FTTTF" },
    { name: "TFTFF" },
    { name: "FTFFT" },
    { name: "TFFTF" },
    { name: "TFFFF" },
    { name: "FFFFT" },
    { name: "FFTFF" },
    { name: "FTFFF" },
    { name: "FFFTF" },
    { name: "FFFFF" },
    // {name: ""},
  ];

  handleClose = (type) => {
    if (type === "repeated-question") {
      this.setState({ openRepeatedQuestion: false });
    }
  };

  checkForEmptySpaces = (itemsArray) => {
    const isThereAnEmptyLine = [];
    itemsArray.forEach((item) => {
      if (item.content.substring(3, 9) === "&nbsp;") {
        // console.log("Yes there's empty space at the start");
        toast.info(
          `Please remove the empty space/Line at the start of ${item.name}`
        );
        isThereAnEmptyLine.push(true);
      }
      if (
        item.content.substring(
          item.content.length - 10,
          item.content.length - 4
        ) === "&nbsp;"
      ) {
        // console.log("There's also emtpy space at the end");
        toast.info(
          `Please remove the empty space/Line at the end of ${item.name}`
        );
        isThereAnEmptyLine.push(true);
      }
    });
    if (isThereAnEmptyLine.includes(true)) {
      return "stop";
    } else {
      return "continue";
    }
  };

  render() {
    const classes = useStyles;
    const {
      showAddQuestions,
      submitting,
      continueToQuestions,
      data,
      resetThings,
      openRepeatedQuestion,
      repeatedQuestion,
      subjects,
    } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>Upload Quiz - Acabest</title>
        </Helmet>

        {submitting || !subjects.length > 0 ? (
          <PreLoader />
        ) : (
          <Container
            component="main"
            maxWidth="md"
            style={{
              margin: "30px auto",
              width: "95%",
              backgroundColor: "#fff",
              boxShadow:
                "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
              padding: "4rem 2rem",
              borderRadius: "10px",
            }}
          >
            <CssBaseline />
            <div className={classes.paper}>
              <Grid justifyContent="center" alignItems="center" item container>
                <Avatar
                  className={classes.avatar}
                  style={{ backgroundColor: "#f50057" }}
                >
                  <CloudUploadIcon />
                </Avatar>
                <br />
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ marginLeft: "10px" }}
                >
                  Upload Questions
                </Typography>
              </Grid>
              {!showAddQuestions && (
                <form className={classes.form} onSubmit={this.handleSubmit}>
                  {this.renderSelect("subject", "Select Subject", subjects)}
                  {data.subject &&
                    this.renderSelect(
                      "subdivision",
                      "Select System/Subdivision",
                      this.systems()
                    )}
                  {data.subject &&
                    data.subdivision &&
                    this.renderSelect("topic", "Select Topic", this.topics())}
                  {this.renderSubmitButton("Submit")}
                </form>
              )}
              {showAddQuestions && (
                <form className={classes.form}>
                  <br />
                  {this.questionDetails.map((questionDetail) => (
                    <div
                      key={questionDetail.id}
                      style={{
                        border: "1px solid #969aa2",
                        marginBottom: "20px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      <p style={{ padding: "0 10px", margin: "0" }}>
                        {questionDetail.name}
                      </p>
                      <Divider />
                      <CKEditor
                        editor={InlineEditor}
                        data={""}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          const quesData = { ...this.state.quesData };
                          quesData[questionDetail.id] = data;
                          this.setState({ quesData });
                        }}
                      />
                    </div>
                  ))}
                  <Autocomplete
                    id="answer-options"
                    options={this.answerOptions}
                    getOptionLabel={(option) => option.name}
                    style={{ width: 300 }}
                    onChange={(event, newValue) => {
                      // console.log(newValue);
                      const quesData = { ...this.state.quesData };
                      quesData.answer = newValue.name.toLowerCase();
                      this.setState({ quesData });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Correct Answer"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  <br />
                  <div
                    style={{
                      border: "1px solid #969aa2",
                      marginBottom: "20px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    <p style={{ padding: "0 10px", margin: "0" }}>
                      Answer Explanation
                    </p>
                    <Divider />
                    <CKEditor
                      editor={InlineEditor}
                      data={""}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        const quesData = { ...this.state.quesData };
                        quesData.answerExplanation = data;
                        this.setState({ quesData });
                      }}
                    />
                  </div>
                  <br />

                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={this.handleAddQuesSubmit}
                  >
                    Submit
                  </Button>
                </form>
              )}
              <br />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                id="continue-btn"
                style={{ display: "none" }}
                onClick={this.goToAddQuestions}
                disabled={!continueToQuestions}
              >
                Continue
              </Button>
              {resetThings && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.resetThings}
                >
                  Reset
                </Button>
              )}
            </div>

            <div>
              <Dialog
                open={openRepeatedQuestion}
                onClose={() => this.handleClose("repeated-question")}
                aria-labelledby="repeated-question-dialog"
              >
                <DialogTitle id="repeated-question-dialog">
                  Warning!
                </DialogTitle>
                <DialogContent>
                  <div>
                    <Typography>
                      This question looks very similar to an already existing
                      question:
                    </Typography>
                    <br />
                    {repeatedQuestion.question && (
                      <div>
                        <div>{ReactHtmlParser(repeatedQuestion.question)}</div>
                        <div>
                          {repeatedQuestion.options.map(
                            (option) =>
                              option.content && (
                                <div
                                  key={option.id}
                                  style={{ display: "flex" }}
                                >
                                  <strong>{option.id.toUpperCase()}.</strong>{" "}
                                  &nbsp;
                                  {ReactHtmlParser(option.content)}
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    )}
                    <br />
                    <Typography>
                      Are you sure it's not the same question?
                    </Typography>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => this.handleClose("repeated-question")}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={this.contiueSubmittingQuestion}
                    color="primary"
                  >
                    Continue Submitting
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default QuizUpload;
