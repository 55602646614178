import React, { Component } from "react";
import HeaderPaper from "./common/headerPaper";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";

class PrivacyPolicy extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Privacy Policy - Acabest</title>
        </Helmet>

        <HeaderPaper headerTitle="Privacy Policy" />

        <Grid
          container
          item
          xs={9}
          lg={6}
          style={{ margin: "30px auto", textAlign: "justify" }}
        >
          <Typography>
            This Privacy Policy was updated on the 16th of February 2021.
          </Typography>
          <br />
          <br />

          <Typography>
            Dear user, this may seem like a long read, but it’s worth it. Thank
            you for joining Acabest. Your privacy is key, but we also need some
            data from you to run. This Privacy policy explains our data
            collection practices to you. By using any of the services provided
            by Acabest through the website and any other platforms, you consent
            to these policies.
          </Typography>
          <br />
          <br />

          <Typography>
            <br />
            <br />
            <strong>What data we collect.</strong>
            <br />
            <br />
          </Typography>

          <Typography>
            Data we collect include your Profile data such as your name, email
            address, phone number, the program you study. We also collect usage
            data as you use our pl atform. This allows us to improve our
            services for you. We use Google Analytics on our site. Google
            Analytics uses cookies to store data on your computer. We use Google
            Analytics to analyze how users use our platform. This allows us to
            make the content more relevant for you.
          </Typography>

          <br />
          <br />
          {/* <h4 style={{ textAlign: "center"}}>Contact Information</h4>
            <br/><br/><br/> */}
          <Typography>Contact Information: acabestmail@gmail.com</Typography>
          <Typography></Typography>

          {/* <p>Email: acabestmail@gmail.com</p> */}
        </Grid>
      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;
