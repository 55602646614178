import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Form from "./common/form";
import Joi from "joi-browser";
import Container from "@material-ui/core/Container";
import {
  getSubdivisions,
  getSubjects,
  getTopics,
} from "../utils/getCategories";
import { Helmet } from "react-helmet";

class ThreeDQuiz extends Form {
  state = {
    user: {},
    data: {
      subject: "",
      subdivision: "",
      topic: "",
    },
    errors: {},
  };

  schema = {
    subject: Joi.string().required().label("Subject"),
    subdivision: Joi.string().required().label("System/Subdivision"),
    topic: Joi.string().required().label("Topic"),
  };

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    this.setState({ user });
  }

  subjects = getSubjects();

  systems = () => {
    const { subject } = this.state.data;
    return getSubdivisions([subject]);
  };

  topics = () => {
    const { subject, subdivision } = this.state.data;
    return getTopics(subject, subdivision);
  };

  doSubmit = () => {
    const { subject, subdivision, topic } = this.state.data;
    window.location.assign(`/3d-playground/${subject}/${subdivision}/${topic}`);
  };

  render() {
    const { data } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>3D Anatomy - Acabest</title>
        </Helmet>

        <br />
        <Typography variant="h4" component="h1" style={{ textAlign: "center" }}>
          3D Anatomy Playground
        </Typography>

        <Container
          component="main"
          maxWidth="sm"
          style={{
            margin: "30px auto",
            width: "95%",
            background:
              "linear-gradient(225deg, rgb(63 81 181 / 86%), rgb(0 123 255 / 12%) 70.71%)",
            boxShadow:
              "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
            padding: "4rem 2rem",
            borderRadius: "10px",
          }}
        >
          {/* <CssBaseline /> */}
          <div>
            <Grid justifyContent="center" alignItems="center" item container>
              <Typography
                component="h1"
                variant="h5"
                style={{ marginLeft: "10px" }}
              >
                Find 3D Models
              </Typography>
            </Grid>
            <form onSubmit={this.handleSubmit}>
              {!data.subdivision &&
                this.renderSelect("subject", "Select Subject", this.subjects)}
              {data.subject &&
                this.renderSelect(
                  "subdivision",
                  "Select System/Subdivision",
                  this.systems()
                )}
              {data.subdivision &&
                this.renderSelect("topic", "Select Topic", this.topics())}

              <br />
              <br />
              {this.renderSubmitButton("Go")}
            </form>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default ThreeDQuiz;
