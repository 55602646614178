import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
// import HomeBgImg from "./home-bg.jpg";
import doctorsHome from "../img/doctorsHome.svg";
import Anatomy3D from "../img/anatomy3D.jpeg";

const useStyles = makeStyles((theme) => ({
  heroImg: {
    // minHeight: "350px",
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: "url(" + Anatomy3D + ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.65)",
  },
  heroImgContent: {
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
    // padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
  doctorsImgContainer: {
    textAlign: "center",
    position: "relative",
    padding: "50px",
  },
}));

const HomePaper = () => {
  const classes = useStyles();
  const [signedIn, setSignedIn] = React.useState(false);

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      setSignedIn(true);
      // ...
    } else {
      // User is signed out.
      setSignedIn(false);
    }
  });

  return (
    <Paper className={classes.heroImg}>
      <div className={classes.overlay} />
      <Grid id="header" container>
        <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
          <div className={classes.heroImgContent}>
            <div className="header-text">
              <Typography component="h1" className="header-text-one">
                Ace your Medical School exams
              </Typography>
              <Typography className="header-text-two">
                All the resources are here!
              </Typography>
            </div>

            {!signedIn && (
              <Fab
                component={Link}
                to="/student-register"
                variant="extended"
                color="secondary"
                aria-label="Add"
                style={{ fontSize: "1rem" }}
                className={classes.margin}
              >
                <HowToRegIcon className={classes.extendedIcon} />
                Register!
              </Fab>
            )}
          </div>
        </Grid>

        <Grid item xs={12} md={6} className={classes.doctorsImgContainer}>
          <img
            className="doctors-image"
            src={doctorsHome}
            alt="medical students"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HomePaper;
