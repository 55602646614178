import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Helmet } from "react-helmet";
import doctorsAbt from "../img/doctorsAbt.svg";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Button, CardActionArea } from "@material-ui/core";
import AboagyeImg from "../img/Aboagye.jpg";
import AbdulWadudImg from "../img/AbdulWadud.jpg";
// import GhaffaruImg from "../img/Ghaffaru.jpg";
import JudithImg from "../img/Judith.jpg";
import BrightImg from "../img/Bright.jpg";
import MustaphaImg from "../img/Mustapha.jpg";
import AfiaImg from "../img/Afia.jpg";
import NhyiraImg from "../img/Nhyira.jpg";
import EwuramaImg from "../img/Ewurama.jpeg";
import NanaKwameImg from "../img/NanaKwame.jpeg";
import Container from "@material-ui/core/Container";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";

class About extends Component {
  state = {
    openShowWriters: false,
  };

  handleClickOpen = (type) => {
    if (type === "show-content-writers") {
      this.setState({ openShowWriters: true });
    }
  };

  handleClose = (type) => {
    if (type === "show-content-writers") {
      this.setState({ openShowWriters: false });
    }
  };

  team = [
    {
      name: "Dr. Abdul Wadud Mohammed",
      title: "Head of Software Engineering",
      image: AbdulWadudImg,
    },
    // {
    //   name: "Dr. Daniel Danso Aboagye",
    //   title: "Head of Medical",
    //   image: AboagyeImg,
    // },
    // {
    //   name: "Ghaffaru Mudashiru",
    //   title: "Mobile & AI Engineer",
    //   image: GhaffaruImg,
    // },
    {
      name: "Dr. Bright Forkuo",
      title: "Content Lead",
      image: BrightImg,
    },
    // {
    //   name: "Dr. Judith Yelbert",
    //   title: "Content Lead - Flashcards",
    //   image: JudithImg,
    // },
    {
      name: "Dr. Mustapha Dadzie",
      title: "Content Lead",
      image: MustaphaImg,
    },
    {
      name: "Dr. Kwame Obeng Bempong",
      title: "Content Lead",
      image: NanaKwameImg,
    },
    {
      name: "Dr. Grace Ewurama Omari",
      title: "Marketing & Publicity",
      image: EwuramaImg,
    },
    {
      name: "Dr. Afia Kwabiaa Baah",
      title: "Marketing & Publicity",
      image: AfiaImg,
    },
    // {
    //   name: "Nhyira Anning",
    //   title: "Customer Support",
    //   image: NhyiraImg,
    // },
    // {
    //   name: "",
    //   title: "",
    //   image: ,
    // },
  ];

  render() {
    const { openShowWriters } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>About - Acabest</title>
          <meta
            name="description"
            content="What we do and what we stand for."
          />
        </Helmet>

        <Grid
          id="about-container"
          item
          md={8}
          xs={11}
          style={{ margin: "30px auto" }}
        >
          <Typography
            variant="h4"
            component="h1"
            style={{ textAlign: "center" }}
          >
            About Us
          </Typography>
          <br />
          <Grid item md={5} xs={10} className="feature-image">
            <img src={doctorsAbt} alt="doctors" />
          </Grid>
          <Typography variant="body1" className="images-details">
            Taking tests is part of medical school. And passing them is
            important. We understand the struggle that comes with preparing for
            tests very well, because we have been students before, and some of
            us still are. That is why we built this platform.
          </Typography>
          <Typography
            variant="body1"
            className="images-details"
            style={{ fontWeight: "bolder", fontSize: "18px" }}
          >
            Our mission is to help students to not just pass, but easily excel
            in their medical school tests.
          </Typography>
          <br />
          <Typography
            variant="h4"
            className="images-header"
            style={{ fontSize: "30px" }}
          >
            Our Story So Far
          </Typography>
          <Typography variant="body1" className="images-details">
            Our story began with one medical student who stayed several hours a
            day behind his PC, figuring out how best to make this work. Today,
            we have a growing team which consists of both students and
            practicing doctors. These are top scoring individuals who are
            passionate about helping students ace their tests. We work together
            to provide up to date, relevant, and engaging content. And the
            platform keeps growing, as we keep adding more content regularly.
          </Typography>
        </Grid>

        <Grid>
          <Typography
            variant="h4"
            className="images-header"
            style={{ fontSize: "30px" }}
          >
            Meet The Team
          </Typography>
          <Grid
            id="team-container"
            container
            item
            xs={11}
            md={10}
            style={{ margin: "30px auto", textAlign: "center" }}
          >
            {/* <Grid
              item
              md={5}
              lg={4}
              style={{ margin: "auto", padding: "20px" }}
            >
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="400"
                    image={AbdulWadudImg}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      Abdul Wadud Mohammed
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      Head of Software Engineering
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid> */}

            {this.team.map((teamMember) => (
              <Grid
                key={teamMember.name}
                item
                md={5}
                lg={4}
                style={{ margin: "auto", padding: "20px" }}
              >
                <Card sx={{ maxWidth: 345 }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="400"
                      image={teamMember.image}
                      alt="green iguana"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        {teamMember.name}
                      </Typography>
                      <Typography variant="body1" color="secondary">
                        {teamMember.title}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
            {/* <Grid
              item
              md={5}
              lg={4}
              style={{ margin: "auto", padding: "20px" }}
            >
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="400"
                    image={AboagyeImg}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      Dr. Daniel Danso Aboagye
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      Head Of Medical Content
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid> */}
            {/* <Grid
              item
              md={5}
              lg={4}
              style={{ margin: "auto", padding: "20px" }}
            >
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="400"
                    image={GhaffaruImg}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      Ghaffaru Mudashiru
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      Mobile and AI Engineer
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid> */}
          </Grid>

          <Container
            component="main"
            maxWidth="lg"
            style={{
              margin: "30px auto",
              width: "95%",
              backgroundColor: "#fff",
              boxShadow:
                "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
              padding: "4rem 2rem",
              borderRadius: "10px",
            }}
          >
            <Grid container style={{ fontSize: "15px" }}>
              <Grid justifyContent="center" item container>
                <br />
                <Typography
                  component="h3"
                  variant="h5"
                  style={{ marginBottom: "20px" }}
                >
                  Content Contributors
                </Typography>
              </Grid>
              <Grid item xs={10} md={6} lg={6}>
                <ul>
                  <li>Dr. Paulina Sarfo Mensah</li>
                  <li>Dr. Emmanuel Teyie</li>
                  <li>Dr. Alice Ackah</li>
                  <li>Dr. Emenyo Apaloo</li>
                  <li>Dr. Albert Gyato</li>
                  {/* <li>Dr. Mohammed Mustapha Ahmed Dadzie</li> */}
                  <li>Dr. Ernestina Yeboah Thompson</li>
                  <li>Dr. Helina Adjei Asantewaa</li>
                  <li>Dr. Kwadwo Adomako Kwakye-Tannor</li>
                  <li>Dr. Emmanuella Sungmambo Boye</li>
                </ul>
              </Grid>
              <Grid item xs={10} md={6} lg={6}>
                <ul>
                  {/* <li>Adwoa Achiaa Oppong-Kyekyeku</li> */}
                  <li>Dr. Dr. Jerry Kevin Boafo Osei-Alomele</li>
                  <li>Dr. Emmanuel Nyamah</li>
                  <li>Dr. Ransford Bio</li>
                  <li>Dr. Godfrey Adjei</li>
                  <li>Pearl Yayra Korsorku </li>
                  <li>Rebecca Korkor Nagetey</li>
                  <li>Elliot Tettey Cudjoe Nartey </li>
                  <li>Derrick Selorm Adzomfoa</li>
                  <li>Elsie Donkor</li>
                  <li>Raymond Ayeliya Tangombire</li>
                </ul>
              </Grid>
              {/* <Grid item xs={10} md={5} lg={4}>
                <ul>
                  <li>Kwame Adom Tawiah</li>
                  <li>Seth Sarpong</li>
                  <li>Eunice Abrokwa</li>
                  <li>Godfrey Adjei</li>
                  <li>Nyantakyi Adu</li>
                  <li>Emmanuel Asiwome Banini</li>
                  <li>Gloria Golda Akwa</li>
                  <li>Ebenezer Agyei Afoakwah</li>
                  <li>Eliza Akosua Asaa Gyebi</li>
                </ul>
              </Grid>
              <Grid item xs={10} md={5} lg={4}>
                <ul>
                  <li>Dominic Forson </li>
                  <li>Nana Yaw Addae-Poku</li>
                  <li>Derrick Antwi Kwarteng</li>
                  <li>Raphael Amoah-Mensah</li>
                  <li>Grace Nyame-Ansah Mensah</li>
                  <li>Kofi Konadu Boakye Yiadom</li>
                  <li>Henry Maxwell Kwofie</li>
                  <li>Kwaku Adjei Abotre-Dwomoh</li>
                  <li>Michael Boahen Afakye</li>
                  <li>Jonathan Kpiero-nuo Baayakuu</li>
                </ul>
              </Grid>
              <Grid item xs={10} md={5} lg={4}>
                <ul>
                  <li>Joshua Yaw Elikem Amegah</li>
                  <li>Emmanuel Kofi Frimpong</li>
                  <li>Frimpong Owusu-Gyan</li>
                  <li>Jimmy Stephen Newton</li>
                  <li>John Yao Avu</li>
                  <li>Wisdom Anabzua Akudugu</li>
                  <li>Emmanuel Agyemang</li>
                  <li>Kofi Oppong Darko</li>
                  <li>Francis Ohene Addo</li>
                  <li>Cecil Eben Nketsiah </li>
                  <li>Ernest Kwamla Kporvuvu</li>
                  <li>Jesse Denyansah Arthur</li>
                </ul>
              </Grid> */}
            </Grid>

            <Grid>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => this.handleClickOpen("show-content-writers")}
              >
                See All Content Contributors
              </Button>
              <div>
                <Dialog
                  open={openShowWriters}
                  onClose={() => this.handleClose("show-content-writers")}
                  aria-labelledby="show-content-writers-dialog"
                >
                  <DialogTitle id="show-content-writers-dialog">
                    Medical Content Writers
                  </DialogTitle>
                  <DialogContent>
                    {/* <DialogContentText>
                      Acabest Content Writers.
                    </DialogContentText> */}
                    <Grid item>
                      <ul>
                        <li>Dr. Paulina Sarfo Mensah</li>
                        <li>Dr. Emmanuel Teyie</li>
                        <li>Dr. Alice Ackah</li>
                        <li>Dr. Jerry Kevin Boafo Osei-Alomele</li>
                        <li>Dr. Emenyo Apaloo</li>
                        <li>Dr. Albert Gyato</li>
                        <li>Dr. Ernestina Yeboah Thompson</li>
                        <li>Dr. Helina Adjei Asantewaa</li>
                        <li>Dr. Kwadwo Adomako Kwakye-Tannor</li>
                        <li>Dr. Emmanuella Sungmambo Boye</li>
                      </ul>
                    </Grid>
                    <Grid item>
                      <ul>
                        {/* <li>Adwoa Achiaa Oppong-Kyekyeku</li> */}
                        <li>Dr. Emmanuel Nyamah</li>
                        <li>Dr. Ransford Bio</li>
                        <li>Dr. Seth Sarpong</li>
                        <li>Dr. Eunice Abrokwa</li>
                        <li>Dr. Godfrey Adjei</li>
                        <li>Dr. Nyantakyi Adu</li>
                      </ul>
                    </Grid>
                    <Grid item>
                      <ul>
                        <li>Pearl Yayra Korsorku </li>
                        <li>Rebecca Korkor Nagetey</li>
                        <li>Elliot Tettey Cudjoe Nartey </li>
                        <li>Derrick Selorm Adzomfoa</li>
                        <li>Elsie Donkor</li>
                        <li>Raymond Ayeliya Tangombire</li>
                        <li>Kwame Adom Tawiah</li>
                        <li>Emmanuel Asiwome Banini</li>
                        <li>Gloria Golda Akwa</li>
                        <li>Ebenezer Agyei Afoakwah</li>
                        <li>Eliza Akosua Asaa Gyebi</li>
                        <li>Dominic Forson </li>
                      </ul>
                    </Grid>
                    <Grid item>
                      <ul>
                        <li>Nana Yaw Addae-Poku</li>
                        <li>Derrick Antwi Kwarteng</li>
                        <li>Raphael Amoah-Mensah</li>
                        <li>Grace Nyame-Ansah Mensah</li>
                        <li>Kofi Konadu Boakye Yiadom</li>
                        <li>Henry Maxwell Kwofie</li>
                        <li>Kwaku Adjei Abotre-Dwomoh</li>
                        <li>Michael Boahen Afakye</li>
                        <li>Jonathan Kpiero-nuo Baayakuu</li>
                        <li>Joshua Yaw Elikem Amegah</li>
                      </ul>
                    </Grid>
                    <Grid item>
                      <ul>
                        <li>Emmanuel Kofi Frimpong</li>
                        <li>Frimpong Owusu-Gyan</li>
                        <li>Jimmy Stephen Newton</li>
                        <li>John Yao Avu</li>
                        <li>Wisdom Anabzua Akudugu</li>
                        <li>Emmanuel Agyemang</li>
                        <li>Kofi Oppong Darko</li>
                        <li>Francis Ohene Addo</li>
                        <li>Cecil Eben Nketsiah </li>
                        <li>Ernest Kwamla Kporvuvu</li>
                        <li>Jesse Denyansah Arthur</li>{" "}
                      </ul>
                    </Grid>
                    <Grid item>
                      <ul></ul>
                    </Grid>
                    <Divider />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => this.handleClose("show-content-writers")}
                      color="primary"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>
          </Container>

          {/* <Grid item md={6} xs={12} className="feature-texts" >
                        <Typography variant="h4" className="images-header">
                            The Mission
                        </Typography>
                        <Typography variant="body1" className="images-details" >
                        Our Mission is to empower medical students to not just survive but to 
                        thrive and be confident in the face of the dreaded medical school exams. 
                        </Typography>
                    <br />
                    <Divider width="80%" />
                    <br />
                        <Typography variant="h4" className="images-header">
                            The Dream
                        </Typography>
                        <Typography variant="body1" className="images-details" >
                        To see a day when medical students will go to exams with 
                        hope in their heart rather than palpitations. 
                        </Typography>
                        <Typography variant="body1" className="images-details" >
                        To see this dream come to pass, we work everyday to develop content and 
                        innovative tools that will revolutionize learning and exam preparations as you know it.

                        </Typography>
                    </Grid>            
                    
                    <Grid item md={8} xs={11} style={{margin: "30px auto"}}>
                    <Typography variant="h4" className="images-header" style={{ textAlign: "center" }}>
                        OUR STORY
                    </Typography>
                        <Typography variant="body1" className="images-details" >
                        Taking tests is part of medical school and passing the tests is a must. Acabest was developed by 
                        medical students with burning passion to see every student ace their test. And we remain committed 
                        to doing that. Today our team consists of both medical students and practicing doctors. We work 
                        together to produce content that is current, accurate and relevant.
                        </Typography>
                        <Typography variant="body1" className="images-details" >
                        To help you ace your tests, we provide a load of resources including questions with concise 
                        explanations, clinical guides on high yield topics, spaced repetition, progress tracking and 
                        more. 
                        </Typography>
                    </Grid> */}
        </Grid>
      </React.Fragment>
    );
  }
}

export default About;
