import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import HeaderPaper from "./common/headerPaper";
import firebase from "firebase/app";
import { toast } from "react-toastify";
import PreLoader from "./common/preLoader";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { getTopicTitle } from "../utils/getCategories";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import { Helmet } from "react-helmet";

class ThreeDQuizzesResults extends Component {
  state = {
    threeDQuizzes: [],
    foundNo3dQuizzes: false,
    user: {},
    subject: "",
    subdivision: "",
    topic: "",
    open3dModal: false,
  };

  componentDidMount() {
    const { subject, subdivision, topic } = this.props.match.params;
    const user = JSON.parse(localStorage.getItem("currentUser"));

    // console.log(subject, subdivision, topic)

    let threeDQuizzes = [];
    this.setState({ threeDQuizzes, user, subject, subdivision, topic });

    const getResults = firebase
      .firestore()
      .collection("threeD-models")
      .where("subject", "==", subject)
      .where("subdivision", "==", subdivision)
      .where("topic", "==", topic)
      .orderBy("dateUploaded", "desc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log(doc.data());
          const file = doc.data();
          threeDQuizzes.push(file);
        });
      })
      .catch((error) => {
        // console.error(error.message);
        toast.error(error.message);
      });

    Promise.all([getResults])
      .then(() => {
        // console.log(threeDQuizzes)
        if (threeDQuizzes.length === 0) {
          this.setState({ foundNo3dQuizzes: true });
        } else {
          this.setState({ foundNo3dQuizzes: false });
        }
        this.setState({ threeDQuizzes, user, subject, subdivision, topic });
      })
      .catch(() => {
        // console.log("something went wrong");
        toast.error("something went wrong");
      });
  }

  handleClickOpen = (threeDQuiz) => {
    const threeDModelUrl = threeDQuiz.url;
    this.setState({
      open3dModal: true,
      threeDModelUrl,
    });
  };

  handleClose = (type) => {
    this.setState({
      open3dModal: false,
    });
  };

  render() {
    const {
      threeDQuizzes,
      foundNo3dQuizzes,
      user,
      topic,
      open3dModal,
      threeDModelUrl,
    } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>3D Quizzes - Acabest</title>
        </Helmet>

        {!threeDQuizzes.length > 0 && foundNo3dQuizzes === false ? (
          <PreLoader />
        ) : (
          <div>
            <HeaderPaper headerTitle={`3D Quizzes - ${getTopicTitle(topic)}`} />

            <div>
              <Dialog
                fullScreen
                open={open3dModal}
                onClose={this.handleClose}
                aria-labelledby="3d-dialog"
              >
                {/* <DialogTitle id="3d-dialog">3D Model on {getTopicTitle(quizPack.questions[practiceQueIndex].topic)}</DialogTitle> */}
                <DialogContent>
                  <AppBar style={{ marginBottom: "500px" }}>
                    <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={this.handleClose}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                      <Typography variant="h6">
                        3D Quiz - {getTopicTitle(topic)}
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  <br /> <br />
                  <div style={{ marginTop: "50px" }}>
                    <iframe
                      title="3D Model"
                      id="embedded-human"
                      frameBorder="0"
                      width="700"
                      height="500"
                      allowFullScreen={true}
                      loading="lazy"
                      src={threeDModelUrl}
                    ></iframe>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="primary">
                    Return
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            <div style={{ width: "90%", margin: "auto", marginTop: "30px" }}>
              <Grid container spacing={4}>
                {threeDQuizzes.map((threeDQuiz) => (
                  <Grid item key={threeDQuiz.id}>
                    <Card>
                      <CardHeader
                        title={
                          // <Link to={`/3d-playground-preview/${threeDQuiz.id}`}>
                          //     <Typography variant="body1" style={{ fontWeight: "bold" }}>
                          //         {threeDQuiz.title}
                          //     </Typography>
                          // </Link>
                          <Button
                            // component={Link}
                            // to={`/edit-3d-quiz/${threeDQuiz.id}`}
                            fullWidth
                            color="primary"
                            onClick={() => this.handleClickOpen(threeDQuiz)}
                          >
                            {threeDQuiz.title}
                          </Button>
                        }
                      />
                    </Card>
                    {user && user.userType === "tutor" && (
                      <Button
                        component={Link}
                        to={`/edit-3d-model/${threeDQuiz.id}`}
                        fullWidth
                        variant="contained"
                        color="secondary"
                      >
                        Edit 3D Model
                      </Button>
                    )}
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        )}

        {foundNo3dQuizzes && (
          <Typography
            style={{
              padding: "15px 0px 20px 30px",
              textTransform: "capitalize",
            }}
            variant="body1"
          >
            <em>
              There are no 3D Quizzes matching your selection available yet.
              Check again soon.
            </em>
          </Typography>
        )}
      </React.Fragment>
    );
  }
}

export default ThreeDQuizzesResults;
