import React, { Component } from "react";
import HeaderPaper from "./common/headerPaper";
import Grid from "@material-ui/core/Grid";
import { Button, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";

class Aecme extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>AECME - Acabest</title>
        </Helmet>

        <HeaderPaper headerTitle="EXTENDED MDC COURSE" />

        <Grid
          container
          // item
          xs={9}
          lg={6}
          style={{ margin: "30px auto", textAlign: "justify" }}
        >
          <Grid>
            <Typography>
              Acabest brings you a calm paced MDC exam prep course meant for
              candidates who's exam is at least 6 months away, especially for
              those still in their final year medical school.
            </Typography>
            <br />
            <Typography>
              <strong>
                It's a 6 month long course starting from 7th July.{" "}
              </strong>
              This is going to be purely online focusing on stage 1 and stage 2
              unmanned (clinical pictures).
            </Typography>
            <br />
            <Typography>
              Subjects to be covered include{" "}
              <strong>
                Medicine, Surgery, Obstetrics and Gynaecology, Paediatrics,
                Public health, and Basic sciences.
              </strong>
            </Typography>
            <br />
            <Typography>
              Since you also have the academic load of final year, in order not
              to overwhelm you, we will have only 1 session a week at a
              convenient timing lasting for 3 hours. During this 3 hour session,
              we will go through 50 MDC prep MCQs as well as 10 unmanned OSCE
              clinical pictures.
            </Typography>
            <br />
            <Typography>
              You will also be given full access to Acabest Premium, which has
              been the main prep resource for candidates of the Ghana MDC
              licensure exam since the introduction of the new MDC exam system.
              The site contains over 3200 answered and explained MCQs, and over
              120 clinical guides, which continues to be updated.
            </Typography>
          </Grid>

          <br />
          <Grid>
            <h2 style={{ fontSize: "23px" }}>How much does it cost?</h2>
            <Typography>
              <strong>GHC 1,800</strong> for the entire duration of the course
            </Typography>
            <br />
            <Typography>
              Click on the button below to WhatsApp us to initiate your
              registration or make enquiries.
            </Typography>

            <br />
            <br />
            <a
              href="https://wa.me/message/RA3ZX3V2YX5ZB1"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: "10px auto" }}
              >
                Register! / Make Enquiries
              </Button>
            </a>
            <br />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Aecme;
