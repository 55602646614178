import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";

class Footer extends Component {
  state = {
    user: {},
  };

  componentDidMount() {
    const user = localStorage.getItem("currentUser");
    this.setState({ user });
  }

  render() {
    // const { user } = this.state;
    return (
      <React.Fragment>
        <footer id="footer">
          <Grid container style={{ color: "#fff" }}>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={3}
              style={{ marginBottom: "20px" }}
            >
              <Typography variant="h5">Company</Typography>
              <a href="about">About Us</a>
              <br />
              <a href="contact">Contact Us</a>
              <br />
              <a href="contact">Partnership</a>
            </Grid>

            <br />
            <br />

            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={3}
              style={{ marginBottom: "20px" }}
            >
              <Typography variant="h5">Resources</Typography>
              <a href="/questionbank">Question Bank</a>
              <br />
              <a href="/osce">OSCE Guides</a>
              <br />
              {/* <a href="/3d-playground">3D Anatomy Plaground</a>
              <br /> */}
              <a href="/spaced-repetition">Automated Revision</a>
              <br />
              <a href="/flashcards">Flashcards</a>
            </Grid>

            <br />
            <br />

            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={3}
              style={{ marginBottom: "20px" }}
            >
              <Typography variant="h5">Legal</Typography>
              <a href="/privacy-policy">Privacy Policy</a>
              <br />
              <a href="/terms">Terms of Use</a>
            </Grid>

            <br />
            <br />

            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={3}
              style={{ marginBottom: "20px" }}
            >
              <Typography variant="h5">Social Media</Typography>
              <a
                style={{ margin: "auto 8px" }}
                href="https://youtube.com/channel/UCY4TC6fJKaDJanGbBK-Jpzg"
              >
                <YouTubeIcon />
              </a>
              <a
                style={{ margin: "auto 8px" }}
                href="https://www.facebook.com/acabestgh/"
              >
                <FacebookIcon />
              </a>
              <a
                style={{ margin: "auto 8px" }}
                href="https://twitter.com/acabest_medical?s=20"
              >
                <TwitterIcon />
              </a>
            </Grid>
          </Grid>
          <Grid container style={{ color: "#fff", justifyContent: "center" }}>
            <p>
              &copy; <span>{new Date().getFullYear()}</span> Acabest Medical
              LTD. All Rights Reserved
            </p>
          </Grid>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
