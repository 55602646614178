import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Form from "./common/form";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Joi from "joi-browser";
import { getOsceSubjects } from "../utils/getCategories";
import { getOsceTypes } from "../utils/getCategories";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

class Osce extends Form {
  state = {
    user: {},
    data: {
      subject: "",
      type: "",
    },
    errors: {},
  };

  schema = {
    subject: Joi.string().required().label("Subject"),
    type: Joi.string().required().label("Type"),
  };

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    this.setState({ user });
  }

  subjects = getOsceSubjects();
  types = getOsceTypes();

  doSubmit = () => {
    const { data } = this.state;
    const subject = data.subject;
    const type = data.type;

    window.location.assign(`/osce-results/${subject}/${type}`);
  };

  render() {
    // const { user } = this.state;
    const classes = useStyles;

    return (
      <React.Fragment>
        <Helmet>
          <title>OSCE - Acabest</title>
        </Helmet>

        <br />
        <Typography variant="h4" component="h1" style={{ textAlign: "center" }}>
          OSCE Guides
        </Typography>

        <div style={{}}>
          <Container
            component="main"
            maxWidth="sm"
            style={{
              margin: "30px auto",
              width: "95%",
              background:
                "linear-gradient(225deg, rgb(63 81 181 / 86%), rgb(0 123 255 / 12%) 70.71%)",
              boxShadow:
                "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
              padding: "4rem 2rem",
              borderRadius: "10px",
            }}
          >
            {/* <CssBaseline /> */}
            <div className={classes.paper}>
              <Grid justifyContent="center" alignItems="center" item container>
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ marginLeft: "10px" }}
                >
                  Find OSCEs
                </Typography>
              </Grid>
              <form className={classes.form} onSubmit={this.handleSubmit}>
                {this.renderSelect("subject", "Select Subject", this.subjects)}
                {this.renderSelect("type", "Select OSCE Type", this.types)}
                <br />
                <br />
                {this.renderSubmitButton("Go")}
              </form>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Osce;
