export function removeEmptySpaces(htmlText) {
  let newText = htmlText;
  if (htmlText.substring(3, 9) === "&nbsp;") {
    // console.log(htmlText);
    // console.log("Yes there's empty space at the start");
    newText =
      htmlText.substring(0, 3) + htmlText.substring(9, htmlText.length + 1);
  }
  if (
    htmlText.substring(htmlText.length - 10, htmlText.length - 4) === "&nbsp;"
  ) {
    // console.log("There's also emtpy space at the end");
    newText =
      htmlText.substring(0, htmlText.length - 10) +
      htmlText.substring(htmlText.length - 4, htmlText.length + 1);
  }

  // console.log(newText);
  return newText;
}
