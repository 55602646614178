export function getHistorySections() {
    return [
        {
            name: "Rapport & Consent",
            id: "rapport"
        },
        {
            name: "Demographics",
            id: "demographics"
        },
        {
            name: "Presenting Complaint",
            id: "pc"
        },
        {
            name: "History Of Presenting Complaint",
            id: "hpc"
        },
        {
            name: "Direct Questions & Systemic Enquiry",
            id: "odqsys"
        },
        {
            name: "",
            id: ""
        },
    ]
}