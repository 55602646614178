import React from "react";
import firebase from "firebase/app";
import { toast } from "react-toastify";
import PreLoader from "./common/preLoader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Joi from "joi-browser";
import Form from "./common/form";
import {
  getSubdivisions,
  getSubjects,
  getTopics,
} from "../utils/getCategories";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { Helmet } from "react-helmet";

class Edit3dModel extends Form {
  state = {
    data: {
      title: "",
      url: "",
      type: "",
      subject: "",
      subdivision: "",
      topic: "",
    },
    errors: {},
  };

  schema = {
    title: Joi.string().min(3).max(200).required().label("Title"),
    url: Joi.string().min(10).required().label("URL"),
    type: Joi.string().required().label("Type"),
    subject: Joi.string().required().label("Subject"),
    subdivision: Joi.string().required().label("System"),
    topic: Joi.string().required().label("topic"),
  };

  componentDidMount() {
    const modelId = this.props.match.params.modelId;

    firebase
      .firestore()
      .collection("threeD-models")
      .doc(modelId)
      .get()
      .then((doc) => {
        console.log(doc.data());
        const model = doc.data();
        this.setState({
          data: {
            title: model.title,
            url: model.url,
            type: model.type,
            subject: model.subject,
            subdivision: model.subdivision,
            topic: model.topic,
          },
          modelId,
        });
      });
  }

  subjects = getSubjects();

  systems = () => {
    const { subject } = this.state.data;
    return getSubdivisions([subject]);
  };

  topics = () => {
    const { subject, subdivision } = this.state.data;
    //   if(!subdivision || !subject) {
    //       return [];
    //   }
    return getTopics(subject, subdivision);
  };

  doSubmit = () => {
    // console.log("submitting model update...");
    const { modelId } = this.state;
    const { title, url, type, subject, subdivision, topic } = this.state.data;

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    firebase
      .firestore()
      .collection("threeD-models")
      .doc(modelId)
      .update({
        title: title,
        url: url,
        type: type,
        subject: subject,
        subdivision: subdivision,
        topic: topic,
      })
      .then(() => {
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        toast.success("3D Model has been successfully updated.");
      })
      .catch((error) => {
        console.log(error.message);
        toast.error("Something went wrong.");
      });
  };

  render() {
    const { submitting, data } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>Edit 3D Model - Acabest</title>
        </Helmet>

        {submitting ? (
          <PreLoader />
        ) : (
          <Container
            component="main"
            maxWidth="sm"
            style={{
              margin: "30px auto",
              width: "95%",
              backgroundColor: "#fff",
              boxShadow:
                "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
              padding: "4rem 2rem",
              borderRadius: "10px",
            }}
          >
            <CssBaseline />
            <div>
              <Grid justifyContent="center" alignItems="center" item container>
                <br />
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ marginLeft: "10px" }}
                >
                  Edit 3D Model
                </Typography>
              </Grid>
              <form onSubmit={this.handleSubmit}>
                {this.renderTextField("title", "Title")}
                {this.renderTextField("url", "URL")}
                {this.renderSelect("type", "Select Type", [
                  { name: "Quiz", id: "quiz" },
                  { name: "Regular", id: "regular" },
                ])}
                {this.renderSelect("subject", "Select Subject", this.subjects)}
                {data.subject &&
                  this.renderSelect(
                    "subdivision",
                    "Select System/Subdivision",
                    this.systems()
                  )}
                {data.subject &&
                  data.subdivision &&
                  this.renderSelect("topic", "Select Topic", this.topics())}

                {this.renderSubmitButton("Submit")}
              </form>
            </div>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default Edit3dModel;
