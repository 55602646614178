import React, { Component } from "react";
// import firebase from "firebase/app";
import { toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import PreLoader from "./common/preLoader";

class FinishPayment extends Component {
  state = {};

  componentDidMount() {
    // const userId = localStorage.getItem("currentUserId");
    // const userEmail = JSON.parse(localStorage.getItem("currentUser")).email;
    // console.log("query", this.props.location.search);
    const params = new URLSearchParams(this.props.location.search);
    const status = params.get("status");
    // console.log("text ref", params.get("tx_ref"));
    // console.log("status", params.get("status"));

    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    if (status === "successful") {
      toast.success("Your subscription has been successfully processed.");
      const submitting = this.state.submitting;
      this.setState({ submitting: !submitting });
      setTimeout(() => {
        window.location.assign("dashboard");
      }, 3000);
    } else if (status === "failed") {
      toast.error(
        "Something went wrong. We were unable to process your subscription"
      );
      this.setState({ submitting: !submitting });
      setTimeout(() => {
        window.location.assign("pricing");
      }, 3000);
    } else if (status === "cancelled") {
      toast.error(
        "Your subscription was cancelled. Please resolve any issues and try again."
      );
      this.setState({ submitting: !submitting });
      setTimeout(() => {
        window.location.assign("pricing");
      }, 3000);
    } else {
      toast.error(
        "Something went wrong. We were unable to process your subscription"
      );
      this.setState({ submitting: !submitting });
      setTimeout(() => {
        window.location.assign("pricing");
      }, 3000);
    }

    // firebase
    //   .firestore()
    //   .collection("premiumUsers")
    //   .doc(userEmail)
    //   .onSnapshot((doc) => {
    //     // console.log(doc.data());
    //     const user = doc.data();
    //     this.setState({ user: doc.data() });

    //   });
  }

  render() {
    const { submitting } = this.state;
    return (
      <React.Fragment>
        {submitting ? (
          <PreLoader message="Hold on...Your subscription is being processed." />
        ) : (
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <Typography variant="body1">
              Once your payment is done, You will be directed to your dashboard.
            </Typography>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default FinishPayment;
