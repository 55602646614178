import React, { useEffect, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Timer from "../components/common/timer";
import firebase from "firebase/app";
import PreLoader from "./common/preLoader";
import { Helmet } from "react-helmet";
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Link } from "react-router-dom";
import { Accordion } from "@material-ui/core";
import { AccordionSummary } from "@material-ui/core";
import { AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import ReactHtmlParser from "react-html-parser";
import TimeUpAlarm from "../audio/BeeperEmergencCall.mp3";
import Beep from "../audio/Beep.mp3";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {
  getSubdivisions,
  getSubjects,
  getTopics,
} from "../utils/getCategories";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { removeEmptySpaces } from "../utils/removeEmptySpaces";
import { useReactToPrint } from "react-to-print";

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

export default function FullWidthTabs({ match }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [osce, setOsce] = React.useState({});
  const [points, setPoints] = React.useState(0);
  const [totalPoints, setTotalPoints] = React.useState(0);
  const [finished, setFinished] = React.useState(false);
  const [practiceStart, setPracticeStart] = React.useState(false);
  const [osceTime, setOsceTime] = React.useState(0);
  const [timeUsed, setTimeUsed] = React.useState(0);
  const [forceSignIn, setForceSignIn] = React.useState(false);
  const [makeGoPremium, setMakeGoPremium] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openInstructions, setOpenInstructions] = React.useState(false);
  const [openCreateFlashCard, setOpenCreateFlashCard] = React.useState(false);
  const [openSuggestedVivaTopics, setOpenSuggestedVivaTopics] =
    React.useState(false);
  const [openMoreAnalytics, setOpenMoreAnalytics] = React.useState(false);
  const [openSureToDelete, setOpenSureToDelete] = React.useState(false);
  const [openListenOsce, setOpenListenOsce] = React.useState(false);
  const [issueToReport, setIssueToReport] = React.useState("");
  const [user, setUser] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [flashcardData, setFlashcardData] = React.useState({
    cardFrontText: "",
    cardBackText: "",
    subject: "",
    subdivision: "",
    topic: "",
    allowSharing: true,
  });
  const [submitting, setSubmitting] = React.useState(false);
  const [permitEditOsce, setPermitEditOsce] = React.useState(false);
  const [missedItemsArray, setMissedItemsArray] = React.useState([]);
  const [vivaTopics, setVivaTopics] = React.useState([]);
  const [showVivaDetails, setShowVivaDetails] = React.useState(false);
  const [currentVivaTopicDetails, setCurrentVivaTopicDetails] = React.useState(
    {}
  );
  const [osceStats, setOsceStats] = React.useState({});
  const [isPremiumUser, setIsPremiumUser] = React.useState(true);
  const [subjects, setSubjects] = React.useState([]);
  // const [subjectsWithRelevantContent, setSubjectsWithRelevantContent] =
  // React.useState([]);
  const [isAdmin, setIsAdmin] = React.useState(false);

  useEffect(() => {
    const { osceId } = match.params;
    const userId = localStorage.getItem("currentUserId");
    const statistics = JSON.parse(localStorage.getItem("statistics"));
    // setUser(user);
    setUserId(userId);
    sessionStorage.removeItem("timeUp");

    // GETTING THE SUBJECTS LIST
    const subjects = getSubjects(statistics.subjects);
    const findSubjectsWithRelevantContent = subjects.filter(
      (subject) => subject.noOfQuestions > 0
    );
    const subjectsWithRelevantContent = getSubjects(
      findSubjectsWithRelevantContent
    );
    setSubjects(subjects);
    // setSubjectsWithRelevantContent(subjectsWithRelevantContent);

    firebase
      .firestore()
      .collection("osces")
      .doc(osceId)
      .get()
      .then((doc) => {
        const osce = doc.data();
        let hasAccesLevel = false;

        if (userId) {
          firebase
            .firestore()
            .collection("users")
            .doc(userId)
            .onSnapshot((doc) => {
              const user = doc.data();
              setUser(user);

              getOsceStats(user, osce.osceId);
              updateSeenOsces(osce, user);

              firebase
                .firestore()
                .collection("premiumUsers")
                .doc(user.email)
                .onSnapshot((doc) => {
                  // console.log(doc.data());
                  const premiumUser = doc.data();

                  if (
                    (premiumUser &&
                      premiumUser.email.length > 3 &&
                      premiumUser.subscription.type === "basic") ||
                    hasAccesLevel ||
                    (user.volunteer &&
                      (user.volunteer.includes(osce.subject) ||
                        user.volunteer.includes("all")))
                  ) {
                    setOsce(osce);
                    setIsPremiumUser(true);
                  } else if (
                    (user.seenOsces && user.seenOsces.length < 16) ||
                    !user.seenOsces
                  ) {
                    setOsce(osce);
                    setIsPremiumUser(false);
                  } else {
                    // console.log("not premium user");
                    if (
                      user.volunteer &&
                      user.volunteer.includes(osce.subject)
                    ) {
                      console.log(user.volunteer);
                      console.log(osce.subject);
                      setIsPremiumUser(true);
                    }
                    const newSegments = osce.segments.splice(0, 5);
                    osce.segments = newSegments;
                    setOsce(osce);
                    setMakeGoPremium(true);
                    setIsPremiumUser(false);
                  }
                });
            });
        } else {
          const newSegments = osce.segments.splice(0, 4);
          osce.segments = newSegments;
          setOsce(osce);
          setForceSignIn(true);
        }

        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.email) {
            //FOR CHECKING CUSTOM STATES
            firebase
              .auth()
              .currentUser.getIdTokenResult()
              .then((idTokenResult) => {
                // console.log(idTokenResult.claims);
                if (
                  idTokenResult.claims.admin &&
                  idTokenResult.claims.admin === true
                ) {
                  setPermitEditOsce(true);
                  setOsce(osce);
                  setMakeGoPremium(false);
                  hasAccesLevel = true;
                  setIsAdmin(true);
                }
                if (
                  idTokenResult.claims.editor &&
                  idTokenResult.claims.editor.length > 0
                ) {
                  const features = idTokenResult.claims.editor;
                  // console.log(features)
                  if (features.includes("osce")) {
                    setPermitEditOsce(true);
                    setOsce(osce);
                    setMakeGoPremium(false);
                    hasAccesLevel = true;
                  }
                }
                if (
                  idTokenResult.claims.moderator &&
                  idTokenResult.claims.moderator.features &&
                  idTokenResult.claims.moderator.features.length > 0
                ) {
                  const features = idTokenResult.claims.moderator.features;
                  const subjects = idTokenResult.claims.moderator.subjects;
                  // console.log(features)
                  // console.log(subjects)
                  if (
                    features.includes("osce") &&
                    subjects.includes(osce.subject)
                  ) {
                    setPermitEditOsce(true);
                    setOsce(osce);
                    setMakeGoPremium(false);
                    hasAccesLevel = true;
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      });
  }, []);

  function updateSeenOsces(osce, user) {
    // console.log("update seen osces called");

    const userId = localStorage.getItem("currentUserId");
    let seenOsces = [];
    if (user) {
      let seenOscesIds = [];
      if (user.seenOsces) {
        // console.log(user.seenOsces);
        seenOsces = user.seenOsces;

        seenOsces.forEach((osce) => {
          seenOscesIds.push(osce.id);
        });
        if (seenOscesIds.includes(osce.osceId)) {
          return;
        }
      } else {
        seenOsces = [];
      }

      if (!seenOscesIds.includes(osce.osceId)) {
        seenOsces.push({
          id: osce.osceId,
          type: osce.type,
          subject: osce.subject,
          title: osce.title,
        });

        // console.log(seenOsces);
        // return;
        firebase.firestore().collection("users").doc(userId).update({
          seenOsces: seenOsces,
        });
      }
    }
  }

  const systems = () => {
    const { subject } = flashcardData;
    return getSubdivisions([subject]);
  };

  const topics = () => {
    const { subject, subdivision } = flashcardData;
    return getTopics(subject, subdivision);
  };

  function handleChange(event, newValue) {
    event.preventDefault();
    setValue(newValue);
  }

  const handleSegmentChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function handleChangeIndex(index) {
    setValue(index);
  }

  let watchTimer = setInterval(checkTimerEnd, 1000);

  function handleCheck(event) {
    let pointsNow = points;
    if (event.currentTarget.checked === true) {
      pointsNow += 1;
      setPoints(pointsNow);
    }
    if (event.currentTarget.checked === false) {
      pointsNow -= 1;
      setPoints(pointsNow);
    }
  }

  function getOsceStats(user, currentOsceId) {
    if (!user.email.length > 0) {
      return;
    }

    if (!user.osceStats || user.osceStats.length < 1) {
      setMissedItemsArray([]);
      setOsceStats({});
      return;
    }

    const osceStats = user.osceStats.filter(
      (stat) => stat.osceId === currentOsceId
    )[0];

    if (!osceStats) {
      return;
    }

    // console.log(osceStats);
    const missedItemsArray = [];
    for (let y = 0; y < osceStats.missedItems.length; y++) {
      const missedItems = osceStats.missedItems;
      let item = {
        osceTitle: osceStats.title,
        noOfTimesTaken: osceStats.noOfTimesTaken,
        noOfTimesMissed: missedItems[y].noOfTimesMissed,
        itemContent: missedItems[y].itemContent,
        itemSegmentTitle: missedItems[y].itemSegmentTitle,
        itemUniqueId: missedItems[y].itemUniqueId,
      };
      missedItemsArray.push(item);
    }

    // for (let x = 0; x < osceStats.length; x++) {
    //   const itemsOsceDetails = {
    //     osceTitle: osceStats[x].title,
    //     noOfTimesTaken: osceStats[x].noOfTimesTaken,
    //   };
    //   for (let y = 0; y < osceStats[x].missedItems.length; y++) {
    //     const missedItems = osceStats[x].missedItems;
    //     let item = {
    //       ...itemsOsceDetails,
    //       noOfTimesMissed: missedItems[y].noOfTimesMissed,
    //       itemContent: missedItems[y].itemContent,
    //       itemSegmentTitle: missedItems[y].itemSegmentTitle,
    //       itemUniqueId: missedItems[y].itemUniqueId,
    //     };
    //     missedItemsArray.push(item);
    //   }
    // }

    missedItemsArray.sort(function (a, b) {
      return b.noOfTimesMissed - a.noOfTimesMissed;
    });
    // console.log(missedItemsArray);
    setMissedItemsArray(missedItemsArray);
    setOsceStats(osceStats);
  }

  function getRemainingTime() {
    return Number(sessionStorage.getItem("timeRemaining"));
  }

  function getResults(event) {
    if (event) {
      event.preventDefault();
    } else {
      const alarm = new Audio(TimeUpAlarm);
      alarm.play();
    }

    const timeRemaining = Number(sessionStorage.getItem("timeRemaining"));
    console.log(timeRemaining);
    console.log(osceTime);
    const timeUsed = (Number(osceTime) - timeRemaining).toFixed(2);
    setTimeUsed(timeUsed);

    const items = document.getElementsByClassName("items");
    const totalPoints = items.length;
    setTotalPoints(totalPoints);

    const missedItems = [];
    for (let item of items) {
      if (item.checked === true) {
        item.parentElement.style.color = "green";
      }
      if (item.checked === false) {
        const itemSegmentNumber = item.id.split("-")[0];
        const itemId = item.id.split("-")[1];
        const itemSegmentTitle =
          osce.segments[itemSegmentNumber - 1].segmentTitle;
        const itemContent =
          osce.segments[itemSegmentNumber - 1].items[itemId - 1].content;
        item.parentElement.style.cssText =
          "border: solid red; border-radius: 4px; background-color: red; color: white; margin: 5px auto; padding: 5px auto}";
        missedItems.push({
          itemId: itemId,
          itemUniqueId: item.id,
          noOfTimesMissed: 1,
          itemSegmentNumber: itemSegmentNumber,
          itemSegmentTitle: itemSegmentTitle,
          itemContent: itemContent,
        });
      }
      item.disabled = true;
    }
    // console.log("missed items", missedItems);

    if (user && osce.type !== "summary") {
      let osceStats = [];
      if (user.osceStats) {
        osceStats = user.osceStats;
        // console.log(user.osceStats);
        let currentOsceStats = osceStats.filter(
          (theOsce) => theOsce.osceId === osce.osceId
        )[0];
        // console.log(currentOsceStats);
        if (!currentOsceStats) {
          // console.log("this osce is not there");
          osceStats.push({
            osceId: osce.osceId,
            title: osce.title,
            missedItems: missedItems,
            timeUsed: timeUsed,
            noOfTimesTaken: 1,
          });
        } else {
          let newMissedItems = [];
          // console.log(currentOsceStats);
          for (let y = 0; y < missedItems.length; y++) {
            const matchingItems = currentOsceStats.missedItems.filter(
              (missedItem) =>
                missedItem.itemUniqueId === missedItems[y].itemUniqueId
            );
            if (matchingItems.length === 1) {
              matchingItems[0].noOfTimesMissed += 1;
              newMissedItems.push(matchingItems[0]);
              currentOsceStats.missedItems =
                currentOsceStats.missedItems.filter(
                  (missedItem) =>
                    missedItem.itemUniqueId !== missedItems[y].itemUniqueId
                );
            }
          }

          for (let x = 0; x < newMissedItems.length; x++) {
            currentOsceStats.missedItems.push(newMissedItems[x]);
          }

          currentOsceStats.timeUsed = (
            (Number(timeUsed) + Number(currentOsceStats.timeUsed)) /
            2
          ).toString();
          currentOsceStats.noOfTimesTaken += 1;
          // console.log("new missed items", newMissedItems);
          // console.log("current osce stats", currentOsceStats);
        }
        const newStats = osceStats.filter(
          (theOsce) => theOsce.osceId !== osce.osceId
        );
        // console.log("new stats before", newStats);
        newStats.push(currentOsceStats);
        // console.log("new stats after", newStats);
        // console.log(newStats);
      } else {
        osceStats.push({
          osceId: osce.osceId,
          title: osce.title,
          missedItems: missedItems,
          timeUsed: timeUsed,
          noOfTimesTaken: 1,
        });
      }

      // console.log(osceStats);
      // return;
      firebase.firestore().collection("users").doc(userId).update({
        osceStats: osceStats,
      });
    }

    clearInterval(watchTimer);
    setFinished(true);
    window.scrollTo(0, 0);
  }

  function handlePracticeStart(event) {
    event.preventDefault();
    // console.log("practice has started")
    const beep = new Audio(Beep);
    beep.play();
    setPracticeStart(true);
  }

  function handleOsceTime(event) {
    const osceTime = event.currentTarget.value;
    setOsceTime(osceTime);
  }

  function checkTimerEnd() {
    if (sessionStorage.getItem("timeUp")) {
      // console.log("timeUp");
      getResults();
      // timeUp();
      clearInterval(watchTimer);
      sessionStorage.removeItem("timeUp");
    }
  }

  if (finished) {
    clearInterval(watchTimer);
  }

  function retakePractice() {
    window.location.reload();
  }

  const handleClickOpen = (type) => {
    console.log(isPremiumUser);
    if (type === "issue-report") {
      setOpen(true);
    } else if (type === "instructions") {
      setOpenInstructions(true);
    } else if (type === "create-flashcard") {
      setOpenCreateFlashCard(true);
    } else if (type === "viva-topics") {
      getVivaTopics();
      setOpenSuggestedVivaTopics(true);
    } else if (type === "analytics") {
      setOpenMoreAnalytics(true);
    } else if (type === "sure-to-delete") {
      setOpenSureToDelete(true);
    } else if (type === "listen-osce") {
      setOpenListenOsce(true);
    }
  };

  const handleClose = (type) => {
    if (type === "issue-report") {
      setOpen(false);
    } else if (type === "instructions") {
      setOpenInstructions(false);
    } else if (type === "create-flashcard") {
      setOpenCreateFlashCard(false);
    } else if (type === "viva-topics") {
      setOpenSuggestedVivaTopics(false);
    } else if (type === "analytics") {
      setOpenMoreAnalytics(false);
    } else if (type === "sure-to-delete") {
      setOpenSureToDelete(false);
    } else if (type === "listen-osce") {
      setOpenListenOsce(false);
    }
  };

  function getVivaTopics() {
    const subject = osce.subject;
    firebase
      .firestore()
      .collection("osces")
      .where("subject", "==", subject)
      .where("type", "==", "summary")
      .orderBy("title", "asc")
      .get()
      .then((querySnapshot) => {
        let vivaTopics = [];
        querySnapshot.forEach((doc) => {
          const viva = doc.data();
          vivaTopics.push(viva);
        });

        setVivaTopics(vivaTopics);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  // function handleReportIssue(event) {
  //   // console.log("handling the report issue")
  //   setIssueToReport(event.currentTarget.value);
  // }

  function reportIssue() {
    if (issueToReport === "") {
      toast.error("Please input your message in the space provided");
      return;
    }
    const { subject, title, osceId } = osce;
    const dateUploaded = Date.now().toString();
    const reportedBy = { email: user.email, firstName: user.firstName };
    // console.log("issue to report", issueToReport);
    // console.log("question Id", osceId)
    // console.log("subject, title", subject, title)

    //Launch the preloader while submitting
    setSubmitting(true);

    firebase
      .firestore()
      .collection("issues")
      .doc(dateUploaded)
      .set({
        content: issueToReport,
        title: title,
        from: "osce",
        subject: subject,
        itemId: osceId,
        issueId: dateUploaded,
        reportedBy: reportedBy,
      })
      .then(() => {
        toast.success(
          "The issue has been reported. Thank You for helping to make Acabest better."
        );
        handleClose("issue-report");
        setSubmitting(false);
      })
      .catch((error) => {
        console.log(error.message);
        setSubmitting(false);
      });
  }

  function getlastModifiedFormatedDate(lastModified) {
    const fullDate = new Date(lastModified).toString();

    const formatedDate = fullDate.split("GMT")[0];
    return formatedDate;
  }

  function startPrintingProcess() {
    const printable = document.getElementById("printable");
    printable.style.display = "block";
    handlePrint();
    printable.style.display = "none";
  }

  function startPrintingAudiobookPDF() {
    let segmentPartsJoined = "";
    for (let x = 0; x < osce.segments.length; x++) {
      for (let y = 0; y < osce.segments[x].items.length; y++) {
        const itemPartsJoined =
          osce.segments[x].items[y].technique +
          " " +
          osce.segments[x].items[y].significance +
          " " +
          osce.segments[x].items[y].explanation;
        segmentPartsJoined += " " + itemPartsJoined;
      }
    }

    const abbreviations = checkForAbbreviations(segmentPartsJoined);
    const abbreviationsContainer = document.getElementById(
      "abbreviations-in-file"
    );
    abbreviationsContainer.innerHTML = `Abbreviations: ${abbreviations}`;
    const audiobookPrintable = document.getElementById("audiobook-printable");
    audiobookPrintable.style.display = "block";
    handlePrintAudiobookPDF();
    audiobookPrintable.style.display = "none";
  }

  function getItemLetterFromId(id) {
    switch (id) {
      case "1":
        return "A";
      case "2":
        return "B";
      case "3":
        return "C";
      case "4":
        return "D";
      case "5":
        return "E";
      case "6":
        return "F";
      case "7":
        return "G";
      case "8":
        return "H";
      case "9":
        return "I";
      case "10":
        return "J";
      case "11":
        return "K";
      case "12":
        return "L";
      case "13":
        return "M";
      case "14":
        return "N";
      case "15":
        return "O";
      case "16":
        return "P";
      case "17":
        return "Q";
      case "18":
        return "R";
      case "19":
        return "S";
      case "20":
        return "T";
      default:
        return "";
    }
  }

  function addFullStop(htmlText) {
    if (htmlText.slice(-5, -4) !== ".") {
      let newText = htmlText.slice(0, -4) + "." + htmlText.slice(-4);
      return newText;
    } else {
      return htmlText;
    }
  }

  function checkForAbbreviations(htmlText) {
    // console.log(htmlText);
    const wordsList = htmlText.split(" ");
    let abbreviations = [];
    for (let x = 0; x < wordsList.length; x++) {
      if (
        wordsList[x].toUpperCase() === wordsList[x] &&
        !abbreviations.includes(wordsList[x]) &&
        wordsList[x] !== ""
      ) {
        // remove the numbers
        if (wordsList[x][0].match(/[A-Z]/i)) {
          abbreviations.push(wordsList[x]);
        }
      }
    }
    return abbreviations;
  }

  function renderLearn() {
    return (
      <TabContainer dir={theme.direction}>
        <Grid item>
          <form onSubmit={getResults}>
            <div>
              <Button
                variant="outlined"
                color="secondary"
                style={{
                  fontSize: "10px",
                  padding: "2px 5px",
                  margin: "0px 5px 10px 5px",
                }}
                onClick={() => handleClickOpen("issue-report")}
              >
                Report an Issue
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                style={{
                  fontSize: "10px",
                  padding: "2px 5px",
                  margin: "0px 5px 10px 5px",
                }}
                onClick={() => handleClickOpen("create-flashcard")}
              >
                Create A Flashcard
              </Button>

              {permitEditOsce && (
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    fontSize: "14px",
                    padding: "2px 5px",
                    margin: "0px 0px 10px 5px",
                  }}
                  onClick={() =>
                    (window.location = `/edit-osce/${osce.osceId}`)
                  }
                >
                  Edit this OSCE
                </Button>
              )}
              {isAdmin && (
                <div>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{
                      fontSize: "14px",
                      padding: "2px 5px",
                      margin: "0px 0px 10px 5px",
                    }}
                    onClick={startPrintingProcess}
                  >
                    GET PDF
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    style={{
                      fontSize: "11px",
                      padding: "2px 5px",
                      margin: "0px 0px 10px 5px",
                    }}
                    onClick={() => setOpenSureToDelete(true)}
                  >
                    Delete this question
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{
                      fontSize: "10px",
                      padding: "2px 5px",
                      margin: "0px 5px 10px 5px",
                    }}
                    onClick={() => handleClickOpen("listen-osce")}
                  >
                    Listen
                  </Button>

                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{
                      fontSize: "14px",
                      padding: "2px 5px",
                      margin: "0px 0px 10px 5px",
                    }}
                    onClick={startPrintingAudiobookPDF}
                  >
                    GET Audiobook PDF
                  </Button>
                </div>
              )}
            </div>

            <div>
              <Dialog
                open={openCreateFlashCard}
                onClose={() => handleClose("create-flashcard")}
                aria-labelledby="create-flashcard-dialog"
              >
                <DialogTitle id="create-flashcard-dialog">
                  Create A Flashcard
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Enter details for your card below. Unless you "Do not allow
                    sharing", your card will be added to the community cards so
                    that other users may benefit from it.
                  </DialogContentText>
                  <div
                    style={{
                      border: "1px solid #969aa2",
                      marginBottom: "20px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    <p style={{ padding: "0 10px", margin: "0" }}>
                      Front of Card
                    </p>
                    <Divider />
                    <CKEditor
                      editor={InlineEditor}
                      data={""}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        const cardData = { ...flashcardData };
                        cardData.cardFrontText = data;
                        setFlashcardData(cardData);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      border: "1px solid #969aa2",
                      marginBottom: "20px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    <p style={{ padding: "0 10px", margin: "0" }}>
                      Back of Card
                    </p>
                    <Divider />
                    <CKEditor
                      editor={InlineEditor}
                      data={""}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        const cardData = { ...flashcardData };
                        cardData.cardBackText = data;
                        setFlashcardData(cardData);
                      }}
                    />
                  </div>
                  <div>
                    <FormControl
                      style={{ width: "100%", margin: "12px auto" }}
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Selct Subject</InputLabel>
                      <Select
                        native
                        value={flashcardData.subject}
                        onChange={(event) => {
                          const cardData = { ...flashcardData };
                          cardData.subject = event.currentTarget.value;
                          cardData.subdivision = ""; // to reset the subdivisions
                          setFlashcardData(cardData);
                        }}
                        id="create-flashcard-subject"
                      >
                        <option value="" />
                        {subjects.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    {flashcardData.subject && (
                      <FormControl
                        style={{ width: "100%", margin: "12px auto" }}
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel>Selct Subdivision</InputLabel>
                        <Select
                          native
                          value={flashcardData.subdivision}
                          onChange={(event) => {
                            const cardData = { ...flashcardData };
                            cardData.subdivision = event.currentTarget.value;
                            cardData.topic = ""; // to reset the topic
                            setFlashcardData(cardData);
                          }}
                          id="create-flashcard-subdivision"
                        >
                          <option value="" />
                          {systems().map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {flashcardData.subdivision && (
                      <FormControl
                        style={{ width: "100%", margin: "12px auto" }}
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel>
                          Select Topic(optional) - recommended
                        </InputLabel>
                        <Select
                          native
                          value={flashcardData.topic}
                          onChange={(event) => {
                            const cardData = { ...flashcardData };
                            cardData.topic = event.currentTarget.value;
                            setFlashcardData(cardData);
                          }}
                          id="create-flashcard-topic"
                        >
                          <option value="" />
                          {topics().map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    <div>
                      <input
                        value="allow"
                        type="checkbox"
                        id="do-not-allow-card-sharing"
                        onChange={(event) => {
                          const cardData = { ...flashcardData };
                          const allow = !event.currentTarget.checked;
                          cardData.allowSharing = allow;
                          setFlashcardData(cardData);
                        }}
                      />
                      <label
                        htmlFor="do-not-allow-card-sharing"
                        style={{
                          marginLeft: "10px",
                          // display: "flex",
                        }}
                      >
                        Do not allow sharing!
                      </label>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => handleClose("create-flashcard")}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button onClick={createFlashcard} color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            {osce.lastModified && permitEditOsce && (
              <Typography variant="caption">
                Last Modified: {getlastModifiedFormatedDate(osce.lastModified)}
              </Typography>
            )}

            {osce.segments &&
              osce.segments.map((segment) => (
                <div key={segment.segmentNo} style={{ margin: "3px auto" }}>
                  <Accordion
                    className="accordion"
                    expanded={expanded === segment.segmentNo}
                    onChange={handleSegmentChange(segment.segmentNo)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={segment.segmentNo}
                      id={segment.segmentNo}
                      className="accordion-summary"
                    >
                      <div className={classes.heading}>
                        {ReactHtmlParser(
                          removeEmptySpaces(segment.segmentTitle)
                        )}
                        {segment.lastModified && permitEditOsce && (
                          <Typography variant="caption">
                            Last Modified:{" "}
                            {getlastModifiedFormatedDate(segment.lastModified)}
                          </Typography>
                        )}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {segment.items.map(
                        (item) =>
                          item.content && (
                            <div key={item.id}>
                              <ul>
                                <li>
                                  <div style={{ fontWeight: "bolder" }}>
                                    {ReactHtmlParser(
                                      removeEmptySpaces(item.content)
                                    )}
                                  </div>
                                  {item.technique &&
                                    item.technique.length > 1 && (
                                      <div>
                                        {ReactHtmlParser(
                                          removeEmptySpaces(item.technique)
                                        )}
                                      </div>
                                    )}
                                  {item.significance &&
                                    item.significance.length > 1 && (
                                      <div>
                                        <em
                                          style={{
                                            backgroundColor: "#ef9c9c",
                                            padding: "4px",
                                            borderRadius: "2px",
                                          }}
                                        >
                                          Significance
                                        </em>
                                        <br />
                                        {ReactHtmlParser(
                                          removeEmptySpaces(item.significance)
                                        )}
                                      </div>
                                    )}
                                  {item.explanation &&
                                    item.explanation.length > 1 && (
                                      <div>
                                        {ReactHtmlParser(
                                          removeEmptySpaces(item.explanation)
                                        )}
                                      </div>
                                    )}
                                </li>
                                <br />
                              </ul>
                            </div>
                          )
                      )}
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </div>
              ))}
            {forceSignIn && (
              <div>
                <Typography>
                  Please Sign In to see the rest of this OSCE
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to={`/login/osce-practice/${osce.osceId}`}
                >
                  Sign In
                </Button>
                <br />
                <br />
                <Typography>
                  If you don't have an account, Click here to Register
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to={`/student-register/osce-practice/${osce.osceId}`}
                >
                  Register
                </Button>
              </div>
            )}
            {makeGoPremium && (
              <div>
                <br />
                <Typography variant="caption">
                  Get a premium account to see the rest of this OSCE.
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to={`/pricing`}
                >
                  Go Premium
                </Button>
                <br />
                <br />
              </div>
            )}
          </form>
        </Grid>
      </TabContainer>
    );
  }

  function makeShowVivaDetails(topic) {
    setCurrentVivaTopicDetails(topic);
    // console.log(topic);
    setShowVivaDetails(true);
  }

  function goBackToSeeVivaTopics() {
    setShowVivaDetails(false);
  }

  function removeParagraphTags(paragraph) {
    let newText = paragraph;

    newText = paragraph.substring(3, paragraph.length - 4);
    // console.log(newText);
    return newText;
  }

  function renderPractice() {
    return (
      <TabContainer dir={theme.direction}>
        <div id="osce-practice">
          {!practiceStart && !finished && (
            <div>
              <form
                style={{ margin: "20px 0px 8px 40px" }}
                onSubmit={handlePracticeStart}
              >
                <TextField
                  disabled={Boolean(practiceStart)}
                  style={{ width: "100px", margin: "0 30px 0 0" }}
                  label="How much time(mins)?"
                  value={osceTime}
                  onChange={handleOsceTime}
                  type="number"
                  //   className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Button
                  disabled={Boolean(!osceTime) || Boolean(practiceStart)}
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ margin: "10px 10px", width: "0px" }}
                >
                  Start
                </Button>
              </form>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleClickOpen("instructions")}
                >
                  How To Practice
                </Button>
              </div>
              <br />

              <div
                className="analytics-container"
                style={{ marginTop: "12px" }}
              >
                <Typography>
                  <strong>ANALYTICS</strong>
                </Typography>
                <br />
                {osceStats.title && osceStats.title.length > 0 && (
                  <div>
                    <Typography variant="body2">
                      You have taken this OSCE{" "}
                      <strong>{osceStats.noOfTimesTaken} times </strong>
                      and it takes you an average of{" "}
                      <strong>{osceStats.timeUsed} minutes </strong>
                      to finish it.
                    </Typography>
                    <br />
                  </div>
                )}
                {missedItemsArray.length > 1 && (
                  <div>
                    <Typography variant="body1">
                      Here are the top 2 OSCE items you miss the most:
                    </Typography>
                    <div className="most-missed-one">
                      <Typography
                        variant="caption"
                        className="analytics-paragraphs"
                        style={{ display: "flex" }}
                      >
                        <p>
                          <strong>1.</strong> You have missed "
                          {ReactHtmlParser(
                            removeParagraphTags(
                              removeEmptySpaces(missedItemsArray[0].itemContent)
                            )
                          )}
                          "
                          <strong style={{ marginLeft: "4px" }}>
                            {missedItemsArray[1].noOfTimesMissed} times.
                          </strong>
                        </p>
                      </Typography>
                    </div>
                    <div
                      className="most-missed-two"
                      style={{ marginBottom: "25px", marginTop: "5px" }}
                    >
                      <Typography
                        variant="caption"
                        className="analytics-paragraphs"
                        style={{
                          display: "flex",
                        }}
                      >
                        <p>
                          <strong>2.</strong> You have missed "
                          {ReactHtmlParser(
                            removeParagraphTags(
                              removeEmptySpaces(missedItemsArray[1].itemContent)
                            )
                          )}
                          "
                          <strong style={{ marginLeft: "4px" }}>
                            {missedItemsArray[1].noOfTimesMissed} times.
                          </strong>
                        </p>
                      </Typography>
                    </div>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleClickOpen("analytics")}
                    >
                      See More Analytics Data
                    </Button>
                  </div>
                )}
                {missedItemsArray.length < 1 && (
                  <div>
                    <Typography variant="caption">
                      Here you will see data on which OSCE items you miss most
                      often, average time you spend, etc. This will help you to
                      keep track of your progress.
                    </Typography>

                    <Typography variant="body2">
                      Not enough data yet, your analytics data will appear as
                      you practice this OSCE.
                    </Typography>
                  </div>
                )}
              </div>

              <div>
                <Dialog
                  open={openMoreAnalytics}
                  onClose={() => handleClose("analytics")}
                  aria-labelledby="analytics-dialog"
                >
                  <DialogTitle id="analytics-dialog">Your Progress</DialogTitle>
                  <DialogContent>
                    {/* <Typography variant="caption">
                      <em>Click on an OSCE to see details</em>
                    </Typography>
                    {osceStats.map((stat) => (
                      
                    ))} */}
                    <div style={{ margin: "3px auto" }}>
                      <Typography variant="caption">
                        You have practiced this OSCE {osceStats.noOfTimesTaken}{" "}
                        times.
                      </Typography>
                      <br />
                      <Typography variant="caption">
                        You use an average of {osceStats.timeUsed} minutes to
                        finish this OSCE.
                      </Typography>
                      <br />
                      <br />
                      <Typography variant="body2">
                        <strong>Commonly missed items: </strong>
                      </Typography>
                      <br />
                      <div style={{ marginLeft: "10px" }}>
                        {osceStats.missedItems &&
                          osceStats.missedItems
                            .sort(function (a, b) {
                              return b.noOfTimesMissed - a.noOfTimesMissed;
                            })
                            .map((item) => (
                              <div key={item.itemUniqueId}>
                                <Typography
                                  variant="caption"
                                  className="analytics-paragraphs"
                                  style={{ display: "flex" }}
                                >
                                  <p>
                                    <span style={{ marginRight: "4px" }}>
                                      -
                                    </span>
                                    {ReactHtmlParser(
                                      removeParagraphTags(
                                        removeEmptySpaces(item.itemContent)
                                      )
                                    )}
                                    <strong style={{ marginLeft: "4px" }}>
                                      {" - "}
                                      {item.noOfTimesMissed === 1
                                        ? `Once`
                                        : `${item.noOfTimesMissed} times`}
                                      .
                                    </strong>
                                  </p>
                                </Typography>
                                <br />
                              </div>
                            ))}
                      </div>
                      <Divider />
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => handleClose("analytics")}
                      color="primary"
                    >
                      Okay
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          )}

          <Grid
            item
            container
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "0 0 10px 0",
            }}
          >
            {practiceStart && !finished && <Timer timeLength={osceTime} />}
          </Grid>

          {finished && (
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="body1">
                  You finished in <strong>{timeUsed}</strong> minutes
                </Typography>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h6">
                  {points} out of {totalPoints} Checked (
                  {Math.round((points / totalPoints) * 100)}%)
                </Typography>
                <Divider />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "7px auto",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={retakePractice}
                >
                  Retake
                </Button>
              </div>
            </div>
          )}

          {/* {console.log(osce.segments)} */}
          {practiceStart && (
            <Grid
              justifyContent="space-around"
              className="osce-practice-cont"
              alignItems="center"
              container
            >
              <Grid item xs={12}>
                <Grid item container justifyContent="center">
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{
                      fontSize: "10px",
                      padding: "2px 5px",
                      margin: "0px 5px 10px 5px",
                    }}
                    onClick={() => handleClickOpen("issue-report")}
                  >
                    Report an Issue
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      fontSize: "10px",
                      padding: "2px 5px",
                      margin: "0px 5px 10px 5px",
                    }}
                    onClick={() => handleClickOpen("instructions")}
                  >
                    instructions
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      fontSize: "10px",
                      padding: "2px 5px",
                      margin: "0px 5px 10px 5px",
                    }}
                    onClick={() => window.location.reload()}
                  >
                    Reset
                  </Button>
                </Grid>

                <div>
                  <Dialog
                    open={openSuggestedVivaTopics}
                    onClose={() => handleClose("viva-topics")}
                    aria-labelledby="viva-topics-dialog"
                  >
                    <DialogTitle id="viva-topics-dialog">
                      <div style={{ textAlign: "center" }}>
                        <Timer timeLength={getRemainingTime()} />
                      </div>
                      {showVivaDetails && currentVivaTopicDetails
                        ? `${currentVivaTopicDetails.title}`
                        : "Suggested Viva Topics"}
                    </DialogTitle>
                    {vivaTopics.length > 0 && !showVivaDetails && (
                      <DialogContent>
                        {vivaTopics
                          .sort(function (a, b) {
                            return b.title - a.title;
                          })
                          .map((topic) => (
                            <Button
                              key={topic.osceId}
                              style={{
                                textTransform: "capitalize",
                                color: "blue",
                                textDecoration: "underline",
                              }}
                              onClick={() => makeShowVivaDetails(topic)}
                            >
                              {topic.title}
                            </Button>
                          ))}
                      </DialogContent>
                    )}

                    {vivaTopics.length > 0 && showVivaDetails && (
                      <DialogContent>
                        {currentVivaTopicDetails.segments &&
                          currentVivaTopicDetails.segments.map((segment) => (
                            <div
                              key={segment.segmentNo}
                              style={{ margin: "3px auto" }}
                            >
                              <Accordion
                                className="accordion"
                                expanded={expanded === segment.segmentNo}
                                onChange={handleSegmentChange(
                                  segment.segmentNo
                                )}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls={segment.segmentNo}
                                  id={segment.segmentNo}
                                  className="accordion-summary"
                                >
                                  <div className={classes.heading}>
                                    {ReactHtmlParser(
                                      removeEmptySpaces(segment.segmentTitle)
                                    )}
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {segment.items.map(
                                    (item) =>
                                      item.content && (
                                        <div key={item.id}>
                                          <ul>
                                            <li>
                                              <div
                                                style={{
                                                  fontWeight: "bolder",
                                                }}
                                              >
                                                {ReactHtmlParser(
                                                  removeEmptySpaces(
                                                    item.content
                                                  )
                                                )}
                                              </div>
                                              {item.technique &&
                                                item.technique.length > 1 && (
                                                  <div>
                                                    {ReactHtmlParser(
                                                      removeEmptySpaces(
                                                        item.technique
                                                      )
                                                    )}
                                                  </div>
                                                )}
                                              {item.significance &&
                                                item.significance.length >
                                                  1 && (
                                                  <div>
                                                    <em
                                                      style={{
                                                        backgroundColor:
                                                          "#ef9c9c",
                                                        padding: "4px",
                                                        borderRadius: "2px",
                                                      }}
                                                    >
                                                      Significance
                                                    </em>
                                                    <br />
                                                    {ReactHtmlParser(
                                                      removeEmptySpaces(
                                                        item.significance
                                                      )
                                                    )}
                                                  </div>
                                                )}
                                              {item.explanation &&
                                                item.explanation.length > 1 && (
                                                  <div>
                                                    {ReactHtmlParser(
                                                      removeEmptySpaces(
                                                        item.explanation
                                                      )
                                                    )}
                                                  </div>
                                                )}
                                            </li>
                                            <br />
                                            <br />
                                          </ul>
                                        </div>
                                      )
                                  )}
                                </AccordionDetails>
                              </Accordion>
                              <Divider />
                            </div>
                          ))}
                      </DialogContent>
                    )}
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: showVivaDetails
                          ? "space-between"
                          : "flex-end",
                      }}
                    >
                      {showVivaDetails && (
                        <Button
                          onClick={() => goBackToSeeVivaTopics()}
                          color="primary"
                        >
                          <ArrowBackIcon />
                          Back to topics
                        </Button>
                      )}
                      <Button
                        onClick={() => handleClose("viva-topics")}
                        color="primary"
                      >
                        Exit
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                container
                justifyContent="space-around"
                className="osce-practice-form"
              >
                <form onSubmit={getResults}>
                  {osce.segments &&
                    osce.segments.map((segment) => (
                      <div
                        key={segment.segmentNo}
                        style={{ margin: "10px auto" }}
                      >
                        <Typography
                          component="h2"
                          variant="h5"
                          style={{ marginLeft: "10px", marginTop: "30px" }}
                        >
                          {ReactHtmlParser(
                            removeEmptySpaces(segment.segmentTitle)
                          )}
                        </Typography>
                        {segment.items.map(
                          (item) =>
                            item.content && (
                              <div
                                key={item.id}
                                className="osce-practice-checkboxes"
                                style={{
                                  margin: "5px auto",
                                  padding: "5px auto",
                                }}
                              >
                                <input
                                  style={{ marginRight: "10px" }}
                                  type="checkbox"
                                  name={item.id}
                                  id={`${segment.segmentNo}-${item.id}`}
                                  className="items"
                                  value={ReactHtmlParser(
                                    removeEmptySpaces(item.content)
                                  )}
                                  onChange={handleCheck}
                                />
                                <label
                                  htmlFor={`${segment.segmentNo}-${item.id}`}
                                >
                                  {ReactHtmlParser(
                                    removeEmptySpaces(item.content)
                                  )}
                                </label>
                              </div>
                            )
                        )}
                        <Divider />
                      </div>
                    ))}

                  {forceSignIn && (
                    <div>
                      <Typography>
                        Please Sign In to continue practicing this OSCE
                      </Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        component={Link}
                        to={`/login/osce-practice/${osce.osceId}`}
                      >
                        Sign In
                      </Button>
                      <br />
                      <br />
                      <Typography>
                        If you don't have an account, Click here to Register
                      </Typography>

                      <Button
                        variant="contained"
                        color="secondary"
                        component={Link}
                        to={`/student-register/osce-practice/${osce.osceId}`}
                      >
                        Register
                      </Button>
                      <br />
                      <br />
                    </div>
                  )}
                  {makeGoPremium && (
                    <div>
                      <br />
                      <Typography>
                        Get a premium account to continue practicing the rest of
                        this OSCE.
                      </Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        component={Link}
                        to={`/pricing`}
                      >
                        Go Premium
                      </Button>
                      <br />
                      <br />
                    </div>
                  )}

                  {osce.type !== "summary" && (
                    <Grid item className="viva-suggestion">
                      <Button
                        style={{
                          textTransform: "capitalize",
                          color: "blue",
                          textDecoration: "underline",
                          fontSize: "15px",
                        }}
                        onClick={() => handleClickOpen("viva-topics")}
                      >
                        Explore Viva Topics for follow up questions?
                      </Button>
                    </Grid>
                  )}
                  <br />

                  <Grid item style={{ margin: "auto" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                      disabled={finished || !practiceStart}
                    >
                      Finish
                    </Button>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          )}
          <div>
            <Dialog
              open={openInstructions}
              onClose={() => handleClose("instructions")}
              aria-labelledby="instructions-dialog"
            >
              <DialogTitle id="instructions-dialog">
                How To Practice
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Typography variant="caption">
                    <strong>1.</strong> It's best to practice with a friend. The
                    person will check the list as you practice the OSCE.
                  </Typography>
                  <br />
                  <Typography variant="caption">
                    <strong>2.</strong> When you're done, the person will select
                    finish. The results will then appear; it will show the items
                    you did and the ones you didn't do, as well as the time you
                    used.
                  </Typography>
                  <br />
                  <Typography variant="caption">
                    <strong>3.</strong> Also, the results will appear
                    automatically when time is up and you aren't done.
                  </Typography>
                  <br />
                  <Typography variant="caption">Enjoy!</Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleClose("instructions")}
                  color="primary"
                >
                  Okay
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </TabContainer>
    );
  }

  function createFlashcard() {
    // console.log("creating your card");
    // console.log(flashcardData);
    const {
      cardFrontText,
      cardBackText,
      subject,
      subdivision,
      topic,
      allowSharing,
    } = flashcardData;
    const creator = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    };
    const cardId = Date.now().toString();
    const userId = localStorage.getItem("currentUserId");

    if (!cardFrontText || !cardBackText || !subject || !subdivision) {
      toast.error(
        "Card front text, Card back text, Subject and Subdivision are required!"
      );
      return;
    }
    // console.log(cardId);

    //Launch the preloader while submitting
    setSubmitting(true);

    let userCards = [];
    let dateObj = new Date();
    dateObj.setUTCHours(0, 0, 0, 0);
    const midNight = +dateObj;

    let updateUserData = {};
    if (user) {
      if (user.flashcards) {
        userCards = user.flashcards;
      } else {
        userCards = [];
      }
      let userCardsIds = [];
      userCards.forEach((card) => {
        userCardsIds.push(card.id);
      });

      if (!userCardsIds.includes(cardId)) {
        // console.log(userCards);
        userCards.push({
          frontText: cardFrontText,
          backText: cardBackText,
          subject: subject,
          system: subdivision,
          topic: topic,
          creator: creator,
          id: cardId,
          upvotes: 0,
          reports: [],
          allowSharing: allowSharing,
          phase: "new",
          interval: 86400000,
          ease: 2.5,
          dueDate: midNight + 86400000,
          dueDateInWords: new Date(midNight + 86400000).toString(),
          lapses: 0,
        });
        updateUserData = firebase
          .firestore()
          .collection("users")
          .doc(userId)
          .update({
            flashcards: userCards,
          });
      }
    }

    const sendCardToCommunity = firebase
      .firestore()
      .collection("flashcards")
      .doc(cardId)
      .set({
        frontText: cardFrontText,
        backText: cardBackText,
        subject: subject,
        system: subdivision,
        topic: topic,
        creator: creator,
        id: cardId,
        upvotes: 0,
        reports: [],
        allowSharing: allowSharing,
      });

    Promise.all([updateUserData, sendCardToCommunity])
      .then(() => {
        // console.log("everything went smoothly");
        setOpenCreateFlashCard(false);
        setFlashcardData({
          cardFrontText: "",
          cardBackText: "",
          subject: "",
          subdivision: "",
          topic: "",
          allowSharing: true,
        });
        setSubmitting(false);
        toast.success("Flashcard successfully created");
      })
      .catch((error) => {
        console.log(error.message);
        setSubmitting(false);
      });
  }

  const componentRef = useRef();
  const audiobookComponentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handlePrintAudiobookPDF = useReactToPrint({
    content: () => audiobookComponentRef.current,
  });

  const doDeleteOsce = (osce) => {
    console.log(osce);
    if (!isAdmin) {
      toast.error("You do not have the permission to delete a question");
      return;
    }

    setSubmitting(true);

    // return;

    const deleteOsce = firebase
      .firestore()
      .collection("osces")
      .doc(osce.osceId)
      .delete();

    const decrementSubjectStats = firebase
      .firestore()
      .collection(`statistics/subjects/subjectData`)
      .doc(osce.subject)
      .update({
        noOfOsces: firebase.firestore.FieldValue.increment(-1),
      });
    const decrementOsceTypeStats = firebase
      .firestore()
      .collection(`statistics/osceTypes/osceTypeData`)
      .doc(osce.type)
      .update({
        noOfOsces: firebase.firestore.FieldValue.increment(-1),
      });
    const decrementOsceTypeSubjectStats = firebase
      .firestore()
      .collection(`statistics/osceTypes/osceTypeData/${osce.type}/subjects`)
      .doc(osce.subject)
      .update({
        noOfOsces: firebase.firestore.FieldValue.increment(-1),
      });
    const decrementGeneralStats = firebase
      .firestore()
      .collection(`statistics`)
      .doc("general")
      .update({
        osces: firebase.firestore.FieldValue.increment(-1),
      });

    Promise.all([
      deleteOsce,
      decrementSubjectStats,
      decrementOsceTypeStats,
      decrementOsceTypeSubjectStats,
      decrementGeneralStats,
    ])
      .then(() => {
        setSubmitting(false);
        toast.success("OSCE Deleted Successfully");
        setOpenSureToDelete(false);
        setTimeout(() => {
          window.location = "/osce";
        }, 2000);
      })
      .catch((error) => {
        toast.error("Something went wrong!");
        setSubmitting(false);
        console.log(error.message);
      });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{osce.title}</title>
        {/* <meta name="description" content={osce.shortDescription} /> */}
      </Helmet>
      {!osce.segments || submitting ? (
        <PreLoader />
      ) : (
        <div>
          <Grid>
            {!isPremiumUser && (
              <Paper
                style={{
                  textAlign: "center",
                  backgroundColor:
                    user.seenOsces && user.seenOsces.length < 12
                      ? "green"
                      : "#ae0f48",
                  color: "#fff",
                  borderRadius: "0px",
                  padding: "3px",
                }}
              >
                Free OSCE guides Limit:{" "}
                <strong>
                  {user.seenOsces && user.seenOsces.length < 16
                    ? user.seenOsces.length
                    : "15"}
                </strong>{" "}
                out of <strong>15</strong>
              </Paper>
            )}
            <Paper style={{ backgroundColor: "#9ea8dd" }}>
              <Typography
                color="primary"
                style={{
                  textTransform: "capitalize",
                  textAlign: "center",
                }}
                variant="h6"
                component="h6"
              >
                OSCE - {osce.title}
              </Typography>
            </Paper>
          </Grid>
          <Grid item lg={8} style={{ margin: "auto" }}>
            <div className={classes.root}>
              <AppBar position="sticky" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab label="READ/LEARN" style={{ fontWeight: "bold" }} />
                  {!practiceStart && (
                    <Tab
                      label="PRACTICE"
                      style={{ fontWeight: "bold" }}
                      onClick={window.scroll(0, 0)}
                    />
                  )}
                  {practiceStart && (
                    <Tab label="PRACTICE" style={{ fontWeight: "bold" }} />
                  )}
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.06)" }}
              >
                {renderLearn()}
                {renderPractice()}
              </SwipeableViews>
            </div>
          </Grid>

          <div>
            <Dialog
              open={open}
              onClose={() => handleClose("issue-report")}
              aria-labelledby="issue-report-dialog"
            >
              <DialogTitle id="issue-report-dialog">
                Report An Issue
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Explain the issue to us in the space below. Thank You!
                </DialogContentText>
                {/* <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="What is the issue?"
                            type="text"
                            onChange={handleReportIssue}
                            fullWidth
                          /> */}
                <div
                  style={{
                    border: "1px solid #969aa2",
                    marginBottom: "20px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  <p style={{ padding: "0 10px", margin: "0" }}>
                    Enter Issue Below
                  </p>
                  <Divider />
                  <CKEditor
                    editor={InlineEditor}
                    data={""}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setIssueToReport(data);
                    }}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleClose("issue-report")}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button onClick={reportIssue} color="primary">
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          <div>
            <Dialog
              open={openSureToDelete}
              onClose={() => handleClose("sure-to-delete")}
              aria-labelledby="sure-to-delete-dialog"
            >
              <DialogTitle id="sure-to-delete-dialog">
                Are you sure?
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  This process cannot be reversed. Are you sure you want to
                  proceeed?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleClose("sure-to-delete")}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button onClick={() => doDeleteOsce(osce)} color="primary">
                  Yes, Delete!
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          <div>
            <Dialog
              open={openListenOsce}
              onClose={() => handleClose("listen-osce")}
              aria-labelledby="listen-osce-dialog"
            >
              <DialogTitle id="listen-osce-dialog">{osce.title}</DialogTitle>
              <DialogContent>
                {/* <iframe
                  width="100%"
                  height="200"
                  scrolling="no"
                  frameBorder="no"
                  title="listen-osce"
                  allow="autoplay"
                  src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1229072965%3Fsecret_token%3Ds-nw71MJk8rG9&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=true#t=0:15"
                ></iframe> */}

                <iframe
                  width="100%"
                  height="300"
                  scrolling="no"
                  frameBorder="no"
                  title="listen-osce"
                  allow="autoplay"
                  src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1406972401%3Fsecret_token%3Ds-gbgFY1fqlfv&color=%23ff5500&auto_play=true&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=true"
                ></iframe>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleClose("listen-osce")}
                  color="primary"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          <Grid
            id="printable"
            style={{
              margin: "20px 10px",
              display: "none",
            }}
            ref={componentRef}
          >
            <Typography
              variant="h4"
              style={{ textAlign: "center", fontWeight: "bold" }}
            >
              {osce.title}
            </Typography>
            {osce.lastModified && (
              <Typography variant="caption">
                Last Modified: {getlastModifiedFormatedDate(osce.lastModified)}
              </Typography>
            )}

            <Grid>
              {osce.segments.map((segment) => (
                <div key={segment.segmentNo} style={{ margin: "50px auto" }}>
                  <div
                    className={classes.heading}
                    style={{ display: "flex", fontSize: "23px" }}
                  >
                    {segment.segmentNo}.{" "}
                    {ReactHtmlParser(removeEmptySpaces(segment.segmentTitle))}
                  </div>
                  {segment.lastModified && permitEditOsce && (
                    <Typography variant="caption">
                      Last Modified:{" "}
                      {getlastModifiedFormatedDate(segment.lastModified)}
                    </Typography>
                  )}
                  {segment.items.map(
                    (item) =>
                      item.content && (
                        <div key={item.id} style={{ fontSize: "18px" }}>
                          <ul>
                            <li>
                              <div style={{ fontWeight: "bolder" }}>
                                {ReactHtmlParser(
                                  removeEmptySpaces(item.content)
                                )}
                              </div>
                              {item.technique && item.technique.length > 1 && (
                                <div>
                                  {ReactHtmlParser(
                                    removeEmptySpaces(item.technique)
                                  )}
                                </div>
                              )}
                              {item.significance &&
                                item.significance.length > 1 && (
                                  <div>
                                    <em
                                      style={{
                                        backgroundColor: "#ef9c9c",
                                        padding: "4px",
                                        borderRadius: "2px",
                                      }}
                                    >
                                      Significance
                                    </em>
                                    <br />
                                    {ReactHtmlParser(
                                      removeEmptySpaces(item.significance)
                                    )}
                                  </div>
                                )}
                              {item.explanation &&
                                item.explanation.length > 1 && (
                                  <div>
                                    {ReactHtmlParser(
                                      removeEmptySpaces(item.explanation)
                                    )}
                                  </div>
                                )}
                            </li>
                            <br />
                          </ul>
                        </div>
                      )
                  )}

                  <Divider />
                </div>
              ))}
            </Grid>
          </Grid>

          <Grid
            id="audiobook-printable"
            style={{
              margin: "20px 10px",
              display: "none",
            }}
            ref={audiobookComponentRef}
          >
            <Typography variant="h6" id="abbreviations-in-file"></Typography>
            <br />
            <br />
            <Typography variant="body1">Audiobooks by acabest.com.</Typography>
            <br />
            <Typography variant="h4">{osce.title}.</Typography>

            <Grid>
              {osce.segments.map((segment) => (
                <div key={segment.segmentNo} style={{ margin: "50px auto" }}>
                  <div
                    className={classes.heading}
                    style={{ display: "flex", fontSize: "23px" }}
                  >
                    Section {segment.segmentNo}:&nbsp;
                    {ReactHtmlParser(removeEmptySpaces(segment.segmentTitle))}.
                  </div>
                  {segment.items.map(
                    (item) =>
                      item.content && (
                        <div key={item.id} style={{ fontSize: "18px" }}>
                          <div
                            style={{
                              fontWeight: "bolder",
                              display: "flex",
                            }}
                          >
                            {getItemLetterFromId(item.id)}, &nbsp;
                            {ReactHtmlParser(
                              addFullStop(removeEmptySpaces(item.content))
                            )}
                          </div>
                          {item.technique && item.technique.length > 1 && (
                            <div>
                              {ReactHtmlParser(
                                addFullStop(removeEmptySpaces(item.technique))
                              )}
                            </div>
                          )}
                          {item.significance && item.significance.length > 1 && (
                            <div>
                              <br />
                              <em
                                style={{
                                  borderRadius: "2px",
                                }}
                              >
                                Significance.
                              </em>
                              <br />
                              {ReactHtmlParser(
                                addFullStop(
                                  removeEmptySpaces(item.significance)
                                )
                              )}
                            </div>
                          )}
                          {item.explanation && item.explanation.length > 1 && (
                            <div>
                              {ReactHtmlParser(
                                addFullStop(removeEmptySpaces(item.explanation))
                              )}
                            </div>
                          )}
                          <br />
                          <br />
                        </div>
                      )
                  )}
                  <br />
                  <br />
                  <br />
                  <Divider />
                </div>
              ))}
            </Grid>
          </Grid>
        </div>
      )}
    </React.Fragment>
  );
}
