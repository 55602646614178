import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Joi from "joi-browser";
import Form from "./common/form";
import { getCategories } from "../utils/getCategories";
import firebase from "firebase/app";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import PreLoader from "./common/preLoader";
import { Helmet } from "react-helmet";
import { getCountriesList } from "../utils/getCountriesInfo";
import {
  getInstitutionsByCountry,
  getInstitutionsList,
} from "../utils/getUniversitiesInfo";
import { getSubjectNameById, getSubjects } from "../utils/getCategories";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";

class MyProfile extends Form {
  state = {
    userId: "",
    user: {},
    data: {
      firstName: "",
      lastName: "",
      country: "",
      institution: "",
      level: "",
    },
    errors: {},
    showEditProfile: false,
    takeSubjects: false,
    userSubjects: [],
    premiumUser: false,
  };

  schema = {
    firstName: Joi.string().min(3).max(30).required().label("First Name"),
    lastName: Joi.string().min(3).max(30).required().label("Last Name"),
    country: Joi.string().required().label("Country"),
    institution: Joi.string().required().label("Institution"),
    level: Joi.string().required().label("Level"),
  };

  componentDidMount() {
    const userId = this.props.match.params.userid;

    const statistics = JSON.parse(localStorage.getItem("statistics"));

    // GETTING THE SUBJECTS LIST
    const subjects = getSubjects(statistics.subjects);
    const findSubjectsWithRelevantContent = subjects.filter(
      (subject) => subject.noOfQuestions > 0
    );
    const subjectsWithRelevantContent = getSubjects(
      findSubjectsWithRelevantContent
    );

    this.setState({ subjects, subjectsWithRelevantContent });

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .onSnapshot((doc) => {
        const user = doc.data();
        if (user) {
          firebase
            .firestore()
            .collection("premiumUsers")
            .doc(user.email)
            .onSnapshot((doc) => {
              const premiumUser = doc.data();
              if (premiumUser && premiumUser.subscription.type === "basic") {
                this.setState({ premiumUser });
              } else {
                this.setState({ premiumUser: false });
              }
            });
        }

        this.setState({
          userId,
          user,
          data: {
            firstName: user.firstName,
            lastName: user.lastName,
            country: user.country,
            institution: user.institution,
            level: user.level,
          },
          userSubjects: user.userSubjects,
        });
      });
  }

  countries = getCountriesList();
  institutions = () => {
    const { country } = this.state.data;

    if (country) {
      return getInstitutionsByCountry(country);
    } else {
      return getInstitutionsList();
    }
  };
  levels = [
    { name: "PreClinical", id: "preclinical" },
    { name: "Clinical", id: "clinical" },
    { name: "Non-specific", id: "non-specific" },
  ];

  doSubmit = () => {
    const { userId, data } = this.state;
    // Call the server
    const firstName = data.firstName.trim();
    const lastName = data.lastName.trim();
    const country = data.country.trim();
    const institution = data.institution.trim();
    const level = data.level.trim();

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update({
        firstName: firstName,
        lastName: lastName,
        country: country,
        institution: institution,
        level: level,
      })
      .then(() => {
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        localStorage.setItem("userLevel", level);
        toast.success("Successfully Updated Profile!");
      })
      .catch((error) => {
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        const errorMessage = error.message;
        toast.error(errorMessage);
        return;
      });
  };

  categories = getCategories();
  tutorPositionList = [
    { name: "Lecturer", id: "lecturer" },
    { name: "Teaching Assistant", id: "teaching-assistant" },
  ];

  takeSubjects = () => {
    const { takeSubjects } = this.state;

    this.setState({ takeSubjects: !takeSubjects });
    if (!takeSubjects) {
      this.setState({ userSubjects: [] });
    }
  };

  handleSubjectChange = (event) => {
    let userSubjects = this.state.userSubjects;
    const subject = event.currentTarget;
    console.log(userSubjects);
    console.log(subject);

    if (subject.checked) {
      if (userSubjects.length === 5) {
        toast.error("You may select a maximum of 5 subjects");
        subject.checked = false;
        return;
      }

      userSubjects.push(subject.value);
      console.log(userSubjects);
      this.setState(userSubjects);
    }
  };

  submitSubjects = (event) => {
    event.preventDefault();
    const userId = localStorage.getItem("currentUserId");
    const { userSubjects } = this.state;
    console.log(userSubjects);

    if (userSubjects.length < 1) {
      toast.error("You must select at least one Subject");
      return;
    }

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update({
        userSubjects,
      })
      .then(() => {
        //Remove the preloader when submitting is done
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        toast.success("Your subjects have been submitted");
        const { data } = { ...this.state };
        data.userSubjects = userSubjects;
        this.setState({ user: data });
        this.setState({ accountCompleted: true });
      })
      .catch((error) => {
        //Remove the preloader when submitting is done
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        console.log(error.message);
      });
  };

  getStartDate = () => {
    const { premiumUser } = this.state;
    const startDate = new Date(premiumUser.subscription.startDate);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const year = startDate.getFullYear();
    // console.log(year);

    const month = months[startDate.getMonth()];
    const date = startDate.getDate();
    const day = days[startDate.getDay()];

    const dateInOrder = `${day}, ${month} ${date}, ${year}`;
    return dateInOrder;
  };

  getEndDate = () => {
    const { premiumUser } = this.state;
    const endDate = new Date(premiumUser.subscription.endDate);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const year = endDate.getFullYear();
    // console.log(year);

    const month = months[endDate.getMonth()];
    const date = endDate.getDate();
    const day = days[endDate.getDay()];

    const dateInOrder = `${day}, ${month} ${date}, ${year}`;
    return dateInOrder;
  };

  render() {
    const { user, submitting, takeSubjects, premiumUser, subjects } =
      this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>My Profile - Acabest</title>
        </Helmet>

        <Grid>
          <Paper style={{ backgroundColor: "#9ea8dd" }}>
            <Typography
              color="primary"
              style={{
                textTransform: "capitalize",
                textAlign: "center",
              }}
              variant="h6"
              component="h6"
            >
              My Profile - {`${user.lastName}, ${user.firstName}`}
            </Typography>
          </Paper>
        </Grid>

        {submitting || !user.email ? (
          <PreLoader />
        ) : (
          <Grid item md={9} lg={6} style={{ margin: "auto", padding: "10px" }}>
            <Container
              component="main"
              maxWidth="sm"
              style={{
                margin: "10px auto",
                width: "95%",
                background:
                  "linear-gradient(225deg, rgb(63 81 181 / 86%), rgb(0 123 255 / 12%) 70.71%)",
                padding: "4rem 2rem",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                borderRadius: "10px",
              }}
            >
              <form onSubmit={this.handleSubmit}>
                {this.renderTextField("firstName", "First Name")}
                {this.renderTextField("lastName", "Last Name")}
                {this.renderSelect("country", "Country", this.countries)}
                {this.renderSelect(
                  "institution",
                  "Select Institution",
                  this.institutions()
                )}
                {this.renderSelect("level", "Select Level", this.levels)}
                {this.renderSubmitButton("Submit Changes")}
              </form>
            </Container>

            <Typography style={{ margin: "20px" }}>
              <strong>Your Subjects:</strong>
            </Typography>
            <p style={{ marginLeft: "35px" }}>
              {user.userSubjects &&
                user.userSubjects.map((subject) => (
                  <span key={subject}>{getSubjectNameById(subject)}, </span>
                ))}
            </p>
            <form onSubmit={this.submitSubjects}>
              <div style={{ margin: "20px" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.takeSubjects}
                >
                  Change Subjects{" "}
                  {takeSubjects ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
                {takeSubjects &&
                  subjects.map((subject) => (
                    <div
                      key={subject.id}
                      style={{ margin: "5px 20px", padding: "5px auto" }}
                    >
                      <input
                        style={{ marginRight: "10px" }}
                        type="checkbox"
                        name={subject.id}
                        id={`${subject.name}-${subject.id}`}
                        value={subject.id}
                        onChange={this.handleSubjectChange}
                      />
                      <label htmlFor={`${subject.name}-${subject.id}`}>
                        {subject.name}
                      </label>
                    </div>
                  ))}
              </div>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginLeft: "20px" }}
              >
                Submit subjects
              </Button>
            </form>

            <Grid item>
              <Typography style={{ margin: "50px 0px 10px 20px" }}>
                <strong>SUBSCRIPTION:</strong>
              </Typography>
              {!premiumUser && (
                <Grid style={{ margin: "10px 0px 10px 20px" }}>
                  <Typography variant="body1">
                    You are currently on the FREE plan. Upgrade to Premium and
                    enjoy the full package Acabest offers.
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to={`/pricing`}
                  >
                    Go Premium
                  </Button>
                </Grid>
              )}
              {premiumUser && (
                <Grid style={{ margin: "10px 0px 10px 20px" }}>
                  <Typography variant="body1">
                    <em>Started On:</em> {this.getStartDate()}
                  </Typography>
                  <Typography variant="body1">
                    <em>Due On:</em> {this.getEndDate()}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to={`/pricing`}
                  >
                    Cancel Subscription
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    );
  }
}

export default MyProfile;
