import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Button from "@material-ui/core/Button";
import firebase from "firebase/app";
import { toast } from "react-toastify";
import { getOsceSubjects } from "../utils/getCategories";
import PreLoader from "./common/preLoader";
import { getOsceTypes } from "../utils/getCategories";
import { Helmet } from "react-helmet";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

class OsceUpload extends Form {
  state = {
    data: {
      title: "",
      subject: "",
      type: "",
      // readLink: ""
    },
    errors: {},
    showAddSegments: false,
    segmentData: {
      segmentTitle: "",
      item1: "",
      technique1: "",
      significance1: "",
      explanation1: "",
      item2: "",
      technique2: "",
      significance2: "",
      explanation2: "",
      item3: "",
      technique3: "",
      significance3: "",
      explanation3: "",
      item4: "",
      technique4: "",
      significance4: "",
      explanation4: "",
      item5: "",
      technique5: "",
      significance5: "",
      explanation5: "",
      item6: "",
      technique6: "",
      significance6: "",
      explanation6: "",
      item7: "",
      technique7: "",
      significance7: "",
      explanation7: "",
      item8: "",
      technique8: "",
      significance8: "",
      explanation8: "",
      item9: "",
      technique9: "",
      significance9: "",
      explanation9: "",
      item10: "",
      technique10: "",
      significance10: "",
      explanation10: "",
      // item11: "",
      // technique11: "",
      // significance11: "",
      // explanation11: "",
      // item12: "",
      // technique12: "",
      // significance12: "",
      // explanation12: "",
      // item13: "",
      // technique13: "",
      // significance13: "",
      // explanation13: "",
      // item14: "",
      // technique14: "",
      // significance14: "",
      // explanation14: "",
      // item15: "",
      // technique15: "",
      // significance15: "",
      // explanation15: "",
    },
    segmentErrors: {},
    continueToAddOsce: false,
  };

  schema = {
    title: Joi.string().min(3).max(200).required().label("Title"),
    subject: Joi.string().required().label("Subject"),
    type: Joi.string().required().label("Type"),
  };

  componentDidMount() {
    const tutor = JSON.parse(localStorage.getItem("currentUser"));
    const tuturName = `${tutor.lastName}, ${tutor.firstName}`;
    const tutorId = localStorage.getItem("currentUserId");

    const tutorObj = { name: tuturName, id: tutorId };
    this.setState({ tutor: tutorObj });
  }

  types = getOsceTypes();

  doSubmit = () => {
    const { data } = this.state;

    const tutor = this.state.tutor;
    const dateUploaded = Date.now();
    const subject = data.subject;
    const title = data.title;
    const type = data.type;
    // const readLink = data.readLink;
    const id = Date.now().toString();

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    let osceId = "";
    const submitBtn = document.getElementById("submit-btn");
    const continueBtn = document.getElementById("continue-btn");

    const addOsce = firebase.firestore().collection("osces").doc(id).set({
      title: title,
      tutor: tutor,
      segments: [],
      osceId: id,
      // readLink: readLink,
      dateUploaded: dateUploaded,
      subject: subject,
      type: type,
    });

    const incrementSubjectStats = firebase
      .firestore()
      .collection(`statistics/subjects/subjectData`)
      .doc(subject)
      .update({
        noOfOsces: firebase.firestore.FieldValue.increment(1),
      });
    const incrementOsceTypeStats = firebase
      .firestore()
      .collection(`statistics/osceTypes/osceTypeData`)
      .doc(type)
      .update({
        noOfOsces: firebase.firestore.FieldValue.increment(1),
      });
    const incrementOsceTypeSubjectStats = firebase
      .firestore()
      .collection(`statistics/osceTypes/osceTypeData/${type}/subjects`)
      .doc(subject)
      .update({
        noOfOsces: firebase.firestore.FieldValue.increment(1),
      });
    const incrementGeneralStats = firebase
      .firestore()
      .collection(`statistics`)
      .doc("general")
      .update({
        osces: firebase.firestore.FieldValue.increment(1),
      });

    Promise.all([
      addOsce,
      incrementSubjectStats,
      incrementOsceTypeStats,
      incrementOsceTypeSubjectStats,
      incrementGeneralStats,
    ])
      .then(() => {
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        toast.success(
          "Successfully Uploaded New OSCE, Click Continue to Start Adding Segments"
        );
        // osceId = docRef.id;
        osceId = id;
        sessionStorage.setItem("osceId", osceId);
        submitBtn.style.display = "none";
        continueBtn.style.display = "block";
        // console.log("osce id", docRef.id);

        this.setState({
          data: {
            title: "",
            subject: "",
            type: type,
            // readLink: "",
          },
          continueToAddOsce: true,
        });
      })
      .catch((error) => {
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        // console.error("Error adding document: ", error);
        const errorMessage = error.message;
        console.error(errorMessage);
        return;
      });
  };

  doSegmentAddSubmit = () => {
    const { segmentData } = this.state;

    const {
      segmentTitle,
      item1,
      technique1,
      significance1,
      explanation1,
      item2,
      technique2,
      significance2,
      explanation2,
      item3,
      technique3,
      significance3,
      explanation3,
      item4,
      technique4,
      significance4,
      explanation4,
      item5,
      technique5,
      significance5,
      explanation5,
      item6,
      technique6,
      significance6,
      explanation6,
      item7,
      technique7,
      significance7,
      explanation7,
      item8,
      technique8,
      significance8,
      explanation8,
      item9,
      technique9,
      significance9,
      explanation9,
      item10,
      technique10,
      significance10,
      explanation10,
      // item11,
      // technique11,
      // significance11,
      // explanation11,
      // item12,
      // technique12,
      // significance12,
      // explanation12,
      // item13,
      // technique13,
      // significance13,
      // explanation13,
      // item14,
      // technique14,
      // significance14,
      // explanation14,
      // item15,
      // technique15,
      // significance15,
      // explanation15,
    } = segmentData;

    const itemsArray = [
      { content: segmentTitle, name: "Segment Title" },
      { content: item1, name: "Item 1 (required)" },
      { content: technique1, name: "Item 1 Technique" },
      { content: significance1, name: "Item 1 Significance" },
      { content: explanation1, name: "Item 1 Explanation" },
      { content: item2, name: "Item 2" },
      { content: technique2, name: "Item 2 Technique" },
      { content: significance2, name: "Item 2 Significance" },
      { content: explanation2, name: "Item 2 Explanation" },
      { content: item3, name: "Item 3" },
      { content: technique3, name: "Item 3 Technique" },
      { content: significance3, name: "Item 3 Significance" },
      { content: explanation3, name: "Item 3 Explanation" },
      { content: item4, name: "Item 4" },
      { content: technique4, name: "Item 4 Technique" },
      { content: significance4, name: "Item 4 Significance" },
      { content: explanation4, name: "Item 4 Explanation" },
      { content: item5, name: "Item 5" },
      { content: technique5, name: "Item 5 Technique" },
      { content: significance5, name: "Item 5 Significance" },
      { content: explanation5, name: "Item 5 Explanation" },
      { content: item6, name: "Item 6" },
      { content: technique6, name: "Item 6 Technique" },
      { content: significance6, name: "Item 6 Significance" },
      { content: explanation6, name: "Item 6 Explanation" },
      { content: item7, name: "Item 7" },
      { content: technique7, name: "Item 7 Technique" },
      { content: significance7, name: "Item 7 Significance" },
      { content: explanation7, name: "Item 7 Explanation" },
      { content: item8, name: "Item 8" },
      { content: technique8, name: "Item 8 Technique" },
      { content: significance8, name: "Item 8 Significance" },
      { content: explanation8, name: "Item 8 Explanation" },
      { content: item9, name: "Item 9" },
      { content: technique9, name: "Item 9 Technique" },
      { content: significance9, name: "Item 9 Significance" },
      { content: explanation9, name: "Item 9 Explanation" },
      { content: item10, name: "Item 10" },
      { content: technique10, name: "Item 10 Technique" },
      { content: significance10, name: "Item 10 Significance" },
      { content: explanation10, name: "Item 10 Explanation" },
    ];

    if (this.checkForEmptySpaces(itemsArray) === "stop") {
      // console.log("there's a stop");
      return;
    }

    if (!segmentTitle || item1.length < 8) {
      toast.error("Segment Title and item 1 must not be empty");
      return;
    }

    // console.log(segmentTitle)

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    let newSegment = {
      segmentTitle: segmentTitle,
      items: [
        {
          content: item1,
          technique: technique1,
          significance: significance1,
          explanation: explanation1,
          id: "1",
        },
        {
          content: item2,
          technique: technique2,
          significance: significance2,
          explanation: explanation2,
          id: "2",
        },
        {
          content: item3,
          technique: technique3,
          significance: significance3,
          explanation: explanation3,
          id: "3",
        },
        {
          content: item4,
          technique: technique4,
          significance: significance4,
          explanation: explanation4,
          id: "4",
        },
        {
          content: item5,
          technique: technique5,
          significance: significance5,
          explanation: explanation5,
          id: "5",
        },
        {
          content: item6,
          technique: technique6,
          significance: significance6,
          explanation: explanation6,
          id: "6",
        },
        {
          content: item7,
          technique: technique7,
          significance: significance7,
          explanation: explanation7,
          id: "7",
        },
        {
          content: item8,
          technique: technique8,
          significance: significance8,
          explanation: explanation8,
          id: "8",
        },
        {
          content: item9,
          technique: technique9,
          significance: significance9,
          explanation: explanation9,
          id: "9",
        },
        {
          content: item10,
          technique: technique10,
          significance: significance10,
          explanation: explanation10,
          id: "10",
        },
        // {
        //   content: item11,
        //   technique: technique11,
        //   significance: significance11,
        //   explanation: explanation11,
        //   id: "11",
        // },
        // {
        //   content: item12,
        //   technique: technique12,
        //   significance: significance12,
        //   explanation: explanation12,
        //   id: "12",
        // },
        // {
        //   content: item13,
        //   technique: technique13,
        //   significance: significance13,
        //   explanation: explanation13,
        //   id: "13",
        // },
        // {
        //   content: item14,
        //   technique: technique14,
        //   significance: significance14,
        //   explanation: explanation14,
        //   id: "14",
        // },
        // {
        //   content: item15,
        //   technique: technique15,
        //   significance: significance15,
        //   explanation: explanation15,
        //   id: "15",
        // },
      ],
    };

    // console.log("New Segment", newSegment);
    //call the server
    // console.log("Segment added");
    const osceId = sessionStorage.getItem("osceId");

    const osceRef = firebase.firestore().collection("osces").doc(osceId);
    const promiseOne = osceRef
      .get()
      .then((doc) => {
        // console.log("osce", doc.data());
        let osce = doc.data();
        let segmentsList = osce.segments;
        const segmentNo = segmentsList.length + 1;
        newSegment.segmentNo = segmentNo;
        segmentsList.push(newSegment);
        // console.log("Updated segment list", segmentsList);
        // console.log("segment Number", segmentNo);

        firebase
          .firestore()
          .collection("osces")
          .doc(osceId)
          .update({
            segments: segmentsList,
          })
          .then((doc) => {
            // console.log("segment has been added");
          })
          .catch((error) => {
            const submitting = this.state.submitting;
            this.setState({ submitting: !submitting });
            const errorMessage = error.message;
            toast.error(errorMessage);
          });
      })
      .catch((error) => {
        const errorMessage = error.message;
        // console.error("error getting the osce", error);
        toast.error(errorMessage);
      });

    Promise.all([promiseOne])
      .then(() => {
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        toast.success("Segment Added");
        this.setState({
          segmentData: {
            segmentTitle: "",
            item1: "",
            technique1: "",
            significance1: "",
            explanation1: "",
            item2: "",
            technique2: "",
            significance2: "",
            explanation2: "",
            item3: "",
            technique3: "",
            significance3: "",
            explanation3: "",
            item4: "",
            technique4: "",
            significance4: "",
            explanation4: "",
            item5: "",
            technique5: "",
            significance5: "",
            explanation5: "",
            item6: "",
            technique6: "",
            significance6: "",
            explanation6: "",
            item7: "",
            technique7: "",
            significance7: "",
            explanation7: "",
            item8: "",
            technique8: "",
            significance8: "",
            explanation8: "",
            item9: "",
            technique9: "",
            significance9: "",
            explanation9: "",
            item10: "",
            technique10: "",
            significance10: "",
            explanation10: "",
            // item11: "",
            // technique11: "",
            // significance11: "",
            // explanation11: "",
            // item12: "",
            // technique12: "",
            // significance12: "",
            // explanation12: "",
            // item13: "",
            // technique13: "",
            // significance13: "",
            // explanation13: "",
            // item14: "",
            // technique14: "",
            // significance14: "",
            // explanation14: "",
            // item15: "",
            // technique15: "",
            // significance15: "",
            // explanation15: "",
          },
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  subjects = getOsceSubjects();

  segmentDetails = [
    { id: "segmentTitle", name: "Segment Title" },
    { id: "item1", name: "Item 1 (required)" },
    { id: "technique1", name: "Item 1 Technique" },
    { id: "significance1", name: "Item 1 Significance" },
    { id: "item2", name: "Item 2" },
    { id: "technique2", name: "Item 2 Technique" },
    { id: "significance2", name: "Item 2 Significance" },
    { id: "item3", name: "Item 3" },
    { id: "technique3", name: "Item 3 Technique" },
    { id: "significance3", name: "Item 3 Significance" },
    { id: "item4", name: "Item 4" },
    { id: "technique4", name: "Item 4 Technique" },
    { id: "significance4", name: "Item 4 Significance" },
    { id: "item5", name: "Item 5" },
    { id: "technique5", name: "Item 5 Technique" },
    { id: "significance5", name: "Item 5 Significance" },
    { id: "item6", name: "Item 6" },
    { id: "technique6", name: "Item 6 Technique" },
    { id: "significance6", name: "Item 6 Significance" },
    { id: "item7", name: "Item 7" },
    { id: "technique7", name: "Item 7 Technique" },
    { id: "significance7", name: "Item 7 Significance" },
    { id: "item8", name: "Item 8" },
    { id: "technique8", name: "Item 8 Technique" },
    { id: "significance8", name: "Item 8 Significance" },
    { id: "item9", name: "Item 9" },
    { id: "technique9", name: "Item 9 Technique" },
    { id: "significance9", name: "Item 9 Significance" },
    { id: "item10", name: "Item 10" },
    { id: "technique10", name: "Item 10 Technique" },
    { id: "significance10", name: "Item 10 Significance" },
    // { id: "item11", name: "Item 11" },
    // { id: "technique11", name: "Item 11 Technique" },
    // { id: "significance11", name: "Item 11 Significance" },
    // { id: "item12", name: "Item 12" },
    // { id: "technique12", name: "Item 12 Technique" },
    // { id: "significance12", name: "Item 12 Significance" },
    // { id: "item13", name: "Item 13" },
    // { id: "technique13", name: "Item 13 Technique" },
    // { id: "significance13", name: "Item 13 Significance" },
    // { id: "item14", name: "Item 14" },
    // { id: "technique14", name: "Item 14 Technique" },
    // { id: "significance14", name: "Item 14 Significance" },
    // { id: "item15", name: "Item 15" },
    // { id: "technique15", name: "Item 15 Technique" },
    // { id: "significance15", name: "Item 15 Significance" },
    // {id: "", name: ""},
  ];

  segmentDetailsWithExplanation = [
    { id: "segmentTitle", name: "Segment Title" },
    { id: "item1", name: "Item 1 (required)" },
    { id: "explanation1", name: "Item 1 Explanation" },
    { id: "item2", name: "Item 2" },
    { id: "explanation2", name: "Item 2 Explanation" },
    { id: "item3", name: "Item 3" },
    { id: "explanation3", name: "Item 3 Explanation" },
    { id: "item4", name: "Item 4" },
    { id: "explanation4", name: "Item 4 Explanation" },
    { id: "item5", name: "Item 5" },
    { id: "explanation5", name: "Item 5 Explanation" },
    // { id: "item6", name: "Item 6" },
    // { id: "explanation6", name: "Item 6 Explanation" },
    // { id: "item7", name: "Item 7" },
    // { id: "explanation7", name: "Item 7 Explanation" },
    // { id: "item8", name: "Item 8" },
    // { id: "explanation8", name: "Item 8 Explanation" },
    // { id: "item9", name: "Item 9" },
    // { id: "explanation9", name: "Item 9 Explanation" },
    // { id: "item10", name: "Item 10" },
    // { id: "explanation10", name: "Item 10 Explanation" },
    // { id: "item11", name: "Item 11" },
    // { id: "explanation11", name: "Item 11 Explanation" },
    // { id: "item12", name: "Item 12" },
    // { id: "explanation12", name: "Item 12 Explanation" },
    // { id: "item13", name: "Item 13" },
    // { id: "explanation13", name: "Item 13 Explanation" },
    // { id: "item14", name: "Item 14" },
    // { id: "explanation14", name: "Item 14 Explanation" },
    // { id: "item15", name: "Item 15" },
    // { id: "explanation15", name: "Item 15 Explanation" },
    // {id: "", name: ""},
  ];

  goToAddSegments = (e) => {
    e.preventDefault();

    this.setState({ showAddSegments: true, continueToAddOsce: false });
  };

  checkForEmptySpaces = (itemsArray) => {
    const isThereAnEmptyLine = [];
    itemsArray.forEach((item) => {
      if (item.content.substring(3, 9) === "&nbsp;") {
        // console.log("Yes there's empty space at the start");
        toast.info(
          `Please remove the empty space/Line at the start of ${item.name}`
        );
        isThereAnEmptyLine.push(true);
      }
      if (
        item.content.substring(
          item.content.length - 10,
          item.content.length - 4
        ) === "&nbsp;"
      ) {
        // console.log("There's also emtpy space at the end");
        toast.info(
          `Please remove the empty space/Line at the end of ${item.name}`
        );
        isThereAnEmptyLine.push(true);
      }
    });
    if (isThereAnEmptyLine.includes(true)) {
      return "stop";
    } else {
      return "continue";
    }
  };

  render() {
    const classes = useStyles;
    const { showAddSegments, submitting, data, continueToAddOsce } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>Upload OSCE</title>
        </Helmet>

        {submitting ? (
          <PreLoader />
        ) : (
          <Container
            component="main"
            maxWidth="md"
            style={{
              margin: "30px auto",
              width: "95%",
              backgroundColor: "#fff",
              boxShadow:
                "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
              padding: "4rem 2rem",
              borderRadius: "10px",
            }}
          >
            <CssBaseline />
            <div className={classes.paper}>
              <Grid justifyContent="center" alignItems="center" item container>
                <Avatar
                  className={classes.avatar}
                  style={{ backgroundColor: "#f50057" }}
                >
                  <CloudUploadIcon />
                </Avatar>
                <br />
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ marginLeft: "10px" }}
                >
                  Upload OSCE
                </Typography>
              </Grid>
              {!showAddSegments && (
                <form className={classes.form} onSubmit={this.handleSubmit}>
                  {this.renderTextField("title", "Title")}
                  {this.renderSelect(
                    "subject",
                    "Select Subject",
                    this.subjects
                  )}
                  {this.renderSelect("type", "Select Type", this.types)}
                  {/* {this.renderTextField("readLink", "Read Link")} */}

                  {this.renderSubmitButton("Submit")}
                </form>
              )}
              {data.type !== "summary" && showAddSegments && (
                <form
                  className={classes.form}
                  onSubmit={this.handleAddSegmetsSubmit}
                >
                  <br />
                  {this.segmentDetails.map((segmentDetail) => (
                    <div
                      key={segmentDetail.id}
                      style={{
                        border: "1px solid #969aa2",
                        marginBottom: "20px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      <p style={{ padding: "0 10px", margin: "0" }}>
                        {segmentDetail.name}
                      </p>
                      <Divider />
                      <CKEditor
                        editor={InlineEditor}
                        data={""}
                        onChange={(event, editor) => {
                          const data = editor.getData();

                          const segmentData = { ...this.state.segmentData };
                          segmentData[segmentDetail.id] = data;
                          this.setState({ segmentData });
                        }}
                      />
                    </div>
                  ))}

                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={this.doSegmentAddSubmit}
                  >
                    Submit
                  </Button>
                </form>
              )}

              {data.type === "summary" && showAddSegments && (
                <form
                  className={classes.form}
                  onSubmit={this.handleAddSegmetsSubmit}
                >
                  <br />
                  {this.segmentDetailsWithExplanation.map((segmentDetail) => (
                    <div
                      key={segmentDetail.id}
                      style={{
                        border: "1px solid #969aa2",
                        marginBottom: "20px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      <p style={{ padding: "0 10px", margin: "0" }}>
                        {segmentDetail.name}
                      </p>
                      <Divider />
                      <CKEditor
                        editor={InlineEditor}
                        data={""}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          const segmentData = { ...this.state.segmentData };
                          segmentData[segmentDetail.id] = data;
                          this.setState({ segmentData });
                        }}
                      />
                    </div>
                  ))}

                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={this.doSegmentAddSubmit}
                  >
                    Submit
                  </Button>
                </form>
              )}
              <br />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                id="continue-btn"
                // style={{ display: "none" }}
                onClick={this.goToAddSegments}
                disabled={!continueToAddOsce}
              >
                Continue
              </Button>
            </div>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default OsceUpload;
