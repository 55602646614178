import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Helmet } from "react-helmet";
import Form from "./common/form";
import Joi from "joi-browser";
import contactUs from "../img/contactUs.svg";
import firebase from "firebase/app";
import { toast } from "react-toastify";
import { Link } from "@material-ui/core";

class Contact extends Form {
  state = {
    data: {
      name: "",
      email: "",
      subject: "",
    },
    errors: {},
    messageDetails: "",
  };

  schema = {
    name: Joi.string().required().label("Name"),
    email: Joi.string()
      .required()
      .email({ minDomainAtoms: 2 })
      .label("Email Address"),
    subject: Joi.string().required().label("Subject"),
  };

  doSubmit = () => {
    console.log("submitting the message");
    const { name, email, subject } = this.state.data;
    const dateUploaded = Date.now();
    const reportedBy = { firstName: name, email: email };

    const details = document.querySelector("#message-details").innerHTML;
    console.log(details);

    if (!document.querySelector("#message-details").innerText) {
      toast.error("Please enter your message in the space provided");
      return;
    }

    firebase
      .firestore()
      .collection("issues")
      .doc(dateUploaded.toString())
      .set({
        content: details,
        messageSubject: subject,
        from: "CONTACT",
        itemId: dateUploaded,
        issueId: dateUploaded,
        reportedBy: reportedBy,
      })
      .then(() => {
        toast.success("Thank You. You will hear from us soon.");
      });
  };

  handleTextArea = () => {
    const details = document.querySelector("#message-details");
    console.log(details);
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Contact - Acabest</title>
          <meta
            name="description"
            content="Get in touch with the Acabest Team"
          />
        </Helmet>

        <Grid
          id="contact-us-container"
          item
          md={8}
          xs={11}
          style={{ margin: "30px auto" }}
        >
          <Typography
            variant="h4"
            component="h1"
            style={{ textAlign: "center" }}
          >
            Contact Us
          </Typography>
          <Grid item md={5} xs={10} className="feature-image">
            <img src={contactUs} alt="doctors" />
          </Grid>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            Address
          </Typography>
          <Typography variant="body1" style={{ textAlign: "center" }}>
            Accra, Ghana.
            <br />
            Email: contact@acabest.com <br />
            Mobile: (+233) 597406788
          </Typography>
          <br />
          <Typography variant="body1" style={{ textAlign: "center" }}>
            For more prompt support,{" "}
            <a
              href="https://wa.me/message/RA3ZX3V2YX5ZB1"
              target="_blank"
              style={{ textDecoration: "underline", color: "blue" }}
            >
              CLICK HERE
            </a>{" "}
            to WhatsApp us directly
          </Typography>
          <br />
          <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
            OR
          </Typography>
          <br />

          <Container
            component="main"
            maxWidth="sm"
            style={{
              margin: "30px auto",
              width: "95%",
              backgroundColor: "#fff",
              boxShadow:
                "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
              padding: "4rem 2rem",
              borderRadius: "10px",
            }}
          >
            <div>
              <Grid justifyContent="center" alignItems="center" item container>
                <Typography
                  component="h3"
                  variant="h5"
                  style={{ marginLeft: "10px" }}
                >
                  Leave us a message
                </Typography>
              </Grid>
              <form onSubmit={this.handleSubmit}>
                {this.renderTextField("name", "Your Name")}
                {this.renderTextField("email", "Email Address")}
                {this.renderTextField("subject", "Message Subject")}
                <div
                  id="message-details"
                  contentEditable="true"
                  style={{
                    height: "200px",
                    padding: "10px",
                    border: "1px solid rgb(133, 133, 133)",
                    borderRadius: "5px",
                    margin: "15px auto",
                  }}
                  onChange={this.handleTextArea}
                ></div>
                <br />
                {this.renderSubmitButton("Send")}
              </form>
            </div>
          </Container>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Contact;
