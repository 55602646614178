import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";

class Timer extends Component {
  state = {
    hour: "00",
    min: this.props.timeLength,
    sec: "00",
    day: "00",
  };

  componentDidMount() {
    // const timeLength = this.props.timeLength;
    // this.setState({ min: timeLength });
    const type = this.props.type;
    this.setState({ type });
    this.startCountDown();
  }

  componentWillUnmount() {
    clearInterval(this.intervalHandle);
  }

  tick = () => {
    var day = Math.floor((this.secondsRemaining / 86400) % 365);

    var hour = Math.floor((this.secondsRemaining / 3600) % 24);
    var min = Math.floor((this.secondsRemaining / 60) % 60);
    var sec = Math.floor(this.secondsRemaining - min * 60) % 60;

    sessionStorage.setItem("timeRemaining", this.secondsRemaining / 60);

    this.setState({
      day,
      hour,
      min,
      sec,
    });

    if (hour < 10) {
      this.setState({
        hour: "0" + this.state.hour,
      });
    }

    if (sec < 10) {
      this.setState({
        sec: "0" + this.state.sec,
      });
    }

    if (min < 10) {
      this.setState({
        min: "0" + min,
      });
    }

    if ((hour === 0) & (min === 0) & (sec === 0)) {
      clearInterval(this.intervalHandle);
    }

    if (this.secondsRemaining < 2) {
      // console.log("time up");
      sessionStorage.setItem("timeUp", "stopQuiz");
    }

    this.secondsRemaining--;
    // console.log(this.secondsRemaining);
  };

  startCountDown = () => {
    this.intervalHandle = setInterval(this.tick, 1000);
    let time = this.state.min;
    this.secondsRemaining = time * 60;
  };

  renderColor() {
    const timeLength = this.props.timeLength;
    if (this.secondsRemaining > 0.1 * timeLength * 60) {
      return { color: "green" };
    } else {
      return { color: "red" };
    }
  }

  render() {
    const { day, hour, min, sec, type } = this.state;

    return (
      <React.Fragment>
        {type !== "scheduled" && (
          <Typography variant="h4" style={this.renderColor()}>
            {hour}:{min}:{sec}
          </Typography>
        )}

        {type === "scheduled" && (
          <div style={this.renderColor()}>
            <Typography component="span" variant="h4">
              {day}
            </Typography>
            {day > 1 ? "days" : "day"}
            <Typography component="span" variant="h4">
              :{hour}
            </Typography>
            h
            <Typography component="span" variant="h4">
              :{min}
            </Typography>
            m
            <Typography component="span" variant="h4">
              :{sec}
            </Typography>
            s
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Timer;
