import React from "react";
import { Helmet } from "react-helmet";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import firebase from "firebase/app";
import { toast } from "react-toastify";
import PreLoader from "./common/preLoader";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Paper } from "@material-ui/core";
import { Link } from "react-router-dom";

class Pricing extends React.Component {
  state = {
    submitting: false,
    currency: "USD",
    user: {},
    openShowPaymentMethods: false,
    showDetailsForInvoicePayment: false,
    showGeneratedInvoice: false,
    paymentNetwork: "MTN",
    forceLogin: false,
    dollarRate: 15,
  };

  componentDidMount() {
    const userId = localStorage.getItem("currentUserId");
    if (userId) {
      firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .onSnapshot((doc) => {
          const user = doc.data();
          this.setState({ user });
          if (user.country === "Ghana - GH") {
            this.setState({ currency: "GHS" });
          }
        });
    }
  }

  tiers = [
    {
      title: "Free",
      priceInGHS: "0",
      priceInUSD: "0",
      promotionPriceInGHS: "0",
      description: [
        "50 Questions",
        "Find questions by Subject/System",
        "Practice & Timed Quiz Mode",
        "--------------------------------------------",
        "15 OSCE guides",
        "Learn & Timed Practice Mode",
        "----------------------------------------------",
        "Create Flashcards",
        "Automated Revision Tool",
        "Access to Community Flashcards",
        "Basic Progress Tracking & Analytics",
      ],
      buttonText: "Sign up for free",
      buttonVariant: "outlined",
      url: "/student-register",
    },
    {
      title: "PREMIUM",
      subheader: (
        <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
          Everything in "Free" Plus...
        </Typography>
      ),
      priceInGHS: "199",
      priceInUSD: "33",
      promotionPriceInGHS: "99",
      promotionPriceInUSD: "16",
      description: [
        "3200+ Questions(Growing)",
        "Find questions by Subject/System",
        "Find questions by TOPIC",
        "Practice & Timed Quiz Mode",
        "Schedule Question sessions",
        "--------------------------------------------",
        "120+ OSCE guides(Growing)",
        "Learn & Timed Practice Mode",
        "----------------------------------------------",
        "Create Flashcards",
        "Automated Revision Tool",
        "Access to Community Flashcards",
        "Basic Progress Tracking & Analytics",
      ],
      buttonText: "Get started",
      buttonVariant: "contained",
      color: "primary",
      url: "/get-premium",
    },
    // {
    //   title: "Institutional",
    //   price: "",
    //   description: [
    //     "Are you a Medical Students' Ass.",
    //     "Or a Medical School Administration",
    //     "Become our partner and",
    //     "Get Up to 50% off for your students",
    //   ],
    //   buttonText: "Contact us",
    //   buttonVariant: "outlined",
    //   url: "/contact",
    // },
  ];

  startPaymentProcessing = () => {
    // console.log("Payment processing started");
    const { user, currency } = this.state;
    this.setState({ openShowPaymentMethods: false });

    if (!user.email) {
      toast.error(
        "Please login to continue. Refresh page if you are already logged in"
      );
      this.setState({ forceLogin: true });
      return;
    }

    let amount = 33;
    let paymentPlanId = 63204;
    let paymentOptions = "card";

    if (currency === "GHS") {
      amount = 199;
      paymentPlanId = 63203;
      paymentOptions = "card";
    }
    // console.log("amount before promo", amount);
    // APPLY LAUNCH SALE PROMO
    if (currency === "USD") {
      amount = 16;
    } else if (currency === "GHS") {
      amount = 99;
    }
    // console.log("amount after launch promo", amount);

    const txRef = `${user.email.substring(0, 6)}-${Math.floor(
      1000 + Math.random() * 9000
    )}`;

    let data = {
      tx_ref: txRef,
      currency: currency,
      payment_options: paymentOptions,
      amount: amount,
      redirect_url: "https://acabest.com/finish-payment",
      meta: {
        consumer_id: user.email,
        // consumer_mac: "92a3-912ba-1192a",
      },
      customer: {
        email: user.email,
        phonenumber: user.mobile,
        name: `${user.firstName} ${user.lastName}`,
      },
      payment_plan: paymentPlanId,
      customizations: {
        title: "Acabest Medical",
        description: "This is a year long subscription",
      },
    };

    // console.log(data);
    // return;

    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    const processPayment = firebase.functions().httpsCallable("processPayment");
    processPayment(data)
      .then((result) => {
        // console.log(result);
        window.open(result.data.paymentLink, "_self");
        // toast.success("Payment processing has began");

        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed. Something went wrong");

        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
      });

    // firebase
    //   .firestore()
    //   .collection("promoCodes")
    //   .doc(user.email)
    //   .get()
    //   .then((doc) => {
    //     const promoCode = doc.data();
    //     if (promoCode && promoCode.expiryDate > Date.now()) {
    //       const percentage = promoCode.percentage;
    //       data.amount = (percentage / 100) * data.amount;
    //     } else {
    //       console.log("Code has expired");
    //     }

    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     toast.error("Failed. Something went wrong");

    //     const submitting = this.state.submitting;
    //     this.setState({ submitting: !submitting });
    //   });
    // return;

    // const paymentButton = document.getElementById("paymentButton");
    // paymentButton.click();

    // axios.post("https://cors-anywhere.herokuapp.com/https://api.flutterwave.com/v3/payments", data, config).then(res => {
    //   console.log(res.data.data.link);
    //   window.open(res.data.data.link, "_self");
    //   // const payPromise = new Promise(() => {
    //   // })
    //   // payPromise.then(() => {
    //   //   console.log("payment complete")
    //   //   window.location.assign("/home")
    //   // })

    // })
  };

  startMomoPaymentProcessing = () => {
    // console.log("started momo payment processing");
    // toast.warn(
    //   "Mobile Money Payment is temporarily not available. Check again soon!"
    // );
    // return;
    const { user, currency, dollarRate } = this.state;
    this.setState({ openShowPaymentMethods: false });

    if (!user.email) {
      toast.error(
        "Please login to continue. Refresh page if you are already logged in"
      );
      this.setState({ forceLogin: true });
      return;
    }

    let amount = 33;

    if (currency === "GHS") {
      amount = 199;
    }

    // console.log("amount before promo", amount);

    // APPLY LAUNCH SALE PROMO
    if (currency === "USD") {
      amount = 16 * dollarRate;
    } else if (currency === "GHS") {
      amount = 99;
    }
    // console.log("amount after launch promo", amount);

    const txRef = `${user.email.substring(0, 6)}-${Math.floor(
      1000 + Math.random() * 9000
    )}`;

    let data = {
      tx_ref: txRef,
      currency: "GHS",
      payment_options: "mobilemoneyghana",
      amount: amount,
      redirect_url: "https://acabest.com/finish-payment",
      meta: {
        consumer_id: user.email,
        // consumer_mac: "92a3-912ba-1192a",
      },
      customer: {
        email: user.email,
        phonenumber: user.mobile,
        name: `${user.firstName} ${user.lastName}`,
      },
      customizations: {
        title: "Acabest Medical",
        description: "This is a year long subscription",
      },
    };

    // console.log(data);
    // return;

    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    const processPayment = firebase.functions().httpsCallable("processPayment");
    processPayment(data)
      .then((result) => {
        // console.log(result);
        window.open(result.data.paymentLink, "_self");
        // toast.success("Payment processing has began");

        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed. Something went wrong");

        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
      });
  };

  actionButtonClicked = (tier) => {
    if (tier.title === "PREMIUM") {
      this.setState({ openShowPaymentMethods: true });
      // this.startMomoPaymentProcessing();
    } else {
      window.location = tier.url;
    }
  };

  // handleClickOpen = (type) => {
  //   if (type === "show-payment-methods") {
  //     this.setState({ openShowPaymentMethods: true });
  //   }
  // };

  handleClose = (type) => {
    if (type === "show-payment-methods") {
      this.setState({ openShowPaymentMethods: false });
    }
  };

  generateInvoice = () => {
    // console.log("generating invoice");
    const { user, paymentNetwork, currency } = this.state;
    const userId = localStorage.getItem("currentUserId");
    let invoice = {};
    const dateGenerated = Date.now();

    if (!user > 0) {
      toast.error(
        "Please login to continue. Refresh page if you are already logged in"
      );
      this.setState({ forceLogin: true });
      return;
    }

    this.setState({ submitting: true });

    let amount = 33;

    if (currency === "GHS") {
      amount = 199;
    }

    // APPLY LAUNCH SALE PROMO
    if (currency === "USD") {
      amount = 16;
    } else if (currency === "GHS") {
      amount = 99;
    }

    const txRef = `${Math.floor(100000 + Math.random() * 900000)}`;

    if (user && user.invoice && user.invoice.network === paymentNetwork) {
      // console.log("user has an invoice");
      invoice = user.invoice;

      this.setState({
        showGeneratedInvoice: true,
        showDetailsForInvoicePayment: false,
        invoice,
        submitting: false,
      });
    } else {
      invoice.network = paymentNetwork;
      invoice.amount = amount;
      invoice.referenceNumber = txRef;
      invoice.user = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      };
      invoice.dateGenerated = dateGenerated;
      invoice.status = "Not Paid";

      firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .update({
          invoice: invoice,
        })
        .then(() => {
          firebase
            .firestore()
            .collection("invoices")
            .doc(invoice.referenceNumber)
            .set(invoice)
            .then(() => {
              this.setState({
                showGeneratedInvoice: true,
                showDetailsForInvoicePayment: false,
                invoice,
                submitting: false,
              });
            });
        })
        .catch((error) => {
          console.log(error.message());
          toast.error("Sorry, something went wrong");
        });
    }
  };

  handleNetworkChange = (event) => {
    const network = event.target.value;
    this.setState({ paymentNetwork: network });
  };

  render() {
    const {
      submitting,
      currency,
      openShowPaymentMethods,
      showDetailsForInvoicePayment,
      showGeneratedInvoice,
      invoice,
      paymentNetwork,
      forceLogin,
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>Pricing - Acabest</title>
          <meta
            name="description"
            content="Get Acabest Premium and see how easily you pass medical school tests."
          />
        </Helmet>
        <Paper
          style={{
            textAlign: "center",
            // height: "40px",
            borderRadius: "0px",
            // lineHeight: "3em",
            color: "#fff",
            backgroundColor: "#d3389b",
            fontWeight: "bolder",
            fontSize: "12px",
            padding: "5px 5px",
          }}
        >
          Ensure you set your 'Country' detail in 'My Profile' to avoid seeing
          the wrong currency
        </Paper>
        <br />
        <Typography variant="h4" component="h1" style={{ textAlign: "center" }}>
          Pricing
        </Typography>
        <br />
        {forceLogin && (
          <div style={{ margin: "30px" }}>
            <Typography variant="body1">
              Please{" "}
              <Button
                component={Link}
                variant="contained"
                color="primary"
                to="/login"
              >
                Sign In
              </Button>{" "}
              to Continue.
            </Typography>
            <Typography variant="caption">
              If you are alredy signed in, then simply refresh the page
            </Typography>
          </div>
        )}

        {submitting ? (
          <PreLoader />
        ) : (
          <Container maxWidth="lg" component="main">
            <Grid
              container
              spacing={5}
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                className="free-plan"
                item
                key="free-plan"
                xs={12}
                sm={6}
                md={4}
              >
                <Card>
                  <CardHeader
                    title="Free"
                    style={{ backgroundColor: "#8f93a6", color: "#fff" }}
                    titleTypographyProps={{ align: "center" }}
                    // action={tier.title === "Premium" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography component="h4" variant="h4">
                        <span style={{ fontSize: "22px" }}>
                          {currency === "USD" ? "USD" : "GHC"}
                        </span>
                        <span>0</span>
                      </Typography>
                      <Typography variant="h6">/yr</Typography>
                    </Box>
                    {this.tiers[0].description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                        style={{ listStyle: "none" }}
                      >
                        {line}
                      </Typography>
                    ))}
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={() => this.actionButtonClicked(this.tiers[0])}
                    >
                      Sign up for free
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              <Grid
                className="premium-plan"
                item
                key="premium-plan"
                xs={12}
                sm={6}
                md={4}
              >
                <Card
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                  }}
                >
                  <CardHeader
                    title="PREMIUM"
                    subheader={
                      <Typography
                        style={{ textAlign: "center", fontWeight: "bold" }}
                      >
                        Everything in "Free" Plus...
                      </Typography>
                    }
                    style={{ backgroundColor: "#3f51b5", color: "#fff" }}
                    titleTypographyProps={{ align: "center" }}
                    // action={tier.title === "Premium" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography component="h4" variant="h4">
                        <span style={{ fontSize: "22px" }}>
                          {currency === "USD" ? "USD" : "GHC"}
                        </span>
                        {currency === "USD" ? (
                          <span>
                            <span>16</span>(
                            <span style={{ textDecoration: "line-through" }}>
                              33
                            </span>
                            )
                          </span>
                        ) : (
                          <span>
                            <span>99</span>(
                            <span style={{ textDecoration: "line-through" }}>
                              199
                            </span>
                            )
                          </span>
                        )}
                      </Typography>
                      <Typography variant="h6">/yr</Typography>
                    </Box>
                    {this.tiers[1].description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                        style={{ listStyle: "none" }}
                      >
                        {line}
                      </Typography>
                    ))}
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => this.actionButtonClicked(this.tiers[1])}
                    >
                      Get Started
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <div>
                <Dialog
                  open={openShowPaymentMethods}
                  onClose={() => this.handleClose("show-payment-methods")}
                  aria-labelledby="show-payment-methods-dialog"
                >
                  {!showDetailsForInvoicePayment && !showGeneratedInvoice && (
                    <DialogTitle id="show-payment-methods-dialog">
                      Choose Preferred Payment Method
                    </DialogTitle>
                  )}
                  {showDetailsForInvoicePayment && (
                    <DialogTitle id="show-payment-methods-dialog">
                      Invoice Mobile Money Payment
                    </DialogTitle>
                  )}
                  {showGeneratedInvoice && (
                    <DialogTitle id="show-payment-methods-dialog">
                      Your Invoice ({invoice.network}) -{" "}
                      {invoice.status === "Not Paid" ? (
                        <span style={{ color: "red" }}>Not Paid</span>
                      ) : (
                        <span style={{ color: "green" }}>Paid</span>
                      )}
                    </DialogTitle>
                  )}
                  {!showDetailsForInvoicePayment && !showGeneratedInvoice && (
                    <DialogContent>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.startPaymentProcessing}
                      >
                        Pay With Card
                      </Button>
                      <br />
                      <Typography variant="caption">
                        Visa, MasterCard, etc
                      </Typography>
                      <br />
                      <Typography variant="caption" style={{ color: "red" }}>
                        This method is unavailable in some areas
                      </Typography>
                      <br />
                      <br />
                      {
                        <div>
                          <div>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.startMomoPaymentProcessing}
                            >
                              Pay With Mobile Money
                            </Button>
                            <br />
                            <Typography variant="caption">
                              MTN, Vodafone, AirtelTigo
                            </Typography>
                          </div>
                          <br />
                          <div>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                this.setState({
                                  showDetailsForInvoicePayment: true,
                                })
                              }
                            >
                              Mobile Money (Invoice)
                            </Button>
                            <br />
                            <Typography variant="caption">
                              Only use this if the above Mobile Money method
                              isn't working for you.
                            </Typography>
                          </div>
                        </div>
                      }
                    </DialogContent>
                  )}
                  {showDetailsForInvoicePayment && (
                    <DialogContent>
                      <Typography variant="body2">
                        An invoice will be generated for you with a unique
                        reference number. Then you will send your payment to the
                        MoMo Pay account with the reference number on the
                        invoice. Your account will be activated within 30mins of
                        confirmation of payment. Account activation may take up
                        to 12 hours outside work hours.
                      </Typography>
                      <br />

                      <FormControl style={{ width: "200px" }}>
                        <InputLabel id="select-network">
                          Select Network
                        </InputLabel>
                        <Select
                          labelId="select-network"
                          id="demo-simple-select"
                          value={paymentNetwork}
                          label="Select Network"
                          onChange={this.handleNetworkChange}
                        >
                          <MenuItem value={"MTN"}>MTN</MenuItem>
                          <MenuItem value={"Vodafone"}>Vodafone</MenuItem>
                          <MenuItem value={"AirtelTigo"}>AirtelTigo</MenuItem>
                        </Select>
                      </FormControl>
                    </DialogContent>
                  )}
                  {showGeneratedInvoice && (
                    <DialogContent>
                      <Typography variant="body2">
                        Number: 059 7406 788 <br />
                        Account Name: Acabest Medical Ltd <br />
                        Reference: {invoice.referenceNumber} <br />
                        Amount: GHC {invoice.amount}
                      </Typography>
                      <Typography variant="body2">
                        <strong>
                          This invoice will be saved on your dashboard for if
                          you want to pay later.
                        </strong>
                      </Typography>
                      <br />
                      <Typography variant="body2">
                        Send the amount to the above number with the reference
                        provided above. You don't need to include the charges.
                        Just send the exact amount above.
                      </Typography>
                      {/* {paymentNetwork === "MTN" && (
                        <Typography variant="body2">
                          <strong>How To Pay via MoMo Pay:</strong> <br />
                          Dial *172# and select 2.(Note: Enter "Merchant ID" at
                          Merchant ID/Payment Reference. There will be another
                          prompt to enter "Reference"). <br />
                          You may also select "MoMo Pay" on the MTN app.
                        </Typography>
                      )} */}
                    </DialogContent>
                  )}
                  <DialogActions>
                    {showDetailsForInvoicePayment && !showGeneratedInvoice && (
                      <Button
                        onClick={() =>
                          this.setState({
                            showDetailsForInvoicePayment: false,
                          })
                        }
                        color="primary"
                      >
                        Go back
                      </Button>
                    )}
                    {showGeneratedInvoice && !showDetailsForInvoicePayment && (
                      <Button
                        onClick={() =>
                          this.setState({
                            showGeneratedInvoice: false,
                            showDetailsForInvoicePayment: true,
                          })
                        }
                        color="primary"
                      >
                        Go back
                      </Button>
                    )}
                    <Button
                      onClick={() => this.handleClose("show-payment-methods")}
                      color="primary"
                    >
                      Close
                    </Button>
                    {showDetailsForInvoicePayment && (
                      <Button
                        onClick={() => this.generateInvoice()}
                        color="primary"
                        variant="contained"
                      >
                        Generate Invoice
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>
          </Container>
        )}
      </div>
    );
  }
}

export default Pricing;
