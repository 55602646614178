import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import firebase from "firebase/app";
import { toast } from "react-toastify";
import PreLoader from "./common/preLoader";
import {
  getSubdivisions,
  getSubjects,
  getTopics,
} from "../utils/getCategories";
import { Helmet } from "react-helmet";

class ThreeDModelsUpload extends Form {
  state = {
    data: {
      title: "",
      url: "",
      subject: "",
      subdivision: "",
      topic: "",
    },
    errors: {},
    subjects: [],
  };

  schema = {
    title: Joi.string().min(3).max(200).required().label("Title"),
    url: Joi.string().min(10).required().label("URL"),
    subject: Joi.string().required().label("Subject"),
    subdivision: Joi.string().required().label("System"),
    topic: Joi.string().required().label("topic"),
  };

  componentDidMount() {
    const tutor = JSON.parse(localStorage.getItem("currentUser"));
    const tuturName = `${tutor.lastName}, ${tutor.firstName}`;
    const tutorId = localStorage.getItem("currentUserId");

    const statistics = JSON.parse(localStorage.getItem("statistics"));

    // GETTING THE SUBJECTS LIST
    const subjects = getSubjects(statistics.subjects);
    const findSubjectsWithRelevantContent = subjects.filter(
      (subject) => subject.noOfQuestions > 0
    );
    const subjectsWithRelevantContent = getSubjects(
      findSubjectsWithRelevantContent
    );

    this.setState({ subjects, subjectsWithRelevantContent });

    const tutorObj = { name: tuturName, id: tutorId };
    this.setState({ tutor: tutorObj });
  }

  systems = () => {
    const { subject } = this.state.data;
    const { subjectsWithRelevantContent } = this.state;

    const subjectData = subjectsWithRelevantContent.filter(
      (relevantSubject) => relevantSubject.id === subject
    )[0];
    const relevantSubdivisions = subjectData.subdivisions.filter(
      (theSubdivision) => theSubdivision.noOfQuestions > 0
    );
    subjectData.subdivisions = relevantSubdivisions;
    return getSubdivisions([subject], [subjectData]);
  };

  topics = () => {
    const { subject, subdivision } = this.state.data;
    const { subjectsWithRelevantContent } = this.state;

    let subjectData = subjectsWithRelevantContent.filter(
      (relevantSubject) => relevantSubject.id === subject
    )[0];
    let subdivisionData = subjectData.subdivisions.filter(
      (theSubdivision) => theSubdivision.id === subdivision
    )[0] || { topics: [] };
    const relevantTopics = subdivisionData.topics.filter(
      (theTopic) => theTopic.noOfQuestions > 0
    );
    subdivisionData.topics = relevantTopics;
    const newSubjectData = {
      ...subjectData,
      subdivisions: [subdivisionData],
    };

    return getTopics(subject, subdivision, [newSubjectData]);
  };

  doSubmit = () => {
    const { data } = this.state;

    const title = data.title;
    const url = data.url;
    const subject = data.subject;
    const subdivision = data.subdivision;
    const topic = data.topic;
    const id = Date.now().toString();
    const tutor = this.state.tutor;
    const dateUploaded = Date.now();

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    const add3DModel = firebase
      .firestore()
      .collection("threeD-models")
      .doc(id)
      .set({
        title: title,
        url: url,
        subject: subject,
        subdivision: subdivision,
        topic: topic,
        id: id,
        tutor: tutor,
        dateUploaded: dateUploaded,
      });

    const incrementSubjectStats = firebase
      .firestore()
      .collection(`statistics/subjects/subjectData`)
      .doc(subject)
      .update({
        noOf3DModels: firebase.firestore.FieldValue.increment(1),
      });
    const incrementGeneralStats = firebase
      .firestore()
      .collection(`statistics`)
      .doc("general")
      .update({
        threeDModels: firebase.firestore.FieldValue.increment(1),
      });

    Promise.all([add3DModel, incrementSubjectStats, incrementGeneralStats])
      .then(() => {
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        toast.success("3D Model has been successfully added");

        this.setState({
          data: {
            title: "",
            url: "",
            subject: "",
            subdivision: "",
            topic: "",
          },
        });
      })
      .catch((error) => {
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        // console.error("Error adding document: ", error);
        const errorMessage = error.message;
        console.error(errorMessage);
        toast.error("Something went wrong");
        return;
      });
  };

  render() {
    const { submitting, data, subjects } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>Upload 3D Models - Acabest</title>
        </Helmet>

        {submitting || !subjects.length > 0 ? (
          <PreLoader />
        ) : (
          <Container
            component="main"
            maxWidth="sm"
            style={{
              margin: "30px auto",
              width: "95%",
              backgroundColor: "#fff",
              boxShadow:
                "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
              padding: "4rem 2rem",
              borderRadius: "10px",
            }}
          >
            <CssBaseline />
            <div>
              <Grid justifyContent="center" alignItems="center" item container>
                <Avatar style={{ backgroundColor: "#f50057" }}>
                  <CloudUploadIcon />
                </Avatar>
                <br />
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ marginLeft: "10px" }}
                >
                  Upload 3D Model
                </Typography>
              </Grid>
              <form onSubmit={this.handleSubmit}>
                {this.renderTextField("title", "Title")}
                {this.renderTextField("url", "URL")}
                {this.renderSelect("subject", "Select Subject", subjects)}
                {data.subject &&
                  this.renderSelect(
                    "subdivision",
                    "Select System/Subdivision",
                    this.systems()
                  )}
                {data.subject &&
                  data.subdivision &&
                  this.renderSelect("topic", "Select Topic", this.topics())}

                {this.renderSubmitButton("Submit")}
              </form>
            </div>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default ThreeDModelsUpload;
