const institutionsData = [
  {
    country: "AE",
    name: "Abu Dhabi University",
    url: "http://www.adu.ac.ae/",
  },
  {
    country: "AE",
    name: "Ajman University of Science & Technology",
    url: "http://www.ajman.ac.ae/",
  },
  {
    country: "AE",
    name: "Alain University of Science and Technology",
    url: "http://www.alainuniversity.ac.ae/",
  },
  {
    country: "AE",
    name: "Al Ghurair University",
    url: "http://www.agu.ae/",
  },
  {
    country: "AE",
    name: "Alhosn University",
    url: "http://www.alhosnu.ae/",
  },
  {
    country: "AE",
    name: "Al Khawarizmi International College",
    url: "http://www.khawarizmi.com/",
  },
  {
    country: "AE",
    name: "American College Of Dubai",
    url: "http://www.acd.ac.ae/",
  },
  {
    country: "AE",
    name: "American University in Dubai",
    url: "http://www.aud.edu/",
  },
  {
    country: "AE",
    name: "American University in the Emirates",
    url: "http://www.aue.ae/",
  },
  {
    country: "AE",
    name: "American University of Sharjah",
    url: "http://www.aus.edu/",
  },
  {
    country: "AE",
    name: "British University in Dubai",
    url: "http://www.buid.ac.ae/",
  },
  {
    country: "AE",
    name: "Dubai Medical College for Girls",
    url: "http://www.dmcg.edu/",
  },
  {
    country: "AE",
    name: "Dubai Pharmacy College",
    url: "http://www.dpc.edu/",
  },
  {
    country: "AE",
    name: "Etisalat University College",
    url: "http://www.ece.ac.ae/",
  },
  {
    country: "AE",
    name: "Gulf Medical University",
    url: "http://www.gmu.ac.ae/",
  },
  {
    country: "AE",
    name: "Hamdan Bin Mohammed e-University",
    url: "http://www.hbmeu.ac.ae/",
  },
  {
    country: "AE",
    name: "Higher Colleges of Technology",
    url: "http://www.hct.ac.ae/",
  },
  {
    country: "AE",
    name: "Ittihad University",
    url: "http://www.ittihad.ac.ae/",
  },
  {
    country: "AE",
    name: "Jumeira University",
    url: "http://www.ju.ac.ae/",
  },
  {
    country: "AE",
    name: "Khalifa University",
    url: "http://www.kustar.ac.ae/",
  },
  {
    country: "AE",
    name: "Khalifa University of Science, Technology and Research",
    url: "http://www.ku.ac.ae/",
  },
  {
    country: "AE",
    name: "Masdar University Of Science And Technology",
    url: "http://www.masdar.ac.ae/",
  },
  {
    country: "AE",
    name: "New York University, Abu Dhabi",
    url: "http://nyuad.nyu.edu/",
  },
  {
    country: "AE",
    name: "Paris-Sorbonne University Abu Dhabi",
    url: "http://www.sorbonne.ae/",
  },
  {
    country: "AE",
    name: "Rak Medical & Health Sciences University",
    url: "http://www.rakmhsu.com/",
  },
  {
    country: "AE",
    name: "Rochester Institute of Technology, Dubai",
    url: "http://dubai.rit.edu/",
  },
  {
    country: "AE",
    name: "Skyline University College, Sharjah",
    url: "http://www.skylineuniversity.com/",
  },
  {
    country: "AE",
    name: "Synergy University, Dubai Campus",
    url: "http://www.synergydubai.ae/",
  },
  {
    country: "AE",
    name: "The Emirates Academy of Hotel Managment",
    url: "http://www.emiratesacademy.edu/",
  },
  {
    country: "AE",
    name: "The Petroleum Institute",
    url: "http://www.pi.ac.ae/",
  },
  {
    country: "AE",
    name: "United Arab Emirates University",
    url: "http://www.uaeu.ac.ae/",
  },
  {
    country: "AE",
    name: "University Of Dubai",
    url: "http://www.ud.ac.ae/",
  },
  {
    country: "AE",
    name: "University of Jazeera",
    url: "http://www.uojazeera.com/",
  },
  {
    country: "AE",
    name: "University of Sharjah",
    url: "http://www.sharjah.ac.ae/",
  },
  {
    country: "AE",
    name: "University of Wollongong - Dubai Campus",
    url: "http://www.uowdubai.ac.ae/",
  },
  {
    country: "AE",
    name: "Zayed University",
    url: "http://www.zu.ac.ae/",
  },
  {
    country: "AF",
    name: "Afghan University",
    url: "http://www.afghanuniversity.edu.af/",
  },
  {
    country: "AF",
    name: "Al-Birony University",
    url: "http://www.au.edu.af/",
  },
  {
    country: "AF",
    name: "American University of Afghanistan",
    url: "http://www.auaf.edu.af/",
  },
  {
    country: "AF",
    name: "Aria Institute of Higher Education",
    url: "http://www.aria.edu.af/",
  },
  {
    country: "AF",
    name: "Badakhshan University",
    url: "http://www.badakhshan.edu.af/",
  },
  {
    country: "AF",
    name: "Baghlan University",
    url: "http://www.baghlan.edu.af/",
  },
  {
    country: "AF",
    name: "Bakhtar University",
    url: "http://www.bakhtar.edu.af/",
  },
  {
    country: "AF",
    name: "Balkh University",
    url: "http://www.ba.edu.af/",
  },
  {
    country: "AF",
    name: "Bamiyan University",
    url: "http://www.bu.edu.af/",
  },
  {
    country: "AF",
    name: "Bost University",
    url: "http://www.bost.edu.af/",
  },
  {
    country: "AF",
    name: "Dawat University",
    url: "http://www.dawat.edu.af/",
  },
  {
    country: "AF",
    name: "Dunya Institute of Higher Education",
    url: "http://www.dunya.edu.af/",
  },
  {
    country: "AF",
    name: "Faryab Higher Education Institute",
    url: "http://www.faryab.edu.af/",
  },
  {
    country: "AF",
    name: "Ghazni University",
    url: "http://www.gu.edu.af/",
  },
  {
    country: "AF",
    name: "Herat University",
    url: "http://www.hu.edu.af/",
  },
  {
    country: "AF",
    name: "Ibn Sina University",
    url: "http://www.ibnesina.edu.af/",
  },
  {
    country: "AF",
    name: "Jawzjan University",
    url: "http://www.ju.edu.af/",
  },
  {
    country: "AF",
    name: "Kaboora Institute of Higher Education",
    url: "http://www.kaboora.edu.af/",
  },
  {
    country: "AF",
    name: "Kabul Education University",
    url: "http://www.keu.edu.af/",
  },
  {
    country: "AF",
    name: "Kabul Health Sciences Institute",
    url: "http://www.kabuli.edu.af/",
  },
  {
    country: "AF",
    name: "Kabul Medical University",
    url: "http://www.kmu.edu.af/",
  },
  {
    country: "AF",
    name: "Kabul University",
    url: "http://www.ku.edu.af/",
  },
  {
    country: "AF",
    name: "Kandahar University",
    url: "http://www.kan.edu.af/",
  },
  {
    country: "AF",
    name: "Kardan University",
    url: "http://www.kardan.edu.af/",
  },
  {
    country: "AF",
    name: "Karwan Institute of Higher Education",
    url: "http://www.karwan.edu.af/",
  },
  {
    country: "AF",
    name: "Kateb Institute of Higher Education",
    url: "http://www.kateb.edu.af/",
  },
  {
    country: "AF",
    name: "Khana-e-Noor Institute of Higher Education",
    url: "http://www.ken.edu.af/",
  },
  {
    country: "AF",
    name: "Khurasan University",
    url: "http://www.kumsa.net/",
  },
  {
    country: "AF",
    name: "Maryam Institute of Higher Education",
    url: "http://www.maryam.edu.af/",
  },
  {
    country: "AF",
    name: "Nangarhar University",
    url: "http://www.nu.edu.af/",
  },
  {
    country: "AF",
    name: "National Military Academy of Afghanistan",
    url: "http://www.nmaa.edu.af/",
  },
  {
    country: "AF",
    name: "Paktia University",
    url: "http://www.pu.edu.af/",
  },
  {
    country: "AF",
    name: "Pamir University",
    url: "http://www.pamir.edu.af/",
  },
  {
    country: "AF",
    name: "Parwan University",
    url: "http://www.parwan.edu.af/",
  },
  {
    country: "AF",
    name: "Polytechnical University of Kabul",
    url: "http://www.polytechnic-kabul.org/",
  },
  {
    country: "AF",
    name: "Rana Institute of Higher Education",
    url: "http://www.rihs.edu.af/",
  },
  {
    country: "AF",
    name: "Sadat Institute of Higher Education",
    url: "http://www.sadat.edu.af/",
  },
  {
    country: "AF",
    name: "Salam University",
    url: "http://www.salam.edu.af/",
  },
  {
    country: "AF",
    name: "Shaikh Zayed University",
    url: "http://www.szu.edu.af/",
  },
  {
    country: "AF",
    name: "Taj Institute of Higher Education",
    url: "http://www.taj.edu.af/",
  },
  {
    country: "AF",
    name: "Takhar University",
    url: "http://www.tu.edu.af/",
  },
  {
    country: "AG",
    name: "American University of Antigua",
    url: "http://www.auamed.org/",
  },
  {
    country: "AG",
    name: "University of Health Sciences Antigua",
    url: "http://www.uhsa.ag/",
  },
  {
    country: "AL",
    name: "Academy of Arts",
    url: "http://www.akademiaearteve.edu.al/",
  },
  {
    country: "AL",
    name: "Academy of Sports and Physical Training",
    url: "http://www.aefs.edu.al/",
  },
  {
    country: "AL",
    name: "Agricultural University of Tirane",
    url: "http://www.ubt.edu.al/",
  },
  {
    country: "AL",
    name: "American University of Tirana",
    url: "http://www.uat.edu.al/",
  },
  {
    country: "AL",
    name: "Beder University",
    url: "http://www.beder.edu.al/",
  },
  {
    country: "AL",
    name: "Epoka University",
    url: "http://www.epoka.edu.al/",
  },
  {
    country: "AL",
    name: "Polytechnic University of Tirane",
    url: "http://www.upt.al/",
  },
  {
    country: "AL",
    name: 'University of Elbasan "Aleksander Xhuvani',
    url: "http://www.uniel.edu.al/",
  },
  {
    country: "AL",
    name: 'University of Gjirokstra "Eqerem Cabej',
    url: "http://www.uogj.edu.al/",
  },
  {
    country: "AL",
    name: 'University of Korca "Fan Noli',
    url: "http://www.unkorce.edu.al/",
  },
  {
    country: "AL",
    name: "University of New York Tirana",
    url: "http://www.unyt.edu.al/",
  },
  {
    country: "AL",
    name: 'University of Shkodra "Luigj Gurakuqi',
    url: "http://www.unishk.edu.al/",
  },
  {
    country: "AL",
    name: "University of Tirana",
    url: "http://www.unitir.edu.al/",
  },
  {
    country: "AL",
    name: 'University of Vlora "Ismail Qemali',
    url: "http://www.univlora.edu.al/",
  },
  {
    country: "AL",
    name: "University “Pavaresia” Vlore",
    url: "http://www.unipavaresia.edu.al/",
  },
  {
    country: "AM",
    name: "American University of Armenia",
    url: "http://www.aua.am/",
  },
  {
    country: "AM",
    name: "Armenian State Agrarian University",
    url: "http://www.asau.am/",
  },
  {
    country: "AM",
    name: "Armenian State University of Economics",
    url: "http://www.asue.am/",
  },
  {
    country: "AM",
    name: "Eurasia International University",
    url: "http://www.eiu.am/",
  },
  {
    country: "AM",
    name: "European Regional Educational Academy of Armenia",
    url: "http://www.eriicta.am/",
  },
  {
    country: "AM",
    name: "French University in Armenia (UFAR)",
    url: "http://www.ufar.am/",
  },
  {
    country: "AM",
    name: "Mehrabyan Medical Institute and Medical College",
    url: "http://www.armmed.am/",
  },
  {
    country: "AM",
    name: "Russian-Armenian (Slavonic) State University",
    url: "http://www.rau.am/",
  },
  {
    country: "AM",
    name: "State Engineering University of Armenia",
    url: "http://www.seua.am/",
  },
  {
    country: "AM",
    name: "Yerevan Haibusak University",
    url: "http://www.haybusak.org/",
  },
  {
    country: "AM",
    name: "Yerevan State Medical University",
    url: "http://www.ysmu.am/",
  },
  {
    country: "AM",
    name: "Yerevan State University",
    url: "http://www.ysu.am/",
  },
  {
    country: "AN",
    name: "American University of the Caribbean, Sint Maarten",
    url: "http://www.aucmed.edu/",
  },
  {
    country: "AN",
    name: "International University School of Medicine (IUSOM)",
    url: "http://www.internationaluniversity-schoolofmedicine.org/",
  },
  {
    country: "AN",
    name: "St.James's School of Medicine, Bonaire",
    url: "http://www.sjsm.org/",
  },
  {
    country: "AN",
    name: "University of Sint Eustatius School of Medicine",
    url: "http://www.eustatiusmed.edu/",
  },
  {
    country: "AN",
    name: "University of the Netherlands Antilles, Curacao",
    url: "http://www.una.an/",
  },
  {
    country: "AO",
    name: "Universidade Católica de Angola",
    url: "http://www.ucan.edu/",
  },
  {
    country: "AO",
    name: "Universidade Gregorio Semedo",
    url: "http://www.ugs.ed.ao/",
  },
  {
    country: "AO",
    name: "Universidade Independente de Angola",
    url: "http://www.unia.ao/",
  },
  {
    country: "AO",
    name: "Universidade Lusíada de Angola",
    url: "http://www.ulangola.net/",
  },
  {
    country: "AO",
    name: "Universidade Metodista de Angola",
    url: "http://www.uma.co.ao/",
  },
  {
    country: "AO",
    name: "Universidade Metropolitana de Angola",
    url: "http://www.unimetroangola.com/",
  },
  {
    country: "AO",
    name: "Universidade Tecnica de Angola",
    url: "http://www.utanga.co.ao/",
  },
  {
    country: "AO",
    name: "Univesidade Agostinho Neto",
    url: "http://www.uan.ao/",
  },
  {
    country: "AR",
    name: "Instituto de Enseñanza Superior del Ejército",
    url: "http://www.iese.edu.ar/",
  },
  {
    country: "AR",
    name: 'Instituto Nacional de Educación Física "General Manuel Belgrano',
    url: "http://www.unlu.edu.ar/Belgrano.htm",
  },
  {
    country: "AR",
    name: "Instituto Tecnológico de Buenos Aires",
    url: "http://www.itba.edu.ar/",
  },
  {
    country: "AR",
    name: "Instituto Universitario Aeronáutico",
    url: "http://www.iua.edu.ar/",
  },
  {
    country: "AR",
    name: "Instituto Universitario CEMA",
    url: "http://www.cema.edu.ar/",
  },
  {
    country: "AR",
    name: "Instituto Universitario de Ciencias de la Salud Fundación H.A. Barceló",
    url: "http://www.barcelo.edu.ar/",
  },
  {
    country: "AR",
    name: "Instituto Universitario de Estudios Navales y Marítimos",
    url: "http://www.inun.edu.ar/",
  },
  {
    country: "AR",
    name: "Instituto Universitario de la Policia Federal",
    url: "http://www.universidad-policial.edu.ar/",
  },
  {
    country: "AR",
    name: "Instituto Universitario Nacional del Arte",
    url: "http://www.iuna.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Abierta Interamericana",
    url: "http://www.vaneduc.edu.ar/uai/",
  },
  {
    country: "AR",
    name: "Universidad Adventista del Plata",
    url: "http://www.uapar.edu/",
  },
  {
    country: "AR",
    name: "Universidad Argentina de la Empresa",
    url: "http://www.uade.edu.ar/",
  },
  {
    country: "AR",
    name: 'Universidad Argentina "John F. Kennedy',
    url: "http://www.kennedy.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Atlantida Argentina",
    url: "http://www.atlantida.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Austral Buenos Aires",
    url: "http://www.austral.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Autónoma de Entre Ríos",
    url: "http://www.uader.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Blas Pascal",
    url: "http://www.ubp.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad CAECE, Buenos Aires",
    url: "http://www.caece.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Católica Argentina Santa Maria de los Buenos Aires",
    url: "http://www.uca.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Católica de Córdoba",
    url: "http://www.uccor.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Católica de Cuyo",
    url: "http://www.uccuyo.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Católica de La Plata",
    url: "http://www.ucalp.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Católica de Salta",
    url: "http://www.ucasal.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Católica de Santa Fé",
    url: "http://www.ucsf.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Católica de Santiago del Estero",
    url: "http://www.ucse.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Champagnat",
    url: "http://www.uch.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad de Belgrano",
    url: "http://www.ub.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad de Bologna - Representación en Buenos Aires",
    url: "http://www.unibo.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad de Buenos Aires",
    url: "http://www.uba.ar/",
  },
  {
    country: "AR",
    name: "Universidad de Ciencias Empresariales y Sociales",
    url: "http://www.uces.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad de Concepción del Uruguay",
    url: "http://www.ucu.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad de Congreso",
    url: "http://www.ucongres.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad de Flores - Buenos Aires",
    url: "http://www.uflo.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad del Aconcagua",
    url: "http://www.uda.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad de la Cuenca del Plata",
    url: "http://www.ucp.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad de la Fraternidad de Agrupaciones Santo Tomas de Aquino (FASTA)",
    url: "http://www.ufasta.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad de la Marina Mercante",
    url: "http://www.udemm.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad del Centro Educativo Latinoamericano Rosario",
    url: "http://www.ucel.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad del Cine - Buenos Aires",
    url: "http://www.ucine.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad del Museo Social Argentino",
    url: "http://www.umsa.edu.ar/",
  },
  {
    country: "AR",
    name: 'Universidad del Norte "Santo Tomás de Aquino',
    url: "http://www.unsta.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad del Salvador",
    url: "http://www.salvador.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad de Mendoza",
    url: "http://www.um.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad de Morón",
    url: "http://www.unimoron.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad de Palermo",
    url: "http://www.palermo.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad de San Andres",
    url: "http://www.udesa.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Empresarial Siglio 21",
    url: "http://www.uesiglo21.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad ISALUD",
    url: "http://www.isalud.edu.ar/",
  },
  {
    country: "AR",
    name: 'Universidad "Juan Agustín Maza',
    url: "http://www.umaza.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Maimónides",
    url: "http://www.maimonides.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Catamarca",
    url: "http://www.unca.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Córdoba",
    url: "http://www.unc.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Cuyo",
    url: "http://www.uncu.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Entre Ríos",
    url: "http://www.uner.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Formosa",
    url: "http://www.unf.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de General San Martín",
    url: "http://www.unsam.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de General Sarmiento",
    url: "http://www.ungs.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Jujuy",
    url: "http://www.unju.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de La Matanza",
    url: "http://www.unlam.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Lanus",
    url: "http://www.unla.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de La Pampa",
    url: "http://www.unlpam.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de la Patagonia Austral",
    url: "http://www.unpa.edu.ar/",
  },
  {
    country: "AR",
    name: 'Universidad Nacional de la Patagonia "San Juan Bosco',
    url: "http://www.unp.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de La Plata",
    url: "http://www.unlp.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de La Rioja",
    url: "http://www.unlar.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional del Centro de la Provincia de Buenos Aires",
    url: "http://www.unicen.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional del Comahue",
    url: "http://www.uncoma.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional del Littoral",
    url: "http://www.unl.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional del Nordeste",
    url: "http://www.unne.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional del Noroeste de la Provincia de Buenos Aires",
    url: "http://www.unnoba.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Lomas de Zamora",
    url: "http://www.unlz.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional del Sur",
    url: "http://www.uns.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Luján",
    url: "http://www.unlu.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Mar del Plata",
    url: "http://www.mdp.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Misiones",
    url: "http://www.unam.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Quilmes",
    url: "http://www.unq.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Río Cuarto",
    url: "http://www.unrc.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Rosario",
    url: "http://www.unr.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Salta",
    url: "http://www.unsa.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de San Juan",
    url: "http://www.unsj.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de San Luis",
    url: "http://www.unsl.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Santiago del Estero",
    url: "http://www.unse.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Tres de Febrero",
    url: "http://www.untref.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Tucumán",
    url: "http://www.unt.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Nacional de Villa María",
    url: "http://www.unvm.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Notarial Argentina",
    url: "http://www.universidadnotarial.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Tecnológica Nacional",
    url: "http://www.utn.edu.ar/",
  },
  {
    country: "AR",
    name: "Universidad Torcuato di Tella",
    url: "http://www.utdt.edu/",
  },
  {
    country: "AT",
    name: "Akademie der bildenden Künste Wien",
    url: "http://www.akbild.ac.at/",
  },
  {
    country: "AT",
    name: "Donau-Universität Krems",
    url: "http://www.donau-uni.ac.at/",
  },
  {
    country: "AT",
    name: "Fachhochschule Burgenland",
    url: "http://www.fh-burgenland.at/",
  },
  {
    country: "AT",
    name: "Fachhochschule für Bank- und Finanzwirtschaft",
    url: "http://www.fh-vie.ac.at/",
  },
  {
    country: "AT",
    name: "Fachhochschule JOANNEUM",
    url: "http://www.fh-joanneum.at/",
  },
  {
    country: "AT",
    name: "Fachhochschule Kärnten",
    url: "http://www.fh-kaernten.ac.at/",
  },
  {
    country: "AT",
    name: "Fachhochschule Krems",
    url: "http://www.fh-krems.ac.at/",
  },
  {
    country: "AT",
    name: "Fachhochschule Kufstein (Tirol)",
    url: "http://www.fh-kufstein.ac.at/",
  },
  {
    country: "AT",
    name: "Fachhochschule Salzburg",
    url: "http://www.fh-sbg.ac.at/",
  },
  {
    country: "AT",
    name: "Fachhochschule St. Pölten",
    url: "http://www.fh-stpoelten.ac.at/",
  },
  {
    country: "AT",
    name: "Fachhochschule Vorarlberg",
    url: "http://www.fhv.at/",
  },
  {
    country: "AT",
    name: "Fachhochschule Wiener Neustadt",
    url: "http://www.fhwn.ac.at/",
  },
  {
    country: "AT",
    name: "Fachhochschulstudiengänge der Wiener Wirtschaft",
    url: "http://www.fhw.at/",
  },
  {
    country: "AT",
    name: "Fachhochschulstudiengänge Hagenberg",
    url: "http://www.fh-hagenberg.at/",
  },
  {
    country: "AT",
    name: "Fachhochschulstudiengänge Krems IMC",
    url: "http://www.imc-krems.ac.at/",
  },
  {
    country: "AT",
    name: "Fachhochschulstudiengänge Steyr",
    url: "http://www.fh-steyr.at/",
  },
  {
    country: "AT",
    name: "Fachhochschulstudiengänge Wels",
    url: "http://www.fh-wels.at/",
  },
  {
    country: "AT",
    name: "Institute of Science and Technology",
    url: "http://www.ist.ac.at/",
  },
  {
    country: "AT",
    name: "Katholisch-Theologische Privatuniversität Linz",
    url: "http://www.kth-linz.ac.at/",
  },
  {
    country: "AT",
    name: "MCI-Management Center Innsbruck",
    url: "http://www.mci.edu/",
  },
  {
    country: "AT",
    name: "Medizinische Universität Graz",
    url: "http://www.meduni-graz.at/",
  },
  {
    country: "AT",
    name: "Medizinische Universität Innsbruck",
    url: "http://www.i-med.ac.at/",
  },
  {
    country: "AT",
    name: "Medizinische Universität Wien",
    url: "http://www.meduniwien.at/",
  },
  {
    country: "AT",
    name: "Montanuniversität Leoben",
    url: "http://www.unileoben.ac.at/",
  },
  {
    country: "AT",
    name: "Private Universität für Medizinische Informatik und Technik Tirol",
    url: "http://www.umit.at/",
  },
  {
    country: "AT",
    name: "Technikum Wien",
    url: "http://www.technikum-wien.at/",
  },
  {
    country: "AT",
    name: "Technische Universität Graz",
    url: "http://www.tugraz.at/",
  },
  {
    country: "AT",
    name: "Technische Universität Wien",
    url: "http://www.tuwien.ac.at/",
  },
  {
    country: "AT",
    name: "Universität für angewandte Kunst Wien",
    url: "http://ns2.uni-ak.ac.at/",
  },
  {
    country: "AT",
    name: "Universität für Bodenkultur Wien",
    url: "http://www.boku.ac.at/",
  },
  {
    country: "AT",
    name: "Universität für künstlerische und industrielle Gestaltung Linz",
    url: "http://www.khs-linz.ac.at/",
  },
  {
    country: "AT",
    name: "Universität für Musik und darstellende Kunst Graz",
    url: "http://www.kug.ac.at/",
  },
  {
    country: "AT",
    name: "Universität für Musik und darstellende Kunst  Wien",
    url: "http://www.mdw.ac.at/",
  },
  {
    country: "AT",
    name: "Universität Graz",
    url: "http://www.kfunigraz.ac.at/",
  },
  {
    country: "AT",
    name: "Universität Innsbruck",
    url: "http://www.uibk.ac.at/",
  },
  {
    country: "AT",
    name: "Universität Klagenfurt",
    url: "http://www.uni-klu.ac.at/",
  },
  {
    country: "AT",
    name: "Universität Linz",
    url: "http://www.uni-linz.ac.at/",
  },
  {
    country: "AT",
    name: "Universität Mozarteum Salzburg",
    url: "http://www.moz.ac.at/",
  },
  {
    country: "AT",
    name: "Universität Salzburg",
    url: "http://www.sbg.ac.at/",
  },
  {
    country: "AT",
    name: "Universität Vienna",
    url: "http://www.univie.ac.at/",
  },
  {
    country: "AT",
    name: "Veterinärmedizinische Universität Wien",
    url: "http://www.vu-wien.ac.at/",
  },
  {
    country: "AT",
    name: "Webster University, Vienna",
    url: "http://www.webster.ac.at/",
  },
  {
    country: "AT",
    name: "Wirtschaftsuniversität Wien",
    url: "http://www.wu-wien.ac.at/",
  },
  {
    country: "AU",
    name: "Australian Catholic University",
    url: "http://www.acu.edu.au/",
  },
  {
    country: "AU",
    name: "Australian Correspondence Schools",
    url: "http://www.acs.edu.au/",
  },
  {
    country: "AU",
    name: "Australian Defence Force Academy",
    url: "http://www.adfa.oz.au/",
  },
  {
    country: "AU",
    name: "Australian Lutheran College",
    url: "http://www.alc.edu.au/",
  },
  {
    country: "AU",
    name: "Australian Maritime College",
    url: "http://www.amc.edu.au/",
  },
  {
    country: "AU",
    name: "Australian National University",
    url: "http://www.anu.edu.au/",
  },
  {
    country: "AU",
    name: "Avondale College",
    url: "http://www.avondale.edu.au/",
  },
  {
    country: "AU",
    name: "Batchelor Institute of Indigenous Tertiary Education",
    url: "http://www.nt.gov.au/batc/",
  },
  {
    country: "AU",
    name: "Blue Mountains Hotel School",
    url: "http://www.hotelschool.com.au/",
  },
  {
    country: "AU",
    name: "Bond University",
    url: "http://www.bond.edu.au/",
  },
  {
    country: "AU",
    name: "Central Queensland University",
    url: "http://www.cqu.edu.au/",
  },
  {
    country: "AU",
    name: "Charles Darwin University",
    url: "http://www.cdu.edu.au/",
  },
  {
    country: "AU",
    name: "Charles Sturt University",
    url: "http://www.csu.edu.au/",
  },
  {
    country: "AU",
    name: "Curtin University of Technology",
    url: "http://www.curtin.edu.au/",
  },
  {
    country: "AU",
    name: "Deakin University",
    url: "http://www.deakin.edu.au/",
  },
  {
    country: "AU",
    name: "Edith Cowan University",
    url: "http://www.ecu.edu.au/",
  },
  {
    country: "AU",
    name: "Flinders University of South Australia",
    url: "http://www.flinders.edu.au/",
  },
  {
    country: "AU",
    name: "Griffith University",
    url: "http://www.gu.edu.au/",
  },
  {
    country: "AU",
    name: "Holmes Institute",
    url: "http://www.holmes.edu.au/",
  },
  {
    country: "AU",
    name: "Institute Of Technology, Australia",
    url: "http://www.iota.edu.au/",
  },
  {
    country: "AU",
    name: "International College of Tourism and Hotel Management",
    url: "http://www.icthm.edu.au/",
  },
  {
    country: "AU",
    name: "James Cook University of North Queensland",
    url: "http://www.jcu.edu.au/",
  },
  {
    country: "AU",
    name: "La Trobe University",
    url: "http://www.latrobe.edu.au/",
  },
  {
    country: "AU",
    name: "Macquarie University",
    url: "http://www.mq.edu.au/",
  },
  {
    country: "AU",
    name: "Marcus Oldham College",
    url: "http://www.marcusoldham.vic.edu.au/",
  },
  {
    country: "AU",
    name: "Monash University",
    url: "http://www.monash.edu.au/",
  },
  {
    country: "AU",
    name: "Murdoch University",
    url: "http://www.murdoch.edu.au/",
  },
  {
    country: "AU",
    name: "Queensland University of Technology",
    url: "http://www.qut.edu.au/",
  },
  {
    country: "AU",
    name: "Royal Melbourne Institute of Technology",
    url: "http://www.rmit.edu.au/",
  },
  {
    country: "AU",
    name: "Southern Cross University",
    url: "http://www.scu.edu.au/",
  },
  {
    country: "AU",
    name: "Swinburne University of Technology",
    url: "http://www.swin.edu.au/",
  },
  {
    country: "AU",
    name: "University of Adelaide",
    url: "http://www.adelaide.edu.au/",
  },
  {
    country: "AU",
    name: "University of Ballarat",
    url: "http://www.ballarat.edu.au/",
  },
  {
    country: "AU",
    name: "University of Canberra",
    url: "http://www.canberra.edu.au/",
  },
  {
    country: "AU",
    name: "University of Divinity",
    url: "http://www.divinity.edu.au/",
  },
  {
    country: "AU",
    name: "University of Melbourne",
    url: "http://www.unimelb.edu.au/",
  },
  {
    country: "AU",
    name: "University of Newcastle",
    url: "http://www.newcastle.edu.au/",
  },
  {
    country: "AU",
    name: "University of New England",
    url: "http://www.une.edu.au/",
  },
  {
    country: "AU",
    name: "University of New South Wales",
    url: "http://www.unsw.edu.au/",
  },
  {
    country: "AU",
    name: "University of Notre Dame Australia",
    url: "http://www.nd.edu.au/",
  },
  {
    country: "AU",
    name: "University of Queensland",
    url: "http://www.uq.edu.au/",
  },
  {
    country: "AU",
    name: "University of South Australia",
    url: "http://www.unisa.edu.au/",
  },
  {
    country: "AU",
    name: "University of Southern Queensland",
    url: "http://www.usq.edu.au/",
  },
  {
    country: "AU",
    name: "University of Sydney",
    url: "http://www.usyd.edu.au/",
  },
  {
    country: "AU",
    name: "University of Tasmania",
    url: "http://www.utas.edu.au/",
  },
  {
    country: "AU",
    name: "University of Technology Sydney",
    url: "http://www.uts.edu.au/",
  },
  {
    country: "AU",
    name: "University of the Sunshine Coast",
    url: "http://www.usc.edu.au/",
  },
  {
    country: "AU",
    name: "University of Western Australia",
    url: "http://www.uwa.edu.au/",
  },
  {
    country: "AU",
    name: "University of Western Sydney",
    url: "http://www.uws.edu.au/",
  },
  {
    country: "AU",
    name: "University of Wollongong",
    url: "http://www.uow.edu.au/",
  },
  {
    country: "AZ",
    name: "Academy of Public Administration",
    url: "http://www.dia.edu.az/",
  },
  {
    country: "AZ",
    name: "Azerbaijan Diplomatic Academy",
    url: "http://www.ada.edu.az/",
  },
  {
    country: "AZ",
    name: "Azerbaijan International University",
    url: "http://www.abu.az/",
  },
  {
    country: "AZ",
    name: "Azerbaijan Medical University",
    url: "http://www.amu.edu.az/",
  },
  {
    country: "AZ",
    name: "Azerbaijan National Conservatorie",
    url: "http://www.conservatory.az/",
  },
  {
    country: "AZ",
    name: "Azerbaijan State Economic University",
    url: "http://www.aseu.az/",
  },
  {
    country: "AZ",
    name: "Azerbaijan State Oil Academy",
    url: "http://adna.jis.az/",
  },
  {
    country: "AZ",
    name: "Azerbaijan Technical University",
    url: "http://www.aztu.az/",
  },
  {
    country: "AZ",
    name: "Azerbaijan Technology University",
    url: "http://www.aztu.edu.az/",
  },
  {
    country: "AZ",
    name: "Azerbaijan Toursim Institute",
    url: "http://www.tourism.edu.az/",
  },
  {
    country: "AZ",
    name: "Azerbaijan University",
    url: "http://www.au.edu.az/",
  },
  {
    country: "AZ",
    name: "Azerbaijan University ol Languages",
    url: "http://www.adu.edu.az/",
  },
  {
    country: "AZ",
    name: "Baki Business University",
    url: "http://www.bbu.edu.az/",
  },
  {
    country: "AZ",
    name: "Baku Slavic University",
    url: "http://www.bsu-edu.org/",
  },
  {
    country: "AZ",
    name: "Baku State University",
    url: "http://www.bsu.az/",
  },
  {
    country: "AZ",
    name: "Ganja State University",
    url: "http://www.gsu.az/",
  },
  {
    country: "AZ",
    name: "Khazar University",
    url: "http://www.khazar.org/",
  },
  {
    country: "AZ",
    name: "Lankaran State University",
    url: "http://www.lsu.edu.az/",
  },
  {
    country: "AZ",
    name: "Nakhchivan Private University",
    url: "http://www.az-npu.org/",
  },
  {
    country: "AZ",
    name: "Nakhchivan State University",
    url: "http://www.ndu.edu.az/",
  },
  {
    country: "AZ",
    name: "National Aviation Academy",
    url: "http://www.naa.edu.az/",
  },
  {
    country: "AZ",
    name: "Odlar Yurdu University",
    url: "http://www.oyu.edu.az/",
  },
  {
    country: "AZ",
    name: "Police Academy",
    url: "http://www.pa.edu.az/",
  },
  {
    country: "AZ",
    name: "Qafqaz University",
    url: "http://www.qafqaz.edu.az/",
  },
  {
    country: "AZ",
    name: "Sumgait State University",
    url: "http://www.sdu.edu.az/",
  },
  {
    country: "AZ",
    name: "Western University",
    url: "http://www.wu.edu.az/",
  },
  {
    country: "BA",
    name: "American University",
    url: "http://www.aubih.ba/",
  },
  {
    country: "BA",
    name: "International Burch University",
    url: "http://www.ibu.edu.ba/",
  },
  {
    country: "BA",
    name: "International University of Sarajevo",
    url: "http://www.ius.edu.ba/",
  },
  {
    country: "BA",
    name: "International University of Travnik",
    url: "http://www.iu-travnik.com/",
  },
  {
    country: "BA",
    name: "Sarajevo Film Academy",
    url: "http://www.sfa.ba/",
  },
  {
    country: "BA",
    name: "Slobomir P Univerzitet",
    url: "http://www.spu.ba/",
  },
  {
    country: "BA",
    name: "University of Banja Luka",
    url: "http://www.unibl.org/",
  },
  {
    country: "BA",
    name: "University of Bihac",
    url: "http://www.unbi.ba/",
  },
  {
    country: "BA",
    name: "University of East Srarajevo",
    url: "http://www.unssa.rs.ba/",
  },
  {
    country: "BA",
    name: "University of Mostar",
    url: "http://www.sve-mo.ba/",
  },
  {
    country: "BA",
    name: "University of Sarajevo",
    url: "http://www.unsa.ba/",
  },
  {
    country: "BA",
    name: "University of Tuzla",
    url: "http://www.untz.ba/",
  },
  {
    country: "BA",
    name: "University of Zenica",
    url: "http://www.unze.ba/",
  },
  {
    country: "BA",
    name: "University Vitez In Travnik",
    url: "http://www.unvi.edu.ba/",
  },
  {
    country: "BA",
    name: 'Univerzitet u Mostaru "Dzemal Bijedic',
    url: "http://www.unmo.ba/",
  },
  {
    country: "BB",
    name: "University of the West Indies, Cave Hill",
    url: "http://www.uwichill.edu.bb/",
  },
  {
    country: "BD",
    name: "Ahsanullah University of Science & Technology",
    url: "http://www.aust.edu/",
  },
  {
    country: "BD",
    name: "American International University - Bangladesh",
    url: "http://www.aiub.edu/",
  },
  {
    country: "BD",
    name: "Asa University Bangladesh",
    url: "http://www.asaub.edu.bd/",
  },
  {
    country: "BD",
    name: "Asian University of Bangladesh",
    url: "http://www.aub-bd.org/",
  },
  {
    country: "BD",
    name: "Atish Dipankar University",
    url: "http://www.atishdipankaruniversity.edu.bd/",
  },
  {
    country: "BD",
    name: "Bangabandhu Sheikh Mujibur Rahman Agricultural University",
    url: "http://www.bsmrau.edu.bd/",
  },
  {
    country: "BD",
    name: "Bangabandhu Sheikh Mujibur Rahman Medical University",
    url: "http://www.bsmmu.edu.bd/",
  },
  {
    country: "BD",
    name: "Bangladesh Agricultural University",
    url: "http://www.bau.edu.bd/",
  },
  {
    country: "BD",
    name: "Bangladesh Open University",
    url: "http://www.bou.edu.bd/",
  },
  {
    country: "BD",
    name: "Bangladesh University",
    url: "http://www.bu.edu.bd/",
  },
  {
    country: "BD",
    name: "Bangladesh University of Business & Technology",
    url: "http://www.bubt.edu.bd/",
  },
  {
    country: "BD",
    name: "Bangladesh University of Engineering and Technology",
    url: "http://www.buet.ac.bd/",
  },
  {
    country: "BD",
    name: "Bangladesh University of Professionals",
    url: "http://www.bup.edu.bd/",
  },
  {
    country: "BD",
    name: "Bangladesh University of Textiles",
    url: "http://www.butex.edu.bd/",
  },
  {
    country: "BD",
    name: "Begum Rokeya University, Rangpur",
    url: "http://www.brur.ac.bd/",
  },
  {
    country: "BD",
    name: "BGC Trust University, Bangladesh",
    url: "http://www.bgctrustbd.org/btub/",
  },
  {
    country: "BD",
    name: "Brac University",
    url: "http://www.bracuniversity.net/",
  },
  {
    country: "BD",
    name: "Chittagong Independent University",
    url: "http://www.ciu.edu.bd/",
  },
  {
    country: "BD",
    name: "Chittagong University of Engineering and Technology",
    url: "http://www.cuet.ac.bd/",
  },
  {
    country: "BD",
    name: "City University",
    url: "http://www.cityuniversity.edu.bd/",
  },
  {
    country: "BD",
    name: "Comilla University",
    url: "http://www.cou.ac.bd/",
  },
  {
    country: "BD",
    name: "Daffodil International University",
    url: "http://www.daffodilvarsity.edu.bd/",
  },
  {
    country: "BD",
    name: "Darul Ihsan University",
    url: "http://www.diu.ac.bd/",
  },
  {
    country: "BD",
    name: "Dhaka International University",
    url: "http://www.diu.net.bd/",
  },
  {
    country: "BD",
    name: "Dhaka University of Engineering and Technology",
    url: "http://www.duet.ac.bd/",
  },
  {
    country: "BD",
    name: "East Delta University",
    url: "http://www.eastdelta.edu.bd/",
  },
  {
    country: "BD",
    name: "Eastern University",
    url: "http://www.easternuni.edu.bd/",
  },
  {
    country: "BD",
    name: "East West University",
    url: "http://www.ewubd.edu/",
  },
  {
    country: "BD",
    name: "East-West University, Mohakhali",
    url: "http://www.ewubd.edu/",
  },
  {
    country: "BD",
    name: "Green University of Bangladesh",
    url: "http://www.green.edu.bd/",
  },
  {
    country: "BD",
    name: "Hajee Mohammad Danesh Science and Technology University",
    url: "http://www.hstu.ac.bd/",
  },
  {
    country: "BD",
    name: "IBAIS University",
    url: "http://www.ibaisuniv.edu.bd/",
  },
  {
    country: "BD",
    name: "Independent University, Bangladesh",
    url: "http://www.iub.edu.bd/",
  },
  {
    country: "BD",
    name: "International Culture University",
    url: "http://www.icu-edu.org/",
  },
  {
    country: "BD",
    name: "International Islamic University Chittagong",
    url: "http://www.iiuc.ac.bd/",
  },
  {
    country: "BD",
    name: "International University of Business Agriculture and Technology",
    url: "http://www.iubat.edu/",
  },
  {
    country: "BD",
    name: "Islamic University Kushtia",
    url: "http://www.iu.ac.bd/",
  },
  {
    country: "BD",
    name: "Islamic University of Technology",
    url: "http://www.iutoic-dhaka.edu/",
  },
  {
    country: "BD",
    name: "Jagannath University",
    url: "http://www.jnu.ac.bd/",
  },
  {
    country: "BD",
    name: "Jahangirnagar University",
    url: "http://www.juniv.edu/",
  },
  {
    country: "BD",
    name: "Jatiya Kabi Kazi Nazrul Islam University",
    url: "http://www.jkkniu.edu.bd/",
  },
  {
    country: "BD",
    name: "Khulna University",
    url: "http://www.ku.ac.bd/",
  },
  {
    country: "BD",
    name: "Khulna University of Engineering And Technology",
    url: "http://www.kuet.ac.bd/",
  },
  {
    country: "BD",
    name: "Leading University",
    url: "http://www.lus.ac.bd/",
  },
  {
    country: "BD",
    name: "Manarat International University",
    url: "http://www.manarat.ac.bd/",
  },
  {
    country: "BD",
    name: "Mawlana Bhashani Science And Technology University",
    url: "http://www.mbstu.ac.bd/",
  },
  {
    country: "BD",
    name: "Metropolitan University",
    url: "http://www.metrouni.edu.bd/",
  },
  {
    country: "BD",
    name: "Military Institute of Science and Technology",
    url: "http://www.mist.ac.bd/",
  },
  {
    country: "BD",
    name: "National University",
    url: "http://www.nu.edu.bd/",
  },
  {
    country: "BD",
    name: "Noakhali University of Science and Technology",
    url: "http://www.nstu.edu.bd/",
  },
  {
    country: "BD",
    name: "North East University Bangladesh",
    url: "http://www.neub.edu.bd/",
  },
  {
    country: "BD",
    name: "Northern University Bangladesh",
    url: "http://www.nub.ac.bd/",
  },
  {
    country: "BD",
    name: "North South University",
    url: "http://www.northsouth.edu/",
  },
  {
    country: "BD",
    name: "Pabna University of Science and Technology",
    url: "http://www.pust.ac.bd/",
  },
  {
    country: "BD",
    name: "Patuakhali Science and Technology University",
    url: "http://www.pstu.ac.bd/",
  },
  {
    country: "BD",
    name: "People's University of Bangladesh",
    url: "http://www.pub.ac.bd/",
  },
  {
    country: "BD",
    name: "Premier University",
    url: "http://www.puc.ac.bd/",
  },
  {
    country: "BD",
    name: "Presidency University",
    url: "http://www.presidency.edu.bd/",
  },
  {
    country: "BD",
    name: "Queens University",
    url: "http://www.queensuniversity.edu.bd/",
  },
  {
    country: "BD",
    name: "Rajshahi University of Engineering and Technology",
    url: "http://www.ruet.ac.bd/",
  },
  {
    country: "BD",
    name: "Shahjalal University of Science and Technology",
    url: "http://www.sust.edu/",
  },
  {
    country: "BD",
    name: "Southeast University (BD)",
    url: "http://www.seu.ac.bd/",
  },
  {
    country: "BD",
    name: "Southern University Bangladesh",
    url: "http://www.southern.edu.bd/",
  },
  {
    country: "BD",
    name: "Stamford University",
    url: "http://www.stamforduniversity.edu.bd/",
  },
  {
    country: "BD",
    name: "Sylhet Agricultural University",
    url: "http://www.sau.ac.bd/",
  },
  {
    country: "BD",
    name: "Sylhet Engineering College",
    url: "http://www.sec.ac.bd/",
  },
  {
    country: "BD",
    name: "Sylhet International University",
    url: "http://www.siu.edu.bd/",
  },
  {
    country: "BD",
    name: "United International University",
    url: "http://www.uiu.ac.bd/",
  },
  {
    country: "BD",
    name: "University of Asia Pacific, Dhanmondi",
    url: "http://www.uap-bd.edu/",
  },
  {
    country: "BD",
    name: "University of Chittagong",
    url: "http://www.cu.ac.bd/",
  },
  {
    country: "BD",
    name: "University of Development Alternative",
    url: "http://www.uoda.edu.bd/",
  },
  {
    country: "BD",
    name: "University of Dhaka",
    url: "http://www.du.ac.bd/",
  },
  {
    country: "BD",
    name: "University of Information Technology & Sciences",
    url: "http://www.uits.edu.bd/",
  },
  {
    country: "BD",
    name: "University of Liberal Arts",
    url: "http://www.ulab.edu.bd/",
  },
  {
    country: "BD",
    name: "University of Rajshahi",
    url: "http://www.ru.ac.bd/",
  },
  {
    country: "BD",
    name: "University of Science & Technology Chittagong",
    url: "http://www.ustc.ac.bd/",
  },
  {
    country: "BD",
    name: "World University of Bangladesh",
    url: "http://www.wub.edu/",
  },
  {
    country: "BE",
    name: "Brexgata University Academy",
    url: "http://www.brexgata.eu/",
  },
  {
    country: "BE",
    name: "Brussels Management School (ICHEC)",
    url: "http://www.ichec.be/",
  },
  {
    country: "BE",
    name: "Brussels School of International Studies",
    url: "http://www.ukc.ac.uk/international/bsis/",
  },
  {
    country: "BE",
    name: "College of Europe",
    url: "http://www.coleurope.eu/",
  },
  {
    country: "BE",
    name: "Continental Theological Seminary",
    url: "http://www.ctsem.edu/",
  },
  {
    country: "BE",
    name: "ECAM - Institut Supérieur Industriel",
    url: "http://www.ecam.be/",
  },
  {
    country: "BE",
    name: "EHSAL - Europese Hogeschool Brussel",
    url: "http://www.ehsal.be/",
  },
  {
    country: "BE",
    name: "Erasmushogeschool Brussel",
    url: "http://www.ehb.be/",
  },
  {
    country: "BE",
    name: "European Carolus Magnus University",
    url: "http://www.carolus-magnus-university.eu/",
  },
  {
    country: "BE",
    name: "European International University",
    url: "http://www.europeaniu.org/",
  },
  {
    country: "BE",
    name: "Evangelische Theologische Faculteit, Leuven",
    url: "http://www.etf.edu/",
  },
  {
    country: "BE",
    name: "Faculté Polytechnique de Mons",
    url: "http://www.fpms.ac.be/",
  },
  {
    country: "BE",
    name: "Facultés Universitaires Catholiques de Mons",
    url: "http://www.fucam.ac.be/",
  },
  {
    country: "BE",
    name: "Facultés Universitaires Notre-Dame de la Paix",
    url: "http://www.fundp.ac.be/",
  },
  {
    country: "BE",
    name: "Facultés Universitaires Saint-Louis",
    url: "http://www.fusl.ac.be/",
  },
  {
    country: "BE",
    name: "Faculté Universitaire des Sciences Agronomiques de Gembloux",
    url: "http://www.fsagx.ac.be/",
  },
  {
    country: "BE",
    name: "Fondation Universitaire Luxembourgeoise",
    url: "http://www.ful.ac.be/",
  },
  {
    country: "BE",
    name: "Hasselt University",
    url: "http://www.uhasselt.be/",
  },
  {
    country: "BE",
    name: "Hogere Zeevaartschool - Maritime Academy",
    url: "http://www.hzs.be/",
  },
  {
    country: "BE",
    name: "Hogeschool Antwerpen",
    url: "http://www.ha.be/",
  },
  {
    country: "BE",
    name: "Hogeschool voor Wetenschap en Kunst (VLEKHO), Brussel",
    url: "http://www.vlekho.be/",
  },
  {
    country: "BE",
    name: "Hogeschool voor Wetenschap & Kunst",
    url: "http://www.wenk.be/",
  },
  {
    country: "BE",
    name: "Hogeschool West-Vlaanderen (TU)",
    url: "http://www.howest.be/",
  },
  {
    country: "BE",
    name: "Karel De Grote Hogeschool",
    url: "http://www.kdg.be/",
  },
  {
    country: "BE",
    name: "Katholieke Hogeschool Kempen",
    url: "http://www.khk.be/",
  },
  {
    country: "BE",
    name: "Katholieke Hogeschool Leuven",
    url: "http://www.khleuven.be/",
  },
  {
    country: "BE",
    name: "Katholieke Hogeschool Limburg",
    url: "http://www.khlim.be/",
  },
  {
    country: "BE",
    name: "Katholieke Universiteit Brussel",
    url: "http://www.kubrussel.ac.be/",
  },
  {
    country: "BE",
    name: "Katholieke Universiteit Leuven",
    url: "http://www.kuleuven.ac.be/",
  },
  {
    country: "BE",
    name: "Katholieke Universiteit Leuven, Campus Kortrijk",
    url: "http://www.kulak.ac.be/",
  },
  {
    country: "BE",
    name: "Royal Military Academy",
    url: "http://www.rma.ac.be/",
  },
  {
    country: "BE",
    name: "Thierry Graduate School of Leadership",
    url: "http://www.thierryschool.org/",
  },
  {
    country: "BE",
    name: "United Business Institute",
    url: "http://www.ubi.edu/",
  },
  {
    country: "BE",
    name: "Université Catholique de Louvain",
    url: "http://www.ucl.ac.be/",
  },
  {
    country: "BE",
    name: "Université de Liège",
    url: "http://www.ulg.ac.be/",
  },
  {
    country: "BE",
    name: "Université de Mons-Hainaut",
    url: "http://www.umh.ac.be/",
  },
  {
    country: "BE",
    name: "Universiteit Antwerpen",
    url: "http://www.ua.ac.be/",
  },
  {
    country: "BE",
    name: "Universiteit Antwerpen Management School",
    url: "http://www.uams.be/",
  },
  {
    country: "BE",
    name: "Universiteit Antwerpen, UFSIA",
    url: "http://www.ufsia.ac.be/",
  },
  {
    country: "BE",
    name: "Universiteit Gent",
    url: "http://www.rug.ac.be/",
  },
  {
    country: "BE",
    name: "Université Libre de Bruxelles",
    url: "http://www.ulb.ac.be/",
  },
  {
    country: "BE",
    name: "Vesalius College",
    url: "http://www.vesalius.edu/",
  },
  {
    country: "BE",
    name: "Vlerick Leuven Gent Management School",
    url: "http://www.vlerick.be/",
  },
  {
    country: "BE",
    name: "Vrije Universiteit Brussel",
    url: "http://www.vub.ac.be/",
  },
  {
    country: "BF",
    name: "Université de Ouagadougou",
    url: "http://www.univ-ouaga.bf/",
  },
  {
    country: "BG",
    name: 'Academy of Economics "Dimitur A. Tscenov',
    url: "http://www.uni-svishtov.bg/",
  },
  {
    country: "BG",
    name: "Academy of Music, Dance and Fine Arts",
    url: "http://amti.hit.bg/",
  },
  {
    country: "BG",
    name: "Agricultural University of Plovdiv",
    url: "http://www.au-plovdiv.bg/",
  },
  {
    country: "BG",
    name: "American University in Bulgaria",
    url: "http://www.aubg.bg/",
  },
  {
    country: "BG",
    name: "Bourgas Free University",
    url: "http://www.bfu.bg/",
  },
  {
    country: "BG",
    name: 'Bourgas University "Prof. Assen Zlatarov',
    url: "http://www.btu.bg/",
  },
  {
    country: "BG",
    name: "City University Programs in Bulgaria",
    url: "http://www.cityu.bg/",
  },
  {
    country: "BG",
    name: "International University College",
    url: "http://www.vumk.eu/",
  },
  {
    country: "BG",
    name: "Medical University of Sofia",
    url: "http://www.mu-sofia.bg/",
  },
  {
    country: "BG",
    name: "Medical University Pleven",
    url: "http://www.mu-pleven.bg/",
  },
  {
    country: "BG",
    name: "Medical University Plovdiv",
    url: "http://www.meduniversity-plovdiv.bg/",
  },
  {
    country: "BG",
    name: "Medical University Varna",
    url: "http://www.mu-varna.bg/",
  },
  {
    country: "BG",
    name: "Military University Shoumen",
    url: "http://www.pv-ma.bg/",
  },
  {
    country: "BG",
    name: 'National Academy for Theatre and Film Arts "Krustju Sarafov',
    url: "http://www.natfiz.bg/",
  },
  {
    country: "BG",
    name: "National Academy of Arts",
    url: "http://www.nha-bg.org/",
  },
  {
    country: "BG",
    name: 'National Academy of Music "Pantcho Vladigerov',
    url: "http://www.nma.bg/",
  },
  {
    country: "BG",
    name: "National Sports Academy Sofia",
    url: "http://www.nsa.bg/",
  },
  {
    country: "BG",
    name: "New Bulgarian University",
    url: "http://www.nbu.bg/",
  },
  {
    country: "BG",
    name: 'Shoumen University "Konstantin Preslavski',
    url: "http://www.shu-bg.net/",
  },
  {
    country: "BG",
    name: 'Sofia University "St. Kliment Ohridski',
    url: "http://www.uni-sofia.bg/",
  },
  {
    country: "BG",
    name: 'South-West University "Neofit Rilski',
    url: "http://www.swu.bg/",
  },
  {
    country: "BG",
    name: "Technical University of Gabrovo",
    url: "http://www.tugab.bg/",
  },
  {
    country: "BG",
    name: "Technical University of Sofia",
    url: "http://www.tu-sofia.bg/",
  },
  {
    country: "BG",
    name: "Technical University of Varna",
    url: "http://www.tu-varna.acad.bg/",
  },
  {
    country: "BG",
    name: "Trakia University Stara Zagora",
    url: "http://www.uni-sz.bg/",
  },
  {
    country: "BG",
    name: "University of Architecture, Civil Engineering and Geodesy",
    url: "http://www.uacg.bg/",
  },
  {
    country: "BG",
    name: "University of Chemical Technology and Metallurgy",
    url: "http://www.uctm.edu/",
  },
  {
    country: "BG",
    name: "University of Economics Varna",
    url: "http://www.ue-varna.bg/",
  },
  {
    country: "BG",
    name: "University of Food Technology",
    url: "http://www.uft-plovdiv.bg/",
  },
  {
    country: "BG",
    name: "University of Forestry Sofia",
    url: "http://www.ltu.bg/",
  },
  {
    country: "BG",
    name: 'University of Mining and Geology "St. Ivan Rils',
    url: "http://www.mgu.bg/",
  },
  {
    country: "BG",
    name: "University of National and World Economy",
    url: "http://www.unwe.acad.bg/",
  },
  {
    country: "BG",
    name: "University of Plovdiv",
    url: "http://www.uni-plovdiv.bg/",
  },
  {
    country: "BG",
    name: "University of Rousse",
    url: "http://www.ru.acad.bg/",
  },
  {
    country: "BG",
    name: "Varna Free University",
    url: "http://www.vfu.bg/",
  },
  {
    country: "BG",
    name: 'Veliko Turnovo University "Cyril and Methodius',
    url: "http://www.uni-vt.bg/",
  },
  {
    country: "BH",
    name: "Al Ahlia University",
    url: "http://www.ahliauniversity.org/",
  },
  {
    country: "BH",
    name: "Ama International University",
    url: "http://www.amaiu.edu.bh/",
  },
  {
    country: "BH",
    name: "Applied Science University",
    url: "http://www.asu.edu.bh/",
  },
  {
    country: "BH",
    name: "Arabian Gulf University",
    url: "http://www.agu.edu.bh/",
  },
  {
    country: "BH",
    name: "Arab Open University",
    url: "http://www.aou.org.bh/",
  },
  {
    country: "BH",
    name: "Bahrain Polytechnic",
    url: "http://www.polytechnic.bh/",
  },
  {
    country: "BH",
    name: "Gulf University College",
    url: "http://www.gulfuniversity.net/",
  },
  {
    country: "BH",
    name: "New York Instiute of Technology",
    url: "http://www.nyit.edu.bh/",
  },
  {
    country: "BH",
    name: "RCSI-Medical University of Bahrain",
    url: "http://www.rcsi-mub.com/",
  },
  {
    country: "BH",
    name: "The Kingdom University",
    url: "http://www.ku.edu.bh/",
  },
  {
    country: "BH",
    name: "University College Bahrain",
    url: "http://www.ucb.edu.bh/",
  },
  {
    country: "BH",
    name: "University of Bahrain",
    url: "http://www.uob.edu.bh/",
  },
  {
    country: "BI",
    name: "Hope Africa University",
    url: "http://hopeafricauniversity.org/",
  },
  {
    country: "BI",
    name: "Université du Burundi",
    url: "http://www.ub.edu.bi/",
  },
  {
    country: "BI",
    name: "Université Lumière de Bujumbura",
    url: "http://www.ulbu.bi/",
  },
  {
    country: "BJ",
    name: "Espam Formation University",
    url: "http://www.espam-formationuc.org/",
  },
  {
    country: "BJ",
    name: "Houdegbe North American University Benin",
    url: "http://www.hnaubenin.org/",
  },
  {
    country: "BJ",
    name: "Université d'Abomey-Calavi (UAC)",
    url: "http://www.uac.bj/",
  },
  {
    country: "BM",
    name: "Bermuda College",
    url: "http://www.bercol.bm/",
  },
  {
    country: "BN",
    name: "Institut Teknologi Brunei",
    url: "http://www.itb.edu.bn/",
  },
  {
    country: "BN",
    name: "Universiti Islam Sultan Sharif Ali",
    url: "http://www.unissa.edu.bn/",
  },
  {
    country: "BN",
    name: "University of Brunei Darussalam",
    url: "http://www.ubd.edu.bn/",
  },
  {
    country: "BO",
    name: "Escuela Militar de Ingeniería",
    url: "http://www.emi.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Adventista de Bolivia",
    url: "http://www.uab.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Amazonica de Pando",
    url: "http://www.uapnet.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Andina Simón Bolivar",
    url: "http://www.uasb.edu.bo/",
  },
  {
    country: "BO",
    name: 'Universidad Autónoma del Beni "José Ballivián',
    url: "http://www.uabjb.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Autónoma Gabriel René Moreno",
    url: "http://www.uagrm.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Autónoma Juan Misael Saracho",
    url: "http://www.uajms.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Autónoma Tomás Frías",
    url: "http://www.uatf.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Católica Boliviana, Cochabamba",
    url: "http://www.ucbcba.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Católica Boliviana, La Paz",
    url: "http://www.ucb.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Católica Boliviana, Santa Cruz",
    url: "http://www.ucbscz.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Católica Boliviana, Tarija",
    url: "http://www.ucbtja.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Central (BO)",
    url: "http://www.unicen.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad de Aquino Bolivia",
    url: "http://www.udabol.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Domingo Savio",
    url: "http://www.upds.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Empresarial Mateo Kuljis",
    url: "http://www.unikuljis.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Juan Misael Saracho",
    url: "http://www.uajms.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Los Andes",
    url: "http://www.udelosandes.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Mayor de San Andrés",
    url: "http://www.umsanet.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Mayor de San Simón",
    url: "http://www.umss.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Nacional Siglo XX Llallagua",
    url: "http://www.unsxx.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Nuestra Senora de La Paz",
    url: "http://www.unslp.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad NUR",
    url: "http://www.nur.edu/",
  },
  {
    country: "BO",
    name: "Universidad Privada Abierta Latinoamericana",
    url: "http://www.upal.edu/",
  },
  {
    country: "BO",
    name: "Universidad Privada Boliviana",
    url: "http://www.upb.edu/",
  },
  {
    country: "BO",
    name: "Universidad Privada del Valle",
    url: "http://www.univalle.edu/",
  },
  {
    country: "BO",
    name: "Universidad Privada de Santa Cruz de la Sierra",
    url: "http://www.upsa.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Privada Franz Tamayo (UNIFRANZ)",
    url: "http://www.unifranz.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Salesiana de Bolivia",
    url: "http://www.usalesiana.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad San Francisco Xavier",
    url: "http://www.usfx.info/",
  },
  {
    country: "BO",
    name: "Universidad San Francisco Xavier de Chuquisaca",
    url: "http://www.usfx.edu.bo/",
  },
  {
    country: "BO",
    name: "Universidad Técnica de Oruro",
    url: "http://www.uto.edu.bo/",
  },
  {
    country: "BR",
    name: "Centro Regional Universitário de Espiríto Santo do Pinhal",
    url: "http://www.creupi.br/",
  },
  {
    country: "BR",
    name: "Centro Universitário Adventista de São Paulo",
    url: "http://www.unasp.edu.br/",
  },
  {
    country: "BR",
    name: "Centro Universitário Barao de Maua",
    url: "http://www.baraodemaua.br/",
  },
  {
    country: "BR",
    name: "Centro Universitário Claretiano",
    url: "http://www.claretiano.edu.br/",
  },
  {
    country: "BR",
    name: "Centro Universitário de Araraquara",
    url: "http://www.uniara.com.br/",
  },
  {
    country: "BR",
    name: "Centro Universitário de João Pessoa",
    url: "http://www.unipe.br/",
  },
  {
    country: "BR",
    name: "Centro Universitário Monte Serrat",
    url: "http://www.unimonte.br/",
  },
  {
    country: "BR",
    name: "Centro Universitário Newton Paiva",
    url: "http://www.newtonpaiva.br/",
  },
  {
    country: "BR",
    name: "Centro Universitário Plinio Leite",
    url: "http://www.unipli.com.br/",
  },
  {
    country: "BR",
    name: "Centro Universitário Senac",
    url: "http://www.sp.senac.br/",
  },
  {
    country: "BR",
    name: "Centro Universitário Serra dos Órgãos",
    url: "http://www.unifeso.edu.br/",
  },
  {
    country: "BR",
    name: "EMESCAM - Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória",
    url: "http://www.emescam.br/",
  },
  {
    country: "BR",
    name: "FAAP - Fundação Armando Alvares Penteado",
    url: "http://www.faap.br/",
  },
  {
    country: "BR",
    name: "Faculdade Integradas do Ceará",
    url: "http://www.fic.br/",
  },
  {
    country: "BR",
    name: "Faculdade Italo Brasileira",
    url: "http://www.italo.br/",
  },
  {
    country: "BR",
    name: "Faculdade Jaguariúna",
    url: "http://www.faj.br/",
  },
  {
    country: "BR",
    name: "Faculdades Integradas Curitiba",
    url: "http://www.faculdadescuritiba.br/",
  },
  {
    country: "BR",
    name: "Faculdades Integradas do Brasil - UNIBRASIL",
    url: "http://www.unibrasil.com.br/",
  },
  {
    country: "BR",
    name: "Faculdades Integradas Toledo",
    url: "http://www.toledo.br/",
  },
  {
    country: "BR",
    name: "Faculdades Integradas UPIS",
    url: "http://www.upis.br/",
  },
  {
    country: "BR",
    name: "FAE Business School - Faculdade de Administração e Economia",
    url: "http://www.fae.edu/",
  },
  {
    country: "BR",
    name: "Fundação Educacional de Ituverava",
    url: "http://www.feituverava.com.br/",
  },
  {
    country: "BR",
    name: "Instituto Mauá de Tecnologia",
    url: "http://www.maua.br/",
  },
  {
    country: "BR",
    name: "Instituto Nacional de Matemática Pura e Aplicada - IMPA",
    url: "http://www.impa.br/",
  },
  {
    country: "BR",
    name: "Instituto Tecnológico de Aeronáutica",
    url: "http://www.ita.br/",
  },
  {
    country: "BR",
    name: "Pontifícia Universidade Católica de Campinas",
    url: "http://www.puccamp.br/",
  },
  {
    country: "BR",
    name: "Pontifícia Universidade Católica de Minas Gerais",
    url: "http://www.pucminas.br/",
  },
  {
    country: "BR",
    name: "Pontifícia Universidade Católica de São Paulo",
    url: "http://www.pucsp.br/",
  },
  {
    country: "BR",
    name: "Pontificia Universidade Católica do Paraná",
    url: "http://www.pucpr.br/",
  },
  {
    country: "BR",
    name: "Pontifícia Universidade Católica do Rio de Janeiro",
    url: "http://www.puc-rio.br/",
  },
  {
    country: "BR",
    name: "Pontifícia Universidade Católica do Rio Grande do Sul",
    url: "http://www.pucrs.br/",
  },
  {
    country: "BR",
    name: "Universidade Bandeirante de São Paulo",
    url: "http://www.uniban.br/",
  },
  {
    country: "BR",
    name: "Universidade Braz Cubas",
    url: "http://www.brazcubas.br/",
  },
  {
    country: "BR",
    name: "Universidade Camilo Castelo Branco",
    url: "http://www.unicastelo.br/",
  },
  {
    country: "BR",
    name: "Universidade Candido Mendes",
    url: "http://www.candidomendes.br/",
  },
  {
    country: "BR",
    name: "Universidade Castelo Branco",
    url: "http://www.castelobranco.br/",
  },
  {
    country: "BR",
    name: "Universidade Católica de Brasília",
    url: "http://www.ucb.br/",
  },
  {
    country: "BR",
    name: "Universidade Católica de Goiás",
    url: "http://www.ucg.br/",
  },
  {
    country: "BR",
    name: "Universidade Católica de Pelotas",
    url: "http://www.ucpel.tche.br/",
  },
  {
    country: "BR",
    name: "Universidade Católica de Pernambuco",
    url: "http://www.unicap.br/",
  },
  {
    country: "BR",
    name: "Universidade Católica de Petrópolis",
    url: "http://www.ucp.br/",
  },
  {
    country: "BR",
    name: "Universidade Católica de Salvador",
    url: "http://www.ucsal.br/",
  },
  {
    country: "BR",
    name: "Universidade Católica de Santos",
    url: "http://www.unisantos.com.br/",
  },
  {
    country: "BR",
    name: "Universidade Católica Dom Bosco",
    url: "http://www.unibosco.br/",
  },
  {
    country: "BR",
    name: "Universidade Cidade de São Paulo",
    url: "http://www.unicid.br/",
  },
  {
    country: "BR",
    name: "Universidade Cruzeiro do Sul",
    url: "http://www.unicsul.br/",
  },
  {
    country: "BR",
    name: "Universidade da Amazônia",
    url: "http://www.unama.br/",
  },
  {
    country: "BR",
    name: "Universidade da Região da Campanha",
    url: "http://www.urcamp.tche.br/",
  },
  {
    country: "BR",
    name: "Universidade de Alfenas",
    url: "http://www.unifenas.br/",
  },
  {
    country: "BR",
    name: "Universidade de Brasília",
    url: "http://www.unb.br/",
  },
  {
    country: "BR",
    name: "Universidade de Caxias do Sul",
    url: "http://www.ucs.tche.br/",
  },
  {
    country: "BR",
    name: "Universidade de Cruz Alta",
    url: "http://www.unicruz.tche.br/",
  },
  {
    country: "BR",
    name: "Universidade de Fortaleza",
    url: "http://www.unifor.br/",
  },
  {
    country: "BR",
    name: "Universidade de Franca",
    url: "http://www.unifran.br/",
  },
  {
    country: "BR",
    name: "Universidade de Guarulhos",
    url: "http://www.ung.br/",
  },
  {
    country: "BR",
    name: "Universidade de Itaúna",
    url: "http://web.prover.com.br/fui/",
  },
  {
    country: "BR",
    name: "Universidade de Marília",
    url: "http://www.unimar.br/",
  },
  {
    country: "BR",
    name: "Universidade de Mogi das Cruzes",
    url: "http://www.umc.br/",
  },
  {
    country: "BR",
    name: "Universidade de Nova Iguaçu",
    url: "http://www.unig.br/",
  },
  {
    country: "BR",
    name: "Universidade de Passo Fundo",
    url: "http://www.upf.tche.br/",
  },
  {
    country: "BR",
    name: "Universidade de Pernambuco",
    url: "http://www.upe.br/",
  },
  {
    country: "BR",
    name: "Universidade de Ribeirão Preto",
    url: "http://www.unaerp.br/",
  },
  {
    country: "BR",
    name: "Universidade de Santa Cruz do Sul",
    url: "http://www.unisc.br/",
  },
  {
    country: "BR",
    name: "Universidade de Santo Amaro",
    url: "http://www.unisa.br/",
  },
  {
    country: "BR",
    name: "Universidade de São Paulo",
    url: "http://www.usp.br/",
  },
  {
    country: "BR",
    name: "Universidade de Taubaté",
    url: "http://www.unitau.br/",
  },
  {
    country: "BR",
    name: "Universidade de Tocantins",
    url: "http://www.pop-to.rnp.br/",
  },
  {
    country: "BR",
    name: "Universidade de Uberaba",
    url: "http://www.uniube.br/",
  },
  {
    country: "BR",
    name: "Universidade de Vila Velha",
    url: "http://www.uvv.br/",
  },
  {
    country: "BR",
    name: "Universidade do Amazonas",
    url: "http://www.fua.br/",
  },
  {
    country: "BR",
    name: "Universidade do Estado da Bahia",
    url: "http://www.uneb.br/",
  },
  {
    country: "BR",
    name: "Universidade do Estado de Santa Catarina",
    url: "http://www.udesc.br/",
  },
  {
    country: "BR",
    name: "Universidade do Estado do Rio de Janeiro",
    url: "http://www.uerj.br/",
  },
  {
    country: "BR",
    name: "Universidade do Estado do Rio Grande do Norte",
    url: "http://www.uern.br/",
  },
  {
    country: "BR",
    name: "Universidade do Extremo Sul Catarinense",
    url: "http://www.unesc.rct-sc.br/",
  },
  {
    country: "BR",
    name: "Universidade do Grande Rio",
    url: "http://www.unigranrio.br/",
  },
  {
    country: "BR",
    name: "Universidade do Oeste de Santa Catarina",
    url: "http://www.unoesc.edu.br/",
  },
  {
    country: "BR",
    name: "Universidade do Oeste Paulista",
    url: "http://www.unoeste.br/",
  },
  {
    country: "BR",
    name: "Universidade do Rio de Janeiro",
    url: "http://www.unirio.br/",
  },
  {
    country: "BR",
    name: "Universidade do Rio Grande",
    url: "http://www.furg.br/",
  },
  {
    country: "BR",
    name: "Universidade do Sagrado Coração",
    url: "http://www.usc.br/",
  },
  {
    country: "BR",
    name: "Universidade do Sul de Santa Catarina",
    url: "http://www.unisul.br/",
  },
  {
    country: "BR",
    name: "Universidade do Vale do Itajaí",
    url: "http://www.univali.rct-sc.br/",
  },
  {
    country: "BR",
    name: "Universidade Estácio de Sá",
    url: "http://www.estacio.br/",
  },
  {
    country: "BR",
    name: "Universidade Estadual da Paraíba",
    url: "http://www.uepb.edu.br/",
  },
  {
    country: "BR",
    name: "Universidade Estadual de Campinas",
    url: "http://www.unicamp.br/",
  },
  {
    country: "BR",
    name: "Universidade Estadual de Feira de Santana",
    url: "http://www.uefs.br/",
  },
  {
    country: "BR",
    name: "Universidade Estadual de Londrina",
    url: "http://www.uel.br/",
  },
  {
    country: "BR",
    name: "Universidade Estadual de Maringá",
    url: "http://www.uem.br/",
  },
  {
    country: "BR",
    name: "Universidade Estadual de Montes Claros",
    url: "http://www.unimontes.br/",
  },
  {
    country: "BR",
    name: "Universidade Estadual de Ponta Grossa",
    url: "http://www.uepg.br/",
  },
  {
    country: "BR",
    name: "Universidade Estadual de Santa Cruz",
    url: "http://www.uesc.br/",
  },
  {
    country: "BR",
    name: "Universidade Estadual do Ceará",
    url: "http://www.uece.br/",
  },
  {
    country: "BR",
    name: "Universidade Estadual do Centro-Oeste",
    url: "http://www.unicentro.br/",
  },
  {
    country: "BR",
    name: "Universidade Estadual do Maranhão",
    url: "http://www.uema.br/",
  },
  {
    country: "BR",
    name: "Universidade Estadual do Norte Fluminense",
    url: "http://www.uenf.br/",
  },
  {
    country: "BR",
    name: "Universidade Estadual do Oeste do Paraná",
    url: "http://www.unioeste.br/",
  },
  {
    country: "BR",
    name: "Universidade Estadual do Piauí",
    url: "http://www.uespi.br/",
  },
  {
    country: "BR",
    name: "Universidade Estadual do Vale do Acaraú",
    url: "http://www.uvanet.br/",
  },
  {
    country: "BR",
    name: "Universidade Estadual Paulista",
    url: "http://www.unesp.br/",
  },
  {
    country: "BR",
    name: "Universidade Estadual Sudoeste da Bahia",
    url: "http://www.uesb.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal da Bahia",
    url: "http://www.ufba.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal da Grande Dourados",
    url: "http://www.ufgd.edu.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal da Paraíba",
    url: "http://www.ufpb.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Alagoas",
    url: "http://www.ufal.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Campina Grande",
    url: "http://www.ufcg.edu.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Goiás",
    url: "http://www.ufg.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Juiz de Fora",
    url: "http://www.ufjf.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Lavras",
    url: "http://www.ufla.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Mato Grosso",
    url: "http://www.ufmt.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Mato Grosso do Sul",
    url: "http://www.ufms.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Minas Gerais",
    url: "http://www.ufmg.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Ouro Preto",
    url: "http://www.ufop.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Pelotas",
    url: "http://www.ufpel.tche.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Pernambuco",
    url: "http://www.ufpe.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Rondônia",
    url: "http://www.unir.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Roraima",
    url: "http://www.technet.com.br/vestibular/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Santa Catarina",
    url: "http://www.ufsc.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Santa Maria",
    url: "http://www.ufsm.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de São Carlos",
    url: "http://www.ufscar.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de São Paulo",
    url: "http://www.epm.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Sergipe",
    url: "http://www.ufs.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Uberlândia",
    url: "http://www.ufu.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal de Viçosa",
    url: "http://www.ufv.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal do ABC",
    url: "http://www.ufabc.edu.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal do Acre",
    url: "http://www.ufac.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal do Amapá",
    url: "http://www.unifap.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal do Ceará",
    url: "http://www.ufc.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal do Espírito Santo",
    url: "http://www.ufes.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal do Maranhão",
    url: "http://www.ufma.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal do Pará",
    url: "http://www.ufpa.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal do Paraná",
    url: "http://www.ufpr.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal do Piauí",
    url: "http://www.ufpi.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal do Rio de Janeiro",
    url: "http://www.ufrj.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal do Rio Grande do Norte",
    url: "http://www.ufrn.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal do Rio Grande do Sul",
    url: "http://www.ufrgs.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal do Vale do São Francisco",
    url: "http://www.univasf.edu.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal Fluminense",
    url: "http://www.uff.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal Rural de Pernambuco",
    url: "http://www.ufrpe.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal Rural do Rio de Janeiro",
    url: "http://www.ufrrj.br/",
  },
  {
    country: "BR",
    name: "Universidade Federal Rural do Semi-Árido",
    url: "http://www.ufersa.edu.br/",
  },
  {
    country: "BR",
    name: "Universidade Gama Filho",
    url: "http://www.ugf.br/",
  },
  {
    country: "BR",
    name: "Universidade Ibirapuera",
    url: "http://www.unib.br/",
  },
  {
    country: "BR",
    name: "Universidade Luterana do Brasil",
    url: "http://www.ulbra.br/",
  },
  {
    country: "BR",
    name: "Universidade Mackenzie",
    url: "http://www.mackenzie.br/",
  },
  {
    country: "BR",
    name: "Universidade Metodista de Piracicaba",
    url: "http://www.unimep.br/",
  },
  {
    country: "BR",
    name: "Universidade Metropolitana de Santos",
    url: "http://www.unimes.com.br/",
  },
  {
    country: "BR",
    name: "Universidade para o Desenvolvimento do Alto Vale do Itajaí",
    url: "http://www.unidavi.rct-sc.br/",
  },
  {
    country: "BR",
    name: "Universidade Paulista",
    url: "http://www.unip-objetivo.br/",
  },
  {
    country: "BR",
    name: "Universidade Potiguar",
    url: "http://www.unp.br/",
  },
  {
    country: "BR",
    name: "Universidade Regional de Blumenau",
    url: "http://www.furb.rct-sc.br/",
  },
  {
    country: "BR",
    name: "Universidade Regional do Cariri",
    url: "http://www.urca.br/",
  },
  {
    country: "BR",
    name: "Universidade Regional do Noroeste do Estado do Rio Grande do Sul",
    url: "http://www.unijui.tche.br/",
  },
  {
    country: "BR",
    name: "Universidade Regional Integrada do Alto Uruguai e das Missões",
    url: "http://www.uri.br/",
  },
  {
    country: "BR",
    name: "Universidade Salgado de Oliveira",
    url: "http://www.universo.br/",
  },
  {
    country: "BR",
    name: "Universidade Salvador",
    url: "http://www.unifacs.br/",
  },
  {
    country: "BR",
    name: "Universidade Santa Cecília dos Bandeirantes",
    url: "http://www.stcecilia.br/",
  },
  {
    country: "BR",
    name: "Universidade Santa Úrsula",
    url: "http://www.usu.br/",
  },
  {
    country: "BR",
    name: "Universidade São Francisco",
    url: "http://www.usf.br/",
  },
  {
    country: "BR",
    name: "Universidade São Judas Tadeu",
    url: "http://www.saojudas.br/",
  },
  {
    country: "BR",
    name: "Universidade São Marcos",
    url: "http://www.smarcos.br/",
  },
  {
    country: "BR",
    name: "Universidade Tiradentes",
    url: "http://www.unit.br/",
  },
  {
    country: "BR",
    name: "Universidade Vale do Paraiba",
    url: "http://www.univap.br/",
  },
  {
    country: "BR",
    name: "Universidade Vale do Rio Doce",
    url: "http://www.univale.br/",
  },
  {
    country: "BR",
    name: "Universidade Vale do Rio dos Sinos",
    url: "http://www.unisinos.br/",
  },
  {
    country: "BR",
    name: "Universidade Veiga de Almeida",
    url: "http://www.uva.br/",
  },
  {
    country: "BS",
    name: "The College of The Bahamas",
    url: "http://www.cob.edu.bs/",
  },
  {
    country: "BT",
    name: "Royal University of Bhutan",
    url: "http://www.rub.edu.bt/",
  },
  {
    country: "BW",
    name: "ABM University College",
    url: "http://www.abm.ac.bw/",
  },
  {
    country: "BW",
    name: "Botho University",
    url: "http://www.bothocollege.ac.bw/",
  },
  {
    country: "BW",
    name: "Botswana Accountancy College",
    url: "http://www.bac.ac.bw/",
  },
  {
    country: "BW",
    name: "Botswana College of Agriculture",
    url: "http://www.bca.bw/",
  },
  {
    country: "BW",
    name: "Botswana International University of Science & Technology",
    url: "http://www.biust.ac.bw/",
  },
  {
    country: "BW",
    name: "Gaborone Universal College of Law",
    url: "http://www.guc.co.bw/",
  },
  {
    country: "BW",
    name: "New Era College of Arts, Science & Technology",
    url: "http://www.neweracollege.ac.bw/",
  },
  {
    country: "BW",
    name: "University of Botswana",
    url: "http://www.ub.bw/",
  },
  {
    country: "BY",
    name: "Academy of Public Administration of Belarus",
    url: "http://www.pac.by/",
  },
  {
    country: "BY",
    name: "Academy of the Ministry of Internal Affairs of the Republic of Belarus",
    url: "http://academy.mia.by/",
  },
  {
    country: "BY",
    name: "Belarusian-Russian University",
    url: "http://www.bru.mogilev.by/",
  },
  {
    country: "BY",
    name: "Belarussian National Technical University",
    url: "http://www.bntu.by/",
  },
  {
    country: "BY",
    name: "Belarussian State Academy of Music",
    url: "http://www.bgam.edu.by/",
  },
  {
    country: "BY",
    name: "Belarussian State Agrarian Technical University",
    url: "http://www.batu.edu.by/",
  },
  {
    country: "BY",
    name: "Belarussian State Agricultural Academy",
    url: "http://www.baa.by/",
  },
  {
    country: "BY",
    name: "Belarussian State Economic University",
    url: "http://www.bseu.by/",
  },
  {
    country: "BY",
    name: "Belarussian State Medical University",
    url: "http://www.bsmu.by/",
  },
  {
    country: "BY",
    name: "Belarussian State Pedagogical University M. Tanka",
    url: "http://www.bspu.unibel.by/",
  },
  {
    country: "BY",
    name: "Belarussian State Polytechnical Academy",
    url: "http://www.bspa.unibel.by/",
  },
  {
    country: "BY",
    name: "Belarussian State Technological University",
    url: "http://www.bstu.unibel.by/",
  },
  {
    country: "BY",
    name: "Belarussian State University",
    url: "http://www.bsu.by/",
  },
  {
    country: "BY",
    name: "Belarussian State University of Culture and Arts",
    url: "http://www.buk.by/",
  },
  {
    country: "BY",
    name: "Belarussian State University of Informatics and Radioelectronics",
    url: "http://www.bsuir.by/",
  },
  {
    country: "BY",
    name: "Belarussian State University of Transport",
    url: "http://www.belsut.gomel.by/",
  },
  {
    country: "BY",
    name: "Brest State Technical University",
    url: "http://www.bstu.by/",
  },
  {
    country: "BY",
    name: "Brest State University",
    url: "http://www.brsu.brest.by/",
  },
  {
    country: "BY",
    name: "European Humanities University",
    url: "http://www.data.minsk.by/ehu/",
  },
  {
    country: "BY",
    name: "Gomel State Medical University",
    url: "http://www.meduniver.gomel.by/",
  },
  {
    country: "BY",
    name: "Gomel State Technical University Pavel Sukhoi",
    url: "http://www.gstu.gomel.by/",
  },
  {
    country: "BY",
    name: "Gomel State University Francisk Scarnia",
    url: "http://www.gsu.unibel.by/",
  },
  {
    country: "BY",
    name: "Grodno State Agrarian University",
    url: "http://www.uni-agro.grodno.by/",
  },
  {
    country: "BY",
    name: "Grodno State Medical University",
    url: "http://www.grsmu.by/",
  },
  {
    country: "BY",
    name: "Grodno State University Yanka Kupaly",
    url: "http://www.grsu.by/",
  },
  {
    country: "BY",
    name: "Institute for Command Engineers of The Ministry for Emergency Situations",
    url: "http://www.kii.gov.by/",
  },
  {
    country: "BY",
    name: "International Sakharov Environmental University",
    url: "http://www.iseu.by/",
  },
  {
    country: "BY",
    name: "Minsk Institute of Management",
    url: "http://www.miu.by/",
  },
  {
    country: "BY",
    name: "Minsk State Linguistic University",
    url: "http://www.mslu.by/",
  },
  {
    country: "BY",
    name: "Mogilev State University A.A. Kuleshov",
    url: "http://msu.mogilev.by/",
  },
  {
    country: "BY",
    name: "Mozyr State Pedagogical Institute",
    url: "http://www.mgpu.gomel.by/",
  },
  {
    country: "BY",
    name: "Polotsk State University",
    url: "http://www.psu.by/",
  },
  {
    country: "BY",
    name: "Vitebsk State Academy of Veterinary Medicine",
    url: "http://www.vsavm.com/",
  },
  {
    country: "BY",
    name: "Vitebsk State Medical University",
    url: "http://www.vgmu.vitebsk.by/",
  },
  {
    country: "BY",
    name: "Vitebsk State Technological University",
    url: "http://www.vstu.vitebsk.by/",
  },
  {
    country: "BY",
    name: "Vitebsk State University",
    url: "http://www.vsu.by/",
  },
  {
    country: "BZ",
    name: "American University of the Caribbean, School of Medicine",
    url: "http://www.aucmed.edu/",
  },
  {
    country: "BZ",
    name: "Central America Health Sciences University (Belize Medical College)",
    url: "http://www.cahsu.edu/",
  },
  {
    country: "BZ",
    name: "Greenford International University",
    url: "http://www.giuedu.bz/",
  },
  {
    country: "BZ",
    name: "University of Belize",
    url: "http://www.ub.edu.bz/",
  },
  {
    country: "BZ",
    name: "West Coast University (WCU)",
    url: "http://www.westcoastuniversity.bz/",
  },
  {
    country: "CA",
    name: "Acadia University",
    url: "http://www.acadiau.ca/",
  },
  {
    country: "CA",
    name: "Algonquin College",
    url: "http://www.algonquincollege.com/",
  },
  {
    country: "CA",
    name: "Ashton College",
    url: "http://www.ashtoncollege.com/",
  },
  {
    country: "CA",
    name: "Assumption University",
    url: "http://www.assumptionu.ca/",
  },
  {
    country: "CA",
    name: "Athabasca University",
    url: "http://www.athabascau.ca/",
  },
  {
    country: "CA",
    name: "Augustana University College",
    url: "http://www.augustana.ab.ca/",
  },
  {
    country: "CA",
    name: "Bishop's University",
    url: "http://www.ubishops.ca/",
  },
  {
    country: "CA",
    name: "Bow Valley College",
    url: "http://www.bowvalleycollege.ca/",
  },
  {
    country: "CA",
    name: "Brandon University",
    url: "http://www.brandonu.ca/",
  },
  {
    country: "CA",
    name: "Brescia College",
    url: "http://www.uwo.ca/brescia/",
  },
  {
    country: "CA",
    name: "British Columbia Institute of Technology",
    url: "http://www.bcit.ca/",
  },
  {
    country: "CA",
    name: "British Columbia Open University",
    url: "http://www.bcou.ca/",
  },
  {
    country: "CA",
    name: "Brock University",
    url: "http://www.brocku.ca/",
  },
  {
    country: "CA",
    name: "Camosun College",
    url: "http://www.camosun.bc.ca/",
  },
  {
    country: "CA",
    name: "Campion College",
    url: "http://www.uregina.ca/campion/",
  },
  {
    country: "CA",
    name: "Canadian College of Business & Computers",
    url: "http://www.ccbc.ca/",
  },
  {
    country: "CA",
    name: "Canadian Mennonite University",
    url: "http://www.cmu.ca/",
  },
  {
    country: "CA",
    name: "Capilano College",
    url: "http://www.capcollege.bc.ca/",
  },
  {
    country: "CA",
    name: "Carleton University",
    url: "http://www.carleton.ca/",
  },
  {
    country: "CA",
    name: "Centennial College",
    url: "http://www.centennialcollege.ca/",
  },
  {
    country: "CA",
    name: "Collège Boréal",
    url: "http://www.borealc.on.ca/",
  },
  {
    country: "CA",
    name: "College of New Caledonia",
    url: "http://www.cnc.bc.ca/",
  },
  {
    country: "CA",
    name: "College of the Rockies",
    url: "http://www.cotr.bc.ca/",
  },
  {
    country: "CA",
    name: "Columbia College",
    url: "http://www.columbiacollege.ca/",
  },
  {
    country: "CA",
    name: "Concordia University",
    url: "http://www.concordia.ca/",
  },
  {
    country: "CA",
    name: "Concordia University College of Alberta",
    url: "http://www.concordia.ab.ca/",
  },
  {
    country: "CA",
    name: "Conestoga College",
    url: "http://www.conestogac.on.ca/",
  },
  {
    country: "CA",
    name: "Dalhousie University",
    url: "http://www.dal.ca/",
  },
  {
    country: "CA",
    name: "DeVry Institute of Technology",
    url: "http://www.cal.devry.ca/",
  },
  {
    country: "CA",
    name: "Dominican College of Philosophy and Theology",
    url: "http://www.op.org/canada/college.htm",
  },
  {
    country: "CA",
    name: "Douglas College",
    url: "http://www.douglas.bc.ca/",
  },
  {
    country: "CA",
    name: "Durham College",
    url: "http://www.durhamc.on.ca/",
  },
  {
    country: "CA",
    name: "École des Hautes Études Commerciales",
    url: "http://www.hec.ca/",
  },
  {
    country: "CA",
    name: "École de technologie supérieure, Université du Québec",
    url: "http://www.etsmtl.ca/",
  },
  {
    country: "CA",
    name: "École nationale d'administration publique, Université du Québec",
    url: "http://www.enap.uquebec.ca/",
  },
  {
    country: "CA",
    name: "École Polytechnique de Montréal, Université de Montréal",
    url: "http://www.polymtl.ca/",
  },
  {
    country: "CA",
    name: "Emily Carr Institute of Art + Design",
    url: "http://www.eciad.bc.ca/",
  },
  {
    country: "CA",
    name: "First Nations University of Canada",
    url: "http://www.firstnationsuniversity.ca/",
  },
  {
    country: "CA",
    name: "George Brown College",
    url: "http://www.gbrownc.on.ca/",
  },
  {
    country: "CA",
    name: "Humber College",
    url: "http://www.humber.ca/",
  },
  {
    country: "CA",
    name: "Huron University College",
    url: "http://www.huronuc.on.ca/",
  },
  {
    country: "CA",
    name: "Institut Armand-Frappier, Université du Québec",
    url: "http://www.iaf.inrs.ca/",
  },
  {
    country: "CA",
    name: "Institut National de la Recherche Scientifique, Université du Québec",
    url: "http://www.inrs.uquebec.ca/",
  },
  {
    country: "CA",
    name: "King's College",
    url: "http://www.uwo.ca/kings/",
  },
  {
    country: "CA",
    name: "Kingston College",
    url: "http://www.kingston.edu/",
  },
  {
    country: "CA",
    name: "King's University College",
    url: "http://www.kingsu.ab.ca/",
  },
  {
    country: "CA",
    name: "Kwantleen University College",
    url: "http://www.kwantlen.bc.ca/",
  },
  {
    country: "CA",
    name: "Lakehead University",
    url: "http://www.lakeheadu.ca/",
  },
  {
    country: "CA",
    name: "Langara College",
    url: "http://www.langara.bc.ca/",
  },
  {
    country: "CA",
    name: "Lansbridge University",
    url: "http://www.lansbridge.com/",
  },
  {
    country: "CA",
    name: "Laurentian University of Sudbury",
    url: "http://www.laurentian.ca/",
  },
  {
    country: "CA",
    name: "Luther College",
    url: "http://www.saskweb.com/luthercollege/",
  },
  {
    country: "CA",
    name: "MacEwan University",
    url: "http://www.macewan.ca/",
  },
  {
    country: "CA",
    name: "Malaspina University College",
    url: "http://www.mala.bc.ca/",
  },
  {
    country: "CA",
    name: "McGill University",
    url: "http://www.mcgill.ca/",
  },
  {
    country: "CA",
    name: "McMaster University",
    url: "http://www.mcmaster.ca/",
  },
  {
    country: "CA",
    name: "Memorial University of Newfoundland",
    url: "http://www.ucs.mun.ca/",
  },
  {
    country: "CA",
    name: "Mount Allison University",
    url: "http://www.mta.ca/",
  },
  {
    country: "CA",
    name: "Mount Royal College",
    url: "http://www.mtroyal.ab.ca/",
  },
  {
    country: "CA",
    name: "Mount Saint Vincent University",
    url: "http://www.msvu.ca/",
  },
  {
    country: "CA",
    name: "Nicola Valley Institute of Technology",
    url: "http://www.nvit.bc.ca/",
  },
  {
    country: "CA",
    name: "Nipissing University",
    url: "http://www.nipissingu.ca/",
  },
  {
    country: "CA",
    name: "Northern Alberta Institute of Technology",
    url: "http://www.nait.ca/",
  },
  {
    country: "CA",
    name: "Northern Lights College",
    url: "http://www.nlc.bc.ca/",
  },
  {
    country: "CA",
    name: "North Island College",
    url: "http://www.nic.bc.ca/",
  },
  {
    country: "CA",
    name: "Nova Scotia Agricultural College",
    url: "http://www.nsac.ns.ca/",
  },
  {
    country: "CA",
    name: "Nova Scotia College of Art and Design",
    url: "http://www.nscad.ns.ca/",
  },
  {
    country: "CA",
    name: "Okanagan University College",
    url: "http://www.okanagan.bc.ca/",
  },
  {
    country: "CA",
    name: "Ontario College of Art and Design",
    url: "http://www.ocad.ca/",
  },
  {
    country: "CA",
    name: "Pacific International College",
    url: "http://www.picollege.ca/",
  },
  {
    country: "CA",
    name: "Queen's University",
    url: "http://www.queensu.ca/",
  },
  {
    country: "CA",
    name: "Quest University",
    url: "http://www.questu.ca/",
  },
  {
    country: "CA",
    name: "Redeemer College",
    url: "http://www.redeemer.ca/",
  },
  {
    country: "CA",
    name: "Regent College",
    url: "http://www.regent-college.edu/",
  },
  {
    country: "CA",
    name: "Royal Military College of Canada",
    url: "http://www.rmc.ca/",
  },
  {
    country: "CA",
    name: "Royal Roads University",
    url: "http://www.royalroads.ca/",
  },
  {
    country: "CA",
    name: "Ryerson Polytechnic University",
    url: "http://www.ryerson.ca/",
  },
  {
    country: "CA",
    name: "Saskatchewan Indian Federated College",
    url: "http://www.sifc.edu/",
  },
  {
    country: "CA",
    name: "Sauder School of Business",
    url: "http://www.sauder.ubc.ca/",
  },
  {
    country: "CA",
    name: "Selkirk College",
    url: "http://www.selkirk.bc.ca/",
  },
  {
    country: "CA",
    name: "Seneca College",
    url: "http://www.senecac.on.ca/",
  },
  {
    country: "CA",
    name: "Sheridan College",
    url: "http://www.sheridanc.on.ca/",
  },
  {
    country: "CA",
    name: "Simon Fraser University",
    url: "http://www.sfu.ca/",
  },
  {
    country: "CA",
    name: "Southern Alberta Institute of Technology",
    url: "http://www.sait.ca/",
  },
  {
    country: "CA",
    name: "St. Anne University",
    url: "http://www.ustanne.ednet.ns.ca/",
  },
  {
    country: "CA",
    name: "St. Clair College",
    url: "http://www.stclairc.on.ca/",
  },
  {
    country: "CA",
    name: "St. Francis Xavier University",
    url: "http://www.stfx.ca/",
  },
  {
    country: "CA",
    name: "St. Mary's University",
    url: "http://www.stmarys.ca/",
  },
  {
    country: "CA",
    name: "St. Paul University",
    url: "http://www.ustpaul.ca/",
  },
  {
    country: "CA",
    name: "St. Thomas University",
    url: "http://www.stthomasu.ca/",
  },
  {
    country: "CA",
    name: "Télé-université, Université du Québec",
    url: "http://www.teluq.uquebec.ca/",
  },
  {
    country: "CA",
    name: "Thompson Rivers University",
    url: "http://www.tru.ca/",
  },
  {
    country: "CA",
    name: "Trent University",
    url: "http://www.trentu.ca/",
  },
  {
    country: "CA",
    name: "Trinity Western University",
    url: "http://www.twu.ca/",
  },
  {
    country: "CA",
    name: "Université de Montréal",
    url: "http://www.umontreal.ca/",
  },
  {
    country: "CA",
    name: "Université de Sherbrooke",
    url: "http://www.usherb.ca/",
  },
  {
    country: "CA",
    name: "Université du Québec à Chicoutimi",
    url: "http://www.uqac.ca/",
  },
  {
    country: "CA",
    name: "Université du Québec à Montréal",
    url: "http://www.uqam.ca/",
  },
  {
    country: "CA",
    name: "Université du Québec à Rimouski",
    url: "http://www.uqar.uquebec.ca/",
  },
  {
    country: "CA",
    name: "Université du Québec à Trois-Rivières",
    url: "http://www.uqtr.uquebec.ca/",
  },
  {
    country: "CA",
    name: "Université du Québec en Abitibi-Témiscamingue",
    url: "http://www.uqat.uquebec.ca/",
  },
  {
    country: "CA",
    name: "Université du Québec en Outaouais",
    url: "http://www.uqo.ca/",
  },
  {
    country: "CA",
    name: "Université Laval",
    url: "http://www.ulaval.ca/",
  },
  {
    country: "CA",
    name: "University Canada West",
    url: "http://www.universitycanadawest.ca/",
  },
  {
    country: "CA",
    name: "University College of Cape Breton",
    url: "http://www.uccb.ns.ca/",
  },
  {
    country: "CA",
    name: "University College of Saint-Boniface",
    url: "http://www.ustboniface.mb.ca/",
  },
  {
    country: "CA",
    name: "University College of the Cariboo",
    url: "http://www.cariboo.bc.ca/",
  },
  {
    country: "CA",
    name: "University College of the Fraser Valley",
    url: "http://www.ucfv.bc.ca/",
  },
  {
    country: "CA",
    name: "University of Alberta",
    url: "http://www.ualberta.ca/",
  },
  {
    country: "CA",
    name: "University of British Columbia",
    url: "http://www.ubc.ca/",
  },
  {
    country: "CA",
    name: "University of Calgary",
    url: "http://www.ucalgary.ca/",
  },
  {
    country: "CA",
    name: "University of Guelph",
    url: "http://www.uoguelph.ca/",
  },
  {
    country: "CA",
    name: "University of King's College",
    url: "http://www.ukings.ns.ca/",
  },
  {
    country: "CA",
    name: "University of Lethbridge",
    url: "http://www.uleth.ca/",
  },
  {
    country: "CA",
    name: "University of Manitoba",
    url: "http://www.umanitoba.ca/",
  },
  {
    country: "CA",
    name: "University of Moncton",
    url: "http://www.umoncton.ca/",
  },
  {
    country: "CA",
    name: "University of Moncton, Edmundston",
    url: "http://www.cuslm.ca/",
  },
  {
    country: "CA",
    name: "University of Moncton, Shippagan",
    url: "http://www.cus.ca/",
  },
  {
    country: "CA",
    name: "University of New Brunswick",
    url: "http://www.unb.ca/",
  },
  {
    country: "CA",
    name: "University of New Brunswick, Saint John",
    url: "http://www.unbsj.ca/",
  },
  {
    country: "CA",
    name: "University of Northern British Columbia",
    url: "http://www.unbc.ca/",
  },
  {
    country: "CA",
    name: "University of Ontario Institute of Technology",
    url: "http://www.uoit.ca/",
  },
  {
    country: "CA",
    name: "University of Ottawa",
    url: "http://www.uottawa.ca/",
  },
  {
    country: "CA",
    name: "University of Prince Edward Island",
    url: "http://www.upei.ca/",
  },
  {
    country: "CA",
    name: "University of Québec",
    url: "http://www.uquebec.ca/",
  },
  {
    country: "CA",
    name: "University of Regina",
    url: "http://www.uregina.ca/",
  },
  {
    country: "CA",
    name: "University of Saskatchewan",
    url: "http://www.usask.ca/",
  },
  {
    country: "CA",
    name: "University of St. Jerome's College",
    url: "http://www.usjc.uwaterloo.ca/",
  },
  {
    country: "CA",
    name: "University of St. Michael's College",
    url: "http://www.utoronto.ca/stmikes/",
  },
  {
    country: "CA",
    name: "University of Sudbury",
    url: "http://www.usudbury.com/",
  },
  {
    country: "CA",
    name: "University of Toronto",
    url: "http://www.utoronto.ca/",
  },
  {
    country: "CA",
    name: "University of Toronto, Mississauga",
    url: "http://www.utm.utoronto.ca/",
  },
  {
    country: "CA",
    name: "University of Toronto, Scarborough",
    url: "http://www.scar.utoronto.ca/",
  },
  {
    country: "CA",
    name: "University of Trinity College",
    url: "http://www.trinity.utoronto.ca/",
  },
  {
    country: "CA",
    name: "University of Victoria",
    url: "http://www.uvic.ca/",
  },
  {
    country: "CA",
    name: "University of Waterloo",
    url: "http://www.uwaterloo.ca/",
  },
  {
    country: "CA",
    name: "University of Western Ontario",
    url: "http://www.uwo.ca/",
  },
  {
    country: "CA",
    name: "University of Windsor",
    url: "http://www.uwindsor.ca/",
  },
  {
    country: "CA",
    name: "University of Winnipeg",
    url: "http://www.uwinnipeg.ca/",
  },
  {
    country: "CA",
    name: "Vancouver Community College",
    url: "http://www.vcc.ca/",
  },
  {
    country: "CA",
    name: "Victoria University Toronto, University of Toronto",
    url: "http://vicu.utoronto.ca/",
  },
  {
    country: "CA",
    name: "Wilfrid Laurier University",
    url: "http://www.wlu.ca/",
  },
  {
    country: "CA",
    name: "William and Catherine Booth College",
    url: "http://www.boothcollege.ca/",
  },
  {
    country: "CA",
    name: "York University",
    url: "http://www.yorku.ca/",
  },
  {
    country: "CA",
    name: "Yukon College",
    url: "http://www.yukoncollege.yk.ca/",
  },
  {
    country: "CD",
    name: "Université Catholique de Bukavu",
    url: "http://www.ucbukavu.ac.cd/",
  },
  {
    country: "CD",
    name: "Université Chrétienne Bilingue du Congo",
    url: "http://www.ucbc.org/",
  },
  {
    country: "CD",
    name: "Université de Bandundu Ville",
    url: "http://www.uniband.org/",
  },
  {
    country: "CD",
    name: "Université de Kamina",
    url: "http://www.ukamina.org/",
  },
  {
    country: "CD",
    name: "Université de Kikwite",
    url: "http://www.unikik.net/",
  },
  {
    country: "CD",
    name: "Université de Kinshasa",
    url: "http://www.unikin.cd/",
  },
  {
    country: "CD",
    name: "Université de Kisangani",
    url: "http://www.unikis.ac.cd/",
  },
  {
    country: "CD",
    name: "Université de Kolwezi",
    url: "http://www.unikol.ac.cd/",
  },
  {
    country: "CD",
    name: "Université de Lubumbashi",
    url: "http://www.unilu.ac.cd/",
  },
  {
    country: "CD",
    name: "Université de l'Uélé",
    url: "http://www.uniuele.ac.cd/",
  },
  {
    country: "CD",
    name: "Université Kongo",
    url: "http://www.universitekongo.org/",
  },
  {
    country: "CD",
    name: "Université Protestante au Congo",
    url: "http://www.upc.ac.cd/",
  },
  {
    country: "CD",
    name: "Université Shalom de Bunia",
    url: "http://www.unishabunia.org/",
  },
  {
    country: "CF",
    name: "Université de Bangui",
    url: "http://www.univ-bangui.net/",
  },
  {
    country: "CG",
    name: "University Marien Ngouabi Brazzaville",
    url: "http://www.univ-mngb.net/",
  },
  {
    country: "CH",
    name: "Business and Hotel Management School",
    url: "http://www.bhms.ch/",
  },
  {
    country: "CH",
    name: "Business School Lausanne (BSL)",
    url: "http://www.bsl-lausanne.ch/",
  },
  {
    country: "CH",
    name: "Cubidor University Switzerland",
    url: "http://www.cubidor.com/",
  },
  {
    country: "CH",
    name: "DCT International Hotel & Business Management School",
    url: "http://www.dct.ch/",
  },
  {
    country: "CH",
    name: "European Graduate School, Media & Communications",
    url: "http://www.egs.edu/",
  },
  {
    country: "CH",
    name: "European University",
    url: "http://www.euruni.edu/",
  },
  {
    country: "CH",
    name: "Fachhochschule Pur",
    url: "http://www.phw.info/",
  },
  {
    country: "CH",
    name: "Fachhochschule St. Gallen",
    url: "https://www.fhsg.ch/",
  },
  {
    country: "CH",
    name: "Franklin College Switzerland",
    url: "http://www.fc.edu/",
  },
  {
    country: "CH",
    name: "Geneva Business School",
    url: "http://www.gbs-ge.ch/",
  },
  {
    country: "CH",
    name: "Glion Institute of Higher Education",
    url: "http://www.glion.edu/",
  },
  {
    country: "CH",
    name: "Graduate School of Business Administration Zurich (GSBA Zurich)",
    url: "http://www.gsba.ch/",
  },
  {
    country: "CH",
    name: "Hochschule für Gestaltung und Kunst Zürich",
    url: "http://www.hgkz.ch/",
  },
  {
    country: "CH",
    name: "International Culinary Institute (ICI)",
    url: "http://www.ici-luzern.com/",
  },
  {
    country: "CH",
    name: "International Hotel Management Institute",
    url: "http://www.imi-luzern.com/",
  },
  {
    country: "CH",
    name: "International School of Business Management",
    url: "http://www.isbm-school.com/",
  },
  {
    country: "CH",
    name: "International University in Geneva",
    url: "http://www.iun.ch/",
  },
  {
    country: "CH",
    name: "Lausanne Hotel School (EHL)",
    url: "http://www.ehl.ch/",
  },
  {
    country: "CH",
    name: "Les Roches Gruyère University of Applied Sciences",
    url: "http://www.lrguas.ch/",
  },
  {
    country: "CH",
    name: "Les Roches International School of Hotel Management",
    url: "http://www.lesroches.edu/",
  },
  {
    country: "CH",
    name: "Libera Università degli Studi di Scienze Umane e Tecnologiche",
    url: "http://www.uniludes.ch/",
  },
  {
    country: "CH",
    name: "Lucerne University of Applied Sciences and Arts",
    url: "http://www.hslu.ch/",
  },
  {
    country: "CH",
    name: "NTB Interstate University of Applied Sciences of Technology",
    url: "http://www.ntb.ch/",
  },
  {
    country: "CH",
    name: "Pädagogische Hochschule Zürich (Zurich School of Education)",
    url: "http://www.phzh.ch/",
  },
  {
    country: "CH",
    name: "Schiller International University, American College of Switzerland",
    url: "http://www.american-college.com/",
  },
  {
    country: "CH",
    name: "School of Management Fribourg",
    url: "http://www.heg-fr.ch/",
  },
  {
    country: "CH",
    name: "Swiss Business School Zurich (SBS)",
    url: "http://www.sbs.edu/",
  },
  {
    country: "CH",
    name: "Swiss Federal Institute of Technology, Lausanne",
    url: "http://www.epfl.ch/",
  },
  {
    country: "CH",
    name: "Swiss Federal Institute of Technology, Zurich",
    url: "http://www.ethz.ch/",
  },
  {
    country: "CH",
    name: "Swiss Management Center",
    url: "http://www.swissmc.ch/",
  },
  {
    country: "CH",
    name: 'University Center "César Ritz',
    url: "http://www.uccr.edu/",
  },
  {
    country: "CH",
    name: "University of Applied Sciences Aargau",
    url: "http://www.fh-aargau.ch/",
  },
  {
    country: "CH",
    name: "University of Applied Sciences Basel (FHBB )",
    url: "http://www.fhbb.ch/",
  },
  {
    country: "CH",
    name: "University of Applied Sciences Chur",
    url: "http://www.fh-htachur.ch/",
  },
  {
    country: "CH",
    name: "University of Applied Sciences Rapperswil",
    url: "http://www.hsr.ch/",
  },
  {
    country: "CH",
    name: "University of Applied Sciences Solothurn Northwestern Switzerland",
    url: "http://www.fhso.ch/",
  },
  {
    country: "CH",
    name: "University of Basel",
    url: "http://www.unibas.ch/",
  },
  {
    country: "CH",
    name: "University of Berne",
    url: "http://www.unibe.ch/",
  },
  {
    country: "CH",
    name: "University of Fribourg",
    url: "http://www.unifr.ch/",
  },
  {
    country: "CH",
    name: "University of Geneva",
    url: "http://www.unige.ch/",
  },
  {
    country: "CH",
    name: "University of Lausanne",
    url: "http://www.unil.ch/",
  },
  {
    country: "CH",
    name: "University of Lucerne",
    url: "http://www.unilu.ch/",
  },
  {
    country: "CH",
    name: "University of Neuchatel",
    url: "http://www.unine.ch/",
  },
  {
    country: "CH",
    name: "University of St. Gallen",
    url: "http://www.unisg.ch/",
  },
  {
    country: "CH",
    name: "University of Switzerland",
    url: "http://www.swissu.org/",
  },
  {
    country: "CH",
    name: "University of the Italian-speaking Part of Switzerland",
    url: "http://www.unisi.ch/",
  },
  {
    country: "CH",
    name: "University of Zürich",
    url: "http://www.unizh.ch/",
  },
  {
    country: "CH",
    name: "Victoria University",
    url: "http://www.victoria-uni.ch/",
  },
  {
    country: "CH",
    name: "Webster University Geneva",
    url: "http://www.webster.ch/",
  },
  {
    country: "CH",
    name: "Zurich University of Applied Sciences Winterthur",
    url: "http://www.zhwin.ch/",
  },
  {
    country: "CI",
    name: "Université d'Abobo-Adjamé",
    url: "http://www.uabobo.ci/",
  },
  {
    country: "CI",
    name: "Université de Bouaké",
    url: "http://www.ubouake.ci/",
  },
  {
    country: "CI",
    name: "Université de Cocody",
    url: "http://www.univ-cocody.ci/",
  },
  {
    country: "CL",
    name: "Escuela de Arquitectura y Diseño",
    url: "http://www.arquitecturaucv.cl/",
  },
  {
    country: "CL",
    name: "Pontificia Universidad Catolica de Chile",
    url: "http://www.puc.cl/",
  },
  {
    country: "CL",
    name: "Universidad Academia de Humanismo Cristiano",
    url: "http://www.academia.cl/",
  },
  {
    country: "CL",
    name: "Universidad Adolfo Ibáñez",
    url: "http://www.uai.cl/",
  },
  {
    country: "CL",
    name: "Universidad Adventista de Chile",
    url: "http://www.unachile.cl/",
  },
  {
    country: "CL",
    name: "Universidad Alberto Hurtado",
    url: "http://www.uahurtado.cl/",
  },
  {
    country: "CL",
    name: "Universidad Arcis",
    url: "http://www.universidadarcis.cl/",
  },
  {
    country: "CL",
    name: 'Universidad "Arturo Prat',
    url: "http://www.unap.cl/",
  },
  {
    country: "CL",
    name: "Universidad Austral de Chile",
    url: "http://www.uach.cl/",
  },
  {
    country: "CL",
    name: "Universidad Autonoma del Sur",
    url: "http://www.uas.cl/",
  },
  {
    country: "CL",
    name: "Universidad Bernardo O'Higgins",
    url: "http://www.ubohiggins.cl/",
  },
  {
    country: "CL",
    name: "Universidad Bolivariana",
    url: "http://www.ubolivariana.cl/",
  },
  {
    country: "CL",
    name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    url: "http://www.ucsh.cl/",
  },
  {
    country: "CL",
    name: "Universidad Catolica de La Santísima Concepción",
    url: "http://www.ucsc.cl/",
  },
  {
    country: "CL",
    name: "Universidad Catolica del Maule",
    url: "http://www.ucm.cl/",
  },
  {
    country: "CL",
    name: "Universidad Catolica del Norte",
    url: "http://www.ucn.cl/",
  },
  {
    country: "CL",
    name: "Universidad Catolica de Temuco",
    url: "http://www.uctem.cl/",
  },
  {
    country: "CL",
    name: "Universidad Catolica de Valparaiso",
    url: "http://www.ucv.cl/",
  },
  {
    country: "CL",
    name: "Universidad Central (CL)",
    url: "http://www.ucentral.cl/",
  },
  {
    country: "CL",
    name: "Universidad Chileno Britanica de Cultura",
    url: "http://www.ubritanica.cl/",
  },
  {
    country: "CL",
    name: "Universidad de Aconcagua",
    url: "http://www.uaconcagua.cl/",
  },
  {
    country: "CL",
    name: "Universidad de Antofagasta",
    url: "http://www.uantof.cl/",
  },
  {
    country: "CL",
    name: "Universidad de Artes, Ciencias y Comunicación",
    url: "http://www.uniacc.cl/",
  },
  {
    country: "CL",
    name: "Universidad de Atacama",
    url: "http://www.uda.cl/",
  },
  {
    country: "CL",
    name: "Universidad de Chile",
    url: "http://www.uchile.cl/",
  },
  {
    country: "CL",
    name: "Universidad de Ciencias de la Informatica",
    url: "http://www.ucinf.cl/",
  },
  {
    country: "CL",
    name: "Universidad de Concepcion",
    url: "http://www.udec.cl/",
  },
  {
    country: "CL",
    name: "Universidad de La Frontera",
    url: "http://www.ufro.cl/",
  },
  {
    country: "CL",
    name: "Universidad de Las Américas",
    url: "http://www.uamericas.cl/",
  },
  {
    country: "CL",
    name: "Universidad de La Serena",
    url: "http://www.userena.cl/",
  },
  {
    country: "CL",
    name: "Universidad del Bío-Bío",
    url: "http://www.ubiobio.cl/",
  },
  {
    country: "CL",
    name: "Universidad del Desarrollo",
    url: "http://www.udesarrollo.cl/",
  },
  {
    country: "CL",
    name: "Universidad del Mar",
    url: "http://www.udelmar.cl/",
  },
  {
    country: "CL",
    name: "Universidad de Los Andes",
    url: "http://www.uandes.cl/",
  },
  {
    country: "CL",
    name: "Universidad de Los Lagos",
    url: "http://www.ulagos.cl/",
  },
  {
    country: "CL",
    name: "Universidad del Pacífico",
    url: "http://www.upacifico.cl/",
  },
  {
    country: "CL",
    name: "Universidad de Magallanes",
    url: "http://www.umag.cl/",
  },
  {
    country: "CL",
    name: "Universidad de Playa Ancha de Ciencias de la Educacion",
    url: "http://www.upa.cl/",
  },
  {
    country: "CL",
    name: "Universidad de San Andres (CL)",
    url: "http://www.usanandres.cl/",
  },
  {
    country: "CL",
    name: "Universidad de Santiago de Chile",
    url: "http://www.usach.cl/",
  },
  {
    country: "CL",
    name: "Universidad de Talca",
    url: "http://www.utalca.cl/",
  },
  {
    country: "CL",
    name: "Universidad de Tarapacá",
    url: "http://www.uta.cl/",
  },
  {
    country: "CL",
    name: "Universidad de Valparaiso",
    url: "http://www.uv.cl/",
  },
  {
    country: "CL",
    name: "Universidad de Viña del Mar",
    url: "http://www.uvm.cl/",
  },
  {
    country: "CL",
    name: "Universidad Diego Portales",
    url: "http://www.udp.cl/",
  },
  {
    country: "CL",
    name: "Universidad Finis Terrae",
    url: "http://www.ufinis.cl/",
  },
  {
    country: "CL",
    name: "Universidad Francisco de Aguirre",
    url: "http://www.unfa.cl/",
  },
  {
    country: "CL",
    name: "Universidad Gabriela Mistral",
    url: "http://www.ugm.cl/",
  },
  {
    country: "CL",
    name: "Universidad Iberoamericana de Ciencias y Tecnologia",
    url: "http://www.unicit.cl/",
  },
  {
    country: "CL",
    name: "Universidad International SEK, Santiago",
    url: "http://www.uisek.cl/",
  },
  {
    country: "CL",
    name: "Universidad José Santos Ossa",
    url: "http://www.ujso.cl/",
  },
  {
    country: "CL",
    name: "Universidad La Republica",
    url: "http://www.ulare.cl/",
  },
  {
    country: "CL",
    name: "Universidad Mariano Egaña",
    url: "http://www.ume.cl/",
  },
  {
    country: "CL",
    name: "Universidad Maritima de Chile",
    url: "http://www.umaritima.cl/",
  },
  {
    country: "CL",
    name: "Universidad Mayor",
    url: "http://www.umayor.cl/",
  },
  {
    country: "CL",
    name: "Universidad Metropolitana de Ciencias de la Educación",
    url: "http://www.umce.cl/",
  },
  {
    country: "CL",
    name: "Universidad Miguel de Cervantes",
    url: "http://www.ucervantes.cl/",
  },
  {
    country: "CL",
    name: "Universidad Nacional Andrés Bello",
    url: "http://www.unab.cl/",
  },
  {
    country: "CL",
    name: "Universidad San Sebastian",
    url: "http://www.uss.cl/",
  },
  {
    country: "CL",
    name: "Universidad Santo Tomás",
    url: "http://www.ust.cl/",
  },
  {
    country: "CL",
    name: 'Universidad Técnica "Federico Santa María',
    url: "http://www.utfsm.cl/",
  },
  {
    country: "CL",
    name: "Universidad Tecnológica Metropolitana",
    url: "http://www.utem.cl/",
  },
  {
    country: "CL",
    name: 'Universidad Tecnologica "Vicente Perez Rosales',
    url: "http://www.uvipro.cl/",
  },
  {
    country: "CM",
    name: "Bamenda University of Science & Technology",
    url: "http://www.bamendauniversity.com/",
  },
  {
    country: "CM",
    name: "Fomic Polytechnic",
    url: "http://www.fomicgroup.cm/",
  },
  {
    country: "CM",
    name: "Université de Bamenda",
    url: "http://www.unibda.net/",
  },
  {
    country: "CM",
    name: "Université de Buéa",
    url: "http://www.ubuea.cm/",
  },
  {
    country: "CM",
    name: "Université de Douala",
    url: "http://www.univ-douala.com/",
  },
  {
    country: "CM",
    name: "Université de Dschang",
    url: "http://www.univ-dschang.org/",
  },
  {
    country: "CM",
    name: "Université de Ngaoundéré",
    url: "http://www.univ-ndere.cm/",
  },
  {
    country: "CM",
    name: "Université des Montagnes",
    url: "http://www.udesmontagnes.org/",
  },
  {
    country: "CM",
    name: "Université de Yaoundé I",
    url: "http://www.uy1.uninet.cm/",
  },
  {
    country: "CM",
    name: "Université de Yaoundé II",
    url: "http://www.universite-yde2.org/",
  },
  {
    country: "CN",
    name: "2nd Military Medical University",
    url: "http://www.smmu.edu.cn/",
  },
  {
    country: "CN",
    name: "3rd Military Medical University",
    url: "http://www.tmmu.edu.cn/",
  },
  {
    country: "CN",
    name: "4th Military Medical University",
    url: "http://www.fmmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Anhui Medical University",
    url: "http://www.ahmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Anhui Normal University",
    url: "http://www.ahnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Anhui Technical College of Water Resources and Hydroelectric Power",
    url: "http://www.ahsdxy.ah.edu.cn/",
  },
  {
    country: "CN",
    name: "Anhui University",
    url: "http://www.ahu.edu.cn/",
  },
  {
    country: "CN",
    name: "Anhui University of Finance and Economics",
    url: "http://www.aufe.edu.cn/",
  },
  {
    country: "CN",
    name: "Anhui University of Traditional Chinese Medicine",
    url: "http://www.ahtcm.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing Foreign Studies University",
    url: "http://www.bfsu.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing Forestry University",
    url: "http://www.bjfu.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing Information Science and Technology University",
    url: "http://www.biti.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing Institute of Technology",
    url: "http://www.bit.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing International Studies University",
    url: "http://www.bisu.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing Language and Culture University",
    url: "http://www.blcu.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing Medical University",
    url: "http://www.bjmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing New Asia University",
    url: "http://www.newasia.bj.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing Normal University",
    url: "http://www.bnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing Petroleum University",
    url: "http://www.bjpeu.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing Polytechnic University",
    url: "http://www.bjpu.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing Sport University",
    url: "http://www.bsu.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing Union University",
    url: "http://www.ipdbuu.com.cn/",
  },
  {
    country: "CN",
    name: "Beijing University of Aeronautics and Astronautics",
    url: "http://www.buaa.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing University of Agriculture",
    url: "http://www.bac.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing University of Chemical Technology",
    url: "http://www.buct.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing University of Chinese Medicine and Pharmacology",
    url: "http://www.bjucmp.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing University of Posts and Telecommunications",
    url: "http://www.bupt.edu.cn/",
  },
  {
    country: "CN",
    name: "Beijing University of Science and Technology",
    url: "http://www.ustb.edu.cn/",
  },
  {
    country: "CN",
    name: "Bohai University",
    url: "http://www.bhu.edu.cn/",
  },
  {
    country: "CN",
    name: "Buddhist Acamedy of China",
    url: "http://www.zgfxy.cn/",
  },
  {
    country: "CN",
    name: "Capital Normal University",
    url: "http://www.cnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Capital University of Economics and Business",
    url: "http://www.cueb.edu.cn/",
  },
  {
    country: "CN",
    name: "Capital University of Medical Sciences",
    url: "http://www.cpums.edu.cn/",
  },
  {
    country: "CN",
    name: "Central Academy of  Fine Art",
    url: "http://www.cafa.edu.cn/",
  },
  {
    country: "CN",
    name: "Central China Normal University",
    url: "http://www.ccnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Central Radio and TV University",
    url: "http://www.crtvu.edu.cn/",
  },
  {
    country: "CN",
    name: "Central South Forestry University",
    url: "http://www.csfu.edu.cn/",
  },
  {
    country: "CN",
    name: "Central South University",
    url: "http://www.csu.edu.cn/",
  },
  {
    country: "CN",
    name: "Central University for Nationalities",
    url: "http://www.cun.edu.cn/",
  },
  {
    country: "CN",
    name: "Central University of Finance and Economics",
    url: "http://www.cufe.edu.cn/",
  },
  {
    country: "CN",
    name: "Changchun Teachers College",
    url: "http://www.cncnc.edu.cn/",
  },
  {
    country: "CN",
    name: "Changchun University of Technology",
    url: "http://www.ccut.edu.cn/",
  },
  {
    country: "CN",
    name: "Changsha Railway University",
    url: "http://www.csru.edu.cn/",
  },
  {
    country: "CN",
    name: "Changsha University of Electric Power",
    url: "http://www.csuep.edu.cn/",
  },
  {
    country: "CN",
    name: "Chengdu Institute of Sichuan International Studies University",
    url: "http://www.cisisu.edu.cn/",
  },
  {
    country: "CN",
    name: "Chengdu University",
    url: "http://www.cdu.edu.cn/",
  },
  {
    country: "CN",
    name: "Chengdu University of Technology",
    url: "http://www.cdut.edu.cn/",
  },
  {
    country: "CN",
    name: "Chengdu University of Traditional Chinese Medicine",
    url: "http://www.cdutcm.edu.cn/",
  },
  {
    country: "CN",
    name: "China Academy of Art",
    url: "http://www.chinaacademyofart.com/",
  },
  {
    country: "CN",
    name: "China Agricultural University",
    url: "http://www.cau.edu.cn/",
  },
  {
    country: "CN",
    name: "China Agriculture University East",
    url: "http://www.bjaeu.edu.cn/",
  },
  {
    country: "CN",
    name: "China Foreign Affairs University",
    url: "http://www.cfau.edu.cn/",
  },
  {
    country: "CN",
    name: "China Medical University Shenyang",
    url: "http://www.cmu.edu.cn/",
  },
  {
    country: "CN",
    name: "China Pharmaceutical University Nanjing",
    url: "http://www.cpu.edu.cn/",
  },
  {
    country: "CN",
    name: "China Three Gorges University",
    url: "http://www.ctgu.edu.cn/",
  },
  {
    country: "CN",
    name: "China University of Geoscience Beijing",
    url: "http://www.cugb.edu.cn/",
  },
  {
    country: "CN",
    name: "China University of Geosciences Wuhan",
    url: "http://www.cug.edu.cn/",
  },
  {
    country: "CN",
    name: "China University of Mining Technology - Beijing",
    url: "http://www.cumtb.edu.cn/",
  },
  {
    country: "CN",
    name: "China University of Mining Technology - Xuzhou",
    url: "http://www.cumt.edu.cn/",
  },
  {
    country: "CN",
    name: "China University of Political Science and Law",
    url: "http://www.cupl.edu.cn/",
  },
  {
    country: "CN",
    name: "China USA Business University",
    url: "http://www.cubu.edu/",
  },
  {
    country: "CN",
    name: "China youth college for political science",
    url: "http://www.cyc.edu.cn/",
  },
  {
    country: "CN",
    name: "Chinese People's Public Security University",
    url: "http://www.cppsu.edu.cn/",
  },
  {
    country: "CN",
    name: "Chongqing Education College",
    url: "http://www.cqec.net.cn/",
  },
  {
    country: "CN",
    name: "Chongqing Medical University",
    url: "http://www.cqmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Chongqing Normal University",
    url: "http://www.cqnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Chongqing Normal University Foreign Trade and Business College",
    url: "http://www.swsm.cn/",
  },
  {
    country: "CN",
    name: "Chongqing Technology and Business University",
    url: "http://www.ctbu.edu.cn/",
  },
  {
    country: "CN",
    name: "Chongqing Telecommunication College",
    url: "http://www.cqtcedu.com/",
  },
  {
    country: "CN",
    name: "Chongqing Three Gorges University",
    url: "http://www.sanxiau.edu.cn/",
  },
  {
    country: "CN",
    name: "Chongqing University",
    url: "http://www.cqu.edu.cn/",
  },
  {
    country: "CN",
    name: "Chongqing University of Communications",
    url: "http://www.cquc.edu.cn/",
  },
  {
    country: "CN",
    name: "Chongqing University of Post and Telecommunications",
    url: "http://www.cqupt.edu.cn/",
  },
  {
    country: "CN",
    name: "Chongqing University of Science and Technology",
    url: "http://www.cqust.cn/",
  },
  {
    country: "CN",
    name: "Chongqing University of Technology",
    url: "http://www.cqut.edu.cn/",
  },
  {
    country: "CN",
    name: "Chongqing Vocational College of Public Transportation",
    url: "http://www.cqgyzy.com/",
  },
  {
    country: "CN",
    name: "Chongqing Wenli University",
    url: "http://www.cqwu.net/",
  },
  {
    country: "CN",
    name: "Civil Aviation University of China",
    url: "http://www.cauc.edu/",
  },
  {
    country: "CN",
    name: "Communication University of China",
    url: "http://www.cuc.edu.cn/",
  },
  {
    country: "CN",
    name: "Dalian Martime University",
    url: "http://www.dlmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Dalian Medical University",
    url: "http://www.dlmedu.edu.cn/",
  },
  {
    country: "CN",
    name: "Dalian Polytechnic University",
    url: "http://www.dlpu.edu.cn/",
  },
  {
    country: "CN",
    name: "Dalian University",
    url: "http://www.dlu.edu.cn/",
  },
  {
    country: "CN",
    name: "Dalian University of Foreign Language",
    url: "http://www.dlufl.edu.cn/",
  },
  {
    country: "CN",
    name: "Dalian University of Technology",
    url: "http://www.dlut.edu.cn/",
  },
  {
    country: "CN",
    name: "Dongbei University of Finance And Economics",
    url: "http://www.dufe.edu.cn/",
  },
  {
    country: "CN",
    name: "Donghua University, Shanghai",
    url: "http://www.dhu.edu.cn/",
  },
  {
    country: "CN",
    name: "East China Jiao Tong University",
    url: "http://www.ecjtu.jx.cn/",
  },
  {
    country: "CN",
    name: "East China Normal University",
    url: "http://www.ecnu.edu.cn/",
  },
  {
    country: "CN",
    name: "East China University of Science and Technology",
    url: "http://www.ecust.edu.cn/",
  },
  {
    country: "CN",
    name: "Emeishan Buddhist College",
    url: "http://www.emsfj.com/",
  },
  {
    country: "CN",
    name: "Foshan University",
    url: "http://www.fosu.edu.cn/",
  },
  {
    country: "CN",
    name: "Fudan University",
    url: "http://www.fudan.edu.cn/",
  },
  {
    country: "CN",
    name: "Fujian Agricultural University",
    url: "http://www.fjau.edu.cn/",
  },
  {
    country: "CN",
    name: "Fujian Medical University",
    url: "http://www.fjmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Fujian Normal University",
    url: "http://www.fjnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Fujian University of Traditional Chinese Medicine",
    url: "http://www.fjtcm.edu.cn/",
  },
  {
    country: "CN",
    name: "Fushun Petroleum University",
    url: "http://www.fspu.edu.cn/",
  },
  {
    country: "CN",
    name: "Fuzhou University",
    url: "http://www.fzu.edu.cn/",
  },
  {
    country: "CN",
    name: "Gansu Agricultural University",
    url: "http://www.gsau.edu.cn/",
  },
  {
    country: "CN",
    name: "Gansu University of Technology",
    url: "http://www.gsut.edu.cn/",
  },
  {
    country: "CN",
    name: "Guangdong Peizheng College",
    url: "http://www.peizheng.com.cn/",
  },
  {
    country: "CN",
    name: "Guangdong Polytechnic Normal University",
    url: "http://www.gdin.edu.cn/",
  },
  {
    country: "CN",
    name: "Guangdong Radio & TV University",
    url: "http://www.gdrtvu.edu.cn/",
  },
  {
    country: "CN",
    name: "Guangdong University of Foreign Studies",
    url: "http://www.gdufs.edu.cn/",
  },
  {
    country: "CN",
    name: "Guangdong University of Technology",
    url: "http://www.gdut.edu.cn/",
  },
  {
    country: "CN",
    name: "Guangxi Medical University",
    url: "http://www.gxmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Guangxi Normal University",
    url: "http://www.gxnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Guangxi Traditional Chinese Medical University",
    url: "http://www.gxtcmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Guangxi University",
    url: "http://www.gxu.edu.cn/",
  },
  {
    country: "CN",
    name: "Guangxi University for Nationalities",
    url: "http://www.gxun.edu.cn/",
  },
  {
    country: "CN",
    name: "Guangzhou Academy of Fine Art",
    url: "http://www.gzarts.edu.cn/",
  },
  {
    country: "CN",
    name: "Guangzhou Normal University",
    url: "http://www.guangztc.edu.cn/",
  },
  {
    country: "CN",
    name: "Guangzhou University",
    url: "http://www.gzhu.edu.cn/",
  },
  {
    country: "CN",
    name: "Guangzhou University of Traditional Chinese Medicine",
    url: "http://www.gzhtcm.edu.cn/",
  },
  {
    country: "CN",
    name: "Guizhou Normal University",
    url: "http://www.gznu.edu.cn/",
  },
  {
    country: "CN",
    name: "Guizhou University",
    url: "http://www.gzu.edu.cn/",
  },
  {
    country: "CN",
    name: "Hainan Normal University",
    url: "http://www.hainnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Hainan University",
    url: "http://www.hainu.edu.cn/",
  },
  {
    country: "CN",
    name: "Harbin Engineering University",
    url: "http://www.hrbeu.edu.cn/",
  },
  {
    country: "CN",
    name: "Harbin Institute of Technology",
    url: "http://www.hit.edu.cn/",
  },
  {
    country: "CN",
    name: "Harbin Medical University",
    url: "http://www.hrbmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Harbin Normal University",
    url: "http://www.hrbnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Harbin University of Civil Engineering & Architecture",
    url: "http://www.hrbucea.edu.cn/",
  },
  {
    country: "CN",
    name: "Harbin University of Science and Technology",
    url: "http://www.hrbust.edu.cn/",
  },
  {
    country: "CN",
    name: "Hebei Academy of Fine Art",
    url: "http://www.hbafa.com/",
  },
  {
    country: "CN",
    name: "Hebei Agricultural University",
    url: "http://www.hebau.edu.cn/",
  },
  {
    country: "CN",
    name: "Hebei Medical University",
    url: "http://www.hebmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Hebei Normal University",
    url: "http://www.hebnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Hebei United University",
    url: "http://www.hebeiuniteduniversity.com/",
  },
  {
    country: "CN",
    name: "Hebei University",
    url: "http://www.hbu.edu.cn/",
  },
  {
    country: "CN",
    name: "Hebei University of Economics and Trade",
    url: "http://www.heuet.edu.cn/",
  },
  {
    country: "CN",
    name: "Hebei University of Science and Technology",
    url: "http://www.hebust.edu.cn/",
  },
  {
    country: "CN",
    name: "Hebei University of Technology",
    url: "http://www.hebut.edu.cn/",
  },
  {
    country: "CN",
    name: "Hefei University of Technology",
    url: "http://www.hfut.edu.cn/",
  },
  {
    country: "CN",
    name: "Hehai University",
    url: "http://www.hhu.edu.cn/",
  },
  {
    country: "CN",
    name: "Heilongjiang August 1st Reclamation University",
    url: "http://www.hlaflru.edu.cn/",
  },
  {
    country: "CN",
    name: "Heilongjiang Commercial University",
    url: "http://www.hljcu.edu.cn/",
  },
  {
    country: "CN",
    name: "Heilongjiang University",
    url: "http://www.hlju.edu.cn/",
  },
  {
    country: "CN",
    name: "Henan Agriculture University",
    url: "http://www.henau.edu.cn/",
  },
  {
    country: "CN",
    name: "Henan Buddhist College",
    url: "http://www.hnfjxy.cn/",
  },
  {
    country: "CN",
    name: "Henan Normal University",
    url: "http://www.henannu.edu.cn/",
  },
  {
    country: "CN",
    name: "Henan Univeristy",
    url: "http://www.henu.edu.cn/",
  },
  {
    country: "CN",
    name: "Hohai University Changzhou",
    url: "http://www.hhuc.edu.cn/",
  },
  {
    country: "CN",
    name: "Huaihai Institute of Technology",
    url: "http://www.hhit.edu.cn/",
  },
  {
    country: "CN",
    name: "Huaihua Medical College",
    url: "http://www.yizhuan.com/",
  },
  {
    country: "CN",
    name: "Huaihua Radio and Television University",
    url: "http://www.hhrtu.com/",
  },
  {
    country: "CN",
    name: "Huaihua University",
    url: "http://www.hhu.edu.cn/",
  },
  {
    country: "CN",
    name: "Huanghe Science & Technology University",
    url: "http://www.hhstu.edu.cn/",
  },
  {
    country: "CN",
    name: "Huaqiao University Quanzhuo",
    url: "http://www.hqu.edu.cn/",
  },
  {
    country: "CN",
    name: "Huazhong Agricultural University",
    url: "http://www.hzau.edu.cn/",
  },
  {
    country: "CN",
    name: "Huazhong University of Science and Technology",
    url: "http://www.hust.edu.cn/",
  },
  {
    country: "CN",
    name: "Hubei University",
    url: "http://www.hubu.edu.cn/",
  },
  {
    country: "CN",
    name: "Huizhou University",
    url: "http://www.hzu.edu.cn/",
  },
  {
    country: "CN",
    name: "Hunan Agricultural University",
    url: "http://www.hunau.net/",
  },
  {
    country: "CN",
    name: "Hunan Normal University",
    url: "http://www.hunnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Hunan University",
    url: "http://www.hunu.edu.cn/",
  },
  {
    country: "CN",
    name: "Inner Mongolia Agricultural University",
    url: "http://www.imau.edu.cn/",
  },
  {
    country: "CN",
    name: "Inner Mongolia Normal University",
    url: "http://www.imnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Inner Mongolia Polytechnic University",
    url: "http://www.impu.edu.cn/",
  },
  {
    country: "CN",
    name: "Inner Mongolia University",
    url: "http://www.imu.edu.cn/",
  },
  {
    country: "CN",
    name: "International Business University of Beijing",
    url: "http://www.ibub.edu.cn/",
  },
  {
    country: "CN",
    name: "Jiangsu University of Science and Technology",
    url: "http://www.jsust.edu.cn/",
  },
  {
    country: "CN",
    name: "Jiangxi Agricultural University",
    url: "http://www.jxau.edu.cn/",
  },
  {
    country: "CN",
    name: "Jiangxi Normal University",
    url: "http://www.jxnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Jiangxi University of Finance and Economics",
    url: "http://www.jxufe.edu.cn/",
  },
  {
    country: "CN",
    name: "Jiangxi University of Traditional Chinese Medicine",
    url: "http://www.jutcm.com/",
  },
  {
    country: "CN",
    name: "Jiaying University",
    url: "http://www.jyu.edu.cn/",
  },
  {
    country: "CN",
    name: "Jilin Agricultural University",
    url: "http://www.jlau.edu.cn/",
  },
  {
    country: "CN",
    name: "Jilin University",
    url: "http://www.jlu.edu.cn/",
  },
  {
    country: "CN",
    name: "Jilin University of Technology",
    url: "http://www.jut.edu.cn/",
  },
  {
    country: "CN",
    name: "Jimei University",
    url: "http://www.jmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Jinan University",
    url: "http://www.jnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Jingdezhen China Institute",
    url: "http://www.jci.edu.cn/",
  },
  {
    country: "CN",
    name: "Jishou University",
    url: "http://www.jsu.edu.cn/",
  },
  {
    country: "CN",
    name: "Kunmimg University of Science and Technology",
    url: "http://www.kmust.edu.cn/",
  },
  {
    country: "CN",
    name: "Lanzhou University",
    url: "http://www.lzu.edu.cn/",
  },
  {
    country: "CN",
    name: "Liaocheng Teachers University",
    url: "http://www.lctu.edu.cn/",
  },
  {
    country: "CN",
    name: "Liaoning Normal University",
    url: "http://www.lnnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Liaoning Technical University",
    url: "http://www.lntu.edu.cn/",
  },
  {
    country: "CN",
    name: "Liaoning University",
    url: "http://www.lnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Logistics Engineering University of PLA",
    url: "http://www.hqgc.net/",
  },
  {
    country: "CN",
    name: "Ludong University",
    url: "http://www.ytnc.edu.cn/",
  },
  {
    country: "CN",
    name: "Luxun Academy of Fine Art",
    url: "http://www.lumei.edu.cn/",
  },
  {
    country: "CN",
    name: "Luzhou Medical College",
    url: "http://www.lzmc.edu.cn/",
  },
  {
    country: "CN",
    name: "Nanchang University",
    url: "http://www.ncu.edu.cn/",
  },
  {
    country: "CN",
    name: "Nanjing Agricultural University",
    url: "http://www.njau.edu.cn/",
  },
  {
    country: "CN",
    name: "Nanjing Forestry University",
    url: "http://www.njfu.edu.cn/",
  },
  {
    country: "CN",
    name: "Nanjing Institute of Meteorology",
    url: "http://www.njim.edu.cn/",
  },
  {
    country: "CN",
    name: "Nanjing Medical University",
    url: "http://www.njmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Nanjing Normal University",
    url: "http://www.njnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Nanjing Union Theological Seminary",
    url: "http://www.njuts.cn/",
  },
  {
    country: "CN",
    name: "Nanjing University",
    url: "http://www.nju.edu.cn/",
  },
  {
    country: "CN",
    name: "Nanjing University of Aeronautics and Astronautics",
    url: "http://www.nuaa.edu.cn/",
  },
  {
    country: "CN",
    name: "Nanjing University of Chemical Technology",
    url: "http://www.njuct.edu.cn/",
  },
  {
    country: "CN",
    name: "Nanjing University of Economics",
    url: "http://www.njue.edu.cn/",
  },
  {
    country: "CN",
    name: "Nanjing University of Posts and Telecommunications",
    url: "http://www.njupt.edu.cn/",
  },
  {
    country: "CN",
    name: "Nanjing University of Science and Technology",
    url: "http://www.njust.edu.cn/",
  },
  {
    country: "CN",
    name: "Nanjing University of Traditional Chinese Medicine",
    url: "http://www.njutcm.edu.cn/",
  },
  {
    country: "CN",
    name: "Nankai University",
    url: "http://nku.nankai.edu.cn/",
  },
  {
    country: "CN",
    name: "National University of Defense Technology",
    url: "http://www.nudt.edu.cn/",
  },
  {
    country: "CN",
    name: "Neijiang Teacher University",
    url: "http://www.neijtc.edu.cn/",
  },
  {
    country: "CN",
    name: "Ningbo University",
    url: "http://www.nbu.edu.cn/",
  },
  {
    country: "CN",
    name: "Ningbo University of Technology",
    url: "http://www.nbut.edu.cn/",
  },
  {
    country: "CN",
    name: "Ningxia Medical College",
    url: "http://www.nxmc.edu.cn/",
  },
  {
    country: "CN",
    name: "Ningxia University",
    url: "http://www.nxu.edu.cn/",
  },
  {
    country: "CN",
    name: "North China Electric Power University",
    url: "http://www.ncepubj.edu.cn/",
  },
  {
    country: "CN",
    name: "North China University of Technology",
    url: "http://www.ncut.edu.cn/",
  },
  {
    country: "CN",
    name: "Northeast Agricultural University",
    url: "http://www.neau.edu.cn/",
  },
  {
    country: "CN",
    name: "Northeastern University",
    url: "http://www.neu.edu.cn/",
  },
  {
    country: "CN",
    name: "Northeast Forest University",
    url: "http://www.nefu.edu.cn/",
  },
  {
    country: "CN",
    name: "Northeast Normal University",
    url: "http://www.nenu.edu.cn/",
  },
  {
    country: "CN",
    name: "Northeast University at Qinhuangdao Campus",
    url: "http://www.neuq.edu.cn/",
  },
  {
    country: "CN",
    name: "Northern Jiaotong University",
    url: "http://www.njtu.edu.cn/",
  },
  {
    country: "CN",
    name: "Northern Sichuan Medical College",
    url: "http://www.nsmc.edu.cn/",
  },
  {
    country: "CN",
    name: "Northwest A&F University",
    url: "http://www.nwau.edu.cn/",
  },
  {
    country: "CN",
    name: "Northwest Normal University Lanzhou",
    url: "http://www.nwnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Northwest Polytechnical University Xi'an",
    url: "http://www.nwpu.edu.cn/",
  },
  {
    country: "CN",
    name: "Northwest University Xi'an",
    url: "http://www.nwu.edu.cn/",
  },
  {
    country: "CN",
    name: "Ocean University of China",
    url: "http://www.ouc.edu.cn/",
  },
  {
    country: "CN",
    name: "Panzhihua University",
    url: "http://www.pzhu.edu.cn/",
  },
  {
    country: "CN",
    name: "Peking University",
    url: "http://www.pku.edu.cn/",
  },
  {
    country: "CN",
    name: "Qingdao University",
    url: "http://www.qdu.edu.cn/",
  },
  {
    country: "CN",
    name: "Qingdao University of Science and Technology",
    url: "http://www.qust.edu.cn/",
  },
  {
    country: "CN",
    name: "Qinghai Normal University",
    url: "http://www.qhnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Qinghai Radio & Television University",
    url: "http://www.qhrtvu.edu.cn/",
  },
  {
    country: "CN",
    name: "Qinghai University",
    url: "http://www.qhu.edu.cn/",
  },
  {
    country: "CN",
    name: "Qiongzhou University",
    url: "http://www.qzu.edu.cn/",
  },
  {
    country: "CN",
    name: "Qufu Normal University",
    url: "http://www.qfnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Renmin University of China",
    url: "http://www.ruc.edu.cn/",
  },
  {
    country: "CN",
    name: "Shaanxi Normal University",
    url: "http://www.snnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shandong Agricultural University",
    url: "http://www.sdau.edu.cn/",
  },
  {
    country: "CN",
    name: "Shandong Economic University",
    url: "http://www.sdie.edu.cn/",
  },
  {
    country: "CN",
    name: "Shandong Medical University",
    url: "http://www.sdmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shandong Normal University",
    url: "http://www.sdnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shandong University",
    url: "http://www.sdu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shandong University of Art and Design",
    url: "http://www.sdada.edu.cn/",
  },
  {
    country: "CN",
    name: "Shandong University of Science and Technology",
    url: "http://www.sdust.edu.cn/",
  },
  {
    country: "CN",
    name: "Shandong University of Technology",
    url: "http://www.sdut.edu.cn/",
  },
  {
    country: "CN",
    name: "Shandong University of Triaditional Chinese Medicine",
    url: "http://www.sdutcm.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai Business School",
    url: "http://www.sbs.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai City College",
    url: "http://www.umcollege.com/",
  },
  {
    country: "CN",
    name: "Shanghai Customs College",
    url: "http://shanghai_edu.customs.gov.cn/",
  },
  {
    country: "CN",
    name: "Shanghai Dainji University",
    url: "http://www.sdju.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai Institue of Foreign Trade",
    url: "http://www.shift.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai International Studies University",
    url: "http://www.shisu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai Jiaotong University",
    url: "http://www.sjtu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai Lida Polytechnic Institute",
    url: "http://www.lidapoly.com/",
  },
  {
    country: "CN",
    name: "Shanghai Lixin University of Commerce",
    url: "http://www.lixin.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai Maritime University",
    url: "http://www.shmtu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai Medical University",
    url: "http://www.shmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai Normal University",
    url: "http://www.shtu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai Ocean University",
    url: "http://www.shou.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai Ouhua Vocational Technical College",
    url: "http://www.shouhua.net.cn/",
  },
  {
    country: "CN",
    name: "Shanghai Sanda University",
    url: "http://www.sandau.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai Second Medical University",
    url: "http://www.shsmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai Second Polytechnic University",
    url: "http://www.shspu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai Sipo Polytechnic",
    url: "http://www.sh-sipopolytechnic.com/",
  },
  {
    country: "CN",
    name: "Shanghai Television University",
    url: "http://www.shtvu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai TieDao University",
    url: "http://www.shtdu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai University",
    url: "http://www.shu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai University of Engineering Science",
    url: "http://www.sues.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai University of Finance and Economics",
    url: "http://www.shufe.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai University of Science and Technology",
    url: "http://www.usst.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanghai University of Traditional Chinese Medicine and Pharmacology",
    url: "http://www.shutcm.edu.cn/",
  },
  {
    country: "CN",
    name: "Shantou University",
    url: "http://www.stu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanxi Agricultural University",
    url: "http://www.sxau.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanxi Normal University",
    url: "http://www.snnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shanxi University",
    url: "http://www.sxu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shaoguan University",
    url: "http://www.sgu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shenyang Institute of Chemical Technology",
    url: "http://www.syict.edu.cn/",
  },
  {
    country: "CN",
    name: "Shenyang Jianzhu University",
    url: "http://www.sjzu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shenyang Pharmaceutical University",
    url: "http://www.syphu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shenyang Polytechnic University",
    url: "http://www.sypu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shenyang University",
    url: "http://www.syu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shenzhen University",
    url: "http://www.szu.edu.cn/",
  },
  {
    country: "CN",
    name: "Shihezi University",
    url: "http://www.shzu.edu.cn/",
  },
  {
    country: "CN",
    name: "Sichuan Agricultural University",
    url: "http://www.sicau.edu.cn/",
  },
  {
    country: "CN",
    name: "Sichuan Fine Art Institute",
    url: "http://zsb.scfai.edu.cn/",
  },
  {
    country: "CN",
    name: "Sichuan International Studies University",
    url: "http://www.sisu.edu.cn/",
  },
  {
    country: "CN",
    name: "Sichuan Normal University",
    url: "http://www.sicnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Sichuan University",
    url: "http://www.scu.edu.cn/",
  },
  {
    country: "CN",
    name: "South China Agricultural University",
    url: "http://www.scau.edu.cn/",
  },
  {
    country: "CN",
    name: "South China Construction University",
    url: "http://www.sccu.edu.cn/",
  },
  {
    country: "CN",
    name: "South China Normal University",
    url: "http://www.scnu.edu.cn/",
  },
  {
    country: "CN",
    name: "South China University",
    url: "http://www.usc.edu.cn/",
  },
  {
    country: "CN",
    name: "South China University of Technology",
    url: "http://www.scut.edu.cn/",
  },
  {
    country: "CN",
    name: "Southeast University",
    url: "http://www.seu.edu.cn/",
  },
  {
    country: "CN",
    name: "Southern Medial University",
    url: "http://www.fimmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Southern Yangtze University",
    url: "http://www.sytu.edu.cn/",
  },
  {
    country: "CN",
    name: "South University of Science and Technology of China",
    url: "http://www.sustc.edu.cn/",
  },
  {
    country: "CN",
    name: "Southwest Agricultural University",
    url: "http://www.swau.edu.cn/",
  },
  {
    country: "CN",
    name: "Southwest Forestry University",
    url: "http://www.swfc.edu.cn/",
  },
  {
    country: "CN",
    name: "Southwest Jiaotong University",
    url: "http://www.swjtu.edu.cn/",
  },
  {
    country: "CN",
    name: "Southwest Petroleum University",
    url: "http://www.swpu.edu.cn/",
  },
  {
    country: "CN",
    name: "Southwest University",
    url: "http://www.swnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Southwest University of Finance and Economics",
    url: "http://www.swufe.edu.cn/",
  },
  {
    country: "CN",
    name: "Southwest University of Nationalities",
    url: "http://www.swun.edu.cn/",
  },
  {
    country: "CN",
    name: "Southwest University of Political Science and Law",
    url: "http://www.swupl.edu.cn/",
  },
  {
    country: "CN",
    name: "Southwest University of Science and Technology",
    url: "http://www.swust.edu.cn/",
  },
  {
    country: "CN",
    name: "South West University Yucai College",
    url: "http://www.swuyc.edu.cn/",
  },
  {
    country: "CN",
    name: "Sun Yat-Sen University of Medical Sciences",
    url: "http://www.gzsums.edu.cn/",
  },
  {
    country: "CN",
    name: "Suzhou University",
    url: "http://www.suda.edu.cn/",
  },
  {
    country: "CN",
    name: "Taiyuan University of Technology",
    url: "http://www.tyut.edu.cn/",
  },
  {
    country: "CN",
    name: "Taizhou College",
    url: "http://www.tzc.edu.cn/",
  },
  {
    country: "CN",
    name: "Tarim University",
    url: "http://www.taru.edu.cn/",
  },
  {
    country: "CN",
    name: "The University of Nottingham Ningbo China",
    url: "http://www.nottingham.edu.cn/",
  },
  {
    country: "CN",
    name: "Tianjin Academy of Fine Art",
    url: "http://www.tjarts.edu.cn/",
  },
  {
    country: "CN",
    name: "Tianjin Medical University",
    url: "http://www.tijmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Tianjin Normal University",
    url: "http://www.tjnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Tianjin Open University",
    url: "http://www.tjrtvu.edu.cn/",
  },
  {
    country: "CN",
    name: "Tianjin Polytechnic University",
    url: "http://www.tjpu.edu.cn/",
  },
  {
    country: "CN",
    name: "Tianjin University",
    url: "http://www.tju.edu.cn/",
  },
  {
    country: "CN",
    name: "Tianjin University of Commerce",
    url: "http://www.tjcu.edu.cn/",
  },
  {
    country: "CN",
    name: "Tianjin University of Finance & Economics",
    url: "http://www.tjufe.edu.cn/",
  },
  {
    country: "CN",
    name: "Tianjin University of Technology",
    url: "http://www.tjut.edu.cn/",
  },
  {
    country: "CN",
    name: "Tibet Agricultural and Animal Husbandry College",
    url: "http://www.xza.cn/",
  },
  {
    country: "CN",
    name: "Tibet Tibetan Medical College",
    url: "http://www.ttmc.edu.cn/",
  },
  {
    country: "CN",
    name: "Tibet University",
    url: "http://www.utibet.edu.cn/",
  },
  {
    country: "CN",
    name: "Tibet University of Nationalities",
    url: "http://www.xzmy.edu.cn/",
  },
  {
    country: "CN",
    name: "Tongji Medical University",
    url: "http://www.tjmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Tongji University",
    url: "http://www.tongji.edu.cn/",
  },
  {
    country: "CN",
    name: "Tsinghua University",
    url: "http://www.tsinghua.edu.cn/",
  },
  {
    country: "CN",
    name: "University of Electronic Science and Technology of China",
    url: "http://www.uestc.edu.cn/",
  },
  {
    country: "CN",
    name: "University of Hydraulic Electric Engineering",
    url: "http://www.uheeyc.edu.cn/",
  },
  {
    country: "CN",
    name: "University of International Business and Economics",
    url: "http://www.uibe.edu.cn/",
  },
  {
    country: "CN",
    name: "University of Jinan",
    url: "http://www.ujn.edu.cn/",
  },
  {
    country: "CN",
    name: "University of Petroleum (East China)",
    url: "http://www.hdpu.edu.cn/",
  },
  {
    country: "CN",
    name: "University of Science and Technology Beijing",
    url: "http://www.ustb.edu.cn/",
  },
  {
    country: "CN",
    name: "University of Science and Technology of China",
    url: "http://www.ustc.edu.cn/",
  },
  {
    country: "CN",
    name: "Urumqi Vocational University",
    url: "http://www.uvu.edu.cn/",
  },
  {
    country: "CN",
    name: "Wenzhou Medical College",
    url: "http://www.wzmu.net/",
  },
  {
    country: "CN",
    name: "Wenzhou University",
    url: "http://www.wzu.edu.cn/",
  },
  {
    country: "CN",
    name: "West China University of Medical Sciences",
    url: "http://www.wcums.edu.cn/",
  },
  {
    country: "CN",
    name: "Wudanshan Taoist College",
    url: "http://www.wdsdjxy.com/",
  },
  {
    country: "CN",
    name: "Wuhan Automobile Polytechnical University",
    url: "http://www.whapu.edu.cn/",
  },
  {
    country: "CN",
    name: "Wuhan Technical University of Surveying and Mapping",
    url: "http://www.wtusm.edu.cn/",
  },
  {
    country: "CN",
    name: "Wuhan Transportation University",
    url: "http://www.whtu.edu.cn/",
  },
  {
    country: "CN",
    name: "Wuhan University",
    url: "http://www.whu.edu.cn/",
  },
  {
    country: "CN",
    name: "Wuhan University of Hydraulic and Electric Engineering",
    url: "http://www.wuhee.edu.cn/",
  },
  {
    country: "CN",
    name: "Wuhan University of Technology",
    url: "http://www.whut.edu.cn/",
  },
  {
    country: "CN",
    name: "Wuhan University School of Medicine",
    url: "http://wsm.whu.edu.cn/",
  },
  {
    country: "CN",
    name: "Wuxi University of Light Industry",
    url: "http://www.wxuli.edu.cn/",
  },
  {
    country: "CN",
    name: "Wuyi University",
    url: "http://www.wyu.edu.cn/",
  },
  {
    country: "CN",
    name: "Xiamen University",
    url: "http://www.xmu.edu.cn/",
  },
  {
    country: "CN",
    name: "Xi'an Academy of Fine Art",
    url: "http://www.xafa.edu.cn/",
  },
  {
    country: "CN",
    name: "Xiangtan Normal University",
    url: "http://www.xtnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Xiangtan University",
    url: "http://www.xtu.edu.cn/",
  },
  {
    country: "CN",
    name: "Xi'an Highway University",
    url: "http://www.xahu.edu.cn/",
  },
  {
    country: "CN",
    name: "Xi'an International Studies University",
    url: "http://www.xisu.edu.cn/",
  },
  {
    country: "CN",
    name: "Xi'an Jiaotong-Liverpool University",
    url: "http://www.xjtlu.edu.cn/",
  },
  {
    country: "CN",
    name: "Xi'an Jiaotong University",
    url: "http://www.xjtu.edu.cn/",
  },
  {
    country: "CN",
    name: "Xi'an University of Architecture and Technology",
    url: "http://www.xauat.edu.cn/",
  },
  {
    country: "CN",
    name: "Xi'an University of Electronic Science and Technology",
    url: "http://www.xidian.edu.cn/",
  },
  {
    country: "CN",
    name: "Xi'an University of Technology",
    url: "http://www.xaut.edu.cn/",
  },
  {
    country: "CN",
    name: "Xihua University",
    url: "http://www.xhu.edu.cn/",
  },
  {
    country: "CN",
    name: "Xijiang University",
    url: "http://www.xiju.edu.cn/",
  },
  {
    country: "CN",
    name: "Xinghai Conservatory of Music",
    url: "http://www.xhcom.edu.cn/",
  },
  {
    country: "CN",
    name: "Xinjiang Agriculture University",
    url: "http://www.xjau.edu.cn/",
  },
  {
    country: "CN",
    name: "Xinjiang Normal University",
    url: "http://www.xjnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Xinjiang University",
    url: "http://www.xju.edu.cn/",
  },
  {
    country: "CN",
    name: "Xinjiang University of Finance and Economics",
    url: "http://www.xjufe.edu.cn/",
  },
  {
    country: "CN",
    name: "Xuzhou Normal University",
    url: "http://www.xznu.edu.cn/",
  },
  {
    country: "CN",
    name: "Yan'an University",
    url: "http://www.yau.edu.cn/",
  },
  {
    country: "CN",
    name: "Yanbian University",
    url: "http://www.ybu.edu.cn/",
  },
  {
    country: "CN",
    name: "Yanbian University of Science and Technology",
    url: "http://www.ybust.edu.cn/",
  },
  {
    country: "CN",
    name: "Yangtze Normal University",
    url: "http://www.yznu.cn/",
  },
  {
    country: "CN",
    name: "Yangtze University",
    url: "http://www.yangtzeu.edu.cn/",
  },
  {
    country: "CN",
    name: "Yangzhou University",
    url: "http://www.yzu.edu.cn/",
  },
  {
    country: "CN",
    name: "Yan Shan University",
    url: "http://www.ysu.edu.cn/",
  },
  {
    country: "CN",
    name: "Yantai Education Institute & Yantai Television University",
    url: "http://www.ytet.edu.cn/",
  },
  {
    country: "CN",
    name: "Yantai University",
    url: "http://www.ytu.edu.cn/",
  },
  {
    country: "CN",
    name: "Yunnan Agriculture University",
    url: "http://www.ynau.edu.cn/",
  },
  {
    country: "CN",
    name: "Yunnan Normal University",
    url: "http://www.ynnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Yunnan University",
    url: "http://www.ynu.edu.cn/",
  },
  {
    country: "CN",
    name: "Zhanjiang Ocean University",
    url: "http://www.zjou.edu.cn/",
  },
  {
    country: "CN",
    name: "Zhejiang Forestry University",
    url: "http://www.zjfc.edu.cn/",
  },
  {
    country: "CN",
    name: "Zhejiang Gongshang University",
    url: "http://www.zjgsu.edu.cn/",
  },
  {
    country: "CN",
    name: "Zhejiang Normal University",
    url: "http://www.zjnu.edu.cn/",
  },
  {
    country: "CN",
    name: "Zhejiang Sci-Tech University",
    url: "http://www.zstu.edu.cn/",
  },
  {
    country: "CN",
    name: "Zhejiang University",
    url: "http://www.zju.edu.cn/",
  },
  {
    country: "CN",
    name: "Zhejiang University of Technology",
    url: "http://www.zjut.edu.cn/",
  },
  {
    country: "CN",
    name: "Zhengda Software College",
    url: "http://www.zdsoft.com.cn/",
  },
  {
    country: "CN",
    name: "Zhengzhou Grain University",
    url: "http://www.zzgc.edu.cn/",
  },
  {
    country: "CN",
    name: "Zhengzhou University of Technology",
    url: "http://www.zzut.edu.cn/",
  },
  {
    country: "CN",
    name: "Zhongnan University of Economics and Law",
    url: "http://www.znuel.net/",
  },
  {
    country: "CN",
    name: "Zhongnan University of Finance and Economics",
    url: "http://www.znufe.edu.cn/",
  },
  {
    country: "CN",
    name: "Zhongnan University of Technology",
    url: "http://www.zhnut.edu.cn/",
  },
  {
    country: "CN",
    name: "Zhongshan University",
    url: "http://www.zsu.edu.cn/",
  },
  {
    country: "CO",
    name: "Centro de Estudios Investigación y Tecnología (CEIT)",
    url: "http://www.iceit.edu.co/",
  },
  {
    country: "CO",
    name: "Colegio de Estudios Superiores de Administración (CESA)",
    url: "http://www.cesa.edu.co/",
  },
  {
    country: "CO",
    name: "Conservatorio del Tolima",
    url: "http://www.bundenet.com/umusical/",
  },
  {
    country: "CO",
    name: 'Corporación Educativa Mayor del Desarrollo "Simón Bolivar',
    url: "http://www.unisimonbolivar.edu.co/",
  },
  {
    country: "CO",
    name: "Corporación Universitaria de Ibagué (CORUNIVERSITARIA)",
    url: "http://www.cui.edu.co/",
  },
  {
    country: "CO",
    name: "Corporación Universitaria de la Costa (UNICOSTA)",
    url: "http://www.cuc.edu.co/",
  },
  {
    country: "CO",
    name: "Corporación Universitaria de Santander (UDES)",
    url: "http://www.udes.edu.co/",
  },
  {
    country: "CO",
    name: "Corporación Universitaria Tecnológica de Bolivar",
    url: "http://www.cutb.edu.co/",
  },
  {
    country: "CO",
    name: "Escuela Colombiana de Carreras Industriales",
    url: "http://www.ecci.edu.co/",
  },
  {
    country: "CO",
    name: "Escuela Colombiana de Ingeniería Julio Garavito",
    url: "http://www.escuelaing.edu.co/",
  },
  {
    country: "CO",
    name: "Escuela de Administración de Negocios",
    url: "http://www.ean.edu.co/",
  },
  {
    country: "CO",
    name: "Escuela de Ingeniería de Antioquia",
    url: "http://www.eia.edu.co/",
  },
  {
    country: "CO",
    name: 'Escuela de Policia "General Santander',
    url: "http://www.escueladepolicia.edu.co/",
  },
  {
    country: "CO",
    name: "Escuela Superiore de Administración Pública",
    url: "http://www.esap.edu.co/",
  },
  {
    country: "CO",
    name: "Fundacion Escuela Colombiana de Rehabiliación",
    url: "http://www.ecr.edu.co/",
  },
  {
    country: "CO",
    name: "Fundación Universitaria de Boyacá",
    url: "http://www.uniboyaca.edu.co/",
  },
  {
    country: "CO",
    name: "Fundación Universitaria del Area Andina. Sede Pereira",
    url: "http://www.funandi.edu.co/",
  },
  {
    country: "CO",
    name: "Fundación Universitaria Luis Amigó",
    url: "http://www.funlam.edu.co/",
  },
  {
    country: "CO",
    name: "Fundación Universitaria Manuela Beltrán",
    url: "http://www.umb.edu.co/",
  },
  {
    country: "CO",
    name: "Fundación Universitaria San Martín",
    url: "http://www.sanmartin.edu.co/",
  },
  {
    country: "CO",
    name: "Institucion Universitaria de Envigado",
    url: "http://www.iue.edu.co/",
  },
  {
    country: "CO",
    name: "Institución Universitaria Iberoamericana",
    url: "http://www.iberoamericana.edu.co/",
  },
  {
    country: "CO",
    name: "Instituto Tecnológico Metropolitano",
    url: "http://www.itm.edu.co/",
  },
  {
    country: "CO",
    name: "Instituto Universitario de La Paz",
    url: "http://www.unipaz.edu.co/",
  },
  {
    country: "CO",
    name: 'Politécnico Columbiano "Jaime Isaza Cadavid',
    url: "http://www.politecjic.edu.co/",
  },
  {
    country: "CO",
    name: "Politécnico Grancolombiano - Institución Universitaria",
    url: "http://www.poligran.edu.co/",
  },
  {
    country: "CO",
    name: "Pontificia Universidad Javeriana",
    url: "http://www.javeriana.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Adventista de Colombia",
    url: "http://www.unac.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Antonio Nariño",
    url: "http://www.uanarino.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Autónoma de Bucaramanga",
    url: "http://www.unab.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Autónoma de Colombia",
    url: "http://www.fuac.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Autónoma de Las Américas",
    url: "http://www.uam.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Autónoma del Caribe",
    url: "http://www.uac.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Autónoma de Manizales",
    url: "http://www.autonoma.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Autónoma de Occidente",
    url: "http://www.cuao.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Autónoma Latinoamericana",
    url: "http://www.unaula.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Bellas Artes",
    url: "http://www.bellasartes.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Católica de Colombia",
    url: "http://www.ucatolica.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Católica del Norte",
    url: "http://www.ucn.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Católica del Oriente",
    url: "http://www.uco.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Católica de Manizales",
    url: "http://www.ucatolicamz.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Católica Popular del Risaralda",
    url: "http://www.ucpr.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Central",
    url: "http://www.ucentral.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Central del Valle del Cauca",
    url: "http://www.uceva.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad CES",
    url: "http://www.ces.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Cooperativa de Colombia",
    url: "http://www.ucc.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de América Bogotá",
    url: "http://www.uamerica.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de Antioquia",
    url: "http://www.udea.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de Antioquía",
    url: "http://www.udea.edu.co/",
  },
  {
    country: "CO",
    name: 'Universidad de Bogotá "Jorge Tadeo Lozano',
    url: "http://www.utadeo.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de Caldas",
    url: "http://www.ucaldas.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de Cartagena",
    url: "http://www.unicartagena.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de Ciencias Aplicadas y Ambientales (UDCA)",
    url: "http://www.udca.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de Córdoba",
    url: "http://www.unicordoba.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de La Amazonia",
    url: "http://www.uniamazonia.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de La Guajira",
    url: "http://www.uniguajira.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de La Sabana",
    url: "http://www.unisabana.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de La Salle",
    url: "http://www.lasalle.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad del Atlántico",
    url: "http://www.uniatlantico.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad del Cauca",
    url: "http://www.ucauca.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad del Magdalena",
    url: "http://www.unimagdalena.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad del Norte",
    url: "http://www.uninorte.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de Los Andes",
    url: "http://www.uniandes.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de Los Llanos",
    url: "http://www.unillanos.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad del Quindío",
    url: "http://www.uniquindio.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad del Rosario",
    url: "http://www.urosario.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad del Sinú",
    url: "http://www.unisinucartagena.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad del Tolima",
    url: "http://www.ut.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad del Valle del Cauca",
    url: "http://www.univalle.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de Manizales",
    url: "http://www.umanizales.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de Medellín",
    url: "http://www.udem.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de Nariño",
    url: "http://www.udenar.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de Pamplona",
    url: "http://www.unipamplona.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de San Buenaventura",
    url: "http://www.usb.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de San Buenaventura Medellin",
    url: "http://www.usbmed.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad de Sucre",
    url: "http://www.unisucre.edu.co/",
  },
  {
    country: "CO",
    name: 'Universidad Distral "Francisco José de Caldas',
    url: "http://www.udistrital.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad EAFIT",
    url: "http://www.eafit.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad El Bosque",
    url: "http://www.unbosque.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Externado de Colombia",
    url: "http://www.uexternado.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Francisco de Paula Santander",
    url: "http://www.ufps.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad ICESI",
    url: "http://www.icesi.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad INCCA de Colombia",
    url: "http://www.unincca.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Industrial de Santander",
    url: "http://www.uis.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad La Gran Colombia",
    url: "http://www.ugrancolombia.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Libre de Colombia",
    url: "http://www.unilibre.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Mariana",
    url: "http://www.umariana.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Metropolitana",
    url: "http://www.unimetro.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Militar Nueva Granada",
    url: "http://www.umng.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Nacional Abierta y a Distancia",
    url: "http://www.unad.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Nacional de Colombia",
    url: "http://www.unal.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Pedagogica Nacional",
    url: "http://www.pedagogica.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Pedagógica y Tecnológica de Colombia",
    url: "http://www.uptc.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Piloto de Colombia",
    url: "http://www.unipiloto.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Pontificia Bolivariana",
    url: "http://www.upb.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Popular del Cesar",
    url: "http://www.unicesar.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Santiago de Cali",
    url: "http://www.usaca.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Santo Tomás (CO)",
    url: "http://www.usta.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Sergio Arboleda",
    url: "http://www.usergioarboleda.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Surcolombiana",
    url: "http://www.usurcolombia.com/",
  },
  {
    country: "CO",
    name: 'Universidad Tecnológica del Choco "Diego Luis Cordoba',
    url: "http://www.utch.edu.co/",
  },
  {
    country: "CO",
    name: "Universidad Tecnológica de Pereira",
    url: "http://www.utp.edu.co/",
  },
  {
    country: "CR",
    name: "Instituto Tecnológico de Costa Rica",
    url: "http://www.itcr.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad Adventista de Centro América",
    url: "http://www.unadeca.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad Autónoma de Centro América",
    url: "http://www.uaca.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad Autonoma Monterrey",
    url: "http://www.unam.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad Braulio Carrillo",
    url: "http://www.universidadbrauliocarrillo.com/",
  },
  {
    country: "CR",
    name: "Universidad Católica Anselmo Llorente",
    url: "http://www.ucatolica.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad Central",
    url: "http://www.universidadcentral.com/",
  },
  {
    country: "CR",
    name: "Universidad de Cartago Florencio del Castillo",
    url: "http://www.uca.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad de Costa Rica",
    url: "http://www.ucr.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad de Iberoamérica",
    url: "http://www.unibe.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad de La Salle",
    url: "http://www.ulasalle.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad del Turismo",
    url: "http://www.utur.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad de San José",
    url: "http://www.universidadsanjosecr.com/",
  },
  {
    country: "CR",
    name: "Universidad Empresarial",
    url: "http://www.unem.edu/internacional/",
  },
  {
    country: "CR",
    name: "Universidad Estatal a Distancia",
    url: "http://www.uned.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad Fidélitas",
    url: "http://www.ufidelitas.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad Fundepos Alma Mater",
    url: "http://www.fundepos.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad Hispanoamericana",
    url: "http://www.uhispanoamericana.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad Internacional de las Américas",
    url: "http://www.uia.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad Isaac Newton",
    url: "http://www.isaacnewtoncr.com/",
  },
  {
    country: "CR",
    name: "Universidad Latina de Costa Rica",
    url: "http://www.ulatina.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad Latinoamericana de Ciencia y Tecnología",
    url: "http://www.ulacit.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad Libre de Costa Rica",
    url: "http://www.ulicori.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad Metropolitana Castro Carazo",
    url: "http://www.umca.net/",
  },
  {
    country: "CR",
    name: "Universidad Nacional",
    url: "http://www.una.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad para la Cooperación Internacional",
    url: "http://www.uci.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad San Juan de la Cruz",
    url: "http://www.universidadsanjuan.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad San Marcos",
    url: "http://www.usam.ac.cr/",
  },
  {
    country: "CR",
    name: "Universidad Santa Paula",
    url: "http://www.uspsantapaula.com/",
  },
  {
    country: "CR",
    name: "Universidad Veritas",
    url: "http://www.uveritas.ac.cr/",
  },
  {
    country: "CR",
    name: "University for Peace",
    url: "http://www.upeace.org/",
  },
  {
    country: "CU",
    name: 'Instituto Superior Minero Metalúrgico "Dr. Antonio Núñez Jiménez',
    url: "http://www.ismm.edu.cu/",
  },
  {
    country: "CU",
    name: "Instituto Superior Politécnico José Antonio Echeverría",
    url: "http://www.cujae.edu.cu/",
  },
  {
    country: "CU",
    name: "Universidad Central de Las Villas",
    url: "http://www.uclv.edu.cu/",
  },
  {
    country: "CU",
    name: "Universidad de Camagüey",
    url: "http://www.reduc.edu.cu/",
  },
  {
    country: "CU",
    name: "Universidad de Ciego de Avila",
    url: "http://www.unica.cu/",
  },
  {
    country: "CU",
    name: "Universidad de Cienfuegos",
    url: "http://www.ucf.edu.cu/",
  },
  {
    country: "CU",
    name: "Universidad de Granma",
    url: "http://www.udg.co.cu/",
  },
  {
    country: "CU",
    name: "Universidad de Holguín",
    url: "http://www.uho.edu.cu/",
  },
  {
    country: "CU",
    name: "Universidad de La Habana",
    url: "http://www.uh.cu/",
  },
  {
    country: "CU",
    name: "Universidad de Matanzas Camilo Cienfuegos",
    url: "http://www.umcc.cu/",
  },
  {
    country: "CU",
    name: "Universidad de Oriente",
    url: "http://www.uo.edu.cu/",
  },
  {
    country: "CU",
    name: "Universidad de Pinar del Río",
    url: "http://www.upr.edu.cu/",
  },
  {
    country: "CU",
    name: 'Universidad Pedagógica "José Martí", Camagüey',
    url: "http://www.ispcmw.rimed.cu/",
  },
  {
    country: "CV",
    name: "Universidade Jean Piaget de Cabo Verde",
    url: "http://cv.unipiaget.org/",
  },
  {
    country: "CY",
    name: "Americanos College",
    url: "http://www.ac.ac.cy/",
  },
  {
    country: "CY",
    name: "Cyprus College",
    url: "http://www.cycollege.ac.cy/",
  },
  {
    country: "CY",
    name: "Cyprus International Institute of Management (CIIM)",
    url: "http://www.ciim.ac.cy/",
  },
  {
    country: "CY",
    name: "Cyprus International University",
    url: "http://www.ciu.edu.tr/",
  },
  {
    country: "CY",
    name: "Cyprus University of Technology",
    url: "http://www.cut.ac.cy/",
  },
  {
    country: "CY",
    name: "Eastern Mediterranean University",
    url: "http://www.emu.edu.tr/",
  },
  {
    country: "CY",
    name: "European University Cyprus",
    url: "http://www.euc.ac.cy/",
  },
  {
    country: "CY",
    name: "European University of Lefke",
    url: "http://www.lefke.edu.tr/",
  },
  {
    country: "CY",
    name: "Frederick University",
    url: "http://www.frederick.ac.cy/",
  },
  {
    country: "CY",
    name: "Girne American University",
    url: "http://www.gau.edu.tr/",
  },
  {
    country: "CY",
    name: "Intercollege",
    url: "http://www.intercollege.ac.cy/",
  },
  {
    country: "CY",
    name: "Near East University",
    url: "http://www.neu.edu.tr/",
  },
  {
    country: "CY",
    name: "Open University of Cyprus",
    url: "http://www.ouc.ac.cy/",
  },
  {
    country: "CY",
    name: "The CTL Eurocollege",
    url: "http://www.ctleuro.ac.cy/",
  },
  {
    country: "CY",
    name: "University of Cyprus",
    url: "http://www.ucy.ac.cy/",
  },
  {
    country: "CY",
    name: "University of Nicosia",
    url: "http://www.unic.ac.cy/",
  },
  {
    country: "CZ",
    name: "Academy of Performing Arts, Film and TV Fakulty",
    url: "http://www.famu.cz/",
  },
  {
    country: "CZ",
    name: "Anglo-American University",
    url: "http://www.aauni.edu/",
  },
  {
    country: "CZ",
    name: "Brno University of Technology",
    url: "http://www.vutbr.cz/",
  },
  {
    country: "CZ",
    name: "Cevro Institut College",
    url: "http://www.vsci.cz/",
  },
  {
    country: "CZ",
    name: "Charles University Prague",
    url: "http://www.cuni.cz/",
  },
  {
    country: "CZ",
    name: "Czech Technical University of Prague",
    url: "http://www.cvut.cz/",
  },
  {
    country: "CZ",
    name: "Czech University of Agriculture Prague",
    url: "http://www.czu.cz/",
  },
  {
    country: "CZ",
    name: "Masaryk University",
    url: "http://www.muni.cz/",
  },
  {
    country: "CZ",
    name: "Mendel University of Agriculture and Forestry",
    url: "http://www.mendelu.cz/",
  },
  {
    country: "CZ",
    name: "Palacky University",
    url: "http://www.upol.cz/",
  },
  {
    country: "CZ",
    name: "Prague College",
    url: "http://www.praguecollege.cz/",
  },
  {
    country: "CZ",
    name: "Prague Institute of Chemical Technology",
    url: "http://www.vscht.cz/",
  },
  {
    country: "CZ",
    name: "Prague International University",
    url: "http://www.mpu-prague.cz/",
  },
  {
    country: "CZ",
    name: "Silesian University",
    url: "http://www.slu.cz/",
  },
  {
    country: "CZ",
    name: "Technical University of Liberec",
    url: "http://www.vslib.cz/",
  },
  {
    country: "CZ",
    name: "Technical University of Mining and Metallurgy Ostrava",
    url: "http://www.vsb.cz/",
  },
  {
    country: "CZ",
    name: "Tiffin University Prague",
    url: "http://www.tiffinprague.cz/",
  },
  {
    country: "CZ",
    name: "Tomas Bata University in Zlin",
    url: "http://www.utb.cz/",
  },
  {
    country: "CZ",
    name: "University of Economics Prague",
    url: "http://www.vse.cz/",
  },
  {
    country: "CZ",
    name: "University of Education Hradec Kralove",
    url: "http://www.vsp.cz/",
  },
  {
    country: "CZ",
    name: "University of Jan Evangelista Purkyne",
    url: "http://www.ujep.cz/",
  },
  {
    country: "CZ",
    name: "University of New York in Prague",
    url: "http://www.unyp.cz/",
  },
  {
    country: "CZ",
    name: "University of Northern Virginia, Prague Campus",
    url: "http://www.unva.cz/",
  },
  {
    country: "CZ",
    name: "University of Ostrava",
    url: "http://www.osu.cz/",
  },
  {
    country: "CZ",
    name: "University of Pardubice",
    url: "http://www.upce.cz/",
  },
  {
    country: "CZ",
    name: "University of South Bohemia",
    url: "http://www.jcu.cz/",
  },
  {
    country: "CZ",
    name: "University of Veterinary and Pharmaceutical Science",
    url: "http://www.vfu.cz/",
  },
  {
    country: "CZ",
    name: "University of West Bohemia",
    url: "http://www.zcu.cz/",
  },
  {
    country: "DE",
    name: "AKAD Hochschulen für Berufstätige, Fachhochschule Leipzig",
    url: "http://www.akad.de/",
  },
  {
    country: "DE",
    name: "Albert-Ludwigs-Universität Freiburg",
    url: "http://www.uni-freiburg.de/",
  },
  {
    country: "DE",
    name: "Alice-Salomon-Fachhochschule für Sozialarbeit und Sozialpädagogik Berlin",
    url: "http://www.asfh-berlin.de/",
  },
  {
    country: "DE",
    name: "Augustana Hochschule Neuendettelsau",
    url: "http://www.augustana.de/",
  },
  {
    country: "DE",
    name: "Bauhaus Universität Weimar",
    url: "http://www.uni-weimar.de/",
  },
  {
    country: "DE",
    name: "Bayerische Julius-Maximilians-Universität Würzburg",
    url: "http://www.uni-wuerzburg.de/",
  },
  {
    country: "DE",
    name: "Bergische Universität Gesamthochschule Wuppertal",
    url: "http://www.uni-wuppertal.de/",
  },
  {
    country: "DE",
    name: "BiTS - Business and Information Technology School gGmbH",
    url: "http://www.bits-iserlohn.de/",
  },
  {
    country: "DE",
    name: "Brandenburgische Technische Universität Cottbus",
    url: "http://www.tu-cottbus.de/",
  },
  {
    country: "DE",
    name: "Carl von Ossietzky Universität Oldenburg",
    url: "http://www.uni-oldenburg.de/",
  },
  {
    country: "DE",
    name: "Christian-Albrechts-Universität Kiel",
    url: "http://www.uni-kiel.de/",
  },
  {
    country: "DE",
    name: "Cologne Business School",
    url: "http://www.cbs.de/",
  },
  {
    country: "DE",
    name: "Deutsche Hochschule für Verwaltungswissenschaften Speyer",
    url: "http://www.dhv-speyer.de/",
  },
  {
    country: "DE",
    name: "Deutsche Sporthochschule Köln",
    url: "http://www.dshs-koeln.de/",
  },
  {
    country: "DE",
    name: "Deutsche Telekom Fachhochschule Leipzig",
    url: "http://www.fh-telekom-leipzig.de/",
  },
  {
    country: "DE",
    name: "Deutsch-Ordens Fachhochschule Riedlingen, Hochschule für Wirtschaft",
    url: "http://www.fh-riedlingen.de/",
  },
  {
    country: "DE",
    name: "DIPLOMA-Fachhochschule Ölsnitz/Vogtland",
    url: "http://www.diploma.de/",
  },
  {
    country: "DE",
    name: "Duale Hochschule Baden-Württemberg",
    url: "http://www.dhbw.de/",
  },
  {
    country: "DE",
    name: "E.A.P. Europäische Wirtschaftshochschule Berlin",
    url: "http://www.eap.net/",
  },
  {
    country: "DE",
    name: "Eberhard-Karls-Universität Tübingen",
    url: "http://www.uni-tuebingen.de/",
  },
  {
    country: "DE",
    name: "Ernst-Moritz-Arndt Universität Greifswald",
    url: "http://www.uni-greifswald.de/",
  },
  {
    country: "DE",
    name: "Europa Fachhochschule Fresenius",
    url: "http://www.fh-fresenius.de/",
  },
  {
    country: "DE",
    name: "Europäische Betriebswirtschafts-Akademie",
    url: "http://www.eba-muenchen.de/",
  },
  {
    country: "DE",
    name: "Europäische Fachhochschule",
    url: "http://www.eufh.de/",
  },
  {
    country: "DE",
    name: "Europa-Universität Viadrina Frankfurt (Oder)",
    url: "http://www.euv-frankfurt-o.de/",
  },
  {
    country: "DE",
    name: "European Business School Schloß Reichartshausen",
    url: "http://www.ebs.de/",
  },
  {
    country: "DE",
    name: "European College of Liberal Arts",
    url: "http://www.ecla.de/",
  },
  {
    country: "DE",
    name: "European Management School",
    url: "http://www.ems-mainz.de/",
  },
  {
    country: "DE",
    name: "Evangelische Fachhochschule Berlin, Fachhochschule für Sozialarbeit und Sozialpädagogik",
    url: "http://www.evfh-berlin.de/",
  },
  {
    country: "DE",
    name: "Evangelische Fachhochschule Darmstadt",
    url: "http://www.efh-darmstadt.de/",
  },
  {
    country: "DE",
    name: "Evangelische Fachhochschule Freiburg, Hochschule für Soziale Arbeit, Diakonie und Religionspädagogik",
    url: "http://www.efh-freiburg.de/",
  },
  {
    country: "DE",
    name: "Evangelische Fachhochschule für Religionspädagogik, und Gemeindediakonie Moritzburg",
    url: "http://www.fhs-moritzburg.de/",
  },
  {
    country: "DE",
    name: 'Evangelische Fachhochschule für Sozialpädagogik der "Diakonenanstalt des Rauhen Hauses" Hamburg',
    url: "http://www.rauheshaus.de/fachhochschule/",
  },
  {
    country: "DE",
    name: "Evangelische Fachhochschule Hannover",
    url: "http://www.efh-hannover.de/",
  },
  {
    country: "DE",
    name: "Evangelische Fachhochschule Ludwigshafen Hochschule für Sozial- und Gesundheitswesen",
    url: "http://www.efhlu.de/",
  },
  {
    country: "DE",
    name: "Evangelische Fachhochschule Nürnberg",
    url: "http://www.evfh-nuernberg.de/",
  },
  {
    country: "DE",
    name: "Evangelische Fachhochschule Reutlingen-Ludwigsburg, Hochschule für Soziale Arbeit, Religionspädagogik und Diakonie",
    url: "http://www.efh-reutlingen-ludwigsburg.de/",
  },
  {
    country: "DE",
    name: "Evangelische Fachhochschule Rheinland-Westfalen-Lippe",
    url: "http://www.efh-bochum.de/",
  },
  {
    country: "DE",
    name: "Evangelische Hochschule für Soziale Arbeit Dresden (FH)",
    url: "http://www.ehs-dresden.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Aachen",
    url: "http://www.fh-aachen.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Aschaffenburg",
    url: "http://www.fh-aschaffenburg.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Augsburg",
    url: "http://www.fh-augsburg.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Biberach, Hochschule für Bauwesen und Wirtschaft",
    url: "http://www.fh-biberach.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Bielefeld",
    url: "http://www.fh-bielefeld.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Bingen",
    url: "http://www.fh-bingen.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Bochum",
    url: "http://www.fh-bochum.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Bonn-Rhein-Sieg",
    url: "http://www.fh-bonn-rhein-sieg.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Brandenburg",
    url: "http://www.fh-brandenburg.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Braunschweig/Wolfenbüttel",
    url: "http://www.fh-wolfenbuettel.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Deggendorf",
    url: "http://www.fh-deggendorf.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule der Wirtschaft",
    url: "http://www.fhdw.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule des Mittelstandes (FHM)",
    url: "http://www.fhm-mittelstand.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Dortmund",
    url: "http://www.fh-dortmund.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Düsseldorf",
    url: "http://www.fh-duesseldorf.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Eberswalde",
    url: "http://www.fh-eberswalde.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Erfurt",
    url: "http://www.fh-erfurt.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Flensburg",
    url: "http://www.fh-flensburg.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Frankfurt am Main",
    url: "http://www.fh-frankfurt.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule für das öffentliche Bibliothekswesen Bonn",
    url: "http://www.fhoebb.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule für die Wirtschaft",
    url: "http://www.fhdw.bib.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule für Oekonomie und Management (FOM)",
    url: "http://www.fom.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule für Technik und Wirtschaft Berlin",
    url: "http://www.fhtw-berlin.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Furtwangen, Hochschule für Technik und Wirtschaft",
    url: "http://www.fh-furtwangen.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule für Verwaltung und Rechtspflege Berlin",
    url: "http://www.fhvr.berlin.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule für Wirtschaft Berlin",
    url: "http://www.fhw-berlin.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Gelsenkirchen",
    url: "http://www.fh-gelsenkirchen.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Gießen-Friedberg",
    url: "http://www.fh-giessen.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Hamburg",
    url: "http://www.fh-hamburg.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Hannover",
    url: "http://www.fh-hannover.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Heidelberg",
    url: "http://www.fh-heidelberg.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Heilbronn, Hochschule für Technik und Wirtschaft",
    url: "http://www.fh-heilbronn.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Hildesheim/Holzminden/Göttingen, Hochschule für angewandte Wissenschaft und Kunst",
    url: "http://www.fh-hildesheim.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Hof",
    url: "http://www.fh-hof.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Ingolstadt",
    url: "http://www.fh-ingolstadt.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Jena",
    url: "http://www.fh-jena.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Kaiserslautern",
    url: "http://www.fh-kl.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Kempten, Hochschule für Technik und Wirtschaft",
    url: "http://www.fh-Kempten.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Kiel",
    url: "http://www.fh-kiel.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Koblenz",
    url: "http://www.fh-koblenz.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Köln",
    url: "http://www.fh-koeln.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Konstanz, Hochschule für Technik, Wirtschaft und Gestaltung",
    url: "http://www.fh-konstanz.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Landshut, Hochschule für Wirtschaft - Sozialwesen - Technik",
    url: "http://www.fh-landshut.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Lausitz",
    url: "http://www.fh-lausitz.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Lippe",
    url: "http://www.fh-lippe.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Lübeck",
    url: "http://www.fh-luebeck.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Ludwigshafen, Hochschule für Wirtschaft",
    url: "http://www.fh-ludwigshafen.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Mainz",
    url: "http://www.fh-mainz.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Mannheim, Hochschule für Sozialwesen",
    url: "http://www.fhs-mannheim.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Mannheim, Hochschule für Technik und Gestaltung",
    url: "http://www.fh-mannheim.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Merseburg",
    url: "http://www.fh-merseburg.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule München",
    url: "http://www.fh-muenchen.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Münster",
    url: "http://www.fh-muenster.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Neubrandenburg",
    url: "http://www.fh-nb.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Neu-Ulm",
    url: "http://www.fh-neu-ulm.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Niederrhein",
    url: "http://www.fh-niederrhein.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Nordhausen",
    url: "http://www.fh-nordhausen.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Nordhessen",
    url: "http://www.diploma.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Nordostniedersachsen",
    url: "http://www.fhnon.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Nürtingen, Hochschule für Wirtschaft, Landwirtschaft und Landespflege",
    url: "http://www.fh-nuertingen.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Offenburg, Hochschule für Technik und Wirtschaft",
    url: "http://www.fh-offenburg.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Oldenburg/Ostfriesland/Wilhelmshaven",
    url: "http://www.fh-oow.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Osnabrück",
    url: "http://www.fh-osnabrueck.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Pforzheim, Hochschule für Gestaltung, Technik und Wirtschaft",
    url: "http://www.fh-pforzheim.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Potsdam",
    url: "http://www.fh-potsdam.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Ravensburg-Weingarten",
    url: "http://www.fh-weingarten.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Regensburg",
    url: "http://www.fh-regensburg.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Reutlingen, Hochschule für Technik und Wirtschaft",
    url: "http://www.fh-reutlingen.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Rosenheim, Hochschule für Technik und Wirtschaft",
    url: "http://www.fh-rosenheim.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Rottenburg, Hochschule für Forstwirtschaft",
    url: "http://www.fh-rottenburg.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Schmalkalden",
    url: "http://www.fh-schmalkalden.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Schwäbisch Gmünd, Hochschule für Gestaltung",
    url: "http://www.hfg-gmuend.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Schwäbisch Hall, Hochschule für Gestaltung",
    url: "http://www.fh-schwaebischhall.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Stralsund",
    url: "http://www.fh-stralsund.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Stuttgart, Hochschule der Medien",
    url: "http://www.hdm-stuttgart.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Stuttgart, Hochschule für Technik",
    url: "http://www.fht-stuttgart.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Trier, Hochschule für Technik, Wirtschaft und Gestaltung",
    url: "http://www.fh-trier.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Ulm, Hochschule für Technik",
    url: "http://www.fh-ulm.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule und Berufskollegs NTA, Prof.Dr. Grübler gemein. GmbH",
    url: "http://www.fh-isny.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Wedel",
    url: "http://www.fh-wedel.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Weihenstephan",
    url: "http://www.fh-weihenstephan.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Westküste, Hochschule für Wirtschaft und Technik",
    url: "http://www.fh-westkueste.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Wiesbaden",
    url: "http://www.fh-wiesbaden.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Worms",
    url: "http://www.fh-worms.de/",
  },
  {
    country: "DE",
    name: "Fachhochschule Würzburg - Schweinfurt",
    url: "http://www.fh-wuerzburg.de/",
  },
  {
    country: "DE",
    name: "Fern-Fachhochschule Hamburg",
    url: "http://www.fern-fh.de/",
  },
  {
    country: "DE",
    name: "Fernuniversität Gesamthochschule Hagen",
    url: "http://www.fernuni-hagen.de/",
  },
  {
    country: "DE",
    name: "Freie Kunst-Studienstätte Ottersberg",
    url: "http://www.fh-ottersberg.de/",
  },
  {
    country: "DE",
    name: "Freie Universität Berlin",
    url: "http://www.fu-berlin.de/",
  },
  {
    country: "DE",
    name: "Friedrich-Alexander Universität Erlangen-Nürnberg",
    url: "http://www.uni-erlangen.de/",
  },
  {
    country: "DE",
    name: "Friedrich-Schiller Universität Jena",
    url: "http://www.uni-jena.de/",
  },
  {
    country: "DE",
    name: "Georg-August Universität Göttingen",
    url: "http://www.uni-goettingen.de/",
  },
  {
    country: "DE",
    name: "Georg-Simon-Ohm-Fachhochschule Nürnberg",
    url: "http://www.fh-nuernberg.de/",
  },
  {
    country: "DE",
    name: "Gustav-Siewerth-Akademie",
    url: "http://www.siewerth-akademie.de/",
  },
  {
    country: "DE",
    name: "Hafencity Universität Hamburg",
    url: "http://www.hcu-hamburg.de/",
  },
  {
    country: "DE",
    name: "Handelshochschule Leipzig",
    url: "http://www.hhl.de/",
  },
  {
    country: "DE",
    name: "Heinrich-Heine Universität Düsseldorf",
    url: "http://www.uni-duesseldorf.de/",
  },
  {
    country: "DE",
    name: "Hertie School of Governance",
    url: "http://www.hertie-school.org/",
  },
  {
    country: "DE",
    name: "Hochschule Albstadt-Sigmaringen",
    url: "http://www.hs-albsig.de/",
  },
  {
    country: "DE",
    name: "Hochschule Anhalt (FH), Hochschule für angewandte Wissenschaften",
    url: "http://www.hs-anhalt.de/",
  },
  {
    country: "DE",
    name: "Hochschule Bremen",
    url: "http://www.hs-bremen.de/",
  },
  {
    country: "DE",
    name: "Hochschule Bremerhaven",
    url: "http://www.hs-bremerhaven.de/",
  },
  {
    country: "DE",
    name: "Hochschule Coburg",
    url: "http://www.hs-coburg.de/",
  },
  {
    country: "DE",
    name: "Hochschule Darmstadt",
    url: "http://www.hs-darmstadt.de/",
  },
  {
    country: "DE",
    name: "Hochschule Esslingen",
    url: "http://www.hs-esslingen.de/",
  },
  {
    country: "DE",
    name: "Hochschule Fulda",
    url: "http://www.hs-fulda.de/",
  },
  {
    country: "DE",
    name: "Hochschule für Bankwirtschaft (HfB), Private Fachhochschule der Bankakademie",
    url: "http://www.hfb.de/",
  },
  {
    country: "DE",
    name: "Hochschule für Berufstätige Rendsburg",
    url: "http://www.akad.de/",
  },
  {
    country: "DE",
    name: "Hochschule für Internationales Management",
    url: "http://www.himh.de/",
  },
  {
    country: "DE",
    name: "Hochschule für Jüdische Studien Heidelberg",
    url: "http://www.hjs.uni-heidelberg.de/",
  },
  {
    country: "DE",
    name: "Hochschule für Philosophie München",
    url: "http://www.hfph.mwn.de/",
  },
  {
    country: "DE",
    name: "Hochschule für Politik (HFP)",
    url: "http://www.hfp.mhn.de/",
  },
  {
    country: "DE",
    name: "Hochschule für Technik und Wirtschaft des Saarlandes",
    url: "http://www.htw-saarland.de/",
  },
  {
    country: "DE",
    name: "Hochschule für Technik und Wirtschaft Dresden (FH)",
    url: "http://www.htw-dresden.de/",
  },
  {
    country: "DE",
    name: "Hochschule für Technik und Wirtschaft Karlsruhe",
    url: "http://www.hs-karlsruhe.de/",
  },
  {
    country: "DE",
    name: "Hochschule für Technik, Wirtschaft und Kultur Leipzig (FH)",
    url: "http://www.htwk-leipzig.de/",
  },
  {
    country: "DE",
    name: "Hochschule Harz, Hochschule für angewandte Wissenschaften (FH)",
    url: "http://www.hs-harz.de/",
  },
  {
    country: "DE",
    name: "Hochschule Magdeburg-Stendal (FH)",
    url: "http://www.hs-magdeburg.de/",
  },
  {
    country: "DE",
    name: "Hochschule Mittweida (FH)",
    url: "http://www.htwm.de/",
  },
  {
    country: "DE",
    name: "Hochschule Vechta",
    url: "http://www.uni-vechta.de/",
  },
  {
    country: "DE",
    name: "Hochschule Wismar, Fachhochschule für Technik, Wirtschaft und Gestaltung",
    url: "http://www.hs-wismar.de/",
  },
  {
    country: "DE",
    name: "Hochschule Zittau/Görlitz (FH)",
    url: "http://www.hs-zigr.de/",
  },
  {
    country: "DE",
    name: "Humboldt Universität Berlin",
    url: "http://www.hu-berlin.de/",
  },
  {
    country: "DE",
    name: "HWP - Hamburger Universität für Wirtschaft und Politik",
    url: "http://www.hwp-hamburg.de/",
  },
  {
    country: "DE",
    name: "Internationale Fachhochschule Aalen",
    url: "http://www.international.fh-aalen.de/",
  },
  {
    country: "DE",
    name: "Internationale Fachhochschule Bad Honnef",
    url: "http://www.fh-bad-honnef.de/",
  },
  {
    country: "DE",
    name: "Internationales Hochschulinstitut Zittau",
    url: "http://www.ihi-zittau.de/",
  },
  {
    country: "DE",
    name: "International School of Management ISM Dortmund",
    url: "http://www.ism-dortmund.de/",
  },
  {
    country: "DE",
    name: "International School of New Media, University of Lübeck",
    url: "http://www.isnm.de/",
  },
  {
    country: "DE",
    name: "International University in Germany",
    url: "http://www.i-u.de/",
  },
  {
    country: "DE",
    name: "Jacobs University Bremen",
    url: "http://www.jacobs-university.de/",
  },
  {
    country: "DE",
    name: "Johannes-Gutenberg Universität Mainz",
    url: "http://www.uni-mainz.de/",
  },
  {
    country: "DE",
    name: "Johann Wolfgang Goethe Universität Frankfurt am Main",
    url: "http://www.uni-frankfurt.de/",
  },
  {
    country: "DE",
    name: "Justus Liebig Universität Gießen",
    url: "http://www.uni-giessen.de/",
  },
  {
    country: "DE",
    name: "Karlshochschule International University",
    url: "http://www.karlshochschule.de/",
  },
  {
    country: "DE",
    name: "Katholische Fachhochschule Berlin (KFB)",
    url: "http://www.kfb-berlin.de/",
  },
  {
    country: "DE",
    name: "Katholische Fachhochschule Freiburg, Hochschule für Sozialwesen, Religionspädagogik und Pflege",
    url: "http://www.kfh-Freiburg.de/",
  },
  {
    country: "DE",
    name: "Katholische Fachhochschule Mainz",
    url: "http://www.kfh-mainz.de/",
  },
  {
    country: "DE",
    name: "Katholische Fachhochschule Norddeutschland",
    url: "http://www.kath-fh-nord.de/",
  },
  {
    country: "DE",
    name: "Katholische Fachhochschule Nordrhein-Westfalen",
    url: "http://www.kfhnw.de/",
  },
  {
    country: "DE",
    name: "Katholische Hochschule für Soziale Arbeit Saarbrücken",
    url: "http://www.khsa.de/",
  },
  {
    country: "DE",
    name: "Katholische Stiftungsfachhochschule München",
    url: "http://www.ksfh.de/",
  },
  {
    country: "DE",
    name: "Katholische Universität Eichstätt",
    url: "http://www.ku-eichstaett.de/",
  },
  {
    country: "DE",
    name: "Kirchliche Hochschule Bethel",
    url: "http://www.bethel.de/kiho/",
  },
  {
    country: "DE",
    name: "Kirchliche Hochschule Wuppertal",
    url: "http://www.uni-wuppertal.de/inst/kiho/",
  },
  {
    country: "DE",
    name: "Kunstakademie Düsseldorf.",
    url: "http://www.kunstakademie-duesseldorf.de/",
  },
  {
    country: "DE",
    name: "Kunsthochschule Berlin-Weissensee, Hochschule für Gestaltung",
    url: "http://www.kh-berlin.de/",
  },
  {
    country: "DE",
    name: "Ludwig-Maximilians-Universität München",
    url: "http://www.uni-muenchen.de/",
  },
  {
    country: "DE",
    name: "Lutherische Theologische Hochschule Oberursel",
    url: "http://www.selk.de/LThH/",
  },
  {
    country: "DE",
    name: "Märkische Fachhochschule Iserlohn",
    url: "http://www.mfh-iserlohn.de/",
  },
  {
    country: "DE",
    name: "Martin-Luther Universität Halle-Wittenberg",
    url: "http://www.uni-halle.de/",
  },
  {
    country: "DE",
    name: "Medizinische Hochschule Hannover",
    url: "http://www.mh-hannover.de/",
  },
  {
    country: "DE",
    name: "Medizinische Universität Lübeck",
    url: "http://www.mu-luebeck.de/",
  },
  {
    country: "DE",
    name: "Merkur Internationale FH Karlsruhe",
    url: "http://www.merkur-fh.org/",
  },
  {
    country: "DE",
    name: "Merz Akademie, Hochschule für Gestaltung Stuttgart",
    url: "http://www.merz-akademie.de/",
  },
  {
    country: "DE",
    name: "Muthesius-Hochschule, Fachhochschule für Kunst und Gestaltung",
    url: "http://www.muthesius.de/",
  },
  {
    country: "DE",
    name: "Nordakademie, Staatlich anerkannte private Fachhochschule mit dualen Studiengängen",
    url: "http://www.nordakademie.de/",
  },
  {
    country: "DE",
    name: "Northern Institute of Technology Management (NIT)",
    url: "http://www.nithh.de/",
  },
  {
    country: "DE",
    name: "Otto-Friedrich Universität Bamberg",
    url: "http://www.uni-bamberg.de/",
  },
  {
    country: "DE",
    name: "Otto-von-Guericke Universität Magdeburg",
    url: "http://www.uni-magdeburg.de/",
  },
  {
    country: "DE",
    name: "Pädagogische Hochschule Erfurt/Mühlhausen",
    url: "http://www.ph-erfurt.de/",
  },
  {
    country: "DE",
    name: "Pädagogische Hochschule Freiburg",
    url: "http://www.ph-freiburg.de/",
  },
  {
    country: "DE",
    name: "Pädagogische Hochschule Heidelberg",
    url: "http://www.ph-heidelberg.de/",
  },
  {
    country: "DE",
    name: "Pädagogische Hochschule Karlsruhe",
    url: "http://www.ph-karlsruhe.de/",
  },
  {
    country: "DE",
    name: "Pädagogische Hochschule Ludwigsburg",
    url: "http://www.ph-ludwigsburg.de/",
  },
  {
    country: "DE",
    name: "Pädagogische Hochschule Schwäbisch Gmünd",
    url: "http://www.ph-gmuend.de/",
  },
  {
    country: "DE",
    name: "Pädagogische Hochschule Weingarten",
    url: "http://www.ph-weingarten.de/",
  },
  {
    country: "DE",
    name: "Phillips-Universität Marburg",
    url: "http://www.uni-marburg.de/",
  },
  {
    country: "DE",
    name: "Philosophisch-Theologische Hochschule der Salesianer Don Boscos",
    url: "http://www.pth-bb.de/",
  },
  {
    country: "DE",
    name: "Philosophisch-Theologische Hochschule Münster",
    url: "http://www.pth-muenster.de/",
  },
  {
    country: "DE",
    name: "Philosophisch-Theologische Hochschule Sankt Georgen",
    url: "http://www.st-georgen.uni-frankfurt.de/",
  },
  {
    country: "DE",
    name: "Philosophisch-Theologische Hochschule SVD Sankt Augustin",
    url: "http://www.philtheol-augustin.de/",
  },
  {
    country: "DE",
    name: "Philosophisch-Theologische Hochschule Vallendar der Gesellschaft des Katholischen Apostolates (Pallottiner)",
    url: "http://www.pthv.de/",
  },
  {
    country: "DE",
    name: "Philosophisch-Theologisches Studium Erfurt, Staatlich anerkannte Wissenschaftliche Hochschule",
    url: "http://www.uni-erfurt.de/phil-theol/",
  },
  {
    country: "DE",
    name: "Popakademie Baden-Württemberg",
    url: "http://www.popakademie.de/",
  },
  {
    country: "DE",
    name: "Private Fachhochschule für Wirtschaft und Technik Vechta/Diepholz",
    url: "http://www.fhwt.de/",
  },
  {
    country: "DE",
    name: "Private Fachhochschule Göttingen",
    url: "http://www.pfh-goettingen.de/",
  },
  {
    country: "DE",
    name: "Private FernFachhochschule Darmstadt",
    url: "http://www.privatfh-da.de/",
  },
  {
    country: "DE",
    name: "Private Hanseuniversität",
    url: "http://www.hanseuni.de/",
  },
  {
    country: "DE",
    name: "Private Universität Witten/Herdecke",
    url: "http://www.uni-wh.de/",
  },
  {
    country: "DE",
    name: "Rheinische Fachhochschule Köln",
    url: "http://www.rfh-koeln.de/",
  },
  {
    country: "DE",
    name: "Rheinische Friedrich-Wilhelms Universität Bonn",
    url: "http://www.uni-bonn.de/",
  },
  {
    country: "DE",
    name: "Rheinisch Westfälische Technische Hochschule Aachen",
    url: "http://www.rwth-aachen.de/",
  },
  {
    country: "DE",
    name: "Ruhr-Universität Bochum",
    url: "http://www.ruhr-uni-bochum.de/",
  },
  {
    country: "DE",
    name: "Ruprecht-Karls-Universität Heidelberg",
    url: "http://www.uni-heidelberg.de/",
  },
  {
    country: "DE",
    name: "Schiller International University, Heidelberg",
    url: "http://www.siu-heidelberg.de/",
  },
  {
    country: "DE",
    name: "SRH University of Applied Sciences",
    url: "http://www.srh-berlin.de/",
  },
  {
    country: "DE",
    name: "Staatlich anerkannte Fachhochschule für Kunsttherapie",
    url: "http://www.fhkt.de/",
  },
  {
    country: "DE",
    name: "Staatliche Hochschule für Musik",
    url: "http://www.mh-trossingen.de/",
  },
  {
    country: "DE",
    name: "Steinbeis-Hochschule-Berlin",
    url: "http://www.stw.de/shb/",
  },
  {
    country: "DE",
    name: "Technische Fachhochschule Berlin",
    url: "http://www.tfh-berlin.de/",
  },
  {
    country: "DE",
    name: "Technische Fachhochschule Georg Agricola für Rohstoff, Energie und, Umwelt zu Bochum",
    url: "http://www.tfh-bochum.de/",
  },
  {
    country: "DE",
    name: "Technische Fachhochschule Wildau",
    url: "http://www.tfh-wildau.de/",
  },
  {
    country: "DE",
    name: "Technische Universität Bergakademie Freiberg",
    url: "http://www.tu-freiberg.de/",
  },
  {
    country: "DE",
    name: "Technische Universität Berlin",
    url: "http://www.tu-berlin.de/",
  },
  {
    country: "DE",
    name: "Technische Universität Carolo-Wilhelmina Braunschweig",
    url: "http://www.tu-bs.de/",
  },
  {
    country: "DE",
    name: "Technische Universität Chemnitz",
    url: "http://www.tu-chemnitz.de/",
  },
  {
    country: "DE",
    name: "Technische Universität Clausthal",
    url: "http://www.tu-clausthal.de/",
  },
  {
    country: "DE",
    name: "Technische Universität Darmstadt",
    url: "http://www.tu-darmstadt.de/",
  },
  {
    country: "DE",
    name: "Technische Universität Dresden",
    url: "http://www.tu-dresden.de/",
  },
  {
    country: "DE",
    name: "Technische Universität Hamburg-Harburg",
    url: "http://www.tu-harburg.de/",
  },
  {
    country: "DE",
    name: "Technische Universität Ilmenau",
    url: "http://www.tu-ilmenau.de/",
  },
  {
    country: "DE",
    name: "Technische Universität München",
    url: "http://www.tu-muenchen.de/",
  },
  {
    country: "DE",
    name: "Theologische Fakultät Paderborn",
    url: "http://www.paderborn.de/theofak/",
  },
  {
    country: "DE",
    name: "Theologische Fakultät Trier",
    url: "http://www.uni-trier.de/uni/theo/",
  },
  {
    country: "DE",
    name: "Theologische Hochschule Friedensau",
    url: "http://www.thh-friedensau.de/",
  },
  {
    country: "DE",
    name: "Tierärztliche Hochschule Hannover",
    url: "http://www.tiho-hannover.de/",
  },
  {
    country: "DE",
    name: "Universität Augsburg",
    url: "http://www.uni-augsburg.de/",
  },
  {
    country: "DE",
    name: "Universität Bayreuth",
    url: "http://www.uni-bayreuth.de/",
  },
  {
    country: "DE",
    name: "Universität Bielefeld",
    url: "http://www.uni-bielefeld.de/",
  },
  {
    country: "DE",
    name: "Universität Bremen",
    url: "http://www.uni-bremen.de/",
  },
  {
    country: "DE",
    name: "Universität der Bundeswehr Hamburg",
    url: "http://www.unibw-hamburg.de/",
  },
  {
    country: "DE",
    name: "Universität der Bundeswehr München",
    url: "http://www.unibw-muenchen.de/",
  },
  {
    country: "DE",
    name: "Universität des Saarlandes",
    url: "http://www.uni-saarland.de/",
  },
  {
    country: "DE",
    name: "Universität Dortmund",
    url: "http://www.uni-dortmund.de/",
  },
  {
    country: "DE",
    name: "Universität Duisburg-Essen",
    url: "http://www.uni-duisburg-essen.de/",
  },
  {
    country: "DE",
    name: "Universität Erfurt",
    url: "http://www.uni-erfurt.de/",
  },
  {
    country: "DE",
    name: "Universität Flensburg",
    url: "http://www.uni-flensburg.de/",
  },
  {
    country: "DE",
    name: "Universität Fridericana Karlsruhe (Technische Hochschule)",
    url: "http://www.uni-karlsruhe.de/",
  },
  {
    country: "DE",
    name: "Universität Hamburg",
    url: "http://www.uni-hamburg.de/",
  },
  {
    country: "DE",
    name: "Universität Hannover",
    url: "http://www.uni-hannover.de/",
  },
  {
    country: "DE",
    name: "Universität Hildesheim",
    url: "http://www.uni-hildesheim.de/",
  },
  {
    country: "DE",
    name: "Universität Hohenheim",
    url: "http://www.uni-hohenheim.de/",
  },
  {
    country: "DE",
    name: "Universität Kaiserslautern",
    url: "http://www.uni-kl.de/",
  },
  {
    country: "DE",
    name: "Universität Kassel",
    url: "http://www.uni-kassel.de/",
  },
  {
    country: "DE",
    name: "Universität Koblenz-Landau",
    url: "http://www.uni-koblenz-landau.de/",
  },
  {
    country: "DE",
    name: "Universität Köln",
    url: "http://www.uni-koeln.de/",
  },
  {
    country: "DE",
    name: "Universität Konstanz",
    url: "http://www.uni-konstanz.de/",
  },
  {
    country: "DE",
    name: "Universität Leipzig",
    url: "http://www.uni-leipzig.de/",
  },
  {
    country: "DE",
    name: "Universität Lüneburg",
    url: "http://www.uni-lueneburg.de/",
  },
  {
    country: "DE",
    name: "Universität Mannheim",
    url: "http://www.uni-mannheim.de/",
  },
  {
    country: "DE",
    name: "Universität Osnabrück",
    url: "http://www.uni-osnabrueck.de/",
  },
  {
    country: "DE",
    name: "Universität Paderborn",
    url: "http://www.uni-paderborn.de/",
  },
  {
    country: "DE",
    name: "Universität Passau",
    url: "http://www.uni-passau.de/",
  },
  {
    country: "DE",
    name: "Universität Potsdam",
    url: "http://www.uni-potsdam.de/",
  },
  {
    country: "DE",
    name: "Universität Regensburg",
    url: "http://www.uni-regensburg.de/",
  },
  {
    country: "DE",
    name: "Universität Rostock",
    url: "http://www.uni-rostock.de/",
  },
  {
    country: "DE",
    name: "Universität Siegen",
    url: "http://www.uni-siegen.de/",
  },
  {
    country: "DE",
    name: "Universität Stuttgart",
    url: "http://www.uni-stuttgart.de/",
  },
  {
    country: "DE",
    name: "Universität Trier",
    url: "http://www.uni-trier.de/",
  },
  {
    country: "DE",
    name: "Universität Ulm",
    url: "http://www.uni-ulm.de/",
  },
  {
    country: "DE",
    name: "Westfälische Wilhelms-Universität Münster",
    url: "http://www.uni-muenster.de/",
  },
  {
    country: "DE",
    name: "Westsächsische Hochschule Zwickau (FH)",
    url: "http://www.fh-zwickau.de/",
  },
  {
    country: "DE",
    name: "Wissenschaftliche Hochschule für Unternehmensführung, Otto-Beisheim Hochschule",
    url: "http://www.whu-koblenz.de/",
  },
  {
    country: "DJ",
    name: "Université de Djibouti",
    url: "http://www.univ.edu.dj/",
  },
  {
    country: "DK",
    name: "Aalborg Business College",
    url: "http://www.ah.dk/",
  },
  {
    country: "DK",
    name: "Aalborg University",
    url: "http://www.auc.dk/",
  },
  {
    country: "DK",
    name: "Aarhus School of Architecture",
    url: "http://www.a-aarhus.dk/",
  },
  {
    country: "DK",
    name: "Aarhus School of Business",
    url: "http://www.hha.dk/",
  },
  {
    country: "DK",
    name: "Aarhus Technical College",
    url: "http://www.ats.dk/",
  },
  {
    country: "DK",
    name: "Aarhus University",
    url: "http://www.au.dk/",
  },
  {
    country: "DK",
    name: "Copenhagen Business School",
    url: "http://www.cbs.dk/",
  },
  {
    country: "DK",
    name: "Copenhagen University",
    url: "http://www.ku.dk/",
  },
  {
    country: "DK",
    name: "Danish Business Academy",
    url: "http://www.ddea.dk/",
  },
  {
    country: "DK",
    name: "Danish University of Education",
    url: "http://www.dpu.dk/",
  },
  {
    country: "DK",
    name: "Engineering College of Aarhus",
    url: "http://www.iha.dk/",
  },
  {
    country: "DK",
    name: "Engineering College of Copenhagen",
    url: "http://www.ihk.dk/",
  },
  {
    country: "DK",
    name: "International Business School of Scandinavia",
    url: "http://www.ibss.eu/",
  },
  {
    country: "DK",
    name: "International People's College",
    url: "http://www.ipc.dk/",
  },
  {
    country: "DK",
    name: "IT University of Copenhagen",
    url: "http://www.itu.dk/",
  },
  {
    country: "DK",
    name: "Knightsbridge University",
    url: "http://www.knightsbridgeuniversity.com/",
  },
  {
    country: "DK",
    name: "Lutheran School Of Theology In Aarhus",
    url: "http://www.teologi.dk/",
  },
  {
    country: "DK",
    name: "Odense University",
    url: "http://www.ou.dk/",
  },
  {
    country: "DK",
    name: "Roskilde University",
    url: "http://www.ruc.dk/",
  },
  {
    country: "DK",
    name: "Royal Academy of Music",
    url: "http://www.musik-kons.dk/",
  },
  {
    country: "DK",
    name: "Royal Danish Academy of Fine Arts, School of Architecture",
    url: "http://www.karch.dk/",
  },
  {
    country: "DK",
    name: "Royal Danish Academy of Fine Arts, School of Visual Art",
    url: "http://www.kunstakademiet.dk/",
  },
  {
    country: "DK",
    name: "Royal Danish Academy of Music",
    url: "http://www.dkdm.dk/",
  },
  {
    country: "DK",
    name: "Royal Danish School of Educational Sciences",
    url: "http://www.dlh.dk/",
  },
  {
    country: "DK",
    name: "Royal Danish School of Pharmacy",
    url: "http://www.dfh.dk/",
  },
  {
    country: "DK",
    name: "Royal School of Library and Information Science",
    url: "http://www.db.dk/",
  },
  {
    country: "DK",
    name: "Royal Veterinary and Agricultural University",
    url: "http://www.kvl.dk/",
  },
  {
    country: "DK",
    name: "Southern Denmark Business School",
    url: "http://www.hhs.dk/",
  },
  {
    country: "DK",
    name: "Technical University of Denmark",
    url: "http://www.dtu.dk/",
  },
  {
    country: "DK",
    name: "Tietgen Business College",
    url: "http://www.tietgen.dk/",
  },
  {
    country: "DK",
    name: "University of Southern Denmark",
    url: "http://www.sdu.dk/",
  },
  {
    country: "DM",
    name: "Ballsbridge University",
    url: "http://www.acedu.org/",
  },
  {
    country: "DM",
    name: "Dominica State College",
    url: "http://www.dsc.dm/",
  },
  {
    country: "DM",
    name: "New World University",
    url: "http://www.newworld.ac/",
  },
  {
    country: "DM",
    name: "Ross University Caribbean School of Medicine",
    url: "http://www.rossu.edu/medical-school/",
  },
  {
    country: "DO",
    name: "Instituto Tecnológico de Santo Domingo",
    url: "http://www.intec.edu.do/",
  },
  {
    country: "DO",
    name: "Pontificia Universidad Católica Madre y Maestra (PUCMM)",
    url: "http://www.pucmm.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Abierta Para Adultos",
    url: "http://www.uapa.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Adventista Dominicana",
    url: "http://www.unad.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Agroforestal Fernando A.Meriño",
    url: "http://www.uafam.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad APEC",
    url: "http://www.unapec.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Autónoma de Santo Domingo",
    url: "http://www.uasd.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Católica de Santo Domingo",
    url: "http://www.ucsd.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Católica Madre y Maestra",
    url: "http://www.pucmm.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Católica Nordestana",
    url: "http://www.ucne.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Católica Tecnológica del Cibao",
    url: "http://www.ucateci.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Central del Este",
    url: "http://www.uce.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Central Dominicana de Estudio Profesionales",
    url: "http://66.98.64.31/",
  },
  {
    country: "DO",
    name: "Universidad de la Tercera Edad",
    url: "http://www.ute.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad del Caribe",
    url: "http://www.unicaribe.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Dominicana O&M",
    url: "http://www.udoym.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Dominico-Americana",
    url: "http://www.icda.edu.do/espanol/unicda/",
  },
  {
    country: "DO",
    name: "Universidad Eugenio Maria de Hostos",
    url: "http://www.uniremhos.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Experimental Felix Adam",
    url: "http://www.unefa.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Federico Henríquez y Carvajal",
    url: "http://www.ufhec.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Iberoamericana",
    url: "http://www.unibe.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Interamericana",
    url: "http://www.unica.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Nacional Evangélica",
    url: "http://www.unev-rd.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Nacional Pedro Henríquez Ureña",
    url: "http://www.unphu.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Odontológica Dominicana",
    url: "http://www.uod.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Tecnológica del Sur",
    url: "http://www.utesur.edu.do/",
  },
  {
    country: "DO",
    name: "Universidad Tecnológica de Santiago",
    url: "http://www.utesa.edu/",
  },
  {
    country: "DO",
    name: "Universidad Ulises Francisco Espaillat",
    url: "http://www.ufhec.edu.do/",
  },
  {
    country: "DZ",
    name: "Centre Universitaire de Jijel",
    url: "http://www.univ-jijel.dz/",
  },
  {
    country: "DZ",
    name: "Centre Universitaire de Tebessa",
    url: "http://www.univ-tebessa.dz/",
  },
  {
    country: "DZ",
    name: "Centre Universitaire de Tiaret",
    url: "http://www.univ-tiaret.dz/",
  },
  {
    country: "DZ",
    name: "Centre Universitaire d'Oum El Bouaghi",
    url: "http://www.univ-oeb.dz/",
  },
  {
    country: "DZ",
    name: "Université Abou Bekr Belkaid, Tlemcen",
    url: "http://www.univ-tlemcen.dz/",
  },
  {
    country: "DZ",
    name: "Université Amar Telidji",
    url: "http://www.lagh-univ.dz/",
  },
  {
    country: "DZ",
    name: "Université Badji Mokhtar",
    url: "http://www.univ-annaba.dz/",
  },
  {
    country: "DZ",
    name: "Université d'Alger",
    url: "http://www.univ-alger.dz/",
  },
  {
    country: "DZ",
    name: "Université d'Alger 3",
    url: "http://www.univ-alger3.dz/",
  },
  {
    country: "DZ",
    name: "Université de Batna",
    url: "http://www.univ-batna.dz/",
  },
  {
    country: "DZ",
    name: "Université de Béjaia",
    url: "http://www.univ-bejaia.dz/",
  },
  {
    country: "DZ",
    name: "Université de Biskra",
    url: "http://www.univ-biskra.dz/",
  },
  {
    country: "DZ",
    name: "Université de Blida",
    url: "http://www.univ-blida.dz/",
  },
  {
    country: "DZ",
    name: "Université de Chlef",
    url: "http://www.univ-chlef.dz/",
  },
  {
    country: "DZ",
    name: "Université de Guelma",
    url: "http://www.univ-guelma.dz/",
  },
  {
    country: "DZ",
    name: "Université de Mostaganem",
    url: "http://www.univ-mosta.dz/",
  },
  {
    country: "DZ",
    name: "Université de M'sila",
    url: "http://www.univ-msila.dz/",
  },
  {
    country: "DZ",
    name: "Université de Saida",
    url: "http://www.univ-saida.dz/",
  },
  {
    country: "DZ",
    name: "Université de Sétif",
    url: "http://www.univ-setif.dz/",
  },
  {
    country: "DZ",
    name: "Université de Sidi-Bel-Abbès (Djillali Liabès)",
    url: "http://www.univ-sba.dz/",
  },
  {
    country: "DZ",
    name: "Université de Skikda",
    url: "http://www.univ-skikda.dz/",
  },
  {
    country: "DZ",
    name: "Université des Sciences et de la Technologie d'Oran",
    url: "http://www.univ-usto.dz/",
  },
  {
    country: "DZ",
    name: "Université des Sciences et de la Technologie Houari Boumediène",
    url: "http://www.usthb.dz/",
  },
  {
    country: "DZ",
    name: "Universite des Sciences Islamiques de Constantine",
    url: "http://www.univ-emir.dz/",
  },
  {
    country: "DZ",
    name: "Université d'Oran Es-Senia",
    url: "http://www.univ-oran.dz/",
  },
  {
    country: "DZ",
    name: "Université Dr Tahar Moulay",
    url: "http://www.univ-saida.dz/",
  },
  {
    country: "DZ",
    name: "Université Kasdi Merbah Ouargla",
    url: "http://www.univ-ouargla.dz/",
  },
  {
    country: "DZ",
    name: "Université Mentouri Constantine",
    url: "http://www.umc.edu.dz/",
  },
  {
    country: "DZ",
    name: "Université M'hamed Bouguerra de Boumerdes",
    url: "http://www.umbb.dz/",
  },
  {
    country: "DZ",
    name: "Université Mouloud Mammeri de Tizi Ouzou",
    url: "http://www.ummto.dz/",
  },
  {
    country: "EC",
    name: "Brookdale Community College",
    url: "http://www.brookdale.edu.ec/",
  },
  {
    country: "EC",
    name: "Escuela Politécnica de Chimborazo",
    url: "http://www.espoch.edu/",
  },
  {
    country: "EC",
    name: "Escuela Politécnica del Ejercito",
    url: "http://www.espe.edu.ec",
  },
  {
    country: "EC",
    name: "Escuela Politécnica Nacional",
    url: "http://www.epn.edu.ec/",
  },
  {
    country: "EC",
    name: "Escuela Superior Politécnica del Litoral",
    url: "http://www.espol.edu.ec/",
  },
  {
    country: "EC",
    name: "Pontificia Universidad Católica del Ecuador",
    url: "http://www.puce.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Agraria del Ecuador",
    url: "http://www.uagraria.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Andina Simón Bolívar",
    url: "http://www.uasb.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Casa Grande",
    url: "http://www.casagrande.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Catolica de Azogues",
    url: "http://www.ucaazo.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Católica de Cuenca",
    url: "http://www.ucacue.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Católica de Santiago de Guayaquil",
    url: "http://www.ucsg.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Central del Ecuador",
    url: "http://www.ucentral.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad de Cuenca",
    url: "http://www.ucuenca.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad de Especialidades del Espíritu Santo",
    url: "http://www.uees.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad de Especialidades Turísticas",
    url: "http://www.uct.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad de Guayaquil",
    url: "http://www.ug.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad del Azuay",
    url: "http://www.uazuay.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad del Pacifico",
    url: "http://www.upacifico.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Estatal de Bolívar",
    url: "http://www.ueb.isfun.net/",
  },
  {
    country: "EC",
    name: "Universidad Internacional del Ecuador",
    url: "http://www.internacional.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Internacional SEK, Quito",
    url: "http://www.sek.edu/html/ecuador.html",
  },
  {
    country: "EC",
    name: 'Universidad Laica "Eloy Alfaro" de Manabi',
    url: "http://www.uleam.edu.ec/",
  },
  {
    country: "EC",
    name: 'Universidad Laica "Vicente Rocafuerte" de Guayaquil',
    url: "http://www.ulaicavr.com/",
  },
  {
    country: "EC",
    name: "Universidad Nacional de Chimborazo",
    url: "http://www.unach.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Nacional de Loja",
    url: "http://www.unl.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Politécnica Salesiana",
    url: "http://www.ups.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad San Francisco de Quito",
    url: "http://www.usfq.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Santa María Campus Guayaquil",
    url: "http://www.usm.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Técnica de Ambato",
    url: "http://www.uta.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Técnica de Babahoyo",
    url: "http://www.utb.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Técnica de Cotopaxi",
    url: "http://www.utc.edu.ec/",
  },
  {
    country: "EC",
    name: 'Universidad Técnica de Esmeraldas "Luis Vargas Torres',
    url: "http://www.utelvt.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Técnica del Norte",
    url: "http://www.utn.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Técnica de Machala",
    url: "http://www.utmachala.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Técnica de Manabí",
    url: "http://www.utm.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Técnica Estatal de Quevedo",
    url: "http://www.uteq.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Técnica Particular de Loja",
    url: "http://www.utpl.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Tecnológica América",
    url: "http://www.unita.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Tecnológica Equinoccial",
    url: "http://www.ute.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Tecnológica Indoamérica",
    url: "http://www.indoamerica.edu.ec/",
  },
  {
    country: "EC",
    name: "Universidad Tecnologica Israel",
    url: "http://www.uisrael.ec/",
  },
  {
    country: "EC",
    name: "Universidad Tecnológica San Antonio de Machala",
    url: "http://www.utsam.edu.ec/",
  },
  {
    country: "EE",
    name: "Estonian Academy of Arts",
    url: "http://www.artun.ee/",
  },
  {
    country: "EE",
    name: "Estonian Academy of Music and Theatre",
    url: "http://www.ema.edu.ee/",
  },
  {
    country: "EE",
    name: "Estonian Academy of Security Sciences",
    url: "http://www.sisekaitse.ee/",
  },
  {
    country: "EE",
    name: "Estonian Business School",
    url: "http://www.ebs.ee/",
  },
  {
    country: "EE",
    name: "Estonian University of Life Sciences",
    url: "http://www.emu.ee/",
  },
  {
    country: "EE",
    name: "Tallinn University",
    url: "http://www.tlu.ee/",
  },
  {
    country: "EE",
    name: "Tallinn University of Technology",
    url: "http://www.ttu.ee/",
  },
  {
    country: "EE",
    name: "Tartu Health Care College",
    url: "http://www.nooruse.ee/",
  },
  {
    country: "EE",
    name: "University of Applied Sciences",
    url: "http://www.tktk.ee/",
  },
  {
    country: "EE",
    name: "University of Tartu",
    url: "http://www.ut.ee/",
  },
  {
    country: "EG",
    name: "Ain Shams University",
    url: "http://www.shams.edu.eg/",
  },
  {
    country: "EG",
    name: "Akhbar El Yom Academy",
    url: "http://www.akhbaracademy.edu.eg/",
  },
  {
    country: "EG",
    name: "Al Azhar University",
    url: "http://www.azhar.edu.eg/",
  },
  {
    country: "EG",
    name: "Alexandria University",
    url: "http://www.alex.edu.eg/",
  },
  {
    country: "EG",
    name: "American University in Cairo",
    url: "http://www.aucegypt.edu/",
  },
  {
    country: "EG",
    name: "Arab Academy for Science & Technology",
    url: "http://www.aast.edu/",
  },
  {
    country: "EG",
    name: "Arab Open University",
    url: "http://www.aou.edu.eg/",
  },
  {
    country: "EG",
    name: "Assiut University",
    url: "http://www.aun.edu.eg/",
  },
  {
    country: "EG",
    name: "Benha University",
    url: "http://www.bu.edu.eg/",
  },
  {
    country: "EG",
    name: "Beni Suef University",
    url: "http://www.bsu.edu.eg/",
  },
  {
    country: "EG",
    name: "Cairo University",
    url: "http://www.cu.edu.eg/",
  },
  {
    country: "EG",
    name: "Damanhour University",
    url: "http://www.damanhour.edu.eg/",
  },
  {
    country: "EG",
    name: "Damietta University",
    url: "http://www.du.edu.eg/",
  },
  {
    country: "EG",
    name: "Delta University",
    url: "http://www.deltauniv.edu.eg/",
  },
  {
    country: "EG",
    name: "El Shorouk Academy",
    url: "http://www.elshoroukacademy.edu.eg/",
  },
  {
    country: "EG",
    name: "Fayoum University",
    url: "http://www.fayoum.edu.eg/",
  },
  {
    country: "EG",
    name: "Future University",
    url: "http://www.futureuniversity.edu.eg/",
  },
  {
    country: "EG",
    name: "German University in Cairo",
    url: "http://www.guc.edu.eg/",
  },
  {
    country: "EG",
    name: "Helwan University",
    url: "http://www.helwan.edu.eg/",
  },
  {
    country: "EG",
    name: "Higher Technological Institute",
    url: "http://www.hti.edu.eg/",
  },
  {
    country: "EG",
    name: "Kafr El-Sheikh University",
    url: "http://www.kfs.edu.eg/",
  },
  {
    country: "EG",
    name: "Mansoura University",
    url: "http://www.mans.edu.eg/",
  },
  {
    country: "EG",
    name: "Menoufia University",
    url: "http://www.menofia.edu.eg/",
  },
  {
    country: "EG",
    name: "Military Technical College",
    url: "http://www.mtc.edu.eg/",
  },
  {
    country: "EG",
    name: "Minia University",
    url: "http://www.minia.edu.eg/",
  },
  {
    country: "EG",
    name: "Misr International University",
    url: "http://www.miuegypt.edu.eg/",
  },
  {
    country: "EG",
    name: "Misr University for Sience and Technology",
    url: "http://www.must.edu.eg/",
  },
  {
    country: "EG",
    name: "Modern Acadmy",
    url: "http://www.modern-academy.edu.eg/",
  },
  {
    country: "EG",
    name: "Modern Sciences & Arts University",
    url: "http://www.msa.eun.eg/",
  },
  {
    country: "EG",
    name: "Modern University For Technology and Information",
    url: "http://www.mti.edu.eg/",
  },
  {
    country: "EG",
    name: "Nile University",
    url: "http://www.nileu.edu.eg/",
  },
  {
    country: "EG",
    name: "October 6 university",
    url: "http://www.o6u.edu.eg/",
  },
  {
    country: "EG",
    name: "Pharos International University",
    url: "http://www.pua.edu.eg/",
  },
  {
    country: "EG",
    name: "Port Said University",
    url: "http://www.psu.edu.eg/",
  },
  {
    country: "EG",
    name: "Sadat Academy for Management Sciences",
    url: "http://www.sadatacademy.edu.eg/",
  },
  {
    country: "EG",
    name: "Sinai University",
    url: "http://www.su.edu.eg/",
  },
  {
    country: "EG",
    name: "Sohag University",
    url: "http://www.sohag-univ.edu.eg/",
  },
  {
    country: "EG",
    name: "South Valley University",
    url: "http://www.svu.edu.eg/",
  },
  {
    country: "EG",
    name: "Suez Canal University",
    url: "http://www.suez.edu.eg/",
  },
  {
    country: "EG",
    name: "Tanta University",
    url: "http://www.tanta.edu.eg/",
  },
  {
    country: "EG",
    name: "Université Française d'Égypte",
    url: "http://www.ufe.edu.eg/",
  },
  {
    country: "EG",
    name: "Université Senghor d'Alexandrie",
    url: "http://www.usenghor-francophonie.org/",
  },
  {
    country: "EG",
    name: "University of Sadat City",
    url: "http://www.usc.edu.eg/",
  },
  {
    country: "EG",
    name: "Zagazig University",
    url: "http://www.zu.edu.eg/",
  },
  {
    country: "ER",
    name: "Eritrea Institute of Technology",
    url: "http://www.eit.edu.er/",
  },
  {
    country: "ES",
    name: "Barcelona Graduate School of Economics",
    url: "http://www.barcelonagse.eu/",
  },
  {
    country: "ES",
    name: "Bircham International University",
    url: "http://www.bircham.edu/",
  },
  {
    country: "ES",
    name: "Centro Universitario Villanueva",
    url: "http://www.villanueva.edu/",
  },
  {
    country: "ES",
    name: "Escuela Superior de Gestion Comercial y Marketing (ESIC)",
    url: "http://www.esic.es/",
  },
  {
    country: "ES",
    name: "Global Business School Barcelona",
    url: "http://www.global-business-school.org/",
  },
  {
    country: "ES",
    name: "IE University",
    url: "http://www.ie.edu/",
  },
  {
    country: "ES",
    name: "Iniciativa Universidad del Atlantico",
    url: "http://www.universidadatlantico.org/",
  },
  {
    country: "ES",
    name: "International University of Southern Europe",
    url: "http://www.iuse.edu.es/",
  },
  {
    country: "ES",
    name: "La Salle Universities - International Programmes",
    url: "http://www.lasalleuniversities.net/",
  },
  {
    country: "ES",
    name: "Mondragon Univertsitatea",
    url: "http://www.muni.es/",
  },
  {
    country: "ES",
    name: "Schiller International University, Madrid",
    url: "http://www.schillermadrid.edu/",
  },
  {
    country: "ES",
    name: "Universidad Abierta Interactiva",
    url: "http://www.uniactiva.com/",
  },
  {
    country: "ES",
    name: "Universidad Alfonso X El Sabio",
    url: "http://www.uax.es/",
  },
  {
    country: "ES",
    name: "Universidad Antonio de Nebrija",
    url: "http://www.unnet.es/",
  },
  {
    country: "ES",
    name: "Universidad Autónoma de Madrid",
    url: "http://www.uam.es/",
  },
  {
    country: "ES",
    name: "Universidad Cardenal Herrera-CEU",
    url: "http://www.uchceu.es/",
  },
  {
    country: "ES",
    name: "Universidad Carlos III de Madrid",
    url: "http://www.uc3m.es/",
  },
  {
    country: "ES",
    name: "Universidad Católica de Ávila",
    url: "http://www.ucavila.es/",
  },
  {
    country: "ES",
    name: "Universidad Católica de Valencia",
    url: "http://www.ucv.es/",
  },
  {
    country: "ES",
    name: "Universidad Católica San Antonio",
    url: "http://www.ucam.edu/",
  },
  {
    country: "ES",
    name: "Universidad Complutense de Madrid",
    url: "http://www.ucm.es/",
  },
  {
    country: "ES",
    name: "Universidad de Alcalá de Henares",
    url: "http://www.uah.es/",
  },
  {
    country: "ES",
    name: "Universidad de Alicante",
    url: "http://www.ua.es/",
  },
  {
    country: "ES",
    name: "Universidad de Almería",
    url: "http://www.ualm.es/",
  },
  {
    country: "ES",
    name: "Universidad de Burgos",
    url: "http://www.ubu.es/",
  },
  {
    country: "ES",
    name: "Universidad de Cádiz",
    url: "http://www.uca.es/",
  },
  {
    country: "ES",
    name: "Universidad de Cantabria",
    url: "http://www.unican.es/",
  },
  {
    country: "ES",
    name: "Universidad de Castilla La Mancha",
    url: "http://www.uclm.es/",
  },
  {
    country: "ES",
    name: "Universidad de Córdoba",
    url: "http://www.uco.es/",
  },
  {
    country: "ES",
    name: "Universidad de Deusto",
    url: "http://www.deusto.es/",
  },
  {
    country: "ES",
    name: "Universidad de Extremadura",
    url: "http://www.unex.es/",
  },
  {
    country: "ES",
    name: "Universidad de Gerona",
    url: "http://www.udg.es/",
  },
  {
    country: "ES",
    name: "Universidad de Granada",
    url: "http://www.ugr.es/",
  },
  {
    country: "ES",
    name: "Universidad de Huelva",
    url: "http://www.uhu.es/",
  },
  {
    country: "ES",
    name: "Universidad de Jaén",
    url: "http://www.ujaen.es/",
  },
  {
    country: "ES",
    name: "Universidad de La Coruña",
    url: "http://www.udc.es/",
  },
  {
    country: "ES",
    name: "Universidad de La Laguna",
    url: "http://www.ull.es/",
  },
  {
    country: "ES",
    name: "Universidad de La Rioja",
    url: "http://www.unirioja.es/",
  },
  {
    country: "ES",
    name: "Universidad de Las Palmas de Gran Canaria",
    url: "http://www.ulpgc.es/",
  },
  {
    country: "ES",
    name: "Universidad de León",
    url: "http://www.unileon.es/",
  },
  {
    country: "ES",
    name: "Universidad del País Vasco",
    url: "http://www.ehu.es/",
  },
  {
    country: "ES",
    name: "Universidad de Málaga",
    url: "http://www.uma.es/",
  },
  {
    country: "ES",
    name: "Universidad de Murcia",
    url: "http://www.um.es/",
  },
  {
    country: "ES",
    name: "Universidad de Navarra",
    url: "http://www.unav.es/",
  },
  {
    country: "ES",
    name: "Universidad de Oviedo",
    url: "http://www.uniovi.es/",
  },
  {
    country: "ES",
    name: "Universidad de Salamanca",
    url: "http://www.usal.es/",
  },
  {
    country: "ES",
    name: "Universidad de San Pablo CEU",
    url: "http://www.ceu.es/",
  },
  {
    country: "ES",
    name: "Universidad de Santiago de Compostela",
    url: "http://www.usc.es/",
  },
  {
    country: "ES",
    name: "Universidad de Sevilla",
    url: "http://www.us.es/",
  },
  {
    country: "ES",
    name: "Universidad de Valencia",
    url: "http://www.uv.es/",
  },
  {
    country: "ES",
    name: "Universidad de Valladolid",
    url: "http://www.uva.es/",
  },
  {
    country: "ES",
    name: "Universidad de Vigo",
    url: "http://www.uvigo.es/",
  },
  {
    country: "ES",
    name: "Universidad de Zaragoza",
    url: "http://www.unizar.es/",
  },
  {
    country: "ES",
    name: "Universidad Europea de Madrid",
    url: "http://www.uem.es/",
  },
  {
    country: "ES",
    name: "Universidad Francisco de Vitoria",
    url: "http://www.ufv.es/",
  },
  {
    country: "ES",
    name: "Universidad Internacional de Andalucía",
    url: "http://www.uia.es/",
  },
  {
    country: "ES",
    name: "Universidad Internacional de Andalucía, Sede Antonio Machado de Baeza",
    url: "http://www.uniaam.uia.es/",
  },
  {
    country: "ES",
    name: "Universidad Internacional de Andalucía, Sede La Rábida",
    url: "http://www.uniara.uia.es/",
  },
  {
    country: "ES",
    name: "Universidad Internacional Menéndez Pelayo",
    url: "http://www.uimp.es/",
  },
  {
    country: "ES",
    name: "Universidad Miguel Hernández de Elche",
    url: "http://www.umh.es/",
  },
  {
    country: "ES",
    name: "Universidad Nacional de Educación a Distancia",
    url: "http://www.uned.es/",
  },
  {
    country: "ES",
    name: "Universidad Pablo de Olavide",
    url: "http://www.upo.es/",
  },
  {
    country: "ES",
    name: "Universidad Politécnica de Cartagena",
    url: "http://www.upct.es/",
  },
  {
    country: "ES",
    name: "Universidad Politécnica de Cataluna",
    url: "http://www.upc.edu/",
  },
  {
    country: "ES",
    name: "Universidad Politécnica de Madrid",
    url: "http://www.upm.es/",
  },
  {
    country: "ES",
    name: "Universidad Politécnica de Valencia",
    url: "http://www.upv.es/",
  },
  {
    country: "ES",
    name: "Universidad Pontificia Comillas",
    url: "http://www.upco.es/",
  },
  {
    country: "ES",
    name: "Universidad Pontificia de Salamanca",
    url: "http://www.upsa.es/",
  },
  {
    country: "ES",
    name: "Universidad Pública de Navarra",
    url: "http://www.unavarra.es/",
  },
  {
    country: "ES",
    name: "Universidad Rey Juan Carlos",
    url: "http://www.urjc.es/",
  },
  {
    country: "ES",
    name: "Universitat Autónoma de Barcelona",
    url: "http://www.uab.es/",
  },
  {
    country: "ES",
    name: "Universitat de Barcelona",
    url: "http://www.ub.es/",
  },
  {
    country: "ES",
    name: "Universitat de les Illes Balears",
    url: "http://www.uib.es/",
  },
  {
    country: "ES",
    name: "Universitat de Lleida",
    url: "http://www.udl.es/",
  },
  {
    country: "ES",
    name: "Universitat de Vic",
    url: "http://www.uvic.es/",
  },
  {
    country: "ES",
    name: "Universitat Internacional de Catalunya",
    url: "http://www.uic.es/",
  },
  {
    country: "ES",
    name: "Universitat Jaume I de Castellón",
    url: "http://www.uji.es/",
  },
  {
    country: "ES",
    name: "Universitat Oberta de Catalunya",
    url: "http://www.uoc.es/",
  },
  {
    country: "ES",
    name: "Universitat Pompeu Fabra",
    url: "http://www.upf.es/",
  },
  {
    country: "ES",
    name: "Universitat Rámon Llull",
    url: "http://www.url.es/",
  },
  {
    country: "ES",
    name: "Universitat Rovira I Virgili Tarragona",
    url: "http://www.urv.es/",
  },
  {
    country: "ET",
    name: "Adama Science and Technology University",
    url: "http://www.astu.edu.et/",
  },
  {
    country: "ET",
    name: "Addis Ababa Science & Technology University",
    url: "http://www.aastu.org/",
  },
  {
    country: "ET",
    name: "Addis Ababa University",
    url: "http://www.aau.edu.et/",
  },
  {
    country: "ET",
    name: "Adigrat University",
    url: "http://www.adu.edu.et/",
  },
  {
    country: "ET",
    name: "Aksum University",
    url: "http://www.aku.edu.et/",
  },
  {
    country: "ET",
    name: "Ambo University",
    url: "http://www.ambou.edu.et/",
  },
  {
    country: "ET",
    name: "Arba Minch University",
    url: "http://www.amu.edu.et/",
  },
  {
    country: "ET",
    name: "Assosa University",
    url: "http://www.asu.edu.et/",
  },
  {
    country: "ET",
    name: "Bahir Dar University",
    url: "http://www.bdu.edu.et/",
  },
  {
    country: "ET",
    name: "Bule Hora  University",
    url: "http://www.bulehorauniversity.com/",
  },
  {
    country: "ET",
    name: "Debre Birhan University",
    url: "http://www.dbu.edu.et/",
  },
  {
    country: "ET",
    name: "Debre Markos University",
    url: "http://www.dmu.edu.et/",
  },
  {
    country: "ET",
    name: "Dilla University",
    url: "http://www.dillauniversity.edu.et/",
  },
  {
    country: "ET",
    name: "Dire Dawa University",
    url: "http://www.ddu.edu.et/",
  },
  {
    country: "ET",
    name: "Ethiopian Civil Service University",
    url: "http://www.ecsc.edu.et/",
  },
  {
    country: "ET",
    name: "Gondar University",
    url: "http://www.uog.edu.et/",
  },
  {
    country: "ET",
    name: "Haramaya University",
    url: "http://www.haramaya.edu.et/",
  },
  {
    country: "ET",
    name: "Hawassa University",
    url: "http://www.hu.edu.et/",
  },
  {
    country: "ET",
    name: "Jigjiga University",
    url: "http://www.jju.edu.et/",
  },
  {
    country: "ET",
    name: "Jimma University",
    url: "http://www.ju.edu.et/",
  },
  {
    country: "ET",
    name: "Madawalabu University",
    url: "http://www.mwu.edu.et/",
  },
  {
    country: "ET",
    name: "Mekelle Institute of Technology",
    url: "http://www.mitethiopia.edu.et/",
  },
  {
    country: "ET",
    name: "Mekelle University",
    url: "http://www.mu.edu.et/",
  },
  {
    country: "ET",
    name: "Mizan Tepi University",
    url: "http://www.mtu.edu.et/",
  },
  {
    country: "ET",
    name: "Semera University",
    url: "http://www.su.edu.et/",
  },
  {
    country: "ET",
    name: "Unity  University",
    url: "http://www.uuc.edu.et/",
  },
  {
    country: "ET",
    name: "Wolaita Sodo University",
    url: "http://www.wsu.edu.et/",
  },
  {
    country: "ET",
    name: "Wolkite University",
    url: "http://www.wku.edu.et/",
  },
  {
    country: "ET",
    name: "Wollega University",
    url: "http://www.wuni.edu.et/",
  },
  {
    country: "ET",
    name: "Wollo University",
    url: "http://www.wu.edu.et/",
  },
  {
    country: "FI",
    name: "Abo Akademi University",
    url: "http://www.abo.fi/",
  },
  {
    country: "FI",
    name: "Academy of Fine Arts",
    url: "http://www.kuva.fi/",
  },
  {
    country: "FI",
    name: "Central Ostrobothnia University of Applied Sciences",
    url: "http://www.cou.fi/",
  },
  {
    country: "FI",
    name: "Diaconia University of Applied Sciences",
    url: "http://www.diak.fi/",
  },
  {
    country: "FI",
    name: "EVTEK University of Applied Sciences",
    url: "http://www.evtek.fi/",
  },
  {
    country: "FI",
    name: "Helsinki School of Economics and Business Administration",
    url: "http://www.hkkk.fi/",
  },
  {
    country: "FI",
    name: "Helsinki University of Technology",
    url: "http://www.hut.fi/",
  },
  {
    country: "FI",
    name: "Lahti Polytechnic",
    url: "http://www.lamk.fi/",
  },
  {
    country: "FI",
    name: "Lappeenranta University of Technology",
    url: "http://www.lut.fi/",
  },
  {
    country: "FI",
    name: "Oulu Polytechnic",
    url: "http://www.oamk.fi/",
  },
  {
    country: "FI",
    name: "Rovaniemi University of Applied Sciences",
    url: "http://www.ramk.fi/",
  },
  {
    country: "FI",
    name: "Satakunta University Of Applied Scinces",
    url: "http://www.samk.fi/",
  },
  {
    country: "FI",
    name: "Scandinavian Art and Business Institute",
    url: "http://www.sabi.eu.com/",
  },
  {
    country: "FI",
    name: "Sibelius Academy",
    url: "http://www.siba.fi/",
  },
  {
    country: "FI",
    name: "South Carelian Polytechnic",
    url: "http://www.scp.fi/",
  },
  {
    country: "FI",
    name: "Swedish School of Economics and Business Administration, Finland",
    url: "http://www.shh.fi/",
  },
  {
    country: "FI",
    name: "Tampere Polytechnic",
    url: "http://www.tpu.fi/",
  },
  {
    country: "FI",
    name: "Tampere University of Technology",
    url: "http://www.tut.fi/",
  },
  {
    country: "FI",
    name: "Theatre Academy Finland",
    url: "http://www.teak.fi/",
  },
  {
    country: "FI",
    name: "Turku School of Economics and Business Administration",
    url: "http://www.tukkk.fi/",
  },
  {
    country: "FI",
    name: "University of Art and Design Helsinki",
    url: "http://www.uiah.fi/",
  },
  {
    country: "FI",
    name: "University of Helsinki",
    url: "http://www.helsinki.fi/",
  },
  {
    country: "FI",
    name: "University of Joensuu",
    url: "http://www.joensuu.fi/",
  },
  {
    country: "FI",
    name: "University of Jyväskylä",
    url: "http://www.jyu.fi/",
  },
  {
    country: "FI",
    name: "University of Kuopio",
    url: "http://www.uku.fi/",
  },
  {
    country: "FI",
    name: "University of Lapland",
    url: "http://www.ulapland.fi/",
  },
  {
    country: "FI",
    name: "University of Oulu",
    url: "http://www.oulu.fi/",
  },
  {
    country: "FI",
    name: "University of Tampere",
    url: "http://www.uta.fi/",
  },
  {
    country: "FI",
    name: "University of Turku",
    url: "http://www.utu.fi/",
  },
  {
    country: "FI",
    name: "University of Vaasa",
    url: "http://www.uwasa.fi/",
  },
  {
    country: "FI",
    name: "Vaasa University of Applied Sciences",
    url: "http://www.puv.fi/",
  },
  {
    country: "FJ",
    name: "Fiji National University",
    url: "http://www.fnu.ac.fj/",
  },
  {
    country: "FJ",
    name: "Fiji School of Medicine",
    url: "http://www.fsm.ac.fj/",
  },
  {
    country: "FJ",
    name: "University of Fiji",
    url: "http://www.unifiji.ac.fj/",
  },
  {
    country: "FJ",
    name: "University of the South Pacific",
    url: "http://www.usp.ac.fj/",
  },
  {
    country: "FO",
    name: "University of the Faroe Islands",
    url: "http://www.setur.fo/",
  },
  {
    country: "FR",
    name: "AgroParisTech",
    url: "http://www.agroparistech.fr/",
  },
  {
    country: "FR",
    name: "American Graduate School in Paris",
    url: "http://www.ags.edu/",
  },
  {
    country: "FR",
    name: "American University of Paris",
    url: "http://www.aup.fr/",
  },
  {
    country: "FR",
    name: "British Institute in Paris, University of London",
    url: "http://www.bip.lon.ac.uk/",
  },
  {
    country: "FR",
    name: "Centre de Formation et de Perfectionnement des Journalistes",
    url: "http://www.cfpj.com/",
  },
  {
    country: "FR",
    name: "Centre d'Etudes Supérieures des Techniques Industrielles",
    url: "http://www.ismcm-cesti.fr/",
  },
  {
    country: "FR",
    name: "Centre d'Etudes Supérieures Industrielles Paris",
    url: "http://www.cesi.fr/",
  },
  {
    country: "FR",
    name: "Centre National d'Etudes Agronomiques des Régions Chaudes",
    url: "http://www.cnearc.fr/",
  },
  {
    country: "FR",
    name: "Conservatoire National des Arts et Métiers",
    url: "http://www.cnam.fr/",
  },
  {
    country: "FR",
    name: "Ecole Catholique d'Arts & Metiers",
    url: "http://www.ecam.fr/",
  },
  {
    country: "FR",
    name: "Ecole Centrale d'Electronique - ECE",
    url: "http://www.ece.fr/",
  },
  {
    country: "FR",
    name: "Ecole Centrale de Lille",
    url: "http://www.ec-lille.fr/",
  },
  {
    country: "FR",
    name: "Ecole Centrale de Lyon",
    url: "http://www.ec-lyon.fr/",
  },
  {
    country: "FR",
    name: "Ecole Centrale de Nantes",
    url: "http://www.ec-nantes.fr/",
  },
  {
    country: "FR",
    name: "Ecole d'Architecture de Nancy",
    url: "http://www.nancy.archi.fr/",
  },
  {
    country: "FR",
    name: "Ecole de l'Air",
    url: "http://www.aea.asso.fr/",
  },
  {
    country: "FR",
    name: "Ecole des Hautes Etudes Commerciales",
    url: "http://www.hec.fr/",
  },
  {
    country: "FR",
    name: "Ecole des Hautes Etudes Commerciales du Nord",
    url: "http://edu.edhec.com/",
  },
  {
    country: "FR",
    name: "Ecole des Hautes Etudes Industrielles de Lille",
    url: "http://www.hei.fr/",
  },
  {
    country: "FR",
    name: "Ecole des Ingénieurs de la Ville de Paris",
    url: "http://www.eivp-paris.fr/",
  },
  {
    country: "FR",
    name: "Ecole d'Ingénieurs en Informatique pour l'Industrie",
    url: "http://www.e3i.univ-tours.fr/",
  },
  {
    country: "FR",
    name: "Ecole Européen des Affaires",
    url: "http://www.eap.fr/",
  },
  {
    country: "FR",
    name: "Ecole Européenne de Chimie, Polymères et Matériaux de Strasbourg",
    url: "http://www-ecpm.u-strasbg.fr/",
  },
  {
    country: "FR",
    name: "Ecole Française d'Electronique et d'Informatique",
    url: "http://www.efrei.fr/",
  },
  {
    country: "FR",
    name: "Ecole Française de Papeterie et des Industries Graphiques",
    url: "http://www.efpg.inpg.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale d'Administration",
    url: "http://www.ena.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale de la Météorologie",
    url: "http://www.enm.meteo.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale de la Statistique et de l'Administration Economique",
    url: "http://www.ensae.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale de la Statistique et de l'Analyse de l'information",
    url: "http://www.ensai.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale de l'Aviation Civile",
    url: "http://www.enac.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale des Ponts et Chausees",
    url: "http://www.enpc.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale des Sciences Géographiques",
    url: "http://www.ensg.ign.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale des Travaux Publics de l'Etat",
    url: "http://www.entpe.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale d'Ingénieurs de Metz",
    url: "http://www.enim.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale d'Ingénieurs de Saint-Etienne",
    url: "http://www.enise.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale d'Ingénieurs des Techniques des Industries Agricoles et Alimentaires",
    url: "http://www.enitiaa-nantes.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Bordeaux",
    url: "http://www.enitab.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Clermont-Ferrand",
    url: "http://www.enitac.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale d'Ingénieurs de Tarbes",
    url: "http://www.enit.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale du Génie de l'Eau et de l'Environnement de Strasbourg",
    url: "http://www-engees.u-strasbg.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieur de Géologie de Nancy",
    url: "http://www.ensg.u-nancy.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieur de Mécanique et d'Aéronautique",
    url: "http://www.ensma.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieur de Mécaniques et des Microtechniques",
    url: "http://www.ens2m.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieur des Ingénieur des Etudes et Techniques d'Armement",
    url: "http://www.ensieta.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieur d'Ingénieurs de Constructions Aéronautique",
    url: "http://www.ensica.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure Agronomique de Toulouse",
    url: "http://www.ensat.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure d'Agronomie de Montpellier",
    url: "http://www.ensam.inra.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure d'Agronomie de Rennes",
    url: "http://agro.roazhon.inra.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure d'Agronomie et des Industries Alimentaires",
    url: "http://www.ensaia.u-nancy.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure d'Arts et Métiers de Paris",
    url: "http://www.paris.ensam.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure de Biologie Appliquée à la Nutrition et à l'Alementation",
    url: "http://www.u-bourgogne.fr/ENSBANA/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure de Chimie de Clermont-Ferrand",
    url: "http://ensccf.univ-bpclermont.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure de Chimie de Lille",
    url: "http://www.ensc-lille.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure de Chimie de Montpellier",
    url: "http://www.enscm.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure de Chimie de Mulhouse",
    url: "http://www.enscmu.univ-mulhouse.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure de Chimie de Paris",
    url: "http://www.enscp.jussieu.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure de Chimie de Rennes",
    url: "http://www.ensc-rennes.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure de Chimie de Toulouse",
    url: "http://www.univ-inpt.fr/~ensct/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure de Chimie et de Physique de Bordeaux",
    url: "http://www.enscpb.u-bordeaux.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure de l'Aéronautique et de l'Espace",
    url: "http://www.supaero.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure d'Electrochimie et d'Electrométallurgie de Gernoble",
    url: "http://www.inpg.fr/INPG/fr_see.html",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure d'Electronique, d'Electrotechnique, d'Informatique et d'Hydraulique de Toulouse",
    url: "http://www.enseeiht.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Bordeaux",
    url: "http://www.enserb.u-bordeaux.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Grenoble",
    url: "http://www.enserg.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure de l'Electronique et de ses Applications",
    url: "http://www.ensea.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure de Physique de Grenoble",
    url: "http://www-enspg.inpg.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure de Physique de Marseille",
    url: "http://www.enspm.u-3mrs.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure de Physique de Strasbourg",
    url: "http://www-ensps.u-strasbg.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure des Arts et Industries de Strasbourg",
    url: "http://www-ensais.u-strasbg.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure des Arts et Industries Textiles",
    url: "http://www.ensait.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure des Industries Chimiques de Nancy",
    url: "http://www.ensic.u-nancy.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure des Industries Textiles de Mulhouse",
    url: "http://www.ensitm.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure des Mines d'Alès",
    url: "http://www.ensm-ales.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure des Mines de Douai",
    url: "http://www.ensm-douai.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure des Mines de Nancy",
    url: "http://www.mines.u-nancy.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure des Mines de Paris",
    url: "http://www.ensmp.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure des Mines de St-Etienne",
    url: "http://www.emse.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure des Telecommunications de Bretagne",
    url: "http://www.enst-bretagne.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure des Telecommunications de Paris",
    url: "http://www.enst.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure de Techniques Avancées",
    url: "http://www.ensta.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure d'Hydraulique et de Mécanique de Grenoble",
    url: "http://www.hmg.inpg.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Superieure d'Informatique et de Mathematiques Appliquees de Grenoble",
    url: "http://www-ensimag.imag.fr/",
  },
  {
    country: "FR",
    name: "École Nationale Supérieure d'Ingénieurs de Constructions Aéronautiques",
    url: "http://www.ensica.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure d'Ingénieurs de Génie Chimique",
    url: "http://www.inp-fc.fr/~ensigc/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure d'Ingenieurs Electriciens de Grenoble",
    url: "http://www.ensieg.inpg.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure d'Ingénieurs en Mécanique et Energétique de Valenciennes",
    url: "http://www.univ-valenciennes.fr/ensimev/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure du Pétrole et des Monteurs",
    url: "http://www.ifp.fr/EC/EC000GP1.html",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure Electricité et Mécanique",
    url: "http://www.ensem.u-nancy.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Supérieure en Electrotechnique, Electronique, Informatique et Hydraulique de Toulouse",
    url: "http://www.enseeiht.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Vétérinaire d'Alfort",
    url: "http://www.vet-alfort.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Vétérinaire de Lyon",
    url: "http://www.vet-lyon.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Vétérinaire de Nantes",
    url: "http://www.vet-nantes.fr/",
  },
  {
    country: "FR",
    name: "Ecole Nationale Vétérinaire de Toulouse",
    url: "http://www.envt.fr/",
  },
  {
    country: "FR",
    name: "Ecole Normale Supérieure de Cachan",
    url: "http://www.ens-cachan.fr/",
  },
  {
    country: "FR",
    name: "Ecole Normale Supérieure de Fontenay-Saint Cloud",
    url: "http://www.ens-fcl.fr/",
  },
  {
    country: "FR",
    name: "Ecole Normale Supérieure de Lyon",
    url: "http://www.ens-lyon.fr/",
  },
  {
    country: "FR",
    name: "Ecole Normale Supérieure de Paris",
    url: "http://www.ens.fr/",
  },
  {
    country: "FR",
    name: "Ecole Polytechnique",
    url: "http://www.polytechnique.fr/",
  },
  {
    country: "FR",
    name: "Ecole Polytechnique Marseille",
    url: "http://www.polytech-marseille.fr/",
  },
  {
    country: "FR",
    name: "Ecole Polytechnique Universitaire de Lille",
    url: "http://www.polytech-lille.fr/",
  },
  {
    country: "FR",
    name: "Ecole pour les Etudes et la Recherche en Informatique et Electronique",
    url: "http://www.eerie.fr/",
  },
  {
    country: "FR",
    name: "Ecole Spéciale de Mécanique et d'Electricité",
    url: "http://www.esme.fr/",
  },
  {
    country: "FR",
    name: "Ecole Spéciale des Travaux Publics du Bâtiment et de l'Industrie",
    url: "http://www.estp.fr/",
  },
  {
    country: "FR",
    name: "Ecole Superieur d'Ingenieurs Leonard de Vinci",
    url: "http://www.devinci.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure d'Agriculture d'Angers",
    url: "http://www.groupe-esa.com/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure d'Agriculture de Purpan",
    url: "http://www.esa-purpan.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Chimie Organique et Minérale",
    url: "http://www.escom.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Chimie Physique Electronique de Lyon",
    url: "http://www.cpe.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Commerce de Bordeaux",
    url: "http://www.esc-bordeaux.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Commerce de Brest",
    url: "http://www.esc-brest.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Commerce de Clermont-Ferrand",
    url: "http://www.esc-clermont.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Commerce de Dijon",
    url: "http://www.escdijon.com/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Commerce de Grenoble",
    url: "http://www.esc-grenoble.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Commerce de Le Havre/Caen",
    url: "http://www.esc-normandie.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Commerce de Lille",
    url: "http://www.esc-lille.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Commerce de Lyon",
    url: "http://www.em-lyon.com/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Commerce de Marseille-Provence",
    url: "http://www.esc-marseille.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Commerce de Nantes-Atlantique",
    url: "http://www.escna.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Commerce de Paris",
    url: "http://www.escp.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Commerce de Pau",
    url: "http://www.esc-pau.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Commerce de Reims",
    url: "http://www.esc-reims.edu/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Commerce de Rouen",
    url: "http://www.esc-rouen.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Commerce de Sophia Antipolis",
    url: "http://www.ceram.edu/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Commerce de Toulouse",
    url: "http://www.esc-toulouse.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Commerce et Management",
    url: "http://www.escem.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure d'Electricité",
    url: "http://www.supelec.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure d'Electronique de l'Ouest",
    url: "http://www.eseo.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure de Physique et de Chimie Industrielles",
    url: "http://www.espci.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure des Sciences Commerciales d'Angers",
    url: "http://www.essca.asso.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure des Sciences Economiques et Commerciales",
    url: "http://www.essec.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure des Sciences et Technologie de l'Ingénieur de Nancy",
    url: "http://www.esstin.u-nancy.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure des Techniques Industrielles et des Textiles",
    url: "http://www.fupl.asso.fr/estit/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure d'Informatique-Electronique-Automatique",
    url: "http://www.esiea.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure d'Ingénieurs de Marseille",
    url: "http://esim.imt-mrs.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure d'Ingénieurs en Electronique et Electrotechnique",
    url: "http://www.esiee.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure d'Ingénieurs en Génie Electrique",
    url: "http://www.esigelec.fr/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure d'Ingénieurs et de Techniciens pour l'Agriculture",
    url: "http://www.esitpa.org/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure d'Optique",
    url: "http://www.iota.u-psud.fr/~ecole/",
  },
  {
    country: "FR",
    name: "Ecole Supérieure Internationale d'Administration des Entreprises",
    url: "http://www.esiae.fr/",
  },
  {
    country: "FR",
    name: "Ecole Superieure Robert de Sorbon",
    url: "http://www.sorbon.fr/",
  },
  {
    country: "FR",
    name: "Ecole Universitaire d'Ingénieurs de Lille",
    url: "http://www.eudil.fr/",
  },
  {
    country: "FR",
    name: "ENIC Telecom Lille 1",
    url: "http://www.enic.fr/",
  },
  {
    country: "FR",
    name: "EPF Ecole d'Ingénieurs",
    url: "http://www.epf.fr/",
  },
  {
    country: "FR",
    name: "European Business School",
    url: "http://www.ebs-paris.com/",
  },
  {
    country: "FR",
    name: "European Management Center Paris",
    url: "http://www.emc-campus.com/",
  },
  {
    country: "FR",
    name: "Grenoble Ecole de Management",
    url: "http://www.grenoble-em.com/",
  },
  {
    country: "FR",
    name: "Groupe Sup de Co Amiens Picardie",
    url: "http://www.supco-amiens.fr/",
  },
  {
    country: "FR",
    name: "Groupe Sup de Co Montpellier",
    url: "http://www.supco-montpellier.fr/",
  },
  {
    country: "FR",
    name: "IDRAC (Institut de recherche en action commerciale)",
    url: "http://www.ecoles-idrac.com/",
  },
  {
    country: "FR",
    name: "Institut Catholique d'Arts et Métiers Lille",
    url: "http://www.icam.fr/",
  },
  {
    country: "FR",
    name: "Institut Catholique d'Arts et Métiers Nantes",
    url: "http://www.icam.fr/presentation/nantes.htm",
  },
  {
    country: "FR",
    name: "Institut Catholique de Paris",
    url: "http://www.icp.fr/",
  },
  {
    country: "FR",
    name: "Institut Catholique de Toulouse",
    url: "http://www.ict-toulouse.asso.fr/",
  },
  {
    country: "FR",
    name: "Institut Commercial de Nancy",
    url: "http://www.icn-nancy.com/",
  },
  {
    country: "FR",
    name: "Institut de Recherche et d'Enseignement Supérieur aux Techniques de l'électronique",
    url: "http://www.ireste.fr/",
  },
  {
    country: "FR",
    name: "Institut des hautes études économiques et commerciales",
    url: "http://www.inseec.fr/",
  },
  {
    country: "FR",
    name: "Institut des Sciences de la Matière et du Rayonnement",
    url: "http://www.ismra.fr/",
  },
  {
    country: "FR",
    name: "Institut des Sciences de l'Ingénieur de Clermont-Ferrand",
    url: "http://cust.univ-bpclermont.fr/",
  },
  {
    country: "FR",
    name: "Institut des Sciences de l'Ingénieur de Montpellier",
    url: "http://www.isim.univ-montp2.fr/",
  },
  {
    country: "FR",
    name: "Institut d'Etudes Politiques de Bordeaux",
    url: "http://www.iep.u-bordeaux.fr/",
  },
  {
    country: "FR",
    name: "Institut d'Etudes Politiques de Paris (Sciences Po)",
    url: "http://www.sciences-po.fr/",
  },
  {
    country: "FR",
    name: "Institut National des Sciences Appliquées de Lyon",
    url: "http://www.insa-lyon.fr/",
  },
  {
    country: "FR",
    name: "Institut National des Sciences Appliquées de Rennes",
    url: "http://www.insa-rennes.fr/",
  },
  {
    country: "FR",
    name: "Institut National des Sciences Appliquées de Rouen",
    url: "http://www.insa-rouen.fr/",
  },
  {
    country: "FR",
    name: "Institut National des Sciences Appliquées de Toulouse",
    url: "http://www.insa-tlse.fr/",
  },
  {
    country: "FR",
    name: "Institut National des Télécommunications",
    url: "http://www.int-evry.fr/",
  },
  {
    country: "FR",
    name: "Institut National Polytechnique de Grenoble",
    url: "http://www.inpg.fr/",
  },
  {
    country: "FR",
    name: "Institut National Polytechnique de Lorraine",
    url: "http://www.inpl-nancy.fr/",
  },
  {
    country: "FR",
    name: "Institut National Polytechnique de Toulouse",
    url: "http://www.inp-fc.fr/",
  },
  {
    country: "FR",
    name: "Institut National Supérieur de Formation Agro-Alimentaire",
    url: "http://agro.roazhon.inra.fr/etab/insfa/accueil.htm",
  },
  {
    country: "FR",
    name: "Institut Supérieur Agricole de Beauvais",
    url: "http://www.isab.fr/",
  },
  {
    country: "FR",
    name: "Institut Supérieur d'Agriculture Lille",
    url: "http://www.isa.fupl.asso.fr/",
  },
  {
    country: "FR",
    name: "Institut Supérieur d'Agriculture Rhone-Alpes",
    url: "http://www.isara.fr/",
  },
  {
    country: "FR",
    name: "Institut Supérieure d'Electronique de Paris",
    url: "http://www.isep.fr/",
  },
  {
    country: "FR",
    name: "Institut Supérieure d'Electronique du Nord",
    url: "http://www.isen.fr/",
  },
  {
    country: "FR",
    name: "Institut Textile et Chimique de Lyon",
    url: "http://www.itech.fr/",
  },
  {
    country: "FR",
    name: "Schiller International University, Paris",
    url: "http://www.schillerparis.com/",
  },
  {
    country: "FR",
    name: "Schiller International University, Strasbourg",
    url: "http://www.schillerstrasbourg.com/",
  },
  {
    country: "FR",
    name: "Université Blaise Pascal (Clermont-Ferrand II)",
    url: "http://www.univ-bpclermont.fr/",
  },
  {
    country: "FR",
    name: "Université Bordeaux I",
    url: "http://www.cribx1.u-bordeaux.fr/",
  },
  {
    country: "FR",
    name: "Université Catholique de Lille",
    url: "http://www.fupl.asso.fr/",
  },
  {
    country: "FR",
    name: "Université Catholique de l' Ouest",
    url: "http://www.uco.fr/",
  },
  {
    country: "FR",
    name: "Université Catholique de Lyon",
    url: "http://www.univ-catholyon.fr/",
  },
  {
    country: "FR",
    name: "Université Charles-de-Gaulle (Lille III)",
    url: "http://www.univ-lille3.fr/",
  },
  {
    country: "FR",
    name: "Université Claude Bernard (Lyon I)",
    url: "http://www.univ-lyon1.fr/",
  },
  {
    country: "FR",
    name: "Université d'Aix-Marseille III",
    url: "http://www.u-3mrs.fr/",
  },
  {
    country: "FR",
    name: "Université d'Angers",
    url: "http://www.univ-angers.fr/",
  },
  {
    country: "FR",
    name: "Université d'Artois",
    url: "http://www.univ-artois.fr/",
  },
  {
    country: "FR",
    name: "Université d'Auvergne (Clermont-Ferrand I)",
    url: "http://www.u-clermont1.fr/",
  },
  {
    country: "FR",
    name: "Université d'Avignon",
    url: "http://www.univ-avignon.fr/",
  },
  {
    country: "FR",
    name: "Université de Bourgogne",
    url: "http://www.u-bourgogne.fr/",
  },
  {
    country: "FR",
    name: "Université de Bretagne Occidentale",
    url: "http://www.univ-brest.fr/",
  },
  {
    country: "FR",
    name: "Université de Bretagne Sud",
    url: "http://www.univ-ubs.fr/",
  },
  {
    country: "FR",
    name: "Université de Caen Basse Normandie",
    url: "http://www.unicaen.fr/",
  },
  {
    country: "FR",
    name: "Université de Cergy-Pontoise",
    url: "http://www.u-cergy.fr/",
  },
  {
    country: "FR",
    name: "Université de Corse Pascal Paoli",
    url: "http://www.univ-corse.fr/",
  },
  {
    country: "FR",
    name: "Université de Franche-Comté",
    url: "http://www.univ-fcomte.fr/",
  },
  {
    country: "FR",
    name: "Université de Haute-Alsace",
    url: "http://www.univ-mulhouse.fr/",
  },
  {
    country: "FR",
    name: "Université de la Méditerranée (Aix Marseille II)",
    url: "http://www.univmed.fr/",
  },
  {
    country: "FR",
    name: "Université de La Rochelle",
    url: "http://www.univ-lr.fr/",
  },
  {
    country: "FR",
    name: "Université de Limoges",
    url: "http://www.unilim.fr/",
  },
  {
    country: "FR",
    name: "Université de Marne la Vallée",
    url: "http://www.univ-mlv.fr/",
  },
  {
    country: "FR",
    name: "Université de Metz",
    url: "http://www.univ-metz.fr/",
  },
  {
    country: "FR",
    name: "Université de Montpellier I",
    url: "http://www.univ-montp1.fr/",
  },
  {
    country: "FR",
    name: "Université de Montpellier II",
    url: "http://www.univ-montp2.fr/",
  },
  {
    country: "FR",
    name: "Université de Nantes",
    url: "http://www.univ-nantes.fr/",
  },
  {
    country: "FR",
    name: "Université de Nice-Sophia Antipolis",
    url: "http://www.unice.fr/",
  },
  {
    country: "FR",
    name: "Université Denis Diderot (Paris VII)",
    url: "http://www.sigu7.jussieu.fr/",
  },
  {
    country: "FR",
    name: "Université de Pau et des Pays de l'Adour",
    url: "http://www.univ-pau.fr/",
  },
  {
    country: "FR",
    name: "Université de Perpignan",
    url: "http://www.univ-perp.fr/",
  },
  {
    country: "FR",
    name: "Université de Picardie Jules-Verne",
    url: "http://www.u-picardie.fr/",
  },
  {
    country: "FR",
    name: "Université de Poitiers",
    url: "http://www.univ-poitiers.fr/",
  },
  {
    country: "FR",
    name: "Université de Provence (Aix-Marseille I)",
    url: "http://www.up.univ-mrs.fr/",
  },
  {
    country: "FR",
    name: "Université de Reims Champagne-Ardenne",
    url: "http://www.univ-reims.fr/",
  },
  {
    country: "FR",
    name: "Université de Rouen - Haute Normandie",
    url: "http://www.univ-rouen.fr/",
  },
  {
    country: "FR",
    name: "Université de Savoie",
    url: "http://www.univ-savoie.fr/",
  },
  {
    country: "FR",
    name: "Université des Sciences et Technologies de Lille (Lille I)",
    url: "http://www.univ-lille1.fr/",
  },
  {
    country: "FR",
    name: "Université des Sciences Humaines (Strasbourg II)",
    url: "http://ushs.u-strasbg.fr/",
  },
  {
    country: "FR",
    name: "Université des Sciences Sociales (Toulouse I)",
    url: "http://www.univ-tlse1.fr/",
  },
  {
    country: "FR",
    name: "Université de Technologie de Belfort Montbéliard",
    url: "http://www.utbm.fr/",
  },
  {
    country: "FR",
    name: "Université de Technologie de Compiègne",
    url: "http://www.utc.fr/",
  },
  {
    country: "FR",
    name: "Université de Technologie de Troyes",
    url: "http://www.utt.fr/",
  },
  {
    country: "FR",
    name: "Université de Toulouse",
    url: "http://www.univ-toulouse.fr/",
  },
  {
    country: "FR",
    name: "Université de Toulouse-le-Mirail (Toulouse II)",
    url: "http://www.univ-tlse2.fr/",
  },
  {
    country: "FR",
    name: "Université de Valenciennes et du Hainaut-Cambrésis",
    url: "http://www.univ-valenciennes.fr/",
  },
  {
    country: "FR",
    name: "Université de Versailles Saint-Quentin-en-Yvelines",
    url: "http://www.uvsq.fr/",
  },
  {
    country: "FR",
    name: "Université d'Evry Val d'Essonne",
    url: "http://www.univ-evry.fr/",
  },
  {
    country: "FR",
    name: "Université d'Orléans",
    url: "http://www.univ-orleans.fr/",
  },
  {
    country: "FR",
    name: "Université du Droit et de la Sante (Lille II)",
    url: "http://www.univ-lille2.fr/",
  },
  {
    country: "FR",
    name: "Université du Havre",
    url: "http://www.univ-lehavre.fr/",
  },
  {
    country: "FR",
    name: "Université du Littoral Cote d'Opale",
    url: "http://www.univ-littoral.fr/",
  },
  {
    country: "FR",
    name: "Université du Maine",
    url: "http://www.univ-lemans.fr/",
  },
  {
    country: "FR",
    name: "Université du Sud, Toulon et Var",
    url: "http://www.univ-tln.fr/",
  },
  {
    country: "FR",
    name: "Université François Rabelais de Tours",
    url: "http://www.univ-tours.fr/",
  },
  {
    country: "FR",
    name: "Université Henri Poincaré (Nancy I)",
    url: "http://www.uhp-nancy.fr/",
  },
  {
    country: "FR",
    name: "Université Jean Monnet",
    url: "http://www.univ-st-etienne.fr/",
  },
  {
    country: "FR",
    name: "Université Jean Moulin (Lyon III)",
    url: "http://www.univ-lyon3.fr/",
  },
  {
    country: "FR",
    name: "Université Joseph Fourier (Grenoble I)",
    url: "http://www.ujf-grenoble.fr/",
  },
  {
    country: "FR",
    name: "Université Louis Pasteur (Strasbourg I)",
    url: "http://www-ulp.u-strasbg.fr/",
  },
  {
    country: "FR",
    name: "Université Lumiére (Lyon II)",
    url: "http://www.univ-lyon2.fr/",
  },
  {
    country: "FR",
    name: "Université Michel de Montaigne (Bordeaux III )",
    url: "http://www.montaigne.u-bordeaux.fr/",
  },
  {
    country: "FR",
    name: "Université Montesquieu (Bordeaux IV)",
    url: "http://www.montesquieu.u-bordeaux.fr/",
  },
  {
    country: "FR",
    name: "Université Nancy II",
    url: "http://www.univ-nancy2.fr/",
  },
  {
    country: "FR",
    name: "Université Panthéon-Assas (Paris II)",
    url: "http://www.u-paris2.fr/",
  },
  {
    country: "FR",
    name: "Université Panthéon-Sorbonne (Paris I)",
    url: "http://www.univ-paris1.fr/",
  },
  {
    country: "FR",
    name: "Université Paris-Dauphine (Paris IX)",
    url: "http://www.dauphine.fr/",
  },
  {
    country: "FR",
    name: "Université Paris Nanterre (Paris X)",
    url: "http://www.u-paris10.fr/",
  },
  {
    country: "FR",
    name: "Université Paris Nord (Paris XIII)",
    url: "http://www.univ-paris13.fr/",
  },
  {
    country: "FR",
    name: "Université Paris-Sorbonne (Paris IV)",
    url: "http://www.paris4.sorbonne.fr/",
  },
  {
    country: "FR",
    name: "Université Paris Sud (Paris XI)",
    url: "http://www.u-psud.fr/",
  },
  {
    country: "FR",
    name: "Université Paul Sabatier (Toulouse III)",
    url: "http://www.ups-tlse.fr/",
  },
  {
    country: "FR",
    name: "Université Paul Valéry (Montpellier III)",
    url: "http://www.univ-montp3.fr/",
  },
  {
    country: "FR",
    name: "Université Pierre et Marie Curie (Paris VI)",
    url: "http://www.jussieu.fr/",
  },
  {
    country: "FR",
    name: "Université Pierre Mendes-France (Grenoble II)",
    url: "http://www.upmf-grenoble.fr/",
  },
  {
    country: "FR",
    name: "Université René Descartes (Paris V)",
    url: "http://www.univ-paris5.fr/",
  },
  {
    country: "FR",
    name: "Université Rennes I",
    url: "http://www.univ-rennes1.fr/",
  },
  {
    country: "FR",
    name: "Université Rennes II - Haute-Bretagne",
    url: "http://www.uhb.fr/",
  },
  {
    country: "FR",
    name: "Université Robert Schuman (Strasbourg III)",
    url: "http://www-urs.u-strasbg.fr/",
  },
  {
    country: "FR",
    name: "Université Sorbonne-Nouvelle (Paris III)",
    url: "http://www.univ-paris3.fr/",
  },
  {
    country: "FR",
    name: "Université Stendhal (Grenoble III)",
    url: "http://www.u-grenoble3.fr/",
  },
  {
    country: "FR",
    name: "Université Val-de-Marne (Paris XII)",
    url: "http://www.univ-paris12.fr/",
  },
  {
    country: "FR",
    name: "Université Victor Segalen (Bordeaux II)",
    url: "http://www.u-bordeaux2.fr/",
  },
  {
    country: "FR",
    name: "Université Vincennes Saint-Denis (Paris VIII)",
    url: "http://www.univ-paris8.fr/",
  },
  {
    country: "GA",
    name: "Université Omar Bongo",
    url: "http://www.uob.ga/",
  },
  {
    country: "GB",
    name: "Aga Khan University",
    url: "http://www.aku.edu/",
  },
  {
    country: "GB",
    name: "American InterContinental University - London",
    url: "http://www.aiuniv.edu/",
  },
  {
    country: "GB",
    name: "Anglia Ruskin University",
    url: "http://www.anglia.ac.uk/",
  },
  {
    country: "GB",
    name: "Aston University",
    url: "http://www.aston.ac.uk/",
  },
  {
    country: "GB",
    name: "Birkbeck College, University of London",
    url: "http://www.bbk.ac.uk/",
  },
  {
    country: "GB",
    name: "Birmingham City University",
    url: "http://www.bcu.ac.uk/",
  },
  {
    country: "GB",
    name: "Bournemouth University",
    url: "http://www.bournemouth.ac.uk/",
  },
  {
    country: "GB",
    name: "British College of Osteopathic Medicine",
    url: "http://www.bcom.ac.uk/",
  },
  {
    country: "GB",
    name: "Brunel University Uxbridge",
    url: "http://www.brunel.ac.uk/",
  },
  {
    country: "GB",
    name: "Buckinghamshire New University",
    url: "http://www.bucks.ac.uk/",
  },
  {
    country: "GB",
    name: "Camberwell College of Arts",
    url: "http://www.camb.linst.ac.uk/",
  },
  {
    country: "GB",
    name: "Canterbury Christ Church University",
    url: "http://www.canterbury.ac.uk/",
  },
  {
    country: "GB",
    name: "Cardiff University",
    url: "http://www.cardiff.ac.uk/",
  },
  {
    country: "GB",
    name: "Central Saint Martins College of Art & Design",
    url: "http://www.csm.linst.ac.uk/",
  },
  {
    country: "GB",
    name: "Chelsea College of Art and Design",
    url: "http://www.chelsea.linst.ac.uk/",
  },
  {
    country: "GB",
    name: "City University",
    url: "http://www.city.ac.uk/",
  },
  {
    country: "GB",
    name: "Courtauld Institute of Art, University of London",
    url: "http://www.courtauld.ac.uk/",
  },
  {
    country: "GB",
    name: "Coventry University",
    url: "http://www.coventry.ac.uk/",
  },
  {
    country: "GB",
    name: "Cranfield University",
    url: "http://www.cranfield.ac.uk/",
  },
  {
    country: "GB",
    name: "De Montfort University Leicester",
    url: "http://www.dmu.ac.uk/",
  },
  {
    country: "GB",
    name: "European School of Economics",
    url: "http://www.eselondon.ac.uk/",
  },
  {
    country: "GB",
    name: "Falmouth University",
    url: "http://www.falmouth.ac.uk/",
  },
  {
    country: "GB",
    name: "Glasgow Caledonian University",
    url: "http://www.gcal.ac.uk/",
  },
  {
    country: "GB",
    name: "Glasgow School of Art",
    url: "http://www.gsa.ac.uk/",
  },
  {
    country: "GB",
    name: "Goldsmiths College, University of London",
    url: "http://www.gold.ac.uk/",
  },
  {
    country: "GB",
    name: "Heriot-Watt University",
    url: "http://www.hw.ac.uk/",
  },
  {
    country: "GB",
    name: "Heythrop College, University of London",
    url: "http://www.heythrop.ac.uk/",
  },
  {
    country: "GB",
    name: "Huron University USA in London",
    url: "http://www.huron.ac.uk/",
  },
  {
    country: "GB",
    name: "ifs University College",
    url: "http://www.ifslearning.ac.uk/",
  },
  {
    country: "GB",
    name: "Imperial College London",
    url: "http://www.ic.ac.uk/",
  },
  {
    country: "GB",
    name: "Imperial College School of Medicine",
    url: "http://www.med.ic.ac.uk/",
  },
  {
    country: "GB",
    name: "Institue of Historical Research, University of London",
    url: "http://ihr.sas.ac.uk/",
  },
  {
    country: "GB",
    name: "Institute of Advanced Legal Studies, University of London",
    url: "http://www.sas.ac.uk/ials/",
  },
  {
    country: "GB",
    name: "Institute of Classical Studies, University of London",
    url: "http://www.sas.ac.uk/icls/",
  },
  {
    country: "GB",
    name: "Institute of Commonwealth Studies, University of London",
    url: "http://www.ihr.sas.ac.uk/ics/",
  },
  {
    country: "GB",
    name: "Institute of Education, University of London",
    url: "http://www.ioe.ac.uk/",
  },
  {
    country: "GB",
    name: "Institute of Germanic Studies, University of London",
    url: "http://www.sas.ac.uk/igs/",
  },
  {
    country: "GB",
    name: "Institute of Latin American Studies, University of London",
    url: "http://www.sas.ac.uk/ilas/",
  },
  {
    country: "GB",
    name: "International Centre for Isclamic Science",
    url: "http://www.kolieh.com/",
  },
  {
    country: "GB",
    name: "International Colleges of Islamic Science",
    url: "http://www.islamiccolleges.com/",
  },
  {
    country: "GB",
    name: "Keele University",
    url: "http://www.keele.ac.uk/",
  },
  {
    country: "GB",
    name: "King's College London, University of London",
    url: "http://www.kcl.ac.uk/",
  },
  {
    country: "GB",
    name: "Kingston University",
    url: "http://www.kingston.ac.uk/",
  },
  {
    country: "GB",
    name: "Leeds Metropolitan University",
    url: "http://www.lmu.ac.uk/",
  },
  {
    country: "GB",
    name: "Liverpool Hope University College",
    url: "http://www.hope.ac.uk/",
  },
  {
    country: "GB",
    name: "Liverpool John Moores University",
    url: "http://www.livjm.ac.uk/",
  },
  {
    country: "GB",
    name: "London Business School",
    url: "http://www.lbs.lon.ac.uk/",
  },
  {
    country: "GB",
    name: "London College of Science & Technology",
    url: "http://www.lcst.ac/",
  },
  {
    country: "GB",
    name: "London Guildhall University",
    url: "http://www.lgu.ac.uk/",
  },
  {
    country: "GB",
    name: "London Institute of Management and Technology",
    url: "http://www.limt.co.uk/",
  },
  {
    country: "GB",
    name: "London Metropolitan University",
    url: "http://www.londonmet.ac.uk/",
  },
  {
    country: "GB",
    name: "London School of Business & Finance",
    url: "http://www.lsbf.org.uk/",
  },
  {
    country: "GB",
    name: "London School of Economics and Political Science, University of London",
    url: "http://www.lse.ac.uk/",
  },
  {
    country: "GB",
    name: "London School of Hygiene & Tropical Medicine, University of London",
    url: "http://www.lshtm.ac.uk/",
  },
  {
    country: "GB",
    name: "London School of Jewish Studies",
    url: "http://www.brijnet.org/lsjs/",
  },
  {
    country: "GB",
    name: "Loughborough University",
    url: "http://www.lboro.ac.uk/",
  },
  {
    country: "GB",
    name: "Middlesex University",
    url: "http://www.mdx.ac.uk/",
  },
  {
    country: "GB",
    name: "Napier University",
    url: "http://www.napier.ac.uk/",
  },
  {
    country: "GB",
    name: "Newport International University",
    url: "http://www.niu.org.uk/",
  },
  {
    country: "GB",
    name: "Nottingham Trent University",
    url: "http://www.ntu.ac.uk/",
  },
  {
    country: "GB",
    name: "Open University",
    url: "http://www.open.ac.uk/",
  },
  {
    country: "GB",
    name: "Oxford Brookes University",
    url: "http://www.brookes.ac.uk/",
  },
  {
    country: "GB",
    name: "Queen Mary and Westfield College, University of London",
    url: "http://www.qmw.ac.uk/",
  },
  {
    country: "GB",
    name: "Richmond University - The American International University in London",
    url: "http://www.richmond.ac.uk/",
  },
  {
    country: "GB",
    name: "Roehampton University of Surrey",
    url: "http://www.roehampton.ac.uk/",
  },
  {
    country: "GB",
    name: "Royal Academy of Music, University of London",
    url: "http://www.ram.ac.uk/",
  },
  {
    country: "GB",
    name: "Royal College of Art",
    url: "http://www.rca.ac.uk/",
  },
  {
    country: "GB",
    name: "Royal College of Music, University of London",
    url: "http://www.rcm.ac.uk/",
  },
  {
    country: "GB",
    name: "Royal Free Hospital School of Medicine, University of London",
    url: "http://www.rfhsm.ac.uk/",
  },
  {
    country: "GB",
    name: "Royal Holloway and Bedford New College",
    url: "http://www.rhbnc.ac.uk/",
  },
  {
    country: "GB",
    name: "Saint George's Hospital Medical School, University of London",
    url: "http://www.sghms.ac.uk/",
  },
  {
    country: "GB",
    name: "Schiller International University, London",
    url: "http://www.schillerlondon.ac.uk/",
  },
  {
    country: "GB",
    name: "School of Oriental and African Studies, University of London",
    url: "http://www.soas.ac.uk/",
  },
  {
    country: "GB",
    name: "School of Pharmacy, University of London",
    url: "http://www.ulsop.ac.uk/",
  },
  {
    country: "GB",
    name: "School of Slavonic and East European Studies, University of London",
    url: "http://www.ssees.ac.uk/",
  },
  {
    country: "GB",
    name: "Sheffield Hallam University",
    url: "http://www.shu.ac.uk/",
  },
  {
    country: "GB",
    name: "Sotheby´s Institute of Art - London",
    url: "http://www.sothebysinstitutelondon.com/",
  },
  {
    country: "GB",
    name: "Southampton Solent University",
    url: "http://www.solent.ac.uk/",
  },
  {
    country: "GB",
    name: "South Bank University",
    url: "http://www.sbu.ac.uk/",
  },
  {
    country: "GB",
    name: "Staffordshire University",
    url: "http://www.staffs.ac.uk/",
  },
  {
    country: "GB",
    name: "St.Patrick's International College, London",
    url: "http://www.st-patricks.ac.uk/",
  },
  {
    country: "GB",
    name: "Stratford College London",
    url: "http://www.sclondon.ac/",
  },
  {
    country: "GB",
    name: "Swansea Metropolitan University",
    url: "http://www.smu.ac.uk/",
  },
  {
    country: "GB",
    name: "Thames Valley University",
    url: "http://www.tvu.ac.uk/",
  },
  {
    country: "GB",
    name: "The American University in London",
    url: "http://www.aul.edu/",
  },
  {
    country: "GB",
    name: "The Manchester Metropolitan University",
    url: "http://www.mmu.ac.uk/",
  },
  {
    country: "GB",
    name: "The Queen's University Belfast",
    url: "http://www.qub.ac.uk/",
  },
  {
    country: "GB",
    name: "The Robert Gordon University",
    url: "http://www.rgu.ac.uk/",
  },
  {
    country: "GB",
    name: "Trinity College Carmarthen",
    url: "http://www.trinity-cm.ac.uk/",
  },
  {
    country: "GB",
    name: "Trinity College of Music",
    url: "http://www.tcm.ac.uk/",
  },
  {
    country: "GB",
    name: "United Medical and Dental Schools, University of London",
    url: "http://www.umds.ac.uk/",
  },
  {
    country: "GB",
    name: "University Campus Suffolk",
    url: "http://www.ucs.ac.uk/",
  },
  {
    country: "GB",
    name: "University College London, University of London",
    url: "http://www.ucl.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Aberdeen",
    url: "http://www.abdn.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Abertay Dundee",
    url: "http://www.abertay.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Bath",
    url: "http://www.bath.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Bedfordshire",
    url: "http://www.beds.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Birmingham",
    url: "http://www.bham.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Bolton",
    url: "http://www.bolton.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Bradford",
    url: "http://www.brad.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Brighton",
    url: "http://www.bton.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Bristol",
    url: "http://www.bris.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Buckingham",
    url: "http://www.buck.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Cambridge",
    url: "http://www.cam.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Central Lancashire",
    url: "http://www.uclan.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Chester",
    url: "http://www.chester.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Derby",
    url: "http://www.derby.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Dundee",
    url: "http://www.dundee.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Durham",
    url: "http://www.dur.ac.uk/",
  },
  {
    country: "GB",
    name: "University of East Anglia",
    url: "http://www.uea.ac.uk/",
  },
  {
    country: "GB",
    name: "University of East London",
    url: "http://www.uel.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Edinburgh",
    url: "http://www.ed.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Essex",
    url: "http://www.essex.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Exeter",
    url: "http://www.ex.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Glamorgan",
    url: "http://www.glam.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Glasgow",
    url: "http://www.gla.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Gloucestershire",
    url: "http://www.glos.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Greenwich",
    url: "http://www.gre.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Halifax, Birmingham Campus",
    url: "http://www.halifaxuni.ac/",
  },
  {
    country: "GB",
    name: "University of Hertfordshire",
    url: "http://www.herts.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Huddersfield",
    url: "http://www.hud.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Hull",
    url: "http://www.hull.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Kent at Canterbury",
    url: "http://www.ukc.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Lancaster",
    url: "http://www.lancs.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Leeds",
    url: "http://www.leeds.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Leicester",
    url: "http://www.le.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Lincoln",
    url: "http://www.lincoln.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Liverpool",
    url: "http://www.liv.ac.uk/",
  },
  {
    country: "GB",
    name: "University of London",
    url: "http://www.lon.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Manchester",
    url: "http://www.man.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Newcastle-upon-Tyne",
    url: "http://www.ncl.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Northampton",
    url: "http://www.northampton.ac.uk/",
  },
  {
    country: "GB",
    name: "University of North London",
    url: "http://www.unl.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Northumbria at Newcastle",
    url: "http://www.unn.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Nottingham",
    url: "http://www.nottingham.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Oxford",
    url: "http://www.ox.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Paisley",
    url: "http://www.paisley.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Plymouth",
    url: "http://www.plymouth.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Portsmouth",
    url: "http://www.port.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Reading",
    url: "http://www.rdg.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Salford",
    url: "http://www.salford.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Sheffield",
    url: "http://www.shef.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Southampton",
    url: "http://www.soton.ac.uk/",
  },
  {
    country: "GB",
    name: "University of St. Andrews",
    url: "http://www.st-and.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Stirling",
    url: "http://www.stir.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Strathclyde",
    url: "http://www.strath.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Sunderland",
    url: "http://www.sunderland.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Surrey",
    url: "http://www.surrey.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Sussex",
    url: "http://www.sussex.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Teesside",
    url: "http://www.tees.ac.uk/",
  },
  {
    country: "GB",
    name: "University of the West of England, Bristol",
    url: "http://www.uwe.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Ulster",
    url: "http://www.ulst.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Wales",
    url: "http://www.wales.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Wales, Aberystwyth",
    url: "http://www.aber.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Wales, Bangor",
    url: "http://www.bangor.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Wales College of Medicine",
    url: "http://www.uwcm.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Wales Institute, Cardiff",
    url: "http://www.uwic.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Wales, Lampeter",
    url: "http://www.lamp.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Wales, Newport",
    url: "http://www.newport.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Wales, Swansea",
    url: "http://www.swan.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Warwick",
    url: "http://www.warwick.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Westminster",
    url: "http://www.westminster.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Wolverhampton",
    url: "http://www.wlv.ac.uk/",
  },
  {
    country: "GB",
    name: "University of Worcester",
    url: "http://www.worc.ac.uk/",
  },
  {
    country: "GB",
    name: "University of York",
    url: "http://www.york.ac.uk/",
  },
  {
    country: "GB",
    name: "Warburg Institute, University of London",
    url: "http://www.sas.ac.uk/warburg/",
  },
  {
    country: "GB",
    name: "Warnborough University",
    url: "http://www.warnborough.edu/",
  },
  {
    country: "GB",
    name: "William Gilbert College",
    url: "http://www.williamgilbert.co.uk/",
  },
  {
    country: "GB",
    name: "Wimbledon School of Art",
    url: "http://www.wimbledon.ac.uk/",
  },
  {
    country: "GD",
    name: "St. George's University",
    url: "http://www.sgu.edu/",
  },
  {
    country: "GE",
    name: "Agricultural University of Georgia",
    url: "http://www.agruni.edu.ge/",
  },
  {
    country: "GE",
    name: "Akaki Tsereteli State University",
    url: "http://www.atsu.edu.ge/",
  },
  {
    country: "GE",
    name: "Caucasus University",
    url: "http://www.cu.edu.ge/",
  },
  {
    country: "GE",
    name: "Free University of Tbilisi",
    url: "http://www.freeuni.ge/",
  },
  {
    country: "GE",
    name: "Georgian Technical University",
    url: "http://www.gtu.edu.ge/",
  },
  {
    country: "GE",
    name: "Ilia Chavchavadze State University",
    url: "http://www.iliauni.edu.ge/",
  },
  {
    country: "GE",
    name: "International Blacksea University",
    url: "http://www.ibsu.edu.ge/",
  },
  {
    country: "GE",
    name: "St. Andrew Georgian University",
    url: "http://www.sangu.ge/",
  },
  {
    country: "GE",
    name: "Tbilisi State Medical University",
    url: "http://www.tsmu.edu/",
  },
  {
    country: "GE",
    name: "Tbilisi State University",
    url: "http://www.tsu.edu.ge/",
  },
  {
    country: "GE",
    name: "Tbilisi Teaching University",
    url: "http://www.tbuniver.edu.ge/",
  },
  {
    country: "GE",
    name: "University of Grigol Robakidze",
    url: "http://www.gruni.edu.ge/",
  },
  {
    country: "GF",
    name: "Université des Antilles et de la Guyane",
    url: "http://www.univ-ag.fr/",
  },
  {
    country: "GH",
    name: "Accra College Of Medicine",
    url: "http://www.acm.edu.gh/",
  },
  {
    country: "GH",
    name: "Accra Polytechnic",
    url: "http://www.accrapolytechnic.edu.gh/",
  },
  {
    country: "GH",
    name: "Akrofi-Christaller Institute of Theeology, Mission and  Culture",
    url: "http://www.acighana.org/",
  },
  {
    country: "GH",
    name: "All Nations University College",
    url: "http://www.anuc.edu.gh/",
  },
  {
    country: "GH",
    name: "Ashesi University",
    url: "http://www.ashesi.edu.gh/",
  },
  {
    country: "GH",
    name: "Cape Coast Polytechnic",
    url: "http://www.cpoly.edu.gh/",
  },
  {
    country: "GH",
    name: "Central University College",
    url: "http://www.central.edu.gh/",
  },
  {
    country: "GH",
    name: "Family Health University College",
    url: "http://www.fhu.edu.gh/",
  },
  {
    country: "GH",
    name: "Ghana Christian University College",
    url: "http://www.ghanacu.org/",
  },
  {
    country: "GH",
    name: "Ghana Institute of Management and Public Administration (GIMPA)",
    url: "http://www.gimpa.edu.gh/",
  },
  {
    country: "GH",
    name: "Ghana Telecom University College",
    url: "http://www.gtuc.edu.gh/",
  },
  {
    country: "GH",
    name: "Ho Polytechnic",
    url: "http://www.hopoly.edu.gh/",
  },
  {
    country: "GH",
    name: "Islamic University College",
    url: "http://www.iug.edu.gh/",
  },
  {
    country: "GH",
    name: "Koforidua Polytechnic",
    url: "http://www.koforiduapoly.edu.gh/",
  },
  {
    country: "GH",
    name: "Kumasi Polytechnic",
    url: "http://www.kpoly.edu.gh/",
  },
  {
    country: "GH",
    name: "Kwame Nkrumah University of Science and Technology",
    url: "http://www.knust.edu.gh/",
  },
  {
    country: "GH",
    name: "Lancaster University Ghana",
    url: "http://www.lancaster.edu.gh/",
  },
  {
    country: "GH",
    name: "Pan African Christian University College",
    url: "http://www.pacuc.edu.gh/",
  },
  // {
  //   country: "GH",
  //   name: "Prempeh College",
  //   url: "http://www.prempeh-college.com/",
  // },
  {
    country: "GH",
    name: "Presbyterian University College",
    url: "http://www.presbyuniversity.edu.gh/",
  },
  {
    country: "GH",
    name: "Regent University College of Science and Technology",
    url: "http://www.regentghana.net/",
  },
  {
    country: "GH",
    name: "Takoradi Polytechnic",
    url: "http://www.tpoly.edu.gh/",
  },
  {
    country: "GH",
    name: "Trinity Theological Seminary",
    url: "http://www.trinity.edu.gh/",
  },
  {
    country: "GH",
    name: "University for Development Studies",
    url: "http://www.uds.edu.gh/",
  },
  {
    country: "GH",
    name: "University of Cape Coast",
    url: "http://www.ucc.edu.gh/",
  },
  {
    country: "GH",
    name: "University of Education, Winneba",
    url: "http://www.uew.edu.gh/",
  },
  {
    country: "GH",
    name: "University of Ghana",
    url: "http://www.ug.edu.gh/",
  },
  {
    country: "GH",
    name: "University of Health and Allied Sciences",
    url: "http://www.uhas.edu.gh/",
  },
  {
    country: "GH",
    name: "Valley View University",
    url: "http://www.vvu.edu.gh/",
  },
  {
    country: "GL",
    name: "University of Greenland",
    url: "http://www.ilisimatusarfik.gl/",
  },
  {
    country: "GM",
    name: "American International University West Africa",
    url: "http://www.aiu.edu.gm/",
  },
  {
    country: "GM",
    name: "EUCLID University",
    url: "http://www.euclid.int/",
  },
  {
    country: "GM",
    name: "University of the Gambia",
    url: "http://www.utg.edu.gm/",
  },
  {
    country: "GN",
    name: "Université Gamal Abdel Nasser de Conakry",
    url: "http://www.uganc.org/",
  },
  {
    country: "GN",
    name: "Université Julius Nyerere Kankan",
    url: "http://www.ujnk.org/",
  },
  {
    country: "GN",
    name: "Université Kofi Annan",
    url: "http://www.univ-kag.org/",
  },
  {
    country: "GN",
    name: "Université Thierno Amadou Diallo",
    url: "http://www.utad-petel-edu.org/",
  },
  {
    country: "GP",
    name: "Université des Antilles et de la Guyane",
    url: "http://www.univ-ag.fr/",
  },
  {
    country: "GQ",
    name: "Universidad Nacional de Guinea Ecuatorial",
    url: "http://www.unge.gq/",
  },
  {
    country: "GR",
    name: "Aegean University",
    url: "http://www.aegean.gr/",
  },
  {
    country: "GR",
    name: "Agricultural University of Athens",
    url: "http://www.aua.gr/",
  },
  {
    country: "GR",
    name: "American College of Greece",
    url: "http://www.acg.gr/",
  },
  {
    country: "GR",
    name: "American College of Thessaloniki",
    url: "http://www.act.edu/",
  },
  {
    country: "GR",
    name: "Aristotle University of Thessaloniki",
    url: "http://www.auth.gr/",
  },
  {
    country: "GR",
    name: "Athens Graduate School of Management (AGSM)",
    url: "http://www.agsm.gr/",
  },
  {
    country: "GR",
    name: "Athens Laboratory of Business Administration (ALBA)",
    url: "http://www.alba.edu.gr/",
  },
  {
    country: "GR",
    name: "Athens School of Fine Arts",
    url: "http://www.asfa.gr/",
  },
  {
    country: "GR",
    name: "Athens University of Economics and Business",
    url: "http://www.aueb.gr/",
  },
  {
    country: "GR",
    name: "City University Athens",
    url: "http://www.cityu.gr/",
  },
  {
    country: "GR",
    name: "DEI Bachelor & Master Degrees",
    url: "http://www.dei.edu.gr/",
  },
  {
    country: "GR",
    name: "Dimocritus University of Thrace",
    url: "http://www.duth.gr/",
  },
  {
    country: "GR",
    name: "European University, Athens Campus",
    url: "http://www.euruni.edu/",
  },
  {
    country: "GR",
    name: "Harokopio University",
    url: "http://www.forthnet.gr/harokopio/",
  },
  {
    country: "GR",
    name: "Hellenic Army Academy",
    url: "http://www.sse.gr/",
  },
  {
    country: "GR",
    name: "Hellenic Open University",
    url: "http://www.eap.gr/",
  },
  {
    country: "GR",
    name: "Institute of Management Development - Ohrid",
    url: "http://www.imd.edu.gr/",
  },
  {
    country: "GR",
    name: "Institute of Management & Enteurpreneurship of South East Europe",
    url: "http://www.imese.gr/",
  },
  {
    country: "GR",
    name: "International Hellenic University",
    url: "http://www.ihu.edu.gr/",
  },
  {
    country: "GR",
    name: "Ionian University Corfu",
    url: "http://www.uion.edu.gr/",
  },
  {
    country: "GR",
    name: "National Technical University of Athens",
    url: "http://www.ntua.gr/",
  },
  {
    country: "GR",
    name: "Panteios University of Economics and Political Sciences Athens",
    url: "http://www.panteion.gr/",
  },
  {
    country: "GR",
    name: "Technical University of Crete",
    url: "http://www.tuc.gr/",
  },
  {
    country: "GR",
    name: "Technological Education Institute of Athens",
    url: "http://www.teiath.gr/",
  },
  {
    country: "GR",
    name: "Technological Education Institute of Epiros",
    url: "http://www.teiep.gr/",
  },
  {
    country: "GR",
    name: "Technological Education Institute of Halkida, Euboea",
    url: "http://www.teihal.gr/",
  },
  {
    country: "GR",
    name: "Technological Education Institute of Heraklion",
    url: "http://www.teiher.gr/",
  },
  {
    country: "GR",
    name: "Technological Education Institute of Kalamata",
    url: "http://www.teikal.gr/",
  },
  {
    country: "GR",
    name: "Technological Education Institute of Kavala",
    url: "http://www.teikav.edu.gr/",
  },
  {
    country: "GR",
    name: "Technological Education Institute of Kozani",
    url: "http://www.teikoz.gr/",
  },
  {
    country: "GR",
    name: "Technological Education Institute of Lamia",
    url: "http://www.teilam.gr/",
  },
  {
    country: "GR",
    name: "Technological Education Institute of Larissa",
    url: "http://www.teilar.gr/",
  },
  {
    country: "GR",
    name: "Technological Education Institute of Mesologgi",
    url: "http://www.teimes.gr/",
  },
  {
    country: "GR",
    name: "Technological Education Institute of Patras",
    url: "http://www.teipat.gr/",
  },
  {
    country: "GR",
    name: "Technological Education Institute of Piraeus",
    url: "http://www.teipir.gr/",
  },
  {
    country: "GR",
    name: "Technological Education Institute of Serres",
    url: "http://www.teiser.gr/",
  },
  {
    country: "GR",
    name: "Technological Education Institute of Thessaloniki",
    url: "http://www.teithe.gr/",
  },
  {
    country: "GR",
    name: "University of Athens",
    url: "http://www.uoa.gr/",
  },
  {
    country: "GR",
    name: "University of Central Greece",
    url: "http://www.ucg.gr/",
  },
  {
    country: "GR",
    name: "University of Crete",
    url: "http://www.uoc.gr/",
  },
  {
    country: "GR",
    name: "University of Indianapolis in Athens",
    url: "http://www.uindy.edu.gr/",
  },
  {
    country: "GR",
    name: "University of Ioannina",
    url: "http://www.uoi.gr/",
  },
  {
    country: "GR",
    name: "University of LaVerne in Athens",
    url: "http://www.laverne.edu.gr/",
  },
  {
    country: "GR",
    name: "University of Macedonia",
    url: "http://www.uom.gr/",
  },
  {
    country: "GR",
    name: "University of Patras",
    url: "http://www.upatras.gr/",
  },
  {
    country: "GR",
    name: "University of Peloponnese",
    url: "http://www.uop.gr/",
  },
  {
    country: "GR",
    name: "University of Piraeus",
    url: "http://www.unipi.gr/",
  },
  {
    country: "GR",
    name: "University of Thessaly",
    url: "http://www.uth.gr/",
  },
  {
    country: "GR",
    name: "University of Western Macedonia",
    url: "http://www.uowm.gr/",
  },
  {
    country: "GR",
    name: "Yorker International University, Athens",
    url: "http://www.nyuniversity.net/",
  },
  {
    country: "GT",
    name: "Centro Universitario Ciudad Vieja",
    url: "http://www.cucv.edu.gt/",
  },
  {
    country: "GT",
    name: "Centro Universitario de Occidente",
    url: "http://www.cunoc.edu.gt/",
  },
  {
    country: "GT",
    name: "Universidad del Istmo",
    url: "http://www.unis.edu.gt/",
  },
  {
    country: "GT",
    name: "Universidad del Valle de Guatemala",
    url: "http://www.uvg.edu.gt/",
  },
  {
    country: "GT",
    name: "Universidad de San Carlos de Guatemala",
    url: "http://www.usac.edu.gt/",
  },
  {
    country: "GT",
    name: "Universidad Francisco Marroquín",
    url: "http://www.ufm.edu.gt/",
  },
  {
    country: "GT",
    name: "Universidad Galileo",
    url: "http://www.galileo.edu/",
  },
  {
    country: "GT",
    name: "Universidad Mariano Gálvez",
    url: "http://www.umg.edu.gt/",
  },
  {
    country: "GT",
    name: "Universidad Panamericana",
    url: "http://www.upana.edu.gt/",
  },
  {
    country: "GT",
    name: "Universidad Rafael Landívar",
    url: "http://www.url.edu.gt/",
  },
  {
    country: "GT",
    name: "Universidad Rural de Guatemala",
    url: "http://www.urural.edu.gt/",
  },
  {
    country: "GU",
    name: "University of Guam",
    url: "http://www.uog.edu/",
  },
  {
    country: "GY",
    name: "Gemsville Technical University",
    url: "http://www.gemsvilleuniversity.com/",
  },
  {
    country: "GY",
    name: "Greenheart Medical School",
    url: "http://www.greenheartmed.org/",
  },
  {
    country: "GY",
    name: "Texila American University",
    url: "http://www.tauedu.org/",
  },
  {
    country: "GY",
    name: "University of Guyana",
    url: "http://www.uog.edu.gy/",
  },
  {
    country: "HK",
    name: "Chinese University of Hong Kong",
    url: "http://www.cuhk.hk/",
  },
  {
    country: "HK",
    name: "Chu Hai College",
    url: "http://www.chuhai.edu.hk/",
  },
  {
    country: "HK",
    name: "City University of Hong Kong",
    url: "http://www.cityu.edu.hk/",
  },
  {
    country: "HK",
    name: "Hong Kong Academy for Performing Arts",
    url: "http://www.hkapa.edu/",
  },
  {
    country: "HK",
    name: "Hong Kong Baptist University",
    url: "http://www.hkbu.edu.hk/",
  },
  {
    country: "HK",
    name: "Hong Kong Institute of Education",
    url: "http://www.ied.edu.hk/",
  },
  {
    country: "HK",
    name: "Hong Kong Polytechnic University",
    url: "http://www.polyu.edu.hk/",
  },
  {
    country: "HK",
    name: "Hong Kong Shue Yan College",
    url: "http://www.hksyc.edu/",
  },
  {
    country: "HK",
    name: "Hong Kong University of Science and Technology",
    url: "http://www.ust.hk/",
  },
  {
    country: "HK",
    name: "Lingnan University",
    url: "http://www.ln.edu.hk/",
  },
  {
    country: "HK",
    name: "Open University of Hong Kong",
    url: "http://www.ouhk.edu.hk/",
  },
  {
    country: "HK",
    name: "University of Hong Kong",
    url: "http://www.hku.hk/",
  },
  {
    country: "HN",
    name: "Escuela Agricola Panamericana Zamorano",
    url: "http://www.zamorano.edu/",
  },
  {
    country: "HN",
    name: "Universidad Católica de Honduras",
    url: "http://www.unicah.edu/",
  },
  {
    country: "HN",
    name: "Universidad de San Pedro Sula",
    url: "http://www.usps.edu.hn/",
  },
  {
    country: "HN",
    name: "Universidad José Cecilio del Valle",
    url: "http://www.ujcv.edu.hn/",
  },
  {
    country: "HN",
    name: "Universidad Metropolitana de Honduras",
    url: "http://www.unimetro.edu.hn/",
  },
  {
    country: "HN",
    name: "Universidad Nacional Autónoma de Honduras",
    url: "http://www.unah.edu.hn/",
  },
  {
    country: "HN",
    name: 'Universidad Pedagógica Nacional "Francisco Morazán',
    url: "http://www.upnfm.edu.hn/",
  },
  {
    country: "HN",
    name: "Universidad Tecnológica Centroamericana",
    url: "http://www.unitec.edu/",
  },
  {
    country: "HN",
    name: "Universidad Tecnológica de Honduras",
    url: "http://www.uth.hn/",
  },
  {
    country: "HR",
    name: "University of Dubrovnik",
    url: "http://www.unidu.hr/",
  },
  {
    country: "HR",
    name: "University of Osijek",
    url: "http://www.unios.hr/",
  },
  {
    country: "HR",
    name: "University of Pula",
    url: "http://www.unipu.hr/",
  },
  {
    country: "HR",
    name: "University of Rijeka",
    url: "http://www.uniri.hr/",
  },
  {
    country: "HR",
    name: "University of Split",
    url: "http://www.unist.hr/",
  },
  {
    country: "HR",
    name: "University of Zadar",
    url: "http://www.unizd.hr/",
  },
  {
    country: "HR",
    name: "University of Zagreb",
    url: "http://www.unizg.hr/",
  },
  {
    country: "HR",
    name: "Zagreb School of Economics and Management",
    url: "http://www.zsem.hr/",
  },
  {
    country: "HT",
    name: "American University of the Caribbean",
    url: "http://www.auchaiti.org/",
  },
  {
    country: "HT",
    name: "Université Chrétienne du Nord d'Haiti",
    url: "http://www.ucnh.org/",
  },
  {
    country: "HT",
    name: "Université d'Etat d'Haiti",
    url: "http://www.ueh.edu.ht/",
  },
  {
    country: "HT",
    name: "Université Épiscopale d'Haiti",
    url: "http://www.uneph.org/",
  },
  {
    country: "HT",
    name: "Université Notre Dame d'Haïti",
    url: "http://www.undh.org/",
  },
  {
    country: "HT",
    name: "Université Quisqueya",
    url: "http://www.uniq.edu/",
  },
  {
    country: "HU",
    name: "Academy of Drama and Film",
    url: "http://www.filmacademy.hu/",
  },
  {
    country: "HU",
    name: "Andrassy Gyula German Speaking University Budapest",
    url: "http://www.andrassyuni.hu/",
  },
  {
    country: "HU",
    name: "Avicenna International College",
    url: "http://www.avicenna.hu/",
  },
  {
    country: "HU",
    name: "Budapest Buddhist University",
    url: "http://www.tkbf.hu/",
  },
  {
    country: "HU",
    name: "Budapest University of Economic Sciences and Public Administration",
    url: "http://www.bke.hu/",
  },
  {
    country: "HU",
    name: "Central European University",
    url: "http://www.ceu.hu/",
  },
  {
    country: "HU",
    name: "College of Dunaujvaros",
    url: "http://www.duf.hu/",
  },
  {
    country: "HU",
    name: "Dániel Berzsenyi Teacher Training College",
    url: "http://www.bdtf.hu/",
  },
  {
    country: "HU",
    name: "Debrecen University of Agricultural Sciences",
    url: "http://www.agr.unideb.hu/",
  },
  {
    country: "HU",
    name: "Eötvös Lorand University",
    url: "http://www.elte.hu/",
  },
  {
    country: "HU",
    name: "Esztergom Theological College",
    url: "http://www.ehf.hu/",
  },
  {
    country: "HU",
    name: "Haynal Imre University of Health Sciences Budapest",
    url: "http://www.hiete.hu/",
  },
  {
    country: "HU",
    name: "Hungarian Academy of Craft and Design",
    url: "http://www.mif.hu/",
  },
  {
    country: "HU",
    name: "Hungarian Academy of Fine Arts Budapest",
    url: "http://www.arts7.hu/",
  },
  {
    country: "HU",
    name: "Hungarian University of Physical Education",
    url: "http://www.hupe.hu/",
  },
  {
    country: "HU",
    name: "Janus Pannonius University",
    url: "http://www.jpte.hu/",
  },
  {
    country: "HU",
    name: "Károl Gáspár University of the Reformed Church",
    url: "http://www.kgre.hu/",
  },
  {
    country: "HU",
    name: "Kodolanyi Janos University College",
    url: "http://www.kodolanyi.hu/",
  },
  {
    country: "HU",
    name: "Kossuth Lajos University",
    url: "http://www.lib.klte.hu/",
  },
  {
    country: "HU",
    name: "Liszt Ferenc Academy of Music Budapest",
    url: "http://www.lfze.hu/",
  },
  {
    country: "HU",
    name: "Pázmány Péter Catholic University",
    url: "http://www.ppke.hu/",
  },
  {
    country: "HU",
    name: "Reformed Theological Academy of Debrecen",
    url: "http://www.drk.hu/",
  },
  {
    country: "HU",
    name: "Séchenyi István University",
    url: "http://www.sze.hu/",
  },
  {
    country: "HU",
    name: "Semmelweis University of Medical Sciences",
    url: "http://www.sote.hu/",
  },
  {
    country: "HU",
    name: "Szent István University",
    url: "http://www.szie.hu/",
  },
  {
    country: "HU",
    name: "Technical University of Budapest",
    url: "http://www.bme.hu/",
  },
  {
    country: "HU",
    name: "University Medical School of Debrecen",
    url: "http://www.dote.hu/",
  },
  {
    country: "HU",
    name: "University Medical School of Pécs",
    url: "http://www.pote.hu/",
  },
  {
    country: "HU",
    name: "University of Debrecen",
    url: "http://www.unideb.hu/",
  },
  {
    country: "HU",
    name: "University of Horticulture and Food Industry",
    url: "http://www.kee.hu/",
  },
  {
    country: "HU",
    name: "University of Miskolc",
    url: "http://www.uni-miskolc.hu/",
  },
  {
    country: "HU",
    name: "University of Pannonia",
    url: "http://www.uni-pannon.hu/",
  },
  {
    country: "HU",
    name: "University of Pecs",
    url: "http://www.pte.hu/",
  },
  {
    country: "HU",
    name: "University of Sopron",
    url: "http://www.efe.hu/",
  },
  {
    country: "HU",
    name: "University of Szeged",
    url: "http://www.u-szeged.hu/",
  },
  {
    country: "HU",
    name: "University of Veterinary Science",
    url: "http://www.univet.hu/",
  },
  {
    country: "HU",
    name: "University of West Hungary",
    url: "http://www.nyme.hu/",
  },
  {
    country: "ID",
    name: "Abfi Institute Perbanas Jakarta",
    url: "http://www.perbanasinstitute.ac.id/",
  },
  {
    country: "ID",
    name: "Binus University",
    url: "http://www.binus.ac.id/",
  },
  {
    country: "ID",
    name: "Bogor Agricultural University",
    url: "http://www.ipb.ac.id/",
  },
  {
    country: "ID",
    name: "Institut Sains dan Teknologi Al Kamal",
    url: "http://www.ista.ac.id/",
  },
  {
    country: "ID",
    name: "Institut Sains & Teknologi Akprind",
    url: "http://www.akprind.ac.id/",
  },
  {
    country: "ID",
    name: "Institut Teknologi Adhi Tama Surabaya",
    url: "http://www.itats.ac.id/",
  },
  {
    country: "ID",
    name: "Institut Teknologi Bandung",
    url: "http://www.itb.ac.id/",
  },
  {
    country: "ID",
    name: "Institut Teknologi Sepuluh Nopember",
    url: "http://www.its.ac.id/",
  },
  {
    country: "ID",
    name: "Institut Teknologi Telkom",
    url: "http://www.stttelkom.ac.id/",
  },
  {
    country: "ID",
    name: "Politeknik Negeri Bandung",
    url: "http://www.polban.ac.id/",
  },
  {
    country: "ID",
    name: "Politeknik Negeri Jakarta",
    url: "http://www.pnj.ac.id/",
  },
  {
    country: "ID",
    name: "Politeknik Negeri Lhokseumawe",
    url: "http://www.pnl.ac.id/",
  },
  {
    country: "ID",
    name: "Politeknik Negeri Malang",
    url: "http://www.poltek-malang.ac.id/",
  },
  {
    country: "ID",
    name: "Politeknik Negeri Padang",
    url: "http://www.polinpdg.ac.id/",
  },
  {
    country: "ID",
    name: "Politeknik Negeri Pontianak",
    url: "http://www.polnep.ac.id/",
  },
  {
    country: "ID",
    name: "Politeknik Negeri Sambas",
    url: "http://www.poltesa.ac.id/",
  },
  {
    country: "ID",
    name: "Politeknik Negeri Semarang",
    url: "http://www.polines.ac.id/",
  },
  {
    country: "ID",
    name: "Politeknik Pos Indonesia",
    url: "http://www.poltekpos.ac.id/",
  },
  {
    country: "ID",
    name: "Prasetiya Mulya Business School",
    url: "http://www.pmbs.ac.id/",
  },
  {
    country: "ID",
    name: "Sekolah Tinggi Akuntansi Negara (STAN)",
    url: "http://www.stan.ac.id/",
  },
  {
    country: "ID",
    name: "Stie Perbanas Surabaya",
    url: "http://www.perbanas.ac.id/",
  },
  {
    country: "ID",
    name: "STMIK AMIKOM Yogyakarta",
    url: "http://www.amikom.ac.id/",
  },
  {
    country: "ID",
    name: "STMIK Sinar Nusantara",
    url: "http://www.sinus.ac.id/",
  },
  {
    country: "ID",
    name: "Swiss German University",
    url: "http://www.sgu.ac.id/",
  },
  {
    country: "ID",
    name: "Trisakti Institute of Tourism",
    url: "http://www.stptrisakti.ac.id/",
  },
  {
    country: "ID",
    name: "Unitomo Surabaya",
    url: "http://www.unitomo.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas 17 Agustus 1945 Banyuwangi",
    url: "http://www.untag-banyuwangi.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas 17 Agustus 1945 Cirebon",
    url: "http://www.untagcirebon.info/",
  },
  {
    country: "ID",
    name: "Universitas 17 Agustus 1945 Jakarta",
    url: "http://www.untag-jkt.org/",
  },
  {
    country: "ID",
    name: "Universitas 17 Agustus 1945 Samarinda",
    url: "http://www.untag-smd.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas 17 Agustus 1945 Semarang",
    url: "http://www.untagsmg.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas 17 Agustus 1945 Surabaya",
    url: "http://www.untag.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Advent Indonesia",
    url: "http://www.unai.edu/",
  },
  {
    country: "ID",
    name: "Universitas Ahmad Dahlan",
    url: "http://www.uad.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Airlangga",
    url: "http://www.unair.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Amir Hamzah",
    url: "http://www.unhamzah.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Andalas",
    url: "http://www.unand.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Atma Jaya Yogyakarta",
    url: "http://www.uajy.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Bakrie",
    url: "http://www.bakrie.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Bengkulu",
    url: "http://www.unib.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Bhayangkara Jakarta Raya",
    url: "http://www.ubharajaya.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Bhayangkara Surabaya",
    url: "http://www.ubhara.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Bojonegoro",
    url: "http://www.unigoro.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Brawijaya",
    url: "http://www.ub.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Bunda Mulia Jakarta",
    url: "http://www.ubm.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Bung Hatta",
    url: "http://www.bunghatta.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Cenderawasih",
    url: "http://www.uncen.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Darma Agung",
    url: "http://www.uda.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Darma Persada",
    url: "http://www.unsada.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Dian Nuswantoro",
    url: "http://www.dinus.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Diponegoro",
    url: "http://www.undip.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Dr. R. Moestopo",
    url: "http://www.moestopo.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Gadjah Mada",
    url: "http://www.ugm.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Gajayana Malang",
    url: "http://www.unigamalang.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Gunadarma",
    url: "http://www.gunadarma.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Haluoleo",
    url: "http://www.unhalu.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Hasanuddin",
    url: "http://www.unhas.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas HKBP Nommensen",
    url: "http://www.nommensen.org/",
  },
  {
    country: "ID",
    name: "Universitas Ibn Chaldun Jakarta",
    url: "http://www.ibnuchaldun.com/",
  },
  {
    country: "ID",
    name: "Universitas Ibn Khaldun Bogor",
    url: "http://www.uika-bogor.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Indonesia",
    url: "http://www.ui.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Indonusa Esa Unggul",
    url: "http://www.indonusa.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Islam Bandung",
    url: "http://www.unisba.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Islam Indonesia",
    url: "http://www.uii.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Islam Jakarta",
    url: "http://www.uinjkt.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Islam Malang",
    url: "http://www.unisma.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Islam Nusantara",
    url: "http://www.uninus.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Islam Riau",
    url: "http://www.uir.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Islam Sultan Agung",
    url: "http://www.unissula.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Islam Sumatera Utara",
    url: "http://www.uisu.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Islam Syekh-Yusuf",
    url: "http://www.unistangerang.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Jambi",
    url: "http://www.unja.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Janabadra",
    url: "http://www.janabadra.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Jayabaya",
    url: "http://www.jayabaya.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Jember",
    url: "http://www.unej.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Jenderal Achmad Yani",
    url: "http://www.unjani.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Jenderal Soedirman",
    url: "http://www.unsoed.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Katolik Indonesia Atma Jaya",
    url: "http://www.atmajaya.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Katolik Parahyangan",
    url: "http://www.unpar.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Katolik Soegijapranoto",
    url: "http://www.unika.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Katolik Widya Karya",
    url: "http://www.widyakarya.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Katolik Widya Manadala",
    url: "http://www.wima.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Kediri",
    url: "http://www.unik-kediri.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Khairun",
    url: "http://www.unkhair.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Klabat",
    url: "http://www.tagnet.org/unklab/",
  },
  {
    country: "ID",
    name: "Universitas Komputer Indonesia",
    url: "http://www.unikom.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Krisnadwipayana",
    url: "http://www.unkris.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Kristen Duta Wacana",
    url: "http://www.ukdw.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Kristen Indonesia",
    url: "http://www.uki.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Kristen Indonesia Tomohon",
    url: "http://www.ukit-tomohon.org/",
  },
  {
    country: "ID",
    name: "Universitas Kristen Krida Wacana",
    url: "http://www.ukrida.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Kristen Maranatha",
    url: "http://www.maranatha.edu/",
  },
  {
    country: "ID",
    name: "Universitas Kristen Petra",
    url: "http://www.petra.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Kristen Satya Wacana",
    url: "http://www.uksw.edu/",
  },
  {
    country: "ID",
    name: "Universitas Lambung Mangkurat",
    url: "http://www.unlam.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Lampung",
    url: "http://www.unila.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Lancang Kuning",
    url: "http://unilak.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Ma Chung",
    url: "http://www.machung.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Madura",
    url: "http://www.unira-pmk.net/",
  },
  {
    country: "ID",
    name: "Universitas Mahasaraswati Denpasar",
    url: "http://www.unmas.org/",
  },
  {
    country: "ID",
    name: "Universitas Mahendradatta",
    url: "http://www.mahendradatta.org/",
  },
  {
    country: "ID",
    name: "Universitas Mataram",
    url: "http://www.unram.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Mercu Buana",
    url: "http://www.mercubuana.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Merdeka Madiun",
    url: "http://www.unmer-madiun.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Merdeka Malang",
    url: "http://www.unmer.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Methodist Indonesia",
    url: "http://www.umi-mdn.edu/",
  },
  {
    country: "ID",
    name: "Universitas Muhammadiyah Jakarta",
    url: "http://www.umj.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Muhammadiyah Jember",
    url: "http://www.unmuhjember.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Muhammadiyah Magelang",
    url: "http://www.ummgl.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Muhammadiyah Makassar",
    url: "http://www.unismuh.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Muhammadiyah Malang",
    url: "http://www.umm.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Muhammadiyah Mataram",
    url: "http://www.unmuhmataram.com/",
  },
  {
    country: "ID",
    name: "Universitas Muhammadiyah Sumatra Utara",
    url: "http://www.umsu.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Muhammadiyah Surakarta",
    url: "http://www.ums.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Muhammadiyah Yogyakarta",
    url: "http://www.umy.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Mulawarman",
    url: "http://www.unmul.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Muria Kudus",
    url: "http://www.umk.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Muslim Indonesia",
    url: "http://www.umi.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Narotama",
    url: "http://www.narotama.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Nasional Jakarta",
    url: "http://www.unas.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Nasional Pasim",
    url: "http://www.pasim.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Negeri Gorontalo",
    url: "http://www.ung.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Negeri Jakarta",
    url: "http://www.unj.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Negeri Makassar",
    url: "http://www.unm.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Negeri Malang",
    url: "http://www.malang.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Negeri Manado",
    url: "http://www.unima.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Negeri Medan",
    url: "http://www.unimed.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Negeri Padang",
    url: "http://www.unp.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Negeri Semarang",
    url: "http://www.unnes.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Negeri Surabaya",
    url: "http://www.unesa.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Negeri Yogyakarta",
    url: "http://www.uny.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Ngurah Rai",
    url: "http://www.unr.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Nusa Cendana",
    url: "http://www.undana.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Padjadjaran",
    url: "http://www.unpad.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Pakuan",
    url: "http://www.unpak.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Palangka Raya",
    url: "http://www.upr.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Pancasakti Tegal",
    url: "http://www.upstegal.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Pancasila",
    url: "http://www.univpancasila.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Paramadina Mulya",
    url: "http://www.paramadina.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Pasundan",
    url: "http://www.unpas.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Pattimura",
    url: "http://www.unpatti.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Pekalongan",
    url: "http://www.unikal.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Pelita Harapan",
    url: "http://www.uph.edu/",
  },
  {
    country: "ID",
    name: 'Universitas Pembangunan Nasional "Veteran" East Java',
    url: "http://www.upnjatim.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Pembangunan Panca Budi",
    url: "http://www.pancabudi.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Pendidikan Indonesia",
    url: "http://www.upi.edu/",
  },
  {
    country: "ID",
    name: "Universitas Persada Indonesia Y.A.I",
    url: "http://www.yai.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Pesantren Darul Ulum Jombang",
    url: "http://www.unipdu.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Proklamasi 45",
    url: "http://www.up45.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Putera Batam",
    url: "http://www.puterabatam.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Riau",
    url: "http://www.unri.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Sam Ratulangi",
    url: "http://www.unsrat.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Sanata Dharma",
    url: "http://www.usd.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Sarjanawiyata Tamansiswa",
    url: "http://www.sarjanawiyata.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Sebelas Maret",
    url: "http://www.uns.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Semarang",
    url: "http://www.usm.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Siliwangi",
    url: "http://www.unsil.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Simalungun",
    url: "http://www.usi.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Slamet Riyadi Surakarta",
    url: "http://www.unisri.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Sriwijaya",
    url: "http://www.unsri.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Sultan Ageng Tirtayasa",
    url: "http://www.untirta.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Sumatera Utara",
    url: "http://www.usu.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Surabaya",
    url: "http://www.ubaya.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Swadaya Gunung Djati",
    url: "http://www.unswagati-crb.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Syiah Kuala",
    url: "http://www.unsyiah.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Tadulako",
    url: "http://www.untad.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Tanjungpura",
    url: "http://www.untan.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Tarumanagara",
    url: "http://www.untar.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Terbuka Indonesia",
    url: "http://www.ut.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Tidar Magelang",
    url: "http://www.utm.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Trilogi",
    url: "http://www.universitas-trilogi.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Trisakti",
    url: "http://www.trisakti.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Tunas Pembangunan",
    url: "http://www.utp.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Udayana",
    url: "http://www.unud.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Widya Gama Malang",
    url: "http://www.widyagama.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Widyatama",
    url: "http://www.widyatama.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Wijaya Kusuma Purwokerto",
    url: "http://www.unwiku.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Wijaya Kusuma Surabaya",
    url: "http://www.wijayakusumasby.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Wisnuwardhana",
    url: "http://www.wisnuwardhana.ac.id/",
  },
  {
    country: "ID",
    name: "Universitas Yarsi",
    url: "http://www.yarsi.ac.id/",
  },
  {
    country: "IE",
    name: "Athlone Institute of Technology",
    url: "http://www.ait.ie/",
  },
  {
    country: "IE",
    name: "Carlow Institute of Technology",
    url: "http://www.itcarlow.ie/",
  },
  {
    country: "IE",
    name: "Cork Institute of Technology",
    url: "http://www.cit.ie/",
  },
  {
    country: "IE",
    name: "Dublin City University",
    url: "http://www.dcu.ie/",
  },
  {
    country: "IE",
    name: "Dublin Institute for Advanced Studies",
    url: "http://www.dias.ie/",
  },
  {
    country: "IE",
    name: "Dublin Institute of Technology",
    url: "http://www.dit.ie/",
  },
  {
    country: "IE",
    name: "Galway Mayo Institute of Technology",
    url: "http://www.gmit.ie/",
  },
  {
    country: "IE",
    name: "Griffith College",
    url: "http://www.gcd.ie/",
  },
  {
    country: "IE",
    name: "Institue of Technology, Tralee",
    url: "http://www.ittralee.ie/",
  },
  {
    country: "IE",
    name: "Irish International University (Distance Education)",
    url: "http://www.iiuedu.ie/",
  },
  {
    country: "IE",
    name: "LSB College",
    url: "http://www.lsb.ie/",
  },
  {
    country: "IE",
    name: "National College of Ireland",
    url: "http://www.ncirl.ie/",
  },
  {
    country: "IE",
    name: "National University of Ireland",
    url: "http://www.nui.ie/",
  },
  {
    country: "IE",
    name: "National University of Ireland, Galway",
    url: "http://www.ucg.ie/",
  },
  {
    country: "IE",
    name: "National University of Ireland, Maynooth",
    url: "http://www.may.ie/",
  },
  {
    country: "IE",
    name: "Royal College of Physicians of Ireland",
    url: "http://www.rcpi.ie/",
  },
  {
    country: "IE",
    name: "Royal College of Surgeons",
    url: "http://www.rcsi.ie/",
  },
  {
    country: "IE",
    name: "Shannon College of Hotel Management",
    url: "http://www.shannoncollege.com/",
  },
  {
    country: "IE",
    name: "University College Cork",
    url: "http://www.ucc.ie/",
  },
  {
    country: "IE",
    name: "University College Dublin",
    url: "http://www.ucd.ie/",
  },
  {
    country: "IE",
    name: "University of Dublin, Trinity College",
    url: "http://www.tcd.ie/",
  },
  {
    country: "IE",
    name: "University of Limerick",
    url: "http://www.ul.ie/",
  },
  {
    country: "IE",
    name: "Warnborough University",
    url: "http://www.warnborough.edu/",
  },
  {
    country: "IE",
    name: "Waterford Institute Of Technology",
    url: "http://www.wit.ie/",
  },
  {
    country: "IL",
    name: "Acdemic Center for Law and Business",
    url: "http://www.clb.ac.il/",
  },
  {
    country: "IL",
    name: "Afeka Tel Aviv Academic College of Engineering",
    url: "http://www.afeka.ac.il/",
  },
  {
    country: "IL",
    name: "Ariel University Center of Samaria",
    url: "http://www.ariel.ac.il/",
  },
  {
    country: "IL",
    name: "Ashkelon Academic College",
    url: "http://www.ash-college.ac.il/",
  },
  {
    country: "IL",
    name: "Bar-Ilan University",
    url: "http://www.biu.ac.il/",
  },
  {
    country: "IL",
    name: "Ben-Gurion University of the Negev",
    url: "http://www.bgu.ac.il/",
  },
  {
    country: "IL",
    name: "Bezalel Academy of Art and Design",
    url: "http://www.bezalel.ac.il/",
  },
  {
    country: "IL",
    name: "College of Management",
    url: "http://www.colman.ac.il/",
  },
  {
    country: "IL",
    name: "Emeq Yizrael College",
    url: "http://www.yvc.ac.il/",
  },
  {
    country: "IL",
    name: "Galillee College",
    url: "http://www.galilcol.ac.il/",
  },
  {
    country: "IL",
    name: "Hebrew University of Jerusalem",
    url: "http://www.huji.ac.il/",
  },
  {
    country: "IL",
    name: "Jerusalem University College",
    url: "http://www.juc.edu/",
  },
  {
    country: "IL",
    name: "Open University of Israel",
    url: "http://www.openu.ac.il/",
  },
  {
    country: "IL",
    name: "Russell Berrie Nanotechnology Institute",
    url: "http://rbni.technion.ac.il/",
  },
  {
    country: "IL",
    name: "Sami Shamoon College of Engineering",
    url: "http://www.sce.ac.il/",
  },
  {
    country: "IL",
    name: "Shenkar School of Engineering & Design",
    url: "http://www.shenkar.ac.il/",
  },
  {
    country: "IL",
    name: "Technion - Israel Institute of Technology",
    url: "http://www.technion.ac.il/",
  },
  {
    country: "IL",
    name: "Tel Aviv University",
    url: "http://www.tau.ac.il/",
  },
  {
    country: "IL",
    name: "The Interdisciplinary Center Herzliya",
    url: "http://www.idc.ac.il/",
  },
  {
    country: "IL",
    name: "University of Haifa",
    url: "http://www.haifa.ac.il/",
  },
  {
    country: "IL",
    name: "Weizmann Institute of Science",
    url: "http://www.weizmann.ac.il/",
  },
  {
    country: "IL",
    name: "Western Galilee College",
    url: "http://www.wgalil.ac.il/",
  },
  {
    country: "IN",
    name: "Acharya Nagarjuna University",
    url: "http://www.nagarjunauniversity.ac.in/",
  },
  {
    country: "IN",
    name: "Acharya Ranga Agricultural University",
    url: "http://www.angrau.net/",
  },
  {
    country: "IN",
    name: "Ahmedabad University",
    url: "http://www.ahduni.edu.in/",
  },
  {
    country: "IN",
    name: "AISECT University",
    url: "http://www.aisectuniversity.ac.in/",
  },
  {
    country: "IN",
    name: "Alagappa University",
    url: "http://www.alagappauniversity.ac.in/",
  },
  {
    country: "IN",
    name: "Aligarh Muslim University",
    url: "http://www.amu.ac.in/",
  },
  {
    country: "IN",
    name: "Allahabad Agricultural Institute, Deemed University",
    url: "http://www.aaidu.org/",
  },
  {
    country: "IN",
    name: "Allahabad University",
    url: "http://www.alldunivpio.org/",
  },
  {
    country: "IN",
    name: "All India Institute of Medical Sciences",
    url: "http://www.aiims.ac.in/",
  },
  {
    country: "IN",
    name: "Amity University",
    url: "http://www.amity.edu/",
  },
  {
    country: "IN",
    name: "Amravati University",
    url: "http://www.amtuni.com/",
  },
  {
    country: "IN",
    name: "Amrita Vishwa Vidyapeetham (Deemed University)",
    url: "http://www.amrita.edu/",
  },
  {
    country: "IN",
    name: "Andhra University",
    url: "http://www.andhrauniversity.info/",
  },
  {
    country: "IN",
    name: "Annamalai University",
    url: "http://www.annamalaiuniversity.ac.in/",
  },
  {
    country: "IN",
    name: "Anna University",
    url: "http://www.annauniv.edu/",
  },
  {
    country: "IN",
    name: "Anna University of Technology, Tirunelveli",
    url: "http://www.annauniv.edu.in/",
  },
  {
    country: "IN",
    name: "ARYA Institute of Engineering & Technology",
    url: "http://www.aryainstitutejpr.com/",
  },
  {
    country: "IN",
    name: "Asian Business School",
    url: "http://www.abs.edu.in/",
  },
  {
    country: "IN",
    name: "Assam Agricultural University",
    url: "http://www.aau.ac.in/",
  },
  {
    country: "IN",
    name: "Assam University",
    url: "http://www.assamuniversity.nic.in/",
  },
  {
    country: "IN",
    name: "Avinashilingam Institute for Home Science and Higher Education for Women",
    url: "http://www.tnuniv.ac.in/adu/",
  },
  {
    country: "IN",
    name: "Awadhesh Pratap Singh University",
    url: "http://www.apsurewa.nic.in/",
  },
  {
    country: "IN",
    name: "Babasaheb Bhimrao Ambedkar University",
    url: "http://www.bbauindia.org/",
  },
  {
    country: "IN",
    name: "Banaras Hindu University",
    url: "http://www.bhu.ac.in/",
  },
  {
    country: "IN",
    name: "Banasthali University",
    url: "http://www.banasthali.org/",
  },
  {
    country: "IN",
    name: "Bangalore University",
    url: "http://bengaluruuniversity.com/",
  },
  {
    country: "IN",
    name: "Bankura University",
    url: "http://www.bankurauniv.com/",
  },
  {
    country: "IN",
    name: "Barkatullah University",
    url: "http://www.bubhopal.nic.in/",
  },
  {
    country: "IN",
    name: "Bengal Engineering College",
    url: "http://www.becs.ac.in/",
  },
  {
    country: "IN",
    name: "Berhampur University",
    url: "http://www.bamu.nic.in/",
  },
  {
    country: "IN",
    name: "Bhagwant University",
    url: "http://www.bhagwantuniversity.com/",
  },
  {
    country: "IN",
    name: "Bharathiar University",
    url: "http://www.bharathi.ernet.in/",
  },
  {
    country: "IN",
    name: "Bharathidasan University",
    url: "http://www.bdu.ernet.in/",
  },
  {
    country: "IN",
    name: "Bharath Institue Of Higher Education & Research",
    url: "http://www.bharathuniv.com/",
  },
  {
    country: "IN",
    name: "Bharati Vidyapeeth University",
    url: "http://www.bharatividyapeeth.edu/",
  },
  {
    country: "IN",
    name: "Bhavnagar University",
    url: "http://www.bhavuni.edu/",
  },
  {
    country: "IN",
    name: "Bhupendra Narayan Mandal University",
    url: "http://www.bnmu.in/",
  },
  {
    country: "IN",
    name: "Bidhan Chandra Agricultural University",
    url: "http://www.bckv.edu.in/",
  },
  {
    country: "IN",
    name: "Biju Pattnaik University of Technology",
    url: "http://www.bput.org/",
  },
  {
    country: "IN",
    name: "Birla Institute of Technology and Science",
    url: "http://www.bits-pilani.ac.in/",
  },
  {
    country: "IN",
    name: "Birla Institute of Technology, Ranchi",
    url: "http://bitmesraranchi.org/",
  },
  {
    country: "IN",
    name: "Birsa Agricultural University",
    url: "http://www.bau.nic.in/",
  },
  {
    country: "IN",
    name: "B. R. Ambedkar Bihar University",
    url: "http://www.brabu.net/",
  },
  {
    country: "IN",
    name: "B. S. Abdur Rahman University",
    url: "http://www.bsauniv.ac.in/",
  },
  {
    country: "IN",
    name: "Bundelkhand University",
    url: "http://www.bundelkhanduniversity.org/",
  },
  {
    country: "IN",
    name: "Burdwan University",
    url: "http://www.buruniv.ac.in/",
  },
  {
    country: "IN",
    name: "CCS Haryana Agricultural University",
    url: "http://hau.ernet.in/",
  },
  {
    country: "IN",
    name: "Center for Entrepreneurship and Small Business Management",
    url: "http://www.cesbm.ac.in/",
  },
  {
    country: "IN",
    name: "Central Agricultural University",
    url: "http://www.cau.org.in/",
  },
  {
    country: "IN",
    name: "Central Institute of English and Foreign Languages",
    url: "http://www.ciefl.org/",
  },
  {
    country: "IN",
    name: "Central Institute of Fisheries Education",
    url: "http://www.cife.edu.in/",
  },
  {
    country: "IN",
    name: "Central Institute of Higher Tibetan Studies",
    url: "http://www.cihts.ac.in/",
  },
  {
    country: "IN",
    name: "Chandra Shekhar Azad University of Agriculture and Technology",
    url: "http://www.csauk.ac.in/",
  },
  {
    country: "IN",
    name: "Chaudhary Charan Singh Haryana Agricultural University",
    url: "http://www.hau.ernet.in/",
  },
  {
    country: "IN",
    name: "Chaudhary Charan Singh University",
    url: "http://www.ccsuniversity.ac.in/",
  },
  {
    country: "IN",
    name: "Ch. Charan Singh University",
    url: "http://www.ccsuniversity.org/",
  },
  {
    country: "IN",
    name: "Chhatrapati Shahu Ji Maharaj University",
    url: "http://www.kanpuruniversity.org/",
  },
  {
    country: "IN",
    name: "Christ University",
    url: "http://www.christuniversity.in/",
  },
  {
    country: "IN",
    name: "CMJ University",
    url: "http://www.cmjuniversity.edu.in/",
  },
  {
    country: "IN",
    name: "Cochin University of Science and Technology",
    url: "http://www.cusat.ac.in/",
  },
  {
    country: "IN",
    name: "Darul Huda Islamic University",
    url: "http://www.darulhuda.com/",
  },
  {
    country: "IN",
    name: "Dav University",
    url: "http://www.davuniversity.org/",
  },
  {
    country: "IN",
    name: "Dayalbagh Educational Institute",
    url: "http://www.dei.ac.in/",
  },
  {
    country: "IN",
    name: "Deccan College Postgraduate and Research Institute",
    url: "http://www.deccancollegepune.ac.in/",
  },
  {
    country: "IN",
    name: "Deen Dayal Upadhyay Gorakhpur University",
    url: "http://www.ddugu.edu.in/",
  },
  {
    country: "IN",
    name: "Delhi College of Engineering (DCE)",
    url: "http://www.dceonline.net/",
  },
  {
    country: "IN",
    name: "Devi Ahilya University of Indore",
    url: "http://www.davv.ac.in/",
  },
  {
    country: "IN",
    name: "Dhirubhai Ambani Institute of Information and Communication Technology",
    url: "http://www.da-iict.org/",
  },
  {
    country: "IN",
    name: "Dibrugarh University",
    url: "http://www.dibru.ernet.in/",
  },
  {
    country: "IN",
    name: "Dravidian University",
    url: "http://www.dravidianuniversity.ac.in/",
  },
  {
    country: "IN",
    name: "Dr. Babasaheb Ambedkar Marathwada Universtiy",
    url: "http://www.bamu.net/",
  },
  {
    country: "IN",
    name: "Dr. Babasaheb Ambedkar Technological University",
    url: "http://www.dbatechuni.org/",
  },
  {
    country: "IN",
    name: "Dr. Bhim Rao Abdekar University",
    url: "http://www.brauagra.com/",
  },
  {
    country: "IN",
    name: "Dr. B.R. Ambedkar Open University",
    url: "http://www.braou.ac.in/",
  },
  {
    country: "IN",
    name: "Dr. C.V. Raman University",
    url: "http://www.cvru.ac.in/",
  },
  {
    country: "IN",
    name: "Dr. D.Y. Patil University",
    url: "http://www.dypatiluniversity.org/",
  },
  {
    country: "IN",
    name: "Dr. Hari Singh Gour University, formerly University of Sagar",
    url: "http://www.sagaruniversity.nic.in/",
  },
  {
    country: "IN",
    name: "Dr. Panjabrao Deshmukh Krishi Vidyapeeth",
    url: "http://www.pdkv.ac.in/",
  },
  {
    country: "IN",
    name: "Dr. Ram Manohar Lohia Avadh University",
    url: "http://www.rmlau.ac.in/",
  },
  {
    country: "IN",
    name: "Dr. YS Parmar University of Horticulture and Forestry",
    url: "http://www.ysparmaruniversity.org/",
  },
  {
    country: "IN",
    name: "Eiilm University",
    url: "http://www.eiilmuniversity.ac.in/",
  },
  {
    country: "IN",
    name: "Engineering Colleges in Tamil Nadu",
    url: "http://www.sonatech.ac.in/",
  },
  {
    country: "IN",
    name: "Fakir Mohan University",
    url: "http://www.fmuniversity.nic.in/",
  },
  {
    country: "IN",
    name: "Fatima mata national college kollam kerala",
    url: "http://www.fatimacollege.net/",
  },
  {
    country: "IN",
    name: "First Global University to teaching Jainism",
    url: "http://www.jainuniversity.org/",
  },
  {
    country: "IN",
    name: "Forest Research Institute Dehradun",
    url: "http://fri.icfre.gov.in/",
  },
  {
    country: "IN",
    name: "Gandhigram Rural Institute",
    url: "http://www.tnuniv.ac.in/gri/",
  },
  {
    country: "IN",
    name: "Gandhi Institute of Technology and Managment",
    url: "http://www.gitam.edu/",
  },
  {
    country: "IN",
    name: "Gauhati University",
    url: "http://www.gu.nic.in/",
  },
  {
    country: "IN",
    name: "Global Open University , Nagaland",
    url: "http://www.government.ind.in/",
  },
  {
    country: "IN",
    name: "Goa University",
    url: "http://www.unigoa.ac.in/",
  },
  {
    country: "IN",
    name: "Gokhale Institute of Politics and Economics",
    url: "http://www.gipe.ernet.in/",
  },
  {
    country: "IN",
    name: "Govind Ballabh Pant University of Agriculture and Technology",
    url: "http://www.gbpuat.ac.in/",
  },
  {
    country: "IN",
    name: "Gujarat Ayurved University",
    url: "http://www.ayurveduniversity.com/",
  },
  {
    country: "IN",
    name: "Gujarat Technological University Ahmedabad",
    url: "http://www.gtu.ac.in/",
  },
  {
    country: "IN",
    name: "Gujarat University Ahmedabad",
    url: "http://www.gujaratuniversity.org.in/",
  },
  {
    country: "IN",
    name: "Gulbarga University",
    url: "http://www.gulbargauniversity.kar.nic.in/",
  },
  {
    country: "IN",
    name: "Guru Ghasidas University",
    url: "http://www.ggu.ac.in/",
  },
  {
    country: "IN",
    name: "Guru Gobind Singh Indraprastha University",
    url: "http://www.ipu.ac.in/",
  },
  {
    country: "IN",
    name: "Guru Jambeshwar University",
    url: "http://www.gju.ernet.in/",
  },
  {
    country: "IN",
    name: "Gurukula Kangri University",
    url: "http://www.gkvharidwar.org/",
  },
  {
    country: "IN",
    name: "Gurukul University",
    url: "http://www.gurukuluniversity.org/",
  },
  {
    country: "IN",
    name: "Guru Nanak Dev University",
    url: "http://www.gnduonline.org/",
  },
  {
    country: "IN",
    name: "Hemchandracharay North Gujarat University",
    url: "http://www.ngu.ac.in/",
  },
  {
    country: "IN",
    name: "Hemwati Nandan Bahuguna Garhwal University",
    url: "http://www.hnbgu.ac.in/",
  },
  {
    country: "IN",
    name: "Hidayatullah National Law University, Raipur",
    url: "http://www.hnluraipur.com/",
  },
  {
    country: "IN",
    name: "Himachal Pradesh Agricultural University",
    url: "http://hillagric.ernet.in/",
  },
  {
    country: "IN",
    name: "Himachal Pradesh University",
    url: "http://hpuniv.nic.in/",
  },
  {
    country: "IN",
    name: "IASE (Institute of Advanced Studies) Deemed University",
    url: "http://www.iaseduniv.org/",
  },
  {
    country: "IN",
    name: "ICFAI University",
    url: "http://www.icfai.org/",
  },
  {
    country: "IN",
    name: "Indian Agricultural Research Institute",
    url: "http://www.iari.ernet.in/",
  },
  {
    country: "IN",
    name: "Indian Board of Alternative Medicine",
    url: "http://www.altmedworld.net/",
  },
  {
    country: "IN",
    name: "Indian Institute of Information Technology",
    url: "http://www.iiita.com/",
  },
  {
    country: "IN",
    name: "Indian Institute of Information Technology and Management - Kerala",
    url: "http://www.iiitmk.ac.in/",
  },
  {
    country: "IN",
    name: "Indian Institute of Management, Tiruchirappalli",
    url: "http://www.iimtrichy.ac.in/",
  },
  {
    country: "IN",
    name: "Indian Institute of Science",
    url: "http://www.iisc.ernet.in/",
  },
  {
    country: "IN",
    name: "Indian Institute of Technology, Bombay",
    url: "http://www.iitb.ac.in/",
  },
  {
    country: "IN",
    name: "Indian Institute of Technology, Delhi",
    url: "http://www.iitd.ernet.in/",
  },
  {
    country: "IN",
    name: "Indian Institute of Technology, Guwahati",
    url: "http://www.iitg.ernet.in/",
  },
  {
    country: "IN",
    name: "Indian Institute of Technology, Hyderabad",
    url: "http://www.iith.ac.in/",
  },
  {
    country: "IN",
    name: "Indian Institute of Technology, Kanpur",
    url: "http://www.iitk.ac.in/",
  },
  {
    country: "IN",
    name: "Indian Institute of Technology, Kharagpur",
    url: "http://www.iitkgp.ernet.in/",
  },
  {
    country: "IN",
    name: "Indian Institute of Technology, Madras",
    url: "http://www.iitm.ac.in/",
  },
  {
    country: "IN",
    name: "Indian Institute of Technology, Roorkee",
    url: "http://www.iitr.ac.in/",
  },
  {
    country: "IN",
    name: "Indian Law Institue",
    url: "http://www.ilidelhi.org/",
  },
  {
    country: "IN",
    name: "Indian School of Business Management and Administration",
    url: "http://isbm.org.in/",
  },
  {
    country: "IN",
    name: "Indian School of Mines (ISM)",
    url: "http://www.ismdhanbad.ac.in/",
  },
  {
    country: "IN",
    name: "Indian Statistical Institute",
    url: "http://www.isical.ac.in/",
  },
  {
    country: "IN",
    name: "Indian University",
    url: "http://www.iur.ac.in/",
  },
  {
    country: "IN",
    name: "Indian Veterinary Research Institute",
    url: "http://www.ivri.nic.in/",
  },
  {
    country: "IN",
    name: "Indira Gandhi Agricultural University",
    url: "http://www.igau.edu.in/",
  },
  {
    country: "IN",
    name: "Indira Gandhi Institute of Development and Research",
    url: "http://www.igidr.ac.in/",
  },
  {
    country: "IN",
    name: "Indira Gandhi Institute of Medical Sciences",
    url: "http://www.igims.org/",
  },
  {
    country: "IN",
    name: "Indira Gandhi National Open University",
    url: "http://www.ignou.ac.in/",
  },
  {
    country: "IN",
    name: "Institute of Management and Technical Studies",
    url: "http://www.imtsinstitute.com/",
  },
  {
    country: "IN",
    name: "Institute of Technology and Management",
    url: "http://www.itmindia.edu/",
  },
  {
    country: "IN",
    name: "Integral University",
    url: "http://www.integraluniversity.ac.in/",
  },
  {
    country: "IN",
    name: "International Institute for Population Sciences",
    url: "http://www.iipsindia.org/",
  },
  {
    country: "IN",
    name: "Jadavpur University",
    url: "http://www.jadavpur.edu/",
  },
  {
    country: "IN",
    name: "Jagran Lakecity University",
    url: "http://www.jlu.edu.in/",
  },
  {
    country: "IN",
    name: "Jai Narayan Vyas University",
    url: "http://www.jnvu.edu.in/",
  },
  {
    country: "IN",
    name: "Jain University, Centre for Management Studies",
    url: "http://www.cms.ac.in/",
  },
  {
    country: "IN",
    name: "Jain Vishva Bharati Institute",
    url: "http://www.jvbi.ac.in/",
  },
  {
    country: "IN",
    name: "Jai Prakash University",
    url: "http://jpv.bih.nic.in/",
  },
  {
    country: "IN",
    name: "Jaipur National University",
    url: "http://www.jnujaipur.ac.in/",
  },
  {
    country: "IN",
    name: "Jamia Hamdard University",
    url: "http://www.jamiahamdard.edu/",
  },
  {
    country: "IN",
    name: "Jamia Millia Islamia University",
    url: "http://jmi.nic.in/",
  },
  {
    country: "IN",
    name: "Jawaharlal Nehru Agricultural University",
    url: "http://www.jnkvv.nic.in/",
  },
  {
    country: "IN",
    name: "Jawaharlal Nehru Centre for Advanced  Scientific Research",
    url: "http://www.jncasr.ac.in/",
  },
  {
    country: "IN",
    name: "Jawaharlal Nehru Technological University",
    url: "http://www.jntu.ac.in/",
  },
  {
    country: "IN",
    name: "Jawaharlal Nehru University",
    url: "http://www.jnu.ac.in/",
  },
  {
    country: "IN",
    name: "Jaypee Institute of Information Technology",
    url: "http://www.jiit.ac.in/",
  },
  {
    country: "IN",
    name: "Jiwaji University",
    url: "http://www.jiwaji.edu/",
  },
  {
    country: "IN",
    name: "Jodhpur National University",
    url: "http://www.jodhpurnationaluniversity.com/",
  },
  {
    country: "IN",
    name: "Kakatiya University",
    url: "http://www.kuwarangal.com/",
  },
  {
    country: "IN",
    name: "Kalinga University",
    url: "http://www.kalingauniversity.com/",
  },
  {
    country: "IN",
    name: "Kameshwar Singh Darbhanga Sanskrit University",
    url: "http://www.ksdsu.edu.in/",
  },
  {
    country: "IN",
    name: "Kamrah International Institute of Technology (KIIT)",
    url: "http://www.kiit.in/",
  },
  {
    country: "IN",
    name: "Kannada University",
    url: "http://www.kannadauniversity.org/",
  },
  {
    country: "IN",
    name: "Kannur University",
    url: "http://www.kannuruniversity.ac.in/",
  },
  {
    country: "IN",
    name: "Kanpur University",
    url: "http://www.kanpuruniversity.org/",
  },
  {
    country: "IN",
    name: "Karnataka State Law University",
    url: "http://www.kslu.ac.in/",
  },
  {
    country: "IN",
    name: "Karnataka State Open University",
    url: "http://www.karnatakastateopenuniversity.in/",
  },
  {
    country: "IN",
    name: "Karnatak University",
    url: "http://www.kud.ac.in/",
  },
  {
    country: "IN",
    name: "Karpagam University",
    url: "http://www.karpagamuniv.com/",
  },
  {
    country: "IN",
    name: "Karunya Institute Of Technology ( Deemed University)",
    url: "http://www.karunya.edu/",
  },
  {
    country: "IN",
    name: "Kerala Agricultural University",
    url: "http://www.kau.edu/",
  },
  {
    country: "IN",
    name: "Kerala University of Fisheries and Ocean Sciences",
    url: "http://www.kufos.ac.in/",
  },
  {
    country: "IN",
    name: "Kohinoor School of Hospitality Management",
    url: "http://kimi.kohinoor.ac.in/",
  },
  {
    country: "IN",
    name: "Konkan Agricultural University",
    url: "http://www.dbskkv.org/",
  },
  {
    country: "IN",
    name: "Krishna University",
    url: "http://www.krishnauniversity.ac.in/",
  },
  {
    country: "IN",
    name: "Kumaun University",
    url: "http://www.kumaununiversity.org/",
  },
  {
    country: "IN",
    name: "Kurukshetra University",
    url: "http://www.kukinfo.com/",
  },
  {
    country: "IN",
    name: "Kuvempu University",
    url: "http://www.kuvempu.ac.in/",
  },
  {
    country: "IN",
    name: "Lakshmibai National Institute of Physical Education",
    url: "http://www.lnipe.edu/",
  },
  {
    country: "IN",
    name: "Lalit Narayan Mithila University",
    url: "http://lnmu.bih.nic.in/",
  },
  {
    country: "IN",
    name: "L.D.College of Engineering",
    url: "http://www.ldceahd.org/",
  },
  {
    country: "IN",
    name: "Madhya Pradesh Bhoj (Open) University",
    url: "http://www.mpbou.org/",
  },
  {
    country: "IN",
    name: "Madurai Kamaraj University",
    url: "http://www.mkuhyd.com/",
  },
  {
    country: "IN",
    name: "Magadh University",
    url: "http://www.magadhuniversity.org/",
  },
  {
    country: "IN",
    name: "Maharaja Ganga Singh University, Bikaner",
    url: "http://www.mgsubikaner.ac.in/",
  },
  {
    country: "IN",
    name: "Maharaja Sayajirao University of Baroda",
    url: "http://www.msubaroda.ac.in/",
  },
  {
    country: "IN",
    name: "Maharishi Dayanand University, Rohtak (Haryana )",
    url: "http://www.mdurohtak.com/",
  },
  {
    country: "IN",
    name: "Maharshi Dayanand Sarswati University Ajmer",
    url: "http://www.mdsuajmer.ac.in/",
  },
  {
    country: "IN",
    name: "Mahatma Gandhi Chitrakut Gramoday University",
    url: "http://www.ruraluniversity-chitrakoot.org/",
  },
  {
    country: "IN",
    name: "Mahatma Gandhi Kashi Vidyapith",
    url: "http://www.mgkvp.ac.in/",
  },
  {
    country: "IN",
    name: "Mahatma Gandhi University, Kerala",
    url: "http://www.mgu.ac.in/",
  },
  {
    country: "IN",
    name: "Mahatma Gandhi University Meghalaya",
    url: "http://www.mgu.edu.in/",
  },
  {
    country: "IN",
    name: "Mahatma Jyotiba Phule Rohilkhand University Bareilly",
    url: "http://www.mjpru.ac.in/",
  },
  {
    country: "IN",
    name: "Mahatma Phule Agricultural University",
    url: "http://mpkv.mah.nic.in/",
  },
  {
    country: "IN",
    name: "Makanlal Chutur Vedi University",
    url: "http://www.mcu.ac.in/",
  },
  {
    country: "IN",
    name: "Makhanlal Chaturvedi National University of Journalism and Communication",
    url: "http://www.mcu.ac.in/",
  },
  {
    country: "IN",
    name: "Mangalore University",
    url: "http://www.mangaloreuniversity.ac.in/",
  },
  {
    country: "IN",
    name: "Manipal University",
    url: "http://www.manipal.edu/",
  },
  {
    country: "IN",
    name: "Manipur University",
    url: "http://www.manipur.nic.in/maniuniversity.htm",
  },
  {
    country: "IN",
    name: "Manonmaniam Sundaranar University",
    url: "http://www.tnuniv.ac.in/msu/",
  },
  {
    country: "IN",
    name: "Mar Athanasios College for Advanced Studies",
    url: "http://www.macfast.org/",
  },
  {
    country: "IN",
    name: "Marathwada Agricultural University",
    url: "http://mkv2.mah.nic.in/",
  },
  {
    country: "IN",
    name: "Mohan Lal Sukhadia University",
    url: "http://www.mlsu.ac.in/",
  },
  {
    country: "IN",
    name: "Mother Teresa Women's University",
    url: "http://www.tnuniv.ac.in/mteresa/",
  },
  {
    country: "IN",
    name: "Motilal Nehru National Institute Of Technology",
    url: "http://www.mnnit.ac.in/",
  },
  {
    country: "IN",
    name: "Moulana Azad national Urdu University",
    url: "http://www.manuu.ac.in/",
  },
  {
    country: "IN",
    name: "Nagaland University",
    url: "http://www.nagauniv.org.in/",
  },
  {
    country: "IN",
    name: "Nagpur University",
    url: "http://www.nagpur-university.com/",
  },
  {
    country: "IN",
    name: "Nalanda Open University",
    url: "http://www.nalandaopenuniversity.info/",
  },
  {
    country: "IN",
    name: "Narendra Deva University of Agriculture and Technology",
    url: "http://www.nduat.nic.in/",
  },
  {
    country: "IN",
    name: "Narsee Monjee Institute of Management Studies",
    url: "http://www.nmims.edu/",
  },
  {
    country: "IN",
    name: "National Dairy Research Institute",
    url: "http://ndri.nic.in/",
  },
  {
    country: "IN",
    name: "National Institute of Industrial Engineering",
    url: "http://www.nitie.edu/",
  },
  {
    country: "IN",
    name: "National Institute of Mental Health and Neuro Sciences",
    url: "http://www.nimhans.kar.nic.in/",
  },
  {
    country: "IN",
    name: "National Institute of Technology, Calicut",
    url: "http://www.nitc.ac.in/",
  },
  {
    country: "IN",
    name: "National Institute of Technology, Durgapur",
    url: "http://www.nitdgp.ac.in/",
  },
  {
    country: "IN",
    name: "National Institute of Technology, Jamshedpur",
    url: "http://www.nitjsr.ac.in/",
  },
  {
    country: "IN",
    name: "National Institute of Technology Kurukshetra",
    url: "http://www.nitkkr.net/",
  },
  {
    country: "IN",
    name: "National Institute of Technology, Rourkela",
    url: "http://www.nitrkl.ac.in/",
  },
  {
    country: "IN",
    name: "National Institute of Technology, Trichy",
    url: "http://www.nitt.edu/",
  },
  {
    country: "IN",
    name: "National Institute of Technology, Warangal",
    url: "http://www.nitw.ernet.in/",
  },
  {
    country: "IN",
    name: "National Law School of India University",
    url: "http://www.nls.ac.in/",
  },
  {
    country: "IN",
    name: "National Museum Institute of History of Art, Conservation and Museology",
    url: "http://www.nationalmuseumindia.org/nmi/index.htm",
  },
  {
    country: "IN",
    name: "National Sanskrit University",
    url: "http://www.sanskrit.nic.in/",
  },
  {
    country: "IN",
    name: "Netaji Subhas Inst of Technology",
    url: "http://www.nsit.ac.in/",
  },
  {
    country: "IN",
    name: "Nirma University",
    url: "http://www.nirmauni.ac.in/",
  },
  {
    country: "IN",
    name: "Nizam's Institute of Medical Sciences",
    url: "http://nims.ap.nic.in/",
  },
  {
    country: "IN",
    name: "North Bengal University",
    url: "http://www.nbu.ac.in/",
  },
  {
    country: "IN",
    name: "North Eastern Hill University",
    url: "http://www.nehu.ac.in/",
  },
  {
    country: "IN",
    name: "North Maharashtra University",
    url: "http://www.nmu.ac.in/",
  },
  {
    country: "IN",
    name: "Open International University for Alternative Medicines",
    url: "http://www.altmeduniversity.com/",
  },
  {
    country: "IN",
    name: "Orissa University of Agriculture and Technology",
    url: "http://bhub.ori.nic.in/ouat/",
  },
  {
    country: "IN",
    name: "Osmania University",
    url: "http://www.osmania.ac.in/",
  },
  {
    country: "IN",
    name: "Pandit Ravi Shankar Shukla University",
    url: "http://www.rsuniversity.com/",
  },
  {
    country: "IN",
    name: "Panjab University",
    url: "http://www.puchd.ac.in/",
  },
  {
    country: "IN",
    name: "Patna University",
    url: "http://puonline.bih.nic.in/",
  },
  {
    country: "IN",
    name: "Periyar University",
    url: "http://www.spuniv.org/",
  },
  {
    country: "IN",
    name: "Pes University",
    url: "http://www.pes.edu/",
  },
  {
    country: "IN",
    name: "Pondicherry University",
    url: "http://www.pondiuni.org/",
  },
  {
    country: "IN",
    name: "Postgraduate lnstitute of Medical Education and Research",
    url: "http://www.pgimer.nic.in/",
  },
  {
    country: "IN",
    name: "Potti Sreeramulu Telugu University",
    url: "http://www.teluguuniversity.ac.in/",
  },
  {
    country: "IN",
    name: "PSG College of Technology",
    url: "http://www.psgtech.edu/",
  },
  {
    country: "IN",
    name: "Punjab Agricultural University",
    url: "http://www.pau.edu/",
  },
  {
    country: "IN",
    name: "Punjab Engineering College Deemed University",
    url: "http://www.pec.ac.in/",
  },
  {
    country: "IN",
    name: "Punjabi University Patiala",
    url: "http://www.punjabiuniversity.ac.in/",
  },
  {
    country: "IN",
    name: "Punjab Technical University",
    url: "http://www.ptu.ac.in/",
  },
  {
    country: "IN",
    name: "Rabindra Bharati University",
    url: "http://www.rabindrabharatiuniversity.net/",
  },
  {
    country: "IN",
    name: "Rajasthan Agricultural University, Bikaner",
    url: "http://www.raubikaner.org/",
  },
  {
    country: "IN",
    name: "Rajasthan Technical University",
    url: "http://www.rtu.ac.in/",
  },
  {
    country: "IN",
    name: "Rajasthan Vidyapeeth University",
    url: "http://www.jrnrvu.org/",
  },
  {
    country: "IN",
    name: "Rajendra Agricultural University",
    url: "http://www.pusavarsity.org.in/",
  },
  {
    country: "IN",
    name: "Rajitlal Institute of Technology & Health Sciences (RITHS)",
    url: "http://www.riths.ac.in/",
  },
  {
    country: "IN",
    name: "Rajiv Gandhi Technical University (University of Technology of Madhya Pradesh)",
    url: "http://www.rgtu.net/",
  },
  {
    country: "IN",
    name: "Rajiv Gandhi University",
    url: "http://www.rgu.ac.in/",
  },
  {
    country: "IN",
    name: "Ranchi University",
    url: "http://www.ranchiuniversity.org.in/",
  },
  {
    country: "IN",
    name: "Rani Durgavati University",
    url: "http://www.rdunijbpin.org/",
  },
  {
    country: "IN",
    name: "RKDF University",
    url: "http://www.rkdf.ac.in/",
  },
  {
    country: "IN",
    name: "Sambalpur University",
    url: "http://www.sambalpuruniversitypgc.in/",
  },
  {
    country: "IN",
    name: "Sampurnanand Sanskrit University",
    url: "http://ssvv.up.nic.in/",
  },
  {
    country: "IN",
    name: "Sanjay Gandhi Postgraduate lnstitute of Medical Sciences",
    url: "http://www.sgpgi.ac.in/",
  },
  {
    country: "IN",
    name: "Sardarkrushinagar Dantiwada Agricultural University",
    url: "http://www.sdau.edu.in/",
  },
  {
    country: "IN",
    name: "Sardar Patel University",
    url: "http://www.spuvvn.edu/",
  },
  {
    country: "IN",
    name: "Sardar Vallabhai Patel University of Agriculture Amd Technology",
    url: "http://www.svbpuctm.org/",
  },
  {
    country: "IN",
    name: "SASTRA Deemed University",
    url: "http://www.sastra.edu/",
  },
  {
    country: "IN",
    name: "Sathyabama University",
    url: "http://www.sathyabamauniv.ac.in/",
  },
  {
    country: "IN",
    name: "Saurashtra University",
    url: "http://www.saurashtrauniversity.edu/",
  },
  {
    country: "IN",
    name: "School of Planning and Architecture",
    url: "http://www.spa.ac.in/",
  },
  {
    country: "IN",
    name: "Shanmugha Arts Science Technology and Research Academy",
    url: "http://www.sce.ac.in/",
  },
  {
    country: "IN",
    name: "Sher-E-Kashmir University of Agricultural Sciences and Technology",
    url: "http://www.skuastkashmir.ac.in/",
  },
  {
    country: "IN",
    name: "Shivaji University",
    url: "http://www.unishivaji.ac.in/",
  },
  {
    country: "IN",
    name: "Shobhit University, Meerut",
    url: "http://www.shobhituniversity.ac.in/",
  },
  {
    country: "IN",
    name: "Shreemati Nathibai Damodar Thackersey Women's University",
    url: "http://www.sndt.edu/",
  },
  {
    country: "IN",
    name: "Shridhar University",
    url: "http://www.shridharuniversity.ac.in/",
  },
  {
    country: "IN",
    name: "Shri Jagannath Sanskrit University",
    url: "http://www.sjsv.nic.in/",
  },
  {
    country: "IN",
    name: "Shri Lal Bahadur Shastri Rashtriya Sanskrit Vidyapeetha",
    url: "http://www.slbsrsv.ac.in/",
  },
  {
    country: "IN",
    name: "Shri Mata Vaishno Devi University",
    url: "http://www.smvdu.net.in/",
  },
  {
    country: "IN",
    name: "Sido Kanhu Murmu University",
    url: "http://www.skmu.edu.in/",
  },
  {
    country: "IN",
    name: "Sikha 'O' Anushandhan University",
    url: "http://www.soauniversity.ac.in/",
  },
  {
    country: "IN",
    name: "Sikkim Manipal University of Health, Medical and Technological Sciences",
    url: "http://www.smu.edu.in/",
  },
  {
    country: "IN",
    name: "Singhania University Rajasthan",
    url: "http://www.singhaniauniversity.co.in/",
  },
  {
    country: "IN",
    name: "SMK Fomra Institute of Technology",
    url: "http://www.smkfomra.net/",
  },
  {
    country: "IN",
    name: "Solapur University",
    url: "http://su.digitaluniversity.ac/",
  },
  {
    country: "IN",
    name: "South Asian University",
    url: "http://www.southasianuniversity.org/",
  },
  {
    country: "IN",
    name: "South Gujarat University",
    url: "http://www.sgu.ernet.in/",
  },
  {
    country: "IN",
    name: "Sree Chitra Tirunal Institute for Medical Sciences and Technology",
    url: "http://sctimst.ker.nic.in/",
  },
  {
    country: "IN",
    name: "Sree Rama Krishna Degree College",
    url: "http://www.srkdc.org/",
  },
  {
    country: "IN",
    name: "Sree Sankaracharya University of Sanskrit",
    url: "http://www.ssus.ac.in/",
  },
  {
    country: "IN",
    name: "Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya",
    url: "http://www.kanchiuniv.ac.in/",
  },
  {
    country: "IN",
    name: "Sri Krishnadevaraya University",
    url: "http://skuniv.ap.nic.in/",
  },
  {
    country: "IN",
    name: "Sri Padmavati Women's University",
    url: "http://www.padmavatiwomen-univ.org/",
  },
  {
    country: "IN",
    name: "Sri Ramachardra Medical College and Research Institute",
    url: "http://www.hostindia.com/srmc/",
  },
  {
    country: "IN",
    name: "Sri Sant Gajanan Maharaj College of Engineering",
    url: "http://www.ssgmce.org/",
  },
  {
    country: "IN",
    name: "Sri Sathya Sai Institute of Higher Learning",
    url: "http://www.srisathyasai.org.in/pages/instts/HighLearn.htm",
  },
  {
    country: "IN",
    name: "Sri Venkateswara Institute of Medical Sciences",
    url: "http://svimstpt.ap.nic.in/",
  },
  {
    country: "IN",
    name: "Sri Venkateswara University",
    url: "http://svuni.ap.nic.in/",
  },
  {
    country: "IN",
    name: "SRM Institute Of Science & Technology ( Deemed University)",
    url: "http://www.srmuniv.ac.in/",
  },
  {
    country: "IN",
    name: "Sunrise University Alwar",
    url: "http://www.sunriseuniversity.in/",
  },
  {
    country: "IN",
    name: "Swami Ramanand Teerth Marathwada University",
    url: "http://www.srtmun.org/",
  },
  {
    country: "IN",
    name: "Symbiosis International University",
    url: "http://www.siu.edu.in/",
  },
  {
    country: "IN",
    name: "Takhatmal Shrivallabh Homoeopathic Medical College",
    url: "http://www.tshmc.org/",
  },
  {
    country: "IN",
    name: "Tamil Nadu Agricultural University",
    url: "http://dbs.tn.nic.in/tnau/",
  },
  {
    country: "IN",
    name: "Tamil Nadu Dr. Ambedkar Law University",
    url: "http://www.tndalu.org/",
  },
  {
    country: "IN",
    name: "Tamil Nadu Dr. M.G.R. Medical University",
    url: "http://www.tnmmu.ac.in/",
  },
  {
    country: "IN",
    name: "Tamil Nadu Veterinary and Animal Sciences University",
    url: "http://www.tanuvas.com/",
  },
  {
    country: "IN",
    name: "Tamil University",
    url: "http://www.tamiluniversity.ac.in/",
  },
  {
    country: "IN",
    name: "Tata Institute of Social Sciences",
    url: "http://www.tiss.edu/",
  },
  {
    country: "IN",
    name: "Telugu University",
    url: "http://www.teluguuniversity.ac.in/",
  },
  {
    country: "IN",
    name: "Tezpur University",
    url: "http://www.tezu.ernet.in/",
  },
  {
    country: "IN",
    name: "Thapar Institute of Engineering and Technology",
    url: "http://www.tiet.ac.in/",
  },
  {
    country: "IN",
    name: "The New College",
    url: "http://www.thenewcollege.org/",
  },
  {
    country: "IN",
    name: "Thiagarajar College of Engineering",
    url: "http://www.tce.edu/",
  },
  {
    country: "IN",
    name: "Thiruvalluvar University",
    url: "http://www.tvuni.in/",
  },
  {
    country: "IN",
    name: "Tilak Maharashtra Vidyapeeth",
    url: "http://www.tmv.edu.in/",
  },
  {
    country: "IN",
    name: "Tilka Manjhi Bhagalpur University",
    url: "http://www.tmbu.org/",
  },
  {
    country: "IN",
    name: "Tripura University",
    url: "http://www.tripurauniv.in/",
  },
  {
    country: "IN",
    name: "Tumkur University",
    url: "http://www.tumkuruniversity.ac.in/",
  },
  {
    country: "IN",
    name: "University of Agricultural Sciences, Bangalore",
    url: "http://uasbng.kar.nic.in/",
  },
  {
    country: "IN",
    name: "University of Agricultural Sciences, Dharwad",
    url: "http://www.uasd.net/",
  },
  {
    country: "IN",
    name: "University of Burdwan",
    url: "http://www.burdwanuniversity.ac.in/",
  },
  {
    country: "IN",
    name: "University of Calcutta",
    url: "http://www.caluniv.ac.in/",
  },
  {
    country: "IN",
    name: "University of Calicut",
    url: "http://unical.ac.in/",
  },
  {
    country: "IN",
    name: "University of Delhi",
    url: "http://www.du.ac.in/",
  },
  {
    country: "IN",
    name: "University of Health Sciences Andhra Pradesh",
    url: "http://59.163.116.210/",
  },
  {
    country: "IN",
    name: "University of Hyderabad",
    url: "http://www.uohyd.ernet.in/",
  },
  {
    country: "IN",
    name: "University of Jammu",
    url: "http://www.jammuvarsity.org/",
  },
  {
    country: "IN",
    name: "University of Kalyani",
    url: "http://www.klyuniv.ac.in/",
  },
  {
    country: "IN",
    name: "University of Kashmir",
    url: "http://kashmiruniversity.net/",
  },
  {
    country: "IN",
    name: "University of Kerala",
    url: "http://www.keralauniversity.ac.in/",
  },
  {
    country: "IN",
    name: "University of Kota",
    url: "http://www.uok.ac.in/",
  },
  {
    country: "IN",
    name: "University of Lucknow",
    url: "http://www.lucknowuniv.org/",
  },
  {
    country: "IN",
    name: "University of Madras",
    url: "http://www.unom.ac.in/",
  },
  {
    country: "IN",
    name: "University of Media Arts",
    url: "http://www.uma.ac.in/",
  },
  {
    country: "IN",
    name: "University of Mumbai",
    url: "http://www.mu.ac.in/",
  },
  {
    country: "IN",
    name: "University of Music and Fine Arts",
    url: "http://www.iksvv.com/",
  },
  {
    country: "IN",
    name: "University of Mysore",
    url: "http://www.uni-mysore.ac.in/",
  },
  {
    country: "IN",
    name: "University of North Bengal",
    url: "http://www.nbu.ac.in/",
  },
  {
    country: "IN",
    name: "University of Pune",
    url: "http://www.unipune.ernet.in/",
  },
  {
    country: "IN",
    name: "University of Rajasthan",
    url: "http://www.uniraj.org/",
  },
  {
    country: "IN",
    name: "University of Roorkee",
    url: "http://www.uor.org/",
  },
  {
    country: "IN",
    name: "Utkal University",
    url: "http://www.utkaluniv.org/",
  },
  {
    country: "IN",
    name: "Uttarakhand Open University",
    url: "http://www.uou.ac.in/",
  },
  {
    country: "IN",
    name: "Uttarakhand Technical University",
    url: "http://www.uktech.ac.in/",
  },
  {
    country: "IN",
    name: "Uttar Pradesh Technical University",
    url: "http://www.uptu.ac.in/",
  },
  {
    country: "IN",
    name: "Vardhaman Mahaveer Open University",
    url: "http://www.vmou.ac.in/",
  },
  {
    country: "IN",
    name: "VBS Purvanchal University",
    url: "http://vbspu.ac.in/",
  },
  {
    country: "IN",
    name: "Veer Kunwar Singh University",
    url: "http://www.vksu-ara.org/",
  },
  {
    country: "IN",
    name: "Veer Surendra Sai University of Technology",
    url: "http://www.vssut.ac.in/",
  },
  {
    country: "IN",
    name: "Vellore Institute of Technology",
    url: "http://www.vit.ac.in/",
  },
  {
    country: "IN",
    name: "Vidyasagar University",
    url: "http://www.vidyasagar.ac.in/",
  },
  {
    country: "IN",
    name: "Vignan University",
    url: "http://www.vignanuniversity.org/",
  },
  {
    country: "IN",
    name: "Vikrama Simhapuri University",
    url: "http://www.simhapuriuniv.ac.in/",
  },
  {
    country: "IN",
    name: "Vikram University",
    url: "http://www.vikramuniversity.org/",
  },
  {
    country: "IN",
    name: "Vinayaka Mission's Research Foundation-Deemed University",
    url: "http://www.vinayakamissions.com/",
  },
  {
    country: "IN",
    name: "Vinoba Bhave University",
    url: "http://www.vbu.co.in/",
  },
  {
    country: "IN",
    name: "Visva-Bharati University",
    url: "http://www.visva-bharati.ac.in/",
  },
  {
    country: "IN",
    name: "Visvesvaraya National Institute of Technology",
    url: "http://www.vnitnagpur.ac.in/",
  },
  {
    country: "IN",
    name: "Visvesvaraya Technological University",
    url: "http://www.vtu.ac.in/",
  },
  {
    country: "IN",
    name: "West Bengal University of Animal and Fishery Sciences",
    url: "http://www.wbuafs.nic.in/",
  },
  {
    country: "IN",
    name: "West Bengal University of Technology",
    url: "http://www.wbut.net/",
  },
  {
    country: "IN",
    name: "Yashawantrao Chavan Maharashtra Open University",
    url: "http://www.ycmou.com/",
  },
  {
    country: "IQ",
    name: "Ahlulbait International University",
    url: "http://www.ahlulbaitonline.com/",
  },
  {
    country: "IQ",
    name: "Al Maarif University College",
    url: "http://www.auc-edu.org/",
  },
  {
    country: "IQ",
    name: "Al Mamon University College",
    url: "http://www.almamonuc.org/",
  },
  {
    country: "IQ",
    name: "Al Mansour University College",
    url: "http://www.muc.edu.iq/",
  },
  {
    country: "IQ",
    name: "Al Muthanna University",
    url: "http://www.uoalmuthana.edu.iq/",
  },
  {
    country: "IQ",
    name: "Al Nahrain University",
    url: "http://www.nahrainuniv.edu.iq/",
  },
  {
    country: "IQ",
    name: "Al Rafidain University College",
    url: "http://www.coalrafidain.edu.iq/",
  },
  {
    country: "IQ",
    name: "Al Rasheed University College",
    url: "http://www.alrasheed-uc.com/",
  },
  {
    country: "IQ",
    name: "Alsadrain University",
    url: "http://www.alsadrain.org/",
  },
  {
    country: "IQ",
    name: "Al Turath University College",
    url: "http://www.turathun.com/",
  },
  {
    country: "IQ",
    name: "Al Yarmouk University College",
    url: "http://www.yu.edu.jo/",
  },
  {
    country: "IQ",
    name: "American University of Iraq, Sulaimani (Kurdistan Region)",
    url: "http://www.auis.edu.iq/",
  },
  {
    country: "IQ",
    name: "Baghdad College of Economic Sciences University",
    url: "http://www.baghdadcollege.edu.iq/",
  },
  {
    country: "IQ",
    name: "Baghdad College of Pharmacy",
    url: "http://www.bpc.edu.iq/",
  },
  {
    country: "IQ",
    name: "British Royal University",
    url: "http://broyalu.net/",
  },
  {
    country: "IQ",
    name: "Cihan University",
    url: "http://www.cihanuniversity.edu.iq/",
  },
  {
    country: "IQ",
    name: "College of Science, Baghdad University",
    url: "http://www.scbaghdad.edu.iq/",
  },
  {
    country: "IQ",
    name: "Dijla University College",
    url: "http://www.duc.edu.iq/",
  },
  {
    country: "IQ",
    name: "Diyala University",
    url: "http://www.uodiyala.edu.iq/",
  },
  {
    country: "IQ",
    name: "Hawler Medical University",
    url: "http://www.hawlermu.org/",
  },
  {
    country: "IQ",
    name: "Iraq University College",
    url: "http://www.iraquniversity.net/",
  },
  {
    country: "IQ",
    name: "Ishik University",
    url: "http://www.iu.edu.iq/",
  },
  {
    country: "IQ",
    name: "Kerbala University",
    url: "http://www.uokerbala.edu.iq/",
  },
  {
    country: "IQ",
    name: "Komar University of Science and Technology",
    url: "http://www.komar.edu.iq/",
  },
  {
    country: "IQ",
    name: "Koya University (Kurdistan Region)",
    url: "http://www.koyauniversity.org/",
  },
  {
    country: "IQ",
    name: "Madenat Alelem University College",
    url: "http://www.madenatalelem.com/",
  },
  {
    country: "IQ",
    name: "Misan University",
    url: "http://www.uomisan.edu.iq/",
  },
  {
    country: "IQ",
    name: "Nawroz University",
    url: "http://www.nawrozuniversity.com/",
  },
  {
    country: "IQ",
    name: "Salahddin University (Kurdistan Region)",
    url: "http://www.suh-edu.com/",
  },
  {
    country: "IQ",
    name: "Soran University",
    url: "http://www.soran.edu.iq/",
  },
  {
    country: "IQ",
    name: "Sulaimani Polytechnic University",
    url: "http://www.spu.edu.iq/",
  },
  {
    country: "IQ",
    name: "Thi Qar University",
    url: "http://www.thiqaruni.org/",
  },
  {
    country: "IQ",
    name: "Tikrit University",
    url: "http://www.tu.edu.iq/",
  },
  {
    country: "IQ",
    name: "Uinversity of Babylon",
    url: "http://www.uobabylon.edu.iq/",
  },
  {
    country: "IQ",
    name: "University of Al-Qadisiyah",
    url: "http://www.qu.edu.iq/",
  },
  {
    country: "IQ",
    name: "University of Anbar",
    url: "http://www.uoanbar.edu.iq/",
  },
  {
    country: "IQ",
    name: "University of Baghdad",
    url: "http://www.uobaghdad.edu.iq/",
  },
  {
    country: "IQ",
    name: "University of Basrah",
    url: "http://www.uobasrah.edu.iq/",
  },
  {
    country: "IQ",
    name: "University of Dohuk (Kurdistan Region)",
    url: "http://www.uod.ac/",
  },
  {
    country: "IQ",
    name: "University of Human Development (Kurdistan Region)",
    url: "http://www.uhd.edu.iq/",
  },
  {
    country: "IQ",
    name: "University of Kirkuk",
    url: "http://www.uokirkuk.edu.iq/",
  },
  {
    country: "IQ",
    name: "University of Kufa",
    url: "http://www.uokufa.edu.iq/",
  },
  {
    country: "IQ",
    name: "University of Kurdistan - Hawler",
    url: "http://www.ukh.ac/",
  },
  {
    country: "IQ",
    name: "University of Mosul",
    url: "http://www.uomosul.edu.iq/",
  },
  {
    country: "IQ",
    name: "University of Mustansiriyah",
    url: "http://www.uomustansiriyah.edu.iq/",
  },
  {
    country: "IQ",
    name: "University of Sulaimania (Kurdistan Region)",
    url: "http://www.univsul.edu.iq/",
  },
  {
    country: "IQ",
    name: "University of Technology - Iraq",
    url: "http://www.uotechnology.edu.iq/",
  },
  {
    country: "IQ",
    name: "University of Wassit",
    url: "http://www.uowasit.edu.iq/",
  },
  {
    country: "IR",
    name: "Adiban Higher Education Institue",
    url: "http://www.adiban.ac.ir/",
  },
  {
    country: "IR",
    name: "Ahvaz University of Medical Sciences",
    url: "http://www.aums.ac.ir/",
  },
  {
    country: "IR",
    name: "Ahwaz Jondishapour University of Medical Sciences",
    url: "http://www.ajums.ac.ir/",
  },
  {
    country: "IR",
    name: "Allameh Tabatabaie University",
    url: "http://www.atu.ac.ir/",
  },
  {
    country: "IR",
    name: "Almustafa Open University",
    url: "http://www.almustafaou.ir/",
  },
  {
    country: "IR",
    name: "Amirkabir College of Managmant & Technology",
    url: "http://www.acmt.ac.ir/",
  },
  {
    country: "IR",
    name: "Amirkabir University of Technology",
    url: "http://www.aut.ac.ir/",
  },
  {
    country: "IR",
    name: "Arak University",
    url: "http://www.araku.ac.ir/",
  },
  {
    country: "IR",
    name: "Arak University of Medical Sciences",
    url: "http://www.arakmu.ac.ir/",
  },
  {
    country: "IR",
    name: "Arak University of Technology",
    url: "http://www.arakut.ac.ir/",
  },
  {
    country: "IR",
    name: "Ardabil University of Medical Sciences",
    url: "http://www.arums.ac.ir/",
  },
  {
    country: "IR",
    name: "Art University",
    url: "http://www.art.ac.ir/",
  },
  {
    country: "IR",
    name: "Azzahra University",
    url: "http://www.azzahra.ac.ir/",
  },
  {
    country: "IR",
    name: "Babol Noshirvani University of Technology",
    url: "http://www.nit.ac.ir/",
  },
  {
    country: "IR",
    name: "Babol University of Medical Sciences",
    url: "http://www.mubabol.ac.ir/",
  },
  {
    country: "IR",
    name: "Baghyatoolah Medical Sciences University",
    url: "http://www.bmsu.ac.ir/",
  },
  {
    country: "IR",
    name: "Baha'i Institute for Higher Education",
    url: "http://www.bihe.org/",
  },
  {
    country: "IR",
    name: "Birjand University",
    url: "http://www.birjand.ac.ir/",
  },
  {
    country: "IR",
    name: "Birjand University of Medical Sciences",
    url: "http://www.bums.ac.ir/",
  },
  {
    country: "IR",
    name: "Bu Ali Sina University",
    url: "http://www.basu.ac.ir/",
  },
  {
    country: "IR",
    name: "Bushehr University of Medical Sciences",
    url: "http://www.bpums.ac.ir/",
  },
  {
    country: "IR",
    name: "Damghan University of Basic Sciences",
    url: "http://www.dubs.ac.ir/",
  },
  {
    country: "IR",
    name: "Daneshestan Institute of Higher Education",
    url: "http://www.sdi.ac.ir/",
  },
  {
    country: "IR",
    name: "Delijan Payame Noor University",
    url: "http://www.delijanpnu.ac.ir/",
  },
  {
    country: "IR",
    name: "Deylaman Institute of Higher Education",
    url: "http://www.deylaman.ac.ir/",
  },
  {
    country: "IR",
    name: "Fasa Faculty of Medical Sciences",
    url: "http://www.fums.ac.ir/",
  },
  {
    country: "IR",
    name: "Ferdowsi University of Mashhad",
    url: "http://www.um.ac.ir/",
  },
  {
    country: "IR",
    name: "Golestan University of Medical Sciences",
    url: "http://www.goums.ac.ir/",
  },
  {
    country: "IR",
    name: "Gonabad University of Medical Sciences",
    url: "http://www.gmu.ac.ir/",
  },
  {
    country: "IR",
    name: "Gorgan University of Agricultural Sciences and Natural Resources",
    url: "http://www.gau.ac.ir/",
  },
  {
    country: "IR",
    name: "Guilan University",
    url: "http://www.guilan.ac.ir/",
  },
  {
    country: "IR",
    name: "Guilan University of Medical Sciences",
    url: "http://www.gums.ac.ir/",
  },
  {
    country: "IR",
    name: "Hamadan University of Medical Sciences",
    url: "http://www.umsha.ac.ir/",
  },
  {
    country: "IR",
    name: "Hormozgan University of Medical Sciences",
    url: "http://www.hums.ac.ir/",
  },
  {
    country: "IR",
    name: "Hyrcania Institute of Higher Education",
    url: "http://www.hyrcania.ac.ir/",
  },
  {
    country: "IR",
    name: "Ilam University",
    url: "http://www.ilam.ac.ir/",
  },
  {
    country: "IR",
    name: "Ilam University of Medical Sciences",
    url: "http://www.medilam.hbi.ir/",
  },
  {
    country: "IR",
    name: "Imam Khomeini International University",
    url: "http://www.ikiu.ac.ir/",
  },
  {
    country: "IR",
    name: "Imam Reza University",
    url: "http://www.imamreza.ac.ir/",
  },
  {
    country: "IR",
    name: "Imam Sadiq University",
    url: "http://www.isu.ac.ir/",
  },
  {
    country: "IR",
    name: "Iranian Academy of Persian Language and Literature",
    url: "http://www.persianacademy.ir/",
  },
  {
    country: "IR",
    name: "Iran University of Medical Sciences",
    url: "http://www.iums.ac.ir/",
  },
  {
    country: "IR",
    name: "Iran University of Science and Technology Tehran",
    url: "http://www.iust.ac.ir/",
  },
  {
    country: "IR",
    name: "Isfahan University",
    url: "http://www.ui.ac.ir/",
  },
  {
    country: "IR",
    name: "Isfahan University of Medical Sciences",
    url: "http://www.mui.ac.ir/",
  },
  {
    country: "IR",
    name: "Isfahan University of Technology",
    url: "http://www.iut.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Ahar",
    url: "http://www.iau-ahar.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Aliabad",
    url: "http://www.aliabadiau.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Arak",
    url: "http://www.iau-arak.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Ardabil",
    url: "http://www.iauardabil.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Bandar Abbas",
    url: "http://www.iauba.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Bojnourd",
    url: "http://www.bojnourdiau.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Bostanabad",
    url: "http://www.iaubos.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Boukan",
    url: "http://www.iau-boukan.ac.ir",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Central Tehran Branch",
    url: "http://www.iauctb.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Dehaghan",
    url: "http://www.dehaghan.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Falavarjan",
    url: "http://www.falavarjan.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Fars Science & Research Branch",
    url: "http://www.fsriau.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Garmsar",
    url: "http://www.iau-garmsar.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Gorgan",
    url: "http://www.gorganiau.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Hamedan",
    url: "http://www.iauh.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Janah",
    url: "http://www.janahiau.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Karaj",
    url: "http://www.iaukb.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Kazeron",
    url: "http://www.kau.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Kerman",
    url: "http://www.iauk.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Khorasgan",
    url: "http://www.khuisf.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Lahijan",
    url: "http://www.iau-lahijan.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Mahshar",
    url: "http://www.mahshahriau.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Majlesi",
    url: "http://www.iaumajlesi.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Mashhad",
    url: "http://www.mshdiau.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Maybod",
    url: "http://www.maybod.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Mehriz",
    url: "http://www.iaumehriz.com/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Mobarakeh",
    url: "http://www.mau.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Najaf Abad",
    url: "http://www.iaun.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, North Tehran",
    url: "http://www.iau-tnb.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Parand",
    url: "http://www.piau.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Qazvin",
    url: "http://www.qazviniau.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Qom",
    url: "http://www.qom-iau.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Quchan",
    url: "http://www.iauq.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Region One",
    url: "http://www.iauro.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Roodehen",
    url: "http://www.riau.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Sanandaj",
    url: "http://www.iausdj.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Saveh",
    url: "http://www.iau-saveh.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Semnan",
    url: "http://www.semnaniau.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Shabestar",
    url: "http://www.iaushab.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Shahr-e-rey Branch",
    url: "http://www.iausr.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Shahrood",
    url: "http://www.iau-shahrood.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Shahryar",
    url: "http://www.shahryariau.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Shiraz",
    url: "http://www.iaushiraz.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Shirvan",
    url: "http://www.iau-shirvan.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Sowmesara",
    url: "http://www.sowmesaraiau.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Tabriz",
    url: "http://www.iaut.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Tafresh",
    url: "http://www.iautb.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Tehran Medical",
    url: "http://www.iautmu.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Tehran Science & Research Branch",
    url: "http://www.srbiau.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Tehran South",
    url: "http://www.azad.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Varamin",
    url: "http://www.iauvaramin.ac.ir/",
  },
  {
    country: "IR",
    name: "Islamic Azad University, Yazd",
    url: "http://www.iauyazd.ac.ir/",
  },
  {
    country: "IR",
    name: "Jahrom University of Medical Sciences",
    url: "http://www.jums.ac.ir/",
  },
  {
    country: "IR",
    name: "Jami Institute of Technology",
    url: "http://www.jami.ac.ir/",
  },
  {
    country: "IR",
    name: "Kamalolmolk Art & Architecture Higher Education Institute of Noshahr",
    url: "http://www.kamalolmolk.ac.ir/",
  },
  {
    country: "IR",
    name: "Kar University",
    url: "http://www.karuniversity.com/",
  },
  {
    country: "IR",
    name: "Kashan University",
    url: "http://www.kashanu.ac.ir/",
  },
  {
    country: "IR",
    name: "Kashan University of Medical Sciences",
    url: "http://www.kaums.ac.ir/",
  },
  {
    country: "IR",
    name: "Kerman Graduate University of Technology",
    url: "http://www.kgut.ac.ir/",
  },
  {
    country: "IR",
    name: "Kermanshah University of Medical Sciences",
    url: "http://www.kums.ac.ir/",
  },
  {
    country: "IR",
    name: "Kermanshah University of Technology",
    url: "http://www.kut.ac.ir/",
  },
  {
    country: "IR",
    name: "Kerman University of Medical Sciences",
    url: "http://www.kmu.ac.ir/",
  },
  {
    country: "IR",
    name: "Khomeinishahr Payame Noor University",
    url: "http://km.isfpnu.ac.ir/",
  },
  {
    country: "IR",
    name: "K.N.Toosi University of Technology",
    url: "http://www.kntu.ac.ir/",
  },
  {
    country: "IR",
    name: "Kurdistan University of Medical Sciences",
    url: "http://www.muk.ac.ir/",
  },
  {
    country: "IR",
    name: "Lorestan University",
    url: "http://www.lu.ac.ir/",
  },
  {
    country: "IR",
    name: "Lorestan University of Medical Sciences",
    url: "http://www.lums.ac.ir/",
  },
  {
    country: "IR",
    name: "Malayer University",
    url: "http://www.malayeru.ac.ir/",
  },
  {
    country: "IR",
    name: "Malekan Payame Nour University",
    url: "http://www.malekanpnu.ac.ir/",
  },
  {
    country: "IR",
    name: "Mamasani University of Technology",
    url: "http://www.mamasaniiau.ac.ir/",
  },
  {
    country: "IR",
    name: "Mashhad University of Medical Sciences",
    url: "http://www.mums.ac.ir/",
  },
  {
    country: "IR",
    name: "Mazandaran University of Iran",
    url: "http://www.umz.ac.ir/",
  },
  {
    country: "IR",
    name: "Mazandaran University of Medical Science",
    url: "http://www.mazums.ac.ir/",
  },
  {
    country: "IR",
    name: "Mazandaran University of Science & Technology",
    url: "http://www.ustmb.ac.ir/",
  },
  {
    country: "IR",
    name: "Mofid University",
    url: "http://www.mofidu.ac.ir/",
  },
  {
    country: "IR",
    name: "Mustafa International University",
    url: "http://www.miu.ac.ir/",
  },
  {
    country: "IR",
    name: "Najaf Abad University of Applied Science and Technology",
    url: "http://www.najafabad-uast.ac.ir/",
  },
  {
    country: "IR",
    name: "Payame Noor University",
    url: "http://www.pnu.ac.ir/",
  },
  {
    country: "IR",
    name: "Payame Noor University, Tafresh",
    url: "http://www.pnut.ac.ir/",
  },
  {
    country: "IR",
    name: "Persian Gulf University",
    url: "http://www.pgu.ac.ir/",
  },
  {
    country: "IR",
    name: "Petroleum University of Technology",
    url: "http://www.put.ac.ir/",
  },
  {
    country: "IR",
    name: "Power and Water Institute of Technology",
    url: "http://www.pwit.ac.ir/",
  },
  {
    country: "IR",
    name: "Qazvin University of Medical Sciences",
    url: "http://www.qums.ac.ir/",
  },
  {
    country: "IR",
    name: "Qom University",
    url: "http://www.qom.ac.ir/",
  },
  {
    country: "IR",
    name: "Qom University of Medical Sciences",
    url: "http://www.muq.ac.ir/",
  },
  {
    country: "IR",
    name: "Qom University of Technology",
    url: "http://www.qut.ac.ir/",
  },
  {
    country: "IR",
    name: "Rafsanjan University of Medical Sciences",
    url: "http://www.rums.ac.ir/",
  },
  {
    country: "IR",
    name: "Raghebe Esfahani University",
    url: "http://www.raghebisf.ac.ir/",
  },
  {
    country: "IR",
    name: "Razi University",
    url: "http://www.razi.ac.ir/",
  },
  {
    country: "IR",
    name: "Sabzevar School of Medical Sciences",
    url: "http://www.medsab.ac.ir/",
  },
  {
    country: "IR",
    name: "Sabzevar Teacher Training University",
    url: "http://www.sttu.ac.ir/",
  },
  {
    country: "IR",
    name: "Sadjad Institute of Technology",
    url: "http://www.sadjad.ac.ir/",
  },
  {
    country: "IR",
    name: "Sadra University",
    url: "http://www.sadra.ac.ir/",
  },
  {
    country: "IR",
    name: "Sahand University of Technology",
    url: "http://www.sut.ac.ir/",
  },
  {
    country: "IR",
    name: "School of Economic Sciences",
    url: "http://www.ses.ac.ir/",
  },
  {
    country: "IR",
    name: "Semnan University",
    url: "http://www.semnan.ac.ir/",
  },
  {
    country: "IR",
    name: "Semnan University of Medical Sciences",
    url: "http://www.sem-ums.ac.ir/",
  },
  {
    country: "IR",
    name: "Shahed University",
    url: "http://www.shahed.ac.ir/",
  },
  {
    country: "IR",
    name: "Shaheed Beheshti University",
    url: "http://www.sbu.ac.ir/",
  },
  {
    country: "IR",
    name: "Shaheed Beheshti University of Medical Sciences",
    url: "http://www.sbmu.ac.ir/",
  },
  {
    country: "IR",
    name: "Shaheed Chamran University",
    url: "http://www.cua.ac.ir/",
  },
  {
    country: "IR",
    name: "Shaheed Rajaei Teacher Training University",
    url: "http://www.srttu.edu/",
  },
  {
    country: "IR",
    name: "Shahid Bahonar University of Kerman",
    url: "http://www.uk.ac.ir/",
  },
  {
    country: "IR",
    name: "Shahrekord University",
    url: "http://www.sku.ac.ir/",
  },
  {
    country: "IR",
    name: "Shahrekord University of Medical Sciences",
    url: "http://www.skums.ac.ir/",
  },
  {
    country: "IR",
    name: "Shahrood University of Medical Sciences",
    url: "http://www.shmu.ac.ir/",
  },
  {
    country: "IR",
    name: "Shahrood University of Technology",
    url: "http://www.shahroodut.ac.ir/",
  },
  {
    country: "IR",
    name: "Sharif University of Technology",
    url: "http://www.sharif.ac.ir/",
  },
  {
    country: "IR",
    name: "Sharif University of Technology, Kish Campus",
    url: "http://www.kish.ac.ir/",
  },
  {
    country: "IR",
    name: "Sheikhbahaee University",
    url: "http://www.shbu.ac.ir/",
  },
  {
    country: "IR",
    name: "Shiraz Payamnoor University",
    url: "http://www.spnu.ac.ir/",
  },
  {
    country: "IR",
    name: "Shiraz University",
    url: "http://www.shirazu.ac.ir/",
  },
  {
    country: "IR",
    name: "Shiraz University of Medical Sciences",
    url: "http://www.sums.ac.ir/",
  },
  {
    country: "IR",
    name: "Shiraz University of Technology",
    url: "http://www.sutech.ac.ir/",
  },
  {
    country: "IR",
    name: "Shomal University",
    url: "http://www.shomal.ac.ir/",
  },
  {
    country: "IR",
    name: "Sistan & Balouchestan University",
    url: "http://www.usb.ac.ir/",
  },
  {
    country: "IR",
    name: "Tabari Institute of Higher Education",
    url: "http://www.tabari.ac.ir/",
  },
  {
    country: "IR",
    name: "Tabriz College of Technology",
    url: "http://www.tct.ac.ir/",
  },
  {
    country: "IR",
    name: "Tabriz Islamic Art University",
    url: "http://www.tabriziau.ac.ir/",
  },
  {
    country: "IR",
    name: "Tabriz University",
    url: "http://www.tabrizu.ac.ir/",
  },
  {
    country: "IR",
    name: "Tabriz University of Medical Sciences",
    url: "http://www.tbzmed.ac.ir/",
  },
  {
    country: "IR",
    name: "Takestan Islamic Azad University",
    url: "http://www.tiau.ac.ir/",
  },
  {
    country: "IR",
    name: "Tarbiat Modares University",
    url: "http://www.modares.ac.ir/",
  },
  {
    country: "IR",
    name: "Tehran University of Medical Sciences",
    url: "http://www.tums.ac.ir/",
  },
  {
    country: "IR",
    name: "Tehran University of Teacher Training",
    url: "http://www.tmu.ac.ir/",
  },
  {
    country: "IR",
    name: "The Bahá'í Institute For Higher Education (BIHE)",
    url: "http://www.bihe.org/",
  },
  {
    country: "IR",
    name: "Torbat Heydarieh University of Medical Sciences",
    url: "http://www.thums.ac.ir/",
  },
  {
    country: "IR",
    name: "University College of Nabi Akram",
    url: "http://www.ucna.info/",
  },
  {
    country: "IR",
    name: "University of Applied Science and Technology",
    url: "http://www.uast.ac.ir/",
  },
  {
    country: "IR",
    name: "University of Hormozgan",
    url: "http://www.hormozgan.ac.ir/",
  },
  {
    country: "IR",
    name: "University of Kurdistan",
    url: "http://www.uok.ac.ir/",
  },
  {
    country: "IR",
    name: "University of Lavasanat Pnu",
    url: "http://www.lavasanpnu.ac.ir/",
  },
  {
    country: "IR",
    name: "University of Mashhad Pnu",
    url: "http://www.pnum.ac.ir/",
  },
  {
    country: "IR",
    name: "University of Mohaghegh",
    url: "http://www.uma.ac.ir/",
  },
  {
    country: "IR",
    name: "University of Science and Culture",
    url: "http://www.usc.ac.ir/",
  },
  {
    country: "IR",
    name: "University of Social Welfare and Rehabilitation Scinences",
    url: "http://www.uswr.ac.ir/",
  },
  {
    country: "IR",
    name: "University of Tehran",
    url: "http://www.ut.ac.ir/",
  },
  {
    country: "IR",
    name: "Urmia University",
    url: "http://www.urmia.ac.ir/",
  },
  {
    country: "IR",
    name: "Urmia University of Medical Sciences",
    url: "http://www.umsu.ac.ir/",
  },
  {
    country: "IR",
    name: "Urmia University of Technology",
    url: "http://www.uut.ac.ir/",
  },
  {
    country: "IR",
    name: "Vali-e-Asr University",
    url: "http://www.vru.ac.ir/",
  },
  {
    country: "IR",
    name: "Yasuj University",
    url: "http://www.yu.ac.ir/",
  },
  {
    country: "IR",
    name: "Yasuj University of Medical Sciences",
    url: "http://www.yums.ac.ir/",
  },
  {
    country: "IR",
    name: "Yazd Medical University",
    url: "http://www.ssu.ac.ir/",
  },
  {
    country: "IR",
    name: "Yazd University",
    url: "http://www.yazduni.ac.ir/",
  },
  {
    country: "IR",
    name: "Zabol University",
    url: "http://www.uoz.ac.ir/",
  },
  {
    country: "IR",
    name: "Zahedan University of Medical Sciences",
    url: "http://www.zdmu.ac.ir/",
  },
  {
    country: "IR",
    name: "Zanjan Institute for Advanced Studies in Basic Sciences",
    url: "http://www.iasbs.ac.ir/",
  },
  {
    country: "IR",
    name: "Zanjan University",
    url: "http://www.znu.ac.ir/",
  },
  {
    country: "IR",
    name: "Zanjan University of Medical Sciences",
    url: "http://www.zums.ac.ir/",
  },
  {
    country: "IS",
    name: "Bifrost School of Business",
    url: "http://www.bifrost.is/",
  },
  {
    country: "IS",
    name: "Holar University College",
    url: "http://www.holar.is/",
  },
  {
    country: "IS",
    name: "Hvanneyri Agricultural University",
    url: "http://www.hvanneyri.is/",
  },
  {
    country: "IS",
    name: "Iceland Academy of the Arts",
    url: "http://www.lhi.is/",
  },
  {
    country: "IS",
    name: "Iceland University of Education",
    url: "http://www.khi.is/",
  },
  {
    country: "IS",
    name: "Reykjavík University",
    url: "http://www.ru.is/",
  },
  {
    country: "IS",
    name: "Technological University of Iceland",
    url: "http://www.iti.is/",
  },
  {
    country: "IS",
    name: "University Centre of the Westfjords",
    url: "http://www.hsvest.is/",
  },
  {
    country: "IS",
    name: "University of Akureyri",
    url: "http://www.unak.is/",
  },
  {
    country: "IS",
    name: "University of Iceland",
    url: "http://www.hi.is/",
  },
  {
    country: "IT",
    name: "Abdus Salam international centre for theoretical physics",
    url: "http://www.ictp.trieste.it/",
  },
  {
    country: "IT",
    name: "Basilicata University Potenza",
    url: "http://www.unibas.it/",
  },
  {
    country: "IT",
    name: "Campus Bio-Medico University of Rome",
    url: "http://www.unicampus.it/",
  },
  {
    country: "IT",
    name: "Catholic University of the Sacred Heart",
    url: "http://www.unicatt.it/",
  },
  {
    country: "IT",
    name: "Dutch University Institute for Art History (DUIA)",
    url: "http://www.iuoart.org/",
  },
  {
    country: "IT",
    name: "European School of Economics",
    url: "http://www.uniese.it/",
  },
  {
    country: "IT",
    name: "European University Institute",
    url: "http://www.iue.it/",
  },
  {
    country: "IT",
    name: "Fondazione Sacro Cuore",
    url: "http://www.sacrocuore.org/",
  },
  {
    country: "IT",
    name: "Free International University of Social Studies",
    url: "http://www.luiss.it/",
  },
  {
    country: "IT",
    name: 'Free University Institute "Carlo Cattaneo',
    url: "http://www.liuc.it/",
  },
  {
    country: "IT",
    name: 'Free University "Maria Santissima Assunta',
    url: "http://www.lumsa.it/",
  },
  {
    country: "IT",
    name: "Free University of Bozen",
    url: "http://www.unibz.it/",
  },
  {
    country: "IT",
    name: "Higher School of University and Advanced Studies Pisa",
    url: "http://www.sssup.it/",
  },
  {
    country: "IT",
    name: "Institute for Advanced Studies Lucca",
    url: "http://www.imtlucca.it/",
  },
  {
    country: "IT",
    name: "International Higher School for Advanced Studies Trieste",
    url: "http://www.sissa.it/",
  },
  {
    country: "IT",
    name: "Istituto Universitario di Studi Superiori",
    url: "http://www.iusspavia.it/",
  },
  {
    country: "IT",
    name: "John Cabot University",
    url: "http://www.johncabot.edu/",
  },
  {
    country: "IT",
    name: "Johns Hopkins University, SAIS Bologna Center",
    url: "http://www.jhubc.it/",
  },
  {
    country: "IT",
    name: "Link Campus University of Malta",
    url: "http://www.unilink.it/",
  },
  {
    country: "IT",
    name: "NABA - Nuova Accademia di Belle Arti, Milan",
    url: "http://www.naba.it/",
  },
  {
    country: "IT",
    name: "Polytechnic Institute of Bari",
    url: "http://www.poliba.it/",
  },
  {
    country: "IT",
    name: "Polytechnic Institute of Milan",
    url: "http://www.polimi.it/",
  },
  {
    country: "IT",
    name: "Polytechnic Institute of Turin",
    url: "http://www.polito.it/",
  },
  {
    country: "IT",
    name: "Pontifica Università Gregoriana",
    url: "http://www.unigre.it/",
  },
  {
    country: "IT",
    name: "Pontificia Università della Santa Croce",
    url: "http://www.pusc.it/",
  },
  {
    country: "IT",
    name: "Pontificia Università Lateranense",
    url: "http://www.pul.it/",
  },
  {
    country: "IT",
    name: "Pontificia Università S. Tommaso",
    url: "http://www.angelicum.org/",
  },
  {
    country: "IT",
    name: "Pontificio Ateneo Antonianum",
    url: "http://www.antonianum.ofm.org/",
  },
  {
    country: "IT",
    name: "Pontificio Istituto Orientale",
    url: "http://www.pio.urbe.it/",
  },
  {
    country: "IT",
    name: "School of Education Pisa",
    url: "http://www.sns.it/",
  },
  {
    country: "IT",
    name: "Second University of Naples",
    url: "http://www.unina2.it/",
  },
  {
    country: "IT",
    name: "Third University of Rome",
    url: "http://www.uniroma3.it/",
  },
  {
    country: "IT",
    name: "Università Bocconi",
    url: "http://www.uni-bocconi.it/",
  },
  {
    country: "IT",
    name: "Università Pontificia Salesiana",
    url: "http://www.unisal.it/",
  },
  {
    country: "IT",
    name: "University Institute of Architecture Venice",
    url: "http://www.iuav.unive.it/",
  },
  {
    country: "IT",
    name: "University Institute of Modern Languages",
    url: "http://www.iulm.it/",
  },
  {
    country: "IT",
    name: "University Institute of Naval Studies",
    url: "http://www.uninav.it/",
  },
  {
    country: "IT",
    name: "University Institute of Oriental Studies",
    url: "http://www.iuo.it/",
  },
  {
    country: "IT",
    name: 'University Institute of Teacher Training "Suor Orsola Benincasa',
    url: "http://www.unisob.na.it/",
  },
  {
    country: "IT",
    name: "University of Ancona",
    url: "http://www.unian.it/",
  },
  {
    country: "IT",
    name: "University of Aquila",
    url: "http://www.univaq.it/",
  },
  {
    country: "IT",
    name: "University of Bari",
    url: "http://www.uniba.it/",
  },
  {
    country: "IT",
    name: "University of Bergamo",
    url: "http://www.unibg.it/",
  },
  {
    country: "IT",
    name: "University of Bologna",
    url: "http://www.unibo.it/",
  },
  {
    country: "IT",
    name: "University of Brescia",
    url: "http://www.unibs.it/",
  },
  {
    country: "IT",
    name: "University of Cagliari",
    url: "http://www.unica.it/",
  },
  {
    country: "IT",
    name: "University of Calabria",
    url: "http://www.unical.it/",
  },
  {
    country: "IT",
    name: "University of Camerino",
    url: "http://www.unicam.it/",
  },
  {
    country: "IT",
    name: "University of Cassino",
    url: "http://www.unicas.it/",
  },
  {
    country: "IT",
    name: "University of Catania",
    url: "http://www.unict.it/",
  },
  {
    country: "IT",
    name: "University of Chieti",
    url: "http://www.unich.it/",
  },
  {
    country: "IT",
    name: "University of Commerce Luigi Bocconi",
    url: "http://www.uni-bocconi.it/",
  },
  {
    country: "IT",
    name: "University of Ferrara",
    url: "http://www.unife.it/",
  },
  {
    country: "IT",
    name: "University of Florence",
    url: "http://www.unifi.it/",
  },
  {
    country: "IT",
    name: "University of Foggia",
    url: "http://www.unifg.it/",
  },
  {
    country: "IT",
    name: "University of Gastronomic Sciences",
    url: "http://www.unisg.it/",
  },
  {
    country: "IT",
    name: "University of Genoa",
    url: "http://www.unige.it/",
  },
  {
    country: "IT",
    name: "University of Insubria",
    url: "http://www.uninsubria.it/",
  },
  {
    country: "IT",
    name: "University of Italian Studies for Foreigners of Perugia",
    url: "http://www.unistrapg.it/",
  },
  {
    country: "IT",
    name: "University of Italian Studies for Foreigners of Siena",
    url: "http://www.unistrasi.it/",
  },
  {
    country: "IT",
    name: "University of Lecce",
    url: "http://www.unile.it/",
  },
  {
    country: "IT",
    name: "University of Macerata",
    url: "http://www.unimc.it/",
  },
  {
    country: "IT",
    name: "University of Messina",
    url: "http://www.unime.it/",
  },
  {
    country: "IT",
    name: "University of Milan",
    url: "http://www.unimi.it/",
  },
  {
    country: "IT",
    name: "University of Milan - Bicocca",
    url: "http://www.unimib.it/",
  },
  {
    country: "IT",
    name: "University of Modena",
    url: "http://www.casa.unimo.it/",
  },
  {
    country: "IT",
    name: "University of Molise",
    url: "http://www.unimol.it/",
  },
  {
    country: "IT",
    name: "University of Naples Federico II",
    url: "http://www.unina.it/",
  },
  {
    country: "IT",
    name: "University of Padua",
    url: "http://www.unipd.it/",
  },
  {
    country: "IT",
    name: "University of Palermo",
    url: "http://www.unipa.it/",
  },
  {
    country: "IT",
    name: "University of Parma",
    url: "http://www.unipr.it/",
  },
  {
    country: "IT",
    name: "University of Pavia",
    url: "http://www.unipv.it/",
  },
  {
    country: "IT",
    name: "University of Perugia",
    url: "http://www.unipg.it/",
  },
  {
    country: "IT",
    name: "University of Pisa",
    url: "http://www.unipi.it/",
  },
  {
    country: "IT",
    name: "University of Reggio Calabria",
    url: "http://www.unirc.it/",
  },
  {
    country: "IT",
    name: 'University of Roma "La Sapienza',
    url: "http://www.uniroma1.it/",
  },
  {
    country: "IT",
    name: 'University of Roma "Tor Vergata',
    url: "http://www.uniroma2.it/",
  },
  {
    country: "IT",
    name: "University of Salerno",
    url: "http://www.unisa.it/",
  },
  {
    country: "IT",
    name: "University of Sannio",
    url: "http://www.unisannio.it/",
  },
  {
    country: "IT",
    name: "University of Sassari",
    url: "http://www.uniss.it/",
  },
  {
    country: "IT",
    name: "University of Siena",
    url: "http://www.unisi.it/",
  },
  {
    country: "IT",
    name: "University of Teramo",
    url: "http://www.unite.it/",
  },
  {
    country: "IT",
    name: "University of Trento",
    url: "http://www.unitn.it/",
  },
  {
    country: "IT",
    name: "University of Trieste",
    url: "http://www.univ.trieste.it/",
  },
  {
    country: "IT",
    name: "University of Turin",
    url: "http://www.unito.it/",
  },
  {
    country: "IT",
    name: "University of Udine",
    url: "http://www.uniud.it/",
  },
  {
    country: "IT",
    name: "University of Urbino",
    url: "http://www.uniurb.it/",
  },
  {
    country: "IT",
    name: "University of Venice",
    url: "http://www.unive.it/",
  },
  {
    country: "IT",
    name: "University of Verona",
    url: "http://www.univr.it/",
  },
  {
    country: "IT",
    name: "Viterbo State University",
    url: "http://www.unitus.it/",
  },
  {
    country: "IT",
    name: "Yorker International University, Milano",
    url: "http://www.nyuniversity.net/",
  },
  {
    country: "JM",
    name: "Northern Caribbean University",
    url: "http://www.ncu.edu.jm/",
  },
  {
    country: "JM",
    name: "University of Technology Jamaica",
    url: "http://www.utech.edu.jm/",
  },
  {
    country: "JM",
    name: "University of the West Indies, Mona",
    url: "http://www.uwimona.edu.jm/",
  },
  {
    country: "JO",
    name: "Al-Ahliyya Amman University",
    url: "http://www.ammanu.edu.jo/",
  },
  {
    country: "JO",
    name: "Al al-Bayt University",
    url: "http://www.aabu.edu.jo/",
  },
  {
    country: "JO",
    name: "Al-Balqa Applied University",
    url: "http://www.bau.edu.jo/",
  },
  {
    country: "JO",
    name: "Al Hussein Bin Talal University",
    url: "http://www.ahu.edu.jo/",
  },
  {
    country: "JO",
    name: "Al-Isra University",
    url: "http://www.isra.edu.jo/",
  },
  {
    country: "JO",
    name: "Al-Zaytoonah University",
    url: "http://www.alzaytoonah.edu.jo/",
  },
  {
    country: "JO",
    name: "Amman Arab University for Higher Studies",
    url: "http://www.aau.edu.jo/",
  },
  {
    country: "JO",
    name: "Amman University",
    url: "http://www.amman.edu/",
  },
  {
    country: "JO",
    name: "Applied Science University",
    url: "http://www.asu.edu.jo/",
  },
  {
    country: "JO",
    name: "Arab Academy for Banking and Financial Sciences",
    url: "http://www.aabfs.org/",
  },
  {
    country: "JO",
    name: "Arab Open University",
    url: "http://www.aou.edu.jo/",
  },
  {
    country: "JO",
    name: "Balqa Applied University",
    url: "http://www.bau.edu.jo/",
  },
  {
    country: "JO",
    name: "German Jordanian University",
    url: "http://www.gju.edu.jo/",
  },
  {
    country: "JO",
    name: "Hashemite University",
    url: "http://www.hu.edu.jo/",
  },
  {
    country: "JO",
    name: "Irbid National University",
    url: "http://www.inu.edu.jo/",
  },
  {
    country: "JO",
    name: "Jadara University",
    url: "http://www.jadara.edu.jo/",
  },
  {
    country: "JO",
    name: "Jerash Private University",
    url: "http://www.jerashun.edu.jo/",
  },
  {
    country: "JO",
    name: "Jordan Academy of Music / Higher Institute of Music",
    url: "http://www.jam.edu.jo/",
  },
  {
    country: "JO",
    name: "Jordan University of Science and Technology",
    url: "http://www.just.edu.jo/",
  },
  {
    country: "JO",
    name: "Middle East University",
    url: "http://www.meu.edu.jo/",
  },
  {
    country: "JO",
    name: "Mu' tah University",
    url: "http://www.mutah.edu.jo/",
  },
  {
    country: "JO",
    name: "New York Institute of Technology",
    url: "http://www.nyit.edu.jo/",
  },
  {
    country: "JO",
    name: "Philadelphia University",
    url: "http://www.philadelphia.edu.jo/",
  },
  {
    country: "JO",
    name: "Princess Sumaya University for Technology",
    url: "http://www.psut.edu.jo/",
  },
  {
    country: "JO",
    name: "Tafila Technical University",
    url: "http://www.ttu.edu.jo/",
  },
  {
    country: "JO",
    name: "The World Islamic Sciences & Education University",
    url: "http://www.wise.edu.jo/",
  },
  {
    country: "JO",
    name: "University of Jordan",
    url: "http://www.ju.edu.jo/",
  },
  {
    country: "JO",
    name: "University of Petra",
    url: "http://www.uop.edu.jo/",
  },
  {
    country: "JO",
    name: "Yarmouk University",
    url: "http://www.yu.edu.jo/",
  },
  {
    country: "JO",
    name: "Zarka Private University",
    url: "http://www.zpu.edu.jo/",
  },
  {
    country: "JP",
    name: "Aichi Bunkyo University",
    url: "http://www.abu.ac.jp/",
  },
  {
    country: "JP",
    name: "Aichi Gakuin University",
    url: "http://www.aichi-gakuin.ac.jp/",
  },
  {
    country: "JP",
    name: "Aichi Gakusen University",
    url: "http://www.gakusen.ac.jp/",
  },
  {
    country: "JP",
    name: "Aichi Institute of Technology",
    url: "http://www.aitech.ac.jp/",
  },
  {
    country: "JP",
    name: "Aichi Medical University",
    url: "http://www.aichi-med-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Aichi Prefectural University",
    url: "http://www.aichi-pu.ac.jp/",
  },
  {
    country: "JP",
    name: "Aichi Prefectural University of Fine Arts & Music",
    url: "http://www.aichi-fam-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Aichi Sangyo University",
    url: "http://www.asu.ac.jp/",
  },
  {
    country: "JP",
    name: "Aichi Shukutoku University",
    url: "http://www.aasa.ac.jp/",
  },
  {
    country: "JP",
    name: "Aichi University",
    url: "http://www.aichi-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Aichi University of Education",
    url: "http://www.aichi-edu.ac.jp/",
  },
  {
    country: "JP",
    name: "Aikoku Gakuen University",
    url: "http://www.aikoku-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Akita University",
    url: "http://www.akita-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Akita University of Economics and Law",
    url: "http://www.akeihou-u.ac.jp/",
  },
  {
    country: "JP",
    name: "American University Extension, Okinawa",
    url: "http://www.aue-okinawa.ac.jp/",
  },
  {
    country: "JP",
    name: "Aomori Chuoh Gakuin University",
    url: "http://www.aomoricgu.ac.jp/",
  },
  {
    country: "JP",
    name: "Aomori Public College",
    url: "http://www.nebuta.ac.jp/",
  },
  {
    country: "JP",
    name: "Aomori University",
    url: "http://www.aomori-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Aomori University of Health and Welfare",
    url: "http://www.auhw.ac.jp/",
  },
  {
    country: "JP",
    name: "Aoyama Gakuin University",
    url: "http://www.aoyama.ac.jp/",
  },
  {
    country: "JP",
    name: "Asahikawa Medical College",
    url: "http://www.asahikawa-med.ac.jp/",
  },
  {
    country: "JP",
    name: "Asahikawa University",
    url: "http://www.asahikawa-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Asahi University",
    url: "http://www.asahi-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Ashikaga Institute of Technology",
    url: "http://www.ashitech.ac.jp/",
  },
  {
    country: "JP",
    name: "Ashiya University",
    url: "http://www.ashiya-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Asia University",
    url: "http://www.asia-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Atomi College",
    url: "http://www.atomi.ac.jp/",
  },
  {
    country: "JP",
    name: "Azabu University",
    url: "http://www.azabu-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Baika Women's College",
    url: "http://www.baika.ac.jp/",
  },
  {
    country: "JP",
    name: "Baiko Women's College",
    url: "http://www.baiko.ac.jp/",
  },
  {
    country: "JP",
    name: "Beppu University",
    url: "http://www.beppu-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Bukkyo University",
    url: "http://www.bukkyo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Bunka Women's University",
    url: "http://www.bunka-wu.ac.jp/",
  },
  {
    country: "JP",
    name: "Bunkyo University",
    url: "http://www.bunkyo.ac.jp/",
  },
  {
    country: "JP",
    name: "Chiba Institute of Technology",
    url: "http://www.it-chiba.ac.jp/",
  },
  {
    country: "JP",
    name: "Chiba Keizai University",
    url: "http://www.cku.ac.jp/",
  },
  {
    country: "JP",
    name: "Chiba University",
    url: "http://www.chiba-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Chiba University of Commerce",
    url: "http://www.cuc.ac.jp/",
  },
  {
    country: "JP",
    name: "Chikushi Jogakuen University",
    url: "http://www.chikushi.ac.jp/",
  },
  {
    country: "JP",
    name: "Chubu Gakuin University & Chubu Women's College",
    url: "http://www.chubu-gu.ac.jp/",
  },
  {
    country: "JP",
    name: "Chubu University",
    url: "http://www.chubu.ac.jp/",
  },
  {
    country: "JP",
    name: "Chukyo Gakuin University",
    url: "http://www.chukyogakuin-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Chukyo University",
    url: "http://www.chukyo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Chukyo Women's University",
    url: "http://www.chujo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Chuo Gakuin University",
    url: "http://www.cgu.ac.jp/",
  },
  {
    country: "JP",
    name: "Chuo University",
    url: "http://www.chuo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Daido Institute of Technology",
    url: "http://www.daido-it.ac.jp/",
  },
  {
    country: "JP",
    name: "Daiichi College of Pharmaceutical Sciences",
    url: "http://www.daiichi-cps.ac.jp/",
  },
  {
    country: "JP",
    name: "Dai Ichi University, College of Technology",
    url: "http://www.daiichi-koudai.ac.jp/",
  },
  {
    country: "JP",
    name: "Daiichi University of Economics",
    url: "http://www.daiichi-ue.ac.jp/",
  },
  {
    country: "JP",
    name: "Daito Bunka University",
    url: "http://www.daito.ac.jp/",
  },
  {
    country: "JP",
    name: "Doho University",
    url: "http://www.doho.ac.jp/",
  },
  {
    country: "JP",
    name: "Dohto University",
    url: "http://www.dohto.ac.jp/",
  },
  {
    country: "JP",
    name: "Dokkyo University",
    url: "http://www.dokkyo.ac.jp/",
  },
  {
    country: "JP",
    name: "Dokkyo University School of Medicine",
    url: "http://www.dokkyomed.ac.jp/",
  },
  {
    country: "JP",
    name: "Doshisha University",
    url: "http://www.doshisha.ac.jp/",
  },
  {
    country: "JP",
    name: "Doshisha Women's College of Liberal Arts",
    url: "http://www.dwc.doshisha.ac.jp/",
  },
  {
    country: "JP",
    name: "Edogawa University",
    url: "http://www.edogawa-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Ehime University",
    url: "http://www.ehime-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Eichi University",
    url: "http://www.eichi-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Elisabeth University of Music",
    url: "http://www.eum.ac.jp/",
  },
  {
    country: "JP",
    name: "Ferris University",
    url: "http://www.ferris.ac.jp/",
  },
  {
    country: "JP",
    name: "Fujita Health University",
    url: "http://www.fujita-hu.ac.jp/",
  },
  {
    country: "JP",
    name: "Fuji University",
    url: "http://www.termnet.co.jp/fujidai/",
  },
  {
    country: "JP",
    name: "Fuji Women's College",
    url: "http://www.fujijoshi.ac.jp/",
  },
  {
    country: "JP",
    name: "Fukui Medical School",
    url: "http://www.fukui-med.ac.jp/",
  },
  {
    country: "JP",
    name: "Fukui Prefectural University",
    url: "http://www.fpu.ac.jp/",
  },
  {
    country: "JP",
    name: "Fukui University",
    url: "http://www.fukui-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Fukui University of Technology",
    url: "http://www.fukui-ut.ac.jp/",
  },
  {
    country: "JP",
    name: "Fukuoka Dental College",
    url: "http://www.fdcnet.ac.jp/",
  },
  {
    country: "JP",
    name: "Fukuoka Institute of Technology",
    url: "http://www.fit.ac.jp/",
  },
  {
    country: "JP",
    name: "Fukuoka International University",
    url: "http://www.fukuoka-int-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Fukuoka Prefectural University",
    url: "http://www.fukuoka-pu.ac.jp/",
  },
  {
    country: "JP",
    name: "Fukuoka University",
    url: "http://www.fukuoka-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Fukuoka University of Education",
    url: "http://www.fukuoka-edu.ac.jp/",
  },
  {
    country: "JP",
    name: "Fukuoka Women's University",
    url: "http://www.fwu.ac.jp/",
  },
  {
    country: "JP",
    name: "Fukushima Medical College",
    url: "http://www.fmu.ac.jp/",
  },
  {
    country: "JP",
    name: "Fukushima University",
    url: "http://www.fukushima-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Fukuyama Heisei University",
    url: "http://www.heisei-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Fukuyama University",
    url: "http://www.fukuyama-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Gakushuin University",
    url: "http://www.gakushuin.ac.jp/",
  },
  {
    country: "JP",
    name: "Gifu Keizai University",
    url: "http://www.gifu-keizai.ac.jp/",
  },
  {
    country: "JP",
    name: "Gifu Pharmaceutical University",
    url: "http://www.gifu-pu.ac.jp/",
  },
  {
    country: "JP",
    name: "Gifu Shotoku Gakuen University",
    url: "http://www.shotoku.ac.jp/",
  },
  {
    country: "JP",
    name: "Gifu University",
    url: "http://www.gifu-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Gifu University for Education and Languages",
    url: "http://www.gifu-kyoiku.ac.jp/",
  },
  {
    country: "JP",
    name: "Gifu Women's University",
    url: "http://www.gijodai.ac.jp/",
  },
  {
    country: "JP",
    name: "Graduate University for Advanced Studies",
    url: "http://www.soken.ac.jp/",
  },
  {
    country: "JP",
    name: "Gunma Prefectural Women's University",
    url: "http://www.gpwu.ac.jp/",
  },
  {
    country: "JP",
    name: "Gunma University",
    url: "http://www.gunma-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Hachinohe Institute of Technology",
    url: "http://www.hi-tech.ac.jp/",
  },
  {
    country: "JP",
    name: "Hachinohe University",
    url: "http://www.hachinohe-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Hakodate University",
    url: "http://www.hakodate-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Hakuoh University",
    url: "http://www.hakuoh.ac.jp/",
  },
  {
    country: "JP",
    name: "Hamamatsu University",
    url: "http://www.hamamatsu-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Hamamatsu University School of Medicine",
    url: "http://www.hama-med.ac.jp/",
  },
  {
    country: "JP",
    name: "Hanazono University",
    url: "http://www.hanazono.ac.jp/",
  },
  {
    country: "JP",
    name: "Hannan University",
    url: "http://www.hannan-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Heisei International University",
    url: "http://www.hiu.ac.jp/",
  },
  {
    country: "JP",
    name: "Higashi Nippon International University",
    url: "http://www.tonichi-kokusai-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Hijiyama University",
    url: "http://www.hijiyama-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Himeji Dokkyo University",
    url: "http://www.himeji-du.ac.jp/",
  },
  {
    country: "JP",
    name: "Himeji Institute of Technology",
    url: "http://www.himeji-tech.ac.jp/",
  },
  {
    country: "JP",
    name: "Hirosaki Gakuin University",
    url: "http://www.hirogaku-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Hirosaki University",
    url: "http://www.hirosaki-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Hiroshima Bunkyo Women's University",
    url: "http://www.h-bunkyo.ac.jp/",
  },
  {
    country: "JP",
    name: "Hiroshima City University",
    url: "http://www.hiroshima-cu.ac.jp/",
  },
  {
    country: "JP",
    name: "Hiroshima Institute of Technology",
    url: "http://www.it-hiroshima.ac.jp/",
  },
  {
    country: "JP",
    name: "Hiroshima International University",
    url: "http://www.hirokoku-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Hiroshima Jogakuin University",
    url: "http://www.hju.ac.jp/",
  },
  {
    country: "JP",
    name: "Hiroshima Kokusai Gakuin University",
    url: "http://www.hkg.ac.jp/",
  },
  {
    country: "JP",
    name: "Hiroshima Prefectural University",
    url: "http://www.hiroshima-pu.ac.jp/",
  },
  {
    country: "JP",
    name: "Hiroshima Shudo University",
    url: "http://www.shudo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Hiroshima University",
    url: "http://www.hiroshima-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Hiroshima University of Economics",
    url: "http://www.hue.ac.jp/",
  },
  {
    country: "JP",
    name: "Hiroshima Women's University",
    url: "http://www.hirojo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Hitotsubashi University",
    url: "http://www.hit-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Hokkaido Information University",
    url: "http://www.do-johodai.ac.jp/",
  },
  {
    country: "JP",
    name: "Hokkaido Institute of Pharmaceutical Sciences",
    url: "http://www.hokuyakudai.ac.jp/",
  },
  {
    country: "JP",
    name: "Hokkaido Institute of Technology",
    url: "http://www.hit.ac.jp/",
  },
  {
    country: "JP",
    name: "Hokkaido Tokai University",
    url: "http://www.u-tokai.ac.jp/",
  },
  {
    country: "JP",
    name: "Hokkaido University",
    url: "http://www.hokudai.ac.jp/",
  },
  {
    country: "JP",
    name: "Hokkaido University of Education",
    url: "http://www.hokkyodai.ac.jp/",
  },
  {
    country: "JP",
    name: "Hokkaido University of Health Sciences",
    url: "http://www.hoku-iryo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Hokkaigakuen University",
    url: "http://www.hokkai-s-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Hokkaigakuen University of Kitami",
    url: "http://www.hokkai-k-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Hokuriku University",
    url: "http://www.hokuriku-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Hokusei Gakuen University",
    url: "http://www.hokusei.ac.jp/",
  },
  {
    country: "JP",
    name: "Hosei University",
    url: "http://www.hosei.ac.jp/",
  },
  {
    country: "JP",
    name: "Hoshi University",
    url: "http://www.hoshi.ac.jp/",
  },
  {
    country: "JP",
    name: "Hyogo College of Medicine",
    url: "http://www.hyo-med.ac.jp/",
  },
  {
    country: "JP",
    name: "Hyogo University",
    url: "http://www.hyogo-dai.ac.jp/",
  },
  {
    country: "JP",
    name: "Hyogo University of Education",
    url: "http://www.hyogo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Ibaraki Christian College",
    url: "http://www.icc.ac.jp/",
  },
  {
    country: "JP",
    name: "Ibaraki Prefectural University of Health Sciences",
    url: "http://www.ipu.ac.jp/",
  },
  {
    country: "JP",
    name: "Ibaraki University",
    url: "http://www.ibaraki.ac.jp/",
  },
  {
    country: "JP",
    name: "International Buddhist University",
    url: "http://www.shitennoji.ac.jp/",
  },
  {
    country: "JP",
    name: "International Budo University",
    url: "http://www.budo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "International Christian University",
    url: "http://www.icu.ac.jp/",
  },
  {
    country: "JP",
    name: "International University of Health and Welfare",
    url: "http://www.iuhw.ac.jp/",
  },
  {
    country: "JP",
    name: "International University of Japan",
    url: "http://www.iuj.ac.jp/",
  },
  {
    country: "JP",
    name: "International University of Kagoshima",
    url: "http://www.iuk.ac.jp/",
  },
  {
    country: "JP",
    name: "Ishinomaki Senshu University",
    url: "http://www.isenshu-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Iwaki Meisei University",
    url: "http://www.iwakimu.ac.jp/",
  },
  {
    country: "JP",
    name: "Iwate Medical University",
    url: "http://www.iwate-med.ac.jp/",
  },
  {
    country: "JP",
    name: "Iwate Prefectural University",
    url: "http://www.iwate-pu.ac.jp/",
  },
  {
    country: "JP",
    name: "Iwate University",
    url: "http://www.iwate-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Japan Advanced Institute of Science and Technology",
    url: "http://www.jaist.ac.jp/",
  },
  {
    country: "JP",
    name: "Japan College of Social Work",
    url: "http://www.shakaijigyo.ac.jp/",
  },
  {
    country: "JP",
    name: "Japanese Red Cross College of Nursing",
    url: "http://www.redcross.ac.jp/",
  },
  {
    country: "JP",
    name: "Japan Women's University",
    url: "http://www.jwu.ac.jp/",
  },
  {
    country: "JP",
    name: "Jichi Medical School",
    url: "http://www.jichi.ac.jp/",
  },
  {
    country: "JP",
    name: "Jikei University School of Medicine",
    url: "http://www.jikei.ac.jp/",
  },
  {
    country: "JP",
    name: "Jissen Women's University",
    url: "http://www.jissen.ac.jp/",
  },
  {
    country: "JP",
    name: "Jobu University",
    url: "http://www.jobu.ac.jp/",
  },
  {
    country: "JP",
    name: "Joetsu University of Education",
    url: "http://www.juen.ac.jp/",
  },
  {
    country: "JP",
    name: "Josai International University",
    url: "http://www.jiu.ac.jp/",
  },
  {
    country: "JP",
    name: "Josai University",
    url: "http://www.josai.ac.jp/",
  },
  {
    country: "JP",
    name: "Juntendo University",
    url: "http://www.juntendo.ac.jp/",
  },
  {
    country: "JP",
    name: "Kagawa Institute of Nutrition",
    url: "http://www.eiyo.ac.jp/",
  },
  {
    country: "JP",
    name: "Kagawa Medical School",
    url: "http://www.kms.ac.jp/",
  },
  {
    country: "JP",
    name: "Kagawa University",
    url: "http://www.kagawa-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kagoshima Immaculate Heart University",
    url: "http://www.juntan.k-junshin.ac.jp/jundai/",
  },
  {
    country: "JP",
    name: "Kagoshima University",
    url: "http://www.kagoshima-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kagoshima Women's College",
    url: "http://www.kwc-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kamakura Women's College",
    url: "http://www.kamakura-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kanagawa Dental College",
    url: "http://www.kdcnet.ac.jp/",
  },
  {
    country: "JP",
    name: "Kanagawa Institute of Technology",
    url: "http://www.kanagawa-it.ac.jp/",
  },
  {
    country: "JP",
    name: "Kanagawa University",
    url: "http://www.kanagawa-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kanazawa College of Art",
    url: "http://www.kanazawa-bidai.ac.jp/",
  },
  {
    country: "JP",
    name: "Kanazawa College of Economics",
    url: "http://www.kanazawa-eco.ac.jp/",
  },
  {
    country: "JP",
    name: "Kanazawa Gakuin University",
    url: "http://www.kanazawa-gu.ac.jp/",
  },
  {
    country: "JP",
    name: "Kanazawa Institute of Technology",
    url: "http://www.kanazawa-it.ac.jp/",
  },
  {
    country: "JP",
    name: "Kanazawa Medical University",
    url: "http://www.kanazawa-med.ac.jp/",
  },
  {
    country: "JP",
    name: "Kanazawa University",
    url: "http://www.kanazawa-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kanda University of International Studies",
    url: "http://www.kuis.ac.jp/",
  },
  {
    country: "JP",
    name: "Kansai Gaidai University",
    url: "http://www.kansai-gaidai-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kansai Medical University",
    url: "http://www.kmu.ac.jp/",
  },
  {
    country: "JP",
    name: "Kansai University",
    url: "http://www.kansai-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kansai University of International Studies",
    url: "http://www.kuins.ac.jp/",
  },
  {
    country: "JP",
    name: "Kansai University of Social Welfare",
    url: "http://www.kusw.ac.jp/",
  },
  {
    country: "JP",
    name: "Kanto Gakuen University",
    url: "http://www.kanto-gakuen.ac.jp/",
  },
  {
    country: "JP",
    name: "Kanto Gakuin University",
    url: "http://www.kanto-gakuin.ac.jp/",
  },
  {
    country: "JP",
    name: "Kawamura Gakuen Woman's University",
    url: "http://www.kgwu.ac.jp/",
  },
  {
    country: "JP",
    name: "Kawasaki Medical School",
    url: "http://www.kawasaki-m.ac.jp/",
  },
  {
    country: "JP",
    name: "Kawasaki University of Medical Care",
    url: "http://www.kawasaki-m.ac.jp/mw/",
  },
  {
    country: "JP",
    name: "Keiai University",
    url: "http://www.u-keiai.ac.jp/",
  },
  {
    country: "JP",
    name: "Keio University",
    url: "http://www.keio.ac.jp/",
  },
  {
    country: "JP",
    name: "Keisen Jogaku-en College",
    url: "http://www.keisen.ac.jp/",
  },
  {
    country: "JP",
    name: "Keiwa College",
    url: "http://www.keiwa-c.ac.jp/",
  },
  {
    country: "JP",
    name: "Kibi International University",
    url: "http://www.kiui.ac.jp/",
  },
  {
    country: "JP",
    name: "Kinjo Gakuin University",
    url: "http://www.kinjo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kinki University",
    url: "http://www.kindai.ac.jp/",
  },
  {
    country: "JP",
    name: "Kitakyushu University",
    url: "http://www.kitakyu-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kitami Institute of Technology",
    url: "http://www.kitami-it.ac.jp/",
  },
  {
    country: "JP",
    name: "Kitasato University",
    url: "http://www.kitasato-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kobe City University of ForeignStudies",
    url: "http://www.kobe-cufs.ac.jp/",
  },
  {
    country: "JP",
    name: "Kobe Design University",
    url: "http://www.kobe-du.ac.jp/",
  },
  {
    country: "JP",
    name: "Kobe Gakuin University",
    url: "http://www.kobegakuin.ac.jp/",
  },
  {
    country: "JP",
    name: "Kobe International University",
    url: "http://www.kobe-kiu.ac.jp/",
  },
  {
    country: "JP",
    name: "Kobe Jogakuin University",
    url: "http://www.kobe-c.ac.jp/",
  },
  {
    country: "JP",
    name: "Kobe Pharmaceutical University",
    url: "http://www.kobepharma-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kobe Shinwa Women's University",
    url: "http://www.kobe-shinwa.ac.jp/",
  },
  {
    country: "JP",
    name: "Kobe Shoin Women's University",
    url: "http://www.shoin.ac.jp/",
  },
  {
    country: "JP",
    name: "Kobe University",
    url: "http://www.kobe-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kobe University of Mercantile Marine",
    url: "http://www.kshosen.ac.jp/",
  },
  {
    country: "JP",
    name: "Kobe Women's University",
    url: "http://www.suma.kobe-wu.ac.jp/",
  },
  {
    country: "JP",
    name: "Kochi Medical School",
    url: "http://www.kochi-ms.ac.jp/",
  },
  {
    country: "JP",
    name: "Kochi University",
    url: "http://www.kochi-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kochi University of Technology",
    url: "http://www.kochi-tech.ac.jp/",
  },
  {
    country: "JP",
    name: "Kochi Women's University",
    url: "http://www.kochi-wu.ac.jp/",
  },
  {
    country: "JP",
    name: "Kogakkan University",
    url: "http://www.kogakkan-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kogakuin University",
    url: "http://www.kogakuin.ac.jp/",
  },
  {
    country: "JP",
    name: "Koka Women's College",
    url: "http://www.koka.ac.jp/",
  },
  {
    country: "JP",
    name: "Kokugakuin University",
    url: "http://www.kokugakuin.ac.jp/",
  },
  {
    country: "JP",
    name: "Kokushikan University",
    url: "http://www.kokushikan.ac.jp/",
  },
  {
    country: "JP",
    name: "Komazawa University",
    url: "http://www.komazawa-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Konan University",
    url: "http://www.konan-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Konan Women's University",
    url: "http://www.konan-wu.ac.jp/",
  },
  {
    country: "JP",
    name: "Korea University",
    url: "http://www.korea-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Koriyama Women's University and College",
    url: "http://www.koriyama-kgc.ac.jp/",
  },
  {
    country: "JP",
    name: "Koshien University",
    url: "http://www.koshien.ac.jp/",
  },
  {
    country: "JP",
    name: "Koyasan University",
    url: "http://www.koyasan-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kumamoto Gakuen University",
    url: "http://www.kumagaku.ac.jp/",
  },
  {
    country: "JP",
    name: "Kumamoto Institute of Technology",
    url: "http://www.knct.ac.jp/",
  },
  {
    country: "JP",
    name: "Kumamoto Prefectural University",
    url: "http://www.pu-kumamoto.ac.jp/",
  },
  {
    country: "JP",
    name: "Kumamoto University",
    url: "http://www.kumamoto-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kunitachi College of Music",
    url: "http://www.kunitachi.ac.jp/",
  },
  {
    country: "JP",
    name: "Kurashiki Sakuyo University",
    url: "http://www.ksu.ac.jp/",
  },
  {
    country: "JP",
    name: "Kurashiki University of Science and the Arts",
    url: "http://www.kusa.ac.jp/",
  },
  {
    country: "JP",
    name: "Kure University",
    url: "http://www.kure-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kurume Institute of Technology",
    url: "http://www.kurume-it.ac.jp/",
  },
  {
    country: "JP",
    name: "Kurume University",
    url: "http://www.kurume-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kushiro Public University of Economics",
    url: "http://www.kushiro-pu.ac.jp/",
  },
  {
    country: "JP",
    name: "Kwansei Gakuin University",
    url: "http://www.kwansei.ac.jp/",
  },
  {
    country: "JP",
    name: "Kwassui Women's College",
    url: "http://www.kwassui.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyorin University",
    url: "http://www.kyorin-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyoritsu Pharmaceutical University",
    url: "http://www.kyoritsu-ph.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyoritsu Woman's University",
    url: "http://www.kyoritsu-wu.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyoto Bunkyo University",
    url: "http://www.kbu.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyoto City University of Arts",
    url: "http://www.kcua.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyoto Gakuen University",
    url: "http://www.kyotogakuen-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyoto Institute of Technology",
    url: "http://www.kit.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyoto Pharmaceutical University",
    url: "http://www.kyoto-phu.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyoto Prefectural University",
    url: "http://www.kpu.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyoto Prefectural University of Medicine",
    url: "http://www.kpu-m.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyoto Sangyo University",
    url: "http://www.kyoto-su.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyoto Seika University",
    url: "http://www.kyoto-seika.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyoto Tachibana Women's University",
    url: "http://www.tachibana-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyoto University",
    url: "http://www.kyoto-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyoto University of Art and Design",
    url: "http://www.kyoto-art.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyoto University of Education",
    url: "http://www.kyokyo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyoto University of Foreign Studies",
    url: "http://www.kufs.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyoto Women's University",
    url: "http://www.kyoto-wu.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyushu Dental College",
    url: "http://www.kyu-dent.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyushu Institute of Design",
    url: "http://www.kyushu-id.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyushu Institute of Technology",
    url: "http://www.kyutech.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyushu International University",
    url: "http://www.kiu.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyushu Kyoritsu University",
    url: "http://www.kyukyo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyushu Sangyo University",
    url: "http://www.ip.kyusan-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyushu Tokai University",
    url: "http://www.ktokai-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyushu University",
    url: "http://www.kyushu-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyushu University of Nursing and SocialWelfare",
    url: "http://www.kyushu-ns.ac.jp/",
  },
  {
    country: "JP",
    name: "Kyushu Women's University",
    url: "http://www.kwuc.ac.jp/",
  },
  {
    country: "JP",
    name: "Matsumoto Dental University",
    url: "http://www.mdu.ac.jp/",
  },
  {
    country: "JP",
    name: "Matsusaka University",
    url: "http://www.mie-chukyo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Matsuyama University",
    url: "http://www.matsuyama-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Meiji College of Pharmacy",
    url: "http://www.my-pharm.ac.jp/",
  },
  {
    country: "JP",
    name: "Meiji Gakuin University",
    url: "http://www.meijigakuin.ac.jp/",
  },
  {
    country: "JP",
    name: "Meiji University",
    url: "http://www.meiji.ac.jp/",
  },
  {
    country: "JP",
    name: "Meiji University of Oriental Medicine",
    url: "http://www.meiji-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Meijo University",
    url: "http://www.meijo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Meikai University",
    url: "http://www.meikai.ac.jp/",
  },
  {
    country: "JP",
    name: "Meio University",
    url: "http://www.meio-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Meisei University",
    url: "http://www.meisei-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Mejiro University",
    url: "http://www.mejiro.ac.jp/",
  },
  {
    country: "JP",
    name: "Mie University",
    url: "http://www.mie-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Mimasaka Women's College",
    url: "http://www.mimasaka.ac.jp/",
  },
  {
    country: "JP",
    name: "Minamikyushu University",
    url: "http://www.nankyudai.ac.jp/",
  },
  {
    country: "JP",
    name: "Miyagi Gakuin Women's College",
    url: "http://www.mgu.ac.jp/",
  },
  {
    country: "JP",
    name: "Miyagi University",
    url: "http://www.myu.ac.jp/",
  },
  {
    country: "JP",
    name: "Miyagi University of Education",
    url: "http://www.miyakyo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Miyazaki Medical College",
    url: "http://www.miyazaki-med.ac.jp/",
  },
  {
    country: "JP",
    name: "Miyazaki Municipal University",
    url: "http://www.miyazaki-mu.ac.jp/",
  },
  {
    country: "JP",
    name: "Miyazaki Prefectural Nursing University",
    url: "http://www.mpu.ac.jp/",
  },
  {
    country: "JP",
    name: "Miyazaki University",
    url: "http://www.miyazaki-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Morioka College",
    url: "http://www.morioka-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Mukogawa Women's University",
    url: "http://www.mukogawa-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Muroran Institute of Technology",
    url: "http://www.muroran-it.ac.jp/",
  },
  {
    country: "JP",
    name: "Musashi Institute of Technology",
    url: "http://www.musashi-tech.ac.jp/",
  },
  {
    country: "JP",
    name: "Musashino Academy of Music",
    url: "http://www.musashino-music.ac.jp/",
  },
  {
    country: "JP",
    name: "Musashino Art University",
    url: "http://www.musabi.ac.jp/",
  },
  {
    country: "JP",
    name: "Musashino Women's University",
    url: "http://www.musashino-wu.ac.jp/",
  },
  {
    country: "JP",
    name: "Musashi University",
    url: "http://www.musashi.ac.jp/",
  },
  {
    country: "JP",
    name: "Nagano University",
    url: "http://www.nagano.ac.jp/",
  },
  {
    country: "JP",
    name: "Nagaoka University of Technology",
    url: "http://www.nagaokaut.ac.jp/",
  },
  {
    country: "JP",
    name: "Nagasaki Institute of Applied Science",
    url: "http://www.nias.ac.jp/",
  },
  {
    country: "JP",
    name: "Nagasaki Prefectural University",
    url: "http://www.nagasakipu.ac.jp/",
  },
  {
    country: "JP",
    name: "Nagasaki University",
    url: "http://www.nagasaki-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Nagoya City University",
    url: "http://www.nagoya-cu.ac.jp/",
  },
  {
    country: "JP",
    name: "Nagoya College of Music",
    url: "http://www.doho.ac.jp/~meion/",
  },
  {
    country: "JP",
    name: "Nagoya Economics University",
    url: "http://www.nagoya-ku.ac.jp/",
  },
  {
    country: "JP",
    name: "Nagoya Gakuin University",
    url: "http://www.ngu.ac.jp/",
  },
  {
    country: "JP",
    name: "Nagoya Institute of Technology",
    url: "http://www.nitech.ac.jp/",
  },
  {
    country: "JP",
    name: "Nagoya University",
    url: "http://www.nagoya-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Nagoya University of Arts",
    url: "http://www.nua.ac.jp/",
  },
  {
    country: "JP",
    name: "Nagoya University of Commerce and Business Administration",
    url: "http://www.nucba.ac.jp/",
  },
  {
    country: "JP",
    name: "Nagoya University of Foreign Studies",
    url: "http://www.nufs.nakanishi.ac.jp/",
  },
  {
    country: "JP",
    name: "Nagoya Women's University",
    url: "http://www.nagoya-wu.ac.jp/",
  },
  {
    country: "JP",
    name: "Nakamura Gakuen University",
    url: "http://www.nakamura-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Nanzan University",
    url: "http://www.nanzan-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Nara Medical University",
    url: "http://www.naramed-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Nara Sangyo University",
    url: "http://www.nara-su.ac.jp/",
  },
  {
    country: "JP",
    name: "Nara University",
    url: "http://www.nara-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Nara University of Commerce",
    url: "http://www.narauc.ac.jp/",
  },
  {
    country: "JP",
    name: "Nara University of Education",
    url: "http://www.nara-edu.ac.jp/",
  },
  {
    country: "JP",
    name: "Nara Women's University",
    url: "http://www.nara-wu.ac.jp/",
  },
  {
    country: "JP",
    name: "Naruto University of Education",
    url: "http://www.naruto-u.ac.jp/",
  },
  {
    country: "JP",
    name: "National Defence Medical College",
    url: "http://www.ndmc.ac.jp/",
  },
  {
    country: "JP",
    name: "National Fisheries University",
    url: "http://www.fish-u.ac.jp/",
  },
  {
    country: "JP",
    name: "National Institute of Fitness and Sports Kanoya",
    url: "http://www.nifs-k.ac.jp/",
  },
  {
    country: "JP",
    name: "Nihon Fukushi University",
    url: "http://www.n-fukushi.ac.jp/",
  },
  {
    country: "JP",
    name: "Nihon University",
    url: "http://www.nihon-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Niigata College of Pharmacy",
    url: "http://www.niigata-pharm.ac.jp/",
  },
  {
    country: "JP",
    name: "Niigata Sangyo University",
    url: "http://www.nsu.ac.jp/",
  },
  {
    country: "JP",
    name: "Niigata University",
    url: "http://www.niigata-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Niigata University of International and Information Studies",
    url: "http://www.nuis.ac.jp/",
  },
  {
    country: "JP",
    name: "Niigata University of Management",
    url: "http://www.niigataum.ac.jp/",
  },
  {
    country: "JP",
    name: "Nippon Bunri University",
    url: "http://www.nbu.ac.jp/",
  },
  {
    country: "JP",
    name: "Nippon Dental University",
    url: "http://www.ndu.ac.jp/",
  },
  {
    country: "JP",
    name: "Nippon Institute of Technology",
    url: "http://www.nit.ac.jp/",
  },
  {
    country: "JP",
    name: "Nippon Medical School",
    url: "http://www.nms.ac.jp/",
  },
  {
    country: "JP",
    name: "Nippon Sport Science University",
    url: "http://www.nittai.ac.jp/",
  },
  {
    country: "JP",
    name: "Nippon Veterinary and Animalscience University",
    url: "http://www.nvau.ac.jp/",
  },
  {
    country: "JP",
    name: "Nishikyushu University",
    url: "http://www.nisikyu-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Nishinippon Institute of Technology",
    url: "http://www.nishitech.ac.jp/",
  },
  {
    country: "JP",
    name: "Nisho Gakusha University",
    url: "http://www.nishogakusha-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Nortre Dame Seishin University",
    url: "http://www.ndsu.ac.jp/",
  },
  {
    country: "JP",
    name: "Notre Dame Women's College",
    url: "http://www.notredame.ac.jp/",
  },
  {
    country: "JP",
    name: "Obihiro University of Agriculture and Veterinary Medicine",
    url: "http://www.obihiro.ac.jp/",
  },
  {
    country: "JP",
    name: "Obirin University",
    url: "http://www.obirin.ac.jp/",
  },
  {
    country: "JP",
    name: "Ochanomizu Women's University",
    url: "http://www.ocha.ac.jp/",
  },
  {
    country: "JP",
    name: "Ohka Gakuen University",
    url: "http://www.ohkagakuen-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Ohtani Women's University",
    url: "http://www.ohtani-w.ac.jp/",
  },
  {
    country: "JP",
    name: "Ohu University",
    url: "http://www.ohu-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Oita Medical University",
    url: "http://www.oita-med.ac.jp/",
  },
  {
    country: "JP",
    name: "Oita University",
    url: "http://www.oita-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Oita University of Nursing and Health Sciences",
    url: "http://www.oita-nhs.ac.jp/",
  },
  {
    country: "JP",
    name: "Okayama Prefectural University",
    url: "http://www.oka-pu.ac.jp/",
  },
  {
    country: "JP",
    name: "Okayama Shoka University",
    url: "http://www.osu.ac.jp/",
  },
  {
    country: "JP",
    name: "Okayama University",
    url: "http://www.okayama-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Okayama University of Science",
    url: "http://www.ous.ac.jp/",
  },
  {
    country: "JP",
    name: "Okinawa International University",
    url: "http://www.okiu.ac.jp/",
  },
  {
    country: "JP",
    name: "Okinawa Prefectural University of Fine Arts",
    url: "http://www.okigei.ac.jp/",
  },
  {
    country: "JP",
    name: "Okinawa University",
    url: "http://www.okinawa-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka City University",
    url: "http://www.osaka-cu.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka College of Music",
    url: "http://www.daion.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka Dental University",
    url: "http://www.osaka-dent.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka Electro-Communication University",
    url: "http://www.osakac.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka Gakuin University",
    url: "http://www.osaka-gu.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka Institute of Technology",
    url: "http://www.oit.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka International University",
    url: "http://www.oiu.oiu.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka International University for Women",
    url: "http://www.oiuw.oiu.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka Medical College",
    url: "http://www.osaka-med.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka Prefectural University",
    url: "http://www.osakafu-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka Sangyo University",
    url: "http://www.osaka-sandai.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka Shoin Women's College",
    url: "http://www.osaka-shoin.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka University",
    url: "http://www.osaka-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka University of Arts",
    url: "http://www.osaka-geidai.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka University of Commerce",
    url: "http://www.daishodai.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka University of Economics",
    url: "http://www.osaka-ue.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka University of Economics & Law",
    url: "http://www.keiho-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka University of Education",
    url: "http://www.osaka-kyoiku.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka University of Foreign Studies",
    url: "http://www.osaka-gaidai.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka University of Health and Sport Sciences",
    url: "http://www.ouhs.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka University of Pharmaceutical Sciences",
    url: "http://www.oups.ac.jp/",
  },
  {
    country: "JP",
    name: "Osaka Women's University",
    url: "http://www.osaka-wu.ac.jp/",
  },
  {
    country: "JP",
    name: "Otani University",
    url: "http://www.otani.ac.jp/",
  },
  {
    country: "JP",
    name: "Otaru University of Commerce",
    url: "http://www.otaru-uc.ac.jp/",
  },
  {
    country: "JP",
    name: "Otemae University",
    url: "http://www.otemae.ac.jp/",
  },
  {
    country: "JP",
    name: "Otemon Gakuin University",
    url: "http://www.otemon.ac.jp/",
  },
  {
    country: "JP",
    name: "Otsuma Women's University",
    url: "http://www.otsuma.ac.jp/",
  },
  {
    country: "JP",
    name: "Polytechnic University",
    url: "http://www.uitec.ac.jp/",
  },
  {
    country: "JP",
    name: "Poole Gakuin University",
    url: "http://www.poole.ac.jp/",
  },
  {
    country: "JP",
    name: "Rakuno Gakuen University",
    url: "http://www.rakuno.ac.jp/",
  },
  {
    country: "JP",
    name: "Reitaku University",
    url: "http://www.reitaku-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Rikkyo University (St. Paul's University)",
    url: "http://www.rikkyo.ac.jp/",
  },
  {
    country: "JP",
    name: "Rissho University",
    url: "http://www.ris.ac.jp/",
  },
  {
    country: "JP",
    name: "Ritsumeikan Asia Pacific University",
    url: "http://www.apu.ac.jp/",
  },
  {
    country: "JP",
    name: "Ritsumeikan University",
    url: "http://www.ritsumei.ac.jp/",
  },
  {
    country: "JP",
    name: "Ryukoku University",
    url: "http://www.ryukoku.ac.jp/",
  },
  {
    country: "JP",
    name: "Ryutsu Keizai University",
    url: "http://www.rku.ac.jp/",
  },
  {
    country: "JP",
    name: "Saga Medical School",
    url: "http://www.saga-med.ac.jp/",
  },
  {
    country: "JP",
    name: "Sagami Women's University",
    url: "http://www.sagami-wu.ac.jp/",
  },
  {
    country: "JP",
    name: "Saga University",
    url: "http://www.saga-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Saitama Institute of Technology",
    url: "http://www.sit.ac.jp/",
  },
  {
    country: "JP",
    name: "Saitama Medical School",
    url: "http://www.saitama-med.ac.jp/",
  },
  {
    country: "JP",
    name: "Saitama Prefectural University",
    url: "http://www.spu.ac.jp/",
  },
  {
    country: "JP",
    name: "Saitama University",
    url: "http://www.saitama-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Sakushin Gakuin University",
    url: "http://www.sakushin-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Sankei University",
    url: "http://www.miyasankei-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Sanno University",
    url: "http://www.sanno.ac.jp/",
  },
  {
    country: "JP",
    name: "Sanyo Gakuen University",
    url: "http://www.sguc.ac.jp/",
  },
  {
    country: "JP",
    name: "Sapporo Gakuin University",
    url: "http://www.sgu.ac.jp/",
  },
  {
    country: "JP",
    name: "Sapporo International University",
    url: "http://www.siu.ac.jp/",
  },
  {
    country: "JP",
    name: "Sapporo Medical University",
    url: "http://www.sapmed.ac.jp/",
  },
  {
    country: "JP",
    name: "Sapporo University",
    url: "http://www.sapporo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Science University of Tokyo",
    url: "http://www.sut.ac.jp/",
  },
  {
    country: "JP",
    name: "Science University of Tokyo in Yamaguchi",
    url: "http://www.yama.sut.ac.jp/",
  },
  {
    country: "JP",
    name: "Seian University of Art & Design",
    url: "http://www.seian.ac.jp/",
  },
  {
    country: "JP",
    name: "Seigakuin University",
    url: "http://www.seigakuin-univ.ac.jp/",
  },
  {
    country: "JP",
    name: "Seijo University",
    url: "http://www.seijo.ac.jp/",
  },
  {
    country: "JP",
    name: "Seikei University",
    url: "http://www.seikei.ac.jp/",
  },
  {
    country: "JP",
    name: "Seinan Gakuin University",
    url: "http://www.seinan-gu.ac.jp/",
  },
  {
    country: "JP",
    name: "Seisen University",
    url: "http://www.seisen-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Seiwa College",
    url: "http://www.seiwa-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Sendai University",
    url: "http://www.sendaidaigaku.jp/",
  },
  {
    country: "JP",
    name: "Senshu University",
    url: "http://www.senshu-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Senzoku Gakuen College",
    url: "http://www.senzoku.ac.jp/",
  },
  {
    country: "JP",
    name: "Setsunan University",
    url: "http://www.setsunan.ac.jp/",
  },
  {
    country: "JP",
    name: "Shibaura Institute of Technology",
    url: "http://www.shibaura-it.ac.jp/",
  },
  {
    country: "JP",
    name: "Shiga University",
    url: "http://www.shiga-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Shiga University of Medical Science",
    url: "http://www.shiga-med.ac.jp/",
  },
  {
    country: "JP",
    name: "Shikoku Christian College",
    url: "http://www.sg-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Shikoku University",
    url: "http://www.shikoku-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Shimane University",
    url: "http://www.shimane-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Shimane University, Faculty of Medicine",
    url: "http://www.shimane-med.ac.jp/",
  },
  {
    country: "JP",
    name: "Shimonoseki City University",
    url: "http://www.shimonoseki-cu.ac.jp/",
  },
  {
    country: "JP",
    name: "Shinshu University",
    url: "http://www.shinshu-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Shirayuri Women's College",
    url: "http://www.shirayuri.ac.jp/",
  },
  {
    country: "JP",
    name: "Shizuoka Prefectural University",
    url: "http://www.u-shizuoka-ken.ac.jp/",
  },
  {
    country: "JP",
    name: "Shizuoka Sangyo University",
    url: "http://www.ssu.ac.jp/",
  },
  {
    country: "JP",
    name: "Shizuoka University",
    url: "http://www.shizuoka.ac.jp/",
  },
  {
    country: "JP",
    name: "Shokei College",
    url: "http://www.shokei-gakuen.ac.jp/shokeicol/",
  },
  {
    country: "JP",
    name: "Shonan Institute of Technology",
    url: "http://www.shonan-it.ac.jp/",
  },
  {
    country: "JP",
    name: "Showa College of Pharmaceutical Sciences",
    url: "http://www.shoyaku.ac.jp/",
  },
  {
    country: "JP",
    name: "Showa University",
    url: "http://www.showa-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Showa Women's University",
    url: "http://www.swu.ac.jp/",
  },
  {
    country: "JP",
    name: "Shuchiin College",
    url: "http://www.shuchiin.ac.jp/",
  },
  {
    country: "JP",
    name: "Shujitsu Women's University",
    url: "http://www.shujitsu.ac.jp/",
  },
  {
    country: "JP",
    name: "Shukutoku University",
    url: "http://www.shukutoku.ac.jp/",
  },
  {
    country: "JP",
    name: "Shumei University",
    url: "http://www.shumei-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Siebold University of Nagasaki",
    url: "http://www.sun.ac.jp/",
  },
  {
    country: "JP",
    name: "Soai University",
    url: "http://www.soai.ac.jp/",
  },
  {
    country: "JP",
    name: "Soka University",
    url: "http://www.soka.ac.jp/",
  },
  {
    country: "JP",
    name: "Sonoda Women's University",
    url: "http://www.sonoda-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Sophia University",
    url: "http://www.sophia.ac.jp/",
  },
  {
    country: "JP",
    name: "St. Andrew's University",
    url: "http://www.andrew.ac.jp/",
  },
  {
    country: "JP",
    name: "St. Luke' s College of Nursing",
    url: "http://www.slcn.ac.jp/",
  },
  {
    country: "JP",
    name: "St. Marianna University School of Medicine",
    url: "http://www.marianna-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Sugino Women's College",
    url: "http://www.sugino.ac.jp/daiindex.html",
  },
  {
    country: "JP",
    name: "Sugiyama Jogakuen University",
    url: "http://www.sugiyama-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Surugadai University",
    url: "http://www.surugadai.ac.jp/",
  },
  {
    country: "JP",
    name: "Suzuka International University",
    url: "http://www.suzuka-iu.ac.jp/",
  },
  {
    country: "JP",
    name: "Suzuka University of Medical Science",
    url: "http://www.suzuka-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Taisho University",
    url: "http://www.tais.ac.jp/",
  },
  {
    country: "JP",
    name: "Takachiho University",
    url: "http://www.takachiho.ac.jp/",
  },
  {
    country: "JP",
    name: "Takamatsu University",
    url: "http://www.takamatsu-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Takarazuka University of Art and Design",
    url: "http://www.takara-univ.ac.jp/",
  },
  {
    country: "JP",
    name: "Takasaki City University of Economics",
    url: "http://www.tcue.ac.jp/",
  },
  {
    country: "JP",
    name: "Takushoku University",
    url: "http://www.takushoku-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Tama Art University",
    url: "http://www.tamabi.ac.jp/",
  },
  {
    country: "JP",
    name: "Tamagawa University",
    url: "http://www.tamagawa.ac.jp/",
  },
  {
    country: "JP",
    name: "Tama Institute of Management and Information Sciences",
    url: "http://www.timis.ac.jp/",
  },
  {
    country: "JP",
    name: "Teikyo Heisei University",
    url: "http://www.teikyo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Teikyo University of Science and Technology",
    url: "http://www.ntu.ac.jp/",
  },
  {
    country: "JP",
    name: "Temple University Japan",
    url: "http://www.tuj.ac.jp/",
  },
  {
    country: "JP",
    name: "Tenri University",
    url: "http://www.tenri-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Tezukayama Gakuin University",
    url: "http://www.tezuka-gu.ac.jp/",
  },
  {
    country: "JP",
    name: "Tezukayama University",
    url: "http://www.tezukayama-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Toho College of Music",
    url: "http://www.toho-music.ac.jp/",
  },
  {
    country: "JP",
    name: "Toho Gakuen School of Music",
    url: "http://www.tohomusic.ac.jp/",
  },
  {
    country: "JP",
    name: "Tohoku Bunka Gakuen University",
    url: "http://www.tbgu.ac.jp/",
  },
  {
    country: "JP",
    name: "Tohoku College of Pharmacy",
    url: "http://www.tohoku-pharm.ac.jp/",
  },
  {
    country: "JP",
    name: "Tohoku Fukushi University",
    url: "http://www.tfu.ac.jp/",
  },
  {
    country: "JP",
    name: "Tohoku Gakuin University",
    url: "http://www.tohoku-gakuin.ac.jp/",
  },
  {
    country: "JP",
    name: "Tohoku Institute of Technology",
    url: "http://www.tohtech.ac.jp",
  },
  {
    country: "JP",
    name: "Tohoku University",
    url: "http://www.tohoku.ac.jp/",
  },
  {
    country: "JP",
    name: "Tohoku University of Art and Design",
    url: "http://www.tuad.ac.jp/",
  },
  {
    country: "JP",
    name: "Tohoku Women's College",
    url: "http://www.tojo.ac.jp/",
  },
  {
    country: "JP",
    name: "Toho University",
    url: "http://www.toho-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Tohwa University",
    url: "http://www.tohwa-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Toin University of Yokohama",
    url: "http://www.toin.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokai Gakuen University",
    url: "http://www.tokaigakuen-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokai University",
    url: "http://www.u-tokai.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokai University Educational System",
    url: "http://www.tokai.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokai Women's College",
    url: "http://www.tokaijoshi-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokiwa University",
    url: "http://www.tokiwa.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokoha Gakuen University",
    url: "http://www.tokoha-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokushima Bunri University",
    url: "http://www.bunri-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokushima University",
    url: "http://www.tokushima-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokuyama University",
    url: "http://www.tokuyama-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo College of Music",
    url: "http://www.tokyo-ondai.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo Denki University",
    url: "http://www.dendai.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo Dental College",
    url: "http://www.tdc.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo Engineering University",
    url: "http://www.teu.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo Gakugei University",
    url: "http://www.u-gakugei.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo Institute of Polytechnics",
    url: "http://www.t-kougei.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo Institute of Technology",
    url: "http://www.titech.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo International University",
    url: "http://www.tiu.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo Kasei University",
    url: "http://www.tokyo-kasei.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo Keizai University",
    url: "http://www.tku.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo Medical and Dental University",
    url: "http://www.tmd.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo Medical College",
    url: "http://www.tokyo-med.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo Metropolitan Institute of Technology",
    url: "http://www.tmit.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo Metropolitan University",
    url: "http://www.metro-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo Metropolitan University of Health Sciences",
    url: "http://www.metro-hs.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo National University of Fine Arts and Music",
    url: "http://www.geidai.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo University",
    url: "http://www.u-tokyo.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo University of Agriculture",
    url: "http://www.nodai.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo University of Agriculture and Technology",
    url: "http://www.tuat.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo University of Art and Design",
    url: "http://www.zokei.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo University of Fisheries",
    url: "http://www.tokyo-u-fish.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo University of Foreign Studies",
    url: "http://www.tufs.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo University of Information Sciences",
    url: "http://www.tuis.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo University of Mercantile Marine",
    url: "http://www.tosho-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo University of Pharmacy and Life Science",
    url: "http://www.toyaku.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo Woman's Christian University",
    url: "http://www.twcu.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo Women's College of Physical Education",
    url: "http://www.twcpe.ac.jp/",
  },
  {
    country: "JP",
    name: "Tokyo Women's Medial College",
    url: "http://www.twmu.ac.jp/",
  },
  {
    country: "JP",
    name: "Tomakomai Komazawa University",
    url: "http://www.t-komazawa.ac.jp/",
  },
  {
    country: "JP",
    name: "Tottori University",
    url: "http://www.tottori-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Tottori University of Environmental Studies",
    url: "http://www.kankyo-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Toyama Medical and Pharmaceutical University",
    url: "http://www.toyama-mpu.ac.jp/",
  },
  {
    country: "JP",
    name: "Toyama Prefectural University",
    url: "http://www.pu-toyama.ac.jp/",
  },
  {
    country: "JP",
    name: "Toyama University",
    url: "http://www.toyama-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Toyama University of International Studies",
    url: "http://www.tuins.ac.jp/",
  },
  {
    country: "JP",
    name: "Toyo Eiwa Women's University",
    url: "http://www.toyoeiwa.ac.jp/",
  },
  {
    country: "JP",
    name: "Toyo Gakuen University",
    url: "http://www.toyogakuen-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Toyohashi University of Technology",
    url: "http://www.tut.ac.jp/",
  },
  {
    country: "JP",
    name: "Toyota Technological Institute",
    url: "http://www.toyota-ti.ac.jp/",
  },
  {
    country: "JP",
    name: "Toyo University",
    url: "http://www.toyo.ac.jp/",
  },
  {
    country: "JP",
    name: "Tsuda College",
    url: "http://www.tsuda.ac.jp/",
  },
  {
    country: "JP",
    name: "Tsukuba University",
    url: "http://www.tsukuba.ac.jp/",
  },
  {
    country: "JP",
    name: "Tsurumi University",
    url: "http://www.tsurumi-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Tsuru University",
    url: "http://www.tsuru.ac.jp/",
  },
  {
    country: "JP",
    name: "Ueno Gakuen College",
    url: "http://www.uenogakuen.ac.jp/",
  },
  {
    country: "JP",
    name: "United Nations University",
    url: "http://www.unu.edu/",
  },
  {
    country: "JP",
    name: "University of Aizu",
    url: "http://www.u-aizu.ac.jp/",
  },
  {
    country: "JP",
    name: "University of Bunkyo",
    url: "http://www.u-bunkyo.ac.jp/",
  },
  {
    country: "JP",
    name: "University of East Asia",
    url: "http://www.toua-u.ac.jp/",
  },
  {
    country: "JP",
    name: "University of Electro-Communications",
    url: "http://www.uec.ac.jp/",
  },
  {
    country: "JP",
    name: "University of Marketing and Distribution Sciences",
    url: "http://www.umds.ac.jp/",
  },
  {
    country: "JP",
    name: "University of Occupational and Environmental Health, Japan",
    url: "http://www.uoeh-u.ac.jp/",
  },
  {
    country: "JP",
    name: "University of Shiga Prefecture",
    url: "http://www.usp.ac.jp/",
  },
  {
    country: "JP",
    name: "University of the Air",
    url: "http://www.u-air.ac.jp/",
  },
  {
    country: "JP",
    name: "University of the Ryukyus",
    url: "http://www.u-ryukyu.ac.jp/",
  },
  {
    country: "JP",
    name: "University of the Sacred Heart Tokyo",
    url: "http://www.u-sacred-heart.ac.jp/",
  },
  {
    country: "JP",
    name: "Utsunomiya University",
    url: "http://www.utsunomiya-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Wakayama Medical College",
    url: "http://www.wakayama-med.ac.jp/",
  },
  {
    country: "JP",
    name: "Wakayama University",
    url: "http://www.wakayama-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Wakkanai Hokusei Gakuen University",
    url: "http://www.wakhok.ac.jp/",
  },
  {
    country: "JP",
    name: "Wako University",
    url: "http://www.wako.ac.jp/",
  },
  {
    country: "JP",
    name: "Waseda University",
    url: "http://www.waseda.ac.jp/",
  },
  {
    country: "JP",
    name: "Wayo Women's University",
    url: "http://www.wayo.ac.jp/",
  },
  {
    country: "JP",
    name: "Women's College of Fine Arts",
    url: "http://www.joshibi.ac.jp/",
  },
  {
    country: "JP",
    name: "Yamagata University",
    url: "http://www.yamagata-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Yamaguchi Prefectural University",
    url: "http://www.yamaguchi-pu.ac.jp/",
  },
  {
    country: "JP",
    name: "Yamaguchi University",
    url: "http://www.yamaguchi-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Yamanashi Gakuin University",
    url: "http://www.ygu.ac.jp/",
  },
  {
    country: "JP",
    name: "Yamanashi Medical University",
    url: "http://www.yamanashi-med.ac.jp/",
  },
  {
    country: "JP",
    name: "Yamanashi University",
    url: "http://www.yamanashi.ac.jp/",
  },
  {
    country: "JP",
    name: "Yasuda Women's University",
    url: "http://www.yasuda-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Yokkaichi University",
    url: "http://www.yokkaichi-u.ac.jp/",
  },
  {
    country: "JP",
    name: "Yokohama City University",
    url: "http://www.yokohama-cu.ac.jp/",
  },
  {
    country: "JP",
    name: "Yokohama College of Commerce",
    url: "http://www.shodai.ac.jp/",
  },
  {
    country: "JP",
    name: "Yokohama National University",
    url: "http://www.ynu.ac.jp/",
  },
  {
    country: "KE",
    name: "Adventist University of Africa",
    url: "http://www.aua.ac.ke/",
  },
  {
    country: "KE",
    name: "Africa International University",
    url: "http://www.aiu.ac.ke/",
  },
  {
    country: "KE",
    name: "Africa Nazarene University",
    url: "http://www.anu.ac.ke/",
  },
  {
    country: "KE",
    name: "Aga Khan University",
    url: "http://www.aku.edu/",
  },
  {
    country: "KE",
    name: "Catholic University of Eastern Africa",
    url: "http://www.cuea.edu/",
  },
  {
    country: "KE",
    name: "Chuka University",
    url: "http://www.chuka.ac.ke/",
  },
  {
    country: "KE",
    name: "Daystar University",
    url: "http://www.daystar.ac.ke/",
  },
  {
    country: "KE",
    name: "Dedan Kimathi University of Technology",
    url: "http://www.dkut.ac.ke/",
  },
  {
    country: "KE",
    name: "Egerton University",
    url: "http://www.egerton.ac.ke/",
  },
  {
    country: "KE",
    name: "Great Lakes University of Kisumu",
    url: "http://www.gluk.ac.ke/",
  },
  {
    country: "KE",
    name: "Gretsa Universtiy",
    url: "http://www.gretsauniversity.ac.ke/",
  },
  {
    country: "KE",
    name: "Inoorero University",
    url: "http://www.iu.ac.ke/",
  },
  {
    country: "KE",
    name: "Jaramogi Oginga Odinga University of Science and Technology",
    url: "http://www.jooust.ac.ke/",
  },
  {
    country: "KE",
    name: "Jomo Kenyatta University of Agriculture and Technology",
    url: "http://www.jkuat.ac.ke/",
  },
  {
    country: "KE",
    name: "Kabarak University",
    url: "http://www.kabarak.ac.ke/",
  },
  {
    country: "KE",
    name: "Karatina University",
    url: "http://www.karatinauniversity.ac.ke/",
  },
  {
    country: "KE",
    name: "Kenya College of Accountancy",
    url: "http://www.kca.ac.ke/",
  },
  {
    country: "KE",
    name: "Kenya Medical Training College",
    url: "http://www.kmtc.ac.ke/",
  },
  {
    country: "KE",
    name: "Kenya Methodist University",
    url: "http://www.kemu.ac.ke/",
  },
  {
    country: "KE",
    name: "Kenyatta University",
    url: "http://www.ku.ac.ke/",
  },
  {
    country: "KE",
    name: "Kiriri Womens University of Science and Technology",
    url: "http://www.kwust.ac.ke/",
  },
  {
    country: "KE",
    name: "Kisii University",
    url: "http://www.kisiiuniversity.ac.ke/",
  },
  {
    country: "KE",
    name: "Laikipia University",
    url: "http://www.laikipia.ac.ke/",
  },
  {
    country: "KE",
    name: "Maasai Mara University",
    url: "http://www.mmarau.ac.ke/",
  },
  {
    country: "KE",
    name: "Maseno University",
    url: "http://www.maseno.ac.ke/",
  },
  {
    country: "KE",
    name: "Masinde Muliro University of Science and Technology",
    url: "http://www.mmust.ac.ke/",
  },
  {
    country: "KE",
    name: "Meru University of Science and Technology",
    url: "http://www.must.ac.ke/",
  },
  {
    country: "KE",
    name: "Moi University",
    url: "http://www.mu.ac.ke/",
  },
  {
    country: "KE",
    name: "Mount Kenya University",
    url: "http://www.mku.ac.ke/",
  },
  {
    country: "KE",
    name: "Multimedia University of Kenya",
    url: "http://www.mmu.ac.ke/",
  },
  {
    country: "KE",
    name: "Nairobi Institute of Business Studies",
    url: "http://www.nibs.ac.ke/",
  },
  {
    country: "KE",
    name: "Nairobi Technical Training Institute",
    url: "http://www.ntti.ac.ke/",
  },
  {
    country: "KE",
    name: "Pan Africa Christian University",
    url: "http://www.pacuniversity.ac.ke/",
  },
  {
    country: "KE",
    name: "Pioneer International University",
    url: "http://www.piu.ac.ke/",
  },
  {
    country: "KE",
    name: "Presbyterian University of East Africa",
    url: "http://www.puea.ac.ke/",
  },
  {
    country: "KE",
    name: "Pwani University",
    url: "http://www.pu.ac.ke/",
  },
  {
    country: "KE",
    name: "Riara University School of Business and Law",
    url: "http://www.riarauniversity.ac.ke/",
  },
  {
    country: "KE",
    name: "Scott Christian University",
    url: "http://www.scott.ac.ke/",
  },
  {
    country: "KE",
    name: "South Eastern Kenya University",
    url: "http://www.seku.ac.ke/",
  },
  {
    country: "KE",
    name: "St. Pauls University",
    url: "http://www.spu.ac.ke/",
  },
  {
    country: "KE",
    name: "Strathmore University",
    url: "http://www.strathmore.edu/",
  },
  {
    country: "KE",
    name: "Technical University of Kenya",
    url: "http://www.tukenya.ac.ke/",
  },
  {
    country: "KE",
    name: "Technical University of Mombasa",
    url: "http://www.tum.ac.ke/",
  },
  {
    country: "KE",
    name: "United States International University",
    url: "http://www.usiu.ac.ke/",
  },
  {
    country: "KE",
    name: "University of Eastern Africa, Baraton",
    url: "http://www.ueab.ac.ke/",
  },
  {
    country: "KE",
    name: "University of Eldoret",
    url: "http://www.uoeld.ac.ke/",
  },
  {
    country: "KE",
    name: "University of Kabianga",
    url: "http://www.kabianga.ac.ke/",
  },
  {
    country: "KE",
    name: "University of Nairobi",
    url: "http://www.uonbi.ac.ke/",
  },
  {
    country: "KE",
    name: "Uzima University College",
    url: "http://www.uzimauniversity.ac.ke/",
  },
  {
    country: "KE",
    name: "Zetech College",
    url: "http://www.zetechcollege.com/",
  },
  {
    country: "KG",
    name: "American University of Central Asia",
    url: "http://www.auca.kg/",
  },
  {
    country: "KG",
    name: "Asian Medical Institute",
    url: "http://www.asmi.edu.kg/",
  },
  {
    country: "KG",
    name: "Bishkek Humanities University",
    url: "http://www.bhu.kg/",
  },
  {
    country: "KG",
    name: "International Ataturk Alatoo University",
    url: "http://www.iaau.edu.kg/",
  },
  {
    country: "KG",
    name: "International University of Kyrgyzstan",
    url: "http://www.iuk.kg/",
  },
  {
    country: "KG",
    name: "Jalalabad State University Kyrgyzstan",
    url: "http://www.jasu.edu.kg/",
  },
  {
    country: "KG",
    name: "Kyrgyz National Agrarian University",
    url: "http://www.knau.kg/",
  },
  {
    country: "KG",
    name: "Kyrgyz National University",
    url: "http://www.university.kg/",
  },
  {
    country: "KG",
    name: "Kyrgyz Russian Slavic University",
    url: "http://www.krsu.edu.kg/",
  },
  {
    country: "KG",
    name: "Kyrgyz State Technical University",
    url: "http://www.kstu.kg/",
  },
  {
    country: "KG",
    name: "Kyrgyz State University of Construction, Transportation and Architecture",
    url: "http://www.ksucta.kg/",
  },
  {
    country: "KG",
    name: "Kyrgyz Turkish  Manas University",
    url: "http://www.manas.kg/",
  },
  {
    country: "KG",
    name: "Medical Faculty, Osh State University",
    url: "http://www.oshmed.com/",
  },
  {
    country: "KH",
    name: "Angkor University",
    url: "http://www.angkor.edu.kh/",
  },
  {
    country: "KH",
    name: "Asia Europe University",
    url: "http://www.aeu.edu.kh/",
  },
  {
    country: "KH",
    name: "Build Bright University",
    url: "http://www.bbu.edu.kh/",
  },
  {
    country: "KH",
    name: "Cambodian Mekong University",
    url: "http://www.mekong.edu.kh/",
  },
  {
    country: "KH",
    name: "Cambodia University of Specialties",
    url: "http://www.cus.edu.kh/",
  },
  {
    country: "KH",
    name: "Chamreun University of Poly Technology",
    url: "http://www.cup.edu.kh/",
  },
  {
    country: "KH",
    name: "International University",
    url: "http://www.iu.edu.kh/",
  },
  {
    country: "KH",
    name: "National Institute of Education",
    url: "http://www.ine.edu.kh/",
  },
  {
    country: "KH",
    name: "National University of Management",
    url: "http://www.num.edu.kh/",
  },
  {
    country: "KH",
    name: "Norton University",
    url: "http://www.norton.edu.kh/",
  },
  {
    country: "KH",
    name: "Pannnasatra University of Cambodia",
    url: "http://www.puc.edu.kh/",
  },
  {
    country: "KH",
    name: "Royal University of Agriculture",
    url: "http://www.rua.edu.kh/",
  },
  {
    country: "KH",
    name: "Royal University of Fine Arts",
    url: "http://www.rufa.edu.kh/",
  },
  {
    country: "KH",
    name: "Royal University of Law and Economics",
    url: "http://www.rule.edu.kh/",
  },
  {
    country: "KH",
    name: "Royal University of Phnom Penh",
    url: "http://www.rupp.edu.kh/",
  },
  {
    country: "KH",
    name: "Svey Reong University",
    url: "http://www.sru.edu.kh/",
  },
  {
    country: "KH",
    name: "University of Cambodia",
    url: "http://www.uc.edu.kh/",
  },
  {
    country: "KH",
    name: "University of Health and Science",
    url: "http://www.univ-sante.edu.kh/",
  },
  {
    country: "KH",
    name: "University of Technology Phnom Penh",
    url: "http://www.utpp.edu.kh/",
  },
  {
    country: "KH",
    name: "Western University",
    url: "http://www.western.edu.kh/",
  },
  {
    country: "KH",
    name: "Zaman University",
    url: "http://www.zamanuniversity.edu.kh/",
  },
  {
    country: "KN",
    name: "International University for Graduate Studies",
    url: "http://www.iugrad.edu.kn/",
  },
  {
    country: "KN",
    name: "Ross University Caribbean School of Veterinary Medicine",
    url: "http://www.rossu.edu/veterinary-school/",
  },
  {
    country: "KN",
    name: "St Theresa's Medical University",
    url: "http://www.stmu.org/",
  },
  {
    country: "KN",
    name: "University of Silvaner",
    url: "http://www.unisilvaner.info/",
  },
  {
    country: "KN",
    name: "Windsor University School of Medicine",
    url: "http://www.windsor.edu/",
  },
  {
    country: "KP",
    name: "Pyongyang University of Science and Technology",
    url: "http://www.pust.net/",
  },
  {
    country: "KR",
    name: "Ajou University",
    url: "http://www.ajou.ac.kr/",
  },
  {
    country: "KR",
    name: "Andong National University",
    url: "http://www.andong.ac.kr/",
  },
  {
    country: "KR",
    name: "Catholic University of Korea",
    url: "http://www.cuk.ac.kr/",
  },
  {
    country: "KR",
    name: "Catholic University of Pusan",
    url: "http://www.cup.ac.kr/",
  },
  {
    country: "KR",
    name: "Catholic University of Taegu-Hyosung",
    url: "http://www.cataegu.ac.kr/",
  },
  {
    country: "KR",
    name: "Changwon National University",
    url: "http://www.changwon.ac.kr/",
  },
  {
    country: "KR",
    name: "Cheju National University",
    url: "http://www.cheju.ac.kr/",
  },
  {
    country: "KR",
    name: "Cheju National University of Education",
    url: "http://www.cheju-e.ac.kr/",
  },
  {
    country: "KR",
    name: "Chinju National University",
    url: "http://www.chinju.ac.kr/",
  },
  {
    country: "KR",
    name: "Chinju National University of Education",
    url: "http://www.chinju-e.ac.kr/",
  },
  {
    country: "KR",
    name: "Chodang University",
    url: "http://www.chodang.ac.kr/",
  },
  {
    country: "KR",
    name: "Chonbuk National University",
    url: "http://www.chonbuk.ac.kr/",
  },
  {
    country: "KR",
    name: "Chonbuk Sanup University of Technology (Howon University)",
    url: "http://www.howon.ac.kr/",
  },
  {
    country: "KR",
    name: "Chongju National University of Education",
    url: "http://www.chongju-e.ac.kr/",
  },
  {
    country: "KR",
    name: "Chongju University",
    url: "http://www.chongju.ac.kr/",
  },
  {
    country: "KR",
    name: "Chonju National University of Education",
    url: "http://www.chonju-e.ac.kr/",
  },
  {
    country: "KR",
    name: "Chonnam National University",
    url: "http://www.chonnam.ac.kr/",
  },
  {
    country: "KR",
    name: "Chosun University",
    url: "http://www.chosun.ac.kr/",
  },
  {
    country: "KR",
    name: "Chunchon National University of Education",
    url: "http://www.cnue-e.ac.kr/",
  },
  {
    country: "KR",
    name: "Chung-Ang University",
    url: "http://www.cau.ac.kr/",
  },
  {
    country: "KR",
    name: "Chungbuk National University",
    url: "http://www.chungbuk.ac.kr/",
  },
  {
    country: "KR",
    name: "Chung-Ju National University",
    url: "http://www.chungju.ac.kr/",
  },
  {
    country: "KR",
    name: "Chungnam National University",
    url: "http://www.chungnam.ac.kr/",
  },
  {
    country: "KR",
    name: "Daebul University",
    url: "http://www.daebul.ac.kr/",
  },
  {
    country: "KR",
    name: "Dankook University",
    url: "http://www.dankook.ac.kr/",
  },
  {
    country: "KR",
    name: "Dong-A University",
    url: "http://www.donga.ac.kr/",
  },
  {
    country: "KR",
    name: "Dongduk Women's University",
    url: "http://www.dongduk.ac.kr/",
  },
  {
    country: "KR",
    name: "Dong Eui University",
    url: "http://www.dongeui.ac.kr/",
  },
  {
    country: "KR",
    name: "Dongguk University",
    url: "http://www.dongguk.ac.kr/",
  },
  {
    country: "KR",
    name: "Dongseo University",
    url: "http://www.dongseo.ac.kr/",
  },
  {
    country: "KR",
    name: "Dongshin University",
    url: "http://www.dongshinu.ac.kr/",
  },
  {
    country: "KR",
    name: "Dong Yang University of Technology",
    url: "http://www.dytc.ac.kr/",
  },
  {
    country: "KR",
    name: "Duksung Women's University",
    url: "http://www.duksung.ac.kr/",
  },
  {
    country: "KR",
    name: "Ewha Women's University",
    url: "http://www.ewha.ac.kr/",
  },
  {
    country: "KR",
    name: "Gwangju Catholic College",
    url: "http://www.gjcatholic.ac.kr/",
  },
  {
    country: "KR",
    name: "Gyeongju University",
    url: "http://www.gyeongju.ac.kr/",
  },
  {
    country: "KR",
    name: "Gyeongsang National University",
    url: "http://www.gsnu.ac.kr/",
  },
  {
    country: "KR",
    name: "Halla University",
    url: "http://www.halla.ac.kr/",
  },
  {
    country: "KR",
    name: "Hallym University",
    url: "http://www.hallym.ac.kr/",
  },
  {
    country: "KR",
    name: "Hankuk Aviation University",
    url: "http://www.hangkong.ac.kr/",
  },
  {
    country: "KR",
    name: "Hankuk University of Foreign Studies",
    url: "http://www.hufs.ac.kr/",
  },
  {
    country: "KR",
    name: "Hankyong National University",
    url: "http://www.hknu.ac.kr/",
  },
  {
    country: "KR",
    name: "Hannam University",
    url: "http://www.hannam.ac.kr/",
  },
  {
    country: "KR",
    name: "Hansei University",
    url: "http://www.hansei.ac.kr/",
  },
  {
    country: "KR",
    name: "Hanseo University",
    url: "http://www.hanseo.ac.kr/",
  },
  {
    country: "KR",
    name: "Hanshin University",
    url: "http://www.hanshin.ac.kr/",
  },
  {
    country: "KR",
    name: "Hansung University Seoul",
    url: "http://www.hansung.ac.kr/",
  },
  {
    country: "KR",
    name: "Hanyang University",
    url: "http://www.hanyang.ac.kr/",
  },
  {
    country: "KR",
    name: "Honam University",
    url: "http://www.honam.ac.kr/",
  },
  {
    country: "KR",
    name: "Hongik University",
    url: "http://www.hongik.ac.kr/",
  },
  {
    country: "KR",
    name: "Hoseo University",
    url: "http://www.hoseo.ac.kr/",
  },
  {
    country: "KR",
    name: "Hyupsung University",
    url: "http://www.hyupsung.ac.kr/",
  },
  {
    country: "KR",
    name: "Inchon National University of Education",
    url: "http://www.inchon-e.ac.kr/",
  },
  {
    country: "KR",
    name: "Inchon University",
    url: "http://www.inchon.ac.kr/",
  },
  {
    country: "KR",
    name: "Information and Communications University",
    url: "http://www.icu.ac.kr/",
  },
  {
    country: "KR",
    name: "Inha University",
    url: "http://www.inha.ac.kr/",
  },
  {
    country: "KR",
    name: "Inje University",
    url: "http://www.inje.ac.kr/",
  },
  {
    country: "KR",
    name: "Jeonju University",
    url: "http://www.jeonju.ac.kr/",
  },
  {
    country: "KR",
    name: "Kangnam University",
    url: "http://www.kangnam.ac.kr/",
  },
  {
    country: "KR",
    name: "Kangnung National University",
    url: "http://www.kangnung.ac.kr/",
  },
  {
    country: "KR",
    name: "Kangwon National University",
    url: "http://www.kangwon.ac.kr/",
  },
  {
    country: "KR",
    name: "Kaya University",
    url: "http://www.kaya.ac.kr/",
  },
  {
    country: "KR",
    name: "KDI School of Public Policy and Management",
    url: "http://www.kdischool.ac.kr/",
  },
  {
    country: "KR",
    name: "Keimyung University",
    url: "http://www.keimyung.ac.kr/",
  },
  {
    country: "KR",
    name: "Kongju National University",
    url: "http://www.kyongju.ac.kr/",
  },
  {
    country: "KR",
    name: "Kongju National University of Education",
    url: "http://www.kongju-e.ac.kr/",
  },
  {
    country: "KR",
    name: "Konkuk University",
    url: "http://www.konkuk.ac.kr/",
  },
  {
    country: "KR",
    name: "Konyang University",
    url: "http://www.konyang.ac.kr/",
  },
  {
    country: "KR",
    name: "Kookmin University",
    url: "http://www.kookmin.ac.kr/",
  },
  {
    country: "KR",
    name: "Korea Advanced Institute of Science & Technology",
    url: "http://www.kaist.ac.kr/",
  },
  {
    country: "KR",
    name: "Korea Maritime University",
    url: "http://www.kmaritime.ac.kr/",
  },
  {
    country: "KR",
    name: "Korea National Open University",
    url: "http://www.knou.ac.kr/",
  },
  {
    country: "KR",
    name: "Korea National University of Arts",
    url: "http://www.knua.ac.kr/",
  },
  {
    country: "KR",
    name: "Korea National University of Education",
    url: "http://www.knue.ac.kr/",
  },
  {
    country: "KR",
    name: "Korea National University of Physical Education",
    url: "http://www.knupe.ac.kr/",
  },
  {
    country: "KR",
    name: "Korea University",
    url: "http://www.korea.ac.kr/",
  },
  {
    country: "KR",
    name: "Kosin University",
    url: "http://www.kosin.ac.kr/",
  },
  {
    country: "KR",
    name: "Kumoh National University of Technology",
    url: "http://www.kumoh.ac.kr/",
  },
  {
    country: "KR",
    name: "Kunsan National University",
    url: "http://www.kunsan.ac.kr/",
  },
  {
    country: "KR",
    name: "Kwan Dong University",
    url: "http://www.kwandong.ac.kr/",
  },
  {
    country: "KR",
    name: "Kwangju National University of Education",
    url: "http://www.kwangju-e.ac.kr/",
  },
  {
    country: "KR",
    name: "Kwangju University",
    url: "http://www.kwangju.ac.kr/",
  },
  {
    country: "KR",
    name: "Kwangwoon University",
    url: "http://www.kwangwoon.ac.kr/",
  },
  {
    country: "KR",
    name: "Kyonggi University",
    url: "http://www.kyonggi.ac.kr/",
  },
  {
    country: "KR",
    name: "Kyongju University",
    url: "http://www.kyongju.ac.kr/",
  },
  {
    country: "KR",
    name: "Kyunghee University",
    url: "http://www.kyunghee.ac.kr/",
  },
  {
    country: "KR",
    name: "Kyungil University",
    url: "http://www.kyungil.ac.kr/",
  },
  {
    country: "KR",
    name: "Kyungnam University",
    url: "http://www.kyungnam.ac.kr/",
  },
  {
    country: "KR",
    name: "Kyungpook National University",
    url: "http://www.kyungpook.ac.kr/",
  },
  {
    country: "KR",
    name: "Kyungsung University",
    url: "http://www.kyungsung.ac.kr/",
  },
  {
    country: "KR",
    name: "Kyungwon University",
    url: "http://www.kyungwon.ac.kr/",
  },
  {
    country: "KR",
    name: "Miryang National University",
    url: "http://www.miryang.ac.kr/",
  },
  {
    country: "KR",
    name: "Mokpo National Maritime University",
    url: "http://www.mmu.ac.kr/",
  },
  {
    country: "KR",
    name: "Mokpo National University",
    url: "http://www.mokpo.ac.kr/",
  },
  {
    country: "KR",
    name: "Mokwon University Taejon",
    url: "http://www.mokwon.ac.kr/",
  },
  {
    country: "KR",
    name: "Myongji University",
    url: "http://www.myongji.ac.kr/",
  },
  {
    country: "KR",
    name: "Paichai University",
    url: "http://www.paichai.ac.kr/",
  },
  {
    country: "KR",
    name: "Pohang University of Science and Technology",
    url: "http://www.postech.ac.kr/",
  },
  {
    country: "KR",
    name: "Pukyong National University",
    url: "http://www.pknu.ac.kr/",
  },
  {
    country: "KR",
    name: "Pusan National University",
    url: "http://www.pusan.ac.kr/",
  },
  {
    country: "KR",
    name: "Pusan National University of Education",
    url: "http://www.pusan-e.ac.kr/",
  },
  {
    country: "KR",
    name: "Pusan University of Foreign Studies",
    url: "http://www.pufs.ac.kr/",
  },
  {
    country: "KR",
    name: "Pusan Women's University",
    url: "http://www.pwc.ac.kr/",
  },
  {
    country: "KR",
    name: "Pyongtaek University",
    url: "http://www.ptuniv.ac.kr/",
  },
  {
    country: "KR",
    name: "Sahmyook University",
    url: "http://www.syu.ac.kr/",
  },
  {
    country: "KR",
    name: "Samchok National University",
    url: "http://www.samchok.ac.kr/",
  },
  {
    country: "KR",
    name: "Sang Ji University",
    url: "http://www.sangji.ac.kr/",
  },
  {
    country: "KR",
    name: "Sang Myung University",
    url: "http://www.sangmyung.ac.kr/",
  },
  {
    country: "KR",
    name: "Sejong University",
    url: "http://www.sejong.ac.kr/",
  },
  {
    country: "KR",
    name: "Semyung University",
    url: "http://www.semyung.ac.kr/",
  },
  {
    country: "KR",
    name: "Seokyeong University",
    url: "http://www.skuniv.ac.kr/",
  },
  {
    country: "KR",
    name: "Seonam University",
    url: "http://www.seonam.ac.kr/",
  },
  {
    country: "KR",
    name: "Seoul City University",
    url: "http://www.uos.ac.kr/",
  },
  {
    country: "KR",
    name: "Seoul National University",
    url: "http://www.snu.ac.kr/",
  },
  {
    country: "KR",
    name: "Seoul National University of Education",
    url: "http://www.seoul-e.ac.kr/",
  },
  {
    country: "KR",
    name: "Seoul National University of Technology",
    url: "http://www.snut.ac.kr/",
  },
  {
    country: "KR",
    name: "Seoul Teological University",
    url: "http://www.stu.ac.kr/",
  },
  {
    country: "KR",
    name: "Seoul Women's University",
    url: "http://www.swu.ac.kr/",
  },
  {
    country: "KR",
    name: "Seowon University",
    url: "http://www.seowon.ac.kr/",
  },
  {
    country: "KR",
    name: "Silla University",
    url: "http://www.silla.ac.kr/",
  },
  {
    country: "KR",
    name: "Sogang University",
    url: "http://www.sogang.ac.kr/",
  },
  {
    country: "KR",
    name: "Sookmyung Women's University",
    url: "http://www.sookmyung.ac.kr/",
  },
  {
    country: "KR",
    name: "Soonchunhyang University",
    url: "http://www.sch.ac.kr/",
  },
  {
    country: "KR",
    name: "Soongsil University",
    url: "http://www.soongsil.ac.kr/",
  },
  {
    country: "KR",
    name: "Sunchon National University",
    url: "http://www.sunchon.ac.kr/",
  },
  {
    country: "KR",
    name: "Sungkyul University",
    url: "http://www.sungkyul.ac.kr/",
  },
  {
    country: "KR",
    name: "Sung Kyun Kwan University",
    url: "http://www.skku.ac.kr/",
  },
  {
    country: "KR",
    name: "Sungshin Women's University",
    url: "http://www.sungshin.ac.kr/",
  },
  {
    country: "KR",
    name: "Sunmoon University",
    url: "http://www.sunmoon.ac.kr/",
  },
  {
    country: "KR",
    name: "Suwon Catholic University",
    url: "http://www.suwoncatholic.ac.kr/",
  },
  {
    country: "KR",
    name: "Suwon University",
    url: "http://www.suwon.ac.kr/",
  },
  {
    country: "KR",
    name: "Taegu National University of Education",
    url: "http://www.taegu-e.ac.kr/",
  },
  {
    country: "KR",
    name: "Taegu University",
    url: "http://www.taegu.ac.kr/",
  },
  {
    country: "KR",
    name: "Taejon National University of Technology",
    url: "http://www.tnut.ac.kr/",
  },
  {
    country: "KR",
    name: "Taejon University",
    url: "http://www.taejon.ac.kr/",
  },
  {
    country: "KR",
    name: "Taeshin Christian University",
    url: "http://www.taeshin.ac.kr/",
  },
  {
    country: "KR",
    name: "Tongmyung University of Information Technology",
    url: "http://www.tit.ac.kr/",
  },
  {
    country: "KR",
    name: "Ulsan University",
    url: "http://www.ulsan.ac.kr/",
  },
  {
    country: "KR",
    name: "University of Science and Technology",
    url: "http://www.ust.ac.kr/",
  },
  {
    country: "KR",
    name: "Wonkwang University",
    url: "http://www.wonkwang.ac.kr/",
  },
  {
    country: "KR",
    name: "Woosuk University",
    url: "http://www.woosuk.ac.kr/",
  },
  {
    country: "KR",
    name: "Yeungnam University",
    url: "http://www.yeungnam.ac.kr/",
  },
  {
    country: "KR",
    name: "Yonsei University",
    url: "http://www.yonsei.ac.kr/",
  },
  {
    country: "KR",
    name: "Yosu National University",
    url: "http://www.yosu.ac.kr/",
  },
  {
    country: "KW",
    name: "American University of Kuwait",
    url: "http://www.auk.edu.kw/",
  },
  {
    country: "KW",
    name: "American University of Middle East",
    url: "http://www.aum.edu.kw/",
  },
  {
    country: "KW",
    name: "Arab Open University, Kuwait Branch",
    url: "http://www.aou.edu.kw/",
  },
  {
    country: "KW",
    name: "Australlian College of Kuwait",
    url: "http://www.ackonline.com/",
  },
  {
    country: "KW",
    name: "Gulf University for Science and Technology",
    url: "http://www.gust.edu.kw/",
  },
  {
    country: "KW",
    name: "Kuwait International Law School",
    url: "http://www.kilaw.edu.kw/",
  },
  {
    country: "KW",
    name: "Kuwait Maastricht Business School",
    url: "http://www.kmbs.edu.kw/",
  },
  {
    country: "KW",
    name: "Kuwait University",
    url: "http://www.kuniv.edu.kw/",
  },
  {
    country: "KY",
    name: "International College of the Cayman Islands",
    url: "http://www.icci.edu.ky/",
  },
  {
    country: "KY",
    name: "St. Matthews University",
    url: "http://www.stmatthews.edu/",
  },
  {
    country: "KZ",
    name: "Abai State University",
    url: "http://www.kaznpu.kz/",
  },
  {
    country: "KZ",
    name: "Abylai Khan University",
    url: "http://www.ablaikhan.kz/",
  },
  {
    country: "KZ",
    name: "Aktau State University",
    url: "http://www.aktsu.kz/",
  },
  {
    country: "KZ",
    name: "Al-Farabi Kazakh National University",
    url: "http://www.kaznu.kz/",
  },
  {
    country: "KZ",
    name: "Atyrau State University",
    url: "http://www.atgu.kz/",
  },
  {
    country: "KZ",
    name: "East Kazakhstan State University",
    url: "http://www.vkgu.kz/",
  },
  {
    country: "KZ",
    name: "East Kazakstan State Technical University",
    url: "http://www.ektu.kz/",
  },
  {
    country: "KZ",
    name: "Eurasian Institute of market",
    url: "http://www.eurazir.kz/",
  },
  {
    country: "KZ",
    name: "International Kazakh-Turkish University",
    url: "http://www.turkistan.kz/",
  },
  {
    country: "KZ",
    name: "Karaganda State Buketov University",
    url: "http://www.ksu.kz/",
  },
  {
    country: "KZ",
    name: "Karaganda State Technical University",
    url: "http://www.kstu.kz/",
  },
  {
    country: "KZ",
    name: "Kazak American University",
    url: "http://www.kau.kz/",
  },
  {
    country: "KZ",
    name: "Kazakh British Technical University",
    url: "http://www.kbtu.kz/",
  },
  {
    country: "KZ",
    name: "Kazakhstan Institute of Management, Economics, and Strategic Research",
    url: "http://www.kimep.kz/",
  },
  {
    country: "KZ",
    name: "Kazak National Technical University",
    url: "http://www.kazntu.kz/",
  },
  {
    country: "KZ",
    name: "Kazak State University of Agriculture",
    url: "http://www.agriun.almaty.kz/",
  },
  {
    country: "KZ",
    name: "Kostanai State University",
    url: "http://www.ksu.kst.kz/",
  },
  {
    country: "KZ",
    name: "L.N. Gumilyov Eurasian National University",
    url: "http://www.enu.kz/",
  },
  {
    country: "KZ",
    name: "North Kazakhstan State University",
    url: "http://www.nkzu.kz/",
  },
  {
    country: "KZ",
    name: "Pavlodar University",
    url: "http://www.psu.kz/",
  },
  {
    country: "KZ",
    name: "Semey State Medical University",
    url: "http://www.sgma.kz/",
  },
  {
    country: "KZ",
    name: "Semey State University",
    url: "http://www.semgu.kz/",
  },
  {
    country: "KZ",
    name: "Suleyman Demirel University",
    url: "http://www.sdu.edu.kz/",
  },
  {
    country: "KZ",
    name: "Turar Ryskulov Kazakh Economic University",
    url: "http://www.kazeu.kz/",
  },
  {
    country: "KZ",
    name: "University of International Business",
    url: "http://www.uib.kz/",
  },
  {
    country: "KZ",
    name: "Western Kazakhstan Agricultural University",
    url: "http://www.wkau.kz/",
  },
  {
    country: "KZ",
    name: "Zhetysu State University",
    url: "http://www.zhgu.kz/",
  },
  {
    country: "KZ",
    name: "Zhezkazgan Baikonurov University",
    url: "http://www.zhezu.kz/",
  },
  {
    country: "LA",
    name: "National University of Laos",
    url: "http://www.nuol.edu.la/",
  },
  {
    country: "LA",
    name: "Soutsaka Institute of management and Technology",
    url: "http://www.simt.edu.la/",
  },
  {
    country: "LB",
    name: "Al Imam Al-Ouzai University",
    url: "http://www.wakf.org/",
  },
  {
    country: "LB",
    name: "Al-Islah University",
    url: "http://www.islahonline.org/",
  },
  {
    country: "LB",
    name: "Al-Manar University",
    url: "http://www.mut.edu.lb/",
  },
  {
    country: "LB",
    name: "American University College of Technology",
    url: "http://www.aut.edu/",
  },
  {
    country: "LB",
    name: "American University of Beirut",
    url: "http://www.aub.edu.lb/",
  },
  {
    country: "LB",
    name: "American University of Science and Technology",
    url: "http://www.aust.edu.lb/",
  },
  {
    country: "LB",
    name: "Arab Open University",
    url: "http://www.arabou-lb.edu.lb/",
  },
  {
    country: "LB",
    name: "Arts, Sciences and Technology University",
    url: "http://www.aul.edu.lb/",
  },
  {
    country: "LB",
    name: "Balamand University",
    url: "http://www.balamand.edu.lb/",
  },
  {
    country: "LB",
    name: "Beirut Arab University",
    url: "http://www.bau.edu.lb/",
  },
  {
    country: "LB",
    name: "Business & Computer University College",
    url: "http://www.hu.edu.lb/",
  },
  {
    country: "LB",
    name: "Centre Universitaire de Technologie",
    url: "http://www.cut.edu.lb/",
  },
  {
    country: "LB",
    name: "Global University",
    url: "http://www.gu.edu.lb/",
  },
  {
    country: "LB",
    name: "Haigazian University",
    url: "http://www.haigazian.edu.lb/",
  },
  {
    country: "LB",
    name: "Hariri Canadian University",
    url: "http://www.hariricanadian.edu.lb/",
  },
  {
    country: "LB",
    name: "Holy Spirit University of Kaslik",
    url: "http://www.usek.edu.lb/",
  },
  {
    country: "LB",
    name: "Jinan University",
    url: "http://www.jinan.edu.lb/",
  },
  {
    country: "LB",
    name: "Lebanese American University",
    url: "http://www.lau.edu.lb/",
  },
  {
    country: "LB",
    name: "Lebanese International University",
    url: "http://www.liu.edu.lb/",
  },
  {
    country: "LB",
    name: "Lebanese University",
    url: "http://www.ul.edu.lb/",
  },
  {
    country: "LB",
    name: "Matn University",
    url: "http://www.matnu.edu.lb/",
  },
  {
    country: "LB",
    name: "Near East School of Theology",
    url: "http://www.theonest.edu.lb/",
  },
  {
    country: "LB",
    name: "Notre Dame University",
    url: "http://www.ndu.edu.lb/",
  },
  {
    country: "LB",
    name: "St. Joseph University Beirut",
    url: "http://www.usj.edu.lb/",
  },
  {
    country: "LC",
    name: "Spartan University of Health Sciences",
    url: "http://www.spartanmed.org/",
  },
  {
    country: "LI",
    name: "Internationle Akademie für Philosophie",
    url: "http://www.iap.li/",
  },
  {
    country: "LI",
    name: "Universität Liechtenstein",
    url: "http://www.uni.li/",
  },
  {
    country: "LK",
    name: "Arthur C Clarke Institute of Modern Technologies",
    url: "http://www.accimt.ac.lk/",
  },
  {
    country: "LK",
    name: "Buddhasravaka Bhikshu University",
    url: "http://www.bbu.ac.lk/",
  },
  {
    country: "LK",
    name: "Buddhist and Pali University of Sri Lanka",
    url: "http://www.bpu.ac.lk/",
  },
  {
    country: "LK",
    name: "Eastern University of Sri Lanka",
    url: "http://www.esn.ac.lk/",
  },
  {
    country: "LK",
    name: "Horizon College of Business and Technology",
    url: "http://www.horizoncampus.edu.lk/",
  },
  {
    country: "LK",
    name: "Kotelawala Defence University",
    url: "http://www.kdu.ac.lk/",
  },
  {
    country: "LK",
    name: "Open University of Sri Lanka",
    url: "http://www.ou.ac.lk/",
  },
  {
    country: "LK",
    name: "Postgraduate Institute of Agriculture (PGIA)",
    url: "http://www.pgia.ac.lk/",
  },
  {
    country: "LK",
    name: "Postgraduate Institute of Pali & Buddhist Studies (University of Kelaniya)",
    url: "http://www.kln.ac.lk/institutes/pgipbs/",
  },
  {
    country: "LK",
    name: "Rajarata University of Sri Lanka",
    url: "http://www.rjt.ac.lk/",
  },
  {
    country: "LK",
    name: "Sabaragamuwa University of Sri Lanka",
    url: "http://www.sab.ac.lk/",
  },
  {
    country: "LK",
    name: "South Asian Institute of Technology And Medicine (SAITM)",
    url: "http://www.saitm.edu.lk/",
  },
  {
    country: "LK",
    name: "South Eastern University of Sri Lanka",
    url: "http://www.seu.ac.lk/",
  },
  {
    country: "LK",
    name: "Sri Lanka Institute of Advanced Technical Education",
    url: "http://www.sliate.net/",
  },
  {
    country: "LK",
    name: "Sri Lanka Institute of Information Technology (SLIT)",
    url: "http://www.sliit.lk/",
  },
  {
    country: "LK",
    name: "University of Colombo",
    url: "http://www.cmb.ac.lk/",
  },
  {
    country: "LK",
    name: "University of Jaffna",
    url: "http://www.jfn.ac.lk/",
  },
  {
    country: "LK",
    name: "University of Kelaniya",
    url: "http://www.kln.ac.lk/",
  },
  {
    country: "LK",
    name: "University of Moratuwa",
    url: "http://www.mrt.ac.lk/",
  },
  {
    country: "LK",
    name: "University of Peradeniya",
    url: "http://www.pdn.ac.lk/",
  },
  {
    country: "LK",
    name: "University of Ruhuna",
    url: "http://www.ruh.ac.lk/",
  },
  {
    country: "LK",
    name: "University of Sri Jayawardenapura",
    url: "http://www.sjp.ac.lk/",
  },
  {
    country: "LK",
    name: "University of the Visual & Performing Arts",
    url: "http://www.vpa.ac.lk/",
  },
  {
    country: "LK",
    name: "Uva Wellassa University",
    url: "http://www.uwu.ac.lk/",
  },
  {
    country: "LK",
    name: "Wayamba University of Sri Lanka",
    url: "http://www.wyb.ac.lk/",
  },
  {
    country: "LR",
    name: "University of Liberia",
    url: "http://www.universityliberia.org/",
  },
  {
    country: "LS",
    name: "National University of Lesotho",
    url: "http://www.nul.ls/",
  },
  {
    country: "LT",
    name: "International School of Management",
    url: "http://www.ism.lt/",
  },
  {
    country: "LT",
    name: "Kaunas Medical Academy",
    url: "http://info.kma.lt/",
  },
  {
    country: "LT",
    name: "Kaunas University of Technology",
    url: "http://www.ktu.lt/",
  },
  {
    country: "LT",
    name: "Klaipeda University",
    url: "http://www.ku.lt/",
  },
  {
    country: "LT",
    name: "Lithuania Christian College",
    url: "http://www.lcc.lt/",
  },
  {
    country: "LT",
    name: "Lithunian Academy of Music and Theatre",
    url: "http://www.lmta.lt/",
  },
  {
    country: "LT",
    name: "Lithunian Institute of Physical Education",
    url: "http://www.lkka.lt/",
  },
  {
    country: "LT",
    name: "Lithunian University of Agriculture",
    url: "http://www.lzua.lt/",
  },
  {
    country: "LT",
    name: "Lithunian Veterinary Academy",
    url: "http://www.lva.lt/",
  },
  {
    country: "LT",
    name: "Military academy of Lithuania",
    url: "http://www.lka.lt/",
  },
  {
    country: "LT",
    name: "Mykolo Romerio university",
    url: "http://www.mruni.eu/",
  },
  {
    country: "LT",
    name: "Siauliai University",
    url: "http://www.su.lt/",
  },
  {
    country: "LT",
    name: "Vilnius Academy of Arts",
    url: "http://www.mch.mii.lt/more/vda/",
  },
  {
    country: "LT",
    name: "Vilnius Gediminas Technical University",
    url: "http://www.vgtu.lt/",
  },
  {
    country: "LT",
    name: "Vilnius Pedagogical University",
    url: "http://www.vpu.lt/",
  },
  {
    country: "LT",
    name: "Vilnius University",
    url: "http://www.vu.lt/",
  },
  {
    country: "LT",
    name: "Vytautas Magnus University",
    url: "http://www.vdu.lt/",
  },
  {
    country: "LU",
    name: "International University Institute of Luxembourg",
    url: "http://www.iuil.lu/",
  },
  {
    country: "LU",
    name: "University of Luxemburg",
    url: "http://www.uni.lu/",
  },
  {
    country: "LV",
    name: "Baltic International Academy",
    url: "http://www.bsa.edu.lv/",
  },
  {
    country: "LV",
    name: "Daugavpils University",
    url: "http://www.dau.lv/",
  },
  {
    country: "LV",
    name: "Higher School of Psychology",
    url: "http://www.psy.lv/",
  },
  {
    country: "LV",
    name: "Latvian Academy of Arts",
    url: "http://www.lma.lv/",
  },
  {
    country: "LV",
    name: "Latvian Academy of Culture",
    url: "http://www.lka.edu.lv/",
  },
  {
    country: "LV",
    name: "Latvian Academy of Music",
    url: "http://www.lmuza.lv/academy/",
  },
  {
    country: "LV",
    name: "Latvian Academy of Sports Education",
    url: "http://lspa.lanet.lv/",
  },
  {
    country: "LV",
    name: "Latvian Maritime Academy",
    url: "http://www.lama.lv/",
  },
  {
    country: "LV",
    name: "Latvian University of Agriculture",
    url: "http://www.llu.lv/",
  },
  {
    country: "LV",
    name: "Liepaja Pedagogical Higher School",
    url: "http://www.cs.lpu.lv/",
  },
  {
    country: "LV",
    name: "Medical Academy of Latvia",
    url: "http://www.aml.lv/",
  },
  {
    country: "LV",
    name: "National Defence Academy of Latvia",
    url: "http://www.mil.lv/",
  },
  {
    country: "LV",
    name: "Police Academy of Latvia",
    url: "http://www.polak.edu.lv/",
  },
  {
    country: "LV",
    name: "Rezekne Higher School",
    url: "http://www.ru.lv/",
  },
  {
    country: "LV",
    name: "Riga Aviation University",
    url: "http://www.rau.lv/",
  },
  {
    country: "LV",
    name: "Riga Teacher Training and Educational Management Academy",
    url: "http://www.rpiva.lv/",
  },
  {
    country: "LV",
    name: "Riga Technical University",
    url: "http://www.rtu.lv/",
  },
  {
    country: "LV",
    name: "School of Business and Finance",
    url: "http://www.ba.lv/",
  },
  {
    country: "LV",
    name: "Stockholm School of Economics in Riga",
    url: "http://www.sseriga.edu.lv/",
  },
  {
    country: "LV",
    name: "Transport and Telecommunication Institute",
    url: "http://www.tsi.lv/",
  },
  {
    country: "LV",
    name: "University of Latvia",
    url: "http://www.lu.lv/",
  },
  {
    country: "LV",
    name: "Vidzeme University College",
    url: "http://www.va.lv/",
  },
  {
    country: "LY",
    name: "Al-Asmarya University for Islamic Studies",
    url: "http://www.asmarya.edu.ly/",
  },
  {
    country: "LY",
    name: "Al-Jabal Al-Gharbi University",
    url: "http://www.jgu.edu.ly/",
  },
  {
    country: "LY",
    name: "Elmergib University",
    url: "http://www.elmergib.edu.ly/",
  },
  {
    country: "LY",
    name: "Libyan International Medical University (LIMU)",
    url: "http://www.limu.edu.ly/",
  },
  {
    country: "LY",
    name: "Libya Open University",
    url: "http://www.libopenuniv-edu.org/",
  },
  {
    country: "LY",
    name: "Omar Al-Mukhtar University",
    url: "http://www.omu.edu.ly/",
  },
  {
    country: "LY",
    name: "Sebha University",
    url: "http://www.sebhau.edu.ly/",
  },
  {
    country: "LY",
    name: "Sirte University",
    url: "http://www.su.edu.ly/",
  },
  {
    country: "LY",
    name: "University of Benghazi",
    url: "http://www.uob.edu.ly/",
  },
  {
    country: "LY",
    name: "University of Tripoli",
    url: "http://www.uot.edu.ly/",
  },
  {
    country: "LY",
    name: "Zawiya University",
    url: "http://www.zu.edu.ly/",
  },
  {
    country: "MA",
    name: "Al Akhawayn University",
    url: "http://www.aui.ma/",
  },
  {
    country: "MA",
    name: "Ecole des Hautes Etudes Commerciales MAROC",
    url: "http://www.hec.ac.ma/",
  },
  {
    country: "MA",
    name: "Ecole des Hautes Etudes en Gestion Informatique et Communication",
    url: "http://www.edhec.ac.ma/",
  },
  {
    country: "MA",
    name: "Ecole Mohammadia d'Ingénieurs",
    url: "http://www.emi.ac.ma/",
  },
  {
    country: "MA",
    name: "Ecole National d'Agriculture de Meknes",
    url: "http://www.enameknes.ac.ma/",
  },
  {
    country: "MA",
    name: "Ecole Nationale d'Architecture",
    url: "http://www.archi.ac.ma/",
  },
  {
    country: "MA",
    name: "Ecole Supérieure de Commerce et des Affaires",
    url: "http://www.esca.ac.ma/",
  },
  {
    country: "MA",
    name: "Ecole Superieure des Télécommunications",
    url: "http://www.suptelecom.net.ma/",
  },
  {
    country: "MA",
    name: "Ecole Supérieure d'Informatique et de Management",
    url: "http://www.hightech.edu/",
  },
  {
    country: "MA",
    name: "Institut des Hautes Etudes de Management",
    url: "http://www.hem.ac.ma/",
  },
  {
    country: "MA",
    name: "Institute of Management and Business Technology",
    url: "http://www.imbt.ma/",
  },
  {
    country: "MA",
    name: "Institut National des Postes et Télécommunications",
    url: "http://www.inpt.ac.ma/",
  },
  {
    country: "MA",
    name: "Institut Supérieur de Commerce et d'Administration des Entreprises",
    url: "http://www.groupeiscae.ma/",
  },
  {
    country: "MA",
    name: "Institut Supérieur de Management et de Technologie (MATCI)",
    url: "http://www.matci.ac.ma/",
  },
  {
    country: "MA",
    name: "International Institute for Higher Education",
    url: "http://www.iihem.ac.ma/",
  },
  {
    country: "MA",
    name: "ISFORT - Institut de Formation en Technologie Alimentaire",
    url: "http://www.isfort.ac.ma/",
  },
  {
    country: "MA",
    name: "Université Abdelmalek Essadi",
    url: "http://www.uae.ac.ma/",
  },
  {
    country: "MA",
    name: "Université Cadi Ayyad",
    url: "http://www.ucam.ac.ma/",
  },
  {
    country: "MA",
    name: "Université Chouaib Doukkali",
    url: "http://www.ucd.ac.ma/",
  },
  {
    country: "MA",
    name: "Université Hassan Ier - Settat",
    url: "http://www.uh1.ac.ma/",
  },
  {
    country: "MA",
    name: "Université Hassan II - Aïn Chock",
    url: "http://www.rectorat-uh2c.ac.ma/",
  },
  {
    country: "MA",
    name: "Université Hassan II - Mohammadia",
    url: "http://www.uh2m.ac.ma/",
  },
  {
    country: "MA",
    name: "Université Ibn Toufail",
    url: "http://www.univ-ibntofail.ac.ma/",
  },
  {
    country: "MA",
    name: "Université Ibn Zohr Agadir",
    url: "http://www.esta.ac.ma/",
  },
  {
    country: "MA",
    name: "Université Mohammed Ier",
    url: "http://www.univ-oujda.ac.ma/",
  },
  {
    country: "MA",
    name: "Université Mohammed V - Agdal",
    url: "http://www.um5a.ac.ma/",
  },
  {
    country: "MA",
    name: "Université Mohammed V - Souissi",
    url: "http://www.um5s.ac.ma/",
  },
  {
    country: "MA",
    name: "Université Moulay Ismail Meknès",
    url: "http://www.rumi.ac.ma/",
  },
  {
    country: "MA",
    name: "Université Sidi Mohammed Ben Abdellah",
    url: "http://www.usmba.ac.ma/",
  },
  {
    country: "MC",
    name: "University of Southern Europe (Monaco Business School)",
    url: "http://www.riviera.fr/usehome.htm",
  },
  {
    country: "MD",
    name: "Academy of Economic Studies of Moldova",
    url: "http://www.ase.md/",
  },
  {
    country: "MD",
    name: 'Balti State University "Alecu Russo',
    url: "http://www.usb.md/",
  },
  {
    country: "MD",
    name: "Comrat State University",
    url: "http://www.kdu.md/",
  },
  {
    country: "MD",
    name: "Free International University of Moldova",
    url: "http://www.ulim.md/",
  },
  {
    country: "MD",
    name: "Moldova Cooperative Trade University",
    url: "http://www.uccm.md/",
  },
  {
    country: "MD",
    name: "Moldova State Agricultural University",
    url: "http://www.uasm.md/",
  },
  {
    country: "MD",
    name: "Moldova State University",
    url: "http://www.usm.md/",
  },
  {
    country: "MD",
    name: 'Moldova State University of Medicine and Pharmacy "N. Testemitsanu',
    url: "http://www.usmf.md/",
  },
  {
    country: "MD",
    name: "Moldova Technical University",
    url: "http://www.utm.md/",
  },
  {
    country: "MD",
    name: 'Pedagogical State University "Ion Creanga',
    url: "http://upm.moldnet.md/",
  },
  {
    country: "MD",
    name: "Tiraspol State University",
    url: "http://www.ust.md/",
  },
  {
    country: "MD",
    name: "University of Applied Sciences of Moldova",
    url: "http://www.usam.md/",
  },
  {
    country: "ME",
    name: "University of Montenegro",
    url: "http://www.ucg.cg.ac.yu/",
  },
  {
    country: "MG",
    name: "Université d'Antananarivo",
    url: "http://www.univ-antananarivo.mg/",
  },
  {
    country: "MG",
    name: "Université d'Antsiranana",
    url: "http://www.univ-antsiranana.mg/",
  },
  {
    country: "MG",
    name: "Université de Fianarantsoa",
    url: "http://www.univ-fianar.mg/",
  },
  {
    country: "MG",
    name: "Université de Mahajanga",
    url: "http://www.univ-mahajanga.mg/",
  },
  {
    country: "MG",
    name: "Université de Toamasina",
    url: "http://www.univ-toamasina.mg/",
  },
  {
    country: "MG",
    name: "Université de Toliary",
    url: "http://www.univ-toliara.mg/",
  },
  {
    country: "MK",
    name: "FON University",
    url: "http://www.fon.edu.mk/",
  },
  {
    country: "MK",
    name: "South East European University",
    url: "http://www.seeu.edu.mk/",
  },
  {
    country: "MK",
    name: "State University of Tetova",
    url: "http://www.unite.edu.mk/",
  },
  {
    country: "MK",
    name: "St.Cyril and Methodius University",
    url: "http://www.ukim.edu.mk/",
  },
  {
    country: "MK",
    name: "St.Kliment Ohridski University",
    url: "http://www.uklo.edu.mk/",
  },
  {
    country: "MK",
    name: 'University for Information Science and Technology  "St. Paul The Apostle',
    url: "http://www.uist.edu.mk/",
  },
  {
    country: "MK",
    name: "University Goce Delcev",
    url: "http://www.ugd.edu.mk/",
  },
  {
    country: "ML",
    name: "Université de Bamako",
    url: "http://www.ml.refer.org/u-bamako/",
  },
  {
    country: "MM",
    name: "International Theravada Buddhist Missionary University",
    url: "http://www.itbmu.org.mm/",
  },
  {
    country: "MM",
    name: "Mandalay Technological University",
    url: "http://www.most.gov.mm/mtu/",
  },
  {
    country: "MM",
    name: "Myanmar Aerospace Engineering University",
    url: "http://www.most.gov.mm/maeu/",
  },
  {
    country: "MM",
    name: "Technological University (Banmaw)",
    url: "http://www.most.gov.mm/banmawtu/",
  },
  {
    country: "MM",
    name: "Technological University (Dawei)",
    url: "http://www.most.gov.mm/daweitu/",
  },
  {
    country: "MM",
    name: "Technological University (Hinthada)",
    url: "http://www.most.gov.mm/hinthadatu/",
  },
  {
    country: "MM",
    name: "Technological University (Hmawbi)",
    url: "http://www.most.gov.mm/hmawbitu/",
  },
  {
    country: "MM",
    name: "Technological University (Hpaan)",
    url: "http://www.most.gov.mm/hpaantu/",
  },
  {
    country: "MM",
    name: "Technological University (Kalay)",
    url: "http://www.most.gov.mm/kalaytu/",
  },
  {
    country: "MM",
    name: "Technological University (Kyaing Tong)",
    url: "http://www.most.gov.mm/kyaingtongtu/",
  },
  {
    country: "MM",
    name: "Technological University (Kyaukse)",
    url: "http://www.most.gov.mm/kyauksetu/",
  },
  {
    country: "MM",
    name: "Technological University (Lashio)",
    url: "http://www.most.gov.mm/lashiotu/",
  },
  {
    country: "MM",
    name: "Technological University (Loikaw)",
    url: "http://www.most.gov.mm/loikawtu/",
  },
  {
    country: "MM",
    name: "Technological University (Magway)",
    url: "http://www.most.gov.mm/magwaytu/",
  },
  {
    country: "MM",
    name: "Technological University (Mandalay)",
    url: "http://www.most.gov.mm/mdytu/",
  },
  {
    country: "MM",
    name: "Technological University (Maubin)",
    url: "http://www.most.gov.mm/maubintu/",
  },
  {
    country: "MM",
    name: "Technological University (Mawlamyaing)",
    url: "http://www.most.gov.mm/mawlamyaingtu/",
  },
  {
    country: "MM",
    name: "Technological University (Meikhtila)",
    url: "http://www.most.gov.mm/meikhtilatu/",
  },
  {
    country: "MM",
    name: "Technological University (Monywa)",
    url: "http://www.most.gov.mm/monywatu/",
  },
  {
    country: "MM",
    name: "Technological University (Myeik)",
    url: "http://www.most.gov.mm/myeiktu/",
  },
  {
    country: "MM",
    name: "Technological University (Myitkyina)",
    url: "http://www.most.gov.mm/myitkyinatu/",
  },
  {
    country: "MM",
    name: "Technological University (Pakokku)",
    url: "http://www.most.gov.mm/pakokkutu/",
  },
  {
    country: "MM",
    name: "Technological University (Pathein)",
    url: "http://www.most.gov.mm/patheintu/",
  },
  {
    country: "MM",
    name: "Technological University (Pinlon)",
    url: "http://www.most.gov.mm/pinlontu/",
  },
  {
    country: "MM",
    name: "Technological University (Pyay)",
    url: "http://www.most.gov.mm/ptu/",
  },
  {
    country: "MM",
    name: "Technological University (Sittwe)",
    url: "http://www.most.gov.mm/sittwetu/",
  },
  {
    country: "MM",
    name: "Technological University (Taunggyi)",
    url: "http://www.most.gov.mm/taunggyitu/",
  },
  {
    country: "MM",
    name: "Technological University (Taungoo)",
    url: "http://www.most.gov.mm/taungootu/",
  },
  {
    country: "MM",
    name: "Technological University (Thanlyin)",
    url: "http://www.most.gov.mm/thanlyintu/",
  },
  {
    country: "MM",
    name: "Technological University (West Yangon)",
    url: "http://www.most.gov.mm/wytu/",
  },
  {
    country: "MM",
    name: "Technological University (Yangon)",
    url: "http://www.most.gov.mm/ytu/",
  },
  {
    country: "MM",
    name: "University of Computer Studies, Yangon",
    url: "http://www.ucsy.edu.mm/",
  },
  {
    country: "MM",
    name: "University of Medicine 1, Yangon",
    url: "http://www.um1ygn.edu.mm/",
  },
  {
    country: "MM",
    name: "University of Medicine Magway",
    url: "http://www.ummg.edu.mm/",
  },
  {
    country: "MN",
    name: "Health sciences University of Mongolia",
    url: "http://www.hsum-ac.mn/",
  },
  {
    country: "MN",
    name: "Institute of Commerce and Business",
    url: "http://www.icb.edu.mn/",
  },
  {
    country: "MN",
    name: "Mongolian National University",
    url: "http://www.num.edu.mn/",
  },
  {
    country: "MN",
    name: "Mongolian State University of Agriculture",
    url: "http://www.msua.edu.mn/",
  },
  {
    country: "MN",
    name: "Mongolian State University of Arts and Culture",
    url: "http://www.msuac.edu.mn/",
  },
  {
    country: "MN",
    name: "Mongolian State University of Education",
    url: "http://www.msue.edu.mn/",
  },
  {
    country: "MN",
    name: "Mongolian University of Science and Technology",
    url: "http://www.must.edu.mn/",
  },
  {
    country: "MN",
    name: "Orkhon University",
    url: "http://www.orkhon.edu.mn/",
  },
  {
    country: "MN",
    name: "Ulaanbaatar University",
    url: "http://www.ulaanbaatar.edu.mn/",
  },
  {
    country: "MN",
    name: "University of the Humanities",
    url: "http://www.humanities.mn/",
  },
  {
    country: "MO",
    name: "Macau Polytechnic Institute",
    url: "http://www.ipm.edu.mo/",
  },
  {
    country: "MO",
    name: "University of Macau",
    url: "http://www.umac.mo/",
  },
  {
    country: "MO",
    name: "University of Saint Joseph",
    url: "http://www.usj.edu.mo/",
  },
  {
    country: "MQ",
    name: "Université des Antilles et de la Guyane",
    url: "http://www.univ-ag.fr/",
  },
  {
    country: "MR",
    name: "Université de Nouakchott",
    url: "http://www.univ-nkc.mr/",
  },
  {
    country: "MS",
    name: "University of Science, Arts and Technology",
    url: "http://www.usat.ms/",
  },
  {
    country: "MT",
    name: "European Institute of Education",
    url: "http://www.eieonline.com/",
  },
  {
    country: "MT",
    name: "International Tourism Institute",
    url: "http://www.itismalta.com/",
  },
  {
    country: "MT",
    name: "University of Malta",
    url: "http://www.um.edu.mt/",
  },
  {
    country: "MU",
    name: "University of Mauritius",
    url: "http://www.uom.ac.mu/",
  },
  {
    country: "MU",
    name: "University of Technology",
    url: "http://www.utm.ac.mu/",
  },
  {
    country: "MV",
    name: "Cyryx College",
    url: "http://www.cyryxcollege.edu.mv/",
  },
  {
    country: "MV",
    name: "Maldives National University",
    url: "http://www.mnu.edu.mv/",
  },
  {
    country: "MV",
    name: "Mandhu College",
    url: "http://www.mandhu.com/edu/",
  },
  {
    country: "MW",
    name: "Catholic University of Malawi",
    url: "http://www.cunima.net/",
  },
  {
    country: "MW",
    name: "Mzuzu University",
    url: "http://www.mzuni.ac.mw/",
  },
  {
    country: "MW",
    name: "University of Livingstonia",
    url: "http://www.ulivingstonia.com/",
  },
  {
    country: "MW",
    name: "University of Malawi",
    url: "http://www.unima.mw/",
  },
  {
    country: "MX",
    name: "Benemerita Universidad Autónoma de Puebla",
    url: "http://www.buap.mx/",
  },
  {
    country: "MX",
    name: "Centro de Enseñanza Técnica Industrial",
    url: "http://www.ceti.mx/",
  },
  {
    country: "MX",
    name: "Centro de Estudios Universitarios Monterrey",
    url: "http://www.ceu.edu.mx/",
  },
  {
    country: "MX",
    name: "Centro de Estudios Universitarios Xochicalco",
    url: "http://www.ceux.mx/",
  },
  {
    country: "MX",
    name: "Centro Universitario Ixtlahuaca",
    url: "http://www.ceunix.com.mx/",
  },
  {
    country: "MX",
    name: "CETYS Universidad",
    url: "http://www.cetys.mx/",
  },
  {
    country: "MX",
    name: "Dirección General de Institutos Tecnológicos",
    url: "http://www.dgit.gob.mx/",
  },
  {
    country: "MX",
    name: "El Colegio de México",
    url: "http://www.colmex.mx/",
  },
  {
    country: "MX",
    name: "Escuela Bancaria y Comercial",
    url: "http://www.ebc.mx/",
  },
  {
    country: "MX",
    name: "Escuela Nacional de Estudios Superiores Unidad León",
    url: "http://www.enes.unam.mx/",
  },
  {
    country: "MX",
    name: "Instituto de Estudios Superiores de la Sierra",
    url: "http://www.ties.com.mx/",
  },
  {
    country: "MX",
    name: "Instituto Politécnico Nacional",
    url: "http://www.ipn.edu.mx/",
  },
  {
    country: "MX",
    name: "Instituto Politécnico Nacional, Campus Guanajuato",
    url: "http://www.upiig.ipn.mx/",
  },
  {
    country: "MX",
    name: "Instituto Tecnológico Autonómo de México",
    url: "http://www.itam.mx/",
  },
  {
    country: "MX",
    name: "Instituto Tecnológico de Aguascalientes",
    url: "http://www.ita.mx/",
  },
  {
    country: "MX",
    name: "Instituto Tecnológico de Celaya",
    url: "http://www.itc.mx/",
  },
  {
    country: "MX",
    name: "Instituto Tecnologico de Durango",
    url: "http://www.itdurango.edu.mx/",
  },
  {
    country: "MX",
    name: "Instituto Tecnológico de León",
    url: "http://www.itleon.edu.mx/",
  },
  {
    country: "MX",
    name: "Instituto Tecnologico de Minatitlan",
    url: "http://www.itmina.edu.mx/",
  },
  {
    country: "MX",
    name: "Instituto Tecnológico de Morelia",
    url: "http://www.itmorelia.edu.mx/",
  },
  {
    country: "MX",
    name: "Instituto Tecnológico de Querétaro",
    url: "http://www.itq.edu.mx/",
  },
  {
    country: "MX",
    name: "Instituto Tecnológico de San Luis Potosí",
    url: "http://www.itslp.edu.mx/",
  },
  {
    country: "MX",
    name: "Instituto Tecnológico de Sonora",
    url: "http://www.itson.mx/",
  },
  {
    country: "MX",
    name: "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM)",
    url: "http://www.itesm.mx/",
  },
  {
    country: "MX",
    name: "Instituto Tecnológico y de Estudios Superiores de Occidente",
    url: "http://www.iteso.mx/",
  },
  {
    country: "MX",
    name: "Politécnico de Guanajuato",
    url: "http://www.poligto.edu.mx/",
  },
  {
    country: "MX",
    name: "Tecnologico de Baja California, Universidad",
    url: "http://www.tecbc.mx/",
  },
  {
    country: "MX",
    name: "Universidad Anáhuac",
    url: "http://www.anahuac.mx/",
  },
  {
    country: "MX",
    name: "Universidad Anáhuac del Sur",
    url: "http://www.uas.mx/",
  },
  {
    country: "MX",
    name: 'Universidad Autónoma Agraria "Antonio Narro',
    url: "http://www.uaaan.mx/",
  },
  {
    country: "MX",
    name: 'Universidad Autónoma "Benito Juárez" de Oaxaca',
    url: "http://www.uabjo.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Aguascalientes",
    url: "http://www.uaa.mx:8001/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Baja California",
    url: "http://www.uabc.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Baja California Sur",
    url: "http://www.uabcs.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Campeche",
    url: "http://www.uacam.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Chapingo",
    url: "http://www.chapingo.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Chiapas",
    url: "http://www.unach.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Chihuahua",
    url: "http://www.uach.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Ciudad Juárez",
    url: "http://www.uacj.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Coahuila",
    url: "http://www.uadec.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autonoma  de Durango",
    url: "http://www.uad.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Fresnillo",
    url: "http://www.uaf.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Guadalajara",
    url: "http://www.uag.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Guerrero",
    url: "http://www.uagro.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de la Ciudad de México",
    url: "http://www.uacm.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de la Laguna",
    url: "http://www.ual.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma del Carmen",
    url: "http://www.unacar.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma del Estado de Hidalgo",
    url: "http://www.reduaeh.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma del Estado de México",
    url: "http://www.uaemex.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma del Estado de Morelos",
    url: "http://www.uaem.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma del Noreste",
    url: "http://www.uane.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Nayarit",
    url: "http://www.uan.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Nuevo León",
    url: "http://www.uanl.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Querétaro",
    url: "http://www.uaq.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de San Luis Potosí",
    url: "http://www.uaslp.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Sinaloa",
    url: "http://www.uasnet.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Tamaulipas",
    url: "http://www.uat.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Tlaxcala",
    url: "http://www.uatx.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Veracruz Villa Rica",
    url: "http://www.univillarica.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Yucatán",
    url: "http://www.uady.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma de Zacatecas",
    url: "http://www.reduaz.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autonoma España de Durango",
    url: "http://www.uaed.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma Metropolitana",
    url: "http://www.uam.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma Metropolitana - Azcapotzalco",
    url: "http://www-azc.uam.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma Metropolitana - Iztapalapa",
    url: "http://www.iztapalapa.uam.mx/",
  },
  {
    country: "MX",
    name: "Universidad Autónoma Metropolitana - Xochimilco",
    url: "http://cueyatl.uam.mx/",
  },
  {
    country: "MX",
    name: "Universidad Champagnat",
    url: "http://www.champagnat.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Chapultepéc",
    url: "http://www.univchapultepec.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Cientifica Latinoamericana de Hidalgo",
    url: "http://www.uclah.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Cristóbal Colón",
    url: "http://www.ver.ucc.mx/",
  },
  {
    country: "MX",
    name: "Universidad Cuauhtémoc",
    url: "http://www.cuauhtemoc.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad de Celaya",
    url: "http://www.udec.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad de Ciencias y Artes de Chiapas",
    url: "http://www.unicach.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad de Colima",
    url: "http://www.ucol.mx/",
  },
  {
    country: "MX",
    name: "Universidad de Comunicación Avanzada",
    url: "http://www.unica.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad de Cuautitlan Izcalli",
    url: "http://www.udeci.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad de Guadalajara",
    url: "http://www.udg.mx/",
  },
  {
    country: "MX",
    name: "Universidad de Guanajuato",
    url: "http://www.ugto.mx/",
  },
  {
    country: "MX",
    name: "Universidad de Guanajuato, Campus León",
    url: "http://www.campusleon.ugto.mx/",
  },
  {
    country: "MX",
    name: "Universidad de Hermosillo",
    url: "http://www.udeh.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad de La Salle, Bajío",
    url: "http://bajio.delasalle.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad de las Américas",
    url: "http://www.udla.mx/",
  },
  {
    country: "MX",
    name: "Universidad de las Américas - Puebla",
    url: "http://www.udlap.mx/",
  },
  {
    country: "MX",
    name: "Universidad de la Sierra A.C.",
    url: "http://www.usac.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad del Bajío",
    url: "http://www.ubac.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad del Centro de México",
    url: "http://www.ucem.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad de León",
    url: "http://www.universidaddeleon.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad del Mar",
    url: "http://www.umar.mx/",
  },
  {
    country: "MX",
    name: "Universidad del Mayab",
    url: "http://www.unimayab.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad del Noreste",
    url: "http://www.une.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad del Noroeste",
    url: "http://www.uno.mx/",
  },
  {
    country: "MX",
    name: "Universidad del Norte",
    url: "http://www.un.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad del Tepeyac",
    url: "http://www.tepeyac.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad del Valle de Atemajac",
    url: "http://www.univa.mx/",
  },
  {
    country: "MX",
    name: "Universidad del Valle del Bravo",
    url: "http://www.uvb.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad del Valle de México",
    url: "http://www.uvmnet.edu/",
  },
  {
    country: "MX",
    name: "Universidad del Valle de Puebla",
    url: "http://members.rotfl.com/uvp/",
  },
  {
    country: "MX",
    name: "Universidad del Valle de Toluca",
    url: "http://www.uvt.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad de Montemorelos",
    url: "http://www.umontemorelos.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad de Monterrey",
    url: "http://www.udem.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad de Norteamerica",
    url: "http://www.un.mx/",
  },
  {
    country: "MX",
    name: "Universidad de Occidente",
    url: "http://www.udo.mx/",
  },
  {
    country: "MX",
    name: "Universidad de Quintana Roo",
    url: "http://www.uqroo.mx/",
  },
  {
    country: "MX",
    name: "Universidad de San Miguel",
    url: "http://www.udesanmiguel.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad de Sonora",
    url: "http://www.uson.mx/",
  },
  {
    country: "MX",
    name: "Universidad Don Vasco",
    url: "http://www.udv.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad España de Durango",
    url: "http://www.unes.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Franciscana de México",
    url: "http://www.ufm.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Franco-Mexicana",
    url: "http://www.ufram.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Fray Luca Paccioli",
    url: "http://www.frayluca.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Hispanoamericana",
    url: "http://hispano.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Iberoamericana",
    url: "http://www.uia.mx/",
  },
  {
    country: "MX",
    name: "Universidad Iberoamericana, Campus León",
    url: "http://www.leon.uia.mx/",
  },
  {
    country: "MX",
    name: "Universidad Insurgentes Plantel León",
    url: "http://www.uileon.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Intercontinental",
    url: "http://www.uic.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Itaca",
    url: "http://www.i.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Jóse Vasconcelos",
    url: "http://www.univas.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Juárez Autónoma de Tabasco",
    url: "http://www.ujat.mx/",
  },
  {
    country: "MX",
    name: "Universidad Juárez del Estado de Durango",
    url: "http://www.ujed.mx/",
  },
  {
    country: "MX",
    name: "Universidad Justo Sierra",
    url: "http://www.ujsierra.mx/",
  },
  {
    country: "MX",
    name: "Universidad Kino",
    url: "http://www.unikino.mx/",
  },
  {
    country: "MX",
    name: "Universidad la Concordia",
    url: "http://www.universidadlaconcordia.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad La Salle",
    url: "http://www.ulsa.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad La Salle Cuernavaca",
    url: "http://www.ulsac.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Lasallista Benavente",
    url: "http://www.ulsab.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Latina",
    url: "http://www.unla.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Latinoamericana",
    url: "http://www.ula.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Madero",
    url: "http://www.umad.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Mesoamericana de San Agustín",
    url: "http://www.umsa.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Metropolitana de Monterrey",
    url: "http://www.umm.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Metropolitana Latin Campus",
    url: "http://www.umla.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Mexicana",
    url: "http://www.unimex.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Mexicana del Noreste",
    url: "http://www.umne.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad México Americana del Norte",
    url: "http://www.uman.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad México Internacional",
    url: "http://www.umi.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Michoacana de San Nicolás de Hidalgo",
    url: "http://www.umich.mx/",
  },
  {
    country: "MX",
    name: "Universidad Motolinía del Pedegral",
    url: "http://www.universidadmotolinia.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Motolinía del Pedregal",
    url: "http://www.motolinia.com.mx/",
  },
  {
    country: "MX",
    name: "Universidad Nacional Autónoma de México",
    url: "http://www.unam.mx/",
  },
  {
    country: "MX",
    name: "Universidad Nuevo Mundo",
    url: "http://www.unum.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Obrera de Mexico",
    url: "http://www.uom.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Olmeca",
    url: "http://www.olmeca.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Panamericana",
    url: "http://www.up.mx/",
  },
  {
    country: "MX",
    name: "Universidad Pedagógica Nacional",
    url: "http://www.upn.mx/",
  },
  {
    country: "MX",
    name: "Universidad Pedagógica Nacional, Unidad León",
    url: "http://www.lef.upn.mx/",
  },
  {
    country: "MX",
    name: "Universidad Pedagógica Veracruzana",
    url: "http://www.secupv.org/",
  },
  {
    country: "MX",
    name: "Universidad Popular Autonóma del Estado de Puebla",
    url: "http://www.upaep.mx/",
  },
  {
    country: "MX",
    name: "Universidad Regiomontana",
    url: "http://www.ur.mx/",
  },
  {
    country: "MX",
    name: "Universidad Regional del Sureste",
    url: "http://www.urse.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Regional Miguel Hidalgo",
    url: "http://www.urmh.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Salesiana",
    url: "http://www.universidadsalesiana.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Santa Fe",
    url: "http://www.usf.com.mx/",
  },
  {
    country: "MX",
    name: "Universidad Simón Bolivar",
    url: "http://www.usb.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Tecnologica de la Mixteca",
    url: "http://www.utm.mx/",
  },
  {
    country: "MX",
    name: "Universidad Tecnológica de México",
    url: "http://www.unitec.mx/",
  },
  {
    country: "MX",
    name: "Universidad Tecnologica de Nezahualcoyotl",
    url: "http://www.utneza.edu.mx/",
  },
  {
    country: "MX",
    name: "Universidad Valle del Grijalva",
    url: "http://www.uvg1.net/",
  },
  {
    country: "MX",
    name: "Universidad Veracruzana",
    url: "http://www.uv.mx/",
  },
  {
    country: "MX",
    name: "Universidad Xicotepetl",
    url: "http://www.uxac.edu.mx/",
  },
  {
    country: "MX",
    name: "Westhill University",
    url: "http://www.westhill.edu.mx/",
  },
  {
    country: "MY",
    name: "Advance Tertiary College",
    url: "http://www.atc2u.com/",
  },
  {
    country: "MY",
    name: "Aimst University",
    url: "http://www.aimst.edu.my/",
  },
  {
    country: "MY",
    name: "Al-Bukhari International University",
    url: "http://www.aiu.edu.my/",
  },
  {
    country: "MY",
    name: "Allianze College of Medical Sciences (ACMS)",
    url: "http://www.acms.edu.my/",
  },
  {
    country: "MY",
    name: "Al Madinah International University",
    url: "http://www.mediu.edu.my/",
  },
  {
    country: "MY",
    name: "Asia E University",
    url: "http://www.aeu.edu.my/",
  },
  {
    country: "MY",
    name: "Asia Pacific Institute of Information Technology (APIIT)",
    url: "http://www.apiit.edu.my/",
  },
  {
    country: "MY",
    name: "Baitulmal Management Institute (IPB)",
    url: "http://www.ipb.edu.my/",
  },
  {
    country: "MY",
    name: "Binary University College of Managemant & Entrepreneurship",
    url: "http://www.binary.edu.my/",
  },
  {
    country: "MY",
    name: "Brickfields Asia College",
    url: "http://www.bac.edu.my/",
  },
  {
    country: "MY",
    name: "British Malaysian Institute",
    url: "http://www.bmi.edu.my/",
  },
  {
    country: "MY",
    name: "City University College of Science and Technology",
    url: "http://www.city.edu.my/",
  },
  {
    country: "MY",
    name: "Curtin University of Technology, Sarawak Campus",
    url: "http://www.curtin.edu.my/",
  },
  {
    country: "MY",
    name: "Cyberjaya University College of Medical Science",
    url: "http://www.cybermed.edu.my/",
  },
  {
    country: "MY",
    name: "Darul Hikmah Islamic College",
    url: "http://www.hikmah.edu.my/",
  },
  {
    country: "MY",
    name: "Darul Naim College of Technology",
    url: "http://www.ktd.edu.my/",
  },
  {
    country: "MY",
    name: "Darul Quran Islamic College University",
    url: "http://kudqi.net.my/",
  },
  {
    country: "MY",
    name: "Darul Takzim Institute of Technology",
    url: "http://www.instedt.edu.my/",
  },
  {
    country: "MY",
    name: "Darul Ulum Islamic College",
    url: "http://kidu-darululum.blogspot.com/",
  },
  {
    country: "MY",
    name: "FTMS Global Academy",
    url: "http://www.ftmsglobal.com/",
  },
  {
    country: "MY",
    name: "Help University College",
    url: "http://www.help.edu.my/",
  },
  {
    country: "MY",
    name: "Iact College",
    url: "http://www.iact.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Batu Lintang",
    url: "http://www.ipbl.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Darul Aman",
    url: "http://www.ipda.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Dato' Razali Ismail",
    url: "http://www.ipgmkdri.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Ilmu Khas",
    url: "http://www.ipik.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Ipoh",
    url: "http://www.ipip.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Islamic Education",
    url: "http://www.ipislam.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Keningau",
    url: "http://www.ipks.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Kent",
    url: "http://www.ipkent.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Kota Bharu",
    url: "http://www.ipgkkb.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Malay Language",
    url: "http://www.ipbmm.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Melaka",
    url: "http://www.ippm.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Penang",
    url: "http://www.i4p.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Perlis",
    url: "http://www.ipgperlis.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Raja Melewar",
    url: "http://www.iprm.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Rajang",
    url: "http://www.ipgkrajang.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Sarawak",
    url: "http://www.ipsmiri.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Sultan Abdul Halim",
    url: "http://www.ipsah.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Sultan Mizan",
    url: "http://www.ipgmksm.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Tawau",
    url: "http://www.ipgmtawau.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Technical Education",
    url: "http://www.ipteknik.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Temenggong Ibrahim",
    url: "http://www.ipgkti.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Tengku Ampuan Afzan",
    url: "http://www.iptaa.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Tuanku Bainun",
    url: "http://www.iptb.edu.my/",
  },
  {
    country: "MY",
    name: "Institute of Teachers Education, Tun Hussein Onn",
    url: "http://www.iptho.edu.my/",
  },
  {
    country: "MY",
    name: "Institut Prima Bestari - Pine Academy",
    url: "http://www.pine.edu.my/",
  },
  {
    country: "MY",
    name: "International Islamic College",
    url: "http://www.iic.edu.my/",
  },
  {
    country: "MY",
    name: "International Islamic College of Penang",
    url: "http://www.kitab.edu.my/",
  },
  {
    country: "MY",
    name: "International Islamic University",
    url: "http://www.iiu.edu.my/",
  },
  {
    country: "MY",
    name: "International Medical University",
    url: "http://www.imu.edu.my/",
  },
  {
    country: "MY",
    name: "International University College of Nursing (IUCN)",
    url: "http://www.iucn.edu.my/",
  },
  {
    country: "MY",
    name: "International University College of Technology Twintech (IUCTT)",
    url: "http://www.iuctt.edu.my/",
  },
  {
    country: "MY",
    name: "Islamic College for Sciences and Technologies",
    url: "http://www.kist.edu.my/",
  },
  {
    country: "MY",
    name: "Johore Bharu Primeir Polytechnic",
    url: "http://www.polijb.edu.my/",
  },
  {
    country: "MY",
    name: "KBU International College",
    url: "http://www.kbu.edu.my/",
  },
  {
    country: "MY",
    name: "KDU College Sdn Bhd",
    url: "http://www.kdu.edu.my/",
  },
  {
    country: "MY",
    name: "Kolej Universiti Insaniah",
    url: "http://www.kuin.edu.my/",
  },
  {
    country: "MY",
    name: "Kota Bharu Polytechnic",
    url: "http://www.pkb.edu.my/",
  },
  {
    country: "MY",
    name: "Kota Kinabalu Polytechnic",
    url: "http://www.pkksabah.edu.my/",
  },
  {
    country: "MY",
    name: "Kuala Lumpur Infrastructure University College",
    url: "http://www.kliuc.edu.my/",
  },
  {
    country: "MY",
    name: "Kuala Lumpur Metropolitan University",
    url: "http://www.klmu.edu.my/",
  },
  {
    country: "MY",
    name: "Kuala Terengganu City Polytechnic",
    url: "http://www.pkkt.edu.my/",
  },
  {
    country: "MY",
    name: "Kuching Polytechnic",
    url: "http://www.poliku.edu.my/",
  },
  {
    country: "MY",
    name: "Limkokwing University College of Creative Technology",
    url: "http://www.limkokwing.edu.my/",
  },
  {
    country: "MY",
    name: "Linton University College",
    url: "http://www.linton.edu.my/",
  },
  {
    country: "MY",
    name: "Mahsa University College for Health and Medical Science",
    url: "http://www.mahsa.edu.my/",
  },
  {
    country: "MY",
    name: "Malaysia University of Science and Technology (MUST)",
    url: "http://www.must.edu.my/",
  },
  {
    country: "MY",
    name: "Management and Science University",
    url: "http://www.msu.edu.my/",
  },
  {
    country: "MY",
    name: "Mara Poly-Tech College",
    url: "http://www.kptm.edu.my/",
  },
  {
    country: "MY",
    name: "Melaka City Polytechnic",
    url: "http://www.polimelaka.edu.my/",
  },
  {
    country: "MY",
    name: "Melaka Islamic University College",
    url: "http://www.kuim.edu.my/",
  },
  {
    country: "MY",
    name: "Merlimau Polytechnic",
    url: "http://www.pmm.edu.my/",
  },
  {
    country: "MY",
    name: "Monash University, Malaysia Campus",
    url: "http://www.monash.edu.my/",
  },
  {
    country: "MY",
    name: "Muadzam Shah Polytechnic",
    url: "http://www.polimuadzam.edu.my/",
  },
  {
    country: "MY",
    name: "Multimedia University",
    url: "http://www.mmu.edu.my/",
  },
  {
    country: "MY",
    name: "Murni Nursing College",
    url: "http://www.murni.edu.my/",
  },
  {
    country: "MY",
    name: "Newcastle University, Medicine Malaysia",
    url: "http://numed.ncl.ac.uk/",
  },
  {
    country: "MY",
    name: "Nilai University College",
    url: "http://www.nilai.edu.my/",
  },
  {
    country: "MY",
    name: "Olympia College",
    url: "http://www.olympia.edu.my/",
  },
  {
    country: "MY",
    name: "Open University Malaysia",
    url: "http://www.oum.edu.my/",
  },
  {
    country: "MY",
    name: "Penang International Dental College",
    url: "http://www.pidc.edu.my/",
  },
  {
    country: "MY",
    name: "Perak Islamic College",
    url: "http://www.kiperak.edu.my/",
  },
  {
    country: "MY",
    name: "Perdana University",
    url: "http://www.perdanauniversity.edu.my/",
  },
  {
    country: "MY",
    name: "Perlis Islamic Higher Learning Institute",
    url: "http://www.iptips.edu.my/",
  },
  {
    country: "MY",
    name: "Petronas Technology University",
    url: "http://www.utp.edu.my/",
  },
  {
    country: "MY",
    name: "Port Dickson Polytechnic",
    url: "http://www.polipd.edu.my/",
  },
  {
    country: "MY",
    name: "Primier International University Perak",
    url: "http://www.piup.edu.my/",
  },
  {
    country: "MY",
    name: "PTPL College",
    url: "http://www.ptpl.edu.my/",
  },
  {
    country: "MY",
    name: "Raffles University",
    url: "http://www.raffles-university.edu.my/",
  },
  {
    country: "MY",
    name: "Saito College",
    url: "http://www.saito.edu.my/",
  },
  {
    country: "MY",
    name: "Seberang Perai Polytechnic",
    url: "http://www.psp.edu.my/",
  },
  {
    country: "MY",
    name: "Segi University College",
    url: "http://www.segi.edu.my/",
  },
  {
    country: "MY",
    name: "Selangor Islamic University College",
    url: "http://www.kuis.edu.my/",
  },
  {
    country: "MY",
    name: "Shahputra College",
    url: "http://www.kolejshahputra.edu.my/",
  },
  {
    country: "MY",
    name: "Sultan Abdul Halim Muadzam Shah Polytechnic",
    url: "http://www.polimas.edu.my/",
  },
  {
    country: "MY",
    name: "Sultanah Bahiyah Polytechnic",
    url: "http://www.ptsb.edu.my/",
  },
  {
    country: "MY",
    name: "Sultan Ahmad Shah Islamic College",
    url: "http://www.kipsas.edu.my/",
  },
  {
    country: "MY",
    name: "Sultan Azlan Shah Polytechnic",
    url: "http://www.psas.edu.my/",
  },
  {
    country: "MY",
    name: "Sultan Haji Ahmad Shah Polytechnic",
    url: "http://www.polisas.edu.my/",
  },
  {
    country: "MY",
    name: "Sultan Idris Shah Polytechnic",
    url: "http://www.psis.edu.my/",
  },
  {
    country: "MY",
    name: "Sultan Ismail Petra International Islamic College",
    url: "http://www.kias.edu.my/",
  },
  {
    country: "MY",
    name: "Sultan Mizan Zainal Abidin Polytechnic",
    url: "http://www.psmza.edu.my/",
  },
  {
    country: "MY",
    name: "Sultan Salahuddin Abdul Aziz Shah Polytechnic",
    url: "http://www.psa.edu.my/",
  },
  {
    country: "MY",
    name: "Sunway University College",
    url: "http://www.sunway.edu.my/",
  },
  {
    country: "MY",
    name: "Swinburne University of Technology, Sarawak Campus",
    url: "http://www.swinburne.edu.my/",
  },
  {
    country: "MY",
    name: "Taj International College",
    url: "http://www.taj.edu.my/",
  },
  {
    country: "MY",
    name: "Taylor's University College",
    url: "http://www.taylors.edu.my/",
  },
  {
    country: "MY",
    name: "TPM College",
    url: "http://www.tpmcollege.edu.my/",
  },
  {
    country: "MY",
    name: "Tunku Abdul Rahman Chinese College",
    url: "http://www.tarc.edu.my/",
  },
  {
    country: "MY",
    name: "Tunku Abdul Rahman University (Chinese University)",
    url: "http://www.utar.edu.my/",
  },
  {
    country: "MY",
    name: "Tunku Syed Sirajuddin Polytechnic",
    url: "http://www.ptss.edu.my/",
  },
  {
    country: "MY",
    name: "UCSI University",
    url: "http://www.ucsi.edu.my/",
  },
  {
    country: "MY",
    name: "Ungku Omar Premier Polytechnic",
    url: "http://www.puo.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Darul Iman",
    url: "http://www.udm.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Industri Selangor",
    url: "http://www.unisel.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Kebangsaan Malaysia",
    url: "http://www.ukm.my/",
  },
  {
    country: "MY",
    name: "Universiti Kuala Lumpur",
    url: "http://www.unikl.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Kuala Lumpur Malaysian Institute of Information Technology (MIIT)",
    url: "http://miit.unikl.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Malaya",
    url: "http://www.um.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Malaysia Kelantan",
    url: "http://www.umk.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Malaysia Perlis",
    url: "http://www.unimap.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Malaysia Sabah",
    url: "http://www.ums.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Malaysia Sarawak",
    url: "http://www.unimas.my/",
  },
  {
    country: "MY",
    name: "Universiti Malaysia Terengganu",
    url: "http://www.umt.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Pendidikan Sultan Idris",
    url: "http://www.upsi.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Putra Malaysia",
    url: "http://www.upm.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Sains Malaysia",
    url: "http://www.usm.my/",
  },
  {
    country: "MY",
    name: "Universiti Sultan Zainal Abidin",
    url: "http://www.unisza.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Teknikal Malaysia Melaka",
    url: "http://www.utem.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Teknologi Malaysia",
    url: "http://www.utm.my/",
  },
  {
    country: "MY",
    name: "Universiti Teknologi Mara",
    url: "http://www.uitm.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Teknologi Petronas",
    url: "http://www.utp.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Tenaga Nasional",
    url: "http://www.uniten.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Tun Abdul Razak",
    url: "http://www.unitar.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Tun Hussein Onn Malaysia",
    url: "http://www.uthm.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Tunku Abdul Rahman",
    url: "http://www.utar.edu.my/",
  },
  {
    country: "MY",
    name: "Universiti Utara Malaysia",
    url: "http://www.uum.edu.my/",
  },
  {
    country: "MY",
    name: "University College of Technology & Innovation (UCTI)",
    url: "http://www.ucti.edu.my/",
  },
  {
    country: "MY",
    name: "University Malaysia Pahang",
    url: "http://www.ump.edu.my/",
  },
  {
    country: "MY",
    name: "University of Management and Technology",
    url: "http://www.umtech.edu.my/",
  },
  {
    country: "MY",
    name: "University of Nottingham, Malaysia Campus",
    url: "http://www.nottingham.edu.my/",
  },
  {
    country: "MY",
    name: "University Tun Abdul Razak",
    url: "http://www.unirazak.edu.my/",
  },
  {
    country: "MY",
    name: "Wawasan Open University",
    url: "http://www.wou.edu.my/",
  },
  {
    country: "MY",
    name: "West Minster International College",
    url: "http://www.westminster.edu.my/",
  },
  {
    country: "MY",
    name: "YPC-iTWEB College",
    url: "http://www.kolejypc.edu.my/",
  },
  {
    country: "MZ",
    name: "Instituto Superior de Ciênicas e Tecnologia de Moçambique",
    url: "http://www.isctem.ac.mz/",
  },
  {
    country: "MZ",
    name: "Instituto Superior de Relações Internacionais (ISRI)",
    url: "http://www.isri.ac.mz/",
  },
  {
    country: "MZ",
    name: "Instituto Superior Politécnico e Universitário",
    url: "http://www.ispu.ac.mz/",
  },
  {
    country: "MZ",
    name: "Universidade Católica de Moçambique",
    url: "http://www.ucm.ac.mz/",
  },
  {
    country: "MZ",
    name: "Universidade Eduardo Mondlane",
    url: "http://www.uem.mz/",
  },
  {
    country: "NA",
    name: "Ilsa Independent College",
    url: "http://www.ilsacollege.edu.na/",
  },
  {
    country: "NA",
    name: "Institute of Information Technology",
    url: "http://www.iit.com.na/",
  },
  {
    country: "NA",
    name: "International University of Management",
    url: "http://www.ium.edu.na/",
  },
  {
    country: "NA",
    name: "Polytechnic of Namibia",
    url: "http://www.polytechnic.edu.na/",
  },
  {
    country: "NA",
    name: "University of Namibia",
    url: "http://www.unam.na/",
  },
  {
    country: "NC",
    name: "University of New Caledonia",
    url: "http://www.ufp.nc/",
  },
  {
    country: "NE",
    name: "Université Abdou Moumouni de Niamey",
    url: "http://uam.refer.ne/",
  },
  {
    country: "NG",
    name: "Abia State Polytechnic",
    url: "http://www.abiapoly.edu.ng/",
  },
  {
    country: "NG",
    name: "Abia State University",
    url: "http://www.absu.edu.ng/",
  },
  {
    country: "NG",
    name: "ABTI American University of Nigeria",
    url: "http://www.aun.edu.ng/",
  },
  {
    country: "NG",
    name: "Abubakar Tafawa Balewa University",
    url: "http://www.atbu.edu.ng/",
  },
  {
    country: "NG",
    name: "Adamawa State University",
    url: "http://www.adsu.edu.ng/",
  },
  {
    country: "NG",
    name: "Adekunle Ajasin University",
    url: "http://www.aaua.edu.ng/",
  },
  {
    country: "NG",
    name: "Adeleke University",
    url: "http://www.adelekeuniversity.edu.ng/",
  },
  {
    country: "NG",
    name: "Adeyemi College of Education",
    url: "http://www.aceondo-ng.com/",
  },
  {
    country: "NG",
    name: "Afe Babalola University",
    url: "http://www.abuad.edu.ng/",
  },
  {
    country: "NG",
    name: "African University of Science and Technology",
    url: "http://www.aust-abuja.org/",
  },
  {
    country: "NG",
    name: "Ahmadu Bello University",
    url: "http://www.abu.edu.ng/",
  },
  {
    country: "NG",
    name: "Ajayi Crowther University",
    url: "http://www.acu.edu.ng/",
  },
  {
    country: "NG",
    name: "Akanu Ibiam Federal Polytechnic, Unwana",
    url: "http://www.polyunwana.net/",
  },
  {
    country: "NG",
    name: "Akwa Ibom State University of Technology",
    url: "http://www.akutech.edu.ng/",
  },
  {
    country: "NG",
    name: "Al-hikmah University",
    url: "http://www.alhikmah.edu.ng/",
  },
  {
    country: "NG",
    name: "Ambrose Alli University",
    url: "http://www.aauekpoma.edu.ng/",
  },
  {
    country: "NG",
    name: "Anambra State University of Science and Technology",
    url: "http://www.ansu.edu.ng/",
  },
  {
    country: "NG",
    name: "Auchi Polytechnic",
    url: "http://www.auchipoly.edu.ng/",
  },
  {
    country: "NG",
    name: "Babcock University",
    url: "http://www.babcockuni.edu.ng/",
  },
  {
    country: "NG",
    name: "Bauchi State University, Gadau",
    url: "http://www.basug.edu.ng/",
  },
  {
    country: "NG",
    name: "Bayero University Kano",
    url: "http://www.buk.edu.ng/",
  },
  {
    country: "NG",
    name: "Baze University Abuja",
    url: "http://www.bazeuniversity.edu.ng",
  },
  {
    country: "NG",
    name: "Bells University of Technology",
    url: "http://www.bellsuniversity.org/",
  },
  {
    country: "NG",
    name: "Benson Idahosa University",
    url: "http://www.biu.edu.ng/",
  },
  {
    country: "NG",
    name: "Benue State University",
    url: "http://www.bsum.edu.ng/",
  },
  {
    country: "NG",
    name: "Bingham University",
    url: "http://www.binghamuni.edu.ng/",
  },
  {
    country: "NG",
    name: "Bowen University",
    url: "http://bowenuniversity-edu.org/",
  },
  {
    country: "NG",
    name: "Caleb University",
    url: "http://www.calebuniversity.edu.ng/",
  },
  {
    country: "NG",
    name: "Caritas University",
    url: "http://www.caritasuni.edu.ng/",
  },
  {
    country: "NG",
    name: "College of Education Ikere",
    url: "http://www.coeikere.edu.ng/",
  },
  {
    country: "NG",
    name: "College of Education Oju",
    url: "http://www.coeoju.com/",
  },
  {
    country: "NG",
    name: "Covenant University",
    url: "http://www.covenantuniversity.edu.ng/",
  },
  {
    country: "NG",
    name: "Crawford University",
    url: "http://www.crawforduniversity.edu.ng/",
  },
  {
    country: "NG",
    name: "Crescent University",
    url: "http://www.crescent-university.edu.ng/",
  },
  {
    country: "NG",
    name: "Cross River University of Science and Technology",
    url: "http://www.crutech.edu.ng/",
  },
  {
    country: "NG",
    name: "Ebonyi State University",
    url: "http://www.ebsu-edu.net/",
  },
  {
    country: "NG",
    name: "Ekiti State University",
    url: "http://www.eksu.edu.ng/",
  },
  {
    country: "NG",
    name: "Enugu State University of Science and Technology",
    url: "http://www.esut.edu.ng/",
  },
  {
    country: "NG",
    name: "Federal College Of Education (Technical), Akoka",
    url: "http://www.fcetakoka-edu.net/",
  },
  {
    country: "NG",
    name: "Federal Polytechnic Bauchi, Nigeria",
    url: "http://fptb.edu.ng/",
  },
  {
    country: "NG",
    name: "Federal University of Petroleum Resources",
    url: "http://www.fupre.edu.ng/",
  },
  {
    country: "NG",
    name: "Federal University of Technology, Akure",
    url: "http://www.futa.edu.ng/",
  },
  {
    country: "NG",
    name: "Federal University of Technology, Minna",
    url: "http://www.futminna.edu.ng/",
  },
  {
    country: "NG",
    name: "Federal University of Technology, Owerri",
    url: "http://www.futo.edu.ng/",
  },
  {
    country: "NG",
    name: "Federal University of Technology, Yola",
    url: "http://www.futy.edu.ng/",
  },
  {
    country: "NG",
    name: "Fountain University",
    url: "http://www.fountainuniversity.edu.ng/",
  },
  {
    country: "NG",
    name: "Godfrey Okoye University",
    url: "http://www.go-uni-enugu.net/",
  },
  {
    country: "NG",
    name: "Gombe State University",
    url: "http://www.gsu.edu.ng/",
  },
  {
    country: "NG",
    name: "Ibrahim Babangida University",
    url: "http://www.ibbu.edu.ng/",
  },
  {
    country: "NG",
    name: "Igbinedion University",
    url: "http://www.iuokada.edu.ng/",
  },
  {
    country: "NG",
    name: "Imo State University",
    url: "http://www.imsu.edu.ng/",
  },
  {
    country: "NG",
    name: "Joseph Ayo Babalola University",
    url: "http://www.jabu.edu.ng/",
  },
  {
    country: "NG",
    name: "Kaduna State University",
    url: "http://www.kasuportal.net/",
  },
  {
    country: "NG",
    name: "Kano State University of Technology Wudil",
    url: "http://www.kustwudil.edu.ng/",
  },
  {
    country: "NG",
    name: "Katsina University",
    url: "http://www.kuk.edu.ng/",
  },
  {
    country: "NG",
    name: "Kebbi State University of Science and Technology",
    url: "http://www.ksusta.edu.ng/",
  },
  {
    country: "NG",
    name: "Kogi State University",
    url: "http://www.ksuportal.edu.ng/",
  },
  {
    country: "NG",
    name: "Kwararafa University",
    url: "http://www.kwararafauniversity.edu.ng/",
  },
  {
    country: "NG",
    name: "Kwara State Polytecnic",
    url: "http://www.kwarapoly.edu.ng/",
  },
  {
    country: "NG",
    name: "Kwara State University",
    url: "http://www.kwasu.edu.ng/",
  },
  {
    country: "NG",
    name: "Ladoke Akintola University of Technology",
    url: "http://www.lautech.edu.ng/",
  },
  {
    country: "NG",
    name: "Lagos City Polytechnic",
    url: "http://www.mylcp.net/",
  },
  {
    country: "NG",
    name: "Lagos State Polytechnic",
    url: "http://www.laspotech.net/",
  },
  {
    country: "NG",
    name: "Lagos State University",
    url: "http://www.lasunigeria.org/",
  },
  {
    country: "NG",
    name: "Landmark University",
    url: "http://www.lmu.edu.ng/",
  },
  {
    country: "NG",
    name: "Lead City University of Ibadan",
    url: "http://www.lcu.edu.ng/",
  },
  {
    country: "NG",
    name: "Madonna University",
    url: "http://www.madonnauniversity.edu.ng/",
  },
  {
    country: "NG",
    name: "Michael Okpara University of Agriculture",
    url: "http://www.mouau.edu.ng/",
  },
  {
    country: "NG",
    name: "Nasarawa State University Keffi",
    url: "http://www.nsuk.edu.ng/",
  },
  {
    country: "NG",
    name: "National Open University of Nigeria",
    url: "http://www.nou.edu.ng/",
  },
  {
    country: "NG",
    name: "Niger Delta University",
    url: "http://www.ndu.edu.ng/",
  },
  {
    country: "NG",
    name: "Nigerian Defence Academy",
    url: "http://www.nda.edu.ng/",
  },
  {
    country: "NG",
    name: "Nigerian Turkish Nile University",
    url: "http://www.ntnu.edu.ng/",
  },
  {
    country: "NG",
    name: "Nnamdi Azikiwe University",
    url: "http://www.unizik.edu.ng/",
  },
  {
    country: "NG",
    name: "Novena University",
    url: "http://www.novenauniversity.edu.ng/",
  },
  {
    country: "NG",
    name: "Obafemi Awolowo University Ile-Ife",
    url: "http://www.oauife.edu.ng/",
  },
  {
    country: "NG",
    name: "Obong University",
    url: "http://www.obonguniversity.net/",
  },
  {
    country: "NG",
    name: "Oduduwa University",
    url: "http://www.oduduwauniversity.edu.ng/",
  },
  {
    country: "NG",
    name: "Olabisi Onabanjo University",
    url: "http://www.oouagoiwoye.edu.ng/",
  },
  {
    country: "NG",
    name: "Ondo State University of Science and Technology",
    url: "http://www.osustech.edu.ng/",
  },
  {
    country: "NG",
    name: "Osun State University",
    url: "http://www.uniosun.edu.ng/",
  },
  {
    country: "NG",
    name: "Pan-African University",
    url: "http://www.pau.edu.ng/",
  },
  {
    country: "NG",
    name: "Paul University",
    url: "http://www.pauluniversity.edu.ng/",
  },
  {
    country: "NG",
    name: "Polytechnic Ibadan",
    url: "http://www.polyibadan.edu.ng/",
  },
  {
    country: "NG",
    name: "Redeemers University",
    url: "http://www.run.edu.ng/",
  },
  {
    country: "NG",
    name: "Renaissance University",
    url: "http://www.rnu.edu.ng/",
  },
  {
    country: "NG",
    name: "Rivers State University of Science and Technology",
    url: "http://www.ust.edu.ng/",
  },
  {
    country: "NG",
    name: "Salem University",
    url: "http://www.salemuniversity.edu.ng/",
  },
  {
    country: "NG",
    name: "Sokoto State University",
    url: "http://www.ssu.edu.ng/",
  },
  {
    country: "NG",
    name: "Tai Solarin University of Education",
    url: "http://www.tasued.edu.ng/",
  },
  {
    country: "NG",
    name: "Tansian University",
    url: "http://www.tansianuniversity.edu.ng/",
  },
  {
    country: "NG",
    name: "Taraba State University",
    url: "http://www.tsuniversity.edu.ng/",
  },
  {
    country: "NG",
    name: "The Achievers University",
    url: "http://www.achievers.edu.ng/",
  },
  {
    country: "NG",
    name: "The Federal Polytechnic Offa",
    url: "http://www.fedpoffa.edu.ng/",
  },
  {
    country: "NG",
    name: "Umaru Musa Yar'Adua University",
    url: "http://www.umyu.edu.ng/",
  },
  {
    country: "NG",
    name: "University of Abuja",
    url: "http://www.unibuja.edu.ng/",
  },
  {
    country: "NG",
    name: "University of Agriculture Abeokuta",
    url: "http://www.unaab.edu.ng/",
  },
  {
    country: "NG",
    name: "University of Agriculture Makurdi",
    url: "http://www.uam.edu.ng/",
  },
  {
    country: "NG",
    name: "University of Benin",
    url: "http://www.uniben.edu/",
  },
  {
    country: "NG",
    name: "University of Calabar",
    url: "http://www.unical.edu.ng/",
  },
  {
    country: "NG",
    name: "University of Ibadan",
    url: "http://www.ui.edu.ng/",
  },
  {
    country: "NG",
    name: "University of Ilorin",
    url: "http://www.unilorin.edu.ng/",
  },
  {
    country: "NG",
    name: "University of Jos",
    url: "http://www.unijos.edu.ng/",
  },
  {
    country: "NG",
    name: "University of Lagos",
    url: "http://www.unilag.edu.ng/",
  },
  {
    country: "NG",
    name: "University of Maiduguri",
    url: "http://www.unimaid.edu.ng/",
  },
  {
    country: "NG",
    name: "University of Mkar",
    url: "http://www.unimkar.edu.ng/",
  },
  {
    country: "NG",
    name: "University of Nigeria",
    url: "http://www.unn.edu.ng/",
  },
  {
    country: "NG",
    name: "University of Portharcourt",
    url: "http://www.uniport.edu.ng/",
  },
  {
    country: "NG",
    name: "University of Uyo",
    url: "http://www.uniuyo.edu.ng/",
  },
  {
    country: "NG",
    name: "Usmanu Danfodiyo University Sokoto",
    url: "http://www.udusok.edu.ng/",
  },
  {
    country: "NG",
    name: "Veritas University",
    url: "http://www.veritas.edu.ng/",
  },
  {
    country: "NG",
    name: "Wellspring University",
    url: "http://www.wellspringuniversity.edu.ng/",
  },
  {
    country: "NG",
    name: "Wesley University of Science and Technology",
    url: "http://www.wustoportal.edu.ng/",
  },
  {
    country: "NG",
    name: "Western Delta University",
    url: "http://www.wdu.edu.ng/",
  },
  {
    country: "NG",
    name: "Yaba College of Technology",
    url: "http://www.yabatech.edu.ng/",
  },
  {
    country: "NI",
    name: "Universidad Americana",
    url: "http://www.uam.edu.ni/",
  },
  {
    country: "NI",
    name: 'Universidad Catolica "Redemptoris Mater',
    url: "http://www.unica.edu.ni/",
  },
  {
    country: "NI",
    name: "Universidad Central de Nicaragua",
    url: "http://www.ucn.edu.ni/",
  },
  {
    country: "NI",
    name: "Universidad Centroamericana",
    url: "http://www.uca.edu.ni/",
  },
  {
    country: "NI",
    name: "Universidad Centroamericana de Ciencias Empresariales (UCEM)",
    url: "http://www.ucem.edu.ni/",
  },
  {
    country: "NI",
    name: "Universidad Ciencias Comerciales",
    url: "http://www.ucc.edu.ni/",
  },
  {
    country: "NI",
    name: "Universidad de las Américas (ULAM)",
    url: "http://www.ulam.edu.ni/",
  },
  {
    country: "NI",
    name: "Universidad de las Regiones Autónomas de la Costa Caribe Nicaragüense",
    url: "http://www.uraccan.edu.ni/",
  },
  {
    country: "NI",
    name: "Universidad de Managua (U de M)",
    url: "http://www.udem.edu.ni/",
  },
  {
    country: "NI",
    name: "Universidad Hispanoamericana",
    url: "http://www.uhispam.edu.ni/",
  },
  {
    country: "NI",
    name: "Universidad Iberoamericana de Ciencia y Tecnologia",
    url: "http://www.unicit.edu.ni/",
  },
  {
    country: "NI",
    name: "Universidad Internacional de Integración de América Latina",
    url: "http://www.unival.edu.ni/",
  },
  {
    country: "NI",
    name: "Universidad Nacional Agraria",
    url: "http://www.una.edu.ni/",
  },
  {
    country: "NI",
    name: "Universidad Nacional Autonoma de Nicaragua",
    url: "http://www.unan.edu.ni/",
  },
  {
    country: "NI",
    name: "Universidad Nacional de Ingenieria",
    url: "http://www.uni.edu.ni/",
  },
  {
    country: "NI",
    name: "Universidad Nicaragüense de Ciencia y Tecnológica",
    url: "http://www.ucyt.edu.ni/",
  },
  {
    country: "NI",
    name: "Universidad Politecnica de Nicaragua",
    url: "http://www.upoli.edu.ni/",
  },
  {
    country: "NI",
    name: "Universidad Popular de Nicaragua (UPONIC)",
    url: "http://www.uponic.edu.ni/",
  },
  {
    country: "NL",
    name: "Christelijke Hogeschool Windesheim",
    url: "http://www.windesheim.nl/",
  },
  {
    country: "NL",
    name: "De Haagse Hogeschool",
    url: "http://www.dehaagsehogeschool.nl/",
  },
  {
    country: "NL",
    name: "Delft University of Technology",
    url: "http://www.tudelft.nl/",
  },
  {
    country: "NL",
    name: "Dutch Delta University",
    url: "http://www.ddu.nl/",
  },
  {
    country: "NL",
    name: "Eindhoven University of Technology",
    url: "http://www.tue.nl/",
  },
  {
    country: "NL",
    name: "Erasmus University Rotterdam",
    url: "http://www.eur.nl/",
  },
  {
    country: "NL",
    name: "European Open University",
    url: "http://www.europeanopenuniversity.com/",
  },
  {
    country: "NL",
    name: "Fontys University of Applied Sciences",
    url: "http://www.fontys.edu/",
  },
  {
    country: "NL",
    name: "Foundation University",
    url: "http://www.foundationuniversity.com/",
  },
  {
    country: "NL",
    name: "Free University Amsterdam",
    url: "http://www.vu.nl/",
  },
  {
    country: "NL",
    name: "Hanzehogeschool Groningen",
    url: "http://www.hanze.nl/",
  },
  {
    country: "NL",
    name: "Hogeschool Inholland",
    url: "http://www.inholland.nl/",
  },
  {
    country: "NL",
    name: "Hogeschool Leiden",
    url: "http://www.hsleiden.nl/",
  },
  {
    country: "NL",
    name: "Hogeschool Rotterdam",
    url: "http://www.hro.nl/",
  },
  {
    country: "NL",
    name: "Hogeschool Utrecht",
    url: "http://www.hu.nl/",
  },
  {
    country: "NL",
    name: "Hogeschool van Amsterdam",
    url: "http://www.hva.nl/",
  },
  {
    country: "NL",
    name: "Hogeschool voor de Kunsten Utrecht (HKU)",
    url: "http://www.hku.nl/",
  },
  {
    country: "NL",
    name: "Hotelschool The Hague",
    url: "http://www.hotelschool.nl/",
  },
  {
    country: "NL",
    name: "Institute of Social Studies",
    url: "http://www.iss.nl/",
  },
  {
    country: "NL",
    name: "International Institute for Geo - Information Science and Earth Observation",
    url: "http://www.itc.nl/",
  },
  {
    country: "NL",
    name: "Islamic University of Rotterdam",
    url: "http://www.islamicuniversity.nl/",
  },
  {
    country: "NL",
    name: "Leiden University",
    url: "http://www.leidenuniv.nl/",
  },
  {
    country: "NL",
    name: "NHL University of Applied Sciences",
    url: "http://www.nhl.nl/",
  },
  {
    country: "NL",
    name: "NHTV Breda University of Professional Education",
    url: "http://www.nhtv.nl",
  },
  {
    country: "NL",
    name: "NTI University",
    url: "http://www.nti.nl/",
  },
  {
    country: "NL",
    name: "Open University of the Netherlands",
    url: "http://www.ou.nl/",
  },
  {
    country: "NL",
    name: "Radboud University of Nijmegen",
    url: "http://www.ru.nl/",
  },
  {
    country: "NL",
    name: "Roosevelt Academy University College",
    url: "http://www.roac.nl/",
  },
  {
    country: "NL",
    name: "Saxion Universities",
    url: "http://saxion.edu/",
  },
  {
    country: "NL",
    name: "Stenden University",
    url: "http://www.stenden.com/",
  },
  {
    country: "NL",
    name: "Tilburg University",
    url: "http://www.tilburguniversity.nl/",
  },
  {
    country: "NL",
    name: "UNESCO-IHE Institute for Water Education",
    url: "http://www.unesco-ihe.org/",
  },
  {
    country: "NL",
    name: "Universiteit Doesburg (UNDO)",
    url: "http://www.universiteitdoesburg.nl/",
  },
  {
    country: "NL",
    name: "University for Humanistics (UH)",
    url: "http://www.uvh.nl/",
  },
  {
    country: "NL",
    name: "University of Amsterdam",
    url: "http://www.uva.nl/",
  },
  {
    country: "NL",
    name: "University of Groningen",
    url: "http://www.rug.nl/",
  },
  {
    country: "NL",
    name: "University of Maastricht",
    url: "http://www.unimaas.nl/",
  },
  {
    country: "NL",
    name: "University of Nijenrode",
    url: "http://www.nijenrode.nl/",
  },
  {
    country: "NL",
    name: "University of Twente",
    url: "http://www.utwente.nl/",
  },
  {
    country: "NL",
    name: "Utrecht University",
    url: "http://www.uu.nl/",
  },
  {
    country: "NL",
    name: "Wageningen University",
    url: "http://www.wageningenuniversity.nl/",
  },
  {
    country: "NL",
    name: "Wittenborg University",
    url: "http://www.wittenborg.eu/",
  },
  {
    country: "NL",
    name: "Zuyd University",
    url: "http://www.zuyd.nl/",
  },
  {
    country: "NO",
    name: "Agder University College",
    url: "http://www.hia.no/",
  },
  {
    country: "NO",
    name: "Agricultural University of Norway",
    url: "http://www.nlh.no/",
  },
  {
    country: "NO",
    name: "Bergen University College",
    url: "http://www.hib.no/",
  },
  {
    country: "NO",
    name: "Bodo Regional University",
    url: "http://www.hibo.no/",
  },
  {
    country: "NO",
    name: "Finnmark University College",
    url: "http://www.hifm.no/",
  },
  {
    country: "NO",
    name: "Lillehammer University College",
    url: "http://www.hil.no/",
  },
  {
    country: "NO",
    name: "Molde University College",
    url: "http://www.himolde.no/",
  },
  {
    country: "NO",
    name: "National Academy of Fine Arts",
    url: "http://ska.khio.no/main",
  },
  {
    country: "NO",
    name: "National College of Art and Design",
    url: "http://www.khio.no/shks/",
  },
  {
    country: "NO",
    name: "Norway's Information Technology University College",
    url: "http://www.nith.no/",
  },
  {
    country: "NO",
    name: "Norwegian College of Veterinary Medicine",
    url: "http://www.veths.no/",
  },
  {
    country: "NO",
    name: "Norwegian School of Economics and Business Administration",
    url: "http://www.nhh.no/",
  },
  {
    country: "NO",
    name: "Norwegian School of Management BI",
    url: "http://www.bi.no/",
  },
  {
    country: "NO",
    name: "Norwegian State Academy of Music",
    url: "http://www.nmh.no/",
  },
  {
    country: "NO",
    name: "Norwegian Teacher Academy for Studies in Religion and Education",
    url: "http://www.nla.no/",
  },
  {
    country: "NO",
    name: "Norwegian University of Life Sciences",
    url: "http://www.umb.no/",
  },
  {
    country: "NO",
    name: "Norwegian University of Science and Technology",
    url: "http://www.ntnu.no/",
  },
  {
    country: "NO",
    name: "Norwegian University of Sport and Physical Education",
    url: "http://www.nih.no/",
  },
  {
    country: "NO",
    name: "Oslo School of Architecture",
    url: "http://www.aho.no/",
  },
  {
    country: "NO",
    name: "University of Bergen",
    url: "http://www.uib.no/",
  },
  {
    country: "NO",
    name: "University of Oslo",
    url: "http://www.uio.no/",
  },
  {
    country: "NO",
    name: "University of Stavanger",
    url: "http://www.uis.no/",
  },
  {
    country: "NO",
    name: "University of Tromsø",
    url: "http://www.uit.no/",
  },
  {
    country: "NP",
    name: "Agriculture and Forestry University",
    url: "http://www.afu.edu.np/",
  },
  {
    country: "NP",
    name: "B.P.Koirala Institute of Health Sciences",
    url: "http://www.bpkihs.edu/",
  },
  {
    country: "NP",
    name: "Kathmandu University",
    url: "http://www.ku.edu.np/",
  },
  {
    country: "NP",
    name: "Lumbini Buddhist University",
    url: "http://www.lbu.edu.np/",
  },
  {
    country: "NP",
    name: "Nepal Sanskrit University",
    url: "http://www.nsu.edu.np/",
  },
  {
    country: "NP",
    name: "Pokhara University",
    url: "http://www.pu.edu.np/",
  },
  {
    country: "NP",
    name: "Purbanchal University",
    url: "http://www.purbuniv.edu.np/",
  },
  {
    country: "NP",
    name: "Tribhuvan University Kathmandu",
    url: "http://www.tribhuvan-university.edu.np/",
  },
  {
    country: "NU",
    name: "St Clements University - Higher Education School",
    url: "http://www.stclements.edu.nu/",
  },
  {
    country: "NZ",
    name: "Auckland University of Technology",
    url: "http://www.aut.ac.nz/",
  },
  {
    country: "NZ",
    name: "Christchurch Polytechnic Institute of Technology",
    url: "http://www.cpit.ac.nz/",
  },
  {
    country: "NZ",
    name: "Lincoln University",
    url: "http://www.lincoln.ac.nz/",
  },
  {
    country: "NZ",
    name: "Massey University",
    url: "http://www.massey.ac.nz/",
  },
  {
    country: "NZ",
    name: "Open Polytechnic",
    url: "http://www.openpolytechnic.ac.nz/",
  },
  {
    country: "NZ",
    name: "University of Auckland",
    url: "http://www.auckland.ac.nz/",
  },
  {
    country: "NZ",
    name: "University of Canterbury",
    url: "http://www.canterbury.ac.nz/",
  },
  {
    country: "NZ",
    name: "University of Otago",
    url: "http://www.otago.ac.nz/",
  },
  {
    country: "NZ",
    name: "University of Waikato",
    url: "http://www.waikato.ac.nz/",
  },
  {
    country: "NZ",
    name: "Victoria University of Wellington",
    url: "http://www.vuw.ac.nz/",
  },
  {
    country: "OM",
    name: "Al-Buraimi University College",
    url: "http://www.buc.edu.om/",
  },
  {
    country: "OM",
    name: "Arab Open University",
    url: "http://www.aou.edu.om/",
  },
  {
    country: "OM",
    name: "Dhofar University",
    url: "http://www.du.edu.om/",
  },
  {
    country: "OM",
    name: "Ibra College of Technology",
    url: "http://www.ict.edu.om/",
  },
  {
    country: "OM",
    name: "Majan University College",
    url: "http://www.majancollege.edu.om/",
  },
  {
    country: "OM",
    name: "Muscat College",
    url: "http://www.muscatcollege.edu.om/",
  },
  {
    country: "OM",
    name: "Oman College Of Management & Technology",
    url: "http://www.omancollege.edu.om/",
  },
  {
    country: "OM",
    name: "Sohar University",
    url: "http://www.soharuni.edu.om/",
  },
  {
    country: "OM",
    name: "Sultan Qaboos University",
    url: "http://www.squ.edu.om/",
  },
  {
    country: "OM",
    name: "Sur University College",
    url: "http://www.suc.edu.om/",
  },
  {
    country: "OM",
    name: "University of Nizwa",
    url: "http://www.unizwa.edu.om/",
  },
  {
    country: "PA",
    name: "Columbus University",
    url: "http://www.columbus.edu/",
  },
  {
    country: "PA",
    name: "Floret Global University",
    url: "http://www.floret.edu.pa/",
  },
  {
    country: "PA",
    name: "Instituto de Enseñanza Superior Oteima",
    url: "http://www.institutoteima.ac.pa/",
  },
  {
    country: "PA",
    name: "Universidad Abierta y a Distancia de Panama",
    url: "http://www.unadp.ac.pa/",
  },
  {
    country: "PA",
    name: "Universidad Autonoma de Chiriqui",
    url: "http://www.unachi.ac.pa/",
  },
  {
    country: "PA",
    name: "Universidad Católica Santa María La Antigua",
    url: "http://www.usma.ac.pa/",
  },
  {
    country: "PA",
    name: "Universidad de Cartago",
    url: "http://www.ucapanama.org/",
  },
  {
    country: "PA",
    name: "Universidad del Istmo",
    url: "http://www.udi.edu/",
  },
  {
    country: "PA",
    name: "Universidad de Panamá",
    url: "http://www.up.ac.pa/",
  },
  {
    country: "PA",
    name: "Universidad Especializada de Las Americas",
    url: "http://www.udelas.ac.pa/",
  },
  {
    country: "PA",
    name: "Universidad Interamericana de Educacion a Distancia de Panama",
    url: "http://www.uniedpa.com/",
  },
  {
    country: "PA",
    name: "Universidad Latina de Panamá",
    url: "http://www.ulat.ac.pa/",
  },
  {
    country: "PA",
    name: "Universidad Latinoamericana de Ciencia y Tecnologia, Panamá",
    url: "http://www.ulacit.ac.pa/",
  },
  {
    country: "PA",
    name: "Universidad Tecnológica de Panamá",
    url: "http://www.utp.ac.pa/",
  },
  {
    country: "PA",
    name: "Universidad Tecnológica Oteima",
    url: "http://www.oteima.ac.pa/",
  },
  {
    country: "PA",
    name: "Victoria International University",
    url: "http://www.viu.edu.pa/",
  },
  {
    country: "PA",
    name: "West Coast University (WCU)",
    url: "http://www.westcoastuniversity-edu.com/",
  },
  {
    country: "PE",
    name: "Pontificia Universidad Católica del Perú",
    url: "http://www.pucp.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Alas Peruanas",
    url: "http://www.uap.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Andina del Cusco",
    url: "http://www.uandina.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Andina Nestor Caceares Velasquez",
    url: "http://www.uancv.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Católica de Santa María",
    url: "http://www.ucsm.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Católica Santo Toribio de Mogrovejo",
    url: "http://www.usat.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad César Vallejo",
    url: "http://www.ucv.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Cientifica del Sur",
    url: "http://www.ucsur.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad de Chiclayo",
    url: "http://www.udch.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad de Ciencias y Humanidades",
    url: "http://www.uch.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad de Huánuco",
    url: "http://www.udh.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad de Lambayeque",
    url: "http://www.udl.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad de Lima",
    url: "http://www.ulima.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad del Pacifico",
    url: "http://www.up.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad de Piura",
    url: "http://www.udep.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Femenina del Sagrado Corazón",
    url: "http://www.unife.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Los Angeles de Chimbote",
    url: "http://www.uladech.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Marcelino Champagnat",
    url: "http://www.champagnat.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional Agraria de la Selva",
    url: "http://www.unas.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional Agraria La Molina",
    url: "http://www.lamolina.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional Agraria La Selva",
    url: "http://www.unas.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional Daniel Alcides Carrion",
    url: "http://www.undac.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional de Cajamarca",
    url: "http://www.unc.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional de Educación Enrique Guzmán y Valle",
    url: "http://www.une.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional de Ingeniería",
    url: "http://www.uni.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional de la Amazonía Peruana",
    url: "http://www.unapiquitos.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional de La Libertad, Trujillo",
    url: "http://www.unitru.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional del Altiplano",
    url: "http://www.unap.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional del Callao",
    url: "http://www.unac.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional del Centro del Perú",
    url: "http://www.uncp.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional de Piura",
    url: "http://www.unp.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional de San Agustin",
    url: "http://www.unsa.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional de San Antonio Abad",
    url: "http://www.unsaac.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional de San Cristóbal de Huamanga",
    url: "http://www.unsch.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional de San Martín",
    url: "http://www.unsm.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional de Santa - Chimbote",
    url: "http://www.uns.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional de Tumbes",
    url: "http://www.untumbes.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional de Ucayali",
    url: "http://www.unu.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional Federico Villarreal",
    url: "http://www.unfv.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional Hermilio Valdizan",
    url: "http://www.unheval.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional Jorge Basadre Grohmann",
    url: "http://www.unjbg.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional José Faustino Sánchez Carrión",
    url: "http://www.unjfsc.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional Mayor de San Marcos",
    url: "http://www.unmsm.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional Pedro Ruíz Gallo",
    url: "http://www.unprg.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional San Luis Gonzaga",
    url: "http://www.unica.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Nacional Santiago Antúnez de Mayolo",
    url: "http://www.unasam.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Norbert Wiener",
    url: "http://www.uwiener.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Particular de Chiclayo",
    url: "http://www.udch.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Particular Inca Garcilaso de la Vega",
    url: "http://www.uigv.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Peruana Cayetano Heredia",
    url: "http://www.upch.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Peruana de Ciencias Aplicadas",
    url: "http://www.upc.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Peruana de Ciencias e Informática",
    url: "http://www.upci.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Peruana Union",
    url: "http://www.upeu.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Privada Antenor Orrego",
    url: "http://www.upao.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Privada del Norte",
    url: "http://www.upnorte.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Privada de Tacna",
    url: "http://www.upt.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Privada Juan Mejía Baca",
    url: "http://www.umb.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Privada Los Andes",
    url: "http://www.upla.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Privada San Juan Bautista",
    url: "http://www.upsjb.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Privada San Pedro",
    url: "http://www.usanpedro.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Privada Sergio Bernales",
    url: "http://www.upsb.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Ricardo Palma",
    url: "http://www.urp.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad San Ignacio de Loyola",
    url: "http://www.sil.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad San Martin de Porres",
    url: "http://www.usmp.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad San Pablo",
    url: "http://www.usp.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Señor de Sipán",
    url: "http://www.uss.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Tecnológica de los Andes de Apurímac",
    url: "http://www.utea.edu.pe/",
  },
  {
    country: "PE",
    name: "Universidad Tecnológica del Peru",
    url: "http://www.utp.edu.pe/",
  },
  {
    country: "PF",
    name: "Université de la Polynésie Française",
    url: "http://www.upf.pf/",
  },
  {
    country: "PG",
    name: "Divine Word University",
    url: "http://www.dwu.ac.pg/",
  },
  {
    country: "PG",
    name: "Pacific Adventist University",
    url: "http://www.pau.ac.pg/",
  },
  {
    country: "PG",
    name: "Papua New Guinea University of Technology",
    url: "http://www.unitech.ac.pg/",
  },
  {
    country: "PG",
    name: "University of Goroka",
    url: "http://www.uog.ac.pg/",
  },
  {
    country: "PG",
    name: "University of Papua New Guinea",
    url: "http://www.upng.ac.pg/",
  },
  {
    country: "PH",
    name: "Adamson University",
    url: "http://www.adamson.edu.ph/",
  },
  {
    country: "PH",
    name: "Adventist University of the Philippines",
    url: "http://www.aup.edu.ph/",
  },
  {
    country: "PH",
    name: "Angeles University",
    url: "http://www.auf.edu.ph/",
  },
  {
    country: "PH",
    name: "Aquinas University",
    url: "http://www.aquinas-university.edu/",
  },
  {
    country: "PH",
    name: "Araullo University",
    url: "http://www.aupen.edu.ph/",
  },
  {
    country: "PH",
    name: "Arellano University",
    url: "http://www.arellano.edu.ph/",
  },
  {
    country: "PH",
    name: "Ateneo de Davao University",
    url: "http://www.addu.edu.ph/",
  },
  {
    country: "PH",
    name: "Ateneo de Manila University",
    url: "http://www.admu.edu.ph/",
  },
  {
    country: "PH",
    name: "Ateneo de Naga University",
    url: "http://www.adnu.edu.ph/",
  },
  {
    country: "PH",
    name: "Ateneo de Zamboanga University",
    url: "http://www.adzu.edu.ph/",
  },
  {
    country: "PH",
    name: "Benguet State University",
    url: "http://www.bsu.edu.ph/",
  },
  {
    country: "PH",
    name: "Bicol University",
    url: "http://www.bicol-u.edu.ph/",
  },
  {
    country: "PH",
    name: "Bulacan State University",
    url: "http://www.bulsu.edu.ph/",
  },
  {
    country: "PH",
    name: "Cagayan State University",
    url: "http://www.csu.edu.ph/",
  },
  {
    country: "PH",
    name: "Capitol University",
    url: "http://www.cu-cdo.edu.ph/",
  },
  {
    country: "PH",
    name: "Central Luzon State University",
    url: "http://www2.mozcom.com/~clsu/",
  },
  {
    country: "PH",
    name: "Central Mindanao University",
    url: "http://www.cmu.edu.ph/",
  },
  {
    country: "PH",
    name: "Central Philippine University",
    url: "http://www.cpuic.edu/",
  },
  {
    country: "PH",
    name: "Centro Escolar University",
    url: "http://www.ceu.edu.ph/",
  },
  {
    country: "PH",
    name: "Colegio de San Juan de Letran",
    url: "http://www.letran.edu/",
  },
  {
    country: "PH",
    name: "College of the Holy Spirit",
    url: "http://www.holyspirit.edu.ph/",
  },
  {
    country: "PH",
    name: "Davao Doctors College",
    url: "http://www.davaodoctors.edu.ph/",
  },
  {
    country: "PH",
    name: "De La Salle University",
    url: "http://www.dlsu.edu.ph/",
  },
  {
    country: "PH",
    name: "De La Salle University, Araneta",
    url: "http://www.araneta.dlsu.edu.ph/",
  },
  {
    country: "PH",
    name: "Divine Word College of Legazpi",
    url: "http://www.dwc-legazpi.edu/",
  },
  {
    country: "PH",
    name: "Don Mariano Marcos Memorial State University",
    url: "http://www.dmmmsu.edu.ph/",
  },
  {
    country: "PH",
    name: "Far Eastern University",
    url: "http://www.feu.edu.ph/",
  },
  {
    country: "PH",
    name: "Feati University",
    url: "http://www.featiu.edu.ph/",
  },
  {
    country: "PH",
    name: "Foundation University",
    url: "http://www.foundationu.com/",
  },
  {
    country: "PH",
    name: "Holy Angel University",
    url: "http://www.hau.edu.ph/",
  },
  {
    country: "PH",
    name: "Information and Communications Technology Academy",
    url: "http://www.iacademy.ph/",
  },
  {
    country: "PH",
    name: "Irish University Business School, Cebu",
    url: "http://www.iubs.co.uk/",
  },
  {
    country: "PH",
    name: "Isabela State University",
    url: "http://www.isu.edu.ph/",
  },
  {
    country: "PH",
    name: "Leyte Normal University",
    url: "http://lnu.evis.net.ph/",
  },
  {
    country: "PH",
    name: "Leyte State University",
    url: "http://www.lsu-visca.edu.ph/",
  },
  {
    country: "PH",
    name: "Liceo de Cagayan University",
    url: "http://www.ldcu.edu.ph/",
  },
  {
    country: "PH",
    name: "Lyceum of the Philippines University",
    url: "http://www.lpu.edu.ph/",
  },
  {
    country: "PH",
    name: "Manila Central University",
    url: "http://www.mcu.edu.ph/",
  },
  {
    country: "PH",
    name: "Manuel L. Quezon University",
    url: "http://www.mlqu.edu.ph/",
  },
  {
    country: "PH",
    name: "Manuel S. Enverga University Foundation",
    url: "http://www.mseuf.edu.ph/",
  },
  {
    country: "PH",
    name: "Mariano Marcos State University",
    url: "http://www.mmsu.edu.ph/",
  },
  {
    country: "PH",
    name: "Mindanao State University",
    url: "http://www.msumain.edu.ph/",
  },
  {
    country: "PH",
    name: "Mindanao State University - Iligan Institute of Technology",
    url: "http://www.msuiit.edu.ph/",
  },
  {
    country: "PH",
    name: "Misamis University",
    url: "http://www.mu.fapenet.org/",
  },
  {
    country: "PH",
    name: "Mountain View College",
    url: "http://www.mvc.edu.ph/",
  },
  {
    country: "PH",
    name: "National University",
    url: "http://www.nu.edu.ph/",
  },
  {
    country: "PH",
    name: "New Era University",
    url: "http://www.neu.edu.ph/",
  },
  {
    country: "PH",
    name: "Northwestern University of the Philippines",
    url: "http://www.nulaoag.com/",
  },
  {
    country: "PH",
    name: "Notre Dame of Marbel University",
    url: "http://www.ndmu.edu.ph/",
  },
  {
    country: "PH",
    name: "Notre Dame University",
    url: "http://www.ndu.fapenet.org/",
  },
  {
    country: "PH",
    name: "Our Lady of Fatima University",
    url: "http://www.fatima.edu.ph/",
  },
  {
    country: "PH",
    name: "Palawan State University",
    url: "http://www.psu-online.edu.ph/",
  },
  {
    country: "PH",
    name: "Pangasinan State University",
    url: "http://www.psu.edu.ph/",
  },
  {
    country: "PH",
    name: "Philippine Christian University",
    url: "http://www.pcu.edu.ph/",
  },
  {
    country: "PH",
    name: "Philippine Military Academy",
    url: "http://www.pma.ph/",
  },
  {
    country: "PH",
    name: "Philippine Normal University",
    url: "http://www.pnumanila.com.ph/",
  },
  {
    country: "PH",
    name: "Philippine Women's University",
    url: "http://www.pwu.edu/",
  },
  {
    country: "PH",
    name: "Polytechnic University of the Philippines",
    url: "http://www.pup.edu.ph/",
  },
  {
    country: "PH",
    name: "Rizal Technological University",
    url: "http://www.rtu.edu.ph/",
  },
  {
    country: "PH",
    name: "Saint Ferdinand College",
    url: "http://www.sfc.edu.ph/",
  },
  {
    country: "PH",
    name: "Saint Louis College",
    url: "http://www.slc-sflu.edu.ph/",
  },
  {
    country: "PH",
    name: "Saint Louis University",
    url: "http://www.slu.edu.ph/",
  },
  {
    country: "PH",
    name: "Saint Mary's University",
    url: "http://www.smu.edu.ph/",
  },
  {
    country: "PH",
    name: "Saint Paul University",
    url: "http://www.spu.edu.ph/",
  },
  {
    country: "PH",
    name: "Samar State University",
    url: "http://www.ssu.edu.ph/",
  },
  {
    country: "PH",
    name: "San Beda College",
    url: "http://www.sanbeda.edu.ph/",
  },
  {
    country: "PH",
    name: "Silliman University",
    url: "http://www.su.edu.ph/",
  },
  {
    country: "PH",
    name: "Southwestern University",
    url: "http://www.swu.edu.ph/",
  },
  {
    country: "PH",
    name: "Tarlac State University",
    url: "http://www2.mozcom.com/~tsu-ics/",
  },
  {
    country: "PH",
    name: "Technological University of the Philippines",
    url: "http://www.tup.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Asia and the Pacific",
    url: "http://www.uap.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Baguio",
    url: "http://www.ubaguio.edu/",
  },
  {
    country: "PH",
    name: "University of Batangas",
    url: "http://www.ub.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Bohol",
    url: "http://www.universityofbohol.com/",
  },
  {
    country: "PH",
    name: "University of Cagayan Valley",
    url: "http://www.ucv.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Cebu",
    url: "http://www.universityofcebu.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Eastern Philippines",
    url: "http://www.uep.educ.ph/",
  },
  {
    country: "PH",
    name: "University of lloilo",
    url: "http://www.ui.phinma.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Manila",
    url: "http://www.univman.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Mindanao",
    url: "http://www.umindanao.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Negros Occidental-Recoletos",
    url: "http://www.uno-r.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Northeastern Philippines",
    url: "http://www.unep.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Northern Philippines",
    url: "http://www.unp.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Nueva Caceres",
    url: "http://www.unc.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Pangasinan",
    url: "http://www.upang.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Perpetual Help",
    url: "http://www.uphr.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Perpetual Help, System Dalta",
    url: "http://www.perpetualdalta.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Regina Carmeli",
    url: "http://www.urc.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Saint La Salle",
    url: "http://www.usls.edu/",
  },
  {
    country: "PH",
    name: "University of San Agustin",
    url: "http://www.usa.edu.ph/",
  },
  {
    country: "PH",
    name: "University of San Carlos",
    url: "http://www.usc.edu.ph/",
  },
  {
    country: "PH",
    name: "University of San Jose Recoletos",
    url: "http://www.usjr.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Santo Tomas",
    url: "http://www.ust.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Southeastern Philippines",
    url: "http://www.usep.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Southern Mindanao",
    url: "http://www.usm.edu.ph/",
  },
  {
    country: "PH",
    name: "University of Southern Philippines Foundation",
    url: "http://www.usp.ph/",
  },
  {
    country: "PH",
    name: "University of the Assumption",
    url: "http://www.ua.edu.ph/",
  },
  {
    country: "PH",
    name: "University of the City of Manila",
    url: "http://www.plm.edu.ph/",
  },
  {
    country: "PH",
    name: "University of the Cordilleras",
    url: "http://www.uc-bcf.edu.ph/",
  },
  {
    country: "PH",
    name: "University of the East, Coloocan",
    url: "http://www.ue.edu.ph/caloocan/",
  },
  {
    country: "PH",
    name: "University of the East, Manila",
    url: "http://www.ue.edu.ph/manila/",
  },
  {
    country: "PH",
    name: "University of the East, Ramon Magsaysay Memorial Medical Center",
    url: "http://www.uerm.edu.ph/",
  },
  {
    country: "PH",
    name: "University of the Immaculate Conception",
    url: "http://uic.globe.com.ph/",
  },
  {
    country: "PH",
    name: "University of the Philippines Baguio",
    url: "http://www.upb.edu.ph/",
  },
  {
    country: "PH",
    name: "University of the Philippines Diliman",
    url: "http://www.upd.edu.ph/",
  },
  {
    country: "PH",
    name: "University of the Philippines Los Banos",
    url: "http://www.uplb.edu.ph/",
  },
  {
    country: "PH",
    name: "University of the Philippines Manila",
    url: "http://www.upm.edu.ph/",
  },
  {
    country: "PH",
    name: "University of the Philippines Mindanao",
    url: "http://www.upmin.edu.ph/",
  },
  {
    country: "PH",
    name: "University of the Philippines Open University",
    url: "http://www.upou.org/",
  },
  {
    country: "PH",
    name: "University of the Philippines Visayas",
    url: "http://www.upv.edu.ph/",
  },
  {
    country: "PH",
    name: "University of the Philippines Visayas - Cebu High School",
    url: "http://www.uphighschoolcebu.edu.ph/",
  },
  {
    country: "PH",
    name: "University of the Visayas",
    url: "http://www.uv.edu.ph/",
  },
  {
    country: "PH",
    name: "Virgen Milagrosa University Foundation",
    url: "http://www.vmuf.edu.ph/",
  },
  {
    country: "PH",
    name: "Wesleyan University Philippines",
    url: "http://www.wesleyan.edu.ph/",
  },
  {
    country: "PH",
    name: "Western Mindanao State University",
    url: "http://www.wmsu.edu.ph/",
  },
  {
    country: "PH",
    name: "West Visayas State University",
    url: "http://www.wvsu.edu.ph/",
  },
  {
    country: "PH",
    name: "Xavier University",
    url: "http://www.xu.edu.ph/",
  },
  {
    country: "PK",
    name: "Abasyn University Peshawar",
    url: "http://www.abasyn.edu.pk/",
  },
  {
    country: "PK",
    name: "Aga Khan University",
    url: "http://www.aku.edu/",
  },
  {
    country: "PK",
    name: "Air University",
    url: "http://www.au.edu.pk/",
  },
  {
    country: "PK",
    name: "Alhamd Islamic University",
    url: "http://www.aiu.edu.pk/",
  },
  {
    country: "PK",
    name: "Al-Khair University",
    url: "http://www.alkhair.edu.pk/",
  },
  {
    country: "PK",
    name: "Allama Iqbal Open University",
    url: "http://www.aiou.edu.pk/",
  },
  {
    country: "PK",
    name: "Alpha Omega University",
    url: "http://www.alphaomega.edu.pk/",
  },
  {
    country: "PK",
    name: "American University of Hawaii, Punjab Campus",
    url: "http://www.auh.edu.pk/",
  },
  {
    country: "PK",
    name: "Arid Agriculture University",
    url: "http://www.uaar.edu.pk/",
  },
  {
    country: "PK",
    name: "Asian Management Institute",
    url: "http://www.ami.edu.pk/",
  },
  {
    country: "PK",
    name: "Asia Pacific Institute of Information Technology Pakistan (APIIT PAK)",
    url: "http://www.apiit.edu.pk/",
  },
  {
    country: "PK",
    name: "Azad Jammu and Kashmir University",
    url: "http://www.ajku.edu.pk/",
  },
  {
    country: "PK",
    name: "Bahauddin Zakariya University, Multan",
    url: "http://www.bzu.edu.pk/",
  },
  {
    country: "PK",
    name: "Bahria University",
    url: "http://www.bahria.edu.pk/",
  },
  {
    country: "PK",
    name: "Balochistan University of Engineering and Technology Khuzdar",
    url: "http://www.buetk.edu.pk/",
  },
  {
    country: "PK",
    name: "Balochistan University of Information Technology & Management Sciences",
    url: "http://www.buitms.edu.pk/",
  },
  {
    country: "PK",
    name: "Baqai Medical University",
    url: "http://baqai.freeyellow.com/",
  },
  {
    country: "PK",
    name: "Cecos Univeristy of Information Technology",
    url: "http://www.cecos.edu.pk/",
  },
  {
    country: "PK",
    name: "College of Business Management ( CBM )",
    url: "http://www.cbm.edu.pk/",
  },
  {
    country: "PK",
    name: "COMSATS Institute of Information Technology",
    url: "http://www.ciit.edu.pk/",
  },
  {
    country: "PK",
    name: "COMSATS Institute of Information Technology, Abbottabad",
    url: "http://www.ciit-atd.edu.pk/",
  },
  {
    country: "PK",
    name: "COMSATS Institute of Information Technology, Attock",
    url: "http://www.ciit-attock.edu.pk/",
  },
  {
    country: "PK",
    name: "COMSATS Institute of Information Technology, Lahore",
    url: "http://www.ciitlahore.edu.pk/",
  },
  {
    country: "PK",
    name: "COMSATS Institute of Information Technology, Wah",
    url: "http://www.ciit-wah.edu.pk/",
  },
  {
    country: "PK",
    name: "COSMIQ Institute of Technology",
    url: "http://www.cosmiq.edu.pk/",
  },
  {
    country: "PK",
    name: "Dadabhoy Institute of Higher Education",
    url: "http://www.dadabhoy.edu.pk/",
  },
  {
    country: "PK",
    name: "Dawood College of Engineering and Technology",
    url: "http://www.dcet.edu.pk/",
  },
  {
    country: "PK",
    name: "Dow University of Health Sciences",
    url: "http://www.duhs.edu.pk/",
  },
  {
    country: "PK",
    name: "Faisalabad Institute of Textile and Fashion Design",
    url: "http://www.fitfd.edu.pk/",
  },
  {
    country: "PK",
    name: "FAST - National University of Computer and Emerging Sciences (NUCES)",
    url: "http://www.nu.edu.pk/",
  },
  {
    country: "PK",
    name: "Federal Urdu University of Arts,Science and Technology",
    url: "http://www.fuuast.edu.pk/",
  },
  {
    country: "PK",
    name: "Gandhara Institute of Medical Sciences",
    url: "http://www.gandhara.edu.pk/",
  },
  {
    country: "PK",
    name: "Gangdara Institute Of Science & Technology",
    url: "http://www.gist.edu.pk/",
  },
  {
    country: "PK",
    name: "GC University",
    url: "http://www.gcu.edu.pk/",
  },
  {
    country: "PK",
    name: "Ghulam Ishaq Khan Institute of Science & Technology",
    url: "http://www.giki.edu.pk/",
  },
  {
    country: "PK",
    name: "Gift University",
    url: "http://www.gift.edu.pk/",
  },
  {
    country: "PK",
    name: "Gomal University",
    url: "http://www.gu.edu.pk/",
  },
  {
    country: "PK",
    name: "Government College University Faisalabad",
    url: "http://www.gcuf.edu.pk/",
  },
  {
    country: "PK",
    name: "Government College University Lahore",
    url: "http://www.gcu.edu.pk/",
  },
  {
    country: "PK",
    name: "Greenwich University",
    url: "http://www.guic.edu.pk/",
  },
  {
    country: "PK",
    name: "Hahnamann Honoeopathic Medical College",
    url: "http://www.pakistanhomoeopathy.com/",
  },
  {
    country: "PK",
    name: "Hajvery University Lahore for Women",
    url: "http://www.hajvery.edu.pk/",
  },
  {
    country: "PK",
    name: "Hamdard University",
    url: "http://www.hamdard.edu.pk/",
  },
  {
    country: "PK",
    name: "Hazara University",
    url: "http://www.hu.edu.pk/",
  },
  {
    country: "PK",
    name: "Hitec University",
    url: "http://www.hitecuni.edu.pk/",
  },
  {
    country: "PK",
    name: "Indus Institute of Higher Education",
    url: "http://www.indus.edu.pk/",
  },
  {
    country: "PK",
    name: "Institute of Business Administration (IBA)",
    url: "http://www.iba.edu.pk/",
  },
  {
    country: "PK",
    name: "Institute of Business Administration Sukkur",
    url: "http://www.iba-suk.edu.pk/",
  },
  {
    country: "PK",
    name: "Institute of Business Management",
    url: "http://www.iobm.edu.pk/",
  },
  {
    country: "PK",
    name: "Institute of Business & Technology",
    url: "http://www.biztek.edu.pk/",
  },
  {
    country: "PK",
    name: "Institute of Industrial Electronics Engineering",
    url: "http://www.iiee.edu.pk/",
  },
  {
    country: "PK",
    name: "Institute of Management Sciences, Lahore (IMS)",
    url: "http://www.pakaims.edu.pk/",
  },
  {
    country: "PK",
    name: "Institute of Management Sciences, Peshawar",
    url: "http://www.imsciences.edu.pk/",
  },
  {
    country: "PK",
    name: "Institute of Space Technology",
    url: "http://www.ist.edu.pk/",
  },
  {
    country: "PK",
    name: "International Islamic University, Islamabad",
    url: "http://www.iiu.edu.pk/",
  },
  {
    country: "PK",
    name: "Iqra University",
    url: "http://www.iqra.edu.pk/",
  },
  {
    country: "PK",
    name: "Islamia University of Bahawalpur",
    url: "http://www.iub.edu.pk/",
  },
  {
    country: "PK",
    name: "Isra University",
    url: "http://www.isra.edu.pk/",
  },
  {
    country: "PK",
    name: "Jinnah University for Women",
    url: "http://www.juw.edu.pk/",
  },
  {
    country: "PK",
    name: "Karachi Institute Of Economics And Technology",
    url: "http://www.pafkiet.edu.pk/",
  },
  {
    country: "PK",
    name: "Karachi Institute of Information Technology",
    url: "http://www.kiit.edu.pk/",
  },
  {
    country: "PK",
    name: "Karachi Medical and Dental College",
    url: "http://www.kmdc.edu.pk/",
  },
  {
    country: "PK",
    name: "Karachi School of Art",
    url: "http://www.ksa.edu.pk/",
  },
  {
    country: "PK",
    name: "Karakoram International University",
    url: "http://www.kiu.edu.pk/",
  },
  {
    country: "PK",
    name: "KASB Institute of Technology",
    url: "http://www.kasbit.edu.pk/",
  },
  {
    country: "PK",
    name: "Kohat University of Science and Technology  (KUST)",
    url: "http://www.kust.edu.pk/",
  },
  {
    country: "PK",
    name: "Lahore College for Women University",
    url: "http://www.lcwu.edu.pk/",
  },
  {
    country: "PK",
    name: "Lahore University of Management Sciences",
    url: "http://www.lums.edu.pk/",
  },
  {
    country: "PK",
    name: "Liaquat University of Medical & Health Sciences Jamshoro",
    url: "http://www.lumhs.edu.pk/",
  },
  {
    country: "PK",
    name: "Mehran University of Engineering & Technology",
    url: "http://www.muet.edu.pk/",
  },
  {
    country: "PK",
    name: "Minhaj University Lahore",
    url: "http://www.mul.edu.pk/",
  },
  {
    country: "PK",
    name: "Mohammad Ali Jinnah University",
    url: "http://www.jinnah.edu.pk/",
  },
  {
    country: "PK",
    name: "Mohammad Ali Jinnah University, Karachi",
    url: "http://www.jinnah.edu/",
  },
  {
    country: "PK",
    name: "Mohi-ud-Din Islamic University",
    url: "http://www.miu.edu.pk/",
  },
  {
    country: "PK",
    name: "Mooreland University",
    url: "http://www.moorelanduniversity.com/",
  },
  {
    country: "PK",
    name: "National College of Arts",
    url: "http://www.nca.edu.pk/",
  },
  {
    country: "PK",
    name: "National University of Computer & Emerging Sciences",
    url: "http://www.nu.edu.pk/",
  },
  {
    country: "PK",
    name: "National University of Modern Languages",
    url: "http://www.numl.edu.pk/",
  },
  {
    country: "PK",
    name: "National University of Science and Technology",
    url: "http://www.nust.edu.pk/",
  },
  {
    country: "PK",
    name: "NED University of Engineering and Technology Karachi",
    url: "http://www.neduet.edu.pk/",
  },
  {
    country: "PK",
    name: "Newports Institute of Communication & Economics",
    url: "http://www.newports.edu.pk/",
  },
  {
    country: "PK",
    name: "North West Frontier Province Agricultural University",
    url: "http://www.aup.edu.pk/",
  },
  {
    country: "PK",
    name: "Nuclear Institute for Agriculture and Biology (NIAB)",
    url: "http://www.niab.org.pk/",
  },
  {
    country: "PK",
    name: "Pak-AIMS (American Institute of Management Sciences)",
    url: "http://www.pakaims.edu/",
  },
  {
    country: "PK",
    name: "Pakistan Institute of Engineering and Applied Sciences (PIEAS)",
    url: "http://www.pieas.edu.pk/",
  },
  {
    country: "PK",
    name: "Pakistan Virtual University",
    url: "http://www.vu.edu.pk/",
  },
  {
    country: "PK",
    name: "Planwel University",
    url: "http://www.planwel.edu/",
  },
  {
    country: "PK",
    name: "Preston Institute of Management Sciences and Technology (PIMSAT)",
    url: "http://www.pimsat-khi.edu.pk/",
  },
  {
    country: "PK",
    name: "Preston University, Pakistan Campus",
    url: "http://www.prestonpak.edu.pk/",
  },
  {
    country: "PK",
    name: "Punjab Institute of Computer science",
    url: "http://www.pics.edu.pk/",
  },
  {
    country: "PK",
    name: "Punjab University College of Information Tecnology",
    url: "http://www.pucit.edu.pk/",
  },
  {
    country: "PK",
    name: "Pyramid Education Center",
    url: "http://www.pedu.edu.pk/",
  },
  {
    country: "PK",
    name: "Qauid-e-Awam University of Engineering Sciences & Technology",
    url: "http://www.panasia.org.sg/tcdc/pakistan/qauid_sindh/index.html",
  },
  {
    country: "PK",
    name: "Quaid-i-Azam University",
    url: "http://www.qau.edu.pk/",
  },
  {
    country: "PK",
    name: "Qurtuba University of Science and Infromation Technology",
    url: "http://www.qurtuba.edu.pk/",
  },
  {
    country: "PK",
    name: "Riphah International Univeristy",
    url: "http://www.riphah.edu.pk/",
  },
  {
    country: "PK",
    name: "Sarhad University of Science & Information Technology, Peshawar",
    url: "http://www.suit.edu.pk/",
  },
  {
    country: "PK",
    name: "Shah Abdul Latif University Khairpur",
    url: "http://www.salu.edu.pk/",
  },
  {
    country: "PK",
    name: "Shaheed Benazir Bhutto Women University",
    url: "http://www.fwu.edu.pk/",
  },
  {
    country: "PK",
    name: "Shaheed Zulifkar Ali Bhutto Institute of Science and Technology",
    url: "http://www.szabist.edu.pk/",
  },
  {
    country: "PK",
    name: "Sindh Agricultural University",
    url: "http://www.sau.edu.pk/",
  },
  {
    country: "PK",
    name: "Sindh Madressatul Islam University",
    url: "http://www.smiu.edu.pk/",
  },
  {
    country: "PK",
    name: "Sir Syed Institute Of Technology Islamabad",
    url: "http://www.ssms.edu.pk/",
  },
  {
    country: "PK",
    name: "Sir Syed University of Engineering and Technology",
    url: "http://www.ssuet.edu.pk/",
  },
  {
    country: "PK",
    name: "Textile Institute of Pakistan",
    url: "http://www.tip.edu.pk/",
  },
  {
    country: "PK",
    name: "The Global College Lahore",
    url: "http://www.global.edu.pk/",
  },
  {
    country: "PK",
    name: "The Government Sadiq College Women University Bahawalpur",
    url: "http://www.gscwu.edu.pk/",
  },
  {
    country: "PK",
    name: "The Superior College",
    url: "http://www.superior.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Agriculture Faisalabad",
    url: "http://www.uaf.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Balochistan",
    url: "http://www.uob.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Central Punjab",
    url: "http://www.ucp.edu.pk/",
  },
  {
    country: "PK",
    name: "University of East",
    url: "http://www.uoe.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Education",
    url: "http://www.ue.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Engineering and Technology Lahore",
    url: "http://www.uet.edu/",
  },
  {
    country: "PK",
    name: "University of Engineering and Technology Peshawar",
    url: "http://www.nwfpuet.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Engineering and Technology Taxila",
    url: "http://www.uettaxila.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Faisalabad",
    url: "http://www.tuf.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Gujrat",
    url: "http://www.uog.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Islamic Studies",
    url: "http://www.aldirasat.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Karachi",
    url: "http://www.uok.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Lahore",
    url: "http://www.uol.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Malakand",
    url: "http://www.uom.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Management and Technology",
    url: "http://www.umt.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Munawwar-ul-Islam",
    url: "http://www.umi.edu.pk/",
  },
  {
    country: "PK",
    name: "University of NorthWest",
    url: "http://www.unw.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Peshawar",
    url: "http://www.upesh.edu/",
  },
  {
    country: "PK",
    name: "University of Sargodha",
    url: "http://www.uos.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Science & Technology Bannu",
    url: "http://www.ustb.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Sindh",
    url: "http://www.usindh.edu.pk/",
  },
  {
    country: "PK",
    name: "University of South Asia",
    url: "http://www.usa.edu.pk/",
  },
  {
    country: "PK",
    name: "University of the Punjab",
    url: "http://www.pu.edu.pk/",
  },
  {
    country: "PK",
    name: "University of the Punjab, Gujranwala Campus",
    url: "http://www.pugc.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Veterinary & Animal Science",
    url: "http://www.uvas.edu.pk/",
  },
  {
    country: "PK",
    name: "University of Wah",
    url: "http://www.uw.edu.pk/",
  },
  {
    country: "PK",
    name: "Virtual University of Pakistan",
    url: "http://www.vu.edu.pk/",
  },
  {
    country: "PK",
    name: "Wah Medical College",
    url: "http://www.wahmedicalcollege.edu.pk/",
  },
  {
    country: "PK",
    name: "Zawiya Academy",
    url: "http://www.zawiya.edu.pk/",
  },
  {
    country: "PK",
    name: "Zia-ud-Din Medical University",
    url: "http://www.geocities.com/CollegePark/7329/",
  },
  {
    country: "PL",
    name: "Academy of Economics in Cracow",
    url: "http://www.ae.krakow.pl/",
  },
  {
    country: "PL",
    name: "Academy of Economics in Katowice",
    url: "http://www.ae.katowice.pl/",
  },
  {
    country: "PL",
    name: "Academy of Economics in Poznan",
    url: "http://www.ae.poznan.pl/",
  },
  {
    country: "PL",
    name: "Academy of Economics in Wroclaw",
    url: "http://www.ae.wroc.pl/",
  },
  {
    country: "PL",
    name: "Academy of Humanities and Economics in Lodz",
    url: "http://www.ahe.edu.pl/",
  },
  {
    country: "PL",
    name: "Academy of International Economic and Political Relations, Gdynia",
    url: "http://www.wsms.edu.pl/",
  },
  {
    country: "PL",
    name: "Adam Mickiewicz University of Poznan",
    url: "http://www.amu.edu.pl/",
  },
  {
    country: "PL",
    name: "AGH University of Science and Technology",
    url: "http://www.agh.edu.pl/",
  },
  {
    country: "PL",
    name: "Agricultural-Technical Academy in Olsztyn",
    url: "http://www.art.olsztyn.pl/",
  },
  {
    country: "PL",
    name: "Agricultural University of Cracow",
    url: "http://www.ar.krakow.pl/",
  },
  {
    country: "PL",
    name: "Agricultural University of Lublin",
    url: "http://www.ar.lublin.pl/",
  },
  {
    country: "PL",
    name: "Agricultural University of Poznan",
    url: "http://www.au.poznan.pl/",
  },
  {
    country: "PL",
    name: "Agricultural University of Szczecin",
    url: "http://www.ar.szczecin.pl/",
  },
  {
    country: "PL",
    name: "Agricultural University of Warsaw",
    url: "http://www.sggw.waw.pl/",
  },
  {
    country: "PL",
    name: "Agricultural University of Wroclaw",
    url: "http://www.ar.wroc.pl/",
  },
  {
    country: "PL",
    name: "Akademia Podlaska",
    url: "http://www.ap.siedlce.pl/",
  },
  {
    country: "PL",
    name: "Aleksander Gieysztor School of Humanities in Pultusk",
    url: "http://www.wsh.edu.pl/",
  },
  {
    country: "PL",
    name: "Aleksander Zelwerowicz State Theatre Academy",
    url: "http://www.at.edu.pl/",
  },
  {
    country: "PL",
    name: "Andrzej Frycz Modrzewski Cracow College",
    url: "http://www.ksw.edu.pl/",
  },
  {
    country: "PL",
    name: "Cardinal Stefan Wyszynski University in Warsaw",
    url: "http://www.uksw.edu.pl/",
  },
  {
    country: "PL",
    name: "Catholic University of Lublin",
    url: "http://www.kul.lublin.pl/",
  },
  {
    country: "PL",
    name: "Christian Theological Academy in Warszaw",
    url: "http://www.qdnet.pl/ChAT/",
  },
  {
    country: "PL",
    name: "College of Computer Science in Lodz",
    url: "http://www.wsinf.edu.pl/",
  },
  {
    country: "PL",
    name: "Collegium Civitas",
    url: "http://www.collegium.edu.pl/",
  },
  {
    country: "PL",
    name: "European Academy of Arts in Warsaw",
    url: "http://www.eas.edu.pl/",
  },
  {
    country: "PL",
    name: "Fine Arts Academy in Gdansk",
    url: "http://www.asp.gda.pl/",
  },
  {
    country: "PL",
    name: "Fine Arts Academy in Katowice",
    url: "http://www.aspkat.edu.pl/",
  },
  {
    country: "PL",
    name: "Fine Arts Academy in Poznan",
    url: "http://www.asp.poznan.pl/",
  },
  {
    country: "PL",
    name: "Fine Arts Academy in Warsaw",
    url: "http://www.asp.waw.pl/",
  },
  {
    country: "PL",
    name: "Fine Arts Academy in Wroclaw",
    url: "http://www.asp.wroc.pl/",
  },
  {
    country: "PL",
    name: 'Fine Arts Academy "Jan Matejko" in Cracow',
    url: "http://149.156.19.10/",
  },
  {
    country: "PL",
    name: "Fine Arts Academy Wladyslaw Strzeminski in Lodz",
    url: "http://www.asp.lodz.pl/",
  },
  {
    country: "PL",
    name: "French Institute of Management",
    url: "http://www.ifg.com.pl/",
  },
  {
    country: "PL",
    name: "Gdansk Management College",
    url: "http://www.wsz.pl/",
  },
  {
    country: "PL",
    name: "Gdynia Maritime Academy",
    url: "http://www.wsm.gdynia.pl/",
  },
  {
    country: "PL",
    name: "Higher School o Business in Tarnow",
    url: "http://wsb.toi.tarnow.pl/",
  },
  {
    country: "PL",
    name: "Higher School o Business/National Louis University(WSB/NLU) in Nowy Sacz",
    url: "http://www.wsb-nlu.edu.pl/",
  },
  {
    country: "PL",
    name: "Jagiellonian University Cracow",
    url: "http://www.uj.edu.pl/",
  },
  {
    country: "PL",
    name: "Józef Tyszkiewicz College of Business and Computer Science in Bielsko-Biala",
    url: "http://www.tyszkiewicz.edu.pl/",
  },
  {
    country: "PL",
    name: "Katowice School of Economics",
    url: "http://www.gwsh.pl/",
  },
  {
    country: "PL",
    name: "Lazarski School of Commerce and Law",
    url: "http://www.lazarski.pl/",
  },
  {
    country: "PL",
    name: "Leon Kozminski Academy of Entrepreneurship and Mangement",
    url: "http://www.wspiz.edu.pl/",
  },
  {
    country: "PL",
    name: "Leon Kozminski Academy of Entrepreneurship and Mangement in Warsaw",
    url: "http://www.wspiz.edu.pl/",
  },
  {
    country: "PL",
    name: "Ludwik Solski State Academy of Theatre in Cracow",
    url: "http://www.pwst.krakow.pl/",
  },
  {
    country: "PL",
    name: "Maria Curie-Sklodowska University Lublin",
    url: "http://www.umcs.lublin.pl/",
  },
  {
    country: "PL",
    name: "Maria Grzegorzewska College for Special Education",
    url: "http://www.wsps.waw.pl/",
  },
  {
    country: "PL",
    name: "Maritime University in Szczecin",
    url: "http://www.wsm.szczecin.pl/",
  },
  {
    country: "PL",
    name: "Medical Academy in Bialystok",
    url: "http://www.amb.edu.pl/",
  },
  {
    country: "PL",
    name: "Medical Academy in Gdansk",
    url: "http://www.amg.gda.pl/",
  },
  {
    country: "PL",
    name: "Medical Academy in Lodz",
    url: "http://www.am.lodz.pl/",
  },
  {
    country: "PL",
    name: "Medical Academy in Lublin",
    url: "http://www.am.lublin.pl/",
  },
  {
    country: "PL",
    name: "Medical Academy in Warszawa",
    url: "http://www.amwaw.edu.pl/",
  },
  {
    country: "PL",
    name: "Medical Academy in Wroclaw",
    url: "http://www.am.wroc.pl/",
  },
  {
    country: "PL",
    name: "Medical Academy Karol Marcinkowski in Poznan",
    url: "http://www.usoms.poznan.pl/",
  },
  {
    country: "PL",
    name: "Medical Academy Ludwik Rydygier in Bydgoszcz",
    url: "http://www.amb.bydgoszcz.pl/",
  },
  {
    country: "PL",
    name: 'Music Academy "Felix Nowowiejski" in Bydgoszcz',
    url: "http://www.amuz.bydgoszcz.pl/",
  },
  {
    country: "PL",
    name: 'Music Academy "Fryderyk Chopin" in Warszaw',
    url: "http://www.chopin.edu.pl/",
  },
  {
    country: "PL",
    name: 'Music Academy "Ignacy Jana Paderewski" in Poznan',
    url: "http://www.amuz.poznan.pl/",
  },
  {
    country: "PL",
    name: "Music Academy in Cracow",
    url: "http://www.amuz.krakow.pl/",
  },
  {
    country: "PL",
    name: "Music Academy in Lodz",
    url: "http://www.amuz.lodz.pl/",
  },
  {
    country: "PL",
    name: 'Music Academy "Karol Lipinski" in Wroclaw',
    url: "http://www.amuz.wroc.pl/",
  },
  {
    country: "PL",
    name: 'Music Academy "Karol Szymanowski" in Katowice',
    url: "http://www.am.katowice.pl/",
  },
  {
    country: "PL",
    name: 'Music Academy "Stanislaw Moniuszko" in Gdansk',
    url: "http://www.amuz.gda.pl/",
  },
  {
    country: "PL",
    name: "Nicolaus Copernicus University of Torun",
    url: "http://www.uni.torun.pl/",
  },
  {
    country: "PL",
    name: "Pawel Wlodkowic University College in Plock",
    url: "http://www.wlodkowic.pl/",
  },
  {
    country: "PL",
    name: "Pedagogical University of Bydgoszcz",
    url: "http://www.wsp.bydgoszcz.pl/",
  },
  {
    country: "PL",
    name: "Pedagogical University of Czestochowa",
    url: "http://www.wsp.czest.pl/",
  },
  {
    country: "PL",
    name: "Pedagogical University of Kielce",
    url: "http://www.pu.kielce.pl/",
  },
  {
    country: "PL",
    name: "Pedagogical University of Krakow",
    url: "http://www.wsp.krakow.pl/",
  },
  {
    country: "PL",
    name: "Pedagogical University of Rzeszow",
    url: "http://www.univ.rzeszow.pl/",
  },
  {
    country: "PL",
    name: "Pedagogical University of Slupsk",
    url: "http://www.wsp.slupsk.pl/",
  },
  {
    country: "PL",
    name: "Pedagogical University of the Polish Association for Adult Education in Warsaw",
    url: "http://www.wsptwpwaw.edu.pl/",
  },
  {
    country: "PL",
    name: "Pedagogical University of Zielona Gora",
    url: "http://www.wsp.zgora.pl/",
  },
  {
    country: "PL",
    name: 'Physical Education Academy "Eugeniusz Piasecki" in Poznan',
    url: "http://www.awf.poznan.pl/",
  },
  {
    country: "PL",
    name: "Physical Education Academy in Katowice",
    url: "http://www.awf.katowice.pl/",
  },
  {
    country: "PL",
    name: "Physical Education Academy in Wroclaw",
    url: "http://www.awf.wroc.pl/",
  },
  {
    country: "PL",
    name: 'Physical Education Academy "Jedrzej Sniadecki" in Gdansk',
    url: "http://www.awf.gda.pl/",
  },
  {
    country: "PL",
    name: 'Physical Education Academy "Jozef Pilsudski" in Warsaw',
    url: "http://www.awf.edu.pl/",
  },
  {
    country: "PL",
    name: "Polish-Japanese Institute of Information Technology in Warsaw",
    url: "http://www.pjwstk.edu.pl/",
  },
  {
    country: "PL",
    name: "Polish Open University in Warsaw (WSZ/POU)",
    url: "http://www.wsz-pou.edu.pl/",
  },
  {
    country: "PL",
    name: "Polonia University in Czêstochowa",
    url: "http://www.ap.edu.pl/",
  },
  {
    country: "PL",
    name: "Pomeranian Academy of Medicine in Szczecin",
    url: "http://www.pam.szczecin.pl/",
  },
  {
    country: "PL",
    name: "Poznan School of Banking",
    url: "http://www.wsb.poznan.pl/",
  },
  {
    country: "PL",
    name: "Poznan University of Life Sciences",
    url: "http://www.puls.edu.pl/",
  },
  {
    country: "PL",
    name: "Ryszard Lazarski University of Commerce and Law in Warsaw",
    url: "http://www.wship.edu.pl/",
  },
  {
    country: "PL",
    name: "School of Banking and Management in Cracow",
    url: "http://www.wszib.krakow.pl/",
  },
  {
    country: "PL",
    name: "Silesian Academy of Medicine in Katowice",
    url: "http://www.slam.katowice.pl/",
  },
  {
    country: "PL",
    name: "Silesian School of Economics and Languages",
    url: "http://www.gallus.pl/",
  },
  {
    country: "PL",
    name: "Silesian Technical University of Gliwice",
    url: "http://www.polsl.gliwice.pl/",
  },
  {
    country: "PL",
    name: "Staszic Pila College",
    url: "http://www.pwsz.pila.pl/",
  },
  {
    country: "PL",
    name: "State Film, Television and Theatre Higher School Leon Schiller in Lodz",
    url: "http://www.filmowka.lodz.pl/",
  },
  {
    country: "PL",
    name: "Teacher Training College of Revalidation, Rehabilitation and Physical Education",
    url: "http://www.nkrriwf.pl/",
  },
  {
    country: "PL",
    name: "Technical University of Bialystok",
    url: "http://www.pb.bialystok.pl/",
  },
  {
    country: "PL",
    name: "Technical University of Cracow",
    url: "http://www.pk.edu.pl/",
  },
  {
    country: "PL",
    name: "Technical University of Czestochowa",
    url: "http://www.pcz.czest.pl/",
  },
  {
    country: "PL",
    name: "Technical University of Gdansk",
    url: "http://www.pg.gda.pl/",
  },
  {
    country: "PL",
    name: "Technical University of Kielce",
    url: "http://www.tu.kielce.pl/",
  },
  {
    country: "PL",
    name: "Technical University of Koszalin",
    url: "http://www.tu.koszalin.pl/",
  },
  {
    country: "PL",
    name: "Technical University of Lodz",
    url: "http://www.p.lodz.pl/",
  },
  {
    country: "PL",
    name: "Technical University of Lublin",
    url: "http://www.pol.lublin.pl/",
  },
  {
    country: "PL",
    name: "Technical University of Opole",
    url: "http://www.po.opole.pl/",
  },
  {
    country: "PL",
    name: "Technical University of Poznan",
    url: "http://www.put.poznan.pl/",
  },
  {
    country: "PL",
    name: "Technical University of Radom",
    url: "http://www.man.radom.pl/",
  },
  {
    country: "PL",
    name: "Technical University of Rzeszow",
    url: "http://www.prz.rzeszow.pl/",
  },
  {
    country: "PL",
    name: "Technical University of Szczecin",
    url: "http://www.tuniv.szczecin.pl/",
  },
  {
    country: "PL",
    name: "Technical University of Warsaw",
    url: "http://www.pw.edu.pl/",
  },
  {
    country: "PL",
    name: "Technical University of Wroclaw",
    url: "http://www.pwr.wroc.pl/",
  },
  {
    country: "PL",
    name: "Technical University of Zielona Gora",
    url: "http://www.pz.zgora.pl/",
  },
  {
    country: "PL",
    name: "University of Bialystok",
    url: "http://www.ub.edu.pl/",
  },
  {
    country: "PL",
    name: "University of Finance and Management in Bialystok",
    url: "http://www.wsfiz.edu.pl/",
  },
  {
    country: "PL",
    name: "University of Gdansk",
    url: "http://www.univ.gda.pl/",
  },
  {
    country: "PL",
    name: "University of Information Technology and Management in Rzeszow",
    url: "http://www.wsiz.rzeszow.pl/",
  },
  {
    country: "PL",
    name: "University of Insurance and Banking in Warsaw",
    url: "http://www.wsub.waw.pl/",
  },
  {
    country: "PL",
    name: "University of Lodz",
    url: "http://www.uni.lodz.pl/",
  },
  {
    country: "PL",
    name: "University of Lower Silesia of the Association for the Advancement of Education in Wroclaw.",
    url: "http://www.dswe.wroc.pl/",
  },
  {
    country: "PL",
    name: "University of Management and Marketing",
    url: "http://www.wszim.edu.pl/",
  },
  {
    country: "PL",
    name: "University of Opole",
    url: "http://www.uni.opole.pl/",
  },
  {
    country: "PL",
    name: "University of Silesia",
    url: "http://www.us.edu.pl/",
  },
  {
    country: "PL",
    name: "University of Szczecin",
    url: "http://www.univ.szczecin.pl/",
  },
  {
    country: "PL",
    name: "University of Technology and Life Sciences",
    url: "http://www.utp.edu.pl/",
  },
  {
    country: "PL",
    name: "University of Wamia and Masuria in Olsztyn",
    url: "http://www.uwm.edu.pl/",
  },
  {
    country: "PL",
    name: "University of Warsaw",
    url: "http://www.uw.edu.pl/",
  },
  {
    country: "PL",
    name: "University of Wroclaw",
    url: "http://www.uni.wroc.pl/",
  },
  {
    country: "PL",
    name: "University School of Physical Education",
    url: "http://www.awf.krakow.pl/",
  },
  {
    country: "PL",
    name: "Walcz College",
    url: "http://www.pwsz.eu/",
  },
  {
    country: "PL",
    name: "Warsaw School of Economics",
    url: "http://www.sgh.waw.pl/",
  },
  {
    country: "PL",
    name: "Warsaw School of Information Technology",
    url: "http://www.wsisiz.edu.pl/",
  },
  {
    country: "PL",
    name: "Warsaw School of Social Psychology",
    url: "http://www.swps.edu.pl/",
  },
  {
    country: "PL",
    name: "West Pomeranian Business School in Szczecin",
    url: "http://www.zpsb.szczecin.pl/",
  },
  {
    country: "PL",
    name: "Zachodniopomorska School of Science and Engineering",
    url: "http://www.zut.edu.pl/",
  },
  {
    country: "PR",
    name: "Caribbean University",
    url: "http://www.caribbean.edu/",
  },
  {
    country: "PR",
    name: "Centro de Estudios Avanzados de Puerto Rico y el Caribe",
    url: "http://www.ceaprc.org/",
  },
  {
    country: "PR",
    name: "Pontificia Universidad Catolica de Puerto Rico",
    url: "http://www.pucpr.edu/",
  },
  {
    country: "PR",
    name: "San Juan Bautista School of Medicine",
    url: "http://www.sanjuanbautista.edu/",
  },
  {
    country: "PR",
    name: "Sistema Universitario Ana G. Méndez",
    url: "http://www.suagm.edu/",
  },
  {
    country: "PR",
    name: "Universidad Central de Bayamón",
    url: "http://www.ucb.edu.pr/",
  },
  {
    country: "PR",
    name: "Universidad Central del Caribe",
    url: "http://www.uccaribe.edu/",
  },
  {
    country: "PR",
    name: "Universidad del Sagrado Corazon",
    url: "http://www.sagrado.edu/",
  },
  {
    country: "PR",
    name: "Universidad del Turabo",
    url: "http://www.ut.pr/",
  },
  {
    country: "PR",
    name: "Universidad de Puerto Rico",
    url: "http://www.upr.clu.edu/",
  },
  {
    country: "PR",
    name: "Universidad de Puerto Rico, Aguadilla",
    url: "http://www.uprag.edu/",
  },
  {
    country: "PR",
    name: "Universidad de Puerto Rico, Arecibo",
    url: "http://www.upra.edu/",
  },
  {
    country: "PR",
    name: "Universidad de Puerto Rico, Bayamon",
    url: "http://www.uprb.edu/",
  },
  {
    country: "PR",
    name: "Universidad de Puerto Rico, Carolina",
    url: "http://www.uprc.edu/",
  },
  {
    country: "PR",
    name: "Universidad de Puerto Rico, Cayey",
    url: "http://www.cayey.upr.edu/",
  },
  {
    country: "PR",
    name: "Universidad de Puerto Rico, Ciencias Medicas",
    url: "http://www.rcm.upr.edu/",
  },
  {
    country: "PR",
    name: "Universidad de Puerto Rico, Humacao",
    url: "http://cuhwww.upr.clu.edu/",
  },
  {
    country: "PR",
    name: "Universidad de Puerto Rico, Mayaguez",
    url: "http://www.uprm.edu/",
  },
  {
    country: "PR",
    name: "Universidad de Puerto Rico, Ponce",
    url: "http://upr-ponce.upr.edu/",
  },
  {
    country: "PR",
    name: "Universidad de Puerto Rico, Rio Pedras",
    url: "http://www.uprrp.edu/",
  },
  {
    country: "PR",
    name: "Universidad de Puerto Rico, Utuado",
    url: "http://www.uprutuado.edu/",
  },
  {
    country: "PR",
    name: "Universidad Interamericana de Puerto Rico",
    url: "http://www.inter.edu/",
  },
  {
    country: "PR",
    name: "Universidad Interamericana de Puerto Rico, Bayamón",
    url: "http://www.bc.inter.edu/",
  },
  {
    country: "PR",
    name: "Universidad Interamericana de Puerto Rico, San Germán",
    url: "http://www.sg.inter.edu/",
  },
  {
    country: "PR",
    name: "Universidad Metropolitana",
    url: "http://www.suagm.edu/suagm/umet/",
  },
  {
    country: "PR",
    name: "Universidad Politecnica de Puerto Rico",
    url: "http://www.pupr.edu/",
  },
  {
    country: "PS",
    name: "Al-Aqsa University",
    url: "http://www.alaqsa.edu.ps/",
  },
  {
    country: "PS",
    name: "Al-Azhar University of Gaza",
    url: "http://www.alazhar.edu.ps/",
  },
  {
    country: "PS",
    name: "Al-Quds Open University",
    url: "http://www.qou.edu/",
  },
  {
    country: "PS",
    name: "Al-Quds University - The Arab University in Jerusalem",
    url: "http://www.alquds.edu/",
  },
  {
    country: "PS",
    name: "An-Najah National University",
    url: "http://www.najah.edu/",
  },
  {
    country: "PS",
    name: "Arab American University - Jenin",
    url: "http://www.aauj.edu/",
  },
  {
    country: "PS",
    name: "Bethlehem University",
    url: "http://www.bethlehem.edu/",
  },
  {
    country: "PS",
    name: "Birzeit University",
    url: "http://www.birzeit.edu/",
  },
  {
    country: "PS",
    name: "Hebron University",
    url: "http://www.hebron.edu/",
  },
  {
    country: "PS",
    name: "Islamic University of Gaza",
    url: "http://www.iugaza.edu.ps/",
  },
  {
    country: "PS",
    name: "Palestine Polytechnic University",
    url: "http://www.ppu.edu/",
  },
  {
    country: "PS",
    name: "Palestine Technical College-Dier Elbalah",
    url: "http://www.ptcdb.edu.ps/",
  },
  {
    country: "PS",
    name: "Palestine Technical University - Kadoorie",
    url: "http://www.ptuk.edu.ps/",
  },
  {
    country: "PS",
    name: "Ummah University",
    url: "http://www.uou.edu.ps/",
  },
  {
    country: "PS",
    name: "University College of Applied Sciences",
    url: "http://www.ucas.edu.ps/",
  },
  {
    country: "PS",
    name: "University of Palestine",
    url: "http://www.up.edu.ps/",
  },
  {
    country: "PT",
    name: "Academia Nacional Superior de Orquesta",
    url: "http://www.oml.pt/anso/",
  },
  {
    country: "PT",
    name: "Escola Nautica Infante D. Henrique",
    url: "http://www.enautica.pt/",
  },
  {
    country: "PT",
    name: "Escola Superior de Artes e Design",
    url: "http://www.esad.pt/",
  },
  {
    country: "PT",
    name: "Escola Superior de Hotelaria e Turismo do Estoril",
    url: "http://www.eshte.pt/",
  },
  {
    country: "PT",
    name: "European University Portugal",
    url: "http://www.europeanuniversity.pt/",
  },
  {
    country: "PT",
    name: "Instituto de Artes Visuais, Design e Marketing - IADE",
    url: "http://www.iade.pt/",
  },
  {
    country: "PT",
    name: "Instituto Piaget",
    url: "http://www.ipiaget.org/",
  },
  {
    country: "PT",
    name: "Instituto Politécnico da Guarda",
    url: "http://www.ipg.pt/",
  },
  {
    country: "PT",
    name: "Instituto Politécnico de Beja",
    url: "http://www.ipbeja.pt/",
  },
  {
    country: "PT",
    name: "Instituto Politécnico de Bragança",
    url: "http://www.ipb.pt/",
  },
  {
    country: "PT",
    name: "Instituto Politécnico de Castelo Branco",
    url: "http://www.ipcb.pt/",
  },
  {
    country: "PT",
    name: "Instituto Politécnico de Coimbra",
    url: "http://www.ipc.pt/",
  },
  {
    country: "PT",
    name: "Instituto Politécnico de Leiria",
    url: "http://www.iplei.pt/",
  },
  {
    country: "PT",
    name: "Instituto Politécnico de Lisboa",
    url: "http://www.ipl.pt/",
  },
  {
    country: "PT",
    name: "Instituto Politécnico de Portalegre",
    url: "http://www.ipportalegre.pt/",
  },
  {
    country: "PT",
    name: "Instituto Politécnico de Santarém",
    url: "http://www.ipsantarem.pt/",
  },
  {
    country: "PT",
    name: "Instituto Politécnico de Setúbal",
    url: "http://www.ips.pt/",
  },
  {
    country: "PT",
    name: "Instituto Politécnico de Tomar",
    url: "http://www.ipt.pt/",
  },
  {
    country: "PT",
    name: "Instituto Politécnico de Viana do Castelo",
    url: "http://www.ipvc.pt/",
  },
  {
    country: "PT",
    name: "Instituto Politécnico do Cávado e do Ave",
    url: "http://www.ipca.pt/",
  },
  {
    country: "PT",
    name: "Instituto Politecnico do Porto",
    url: "http://www.ipp.pt/",
  },
  {
    country: "PT",
    name: "Instituto Superior Bissaya Barreto",
    url: "http://www.isbb.pt/",
  },
  {
    country: "PT",
    name: "Instituto Superior D. Afonso III - INUAF",
    url: "http://www.inuaf-studia.pt/",
  },
  {
    country: "PT",
    name: "Instituto Superior de Assistentes e Intérpretes",
    url: "http://www.isai.pt/",
  },
  {
    country: "PT",
    name: "Instituto Superior de Ciências do Trabalho e da Empresa",
    url: "http://www.iscte.pt/",
  },
  {
    country: "PT",
    name: "Instituto Superior de Ciências Educativas",
    url: "http://www.pedago.pt/isce.htm",
  },
  {
    country: "PT",
    name: "Instituto Superior de Ciências Empresariais e de Turismo",
    url: "http://www.iscet.pt/",
  },
  {
    country: "PT",
    name: "Instituto Superior de Entre Douro e Vouga",
    url: "http://www.isvouga.com/",
  },
  {
    country: "PT",
    name: "Instituto Superior de Gestão - ISG",
    url: "http://www.isg.pt/",
  },
  {
    country: "PT",
    name: "Instituto Superior de Informática e Gestão",
    url: "http://www.cocite.pt/",
  },
  {
    country: "PT",
    name: "Instituto Superior de Línguas e Administração",
    url: "http://www.isla.pt/",
  },
  {
    country: "PT",
    name: "Instituto Superior de Paços de Brandão",
    url: "http://www.ispab.pt/",
  },
  {
    country: "PT",
    name: "Instituto Superior de Psicologia Aplicada",
    url: "http://www.ispa.pt/",
  },
  {
    country: "PT",
    name: "Instituto Superior de Saúde do Alto Ave",
    url: "http://www.isave.edu.pt/",
  },
  {
    country: "PT",
    name: "Instituto Superior de Tecnologias Avançadas - ISTEC",
    url: "http://www.istec.pt/",
  },
  {
    country: "PT",
    name: "Instituto Superior de Transportes e Comunicações",
    url: "http://www.istp.pt/",
  },
  {
    country: "PT",
    name: "Instituto Superior Novas Profissões - INP",
    url: "http://www.inp.pt/",
  },
  {
    country: "PT",
    name: "Instituto Superior Politécnico Gaya",
    url: "http://www.ispgaya.pt/",
  },
  {
    country: "PT",
    name: "Universidade Aberta Lisboa",
    url: "http://www.univ-ab.pt/",
  },
  {
    country: "PT",
    name: "Universidade Atlântica",
    url: "http://www.uatla.pt/",
  },
  {
    country: "PT",
    name: "Universidade Autónoma de Lisboa Luís de Camoes",
    url: "http://www.universidade-autonoma.pt/",
  },
  {
    country: "PT",
    name: "Universidade Catolica Portuguesa",
    url: "http://www.ucp.pt/",
  },
  {
    country: "PT",
    name: "Universidade da Beira Interior",
    url: "http://www.ubi.pt/",
  },
  {
    country: "PT",
    name: "Universidade da Madeira",
    url: "http://www.uma.pt/",
  },
  {
    country: "PT",
    name: "Universidade de Aveiro",
    url: "http://www.ua.pt/",
  },
  {
    country: "PT",
    name: "Universidade de Coimbra",
    url: "http://www.uc.pt/",
  },
  {
    country: "PT",
    name: "Universidade de Evora",
    url: "http://www.uevora.pt/",
  },
  {
    country: "PT",
    name: "Universidade de Lisboa",
    url: "http://www.ul.pt/",
  },
  {
    country: "PT",
    name: "Universidade de Tras-os-Montes e Alto Douro",
    url: "http://www.utad.pt/",
  },
  {
    country: "PT",
    name: "Universidade do Algarve",
    url: "http://www.ualg.pt/",
  },
  {
    country: "PT",
    name: "Universidade do Minho",
    url: "http://www.uminho.pt/",
  },
  {
    country: "PT",
    name: "Universidade do Porto",
    url: "http://www.up.pt/",
  },
  {
    country: "PT",
    name: "Universidade dos Acores",
    url: "http://www.uac.pt/",
  },
  {
    country: "PT",
    name: "Universidade Fernando Pessoa",
    url: "http://www.ufp.pt/",
  },
  {
    country: "PT",
    name: "Universidade Independente",
    url: "http://www.uni.pt/",
  },
  {
    country: "PT",
    name: "Universidade Internacional Lisboa",
    url: "http://www.uinternacional.pt/",
  },
  {
    country: "PT",
    name: "Universidade Lusiada",
    url: "http://www.ulusiada.pt/",
  },
  {
    country: "PT",
    name: "Universidade Lusófona de Humanidades e Technologias",
    url: "http://www.ulusofona.pt/",
  },
  {
    country: "PT",
    name: "Universidade Moderna de Lisboa",
    url: "http://www.umoderna.pt/",
  },
  {
    country: "PT",
    name: "Universidade Nova de Lisboa",
    url: "http://www.unl.pt/",
  },
  {
    country: "PT",
    name: "Universidade Portucalense Infante D. Henrique",
    url: "http://www.uportu.pt/",
  },
  {
    country: "PT",
    name: "Universidade Tecnica de Lisboa",
    url: "http://www.utl.pt/",
  },
  {
    country: "PY",
    name: "Universidad Americana",
    url: "http://www.uamericana.edu.py/",
  },
  {
    country: "PY",
    name: "Universidad Autónoma de Asunción",
    url: "http://www.uaa.edu.py/",
  },
  {
    country: "PY",
    name: "Universidad Autónoma del Paraguay",
    url: "http://www.uap.edu.py/",
  },
  {
    country: "PY",
    name: "Universidad Católica Nuestra Senora de la Asunción",
    url: "http://www.uc.edu.py/",
  },
  {
    country: "PY",
    name: "Universidad Columbia del Paraguay",
    url: "http://www.columbia.edu.py/",
  },
  {
    country: "PY",
    name: "Universidad Comunera",
    url: "http://www.ucom.edu.py/",
  },
  {
    country: "PY",
    name: "Universidad de la Integración de las Americas (UNIDAD)",
    url: "http://www.unida.edu.py/",
  },
  {
    country: "PY",
    name: "Universidad del Cono Sur de las Américas",
    url: "http://www.ucsa.edu.py/",
  },
  {
    country: "PY",
    name: "Universidad del Norte",
    url: "http://www.uninorte.edu.py/",
  },
  {
    country: "PY",
    name: "Universidad Metropolitana de Asunción",
    url: "http://www.uma.edu.py/",
  },
  {
    country: "PY",
    name: "Universidad Nacional de Asunción",
    url: "http://www.una.py/",
  },
  {
    country: "PY",
    name: "Universidad Nacional del Este",
    url: "http://www.une.edu.py/",
  },
  {
    country: "PY",
    name: "Universidad Privada del Este",
    url: "http://www.upe.edu.py/",
  },
  {
    country: "QA",
    name: "Northwestern University In Qatar",
    url: "http://www.qatar.northwestern.edu/",
  },
  {
    country: "QA",
    name: "University of Qatar",
    url: "http://www.qu.edu.qa/",
  },
  {
    country: "RE",
    name: "Université de la Reunion",
    url: "http://www.univ-reunion.fr/",
  },
  {
    country: "RO",
    name: "1 December University of Alba Iulia",
    url: "http://www.uab.ro/",
  },
  {
    country: "RO",
    name: "Academia de Studii Economice din Bucuresti",
    url: "http://www.ase.ro/",
  },
  {
    country: "RO",
    name: "Academia Tehnica Militara",
    url: "http://www.mta.ro/",
  },
  {
    country: "RO",
    name: 'Academy of Arts "George Enescu" Iasi',
    url: "http://www.arteiasi.ro/",
  },
  {
    country: "RO",
    name: 'Academy of Music "Georghe Dima" Cluj-Napoca',
    url: "http://www.amgd.eu/",
  },
  {
    country: "RO",
    name: "AISTEDA",
    url: "http://www.aistedaab.ro/",
  },
  {
    country: "RO",
    name: "Babes-Bolyai University of Cluj-Napoca",
    url: "http://www.ubbcluj.ro/",
  },
  {
    country: "RO",
    name: "Banat's University of Agricultural Sciences",
    url: "http://www.usab-tm.ro/",
  },
  {
    country: "RO",
    name: "Constantin Brancoveanu University Pitesti",
    url: "http://www.univcb.ro/",
  },
  {
    country: "RO",
    name: "Emanuel University",
    url: "http://www.emanuel.ro/",
  },
  {
    country: "RO",
    name: 'Institute of Architecture "Ion Mincu" Bucharest',
    url: "http://www.iaim.ro/",
  },
  {
    country: "RO",
    name: "Maritime University Constanta",
    url: "http://www.universitateamaritima.ro/",
  },
  {
    country: "RO",
    name: "National Academy for Physical Education and Sports Bucharest",
    url: "http://www.anefs-edu.ro/",
  },
  {
    country: "RO",
    name: "National School of Political and Administrative Studies Bucharest",
    url: "http://www.snspa.ro/",
  },
  {
    country: "RO",
    name: "National University of Arts",
    url: "http://www.unarte.ro/",
  },
  {
    country: "RO",
    name: "National University of Music",
    url: "http://www.unmb.ro/",
  },
  {
    country: "RO",
    name: "National University of Theater and Film Arts",
    url: "http://www.unatc.ro/",
  },
  {
    country: "RO",
    name: "North University of Baia Mare",
    url: "http://www.ubm.ro/",
  },
  {
    country: "RO",
    name: "Oradea University",
    url: "http://www.oradeauniversity.com",
  },
  {
    country: "RO",
    name: "Petru Maior University of Targu Mures",
    url: "http://www.upm.ro/",
  },
  {
    country: "RO",
    name: "Romanian-American University",
    url: "http://www.rau.ro/",
  },
  {
    country: "RO",
    name: "Spiru Haret University",
    url: "http://www.spiruharet.ro/",
  },
  {
    country: "RO",
    name: "Targu-Mures University of Theatre",
    url: "http://www.uat.ro/",
  },
  {
    country: "RO",
    name: "Technical University of Civil Engineering Bucharest",
    url: "http://www.utcb.ro/",
  },
  {
    country: "RO",
    name: "Technical University of Cluj-Napoca",
    url: "http://www.utcluj.ro/",
  },
  {
    country: "RO",
    name: "Technical University of Iasi",
    url: "http://www.tuiasi.ro/",
  },
  {
    country: "RO",
    name: "Technical University of Timisoara",
    url: "http://www.utt.ro/",
  },
  {
    country: "RO",
    name: 'Universitatea de Vest "Vasile Goldi&#351;',
    url: "http://www.uvvg.ro/",
  },
  {
    country: "RO",
    name: 'University "Aurel Vlaicu" Arad',
    url: "http://www.uav.ro/",
  },
  {
    country: "RO",
    name: "University Lucian Blaga of Sibiu",
    url: "http://www.ulbsibiu.ro/",
  },
  {
    country: "RO",
    name: "University of Agriculture and Veterinary Medicine Bucharest",
    url: "http://www.usab.ro/",
  },
  {
    country: "RO",
    name: "University of Agriculture and Veterinary Medicine Cluj-Napoca",
    url: "http://www.usamvcluj.ro/",
  },
  {
    country: "RO",
    name: "University of Agriculture and Veterinary Medicine Iasi",
    url: "http://www.univagro-iasi.ro/",
  },
  {
    country: "RO",
    name: "University of Agriculture and Veterinary Medicine Timisoara",
    url: "http://www.usab-tm.ro/",
  },
  {
    country: "RO",
    name: "University of Art and Design Cluj-Napoca",
    url: "http://www.uartdcluj.ro/",
  },
  {
    country: "RO",
    name: "University of Bacau",
    url: "http://www.ub.ro/",
  },
  {
    country: "RO",
    name: "University of Bucharest",
    url: "http://www.unibuc.ro/",
  },
  {
    country: "RO",
    name: "University of Constanta",
    url: "http://www.univ-ovidius.ro/",
  },
  {
    country: "RO",
    name: "University of Constanta Medical School",
    url: "http://www.ovidunivconstanta-edu.org/",
  },
  {
    country: "RO",
    name: "University of Craiova",
    url: "http://www.central.ucv.ro/",
  },
  {
    country: "RO",
    name: "University of Galatzi",
    url: "http://www.ugal.ro/",
  },
  {
    country: "RO",
    name: "University of Iasi",
    url: "http://www.uaic.ro/",
  },
  {
    country: "RO",
    name: "University of Medicine and Pharmacology of Oradea",
    url: "http://www.oradeauniversity.com/",
  },
  {
    country: "RO",
    name: "University of Medicine and Pharmacy of Bucharest",
    url: "http://www.univermed-cdgm.ro/",
  },
  {
    country: "RO",
    name: "University of Medicine and Pharmacy of Cluj-Napoca",
    url: "http://www.umfcluj.ro/",
  },
  {
    country: "RO",
    name: "University of Medicine and Pharmacy of Iasi",
    url: "http://www.umfiasi.ro/",
  },
  {
    country: "RO",
    name: "University of Medicine and Pharmacy of Targu Mures",
    url: "http://www.umftgm.ro/",
  },
  {
    country: "RO",
    name: "University of Medicine and Pharmacy of Timisoara",
    url: "http://www.umft.ro/",
  },
  {
    country: "RO",
    name: "University of Oradea",
    url: "http://www.uoradea.ro/",
  },
  {
    country: "RO",
    name: "University of Petrosani",
    url: "http://www.upet.ro/",
  },
  {
    country: "RO",
    name: "University of Pitesti",
    url: "http://www.upit.ro/",
  },
  {
    country: "RO",
    name: "University of Resita",
    url: "http://www.uem.ro/",
  },
  {
    country: "RO",
    name: "University of Sibiu",
    url: "http://www.sibiu.ro/",
  },
  {
    country: "RO",
    name: "University of Suceava",
    url: "http://www.usv.ro/",
  },
  {
    country: "RO",
    name: "University of Targu Jiu",
    url: "http://www.utgjiu.ro/",
  },
  {
    country: "RO",
    name: "University Oil- Gas Ploiesti",
    url: "http://www.upg-ploiesti.ro/",
  },
  {
    country: "RO",
    name: 'University "Petre Andrei" Iasi',
    url: "http://www.upa.ro/",
  },
  {
    country: "RO",
    name: "University Politehnica of Bucharest",
    url: "http://www.pub.ro/",
  },
  {
    country: "RO",
    name: 'University "Titu Maiorescu',
    url: "http://www.utm.ro/",
  },
  {
    country: "RO",
    name: 'University "Transilvany" of Brasov',
    url: "http://www.unitbv.ro/",
  },
  {
    country: "RO",
    name: "Valahia University of Targoviste",
    url: "http://www.valahia.ro/",
  },
  {
    country: "RO",
    name: "West University of Timisoara",
    url: "http://www.uvt.ro/",
  },
  {
    country: "RS",
    name: "Drzavni Univerzitet u Novom Pazaru",
    url: "http://www.np.ac.rs/",
  },
  {
    country: "RS",
    name: "European University",
    url: "http://www.eu.ac.rs/",
  },
  {
    country: "RS",
    name: "International University of Novi Pazar",
    url: "http://www.uninp.edu.rs/",
  },
  {
    country: "RS",
    name: "Megatrend University of Applied Sciences",
    url: "http://www.megatrend-edu.net/",
  },
  {
    country: "RS",
    name: "Metropolitan University",
    url: "http://www.metropolitan.edu.rs/",
  },
  {
    country: "RS",
    name: "Privredna Akademija (Business Academy)",
    url: "http://www.privrednaakademija.edu.rs/",
  },
  {
    country: "RS",
    name: "Singidunum University",
    url: "http://www.singidunum.edu.rs/",
  },
  {
    country: "RS",
    name: "University of Belgrade",
    url: "http://www.bg.ac.rs/",
  },
  {
    country: "RS",
    name: "University of Fine Arts Belgrade",
    url: "http://www.arts.bg.ac.rs/",
  },
  {
    country: "RS",
    name: "University of Kragujevac",
    url: "http://www.kg.ac.rs/",
  },
  {
    country: "RS",
    name: "University of Nis",
    url: "http://www.ni.ac.rs/",
  },
  {
    country: "RS",
    name: "University of Novi Sad",
    url: "http://www.uns.ac.rs/",
  },
  {
    country: "RS",
    name: "University of Priština",
    url: "http://www.pr.ac.rs/",
  },
  {
    country: "RU",
    name: "Academy of Management and Entrepreneurship",
    url: "http://www.ame.ru/",
  },
  {
    country: "RU",
    name: "Adygeja State University",
    url: "http://www.adygnet.ru/",
  },
  {
    country: "RU",
    name: "Altai State Medical University",
    url: "http://www.asmu.ru/",
  },
  {
    country: "RU",
    name: "Altai State Technical University",
    url: "http://www.astu.secna.ru/",
  },
  {
    country: "RU",
    name: "Altai State University",
    url: "http://www.asu.ru/",
  },
  {
    country: "RU",
    name: "Amur State University",
    url: "http://www.amursu.ru/",
  },
  {
    country: "RU",
    name: "Arhangelsk State Technical University",
    url: "http://www.agtu.ru/",
  },
  {
    country: "RU",
    name: "Astrahan State Medical Academy",
    url: "http://agma.astranet.ru/",
  },
  {
    country: "RU",
    name: "Astrahan State Technical University",
    url: "http://www.astu.org/",
  },
  {
    country: "RU",
    name: "Astrakhan State University",
    url: "http://www.aspu.ru/",
  },
  {
    country: "RU",
    name: "Baikal National University of Economics and Law",
    url: "http://www.isea.ru/",
  },
  {
    country: "RU",
    name: "Baltic State Technical University",
    url: "http://www.voenmeh.ru/",
  },
  {
    country: "RU",
    name: "Bashkir State Agrarian University",
    url: "http://www.bashedu.ru/vuz/bgau_e.htm",
  },
  {
    country: "RU",
    name: "Bashkir State Medical University",
    url: "http://www.bashedu.ru/vuz/bgmu_e.htm",
  },
  {
    country: "RU",
    name: "Bashkir State Pedagogical University named After M. Akmullah",
    url: "http://www.bspu.ru/",
  },
  {
    country: "RU",
    name: "Bashkir State University",
    url: "http://www.bashedu.ru/",
  },
  {
    country: "RU",
    name: "Bauman Moscow State Technical University",
    url: "http://www.bmstu.ru/",
  },
  {
    country: "RU",
    name: "Belgorod State Agricultural Academy",
    url: "http://www.bsaa.ru/",
  },
  {
    country: "RU",
    name: "Belgorod State Technical University",
    url: "http://www.bstu.ru/",
  },
  {
    country: "RU",
    name: "Belgorod State University",
    url: "http://www.bsu.edu.ru/",
  },
  {
    country: "RU",
    name: "Birsk State Pedagogical Institute",
    url: "http://www.bashedu.ru/vuz/birskgpi_e.htm",
  },
  {
    country: "RU",
    name: "Biysk State Padagogical University after V.M. Shukshin",
    url: "http://www.bigpi.biysk.ru/wwwsite/",
  },
  {
    country: "RU",
    name: "Bratsk State Technical University",
    url: "http://www.brstu.ru/",
  },
  {
    country: "RU",
    name: "Brjansk State Technical University",
    url: "http://www.tu-bryansk.ru/",
  },
  {
    country: "RU",
    name: "Burjat State University",
    url: "http://www.bsu.burnet.ru/",
  },
  {
    country: "RU",
    name: "Cheljabinsk State Institute of Teacher Training",
    url: "http://cspi.urc.ac.ru/",
  },
  {
    country: "RU",
    name: "Cheljabinsk State University",
    url: "http://www.csu.ac.ru/",
  },
  {
    country: "RU",
    name: "Cheljabinsk University of Agricultural Engineering",
    url: "http://www.urc.ac.ru/Universities/AGRO/",
  },
  {
    country: "RU",
    name: "Chuvash State University",
    url: "http://www.chuvsu.ru/",
  },
  {
    country: "RU",
    name: "Dagestan State University",
    url: "http://www.dgu.ru/",
  },
  {
    country: "RU",
    name: "Don State Agrarian University",
    url: "http://www.dongau.ru/",
  },
  {
    country: "RU",
    name: "Don State Technical University",
    url: "http://www.dstu.edu.ru/",
  },
  {
    country: "RU",
    name: "Dubna International University for Nature, Society and Man",
    url: "http://www.uni-dubna.ru/",
  },
  {
    country: "RU",
    name: "East-Siberian State Institute of Culture",
    url: "http://www.vsgaki.burnet.ru/",
  },
  {
    country: "RU",
    name: "East-Siberian State University",
    url: "http://www.vsgtu.eastsib.ru/",
  },
  {
    country: "RU",
    name: "East-Siberian State University of Technology",
    url: "http://www.esstu.ru/",
  },
  {
    country: "RU",
    name: "European University at St.Petersburg",
    url: "http://www.eu.spb.ru/",
  },
  {
    country: "RU",
    name: "Far Easten State University of Humanities",
    url: "http://www.khspu.ru/",
  },
  {
    country: "RU",
    name: "Far Eastern State Technical Fisheries University",
    url: "http://www.dalrybvtuz.ru/",
  },
  {
    country: "RU",
    name: "Far Eastern State Technical University",
    url: "http://www.festu.ru/",
  },
  {
    country: "RU",
    name: "Far Eastern State University",
    url: "http://www.dvgu.ru/",
  },
  {
    country: "RU",
    name: "Far East State Transport University",
    url: "http://www.dvgups.ru/",
  },
  {
    country: "RU",
    name: "Finance Academy",
    url: "http://www.fa.ru/",
  },
  {
    country: "RU",
    name: "Gnesins Russian Academy of Music",
    url: "http://www.gnesin-academy.ru/",
  },
  {
    country: "RU",
    name: "Gorno-Altaisk State University",
    url: "http://www.gasu.ru/",
  },
  {
    country: "RU",
    name: "Gubkin Russian State University of Oil and Gas",
    url: "http://www.gubkin.ru/",
  },
  {
    country: "RU",
    name: "Herzen State Pedagogical University of Russia",
    url: "http://www.herzen.spb.ru/",
  },
  {
    country: "RU",
    name: "Higher School of Economics",
    url: "http://www.hse.ru/",
  },
  {
    country: "RU",
    name: "Immanuel Kant State University of Russia",
    url: "http://www.kantiana.ru/",
  },
  {
    country: "RU",
    name: "I.M. Sechenov Moscow Medical Academy",
    url: "http://www.mma.ru/",
  },
  {
    country: "RU",
    name: "Institute for the Economy in Transition",
    url: "http://www.iet.ru/",
  },
  {
    country: "RU",
    name: "Institute of Business and Politics",
    url: "http://www.ibp-moscow.ru/",
  },
  {
    country: "RU",
    name: "Institute of Management and Economy",
    url: "http://www.ime.ru/",
  },
  {
    country: "RU",
    name: "International Banking Institute, St. Petersburg",
    url: "http://www.ibi.spb.ru/",
  },
  {
    country: "RU",
    name: "International East-European University",
    url: "http://www.ieeu.udm.ru/",
  },
  {
    country: "RU",
    name: "International University of Engineering, Moscow",
    url: "http://www.miu.ru/",
  },
  {
    country: "RU",
    name: "International University of Fundamental Studies, St. Petersburg",
    url: "http://www.iufs.edu/",
  },
  {
    country: "RU",
    name: "Irkutsk State Academy of Economics",
    url: "http://www.isea.irk.ru/",
  },
  {
    country: "RU",
    name: "Irkutsk State Technical University",
    url: "http://www.istu.irk.ru/",
  },
  {
    country: "RU",
    name: "Irkutsk State University",
    url: "http://www.isu.ru/",
  },
  {
    country: "RU",
    name: "Ivanovo State Academy of Medicine",
    url: "http://isma.indi.ru/",
  },
  {
    country: "RU",
    name: "Ivanovo State Power University",
    url: "http://www.ispu.ru/",
  },
  {
    country: "RU",
    name: "Ivanovo State Textile Academy",
    url: "http://education.ivanovo.ru:8101/IGTA/",
  },
  {
    country: "RU",
    name: "Ivanovo State University",
    url: "http://www.ivanovo.ac.ru/",
  },
  {
    country: "RU",
    name: "Ivanovo State University of Chemistry and Technology",
    url: "http://www.isuct.ru/",
  },
  {
    country: "RU",
    name: "Izhevsk State Technical University",
    url: "http://www.istu.ru/",
  },
  {
    country: "RU",
    name: "Jewish University in Moscow",
    url: "http://www.jum.ru/",
  },
  {
    country: "RU",
    name: "Kabardino-Balkarian State University",
    url: "http://www.kbsu.ru/",
  },
  {
    country: "RU",
    name: "Kaliningrad State Technical University",
    url: "http://www.klgtu.ru/",
  },
  {
    country: "RU",
    name: "Kalmyk State University",
    url: "http://www.kalmsu.ru/",
  },
  {
    country: "RU",
    name: "Kaluga State Pedagogical University",
    url: "http://kspu.kaluga.ru/",
  },
  {
    country: "RU",
    name: "Kamchatka State University Vitus Bering",
    url: "http://www.kamgu.ru/",
  },
  {
    country: "RU",
    name: "Karelian State Pedagogical University",
    url: "http://kspu.ptz.ru/",
  },
  {
    country: "RU",
    name: "Kazan State Academy of Architecture and Civil Engineering",
    url: "http://www.ksaba.ru/",
  },
  {
    country: "RU",
    name: "Kazan State Finance and Economics Institute",
    url: "http://www.ksfei.ru/",
  },
  {
    country: "RU",
    name: "Kazan State Music Conservatory",
    url: "http://www.kazanconservatory.ru/",
  },
  {
    country: "RU",
    name: "Kazan State Pedagogical University",
    url: "http://www.kgpu.ru/",
  },
  {
    country: "RU",
    name: "Kazan State University",
    url: "http://www.ksu.ru/",
  },
  {
    country: "RU",
    name: "Kazan State University of Medicine",
    url: "http://www.kgmu.kcn.ru/",
  },
  {
    country: "RU",
    name: "Kazan State University of Technology",
    url: "http://www.kai.ru/",
  },
  {
    country: "RU",
    name: "Kemerovo State University",
    url: "http://www.kemsu.ru/",
  },
  {
    country: "RU",
    name: "Khabarovsk State Academy of Economics and Law",
    url: "http://www.ael.ru/",
  },
  {
    country: "RU",
    name: "Khabarovsk State University of Technology",
    url: "http://www.khstu.ru/",
  },
  {
    country: "RU",
    name: "Komsomolsk-on-Amur State Technical University",
    url: "http://www.knastu.ru/",
  },
  {
    country: "RU",
    name: "Kostroma State Technological University",
    url: "http://www.kstu.edu.ru/",
  },
  {
    country: "RU",
    name: "Krasnoyarsk State Technical University",
    url: "http://www.kgtu.runnet.ru/",
  },
  {
    country: "RU",
    name: "Kuban State University",
    url: "http://www.kubsu.ru/",
  },
  {
    country: "RU",
    name: "Kuban State University of Agriculture",
    url: "http://www.kubagro.ru/",
  },
  {
    country: "RU",
    name: "Kuban State University of Technology",
    url: "http://www.kubstu.ru/",
  },
  {
    country: "RU",
    name: "Kurgan International University",
    url: "http://www.kiu.ru/",
  },
  {
    country: "RU",
    name: "Kursk State Medical University",
    url: "http://www.ksmu.kursknet.ru/",
  },
  {
    country: "RU",
    name: "Kursk State Technical University",
    url: "http://www.kstu.kursk.ru/",
  },
  {
    country: "RU",
    name: "Kursk State University",
    url: "http://www.kursk-uni.ru/",
  },
  {
    country: "RU",
    name: "Kuzbass State Technical University",
    url: "http://www.kuzstu.ac.ru/",
  },
  {
    country: "RU",
    name: "Linguistic University of Nizhny Novgorod",
    url: "http://www.lunn.sci-nnov.ru/",
  },
  {
    country: "RU",
    name: "Lipeck State Technical University",
    url: "http://www.stu.lipetsk.ru/",
  },
  {
    country: "RU",
    name: "Magnitogorsk State University",
    url: "http://www.masu.ru/",
  },
  {
    country: "RU",
    name: "Marij State Technical University",
    url: "http://www.marstu.mari.ru/",
  },
  {
    country: "RU",
    name: "Marij State University",
    url: "http://margu.mari.ru/",
  },
  {
    country: "RU",
    name: "Maxim Gorky Institute of Literature",
    url: "http://filine.centro.ru/Gorky/",
  },
  {
    country: "RU",
    name: "Mendeleev Russian University of Chemical Technology",
    url: "http://www.muctr.edu.ru/",
  },
  {
    country: "RU",
    name: "Modern Institute of Managament",
    url: "http://miom.org/",
  },
  {
    country: "RU",
    name: "Modern University for the Humanities",
    url: "http://www.muh.ru/",
  },
  {
    country: "RU",
    name: "Mordovian State University",
    url: "http://www.mrsu.ru/",
  },
  {
    country: "RU",
    name: "Moscow External University of the Humanities",
    url: "http://www.chat.ru/~megu/",
  },
  {
    country: "RU",
    name: "Moscow Institute of Physics and Technology",
    url: "http://www.mipt.ru/",
  },
  {
    country: "RU",
    name: "Moscow International Higher Business School (MIRBIS)",
    url: "http://www.mirbis.ru/",
  },
  {
    country: "RU",
    name: "Moscow P. I. Tchaikovsky Conservatory",
    url: "http://www.mosconsv.ru/",
  },
  {
    country: "RU",
    name: "Moscow Power Engineering Institute (Technical University)",
    url: "http://www.mpei.ac.ru/",
  },
  {
    country: "RU",
    name: "Moscow State Academy of Applied Biotechnology",
    url: "http://www.msaab.ru/",
  },
  {
    country: "RU",
    name: "Moscow State Automobile and Road Technical University",
    url: "http://www.madi.ru/",
  },
  {
    country: "RU",
    name: "Moscow State Industrial University",
    url: "http://www.msiu.ru/",
  },
  {
    country: "RU",
    name: "Moscow State Institute of International Relations MFA Russia (MGIMO-University)",
    url: "http://www.mgimo.ru/",
  },
  {
    country: "RU",
    name: "Moscow State Institute of Radiotechnics, Electronics and Automation (Technical University)",
    url: "http://www.mirea.ru/",
  },
  {
    country: "RU",
    name: "Moscow State Institute of Steel and Alloys",
    url: "http://www.misis.ru/",
  },
  {
    country: "RU",
    name: "Moscow State Technical University of Civil Aviation",
    url: "http://www.mstuca.ru/",
  },
  {
    country: "RU",
    name: "Moscow State Textile University A.N. Kosygin",
    url: "http://www.msta.ac.ru/",
  },
  {
    country: "RU",
    name: "Moscow State University",
    url: "http://www.msu.ru/",
  },
  {
    country: "RU",
    name: "Moscow State University M.V. Lomonosov",
    url: "http://www.msu.ru/",
  },
  {
    country: "RU",
    name: "Moscow State University of Civil Engineering",
    url: "http://mgsu.norna.ru/",
  },
  {
    country: "RU",
    name: "Moscow State University of Commerce",
    url: "http://www.rsute.ru/",
  },
  {
    country: "RU",
    name: "Moscow State University of Culture",
    url: "http://www.msuc.edu.ru/",
  },
  {
    country: "RU",
    name: "Moscow State University of Ecological Engineering",
    url: "http://www.msuie.ru/",
  },
  {
    country: "RU",
    name: "Moscow State University of Economics, Statistics and Informatics",
    url: "http://www.mesi.ru/",
  },
  {
    country: "RU",
    name: "Moscow State University of Environmental Engineering",
    url: "http://www.msuee.ru/",
  },
  {
    country: "RU",
    name: "Moscow State University of Forestry Engineering",
    url: "http://www.mgul.ac.ru/",
  },
  {
    country: "RU",
    name: "Moscow State University of Geodesy and Cartography",
    url: "http://www.miigaik.ru/",
  },
  {
    country: "RU",
    name: "Moscow State University of Railway Transport",
    url: "http://www.miit.ru/",
  },
  {
    country: "RU",
    name: "Moscow State University of Technology (Stankin)",
    url: "http://www.stankin.ru/",
  },
  {
    country: "RU",
    name: "Moscow Technical University of Informatics and Communication",
    url: "http://www.mtuci.ru/",
  },
  {
    country: "RU",
    name: "Moscow University Touro",
    url: "http://www.touro.ru/",
  },
  {
    country: "RU",
    name: "Murmansk State Technical University",
    url: "http://www.mstu.edu.ru/",
  },
  {
    country: "RU",
    name: "National Research University of Electronic Technology",
    url: "http://www.miet.ru/",
  },
  {
    country: "RU",
    name: "Nayanova University",
    url: "http://www.nayanova.edu/",
  },
  {
    country: "RU",
    name: "Nevsky Institute of Language and Culture",
    url: "http://www.nilc.spb.ru/",
  },
  {
    country: "RU",
    name: "New Economic School",
    url: "http://www.nes.ru/",
  },
  {
    country: "RU",
    name: "NGO Vladikavkaz Institute of Economics, Management, and Law",
    url: "http://www.vieup.ru/",
  },
  {
    country: "RU",
    name: "Nizhny Novgorod State Academy of Medicine",
    url: "http://www.n-nov.mednet.com/",
  },
  {
    country: "RU",
    name: "Nizhny Novgorod State Architectural - Building University",
    url: "http://www.ngasu.sci-nnov.ru/",
  },
  {
    country: "RU",
    name: "Nizhny Novgorod State Technical University",
    url: "http://www.nntu.sci-nnov.ru/",
  },
  {
    country: "RU",
    name: "Nizhny Novgorod State University",
    url: "http://www.unn.ac.ru/",
  },
  {
    country: "RU",
    name: "Northern State Medical University",
    url: "http://www.nsmu.ru/",
  },
  {
    country: "RU",
    name: "North Ossetian State University",
    url: "http://www.nosu.ru/",
  },
  {
    country: "RU",
    name: "North-West State Technical University",
    url: "http://www.nwpi.ru/",
  },
  {
    country: "RU",
    name: "Novgorod State University",
    url: "http://www.novsu.ac.ru/",
  },
  {
    country: "RU",
    name: "Novosibirsk State Academy of Economics and Mangement",
    url: "http://cit.drbit.com.ru/",
  },
  {
    country: "RU",
    name: "Novosibirsk State Agricultural University",
    url: "http://www.nsau.edu.ru/",
  },
  {
    country: "RU",
    name: "Novosibirsk State Medical Academy",
    url: "http://www.medin.nsc.ru/",
  },
  {
    country: "RU",
    name: "Novosibirsk State Music Academy M. Glinka",
    url: "http://www.conservatoire.ru/",
  },
  {
    country: "RU",
    name: "Novosibirsk State Technical University",
    url: "http://www.nstu.ru/",
  },
  {
    country: "RU",
    name: "Novosibirsk State University",
    url: "http://www.nsu.ru/",
  },
  {
    country: "RU",
    name: "Obninsk State Technical University for Nuclear Power Engineering",
    url: "http://www.iate.obninsk.ru/",
  },
  {
    country: "RU",
    name: "Omsk State Agrarian University",
    url: "http://www.omgau.ru/",
  },
  {
    country: "RU",
    name: "Omsk State Medicical Academy",
    url: "http://www.omsk-osma.ru/",
  },
  {
    country: "RU",
    name: "Omsk State Pedagogical University",
    url: "http://www.omsk.edu/",
  },
  {
    country: "RU",
    name: "Omsk State Technical University",
    url: "http://www.omgtu.ru/",
  },
  {
    country: "RU",
    name: "Omsk State Transport University",
    url: "http://www.omgups.ru/",
  },
  {
    country: "RU",
    name: "Omsk State University",
    url: "http://www.univer.omsk.su/",
  },
  {
    country: "RU",
    name: "Orenburg State University",
    url: "http://www.osu.ru/",
  },
  {
    country: "RU",
    name: "Oriental University",
    url: "http://www.orun.ru/",
  },
  {
    country: "RU",
    name: "Penza State University",
    url: "http://www.pnzgu.ru/",
  },
  {
    country: "RU",
    name: "People' s Friendship University of Russia",
    url: "http://www.pfu.edu.ru/",
  },
  {
    country: "RU",
    name: "Perm State Academy of Agriculture",
    url: "http://www.pstu.ac.ru/",
  },
  {
    country: "RU",
    name: "Perm State Medical Academy",
    url: "http://www.psma.ru/",
  },
  {
    country: "RU",
    name: "Perm State Pedagogical University",
    url: "http://www.pspu.ac.ru/",
  },
  {
    country: "RU",
    name: "Perm State Pharmaceutical Academy",
    url: "http://www.psfa.ru/",
  },
  {
    country: "RU",
    name: "Perm State Technical University",
    url: "http://www.pstu.ac.ru/",
  },
  {
    country: "RU",
    name: "Perm State University",
    url: "http://www.psu.ru/",
  },
  {
    country: "RU",
    name: "Petrozavodsk State University",
    url: "http://www.karelia.ru/psu/",
  },
  {
    country: "RU",
    name: "Pjatigorsk State Linguistic University",
    url: "http://www.pglu.ru/",
  },
  {
    country: "RU",
    name: "Plekhanov Russian Academy of Economics",
    url: "http://www.rea.ru/",
  },
  {
    country: "RU",
    name: "Pomorsk State University",
    url: "http://www.pomorsu.ru/",
  },
  {
    country: "RU",
    name: "Pomor State University",
    url: "http://www.pomorsu.ru/",
  },
  {
    country: "RU",
    name: "Rostov State Medical University",
    url: "http://www.rsmu.da.ru/",
  },
  {
    country: "RU",
    name: "Rostov State University",
    url: "http://www.rsu.ru/",
  },
  {
    country: "RU",
    name: "Russian Academy of Arts",
    url: "http://www.rah.ru/",
  },
  {
    country: "RU",
    name: "Russian Academy of Theatre Arts",
    url: "http://www.gitis.net/",
  },
  {
    country: "RU",
    name: "Russian Customs Academy, Vladivostok Branch",
    url: "http://www.vfrta.ru/",
  },
  {
    country: "RU",
    name: "Russian State Geological Prospecting University",
    url: "http://www.mgri-rggru.ru/",
  },
  {
    country: "RU",
    name: "Russian State Hydrometeorological University",
    url: "http://www.rshu.ru/",
  },
  {
    country: "RU",
    name: "Russian State Medical University",
    url: "http://www.rsmu.ru/",
  },
  {
    country: "RU",
    name: "Russian State University for the Humanities",
    url: "http://www.rsuh.ru/",
  },
  {
    country: "RU",
    name: "Ryazan State Pedagogical University",
    url: "http://www.ttc.ryazan.ru/",
  },
  {
    country: "RU",
    name: "Rybinsk State Academy of Aviational Technology",
    url: "http://www.rgata.yaroslavl.ru/",
  },
  {
    country: "RU",
    name: "Sakhalin State University",
    url: "http://www.sakhgu.sakhalin.ru/",
  },
  {
    country: "RU",
    name: "Samara State Academy for Railway Transportation",
    url: "http://www.samiit.ru/",
  },
  {
    country: "RU",
    name: "Samara State Academy of Architecture and Civil Engineering",
    url: "http://www.ssaba.smr.ru/",
  },
  {
    country: "RU",
    name: "Samara State Aerospace University",
    url: "http://www.ssau.ru/",
  },
  {
    country: "RU",
    name: "Samara State Agricultural Academy",
    url: "http://www.ssaa.ru/",
  },
  {
    country: "RU",
    name: "Samara State Medical University",
    url: "http://www.samsmu.ru/",
  },
  {
    country: "RU",
    name: "Samara State Technical University",
    url: "http://www.sstu.samara.ru/",
  },
  {
    country: "RU",
    name: "Samara State University",
    url: "http://www.ssu.samara.ru/",
  },
  {
    country: "RU",
    name: "Samara State University of Economics",
    url: "http://www.sseu.ru/",
  },
  {
    country: "RU",
    name: "Samara State University of Teacher Training",
    url: "http://www.ssttu.samara.ru/",
  },
  {
    country: "RU",
    name: "Saratov State Academy of Law",
    url: "http://www.sgap.ru/",
  },
  {
    country: "RU",
    name: "Saratov State Agrarian University",
    url: "http://www.sgau.ru/",
  },
  {
    country: "RU",
    name: "Saratov State Medical University",
    url: "http://www.med.sgu.ru/",
  },
  {
    country: "RU",
    name: "Saratov State Socio-Economic University",
    url: "http://www.ssea.runnet.ru/",
  },
  {
    country: "RU",
    name: "Saratov State Technical University",
    url: "http://www.sstu-edu.ru/",
  },
  {
    country: "RU",
    name: "Saratov State University",
    url: "http://www.sgu.ru/",
  },
  {
    country: "RU",
    name: "Siberian Academy of Public Services",
    url: "http://www.sapanet.ru/",
  },
  {
    country: "RU",
    name: "Siberian State Industrial University",
    url: "http://sibsiu.kemerovo.su/",
  },
  {
    country: "RU",
    name: "Siberian State Medical University",
    url: "http://www.ssmu.ru/",
  },
  {
    country: "RU",
    name: "Siberian State Transport University",
    url: "http://www.stu.ru/",
  },
  {
    country: "RU",
    name: "Siberian State University of Telecommunications and Informatics",
    url: "http://www.neic.nsk.su/",
  },
  {
    country: "RU",
    name: "Skrjabin Moscow State Academy of Veterinary Medicine and Biotechnology",
    url: "http://www.mgavm.ru/",
  },
  {
    country: "RU",
    name: "Smolensk Humanitarian University",
    url: "http://shu.smolensk.su/",
  },
  {
    country: "RU",
    name: "Smolensk State Medical Academy",
    url: "http://www.sgma.info/",
  },
  {
    country: "RU",
    name: "Smolny University",
    url: "http://www.smolny-un.spb.ru/",
  },
  {
    country: "RU",
    name: "Southern Ural State University",
    url: "http://www.tu-chel.ac.ru/",
  },
  {
    country: "RU",
    name: "State Maritine Academy",
    url: "http://www.gma.ru/",
  },
  {
    country: "RU",
    name: "State University of Aerospace Technologies",
    url: "http://www.intstudy.mai.ru/",
  },
  {
    country: "RU",
    name: "State University of Management",
    url: "http://www.guu.ru/",
  },
  {
    country: "RU",
    name: "Stavropol State Technical University",
    url: "http://www.ncstu.ru/",
  },
  {
    country: "RU",
    name: "Stavropol State University",
    url: "http://www.stavsu.ru/",
  },
  {
    country: "RU",
    name: "Sterlitamak State Pedagogical Institute",
    url: "http://www.bashedu.ru/vuz/sgpi_e.htm",
  },
  {
    country: "RU",
    name: "St. Petersburg Institute of Arts and Restoration",
    url: "http://www.spbiir.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg Institute of Sales Management and Economics",
    url: "http://www.spbtei.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg Repin State Academic Institute of Painting Sculpture and Achitecture",
    url: "http://artacademy.spb.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State Academy for Engineering Economics (ENGECON)",
    url: "http://www.engec.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State Agrarian University",
    url: "http://www.spbgau.spb.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State Chemical Pharmaceutical Academy",
    url: "http://www.spcpa.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State Cinema and TV University",
    url: "http://www.gukit.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State Conservatory",
    url: "http://www.conservatory.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State Electrotechnical University",
    url: "http://www.eltech.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State Forest-Technical Academy",
    url: "http://www.ftacademy.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State Institute of Technology (Technological University)",
    url: "http://www.gti.spb.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State Marine Technical University",
    url: "http://www.smtu.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg StateMechnikov  Medical Academy",
    url: "http://www.spsmma.com/",
  },
  {
    country: "RU",
    name: "St. Petersburg State Medical Academy",
    url: "http://www.mechnik.spb.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State Mining Institute (Technical University)",
    url: "http://www.spmi.edu.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State Mountain Institut",
    url: "http://www.gorny-ins.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State Pediatric Medical Academy",
    url: "http://www.gpma.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State Polytechnical University",
    url: "http://www.spbstu.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State Technical University",
    url: "http://www.unilib.neva.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State Technological University of Plant Polymers",
    url: "http://www.gturp.spb.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State Theatre Arts Academy",
    url: "http://www.tart.spb.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State University",
    url: "http://www.spbu.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State University of Aerospace Instrumentation",
    url: "http://www.suai.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State University of Architecture and Civil Engineering",
    url: "http://www.spbgasu.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State University of Civil Aviation",
    url: "http://www.academiaga.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State University of Culture and Arts",
    url: "http://www.spbguki.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State University of Economics and Finance",
    url: "http://www.uef.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State University of Engineering and Economics",
    url: "http://www.engec.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State University of Information Technologies, Mechanics and Optics",
    url: "http://www.ifmo.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State University of Technology and Design",
    url: "http://www.sutd.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State University of Telecommunication",
    url: "http://www.sut.ru/",
  },
  {
    country: "RU",
    name: "St. Petersburg State University of Waterway Communications",
    url: "http://www.spbuwc.ru/",
  },
  {
    country: "RU",
    name: "Surgut State University",
    url: "http://www.surgu.wsnet.ru/",
  },
  {
    country: "RU",
    name: "Syktyvkar State University",
    url: "http://ssu.komi.com:8101/",
  },
  {
    country: "RU",
    name: "Taganrog State Pedagogical Institute",
    url: "http://www.tgpi.ttn.ru/",
  },
  {
    country: "RU",
    name: "Taganrog State University of Radioengineering",
    url: "http://www.tsure.ru/",
  },
  {
    country: "RU",
    name: "Tambov State Technical University",
    url: "http://www.tstu.ru/",
  },
  {
    country: "RU",
    name: "Timirjazev Moscow Academy of Agricultutre",
    url: "http://www.timacad.ru/",
  },
  {
    country: "RU",
    name: "Togliatti State University",
    url: "http://www.tltsu.ru/",
  },
  {
    country: "RU",
    name: "Tomsk Polytechnic University",
    url: "http://www.tpu.ru/",
  },
  {
    country: "RU",
    name: "Tomsk State Pedagogical University",
    url: "http://www.tspu.edu.ru/",
  },
  {
    country: "RU",
    name: "Tomsk State University",
    url: "http://www.tsu.ru/",
  },
  {
    country: "RU",
    name: "Tomsk State University of Architecture and Building",
    url: "http://www.tsuab.ru/",
  },
  {
    country: "RU",
    name: "Tomsk State University of Control Systems and Radioelectronics",
    url: "http://www.tusur.ru/",
  },
  {
    country: "RU",
    name: "Tula State University",
    url: "http://www.tsu.tula.ru/",
  },
  {
    country: "RU",
    name: "Tuva State University",
    url: "http://tuvsu.ru/",
  },
  {
    country: "RU",
    name: "Tver State Medical Academy",
    url: "http://www.tsma.ru/",
  },
  {
    country: "RU",
    name: "Tver State Technical University",
    url: "http://www.tstu.tver.ru/",
  },
  {
    country: "RU",
    name: "Tver State University",
    url: "http://university.tversu.ru/",
  },
  {
    country: "RU",
    name: "Tyumen State Agricultural Academy",
    url: "http://www.tgsha.ru/",
  },
  {
    country: "RU",
    name: "Tyumen State University",
    url: "http://www.tsu.tmn.ru/",
  },
  {
    country: "RU",
    name: "Tyumen State University of Gas and Oil",
    url: "http://www.tsogu.ru/",
  },
  {
    country: "RU",
    name: "Udmurt State University",
    url: "http://www.uni.udm.ru/",
  },
  {
    country: "RU",
    name: "Ufa State Aviation Technical University",
    url: "http://www.ugatu.ac.ru/",
  },
  {
    country: "RU",
    name: "Ufa State Institute of Arts",
    url: "http://www.bashedu.ru/vuz/ugii_e.htm",
  },
  {
    country: "RU",
    name: "Ufa State Petroleum Technological University",
    url: "http://www.rusoil.net/",
  },
  {
    country: "RU",
    name: "Ukhta State Technical University",
    url: "http://uii.sever.ru/",
  },
  {
    country: "RU",
    name: "Ulyanovsk Higher School of Civil Aviation",
    url: "http://www.uvauga.ru/",
  },
  {
    country: "RU",
    name: "Ulyanovsk State Agricultural Academy",
    url: "http://www.ugsha.ru/",
  },
  {
    country: "RU",
    name: "Ulyanovsk State Pedagogical University",
    url: "http://www.ulspu.ru/",
  },
  {
    country: "RU",
    name: "Ulyanovsk State Technical University",
    url: "http://www.ulstu.ru/",
  },
  {
    country: "RU",
    name: "Ulyanovsk State University",
    url: "http://www.ulsu.ru/",
  },
  {
    country: "RU",
    name: "Ural Academy of Public Administration",
    url: "http://www.uapa.ru/",
  },
  {
    country: "RU",
    name: "Ural Gorkij State University",
    url: "http://www.usu.ru/",
  },
  {
    country: "RU",
    name: "Ural State Academy of Architecture and Arts",
    url: "http://www.usaaa.ru/",
  },
  {
    country: "RU",
    name: "Ural State Academy of Law",
    url: "http://www.usla.ru/",
  },
  {
    country: "RU",
    name: "Ural State Academy of Mining and Geology",
    url: "http://www.usmga.ru/",
  },
  {
    country: "RU",
    name: "Ural State Academy of Railway Transport",
    url: "http://www.usart.ru/",
  },
  {
    country: "RU",
    name: "Ural State Conservatory",
    url: "http://www.uscon.ru/",
  },
  {
    country: "RU",
    name: "Ural State Forestry Technical Academy",
    url: "http://www.usfea.ru/",
  },
  {
    country: "RU",
    name: "Ural State Technical University",
    url: "http://www.ustu.ru/",
  },
  {
    country: "RU",
    name: "Ural State University of Economics",
    url: "http://www.usue.ru/",
  },
  {
    country: "RU",
    name: "Vaganova Academy of Russian Ballet",
    url: "http://www.kirovballet.com/vaganova.html",
  },
  {
    country: "RU",
    name: "Vladimir State University",
    url: "http://www.vlsu.ru/",
  },
  {
    country: "RU",
    name: "Vladivostock State University of Economics",
    url: "http://www.vvsu.ru/",
  },
  {
    country: "RU",
    name: "Volga State Academy of Water Transport",
    url: "http://www.aqua.sci-nnov.ru/",
  },
  {
    country: "RU",
    name: "Volgograd Medical Academy",
    url: "http://www.avtlg.ru/~vlgmed/",
  },
  {
    country: "RU",
    name: "Volgograd State Academy of Physical Education",
    url: "http://www.vgafk.ru/",
  },
  {
    country: "RU",
    name: "Volgograd State Pedagogical University",
    url: "http://www.vspu.ru/",
  },
  {
    country: "RU",
    name: "Volgograd State Technical University",
    url: "http://www.vstu.ru/",
  },
  {
    country: "RU",
    name: "Volgograd State University",
    url: "http://www.volsu.ru/",
  },
  {
    country: "RU",
    name: "Volgograd State University of Architecture and Civil Engineering (VolgGASU)",
    url: "http://www.vgasa.ru/",
  },
  {
    country: "RU",
    name: "Vologda State Pedagogical University",
    url: "http://www.uni-vologda.ac.ru/",
  },
  {
    country: "RU",
    name: "Voronezh State Academy of Technology",
    url: "http://www.vgta.vrn.ru/",
  },
  {
    country: "RU",
    name: "Voronezh State Agricultural University",
    url: "http://www.vsau.ru/",
  },
  {
    country: "RU",
    name: "Voronezh State Medical Academy",
    url: "http://www.vsma.info/",
  },
  {
    country: "RU",
    name: "Voronezh State Pedagogical University",
    url: "http://www.vspu.ac.ru/",
  },
  {
    country: "RU",
    name: "Voronezh State Technical University",
    url: "http://www.vorstu.ac.ru/",
  },
  {
    country: "RU",
    name: "Voronezh State University",
    url: "http://www.vsu.ru/",
  },
  {
    country: "RU",
    name: "Vyatka State Pedagogical University",
    url: "http://www.vspu.kirov.ru/",
  },
  {
    country: "RU",
    name: "Yakutsk State University",
    url: "http://www.ysu.ru/",
  },
  {
    country: "RU",
    name: "Yaroslavl International University of Business and New Technologies",
    url: "http://www.iubnt.yar.ru/",
  },
  {
    country: "RU",
    name: "Yaroslavl State Medical Academy",
    url: "http://www.yma.ac.ru/",
  },
  {
    country: "RU",
    name: "Yaroslavl State Pedagogical University",
    url: "http://www.yspu.yar.ru/",
  },
  {
    country: "RU",
    name: "Yaroslavl State Technical University",
    url: "http://www.ystu.yar.ru/",
  },
  {
    country: "RU",
    name: "Yaroslavl State Theatre Institute",
    url: "http://www.theatrins-yar.ru/",
  },
  {
    country: "RU",
    name: "Yaroslavl State University",
    url: "http://www.uniyar.ac.ru/",
  },
  {
    country: "RU",
    name: "Yelets State University",
    url: "http://www.elsu.ru/",
  },
  {
    country: "RU",
    name: "Yugra State University",
    url: "http://www.ugrasu.ru/",
  },
  {
    country: "RW",
    name: "Adventist University of Central Africa",
    url: "http://www.auca.ac.rw/",
  },
  {
    country: "RW",
    name: "Higher Institute of Agriculture and Animal Husbandry",
    url: "http://www.isae.ac.rw/",
  },
  {
    country: "RW",
    name: "Institut d'Agriculture, de Technologie et d'Education de Kibungo",
    url: "http://www.inatek.ac.rw/",
  },
  {
    country: "RW",
    name: "Kigali Health Institute",
    url: "http://www.khi.ac.rw/",
  },
  {
    country: "RW",
    name: "Kigali Independent University",
    url: "http://www.ulk.ac.rw/",
  },
  {
    country: "RW",
    name: "Kigali Institute of Education",
    url: "http://www.kie.ac.rw/",
  },
  {
    country: "RW",
    name: "Kigali Institute of Science & Technology",
    url: "http://www.kist.ac.rw/",
  },
  {
    country: "RW",
    name: "National University of Rwanda",
    url: "http://www.nur.ac.rw/",
  },
  {
    country: "RW",
    name: "School of Finance and Banking",
    url: "http://www.sfb.ac.rw/",
  },
  {
    country: "RW",
    name: "Umutara Polytechnic",
    url: "http://www.umutarapolytech.ac.rw/",
  },
  {
    country: "RW",
    name: "Université Catholique de Kabgayi",
    url: "http://www.uck.ac.rw/",
  },
  {
    country: "SA",
    name: "Al-baha University",
    url: "http://www.bu.edu.sa/",
  },
  {
    country: "SA",
    name: "Al-Batterjee Medical College",
    url: "http://www.bmcmedcollege.com/",
  },
  {
    country: "SA",
    name: "Alfaisal University",
    url: "http://www.alfaisal.edu/",
  },
  {
    country: "SA",
    name: "Al-Imam Mohamed Ibn Saud Islamic University",
    url: "http://www.imamu.edu.sa/",
  },
  {
    country: "SA",
    name: "Aljouf University",
    url: "http://www.ju.edu.sa/",
  },
  {
    country: "SA",
    name: "Alkharj University",
    url: "http://www.ku.edu.sa/",
  },
  {
    country: "SA",
    name: "Al-Yamamah College",
    url: "http://www.alyamamah.edu.sa/",
  },
  {
    country: "SA",
    name: "Arab Open University",
    url: "http://www.arabou.org.sa/",
  },
  {
    country: "SA",
    name: "Batterjee Medical College",
    url: "http://www.bmc.edu.sa/",
  },
  {
    country: "SA",
    name: "Buraydah College for Applied Medical Sciences",
    url: "http://www.bpc.edu.sa/",
  },
  {
    country: "SA",
    name: "College of Busniess Admnistration",
    url: "http://www.cba.edu.sa/",
  },
  {
    country: "SA",
    name: "College of Nursing and Allied Health Scinces",
    url: "http://www.ngha.med.sa/",
  },
  {
    country: "SA",
    name: "College of Technology at Abha",
    url: "http://www.act.gotevot.edu.sa/",
  },
  {
    country: "SA",
    name: "College of Technology at Dammam",
    url: "http://www.dct.gotevot.edu.sa/",
  },
  {
    country: "SA",
    name: "College of Technology at Jazan",
    url: "http://www.tnmeyah.net/jct/",
  },
  {
    country: "SA",
    name: "College of Technology at Jeddah",
    url: "http://www.jct.edu.sa/",
  },
  {
    country: "SA",
    name: "College of Technology at Kharj",
    url: "http://kct.gotevot.edu.sa/",
  },
  {
    country: "SA",
    name: "College of Technology at Riyadh",
    url: "http://www.rct.edu.sa/",
  },
  {
    country: "SA",
    name: "College of Telecommunication & Information",
    url: "http://www.cti.edu.sa/",
  },
  {
    country: "SA",
    name: "Dammam Community College",
    url: "http://www.dcc.edu.sa/",
  },
  {
    country: "SA",
    name: "Dar al Hekma College",
    url: "http://www.daralhekma.edu.sa/",
  },
  {
    country: "SA",
    name: "Dar Al-Uloom Colleges",
    url: "http://www.dau.edu.sa/",
  },
  {
    country: "SA",
    name: "Effat College",
    url: "http://www.effatcollege.edu.sa/",
  },
  {
    country: "SA",
    name: "Fahad Bin Sultan University",
    url: "http://www.fbsc.edu.sa/",
  },
  {
    country: "SA",
    name: "Ibn Sina National College for Medical Studies",
    url: "http://www.ibnsina.edu.sa/",
  },
  {
    country: "SA",
    name: "Institute of Public Administration",
    url: "http://www.ipa.edu.sa/",
  },
  {
    country: "SA",
    name: "Islamic University of Medinah",
    url: "http://www.iu.edu.sa/",
  },
  {
    country: "SA",
    name: "Jazan University",
    url: "http://www.jazanu.edu.sa/",
  },
  {
    country: "SA",
    name: "Jubail Industrial College",
    url: "http://www.jic.edu.sa/",
  },
  {
    country: "SA",
    name: "Jubail University College",
    url: "http://www.ucj.edu.sa/",
  },
  {
    country: "SA",
    name: "King Abdul Aziz University",
    url: "http://www.kau.edu.sa/",
  },
  {
    country: "SA",
    name: "King Abdullah University of Science and Technology",
    url: "http://www.kaust.edu.sa/",
  },
  {
    country: "SA",
    name: "King Fahad University of Petroleum and Minerals",
    url: "http://www.kfupm.edu.sa/",
  },
  {
    country: "SA",
    name: "King Fahd Security College",
    url: "http://www.kfsc.edu.sa/",
  },
  {
    country: "SA",
    name: "King Faisal University",
    url: "http://www.kfu.edu.sa/",
  },
  {
    country: "SA",
    name: "King Khaled University",
    url: "http://www.kku.edu.sa/",
  },
  {
    country: "SA",
    name: "King Saud bin Abdulaziz University for Health Sciences",
    url: "http://www.ksau-hs.edu.sa/",
  },
  {
    country: "SA",
    name: "King Saud University",
    url: "http://www.ksu.edu.sa/",
  },
  {
    country: "SA",
    name: "Knowledge College for Science and Technology",
    url: "http://www.mcst.edu.sa/",
  },
  {
    country: "SA",
    name: "Knowledge International University",
    url: "http://www.almaarifah.com/",
  },
  {
    country: "SA",
    name: "Majmaah University",
    url: "http://www.mu.edu.sa/",
  },
  {
    country: "SA",
    name: "Naif Arab University for Security Sciences",
    url: "http://www.nauss.edu.sa/",
  },
  {
    country: "SA",
    name: "Najran University",
    url: "http://www.nu.edu.sa/",
  },
  {
    country: "SA",
    name: "Northern Border University",
    url: "http://www.nbu.edu.sa/",
  },
  {
    country: "SA",
    name: "Prince Mohammad Bin Fahd University",
    url: "http://www.pmu.edu.sa/",
  },
  {
    country: "SA",
    name: "Princess Nora Bint Abdulrahman University",
    url: "http://www.rug.edu.sa/",
  },
  {
    country: "SA",
    name: "Prince Sultan College for Tourism and Hotel Scinces",
    url: "http://www.pscabha.edu.sa/",
  },
  {
    country: "SA",
    name: "Prince Sultan University",
    url: "http://www.psu.edu.sa/",
  },
  {
    country: "SA",
    name: "Private College of Dentistry and Pharmacy",
    url: "http://www.pcdp.edu.sa/",
  },
  {
    country: "SA",
    name: "Qassim Private College",
    url: "http://www.qc.edu.sa/",
  },
  {
    country: "SA",
    name: "Qassim University",
    url: "http://www.qu.edu.sa/",
  },
  {
    country: "SA",
    name: "Riyadh College of Dentistry and Pharmacy",
    url: "http://www.riyadh.edu.sa/",
  },
  {
    country: "SA",
    name: "Saad College of Nursing and Allied Health Sciences",
    url: "http://www.saadcollege.com/",
  },
  {
    country: "SA",
    name: "Saudi Electronic University",
    url: "http://www.seu.edu.sa/",
  },
  {
    country: "SA",
    name: "Shaqra University",
    url: "http://www.su.edu.sa/",
  },
  {
    country: "SA",
    name: "Taibah University",
    url: "http://www.taibahu.edu.sa/",
  },
  {
    country: "SA",
    name: "Taif University",
    url: "http://www.tu.edu.sa/",
  },
  {
    country: "SA",
    name: "Umm Al-Qura University",
    url: "http://www.uqu.edu.sa/",
  },
  {
    country: "SA",
    name: "University of Dammam",
    url: "http://www.ud.edu.sa/",
  },
  {
    country: "SA",
    name: "University of Hail",
    url: "http://www.uoh.edu.sa/",
  },
  {
    country: "SA",
    name: "University of Tabuk",
    url: "http://www.ut.edu.sa/",
  },
  {
    country: "SA",
    name: "Yanbu Industrial College",
    url: "http://www.yic.edu.sa/",
  },
  {
    country: "SA",
    name: "Yanbu Technical Institute",
    url: "http://www.yti.edu.sa/",
  },
  {
    country: "SA",
    name: "Yanbu University College",
    url: "http://www.yuc.edu.sa/",
  },
  {
    country: "SB",
    name: "University of the South Pacific",
    url: "http://www.usp.ac.fj/",
  },
  {
    country: "SC",
    name: "University of Seychelles",
    url: "http://www.unisey.ac.sc/",
  },
  {
    country: "SC",
    name: "University of Seychelles - American Institute of Medicine",
    url: "http://www.usaim.edu/",
  },
  {
    country: "SD",
    name: "Ahfad University for Women",
    url: "http://www.ahfad.edu.sd/",
  },
  {
    country: "SD",
    name: "Al Fashir University",
    url: "http://www.fashir.edu.sd/",
  },
  {
    country: "SD",
    name: "Al-Zaiem Al-Azhari University",
    url: "http://www.aau.edu.sd/",
  },
  {
    country: "SD",
    name: "Bakht Er-Ruda University",
    url: "http://www.bakhtalruda.edu.sd/",
  },
  {
    country: "SD",
    name: "Bayan College for Science & Technology",
    url: "http://www.bayantech.edu/",
  },
  {
    country: "SD",
    name: "Blue Nile University",
    url: "http://www.bnu.edu.sd/",
  },
  {
    country: "SD",
    name: "Canadian Sudanese College",
    url: "http://www.ccs.edu.sd/",
  },
  {
    country: "SD",
    name: "Dalanj University",
    url: "http://www.dalanjuniversity.edu.sd/",
  },
  {
    country: "SD",
    name: "Elrazi College Of Medical & Technological Sciences",
    url: "http://www.elrazicollege.net/",
  },
  {
    country: "SD",
    name: "Gezira College of Technology",
    url: "http://www.geziracollege.edu.sd/",
  },
  {
    country: "SD",
    name: "High Institute for Banking & Financial Studies",
    url: "http://www.hibfs.edu.sd/",
  },
  {
    country: "SD",
    name: "International University of Africa (IUA)",
    url: "http://www.iua.edu.sd/",
  },
  {
    country: "SD",
    name: "Kassala University",
    url: "http://www.kassalauni.edu.sd/",
  },
  {
    country: "SD",
    name: "National College for Medical and Technical Studies",
    url: "http://www.nc.edu.sd/",
  },
  {
    country: "SD",
    name: "National Ribat University",
    url: "http://www.ribat.edu.sd/",
  },
  {
    country: "SD",
    name: "Neelain University",
    url: "http://www.neelain.edu.sd/",
  },
  {
    country: "SD",
    name: "Nile Valley University",
    url: "http://www.nilevalley.edu.sd/",
  },
  {
    country: "SD",
    name: "Omdurman Ahlia University",
    url: "http://www.oau.edu.sd/",
  },
  {
    country: "SD",
    name: "Omdurman Islamic University",
    url: "http://www.oiu.edu.sd/",
  },
  {
    country: "SD",
    name: "Open University of Sudan (OUS)",
    url: "http://www.ous.edu.sd/",
  },
  {
    country: "SD",
    name: "Red Sea University",
    url: "http://www.rsu.edu.sd/",
  },
  {
    country: "SD",
    name: "Shendi University",
    url: "http://www.ush.sd/",
  },
  {
    country: "SD",
    name: "Sinnar University",
    url: "http://www.sinnaruniv.edu.sd/",
  },
  {
    country: "SD",
    name: "Sudan Academy of Sciences",
    url: "http://www.sas-sd.net/",
  },
  {
    country: "SD",
    name: "Sudan University for Science and Technology",
    url: "http://www.sustech.edu/",
  },
  {
    country: "SD",
    name: "University of Dongola",
    url: "http://www.uofd.edu.sd/",
  },
  {
    country: "SD",
    name: "University of El Imam El Mahdi University",
    url: "http://www.elmahdi.edu.sd/",
  },
  {
    country: "SD",
    name: "University of Gadarif",
    url: "http://www.gaduniv.edu.sd/",
  },
  {
    country: "SD",
    name: "University of Gezira",
    url: "http://www.uofg.edu.sd/",
  },
  {
    country: "SD",
    name: "University of Holy Quran and Islamic Siences",
    url: "http://www.quran-unv.edu.sd/",
  },
  {
    country: "SD",
    name: "University of Khartoum",
    url: "http://www.uofk.edu/",
  },
  {
    country: "SD",
    name: "University of Kordofan",
    url: "http://www.kordofan.edu.sd/",
  },
  {
    country: "SD",
    name: "University Of Medical Sciences & Technology (UMST)",
    url: "http://www.umst-edu.com/",
  },
  {
    country: "SD",
    name: "University of Science and Technology",
    url: "http://www.ust.edu.sd/",
  },
  {
    country: "SD",
    name: "Upper Nile University",
    url: "http://www.unu.edu.sd/",
  },
  {
    country: "SE",
    name: "Blekinge Institute of Technology",
    url: "http://www.bth.se/",
  },
  {
    country: "SE",
    name: "Chalmers University of Technology",
    url: "http://www.chalmers.se/",
  },
  {
    country: "SE",
    name: "Dalarna University College",
    url: "http://www.du.se/",
  },
  {
    country: "SE",
    name: "Free University Stockholm",
    url: "http://www.stockholm-fu.com/",
  },
  {
    country: "SE",
    name: "Göteborg University",
    url: "http://www.gu.se/",
  },
  {
    country: "SE",
    name: "Gotland University College",
    url: "http://www.hgo.se/",
  },
  {
    country: "SE",
    name: "Halmstad University College",
    url: "http://www.hh.se/",
  },
  {
    country: "SE",
    name: "Jönköping University College",
    url: "http://www.hj.se/",
  },
  {
    country: "SE",
    name: "Jönköping University College of Health Sciences",
    url: "http://www.hj.se/hhj/",
  },
  {
    country: "SE",
    name: "Kalmar University College",
    url: "http://www.hik.se/",
  },
  {
    country: "SE",
    name: "Karlstad University",
    url: "http://www.kau.se/",
  },
  {
    country: "SE",
    name: "Karolinska Institute Stockholm",
    url: "http://www.ki.se/",
  },
  {
    country: "SE",
    name: "Linköping University",
    url: "http://www.liu.se/",
  },
  {
    country: "SE",
    name: "Lulea University of Technology",
    url: "http://www.luth.se/",
  },
  {
    country: "SE",
    name: "Lund University",
    url: "http://www.lu.se/",
  },
  {
    country: "SE",
    name: "Mälardalen University",
    url: "http://www.mdh.se/",
  },
  {
    country: "SE",
    name: "Malmö University College",
    url: "http://www.mah.se/",
  },
  {
    country: "SE",
    name: "Mid-Sweden University",
    url: "http://www.miun.se/",
  },
  {
    country: "SE",
    name: "Nordic School of Public Health",
    url: "http://www.nhv.se/",
  },
  {
    country: "SE",
    name: "Örebro University",
    url: "http://www.oru.se/",
  },
  {
    country: "SE",
    name: "Royal lnstitute of Technology",
    url: "http://www.kth.se/",
  },
  {
    country: "SE",
    name: "School of Design and Crafts",
    url: "http://www.hdk.gu.se/",
  },
  {
    country: "SE",
    name: "Södertörn University College",
    url: "http://www.sh.se/",
  },
  {
    country: "SE",
    name: "Stockholm Institute of Education",
    url: "http://www.lhs.se/",
  },
  {
    country: "SE",
    name: "Stockholm School of Economics",
    url: "http://www.hhs.se/",
  },
  {
    country: "SE",
    name: "Stockholm University",
    url: "http://www.su.se/",
  },
  {
    country: "SE",
    name: "Swedish University of Agricultural Sciences",
    url: "http://www.slu.se/",
  },
  {
    country: "SE",
    name: "Umea University",
    url: "http://www.umu.se/",
  },
  {
    country: "SE",
    name: "University College of Arts, Crafts and Design",
    url: "http://www.konstfack.se/",
  },
  {
    country: "SE",
    name: "University College of Borås",
    url: "http://www.hb.se/",
  },
  {
    country: "SE",
    name: "University College of Gävle",
    url: "http://www.hig.se/",
  },
  {
    country: "SE",
    name: "University College of Kristianstad",
    url: "http://www.hkr.se/",
  },
  {
    country: "SE",
    name: "University College of Skövde",
    url: "http://www.his.se/",
  },
  {
    country: "SE",
    name: "University College of Trollhättan/Uddevalla",
    url: "http://www.htu.se/",
  },
  {
    country: "SE",
    name: "Uppsala University",
    url: "http://www.uu.se/",
  },
  {
    country: "SE",
    name: "Växjö University",
    url: "http://www.vxu.se/",
  },
  {
    country: "SE",
    name: "World Maritime University",
    url: "http://www.wmu.se/",
  },
  {
    country: "SG",
    name: "Aventis School of Management",
    url: "http://www.aventis.edu.sg/",
  },
  {
    country: "SG",
    name: "Nanyang Technological University",
    url: "http://www.ntu.edu.sg/",
  },
  {
    country: "SG",
    name: "National University of Singapore",
    url: "http://www.nus.edu.sg/",
  },
  {
    country: "SG",
    name: "Singapore Institute of Management (SIM)",
    url: "http://www.sim.edu.sg/",
  },
  {
    country: "SG",
    name: "Singapore Management University",
    url: "http://www.smu.edu.sg/",
  },
  {
    country: "SG",
    name: "Taoist College Singapore",
    url: "http://www.taoistcollege.org.sg/",
  },
  {
    country: "SI",
    name: "University of Ljubljana",
    url: "http://www.uni-lj.si/",
  },
  {
    country: "SI",
    name: "University of Maribor",
    url: "http://www.uni-mb.si/",
  },
  {
    country: "SI",
    name: "University of Nova Gorica",
    url: "http://www.ung.si/",
  },
  {
    country: "SI",
    name: "University of Primorska",
    url: "http://www.upr.si/",
  },
  {
    country: "SK",
    name: "Academy of Arts in Banská Bystrica",
    url: "http://www.aku.sk/",
  },
  {
    country: "SK",
    name: "Academy of Fine Arts and Design in Bratislava",
    url: "http://www.vsvu.sk/",
  },
  {
    country: "SK",
    name: "Armed Forces Academy of General Milan Rastislav Štefánik",
    url: "http://www.aos.sk/",
  },
  {
    country: "SK",
    name: "Bratislava International School of Liberal Arts",
    url: "http://www.bisla.sk/",
  },
  {
    country: "SK",
    name: "Catholic University in Ruzomberok",
    url: "http://www.ku.sk/",
  },
  {
    country: "SK",
    name: "Catholic University in Ružomberok",
    url: "http://www.ku.sk/",
  },
  {
    country: "SK",
    name: "College in Sládkovičovo",
    url: "http://www.vssladkovicovo.sk/",
  },
  {
    country: "SK",
    name: "Comenius University in Bratislava",
    url: "http://www.uniba.sk/",
  },
  {
    country: "SK",
    name: "International School of Management in Prešov",
    url: "http://www.ismpo.sk/",
  },
  {
    country: "SK",
    name: "Janos Selye University",
    url: "http://www.selyeuni.sk/",
  },
  {
    country: "SK",
    name: "Law University of Bratislava",
    url: "http://www.uninova.sk/",
  },
  {
    country: "SK",
    name: "Matej Bel University in Banská Bystrica",
    url: "http://www.umb.sk/",
  },
  {
    country: "SK",
    name: "Pavol Jozef Safarik University in Kosice",
    url: "http://www.upjs.sk/",
  },
  {
    country: "SK",
    name: "Police Academy in Bratislava",
    url: "http://www.akademiapz.sk/",
  },
  {
    country: "SK",
    name: "School of Management",
    url: "http://www.vsm.sk/",
  },
  {
    country: "SK",
    name: "Slovak Medical University",
    url: "http:///www.szu.sk/",
  },
  {
    country: "SK",
    name: "Slovak University of Agriculture in Nitra",
    url: "http://www.uniag.sk/",
  },
  {
    country: "SK",
    name: "Slovak University of Technology in Bratislava",
    url: "http://www.stuba.sk/",
  },
  {
    country: "SK",
    name: "St. Elizabeth’s College of Health and Social Sciences in Bratislava",
    url: "http://www.vssvalzbety.sk/",
  },
  {
    country: "SK",
    name: "Technical Institute of Dubnica in Dubnica nad Váhom",
    url: "http://www.dti.sk/",
  },
  {
    country: "SK",
    name: "Technical University in Kosice",
    url: "http://www.tuke.sk/",
  },
  {
    country: "SK",
    name: "Technical University in Zvolen",
    url: "http://www.tuzvo.sk/",
  },
  {
    country: "SK",
    name: "University Konstantina Filozov in Nitra",
    url: "http://www.ukf.sk/",
  },
  {
    country: "SK",
    name: "University of Alexandra Dubček in Trenčín",
    url: "http://www.tnuni.sk/",
  },
  {
    country: "SK",
    name: "University of Central Europe in Skalica",
    url: "http://www.sevs.sk/",
  },
  {
    country: "SK",
    name: "University of Economics in Bratislava",
    url: "http://www.euba.sk/",
  },
  {
    country: "SK",
    name: "University of Performing Arts in Bratislava",
    url: "http://www.vsmu.sk/",
  },
  {
    country: "SK",
    name: "University of Presov",
    url: "http://www.unipo.sk/",
  },
  {
    country: "SK",
    name: "University of St. Cyril and Methodius in Trnava",
    url: "http://www.ucm.sk/",
  },
  {
    country: "SK",
    name: "University of SV Cyril and Methodius in Trnava",
    url: "http://www.ucm.sk/",
  },
  {
    country: "SK",
    name: "University of Trnava",
    url: "http://www.truni.sk/",
  },
  {
    country: "SK",
    name: "University of Veterinary Medicine in Kosice",
    url: "http://www.uvm.sk/",
  },
  {
    country: "SK",
    name: "University of Zilinska",
    url: "http://www.uniza.sk/",
  },
  {
    country: "SL",
    name: "Fourah Bay College, University of Sierra Leone",
    url: "http://fbcusl.8k.com/",
  },
  {
    country: "SL",
    name: "LICCSAL Business College",
    url: "http://www.liccsalsl.org/",
  },
  {
    country: "SL",
    name: "Njala University",
    url: "http://www.nu-online.com/",
  },
  {
    country: "SL",
    name: "University of Sierra Leone",
    url: "http://www.tusol.org/",
  },
  {
    country: "SM",
    name: "University of San Marino",
    url: "http://www.unirsm.sm/",
  },
  {
    country: "SN",
    name: "L'Université de l'Entreprise",
    url: "http://www.afi-ue.sn/",
  },
  {
    country: "SN",
    name: "Université Alioune Diop de Bambey",
    url: "http://www.bambey.univ.sn/",
  },
  {
    country: "SN",
    name: "Universite Amadou Hampaté Ba",
    url: "http://www.uahb.sn/",
  },
  {
    country: "SN",
    name: "Université Cheikh Anta Diop",
    url: "http://www.ucad.sn/",
  },
  {
    country: "SN",
    name: "Université Dakar Bourguiba",
    url: "http://www.udb.sn/",
  },
  {
    country: "SN",
    name: "Université de Thiès",
    url: "http://www.univ-thies.sn/",
  },
  {
    country: "SN",
    name: "Université de Ziguinchor",
    url: "http://www.univ-zig.sn/",
  },
  {
    country: "SN",
    name: "Université du Sahel",
    url: "http://www.unis.sn/",
  },
  {
    country: "SN",
    name: "Université Euro-Afrique",
    url: "http://www.uea.edu.sn/",
  },
  {
    country: "SN",
    name: "Université Gaston Berger Saint-Louis",
    url: "http://www.ugb.sn/",
  },
  {
    country: "SO",
    name: "Amoud University",
    url: "http://www.amouduniversity.org/",
  },
  {
    country: "SO",
    name: "Benadir University",
    url: "http://www.benadiruniversity.net/",
  },
  {
    country: "SO",
    name: "Bosaso College",
    url: "http://www.bosasocollege.com/",
  },
  {
    country: "SO",
    name: "East Africa University Bosaso",
    url: "http://www.eastafricauniversity.net/",
  },
  {
    country: "SO",
    name: "East Somalia University",
    url: "http://www.eastsomaliauniversity.com/",
  },
  {
    country: "SO",
    name: "Eelo American University",
    url: "http://www.eelouniversity.org/",
  },
  {
    country: "SO",
    name: "Gollis University",
    url: "http://www.gollisuniversity.com/",
  },
  {
    country: "SO",
    name: "Hiiraan University",
    url: "http://www.hiiraanuniversity.info/",
  },
  {
    country: "SO",
    name: "Maakhir University",
    url: "http://maakhiruniversity.net/",
  },
  {
    country: "SO",
    name: "Mogadishu University",
    url: "http://www.mogadishuuniversity.com/",
  },
  {
    country: "SO",
    name: "Nugaal University",
    url: "http://www.nugaaluniversity.com/",
  },
  {
    country: "SO",
    name: "Puntland State University",
    url: "http://www.puntlandstateuniversity.com/",
  },
  {
    country: "SO",
    name: "Sanaag University of Science and Technology",
    url: "http://www.sanaaguniversity.com/",
  },
  {
    country: "SO",
    name: "Somaliland University of Technology",
    url: "http://www.somtech.org/",
  },
  {
    country: "SO",
    name: "University of Burao",
    url: "http://www.buraouniversity.com/",
  },
  {
    country: "SO",
    name: "University of Hargeisa",
    url: "http://www.hargeisauniversity.net/",
  },
  {
    country: "SO",
    name: "University of Somalia",
    url: "http://www.universityofsomalia.net/",
  },
  {
    country: "SR",
    name: "Anton de Kom University",
    url: "http://www.uvs.edu/",
  },
  {
    country: "SS",
    name: "University of Juba",
    url: "http://www.juba.edu.sd/",
  },
  {
    country: "SS",
    name: "University of Northern Bahr El-Ghazal",
    url: "http://www.unbeg.edu.sd/",
  },
  {
    country: "SV",
    name: "Universidad Albert Einstein",
    url: "http://www.uae.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad Autónoma de Santa Ana",
    url: "http://www.unasa.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad Capitain General Gerardo Barrios",
    url: "http://www.ugb.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad Católica de Occidente",
    url: "http://www.unico.edu.sv/",
  },
  {
    country: "SV",
    name: 'Universidad Centroamericana "José Simeón Canas',
    url: "http://www.uca.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad Cristiana de las Asambleas de Dios",
    url: "http://www.ucad.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad de El Salvador",
    url: "http://www.ues.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad de Oriente",
    url: "http://www.univo.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad de Sonsonate",
    url: "http://www.usonsonate.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad Don Bosco",
    url: "http://www.cdb.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad Dr. Andreas Bello",
    url: "http://www.unab.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad Dr. Jose Matias Delgado",
    url: "http://www.ujmd.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad Evangélica de El Salvador",
    url: "http://www.uees.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad Francisco Gavidia",
    url: "http://www.ufg.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad Isaac Newton",
    url: "http://www.usin.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad Luterana Salvadorena",
    url: "http://www.netcomsa.com/uls/",
  },
  {
    country: "SV",
    name: "Universidad Modular Abierta",
    url: "http://www.uma.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad Monsenor Oscar Arnulfo Romeo",
    url: "http://www.umoar.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad Nueva San Salvador",
    url: "http://www.unssa.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad Panamericana de San Salvador",
    url: "http://www.upan.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad Pedagógica de El Salvador",
    url: "http://www.universidadpedagogica.com/",
  },
  {
    country: "SV",
    name: "Universidad Politécnica de El Salvador",
    url: "http://www.upes.edu.sv/",
  },
  {
    country: "SV",
    name: 'Universidad Salvadorena "Alberto Masferrer',
    url: "http://www.usam.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad Técnica Latinoamericana",
    url: "http://www.utla.edu.sv/",
  },
  {
    country: "SV",
    name: "Universidad Tecnológica de El Salvador",
    url: "http://www.utec.edu.sv/",
  },
  {
    country: "SY",
    name: "Al-Baath University",
    url: "http://www.albaath-univ.edu.sy/",
  },
  {
    country: "SY",
    name: "Al-Wataniya Private University",
    url: "http://www.wpu.edu.sy/",
  },
  {
    country: "SY",
    name: "Arab European University",
    url: "http://www.aiu.edu.sy/",
  },
  {
    country: "SY",
    name: "Damascus University",
    url: "http://www.damascusuniversity.edu.sy/",
  },
  {
    country: "SY",
    name: "Higher Institute of Business Administration",
    url: "http://www.hiba.edu.sy/",
  },
  {
    country: "SY",
    name: "International University for Science & Technology",
    url: "http://www.iust.edu.sy/",
  },
  {
    country: "SY",
    name: "Ittihad Private University",
    url: "http://www.ipu.edu.sy/",
  },
  {
    country: "SY",
    name: "Mamoun Private University for Science and Technology",
    url: "http://www.must.edu.sy/",
  },
  {
    country: "SY",
    name: "Syrian International Private University for Science & Technology",
    url: "http://www.siust.edu.sy/",
  },
  {
    country: "SY",
    name: "Syrian Virtual University",
    url: "http://www.svuonline.org/",
  },
  {
    country: "SY",
    name: "Tishreen University",
    url: "http://www.tishreen.edu.sy/",
  },
  {
    country: "SY",
    name: "University of Aleppo",
    url: "http://www.alepuniv.edu.sy/",
  },
  {
    country: "SY",
    name: "University of Kalamoon",
    url: "http://www.uok.edu.sy/",
  },
  {
    country: "SY",
    name: "Wadi International University",
    url: "http://www.wiu.edu.sy/",
  },
  {
    country: "SY",
    name: "Yarmouk Private University",
    url: "http://www.ypu.edu.sy/",
  },
  {
    country: "SZ",
    name: "University of Swaziland",
    url: "http://www.uniswa.sz/",
  },
  {
    country: "TC",
    name: "Charisma University",
    url: "http://www.charismauniversity.org/",
  },
  {
    country: "TD",
    name: "Université de N'Djamena",
    url: "http://www.univ-ndjamena.org/",
  },
  {
    country: "TG",
    name: "Université de Lomé",
    url: "http://www.univ-lome.tg/",
  },
  {
    country: "TH",
    name: "Asian Institute of Technology",
    url: "http://www.ait.ac.th/",
  },
  {
    country: "TH",
    name: "Asian University of Science and Technology",
    url: "http://www.asianust.ac.th/",
  },
  {
    country: "TH",
    name: "Assumption University of Thailand",
    url: "http://www.au.edu/",
  },
  {
    country: "TH",
    name: "Bangkok University",
    url: "http://www.bu.ac.th/",
  },
  {
    country: "TH",
    name: "Burapha University",
    url: "http://www.buu.ac.th/",
  },
  {
    country: "TH",
    name: "Chaopraya University",
    url: "http://www.cpu.ac.th/",
  },
  {
    country: "TH",
    name: "Chiang Mai Rajabhat University",
    url: "http://www.cmru.ac.th/",
  },
  {
    country: "TH",
    name: "Chiang Mai University",
    url: "http://www.chiangmai.ac.th/",
  },
  {
    country: "TH",
    name: "Chiang Mai Vocational College",
    url: "http://www.cmvc.ac.th/",
  },
  {
    country: "TH",
    name: "Chulalongkorn University",
    url: "http://www.chula.ac.th/",
  },
  {
    country: "TH",
    name: "Dhurakijpundit University",
    url: "http://www.dpu.ac.th/",
  },
  {
    country: "TH",
    name: "Eastern Asia University",
    url: "http://www.eau.ac.th/",
  },
  {
    country: "TH",
    name: "Engineering Faculty of Asian University",
    url: "http://www.efau.org/",
  },
  {
    country: "TH",
    name: "Hatyai University",
    url: "http://www.hu.ac.th/",
  },
  {
    country: "TH",
    name: "Huachiew Chalermprakiet University",
    url: "http://www.hcu.ac.th/",
  },
  {
    country: "TH",
    name: "International Buddhist College",
    url: "http://www.ibc.ac.th/",
  },
  {
    country: "TH",
    name: "Kasem Bundit University",
    url: "http://www.kbu.ac.th/",
  },
  {
    country: "TH",
    name: "Kasetsart University",
    url: "http://www.ku.ac.th/",
  },
  {
    country: "TH",
    name: "Khon Kaen University",
    url: "http://www.kku.ac.th/",
  },
  {
    country: "TH",
    name: "King Mongkut's University of Technology Ladkrabang",
    url: "http://www.kmitl.ac.th/",
  },
  {
    country: "TH",
    name: "King Mongkut's University of Technology North Bangkok",
    url: "http://www.kmutnb.ac.th/",
  },
  {
    country: "TH",
    name: "King Mongkut's University of Technology Thonburi",
    url: "http://www.kmutt.ac.th/",
  },
  {
    country: "TH",
    name: "Krirk University",
    url: "http://www.krirk.ac.th/",
  },
  {
    country: "TH",
    name: "Lampang College of Commerce and Technology",
    url: "http://www.lcct.ac.th/",
  },
  {
    country: "TH",
    name: "Lampang Rajabhat University",
    url: "http://www.lpru.ac.th/",
  },
  {
    country: "TH",
    name: "Lampang Vocational College",
    url: "http://www.lampangvc.ac.th/",
  },
  {
    country: "TH",
    name: "Lamphun College of Agriculture and Technology",
    url: "http://www.lcat.ac.th/",
  },
  {
    country: "TH",
    name: "Mae Fah Luang University",
    url: "http://www.mfu.ac.th/",
  },
  {
    country: "TH",
    name: "Maejo University",
    url: "http://www.mju.ac.th/",
  },
  {
    country: "TH",
    name: "Mahachulalongkorn Buddhist University",
    url: "http://www.mcu.ac.th/",
  },
  {
    country: "TH",
    name: "Mahamakut Buddhist University",
    url: "http://www.mbu.ac.th/",
  },
  {
    country: "TH",
    name: "Mahanakorn University of Technology",
    url: "http://www.mut.ac.th/",
  },
  {
    country: "TH",
    name: "Mahasarakham University",
    url: "http://www.msu.ac.th/",
  },
  {
    country: "TH",
    name: "Mahidol University",
    url: "http://www.mahidol.ac.th/",
  },
  {
    country: "TH",
    name: "Mahidol University International College",
    url: "http://www.muic.mahidol.ac.th/",
  },
  {
    country: "TH",
    name: "Naresuan University",
    url: "http://www.nu.ac.th/",
  },
  {
    country: "TH",
    name: "National Institute of Development Administration",
    url: "http://www.nida.ac.th/",
  },
  {
    country: "TH",
    name: "Nivadhana University",
    url: "http://www.nivadhana.ac.th/",
  },
  {
    country: "TH",
    name: "Pathumthani University",
    url: "http://www.ptu.ac.th/",
  },
  {
    country: "TH",
    name: "Payap University Chaiang Mai",
    url: "http://www.payap.ac.th/",
  },
  {
    country: "TH",
    name: "Phranakhon Si Ayutthaya Rajabhat University",
    url: "http://www.aru.ac.th/",
  },
  {
    country: "TH",
    name: "Prince of Songkla University",
    url: "http://www.psu.ac.th/",
  },
  {
    country: "TH",
    name: "Rajamangala University of Technology, Lanna Chiang Rai",
    url: "http://chiangraicampus.rmutl.ac.th/",
  },
  {
    country: "TH",
    name: "Rajamangala University of Technology, Lanna Nan",
    url: "http://www.nan.rmutl.ac.th/",
  },
  {
    country: "TH",
    name: "Rajamangala University of Technology, Phra Nakhon",
    url: "http://www.rmutp.ac.th/",
  },
  {
    country: "TH",
    name: "Ramkhamhaeng University",
    url: "http://www.ru.ac.th/",
  },
  {
    country: "TH",
    name: "Rangsit University",
    url: "http://www.rsu.ac.th/",
  },
  {
    country: "TH",
    name: "Shinawatra University",
    url: "http://www.shinawatra.ac.th/",
  },
  {
    country: "TH",
    name: "Siam Technology College",
    url: "http://www.siamtechu.net/",
  },
  {
    country: "TH",
    name: "Siam University",
    url: "http://www.siam.edu/",
  },
  {
    country: "TH",
    name: "Silpakorn University",
    url: "http://www.su.ac.th/",
  },
  {
    country: "TH",
    name: "South-East Asia University",
    url: "http://www.sau.ac.th/",
  },
  {
    country: "TH",
    name: "Srinakharinwirot University",
    url: "http://www.swu.ac.th/",
  },
  {
    country: "TH",
    name: "Sripatum University",
    url: "http://www.spu.ac.th/",
  },
  {
    country: "TH",
    name: "Stamford International University",
    url: "http://www.stamford.edu/",
  },
  {
    country: "TH",
    name: "St. John's University",
    url: "http://www.stjohn.ac.th/",
  },
  {
    country: "TH",
    name: "Suan Dusit Rajabhat University",
    url: "http://www.dusit.ac.th/",
  },
  {
    country: "TH",
    name: "Sukhothai Thammathirat Open University",
    url: "http://www.stou.ac.th/",
  },
  {
    country: "TH",
    name: "Suranaree University of Technology",
    url: "http://www.sut.ac.th/",
  },
  {
    country: "TH",
    name: "Thaksin University",
    url: "http://www.tsu.ac.th/",
  },
  {
    country: "TH",
    name: "Thammasat University",
    url: "http://www.tu.ac.th/",
  },
  {
    country: "TH",
    name: "Ubonratchathani University",
    url: "http://www.ubu.ac.th/",
  },
  {
    country: "TH",
    name: "University of the Thai Chamber of Commerce",
    url: "http://www.utcc.ac.th/",
  },
  {
    country: "TH",
    name: "Vongchavalitkul University",
    url: "http://www.vu.ac.th/",
  },
  {
    country: "TH",
    name: "Walailak University",
    url: "http://www.wu.ac.th/",
  },
  {
    country: "TH",
    name: "Webster University, Thailand",
    url: "http://www.webster.ac.th/",
  },
  {
    country: "TH",
    name: "Yonok University",
    url: "http://www.yonok.ac.th/",
  },
  {
    country: "TJ",
    name: "Tajik Agrarian University",
    url: "http://www.tajagroun.tj/",
  },
  {
    country: "TJ",
    name: "Tajikistan International University",
    url: "http://www.tiu.tj/",
  },
  {
    country: "TJ",
    name: "Tajik National University",
    url: "http://tgnu.tarena.tj/",
  },
  {
    country: "TM",
    name: "International Turkmen Turkish University",
    url: "http://www.ittu.edu.tm/",
  },
  {
    country: "TN",
    name: "Instituts Supérieurs de Formation des Maîtres",
    url: "http://www.disfm.rnu.tn/",
  },
  {
    country: "TN",
    name: "Instituts Supérieurs des Etudes Technologiques",
    url: "http://www.isetr.rnu.tn/",
  },
  {
    country: "TN",
    name: "Institut Supérieur de Gestion de Tunis",
    url: "http://www.isg.rnu.tn/",
  },
  {
    country: "TN",
    name: "Université Centrale",
    url: "http://www.ucaat.com/",
  },
  {
    country: "TN",
    name: "Université de 7 Novembre à Carthage",
    url: "http://u7nc.uvt.rnu.tn/",
  },
  {
    country: "TN",
    name: "Université de Gabès",
    url: "http://www.univgb.rnu.tn/",
  },
  {
    country: "TN",
    name: "Université de Gafsa",
    url: "http://www.ugaf.rnu.tn/",
  },
  {
    country: "TN",
    name: "Université de Jendouba",
    url: "http://www.uj.rnu.tn/",
  },
  {
    country: "TN",
    name: "Université de Kairouan",
    url: "http://www.univ-k.rnu.tn/",
  },
  {
    country: "TN",
    name: "Université de la Manouba",
    url: "http://www.uma.rnu.tn/",
  },
  {
    country: "TN",
    name: "Université de Monastir",
    url: "http://www.um.rnu.tn/",
  },
  {
    country: "TN",
    name: "Université de Sfax",
    url: "http://www.uss.rnu.tn/",
  },
  {
    country: "TN",
    name: "Université de Tunis",
    url: "http://www.utunis.rnu.tn/",
  },
  {
    country: "TN",
    name: "Université de Tunis El Manar",
    url: "http://www.utm.rnu.tn/",
  },
  {
    country: "TN",
    name: "Université du Centre, Sousse",
    url: "http://www.uc.rnu.tn/",
  },
  {
    country: "TN",
    name: "Université Ez-Zitouna",
    url: "http://www.uz.rnu.tn/",
  },
  {
    country: "TN",
    name: "Université Libre de Tunis",
    url: "http://www.ult.ens.tn/",
  },
  {
    country: "TN",
    name: "Université Virtuelle de Tunis",
    url: "http://www.uvt.rnu.tn/",
  },
  {
    country: "TN",
    name: "University of Aviation and Technology",
    url: "http://www.unat.ens.tn/",
  },
  {
    country: "TO",
    name: "University of the South Pacific Centre",
    url: "http://www.usp.ac.fj/",
  },
  {
    country: "TR",
    name: "Abant Izzet Baysal University",
    url: "http://www.ibu.edu.tr/",
  },
  {
    country: "TR",
    name: "Adnan Menderes University",
    url: "http://www.adu.edu.tr/",
  },
  {
    country: "TR",
    name: "Afyon Kocatepe University",
    url: "http://www.aku.edu.tr/",
  },
  {
    country: "TR",
    name: "Ahi Evran University",
    url: "http://www.ahievran.edu.tr/",
  },
  {
    country: "TR",
    name: "Akdeniz University",
    url: "http://www.akdeniz.edu.tr/",
  },
  {
    country: "TR",
    name: "Anadolu University",
    url: "http://www.anadolu.edu.tr/",
  },
  {
    country: "TR",
    name: "Ankara University",
    url: "http://www.ankara.edu.tr/",
  },
  {
    country: "TR",
    name: "Atatürk University",
    url: "http://www.atauni.edu.tr/",
  },
  {
    country: "TR",
    name: "Atilim University",
    url: "http://www.atilim.edu.tr/",
  },
  {
    country: "TR",
    name: "Bahcesehir University",
    url: "http://www.bahcesehir.edu.tr/",
  },
  {
    country: "TR",
    name: "Balikesir University",
    url: "http://www.balikesir.edu.tr/",
  },
  {
    country: "TR",
    name: "Baskent University",
    url: "http://www.baskent.edu.tr/",
  },
  {
    country: "TR",
    name: "Beykent University",
    url: "http://www.beykent.edu.tr/",
  },
  {
    country: "TR",
    name: "Bilecik University",
    url: "http://www.bilecik.edu.tr/",
  },
  {
    country: "TR",
    name: "Bilkent University",
    url: "http://www.bilkent.edu.tr/",
  },
  {
    country: "TR",
    name: "Bogazici University",
    url: "http://www.boun.edu.tr/",
  },
  {
    country: "TR",
    name: "Cag University",
    url: "http://www.cag.edu.tr/",
  },
  {
    country: "TR",
    name: "Canakkale (18th March) University",
    url: "http://www.comu.edu.tr/",
  },
  {
    country: "TR",
    name: "Cankaya University",
    url: "http://www.cankaya.edu.tr/",
  },
  {
    country: "TR",
    name: "Celal Bayar University",
    url: "http://www.bayar.edu.tr/",
  },
  {
    country: "TR",
    name: "Cukurova University",
    url: "http://www.cu.edu.tr/",
  },
  {
    country: "TR",
    name: "Cumhuriyet (Republik) University",
    url: "http://www.cumhuriyet.edu.tr/",
  },
  {
    country: "TR",
    name: "Dicle (Tirgris) University",
    url: "http://www.dicle.edu.tr/",
  },
  {
    country: "TR",
    name: "Dogus University",
    url: "http://www.dogus.edu.tr/",
  },
  {
    country: "TR",
    name: "Dokuz Eylül University",
    url: "http://www.deu.edu.tr/",
  },
  {
    country: "TR",
    name: "Dumlupinar University",
    url: "http://www.dumlupinar.edu.tr/",
  },
  {
    country: "TR",
    name: "Ege University",
    url: "http://www.ege.edu.tr/",
  },
  {
    country: "TR",
    name: "Erciyes University",
    url: "http://www.erciyes.edu.tr/",
  },
  {
    country: "TR",
    name: "Fatih University",
    url: "http://www.fatih.edu.tr/",
  },
  {
    country: "TR",
    name: "Firat (Euphrates) University",
    url: "http://www.firat.edu.tr/",
  },
  {
    country: "TR",
    name: "Galatasaray University",
    url: "http://www.gsu.edu.tr/",
  },
  {
    country: "TR",
    name: "Gaziantep University",
    url: "http://www.gantep.edu.tr/",
  },
  {
    country: "TR",
    name: "Gaziosmanpasa University",
    url: "http://www.gop.edu.tr/",
  },
  {
    country: "TR",
    name: "Gazi University Ankara",
    url: "http://www.gazi.edu.tr/",
  },
  {
    country: "TR",
    name: "Gebze Institute of Technology",
    url: "http://www.gyte.edu.tr/",
  },
  {
    country: "TR",
    name: "Gediz University",
    url: "http://www.gediz.edu.tr/",
  },
  {
    country: "TR",
    name: "Gulhane Military Medical Academy",
    url: "http://www.gata.edu.tr/",
  },
  {
    country: "TR",
    name: "Hacettepe University",
    url: "http://www.hun.edu.tr/",
  },
  {
    country: "TR",
    name: "Halic University",
    url: "http://www.halic.edu.tr/",
  },
  {
    country: "TR",
    name: "Harran University",
    url: "http://www.harran.edu.tr/",
  },
  {
    country: "TR",
    name: "Iasar University",
    url: "http://www.yasar.edu.tr/",
  },
  {
    country: "TR",
    name: "Inönü University",
    url: "http://www.inonu.edu.tr/",
  },
  {
    country: "TR",
    name: "Isik University",
    url: "http://www.isikun.edu.tr/",
  },
  {
    country: "TR",
    name: "Istanbul Arel University",
    url: "http://www.arel.edu.tr/",
  },
  {
    country: "TR",
    name: "Istanbul Bilgi University",
    url: "http://www.ibun.edu.tr/",
  },
  {
    country: "TR",
    name: "Istanbul Kultur University",
    url: "http://www.iku.edu.tr/",
  },
  {
    country: "TR",
    name: "İstanbul Şehir University",
    url: "http://www.sehir.edu.tr/",
  },
  {
    country: "TR",
    name: "Istanbul Technical University",
    url: "http://www.itu.edu.tr/",
  },
  {
    country: "TR",
    name: "Istanbul Ticaret University",
    url: "http://www.iticu.edu.tr/",
  },
  {
    country: "TR",
    name: "Istanbul University",
    url: "http://www.istanbul.edu.tr/",
  },
  {
    country: "TR",
    name: "Izmir Economic University",
    url: "http://www.izmirekonomi.edu.tr/",
  },
  {
    country: "TR",
    name: "Izmir Institute of Technology",
    url: "http://www.iyte.edu.tr/",
  },
  {
    country: "TR",
    name: "Kadir Has University",
    url: "http://www.khas.edu.tr/",
  },
  {
    country: "TR",
    name: "Kafkas University",
    url: "http://www.kafkas.edu.tr/",
  },
  {
    country: "TR",
    name: "Kahramanmaras Sütcü Imam University",
    url: "http://www.ksu.edu.tr/",
  },
  {
    country: "TR",
    name: "Karadeniz Technical University",
    url: "http://www.ktu.edu.tr/",
  },
  {
    country: "TR",
    name: "Kilis 7 Aralık University",
    url: "http://www.kilis.edu.tr/",
  },
  {
    country: "TR",
    name: "Kirikkale University",
    url: "http://www.kku.edu.tr/",
  },
  {
    country: "TR",
    name: "Kocaeli University",
    url: "http://www.kou.edu.tr/",
  },
  {
    country: "TR",
    name: "Koc University",
    url: "http://www.ku.edu.tr/",
  },
  {
    country: "TR",
    name: "Maltepe University",
    url: "http://www.maltepe.edu.tr/",
  },
  {
    country: "TR",
    name: "Marmara University",
    url: "http://www.marmara.edu.tr/",
  },
  {
    country: "TR",
    name: "Mersin University",
    url: "http://www.mersin.edu.tr/",
  },
  {
    country: "TR",
    name: "Middle East Technical University",
    url: "http://www.odtu.edu.tr/",
  },
  {
    country: "TR",
    name: "Mimar Sinan University",
    url: "http://www.msu.edu.tr/",
  },
  {
    country: "TR",
    name: "Mugla University",
    url: "http://www.mu.edu.tr/",
  },
  {
    country: "TR",
    name: "Mustafa Kemal University",
    url: "http://www.mku.edu.tr/",
  },
  {
    country: "TR",
    name: "Namik Kemal University",
    url: "http://www.nku.edu.tr/",
  },
  {
    country: "TR",
    name: "Necmettin Erbakan University",
    url: "http://www.konya.edu.tr/",
  },
  {
    country: "TR",
    name: "Nigde University",
    url: "http://www.nigde.edu.tr/",
  },
  {
    country: "TR",
    name: "Ondokuz Mayis University Samsun",
    url: "http://www.omu.edu.tr/",
  },
  {
    country: "TR",
    name: "Osmangazi University",
    url: "http://www.ogu.edu.tr/",
  },
  {
    country: "TR",
    name: "Ozyegin University",
    url: "http://www.ozyegin.edu.tr/",
  },
  {
    country: "TR",
    name: "Pamukkale University",
    url: "http://www.pamukkale.edu.tr/",
  },
  {
    country: "TR",
    name: "Sabanci University",
    url: "http://www.sabanciuniv.edu.tr/",
  },
  {
    country: "TR",
    name: "Sakarya University",
    url: "http://www.sau.edu.tr/",
  },
  {
    country: "TR",
    name: "Selcuk University",
    url: "http://www.selcuk.edu.tr/",
  },
  {
    country: "TR",
    name: "Suleyman Demirel University",
    url: "http://www.sdu.edu.tr/",
  },
  {
    country: "TR",
    name: "Tobb Economics and Technology University",
    url: "http://www.etu.edu.tr/",
  },
  {
    country: "TR",
    name: "Trakya University",
    url: "http://www.trakya.edu.tr/",
  },
  {
    country: "TR",
    name: "Türkisch-Deutsche Universität",
    url: "http://www.tau.edu.tr/",
  },
  {
    country: "TR",
    name: "Turkish Military Academy",
    url: "http://www.kho.edu.tr/",
  },
  {
    country: "TR",
    name: "Turkish Naval Academy",
    url: "http://www.dho.edu.tr/",
  },
  {
    country: "TR",
    name: "Ufuk University",
    url: "http://www.ufuk.edu.tr/",
  },
  {
    country: "TR",
    name: "Uludag University",
    url: "http://www.uludag.edu.tr/",
  },
  {
    country: "TR",
    name: "Yalova University",
    url: "http://www.yalova.edu.tr/",
  },
  {
    country: "TR",
    name: "Yasar University",
    url: "http://www.yasar.edu.tr/",
  },
  {
    country: "TR",
    name: "Yeditepe University",
    url: "http://www.yeditepe.edu.tr/",
  },
  {
    country: "TR",
    name: "Yildirim Beyazit University",
    url: "http://www.ybu.edu.tr/",
  },
  {
    country: "TR",
    name: "Yildiz Technical University",
    url: "http://www.yildiz.edu.tr/",
  },
  {
    country: "TR",
    name: "Yüzüncü Yil (Centennial) University",
    url: "http://www.yyu.edu.tr/",
  },
  {
    country: "TR",
    name: "Zonguldak Karaelmas University",
    url: "http://www.karaelmas.edu.tr/",
  },
  {
    country: "TT",
    name: "University of the Southern Caribbean",
    url: "http://www.usc.edu.tt/",
  },
  {
    country: "TT",
    name: "University of the West Indies St. Augustine",
    url: "http://www.uwi.tt/",
  },
  {
    country: "TT",
    name: "University of Trinidad and Tobago",
    url: "http://www.utt.edu.tt/",
  },
  {
    country: "TW",
    name: "Aletheia University",
    url: "http://www.au.edu.tw/",
  },
  {
    country: "TW",
    name: "Central Police University",
    url: "http://www.cpu.edu.tw/",
  },
  {
    country: "TW",
    name: "Chang Gung University",
    url: "http://www.cgu.edu.tw/",
  },
  {
    country: "TW",
    name: "Chang Jung University",
    url: "http://www.cju.edu.tw/",
  },
  {
    country: "TW",
    name: "Chao Yang University of Science and Technology",
    url: "http://www.cyut.edu.tw/",
  },
  {
    country: "TW",
    name: "Chia Nana College of Pharmacy and Science",
    url: "http://www.chna.edu.tw/",
  },
  {
    country: "TW",
    name: "China Medical College",
    url: "http://www.cmc.edu.tw/",
  },
  {
    country: "TW",
    name: "Chinese Culture University",
    url: "http://www.pccu.edu.tw/",
  },
  {
    country: "TW",
    name: "Ching Kuo Institue of Management & Health",
    url: "http://www.cku.edu.tw/",
  },
  {
    country: "TW",
    name: "Chung Hua University",
    url: "http://www.chu.edu.tw/",
  },
  {
    country: "TW",
    name: "Chung Shan Medical and Dental College",
    url: "http://www.csmc.edu.tw/",
  },
  {
    country: "TW",
    name: "Chung Yuan Christian University",
    url: "http://www.cycu.edu.tw/",
  },
  {
    country: "TW",
    name: "Da-Yeh University",
    url: "http://www.dyu.edu.tw/",
  },
  {
    country: "TW",
    name: "Feng Chia University",
    url: "http://www.fcu.edu.tw/",
  },
  {
    country: "TW",
    name: "Fu Jen Catholic University",
    url: "http://www.fju.edu.tw/",
  },
  {
    country: "TW",
    name: "Hsuan Chuang University",
    url: "http://www.hcu.edu.tw/",
  },
  {
    country: "TW",
    name: "Huafan University",
    url: "http://www.hfu.edu.tw/",
  },
  {
    country: "TW",
    name: "I-Shou University",
    url: "http://www.isu.edu.tw/",
  },
  {
    country: "TW",
    name: "Kaohsiung Medical College",
    url: "http://www.kmc.edu.tw/",
  },
  {
    country: "TW",
    name: "Kaohsuing Open University",
    url: "http://www.ouk.edu.tw/",
  },
  {
    country: "TW",
    name: "Kung Shan Institute of Technology",
    url: "http://www.ksit.edu.tw/",
  },
  {
    country: "TW",
    name: "Kun Shan University",
    url: "http://www.ksu.edu.tw/",
  },
  {
    country: "TW",
    name: "Ling Tung University",
    url: "http://www.ltc.edu.tw/",
  },
  {
    country: "TW",
    name: "LungHwa University of Science and Technology",
    url: "http://www.lhu.edu.tw/",
  },
  {
    country: "TW",
    name: "Ming Chuan University",
    url: "http://www.mcu.edu.tw/",
  },
  {
    country: "TW",
    name: "Nanhua University",
    url: "http://www.nhu.edu.tw/",
  },
  {
    country: "TW",
    name: "National Central University",
    url: "http://www.ncu.edu.tw/",
  },
  {
    country: "TW",
    name: "National Changhua University of Education",
    url: "http://www.ncue.edu.tw/",
  },
  {
    country: "TW",
    name: "National Chengchi University",
    url: "http://www.nccu.edu.tw/",
  },
  {
    country: "TW",
    name: "National Cheng Kung University",
    url: "http://english.web.ncku.edu.tw/",
  },
  {
    country: "TW",
    name: "National Chiao Tung University",
    url: "http://www.nctu.edu.tw/",
  },
  {
    country: "TW",
    name: "National Chiayi University",
    url: "http://www.ncyu.edu.tw/",
  },
  {
    country: "TW",
    name: "National Chi Nan University",
    url: "http://www.ncnu.edu.tw/",
  },
  {
    country: "TW",
    name: "National Chung Cheng University",
    url: "http://www.ccu.edu.tw/",
  },
  {
    country: "TW",
    name: "National Chung Hsing University, Taichung",
    url: "http://www.nchu.edu.tw/",
  },
  {
    country: "TW",
    name: "National Chung Hsing University, Taipei",
    url: "http://www.nchulc.edu.tw/",
  },
  {
    country: "TW",
    name: "National College of Physical Education and Sports",
    url: "http://www.ncpes.edu.tw/",
  },
  {
    country: "TW",
    name: "National Dong Hwa University",
    url: "http://www.ndhu.edu.tw/",
  },
  {
    country: "TW",
    name: "National Formosa University",
    url: "http://www.nfu.edu.tw/",
  },
  {
    country: "TW",
    name: "National Hsin-Chu Teachers College",
    url: "http://www.nhctc.edu.tw/",
  },
  {
    country: "TW",
    name: "National Hualien Teachers College",
    url: "http://www.nhltc.edu.tw/",
  },
  {
    country: "TW",
    name: "National Ilan University",
    url: "http://www.niu.edu.tw/",
  },
  {
    country: "TW",
    name: "National Institute of the Arts",
    url: "http://www.nia.edu.tw/",
  },
  {
    country: "TW",
    name: "National Kaohsiung First University of Science and Technology",
    url: "http://www.nkfu.edu.tw/",
  },
  {
    country: "TW",
    name: "National Kaohsiung Normal University",
    url: "http://www.nknu.edu.tw/",
  },
  {
    country: "TW",
    name: "National Kaohsiung University of Applied Sciences",
    url: "http://www.kuas.edu.tw/",
  },
  {
    country: "TW",
    name: "National Open University",
    url: "http://www.nou.edu.tw/",
  },
  {
    country: "TW",
    name: "National Pingtung Teachers College",
    url: "http://www.npttc.edu.tw/",
  },
  {
    country: "TW",
    name: "National Pingtung University of Science and Technology",
    url: "http://www.npust.edu.tw/",
  },
  {
    country: "TW",
    name: "National Sun Yat-Sen University",
    url: "http://www.nsysu.edu.tw/",
  },
  {
    country: "TW",
    name: "National Taichung Teachers College",
    url: "http://www.ntctc.edu.tw/",
  },
  {
    country: "TW",
    name: "National Tainan Teachers College",
    url: "http://www.ntntc.edu.tw/",
  },
  {
    country: "TW",
    name: "National Taipei College of Nursing",
    url: "http://www.ntcn.edu.tw/",
  },
  {
    country: "TW",
    name: "National Taipei Teachers College",
    url: "http://www.ntptc.edu.tw/",
  },
  {
    country: "TW",
    name: "National Taipei University",
    url: "http://www.ntpu.edu.tw/",
  },
  {
    country: "TW",
    name: "National Taipei University of Technology",
    url: "http://www.ntut.edu.tw/",
  },
  {
    country: "TW",
    name: "National Taitung Teachers College",
    url: "http://www.ntttc.edu.tw/",
  },
  {
    country: "TW",
    name: "National Taiwan College of Arts",
    url: "http://www.ntca.edu.tw/",
  },
  {
    country: "TW",
    name: "National Taiwan College of Physical Education and Sports",
    url: "http://www.ntcpe.edu.tw/",
  },
  {
    country: "TW",
    name: "National Taiwan College of the Arts",
    url: "http://www.ntca.edu.tw/",
  },
  {
    country: "TW",
    name: "National Taiwan Normal University",
    url: "http://www.ntnu.edu.tw/",
  },
  {
    country: "TW",
    name: "National Taiwan Ocean University",
    url: "http://www.ntou.edu.tw/",
  },
  {
    country: "TW",
    name: "National Taiwan University",
    url: "http://www.ntu.edu.tw/",
  },
  {
    country: "TW",
    name: "National Taiwan University of Science and Technology",
    url: "http://www.ntust.edu.tw/",
  },
  {
    country: "TW",
    name: "National Tsinghua University",
    url: "http://www.nthu.edu.tw/",
  },
  {
    country: "TW",
    name: "National United University",
    url: "http://www.nuu.edu.tw/",
  },
  {
    country: "TW",
    name: "National University of Kaohsiung",
    url: "http://www.nuk.edu.tw/",
  },
  {
    country: "TW",
    name: "National Yang Ming Medical College",
    url: "http://www.ym.edu.tw/",
  },
  {
    country: "TW",
    name: "National Yunlin University of Science and Technology",
    url: "http://www.yuntech.edu.tw/",
  },
  {
    country: "TW",
    name: "Providence University",
    url: "http://www.pu.edu.tw/",
  },
  {
    country: "TW",
    name: "Shih Chien University",
    url: "http://www.scc.edu.tw/",
  },
  {
    country: "TW",
    name: "Shih Hsin University",
    url: "http://www.shu.edu.tw/",
  },
  {
    country: "TW",
    name: "Soochow University",
    url: "http://www.scu.edu.tw/",
  },
  {
    country: "TW",
    name: "Southern Taiwan University of Technology",
    url: "http://www.stut.edu.tw/",
  },
  {
    country: "TW",
    name: "St. John's & St. Mary's Institute of Technology",
    url: "http://www.sjsmit.edu.tw/",
  },
  {
    country: "TW",
    name: "TaiChung Healthcare and Management University",
    url: "http://www.thmu.edu.tw/",
  },
  {
    country: "TW",
    name: "Tainan National College of the Arts",
    url: "http://www.tnca.edu.tw/",
  },
  {
    country: "TW",
    name: "Taipei Medical College",
    url: "http://www.tmc.edu.tw/",
  },
  {
    country: "TW",
    name: "Taipei Municipal Teachers College",
    url: "http://www.tmtc.edu.tw/",
  },
  {
    country: "TW",
    name: "Taipei Physical Education College",
    url: "http://www.tpec.edu.tw/",
  },
  {
    country: "TW",
    name: "Tamkang University",
    url: "http://www.tku.edu.tw/",
  },
  {
    country: "TW",
    name: "Tatung Institute of Technology",
    url: "http://www.ttit.edu.tw/",
  },
  {
    country: "TW",
    name: "TaTung University",
    url: "http://www.ttu.edu.tw/",
  },
  {
    country: "TW",
    name: "Tunghai University",
    url: "http://www.thu.edu.tw/",
  },
  {
    country: "TW",
    name: "Tzu Chi College of Medicine and Humanities",
    url: "http://www.tcu.edu.tw/",
  },
  {
    country: "TW",
    name: "Yuan Ze University",
    url: "http://www.yzu.edu.tw/",
  },
  {
    country: "TZ",
    name: "African Virtual University",
    url: "http://www.avu.org/",
  },
  {
    country: "TZ",
    name: "Aga Khan University",
    url: "http://www.aku.edu/",
  },
  {
    country: "TZ",
    name: "Dares Salaam Institute of Technology",
    url: "http://www.dit.ac.tz/",
  },
  {
    country: "TZ",
    name: "Hubert Kairuki Memorial University",
    url: "http://www.hkmu.ac.tz/",
  },
  {
    country: "TZ",
    name: "Institute of Accountancy Arusha",
    url: "http://www.iaa.ac.tz/",
  },
  {
    country: "TZ",
    name: "Institute of Finance Management",
    url: "http://www.ifm.ac.tz/",
  },
  {
    country: "TZ",
    name: "International Medical & Technological University",
    url: "http://www.imtu.edu/index.htm",
  },
  {
    country: "TZ",
    name: "Kilimanjaro Christian Medical College",
    url: "http://www.kcmc.ac.tz/",
  },
  {
    country: "TZ",
    name: "Moshi University College of Cooperative and Business Studies",
    url: "http://www.muccobs.ac.tz/",
  },
  {
    country: "TZ",
    name: "Mount Meru University",
    url: "http://www.mmu.ac.tz/",
  },
  {
    country: "TZ",
    name: "Muhimbili University of Health and Allied Sciences (MUHAS)",
    url: "http://www.muchs.ac.tz/",
  },
  {
    country: "TZ",
    name: "Mwalimu Nyerere Memorial Academy",
    url: "http://www.mnma.ac.tz/",
  },
  {
    country: "TZ",
    name: "Mzumbe University (Chuo Kikuu Mzumbe)",
    url: "http://www.mzumbe.ac.tz/",
  },
  {
    country: "TZ",
    name: "Open University of Tanzania",
    url: "http://www.out.ac.tz/",
  },
  {
    country: "TZ",
    name: "Sebatian Kolowa University College",
    url: "http://www.sekuco.org/",
  },
  {
    country: "TZ",
    name: "Sokoine University of Agriculture",
    url: "http://www.suanet.ac.tz/",
  },
  {
    country: "TZ",
    name: "St. Augustine University of Tanzania",
    url: "http://www.saut.ac.tz/",
  },
  {
    country: "TZ",
    name: "St. Joseph Group of Institutes",
    url: "http://www.stjosephtanzania.com/sjcet/index.html",
  },
  {
    country: "TZ",
    name: "Tumaini University - Iringa University College",
    url: "http://www.tumaini.ac.tz/",
  },
  {
    country: "TZ",
    name: "University of Dar Es Salaam",
    url: "http://www.udsm.ac.tz/",
  },
  {
    country: "TZ",
    name: "University of Dodoma",
    url: "http://www.udom.ac.tz/",
  },
  {
    country: "UA",
    name: "Alfred Nobel University of Economics and Law",
    url: "http://www.duep.edu/",
  },
  {
    country: "UA",
    name: "Bila Cerkva State Agrarian University",
    url: "http://www.btsau.kiev.ua/",
  },
  {
    country: "UA",
    name: "Chernivci National University",
    url: "http://www.chnu.cv.ua/",
  },
  {
    country: "UA",
    name: "Crimea State Medical University",
    url: "http://www.crsmu.com/",
  },
  {
    country: "UA",
    name: "Dneprodzerzhinsk State Technical University",
    url: "http://www.dstu.dp.ua/",
  },
  {
    country: "UA",
    name: "Dnepropetrovsk National University",
    url: "http://www.dnu.dp.ua/",
  },
  {
    country: "UA",
    name: "Dnepropetrovsk National University of Railway Transport",
    url: "http://www.diit.edu.ua/",
  },
  {
    country: "UA",
    name: "Donetsk National Technical University",
    url: "http://www.donntu.edu.ua/",
  },
  {
    country: "UA",
    name: "Donetsk National University",
    url: "http://www.donnu.edu.ua/",
  },
  {
    country: "UA",
    name: "Donetsk State Medical University",
    url: "http://www.donmeduni.com/",
  },
  {
    country: "UA",
    name: "East Ukrainian National University",
    url: "http://www.snu.edu.ua/",
  },
  {
    country: "UA",
    name: "Ivano-Frankivsk National University of Oli and Gas",
    url: "http://www.ifdtung.if.ua/",
  },
  {
    country: "UA",
    name: "Kharkiv National University",
    url: "http://www.univer.kharkov.ua/",
  },
  {
    country: "UA",
    name: "Kharkiv National University of Economics",
    url: "http://www.hneu.edu.ua/",
  },
  {
    country: "UA",
    name: "Kharkiv National University of Radio Electronics",
    url: "http://www.kture.kharkov.ua/",
  },
  {
    country: "UA",
    name: "Kharkiv State Medical University",
    url: "http://www.ksmu.kharkov.ua/",
  },
  {
    country: "UA",
    name: "Kharkiv State Technical University of Construction and Architecture",
    url: "http://www.kstuca.kharkov.ua/",
  },
  {
    country: "UA",
    name: "Kharkiv State Transport Technical University",
    url: "http://www.khadi.kharkov.ua/",
  },
  {
    country: "UA",
    name: "Kherson State University",
    url: "http://www.university.kherson.ua/",
  },
  {
    country: "UA",
    name: "Khmelnitsky National University",
    url: "http://www.tup.km.ua/",
  },
  {
    country: "UA",
    name: "Kiev National Economic University Named after Vadym Hetman",
    url: "http://www.kneu.edu.ua/",
  },
  {
    country: "UA",
    name: "Kiev National University of Construction and Architecture",
    url: "http://www.knuba.edu.ua/",
  },
  {
    country: "UA",
    name: "Kiev School Of Economics",
    url: "http://www.kse.org.ua/",
  },
  {
    country: "UA",
    name: "Kiev Slavonic University",
    url: "http://www.ksu.edu.ua/",
  },
  {
    country: "UA",
    name: "KROK Economics and Law University",
    url: "http://www.krok.edu.ua/",
  },
  {
    country: "UA",
    name: "Luhansk State Medical University",
    url: "http://www.lsmu.com/",
  },
  {
    country: "UA",
    name: "Luhansk Taras Shevchenko National Pedagogical University",
    url: "http://www.lgpu.lg.ua/",
  },
  {
    country: "UA",
    name: "Lviv Medical University",
    url: "http://www.meduniv.lviv.ua/",
  },
  {
    country: "UA",
    name: "Lviv National Medical Univeristy",
    url: "http://www.meduniv.lviv.ua/",
  },
  {
    country: "UA",
    name: "Lviv National University Ivan Franko",
    url: "http://www.franko.lviv.ua/",
  },
  {
    country: "UA",
    name: "Lviv Polytechnic National University",
    url: "http://www.lp.edu.ua/",
  },
  {
    country: "UA",
    name: "National Aerospace University Kharkov Aviation Institute",
    url: "http://www.khai.edu/",
  },
  {
    country: "UA",
    name: "National Agricultural University",
    url: "http://www.nauu.kiev.ua/",
  },
  {
    country: "UA",
    name: "National Aviation Univesity",
    url: "http://www.nau.edu.ua/",
  },
  {
    country: "UA",
    name: "National Medical University O.O. Bogomolets",
    url: "http://www.nmu.edu.ua/",
  },
  {
    country: "UA",
    name: "National Metallurgical Academy of Ukraine",
    url: "http://www.nmetau.edu.ua/",
  },
  {
    country: "UA",
    name: "National Mining University",
    url: "http://www.nmu.org.ua/",
  },
  {
    country: "UA",
    name: "National Pedagogical University M. Dragomanov",
    url: "http://www.npu.edu.ua/",
  },
  {
    country: "UA",
    name: "National Pirogov Memorial Medical University",
    url: "http://www.vnmu.vn.ua/",
  },
  {
    country: "UA",
    name: "National Taras Shevchenko University of Kiev",
    url: "http://www.univ.kiev.ua/",
  },
  {
    country: "UA",
    name: "National Technical University (Kharkiv Polytechnical Institute)",
    url: "http://www.kpi.kharkov.ua/",
  },
  {
    country: "UA",
    name: "National Technical University (Kiev Politechnical Institute)",
    url: "http://www.kpi.ua/",
  },
  {
    country: "UA",
    name: "National Transport University",
    url: "http://www.ntu.kar.net/",
  },
  {
    country: "UA",
    name: "National University of Food Technologies",
    url: "http://www.usuft.kiev.ua/",
  },
  {
    country: "UA",
    name: "National University of Internal Affairs",
    url: "http://www.univd.edu.ua/",
  },
  {
    country: "UA",
    name: "National University of Kiev-Mohyla Academy",
    url: "http://www.ukma.kiev.ua/",
  },
  {
    country: "UA",
    name: "National University of Ostroh Academy",
    url: "http://www.uosa.uar.net/",
  },
  {
    country: "UA",
    name: "National University of Shipbuilding",
    url: "http://www.usmtu.edu.ua/",
  },
  {
    country: "UA",
    name: "Odessa National Academy of Food Technologies",
    url: "http://www.osaft.odessa.ua/",
  },
  {
    country: "UA",
    name: "Odessa National I.I. Mecnikov University",
    url: "http://www.onu.edu.ua/",
  },
  {
    country: "UA",
    name: "Odessa National Marine University",
    url: "http://www.osmu.odessa.ua/",
  },
  {
    country: "UA",
    name: "Odessa National Maritime Academy",
    url: "http://www.ma.odessa.ua/",
  },
  {
    country: "UA",
    name: "Odessa National Polytechnic University",
    url: "http://www.opu.odessa.ua/",
  },
  {
    country: "UA",
    name: "Odessa State Academy of Construction and Architecture",
    url: "http://www.ogasa.odessa.ua/",
  },
  {
    country: "UA",
    name: "Odessa State Medical University",
    url: "http://www.arosmu.org/",
  },
  {
    country: "UA",
    name: "Petro Mohyla Black Sea State University",
    url: "http://www.kma.mk.ua/",
  },
  {
    country: "UA",
    name: "Poltava National Technical University",
    url: "http://www.pntu.edu.ua/",
  },
  {
    country: "UA",
    name: "Poltava University of Consumer Cooperatives in Ukraine",
    url: "http://www.pusku.edu.ua/",
  },
  {
    country: "UA",
    name: "Precarpathian University",
    url: "http://www.pu.if.ua/",
  },
  {
    country: "UA",
    name: "Sevastopol National Technical University",
    url: "http://sevntu.com.ua/",
  },
  {
    country: "UA",
    name: "State Pedagogical University in Kryvyi Rih",
    url: "http://www.kdpu.edu.ua/",
  },
  {
    country: "UA",
    name: "Sumy National Agrarian University",
    url: "http://www.sau.sumy.ua/",
  },
  {
    country: "UA",
    name: "Sumy State University",
    url: "http://www.sumdu.edu.ua/",
  },
  {
    country: "UA",
    name: "Taurida National V.I.Vernadsky University",
    url: "http://www.tnu.crimea.ua/",
  },
  {
    country: "UA",
    name: "Technological University of Podillya",
    url: "http://www.tup.km.ua/",
  },
  {
    country: "UA",
    name: "Ternopil Academy of National Economy",
    url: "http://www.tane.edu.ua/",
  },
  {
    country: "UA",
    name: "Ternopil State Ivan Pul'uj Technical University",
    url: "http://www.tu.edu.te.ua/",
  },
  {
    country: "UA",
    name: "Ukrainian Academy of Banking",
    url: "http://www.academy.sumy.ua/",
  },
  {
    country: "UA",
    name: "Ukrainian Academy of Pharmacy",
    url: "http://www.ukrfa.kharkov.ua/",
  },
  {
    country: "UA",
    name: "Ukrainian Medical Stomatological Academy",
    url: "http://www.uamsa.net/",
  },
  {
    country: "UA",
    name: "Ukrainian National Forestry University",
    url: "http://www.forest.lviv.ua/",
  },
  {
    country: "UA",
    name: "Uzhgorod National University",
    url: "http://www.univ.uzhgorod.ua/",
  },
  {
    country: "UA",
    name: "Vinnica National Technical University",
    url: "http://www.vstu.vinnica.ua/",
  },
  {
    country: "UA",
    name: "Volyn National University Lesja Ukrainka",
    url: "http://www.vdu.edu.ua/",
  },
  {
    country: "UA",
    name: "Zaporizhzhe National Technical University",
    url: "http://www.zntu.edu.ua/",
  },
  {
    country: "UA",
    name: "Zaporizhzhe National University",
    url: "http://www.znu.edu.ua/",
  },
  {
    country: "UG",
    name: "Aga Khan University",
    url: "http://www.aku.edu/",
  },
  {
    country: "UG",
    name: "Bugema University",
    url: "http://www.bugemauniv.ac.ug/",
  },
  {
    country: "UG",
    name: "Busitema University",
    url: "http://www.busitema.ac.ug/",
  },
  {
    country: "UG",
    name: "Busoga University",
    url: "http://www.busogauniversity.ac.ug/",
  },
  {
    country: "UG",
    name: "Central Buganda University",
    url: "http://www.cbu2000.com/",
  },
  {
    country: "UG",
    name: "Gulu University",
    url: "http://www.gu.ac.ug/",
  },
  {
    country: "UG",
    name: "Islamic University in Uganda",
    url: "http://www.iuiu.ac.ug/",
  },
  {
    country: "UG",
    name: "Kampala International University",
    url: "http://www.kiu.ac.ug/",
  },
  {
    country: "UG",
    name: "Kampala University",
    url: "http://www.ku.ac.ug/",
  },
  {
    country: "UG",
    name: "Kyambogo University",
    url: "http://www.kyu.ac.ug/",
  },
  {
    country: "UG",
    name: "Makerere University",
    url: "http://www.mak.ac.ug/",
  },
  {
    country: "UG",
    name: "Mbarara University of Science and Technology",
    url: "http://www.must.ac.ug/",
  },
  {
    country: "UG",
    name: "Mountains of the Moon University",
    url: "http://www.mmu.ac.ug/",
  },
  {
    country: "UG",
    name: "Mutesa 1 Royal University",
    url: "http://www.mru.ac.ug/",
  },
  {
    country: "UG",
    name: "Ndejje University",
    url: "http://www.ndejjeuniversity.ac.ug/",
  },
  {
    country: "UG",
    name: "Nkumba University",
    url: "http://www.nkumbauniversity.ac.ug/",
  },
  {
    country: "UG",
    name: "St. Augustine International University",
    url: "http://www.saiu.ac.ug/",
  },
  {
    country: "UG",
    name: "Uganda Christian University",
    url: "http://www.ucu.ac.ug/",
  },
  {
    country: "UG",
    name: "Uganda Martyr's University",
    url: "http://www.umu.ac.ug/",
  },
  {
    country: "US",
    name: "Abilene Christian University",
    url: "http://www.acu.edu/",
  },
  {
    country: "US",
    name: "Academy of Art College",
    url: "http://www.academyart.edu/",
  },
  {
    country: "US",
    name: "Adams State College",
    url: "http://www.adams.edu/",
  },
  {
    country: "US",
    name: "Adelphi University",
    url: "http://www.adelphi.edu/",
  },
  {
    country: "US",
    name: "Adler School of Professional Psychology",
    url: "http://www.adler.edu/",
  },
  {
    country: "US",
    name: "Adrian College",
    url: "http://www.adrian.edu/",
  },
  {
    country: "US",
    name: "Agnes Scott College",
    url: "http://www.scottlan.edu/",
  },
  {
    country: "US",
    name: "Air Force Institute of Technology",
    url: "http://www.afit.af.mil/",
  },
  {
    country: "US",
    name: "Alabama Agricultural and Mechanical University",
    url: "http://www.aamu.edu/",
  },
  {
    country: "US",
    name: "Alabama State University",
    url: "http://www.alasu.edu/",
  },
  {
    country: "US",
    name: "Alaska Bible College",
    url: "http://www.akbible.edu/",
  },
  {
    country: "US",
    name: "Alaska Pacific University",
    url: "http://www.alaskapacific.edu/",
  },
  {
    country: "US",
    name: "Albany College of Pharmacy",
    url: "http://www.acp.edu/",
  },
  {
    country: "US",
    name: "Albany Law School",
    url: "http://www.albanylaw.edu/",
  },
  {
    country: "US",
    name: "Albany Medical Center",
    url: "http://www.amc.edu/",
  },
  {
    country: "US",
    name: "Albany State University",
    url: "http://www.asurams.edu/",
  },
  {
    country: "US",
    name: "Albertus Magnus College",
    url: "http://www.albertus.edu/",
  },
  {
    country: "US",
    name: "Albion College",
    url: "http://www.albion.edu/",
  },
  {
    country: "US",
    name: "Albright College",
    url: "http://www.albright.edu/",
  },
  {
    country: "US",
    name: "Alcorn State University",
    url: "http://www.alcorn.edu/",
  },
  {
    country: "US",
    name: "Alderson Broaddus College",
    url: "http://www.ab.edu/",
  },
  {
    country: "US",
    name: "Alfred Adler Graduate School",
    url: "http://www.alfredadler.edu/",
  },
  {
    country: "US",
    name: "Alfred University",
    url: "http://www.alfred.edu/",
  },
  {
    country: "US",
    name: "Alice Lloyd College",
    url: "http://www.alc.edu/",
  },
  {
    country: "US",
    name: "Allegheny College",
    url: "http://www.alleg.edu/",
  },
  {
    country: "US",
    name: "Allen University",
    url: "http://www.scicu.org/allen/",
  },
  {
    country: "US",
    name: "Alma College",
    url: "http://www.alma.edu/",
  },
  {
    country: "US",
    name: "Alvernia College",
    url: "http://www.alvernia.edu/",
  },
  {
    country: "US",
    name: "Alverno College",
    url: "http://www.alverno.edu/",
  },
  {
    country: "US",
    name: "Ambassador University",
    url: "http://www.ambassador.edu/",
  },
  {
    country: "US",
    name: "Amber University",
    url: "http://www.amberu.edu/",
  },
  {
    country: "US",
    name: "American Academy of Nutrition",
    url: "http://www.nutritioneducation.com/",
  },
  {
    country: "US",
    name: "American Business & Technology University",
    url: "http://www.abtu.edu/",
  },
  {
    country: "US",
    name: "American Conservatory of Music",
    url: "http://members.aol.com/amerconsmu/",
  },
  {
    country: "US",
    name: "American Conservatory Theater",
    url: "http://www.act-sfbay.org/",
  },
  {
    country: "US",
    name: "American-European School of Management",
    url: "http://www.aesom.com/",
  },
  {
    country: "US",
    name: "American Film Institute Center for Advanced Film and Television Studies",
    url: "http://www.afionline.org/cafts/cafts.home.html",
  },
  {
    country: "US",
    name: "American Indian College",
    url: "http://www.aicag.edu/",
  },
  {
    country: "US",
    name: "American InterContinental University - Atlanta",
    url: "http://www.aiuniv.edu/",
  },
  {
    country: "US",
    name: "American InterContinental University - Ft. Lauderdale",
    url: "http://www.aiuniv.edu/",
  },
  {
    country: "US",
    name: "American InterContinental University - Georgia",
    url: "http://www.aiuniv.edu/",
  },
  {
    country: "US",
    name: "American InterContinental University Online",
    url: "http://www.aiu-online.com/",
  },
  {
    country: "US",
    name: "American International College",
    url: "http://www.aic.edu/",
  },
  {
    country: "US",
    name: "American Jewish University",
    url: "http://www.ajula.edu/",
  },
  {
    country: "US",
    name: "American Military University",
    url: "http://www.amunet.edu/",
  },
  {
    country: "US",
    name: "American Public University",
    url: "http://www.apus.edu/",
  },
  {
    country: "US",
    name: "American University",
    url: "http://www.american.edu/",
  },
  {
    country: "US",
    name: "American World University",
    url: "http://www.awu.edu/",
  },
  {
    country: "US",
    name: "Amherst College",
    url: "http://www.amherst.edu/",
  },
  {
    country: "US",
    name: "Anderson College",
    url: "http://www.anderson-college.edu/",
  },
  {
    country: "US",
    name: "Anderson University",
    url: "http://www.anderson.edu/",
  },
  {
    country: "US",
    name: "Andon College - Modesto",
    url: "http://www.andoncollege.com/",
  },
  {
    country: "US",
    name: "Andon College - Stockton",
    url: "http://www.andoncollege.org/",
  },
  {
    country: "US",
    name: "Andrew Jackson University",
    url: "http://www.aju.edu/",
  },
  {
    country: "US",
    name: "Andrews University",
    url: "http://www.andrews.edu/",
  },
  {
    country: "US",
    name: "Angelo State University",
    url: "http://www.angelo.edu/",
  },
  {
    country: "US",
    name: "Anna Maria College",
    url: "http://www.anna-maria.edu/",
  },
  {
    country: "US",
    name: "Antioch New England Graduate School",
    url: "http://www.antiochne.edu/",
  },
  {
    country: "US",
    name: "Antioch University",
    url: "http://www.antioch.edu/",
  },
  {
    country: "US",
    name: "Antioch University Los Angeles",
    url: "http://www.antiochla.edu/",
  },
  {
    country: "US",
    name: "Antioch University Santa Barbara",
    url: "http://www.antiochsb.edu/",
  },
  {
    country: "US",
    name: "Antioch University Seattle",
    url: "http://www.antiochsea.edu/",
  },
  {
    country: "US",
    name: "Appalachian Bible College",
    url: "http://www.appbibco.edu/",
  },
  {
    country: "US",
    name: "Appalachian State University",
    url: "http://www.appstate.edu/",
  },
  {
    country: "US",
    name: "Aquinas College",
    url: "http://www.aquinas.edu/",
  },
  {
    country: "US",
    name: "Arcadia University",
    url: "http://www.arcadia.edu/",
  },
  {
    country: "US",
    name: "Argosy University",
    url: "http://www.argosy.edu/",
  },
  {
    country: "US",
    name: "Argosy University - Hawaii",
    url: "http://www.argosyu.edu/",
  },
  {
    country: "US",
    name: "Arizona Christian University",
    url: "http://www.arizonachristian.edu",
  },
  {
    country: "US",
    name: "Arizona State University",
    url: "http://www.asu.edu/",
  },
  {
    country: "US",
    name: "Arizona State University, Downtown Phoenix Campus",
    url: "https://campus.asu.edu/downtown",
  },
  {
    country: "US",
    name: "Arizona State University, Polytechnic Campus",
    url: "https://campus.asu.edu/polytechnic",
  },
  {
    country: "US",
    name: "Arizona State University, Tempe Campus",
    url: "https://campus.asu.edu/tempe",
  },
  {
    country: "US",
    name: "Arizona State University, West Campus",
    url: "https://campus.asu.edu/west",
  },
  {
    country: "US",
    name: "Arkansas State University",
    url: "http://www.astate.edu/",
  },
  {
    country: "US",
    name: "Arkansas State University, Beebe",
    url: "http://www.asub.edu/",
  },
  {
    country: "US",
    name: "Arkansas State University, Mountain Home",
    url: "http://www.asumh.edu/",
  },
  {
    country: "US",
    name: "Arkansas State University, Newport",
    url: "http://www.asun.edu/",
  },
  {
    country: "US",
    name: "Arkansas Tech University",
    url: "http://www.atu.edu/",
  },
  {
    country: "US",
    name: "Arlington Baptist College",
    url: "http://www.abconline.edu/",
  },
  {
    country: "US",
    name: "Armstrong Atlantic State University",
    url: "http://www.armstrong.edu/",
  },
  {
    country: "US",
    name: "Armstrong University",
    url: "http://www.armstrong-u.edu/",
  },
  {
    country: "US",
    name: "Art Academy of Cincinnati",
    url: "http://www.artacademy.edu/",
  },
  {
    country: "US",
    name: "Art Center College of Design",
    url: "http://www.artcenter.edu/",
  },
  {
    country: "US",
    name: "Arthur D. Little Management Education Institute",
    url: "http://www.adlsom.edu/",
  },
  {
    country: "US",
    name: "Art Institute of Charlotte",
    url: "http://www.aich.artinstitutes.edu/",
  },
  {
    country: "US",
    name: "Art Institute of Southern California",
    url: "http://www.aisc.edu/",
  },
  {
    country: "US",
    name: "Asbury College",
    url: "http://www.asbury.edu/",
  },
  {
    country: "US",
    name: "Ashland University",
    url: "http://www.ashland.edu/",
  },
  {
    country: "US",
    name: "Assumption College",
    url: "http://www.assumption.edu/",
  },
  {
    country: "US",
    name: "Athenaeum of Ohio",
    url: "http://www.mtsm.org/",
  },
  {
    country: "US",
    name: "Athens State College",
    url: "http://www.athens.edu/",
  },
  {
    country: "US",
    name: "Atlanta Christian College",
    url: "http://www.acc.edu/",
  },
  {
    country: "US",
    name: "Atlanta College of Art",
    url: "http://www.aca.edu/",
  },
  {
    country: "US",
    name: "Atlantic International University",
    url: "http://www.aiu.edu/",
  },
  {
    country: "US",
    name: "Atlantic Union College",
    url: "http://www.atlanticuc.edu/",
  },
  {
    country: "US",
    name: "Atlantic University",
    url: "http://www.atlanticuniv.edu/",
  },
  {
    country: "US",
    name: "Auburn University",
    url: "http://www.auburn.edu/",
  },
  {
    country: "US",
    name: "Auburn University at Montgomery",
    url: "http://www.aum.edu/",
  },
  {
    country: "US",
    name: "Audrey Cohen College",
    url: "http://www.audrey-cohen.edu/",
  },
  {
    country: "US",
    name: "Augsburg College",
    url: "http://www.augsburg.edu/",
  },
  {
    country: "US",
    name: "Augustana College",
    url: "http://www.augustana.edu/",
  },
  {
    country: "US",
    name: "Augusta State University",
    url: "http://www.aug.edu/",
  },
  {
    country: "US",
    name: "Aurora University",
    url: "http://www.aurora.edu/",
  },
  {
    country: "US",
    name: "Austin College",
    url: "http://www.austinc.edu/",
  },
  {
    country: "US",
    name: "Austin Community College",
    url: "http://www.austin.cc.tx.us/",
  },
  {
    country: "US",
    name: "Austin Peay State University",
    url: "http://www.apsu.edu/",
  },
  {
    country: "US",
    name: "Ave Maria University",
    url: "http://www.avemaria.edu/",
  },
  {
    country: "US",
    name: "Averett College",
    url: "http://www.averett.edu/",
  },
  {
    country: "US",
    name: "Avila College",
    url: "http://www.avila.edu/",
  },
  {
    country: "US",
    name: "Azusa Pacific University",
    url: "http://www.apu.edu/",
  },
  {
    country: "US",
    name: "Babson College",
    url: "http://www.babson.edu/",
  },
  {
    country: "US",
    name: "Baker College of Auburn Hills",
    url: "http://www.baker.edu/visit/auburn.html",
  },
  {
    country: "US",
    name: "Baker College of Cadillac",
    url: "http://www.baker.edu/visit/cadillac.html",
  },
  {
    country: "US",
    name: "Baker College of Flint",
    url: "http://www.baker.edu/visit/flint.html",
  },
  {
    country: "US",
    name: "Baker College of Mount Clemens",
    url: "http://www.baker.edu/visit/mtclemens.html",
  },
  {
    country: "US",
    name: "Baker College of Muskegon",
    url: "http://www.baker.edu/visit/muskegon.html",
  },
  {
    country: "US",
    name: "Baker College of Owosso",
    url: "http://www.baker.edu/visit/owosso.html",
  },
  {
    country: "US",
    name: "Baker College of Port Huron",
    url: "http://www.baker.edu/visit/porthuron.html",
  },
  {
    country: "US",
    name: "Baker University",
    url: "http://www.bakeru.edu/",
  },
  {
    country: "US",
    name: "Baldwin-Wallace College",
    url: "http://www.baldwinw.edu/",
  },
  {
    country: "US",
    name: "Ball State University",
    url: "http://www.bsu.edu/",
  },
  {
    country: "US",
    name: "Baltimore Hebrew University",
    url: "http://www.bhu.edu/",
  },
  {
    country: "US",
    name: "Baltimore International College",
    url: "http://www.bic.edu/",
  },
  {
    country: "US",
    name: "Bank Street College of Education",
    url: "http://www.bnkst.edu/",
  },
  {
    country: "US",
    name: "Baptist Bible College of Missouri",
    url: "http://www.bbcnet.edu/",
  },
  {
    country: "US",
    name: "Baptist Bible College of Pennsylvania",
    url: "http://www.bbc.edu/",
  },
  {
    country: "US",
    name: "Barat College",
    url: "http://www.barat.edu/",
  },
  {
    country: "US",
    name: "Barber-Scotia College",
    url: "http://www.b-sc.edu/",
  },
  {
    country: "US",
    name: "Barclay College",
    url: "http://www.barclaycollege.edu/",
  },
  {
    country: "US",
    name: "Bard College",
    url: "http://www.bard.edu/",
  },
  {
    country: "US",
    name: "Bard Graduate Center for Studies in the Decorative Arts",
    url: "http://www.bard.edu/graduate/BGC/intro.html",
  },
  {
    country: "US",
    name: "Barnard College - Columbia University",
    url: "http://www.barnard.edu/",
  },
  {
    country: "US",
    name: "Barry University",
    url: "http://www.barry.edu/",
  },
  {
    country: "US",
    name: "Bartlesville Wesleyan College",
    url: "http://www.bwc.edu/",
  },
  {
    country: "US",
    name: "Barton College",
    url: "http://www.barton.edu/",
  },
  {
    country: "US",
    name: "Bastyr University",
    url: "http://www.bastyr.edu/",
  },
  {
    country: "US",
    name: "Bates College",
    url: "http://www.bates.edu/",
  },
  {
    country: "US",
    name: "Bauder College",
    url: "http://www.bauder.edu/",
  },
  {
    country: "US",
    name: "Baylor College of Dentistry",
    url: "http://www.tambcd.edu/",
  },
  {
    country: "US",
    name: "Baylor College of Medicine",
    url: "http://www.bcm.tmc.edu/",
  },
  {
    country: "US",
    name: "Baylor University",
    url: "http://www.baylor.edu/",
  },
  {
    country: "US",
    name: "Belhaven College",
    url: "http://www.belhaven.edu/",
  },
  {
    country: "US",
    name: "Bellarmine College",
    url: "http://www.bellarmine.edu/",
  },
  {
    country: "US",
    name: "Bellevue University",
    url: "http://www.bellevue.edu/",
  },
  {
    country: "US",
    name: "Bellin College of Nursing",
    url: "http://www.bellin.org/bcn/",
  },
  {
    country: "US",
    name: "Belmont Abbey College",
    url: "http://www.bac.edu/",
  },
  {
    country: "US",
    name: "Belmont University",
    url: "http://www.belmont.edu/",
  },
  {
    country: "US",
    name: "Beloit College",
    url: "http://www.beloit.edu/",
  },
  {
    country: "US",
    name: "Bemidji State Univeristy",
    url: "http://www.bemidji.msus.edu/",
  },
  {
    country: "US",
    name: "Benedict College",
    url: "http://www.benedict.edu/",
  },
  {
    country: "US",
    name: "Benedictine College",
    url: "http://www.benedictine.edu/",
  },
  {
    country: "US",
    name: "Benedictine University",
    url: "http://www.ben.edu/",
  },
  {
    country: "US",
    name: "Benedictine University, Springfield College in Illinois",
    url: "http://www.sci.edu/",
  },
  {
    country: "US",
    name: "Bennett College",
    url: "http://www.bennett.edu/",
  },
  {
    country: "US",
    name: "Bennington College",
    url: "http://www.bennington.edu/",
  },
  {
    country: "US",
    name: "Bentley College",
    url: "http://www.bentley.edu/",
  },
  {
    country: "US",
    name: "Berea College",
    url: "http://www.berea.edu/",
  },
  {
    country: "US",
    name: "Berean University of the Assemblies of God",
    url: "http://www.berean.edu/",
  },
  {
    country: "US",
    name: "Berklee College of Music",
    url: "http://www.berklee.edu/",
  },
  {
    country: "US",
    name: "Berne University",
    url: "http://www.berne.edu/",
  },
  {
    country: "US",
    name: "Berry College",
    url: "http://www.berry.edu/",
  },
  {
    country: "US",
    name: "Bethany College California",
    url: "http://www.bethany.edu/",
  },
  {
    country: "US",
    name: "Bethany College Kansas",
    url: "http://www.bethanylb.edu/",
  },
  {
    country: "US",
    name: "Bethany College West Virginia",
    url: "http://www.bethany.wvnet.edu/",
  },
  {
    country: "US",
    name: "Bethel College McKenzie",
    url: "http://www.bethel-college.edu/",
  },
  {
    country: "US",
    name: "Bethel College Mishawaka",
    url: "http://www.bethel-in.edu/",
  },
  {
    country: "US",
    name: "Bethel College Newton",
    url: "http://www.bethelks.edu/",
  },
  {
    country: "US",
    name: "Beth-El College of Nursing and Health Sciences",
    url: "http://www.uccs.edu/~bethel/",
  },
  {
    country: "US",
    name: "Bethel College St. Paul",
    url: "http://www.bethel.edu/",
  },
  {
    country: "US",
    name: "Bethune-Cookman College",
    url: "http://www.bethune.cookman.edu/",
  },
  {
    country: "US",
    name: "Biola University",
    url: "http://www.biola.edu/",
  },
  {
    country: "US",
    name: "Birmingham-Southern College",
    url: "http://www.bsc.edu/",
  },
  {
    country: "US",
    name: "Blackburn College",
    url: "http://www.blackburn.edu/",
  },
  {
    country: "US",
    name: "Black Hawk College",
    url: "http://www.bhc.edu/",
  },
  {
    country: "US",
    name: "Black Hills State University",
    url: "http://www.bhsu.edu/",
  },
  {
    country: "US",
    name: "Blessing-Rieman College of Nursing",
    url: "http://www.brcn.edu/",
  },
  {
    country: "US",
    name: "Bloomfield College",
    url: "http://www.bloomfield.edu/",
  },
  {
    country: "US",
    name: "Bloomsburg University of Pennsylvania",
    url: "http://www.bloomu.edu/",
  },
  {
    country: "US",
    name: "Bluefield College",
    url: "http://www.bluefield.edu/",
  },
  {
    country: "US",
    name: "Bluefield State College",
    url: "http://www.bluefield.wvnet.edu/",
  },
  {
    country: "US",
    name: "Blue Mountain College",
    url: "http://www.bmc.edu/",
  },
  {
    country: "US",
    name: "Bluffton College",
    url: "http://www.bluffton.edu/",
  },
  {
    country: "US",
    name: "Bob Jones University",
    url: "http://www.bju.edu/",
  },
  {
    country: "US",
    name: "Boise Bible College",
    url: "http://www.boisebible.edu/",
  },
  {
    country: "US",
    name: "Boise State University",
    url: "http://www.boisestate.edu/",
  },
  {
    country: "US",
    name: "Boricua College",
    url: "http://www.boricuacollege.edu/",
  },
  {
    country: "US",
    name: "Boston Architectural Center",
    url: "http://www.the-bac.edu/",
  },
  {
    country: "US",
    name: "Boston College",
    url: "http://www.bc.edu/",
  },
  {
    country: "US",
    name: "Boston University",
    url: "http://www.bu.edu/",
  },
  {
    country: "US",
    name: "Bowdoin College",
    url: "http://www.bowdoin.edu/",
  },
  {
    country: "US",
    name: "Bowie State University",
    url: "http://www.bowiestate.edu/",
  },
  {
    country: "US",
    name: "Bowling Green State University",
    url: "http://www.bgsu.edu/",
  },
  {
    country: "US",
    name: "Bowling Green State University, Firelands",
    url: "http://www.firelands.bgsu.edu/",
  },
  {
    country: "US",
    name: "Bradley University",
    url: "http://www.bradley.edu/",
  },
  {
    country: "US",
    name: "Brandeis University",
    url: "http://www.brandeis.edu/",
  },
  {
    country: "US",
    name: "Brandman University",
    url: "http://www.brandman.edu/",
  },
  {
    country: "US",
    name: "Brenau University",
    url: "http://www.brenau.edu/",
  },
  {
    country: "US",
    name: "Brescia University",
    url: "http://www.brescia.edu/",
  },
  {
    country: "US",
    name: "Brevard College",
    url: "http://www.brevard.edu/",
  },
  {
    country: "US",
    name: "Brewton-Parker College",
    url: "http://www.bpc.edu/",
  },
  {
    country: "US",
    name: "Breyer State University (Virtual University)",
    url: "http://www.breyerstate.com/",
  },
  {
    country: "US",
    name: "Briar Cliff College",
    url: "http://www.briar-cliff.edu/",
  },
  {
    country: "US",
    name: "Bridgewater College",
    url: "http://www.bridgewater.edu/",
  },
  {
    country: "US",
    name: "Bridgewater State College",
    url: "http://www.bridgew.edu/",
  },
  {
    country: "US",
    name: "Brigham Young University",
    url: "http://www.byu.edu/",
  },
  {
    country: "US",
    name: "Brigham Young University Hawaii",
    url: "http://www.byuh.edu/",
  },
  {
    country: "US",
    name: "Brooklyn Law School",
    url: "http://www.brooklaw.edu/",
  },
  {
    country: "US",
    name: "Brooks Institute of Photography",
    url: "http://www.brooks.edu/",
  },
  {
    country: "US",
    name: "Brown University",
    url: "http://www.brown.edu/",
  },
  {
    country: "US",
    name: "Brunswick Community College",
    url: "http://www.brunswickcc.edu/",
  },
  {
    country: "US",
    name: "Bryan College",
    url: "http://www.bryan.edu/",
  },
  {
    country: "US",
    name: "Bryant and Stratton College",
    url: "http://www.bryantstratton.edu/",
  },
  {
    country: "US",
    name: "Bryant University",
    url: "http://www.bryant.edu/",
  },
  {
    country: "US",
    name: "Bryn Athyn College of the New Church",
    url: "http://www.newchurch.edu/college/",
  },
  {
    country: "US",
    name: "Bryn Mawr College",
    url: "http://www.brynmawr.edu/",
  },
  {
    country: "US",
    name: "Bucknell University",
    url: "http://www.bucknell.edu/",
  },
  {
    country: "US",
    name: "Buena Vista University",
    url: "http://www.bvu.edu/",
  },
  {
    country: "US",
    name: "Burlington College",
    url: "http://www.burlcol.edu/",
  },
  {
    country: "US",
    name: "Butler University",
    url: "http://www.butler.edu/",
  },
  {
    country: "US",
    name: "Cabrini College",
    url: "http://www.cabrini.edu/",
  },
  {
    country: "US",
    name: "Caldwell College",
    url: "http://www.caldwell.edu/",
  },
  {
    country: "US",
    name: "California Baptist College",
    url: "http://www.calbaptist.edu/",
  },
  {
    country: "US",
    name: "California Coast University",
    url: "http://www.calcoast.edu/",
  },
  {
    country: "US",
    name: "California College for Health Sciences",
    url: "http://www.cchs.edu/",
  },
  {
    country: "US",
    name: "California College of Arts and Crafts",
    url: "http://www.ccac-art.edu/",
  },
  {
    country: "US",
    name: "California College of Podiatric Medicine",
    url: "http://www.ccpm.edu/",
  },
  {
    country: "US",
    name: "California College San Diego",
    url: "http://www.cc-sd.edu/",
  },
  {
    country: "US",
    name: "California Institute of Integral Studies",
    url: "http://www.ciis.edu/",
  },
  {
    country: "US",
    name: "California Institute of Technology",
    url: "http://www.caltech.edu/",
  },
  {
    country: "US",
    name: "California Institute of the Arts",
    url: "http://www.calarts.edu/",
  },
  {
    country: "US",
    name: "California Lutheran University",
    url: "http://www.callutheran.edu/",
  },
  {
    country: "US",
    name: "California Maritime Academy",
    url: "http://www.csum.edu/",
  },
  {
    country: "US",
    name: "California Polytechnic State University - San Luis Obispo",
    url: "http://www.calpoly.edu/",
  },
  {
    country: "US",
    name: "California School of Professional Psychology - Berkley/Alameda",
    url: "http://www.cspp.edu/",
  },
  {
    country: "US",
    name: "California School of Professional Psychology - Fresno",
    url: "http://www.cspp.edu/catalog/8.htm",
  },
  {
    country: "US",
    name: "California School of Professional Psychology - Los Angels",
    url: "http://www.cspp.edu/catalog/9.htm",
  },
  {
    country: "US",
    name: "California School of Professional Psychology - San Diego",
    url: "http://www.cspp.edu/catalog/10.htm",
  },
  {
    country: "US",
    name: "California State Polytechnic University - Pomona",
    url: "http://www.csupomona.edu/",
  },
  {
    country: "US",
    name: "California State University, Bakersfield",
    url: "http://www.csubak.edu/",
  },
  {
    country: "US",
    name: "California State University, Channel Islands",
    url: "http://www.csuci.edu/",
  },
  {
    country: "US",
    name: "California State University, Chico",
    url: "http://www.csuchico.edu/",
  },
  {
    country: "US",
    name: "California State University, Dominguez Hills",
    url: "http://www.csudh.edu/",
  },
  {
    country: "US",
    name: "California State University, Fresno",
    url: "http://www.csufresno.edu/",
  },
  {
    country: "US",
    name: "California State University, Fullerton",
    url: "http://www.fullerton.edu/",
  },
  {
    country: "US",
    name: "California State University, Hayward",
    url: "http://www.csuhayward.edu/",
  },
  {
    country: "US",
    name: "California State University, Long Beach",
    url: "http://www.csulb.edu/",
  },
  {
    country: "US",
    name: "California State University, Los Angeles",
    url: "http://www.calstatela.edu/",
  },
  {
    country: "US",
    name: "California State University, Monterey Bay",
    url: "http://www.monterey.edu/",
  },
  {
    country: "US",
    name: "California State University, Northridge",
    url: "http://www.csun.edu/",
  },
  {
    country: "US",
    name: "California State University, Sacramento",
    url: "http://www.csus.edu/",
  },
  {
    country: "US",
    name: "California State University, San Bernadino",
    url: "http://www.csusb.edu/",
  },
  {
    country: "US",
    name: "California State University, San Marcos",
    url: "http://www.csusm.edu/",
  },
  {
    country: "US",
    name: "California State University, Stanislaus",
    url: "http://www.csustan.edu/",
  },
  {
    country: "US",
    name: "California University of Management and Sciences",
    url: "http://cauniversity.edu.cufce.org/",
  },
  {
    country: "US",
    name: "California University of Pennsylvania",
    url: "http://www.cup.edu/",
  },
  {
    country: "US",
    name: "California Western School of Law",
    url: "http://www.cwsl.edu/",
  },
  {
    country: "US",
    name: "Calumet College of St. Joseph",
    url: "http://www.ccsj.edu/",
  },
  {
    country: "US",
    name: "Calvary Bible College",
    url: "http://www.calvary.edu/",
  },
  {
    country: "US",
    name: "Calvin College",
    url: "http://www.calvin.edu/",
  },
  {
    country: "US",
    name: "Cambridge College",
    url: "http://www.cambridge.edu/",
  },
  {
    country: "US",
    name: "Cameron University",
    url: "http://www.cameron.edu/",
  },
  {
    country: "US",
    name: "Campbellsville College",
    url: "http://www.campbellsvil.edu/",
  },
  {
    country: "US",
    name: "Campbell University",
    url: "http://www.campbell.edu/",
  },
  {
    country: "US",
    name: "Canisius College",
    url: "http://www.canisius.edu/",
  },
  {
    country: "US",
    name: "Capella University",
    url: "http://www.capella.edu/",
  },
  {
    country: "US",
    name: "Capital University",
    url: "http://www.capital.edu/",
  },
  {
    country: "US",
    name: "Capital University Law School",
    url: "http://www.law.capital.edu/",
  },
  {
    country: "US",
    name: "Capitol College",
    url: "http://www.capitol-college.edu/",
  },
  {
    country: "US",
    name: "Cardinal Stritch University",
    url: "http://www.stritch.edu/",
  },
  {
    country: "US",
    name: "Carleton College",
    url: "http://www.carleton.edu/",
  },
  {
    country: "US",
    name: "Carlos Albizu University",
    url: "http://www.albizu.edu/",
  },
  {
    country: "US",
    name: "Carlow College",
    url: "http://www.carlow.edu/",
  },
  {
    country: "US",
    name: "Carnegie Mellon University",
    url: "http://www.cmu.edu/",
  },
  {
    country: "US",
    name: "Carroll College Helena",
    url: "http://www.carroll.edu/",
  },
  {
    country: "US",
    name: "Carroll College Waukesha",
    url: "http://www.cc.edu/",
  },
  {
    country: "US",
    name: "Carson-Newman College",
    url: "http://www.cn.edu/",
  },
  {
    country: "US",
    name: "Carthage College",
    url: "http://www.carthage.edu/",
  },
  {
    country: "US",
    name: "Case Western Reserve University",
    url: "http://www.cwru.edu/",
  },
  {
    country: "US",
    name: "Castleton State College",
    url: "http://www.csc.vsc.edu/",
  },
  {
    country: "US",
    name: "Catawba College",
    url: "http://www.catawba.edu/",
  },
  {
    country: "US",
    name: "Catholic Theological Union",
    url: "http://www.ctu.edu/",
  },
  {
    country: "US",
    name: "Cedar Crest College",
    url: "http://www.cedarcrest.edu/",
  },
  {
    country: "US",
    name: "Cedarville College",
    url: "http://www.cedarville.edu/",
  },
  {
    country: "US",
    name: "Centenary College",
    url: "http://www.centenarycollege.edu/",
  },
  {
    country: "US",
    name: "Centenary College of Louisiana",
    url: "http://www.centenary.edu/",
  },
  {
    country: "US",
    name: "Center for Humanistic Studies",
    url: "http://www.humanpsych.edu/",
  },
  {
    country: "US",
    name: "Central Baptist College",
    url: "http://www.cbc.edu/",
  },
  {
    country: "US",
    name: "Central Bible College",
    url: "http://www.cbcag.edu/",
  },
  {
    country: "US",
    name: "Central Christian College of the Bible",
    url: "http://www.cccb.edu/",
  },
  {
    country: "US",
    name: "Central College",
    url: "http://www.central.edu/",
  },
  {
    country: "US",
    name: "Central Connecticut State University",
    url: "http://www.ccsu.edu/",
  },
  {
    country: "US",
    name: "Central Methodist College",
    url: "http://www.cmc.edu/",
  },
  {
    country: "US",
    name: "Central Michigan University",
    url: "http://www.cmich.edu/",
  },
  {
    country: "US",
    name: "Central State University",
    url: "http://www.centralstate.edu/",
  },
  {
    country: "US",
    name: "Central Washington University",
    url: "http://www.cwu.edu/",
  },
  {
    country: "US",
    name: "Centre College",
    url: "http://www.centre.edu/",
  },
  {
    country: "US",
    name: "Chadron State College",
    url: "http://www.csc.edu/",
  },
  {
    country: "US",
    name: "Chaminade University of Honolulu",
    url: "http://www.chaminade.edu/",
  },
  {
    country: "US",
    name: "Champlain College",
    url: "http://www.champlain.edu/",
  },
  {
    country: "US",
    name: "Chapman University",
    url: "http://www.chapman.edu/",
  },
  {
    country: "US",
    name: "Charles R. Drew University of Medicine and Science",
    url: "http://www.cdrewu.edu/",
  },
  {
    country: "US",
    name: "Charleston Southern University",
    url: "http://www.csuniv.edu/",
  },
  {
    country: "US",
    name: "Charter Oak State College",
    url: "http://www.cosc.edu/",
  },
  {
    country: "US",
    name: "Chatham College",
    url: "http://www.chatham.edu/",
  },
  {
    country: "US",
    name: "Chestnut Hill College",
    url: "http://www.chc.edu/",
  },
  {
    country: "US",
    name: "Cheyney University of Pennsylvania",
    url: "http://www.cheyney.edu/",
  },
  {
    country: "US",
    name: "Chicago State University",
    url: "http://www.csu.edu/",
  },
  {
    country: "US",
    name: "Chowan College",
    url: "http://www.chowan.edu/",
  },
  {
    country: "US",
    name: "Christendom College",
    url: "http://www.christendom.edu/",
  },
  {
    country: "US",
    name: "Christian Brothers University",
    url: "http://www.cbu.edu/",
  },
  {
    country: "US",
    name: "Christian Heritage College",
    url: "http://www.christianheritage.edu/",
  },
  {
    country: "US",
    name: "Christopher Newport University",
    url: "http://www.cnu.edu/",
  },
  {
    country: "US",
    name: "Circleville Bible College",
    url: "http://www.biblecollege.edu/",
  },
  {
    country: "US",
    name: "City University",
    url: "http://www.cityu.edu/",
  },
  {
    country: "US",
    name: "City University of New York, Bernard M. Baruch College",
    url: "http://www.baruch.cuny.edu/",
  },
  {
    country: "US",
    name: "City University of New York, Brooklyn College",
    url: "http://www.brooklyn.cuny.edu/",
  },
  {
    country: "US",
    name: "City University of New York, City College",
    url: "http://www.ccny.cuny.edu/",
  },
  {
    country: "US",
    name: "City University of New York, College of Staten Island",
    url: "http://www.csi.cuny.edu/",
  },
  {
    country: "US",
    name: "City University of New York (CUNY) System",
    url: "http://www.cuny.edu/",
  },
  {
    country: "US",
    name: "City University of New York, Graduate School and University Center",
    url: "http://www.gc.cuny.edu/",
  },
  {
    country: "US",
    name: "City University of New York, Hunter College",
    url: "http://www.hunter.cuny.edu/",
  },
  {
    country: "US",
    name: "City University of New York, John Jay College of Criminal Justice",
    url: "http://www.jjay.cuny.edu/",
  },
  {
    country: "US",
    name: "City University of New York, Lehman College",
    url: "http://www.lehman.cuny.edu/",
  },
  {
    country: "US",
    name: "City University of New York, Medgar Evers College",
    url: "http://www.mec.cuny.edu/",
  },
  {
    country: "US",
    name: "City University of New York Medical School / Sophie Davis School of Biomedical Education",
    url: "http://med.cuny.edu/",
  },
  {
    country: "US",
    name: "City University of New York, New York City Technical College",
    url: "http://www.nyctc.cuny.edu/",
  },
  {
    country: "US",
    name: "City University of New York, Queens College",
    url: "http://www.qc.edu/",
  },
  {
    country: "US",
    name: "City University of New York, School of Law at Queens College",
    url: "http://www.law.cuny.edu/",
  },
  {
    country: "US",
    name: "City University of New York, York College",
    url: "http://www.york.cuny.edu/",
  },
  {
    country: "US",
    name: "Claflin College",
    url: "http://www.scicu.org/claflin/",
  },
  {
    country: "US",
    name: "Claremont Graduate University",
    url: "http://www.cgu.edu/",
  },
  {
    country: "US",
    name: "Claremont Lincoln University",
    url: "http://www.claremontlincoln.org/",
  },
  {
    country: "US",
    name: "Claremont McKenna College",
    url: "http://www.mckenna.edu/",
  },
  {
    country: "US",
    name: "Clarion University",
    url: "http://www.clarion.edu/",
  },
  {
    country: "US",
    name: "Clark Atlanta University",
    url: "http://www.cau.edu/",
  },
  {
    country: "US",
    name: "Clarke College",
    url: "http://www.clarke.edu/",
  },
  {
    country: "US",
    name: "Clarkson College",
    url: "http://www.clarksoncollege.edu/",
  },
  {
    country: "US",
    name: "Clarkson University",
    url: "http://www.clarkson.edu/",
  },
  {
    country: "US",
    name: "Clark University",
    url: "http://www.clarku.edu/",
  },
  {
    country: "US",
    name: "Clayton College & State University",
    url: "http://www.clayton.edu/",
  },
  {
    country: "US",
    name: "Clearwater Christian College",
    url: "http://www.clearwater.edu/",
  },
  {
    country: "US",
    name: "Cleary College",
    url: "http://www.cleary.edu/",
  },
  {
    country: "US",
    name: "Clemson University",
    url: "http://www.clemson.edu/",
  },
  {
    country: "US",
    name: "Cleveland Chiropractic College, Kansas City",
    url: "http://www.clevelandchiropractic.edu/",
  },
  {
    country: "US",
    name: "Cleveland Chiropractic College, Los Angeles",
    url: "http://www.clevelandchiropractic.edu/",
  },
  {
    country: "US",
    name: "Cleveland Institute of Art",
    url: "http://www.cia.edu/",
  },
  {
    country: "US",
    name: "Cleveland Institute of Music",
    url: "http://www.cim.edu/",
  },
  {
    country: "US",
    name: "Cleveland State University",
    url: "http://www.csuohio.edu/",
  },
  {
    country: "US",
    name: "Coastal Carolina University",
    url: "http://www.coastal.edu/",
  },
  {
    country: "US",
    name: "Coe College",
    url: "http://www.coe.edu/",
  },
  {
    country: "US",
    name: "Cogswell Polytechnical College",
    url: "http://www.cogswell.edu/",
  },
  {
    country: "US",
    name: "Coker College",
    url: "http://www.coker.edu/",
  },
  {
    country: "US",
    name: "Colby College",
    url: "http://www.colby.edu/",
  },
  {
    country: "US",
    name: "Colby-Sawyer College",
    url: "http://www.colby-sawyer.edu/",
  },
  {
    country: "US",
    name: "Coleman College",
    url: "http://www.coleman.edu/",
  },
  {
    country: "US",
    name: "Colgate University",
    url: "http://www.colgate.edu/",
  },
  {
    country: "US",
    name: "CollegeAmerica, Denver",
    url: "http://www.collegeamerica.edu/",
  },
  {
    country: "US",
    name: "CollegeAmerica, Phoenix",
    url: "http://www.collegeamerica.edu/",
  },
  {
    country: "US",
    name: "College for Creative Studies",
    url: "http://www.ccscad.edu/",
  },
  {
    country: "US",
    name: "College for Financial Planning",
    url: "http://www.fp.edu/",
  },
  {
    country: "US",
    name: "College for Lifelong Learning",
    url: "http://www.cll.edu/",
  },
  {
    country: "US",
    name: "College Misericordia",
    url: "http://www.miseri.edu/",
  },
  {
    country: "US",
    name: "College of Aeronautics",
    url: "http://www.aero.edu/",
  },
  {
    country: "US",
    name: "College of Charleston",
    url: "http://www.cofc.edu/",
  },
  {
    country: "US",
    name: "College of DuPage",
    url: "http://www.cod.edu/",
  },
  {
    country: "US",
    name: "College of Idaho",
    url: "http://www.collegeofidaho.edu/",
  },
  {
    country: "US",
    name: "College of Mount St. Joseph",
    url: "http://www.msj.edu/",
  },
  {
    country: "US",
    name: "College of Mount St. Vincent",
    url: "http://www.cmsv.edu/",
  },
  {
    country: "US",
    name: "College of New Rochelle",
    url: "http://www.cnr.edu/",
  },
  {
    country: "US",
    name: "College of Notre Dame",
    url: "http://www.cnd.edu/",
  },
  {
    country: "US",
    name: "College of Notre Dame of Maryland",
    url: "http://www.ndm.edu/",
  },
  {
    country: "US",
    name: "College of Osteopathic Medicine of the Pacific (Western University of Health Sciences)",
    url: "http://www.westernu.edu/comp.html",
  },
  {
    country: "US",
    name: "College of St. Benedict and St. John's University",
    url: "http://www.csbsju.edu/",
  },
  {
    country: "US",
    name: "College of St. Catherine",
    url: "http://www.stkate.edu/",
  },
  {
    country: "US",
    name: "College of St. Elizabeth",
    url: "http://www.st-elizabeth.edu/",
  },
  {
    country: "US",
    name: "College of St. Joseph",
    url: "http://www.csj.edu/",
  },
  {
    country: "US",
    name: "College of St. Mary",
    url: "http://www.csm.edu/",
  },
  {
    country: "US",
    name: "College of St. Rose",
    url: "http://www.strose.edu/",
  },
  {
    country: "US",
    name: "College of St. Scholastica",
    url: "http://www.css.edu/",
  },
  {
    country: "US",
    name: "College of the Atlantic",
    url: "http://www.coa.edu/",
  },
  {
    country: "US",
    name: "College of the Holy Cross",
    url: "http://www.holycross.edu/",
  },
  {
    country: "US",
    name: "College of the Ozarks",
    url: "http://www.cofo.edu/",
  },
  {
    country: "US",
    name: "College of Visual Arts",
    url: "http://www.cva.edu/",
  },
  {
    country: "US",
    name: "College of William and Mary",
    url: "http://www.wm.edu/",
  },
  {
    country: "US",
    name: "Colorado Christian University",
    url: "http://www.ccu.edu/",
  },
  {
    country: "US",
    name: "Colorado College",
    url: "http://www.cc.colorado.edu/",
  },
  {
    country: "US",
    name: "Colorado School of Mines",
    url: "http://www.mines.edu/",
  },
  {
    country: "US",
    name: "Colorado State University",
    url: "http://www.colostate.edu/",
  },
  {
    country: "US",
    name: "Colorado State University-Pueblo",
    url: "http://www.colostate-pueblo.edu/",
  },
  {
    country: "US",
    name: "Colorado Technical University",
    url: "http://www.colotechu.edu/",
  },
  {
    country: "US",
    name: "Columbia College Chicago",
    url: "http://www.colum.edu/",
  },
  {
    country: "US",
    name: "Columbia College Hollywood",
    url: "http://www.columbiacollege.edu/",
  },
  {
    country: "US",
    name: "Columbia College of Missouri",
    url: "http://www.ccis.edu/",
  },
  {
    country: "US",
    name: "Columbia College South Carolina",
    url: "http://www.colacoll.edu/",
  },
  {
    country: "US",
    name: "Columbia Commonwealth University",
    url: "http://www.ccwu.edu/",
  },
  {
    country: "US",
    name: "Columbia International University",
    url: "http://www.ciu.edu/",
  },
  {
    country: "US",
    name: "Columbia Southern University",
    url: "http://www.columbiasouthern.edu/",
  },
  {
    country: "US",
    name: "Columbia Union College",
    url: "http://www.cuc.edu/",
  },
  {
    country: "US",
    name: "Columbia University",
    url: "http://www.columbia.edu/",
  },
  {
    country: "US",
    name: "Columbus College of Art and Design",
    url: "http://www.ccad.edu/",
  },
  {
    country: "US",
    name: "Columbus State University",
    url: "http://www.colstate.edu/",
  },
  {
    country: "US",
    name: "Columbus University",
    url: "http://www.columbusu.com/",
  },
  {
    country: "US",
    name: "Community College of Denver",
    url: "http://ccd.rightchoice.org/",
  },
  {
    country: "US",
    name: "Concord College",
    url: "http://www.concord.edu/",
  },
  {
    country: "US",
    name: "Concordia College, Ann Arbor",
    url: "http://www.ccaa.edu/",
  },
  {
    country: "US",
    name: "Concordia College, Bronxville",
    url: "http://www.concordia-ny.edu/",
  },
  {
    country: "US",
    name: "Concordia College, Moorhead",
    url: "http://www.cord.edu/",
  },
  {
    country: "US",
    name: "Concordia College, Selma",
    url: "http://higher-ed.lcms.org/selma.htm",
  },
  {
    country: "US",
    name: "Concordia College, Seward",
    url: "http://www.cune.edu/",
  },
  {
    country: "US",
    name: "Concordia College, St. Paul",
    url: "http://www.csp.edu/",
  },
  {
    country: "US",
    name: "Concordia University, Austin",
    url: "http://www.concordia.edu/",
  },
  {
    country: "US",
    name: "Concordia University, Irvine",
    url: "http://www.cui.edu/",
  },
  {
    country: "US",
    name: "Concordia University, Mequon",
    url: "http://www.cuw.edu/",
  },
  {
    country: "US",
    name: "Concordia University, Portland",
    url: "http://www.cu-portland.edu/",
  },
  {
    country: "US",
    name: "Concordia University, River Forest",
    url: "http://www.curf.edu/",
  },
  {
    country: "US",
    name: "Connecticut College",
    url: "http://www.conncoll.edu/",
  },
  {
    country: "US",
    name: "Converse College",
    url: "http://www.converse.edu/",
  },
  {
    country: "US",
    name: "Conway School of Landscape Design",
    url: "http://www.csld.edu/",
  },
  {
    country: "US",
    name: "Coppin State College",
    url: "http://www.coppin.edu/",
  },
  {
    country: "US",
    name: "Cornell College",
    url: "http://www.cornell-iowa.edu/",
  },
  {
    country: "US",
    name: "Cornell University",
    url: "http://www.cornell.edu/",
  },
  {
    country: "US",
    name: "Cornish College of the Arts",
    url: "http://www.cornish.edu/",
  },
  {
    country: "US",
    name: "Cosmopolitan University",
    url: "http://www.cosmoedu.net/",
  },
  {
    country: "US",
    name: "Covenant College",
    url: "http://www.covenant.edu/",
  },
  {
    country: "US",
    name: "Cranbrook Academy of Art",
    url: "http://www.cranbrookart.edu/",
  },
  {
    country: "US",
    name: "Creighton University",
    url: "http://www.creighton.edu/",
  },
  {
    country: "US",
    name: "Crichton College",
    url: "http://www.crichton.edu/",
  },
  {
    country: "US",
    name: "Crown College",
    url: "http://www.crown.edu/",
  },
  {
    country: "US",
    name: "Culver-Stockton College",
    url: "http://www.culver.edu/",
  },
  {
    country: "US",
    name: "Cumberland College",
    url: "http://www.cumber.edu/",
  },
  {
    country: "US",
    name: "Cumberland University",
    url: "http://www.cumberland.edu/",
  },
  {
    country: "US",
    name: "Curry College",
    url: "http://www.curry.edu:8080/",
  },
  {
    country: "US",
    name: "Daemen College",
    url: "http://www.daemen.edu/",
  },
  {
    country: "US",
    name: "Dakota State University",
    url: "http://www.dsu.edu/",
  },
  {
    country: "US",
    name: "Dakota Wesleyan University",
    url: "http://www.dwu.edu/",
  },
  {
    country: "US",
    name: "Dallas Baptist University",
    url: "http://www.dbu.edu/",
  },
  {
    country: "US",
    name: "Dallas Christian College",
    url: "http://www.dallas.edu/",
  },
  {
    country: "US",
    name: "Dana College",
    url: "http://www.dana.edu/",
  },
  {
    country: "US",
    name: "Daniel Webster College",
    url: "http://www.dwc.edu/",
  },
  {
    country: "US",
    name: "Danville Area Community College",
    url: "http://www.dacc.cc.il.us/",
  },
  {
    country: "US",
    name: "Dartmouth College",
    url: "http://www.dartmouth.edu/",
  },
  {
    country: "US",
    name: "Darton College",
    url: "http://www.darton.edu/",
  },
  {
    country: "US",
    name: "Davenport College of Business, Grand Rapids",
    url: "http://www.davenport.edu/grandrapids/",
  },
  {
    country: "US",
    name: "Davenport College of Business, Kalamazoo",
    url: "http://www.davenport.edu/kalamazoo/",
  },
  {
    country: "US",
    name: "Davenport College of Business, Lansing",
    url: "http://www.davenport.edu/lansing/",
  },
  {
    country: "US",
    name: "Davidson College",
    url: "http://www.davidson.edu/",
  },
  {
    country: "US",
    name: "Davis and Elkins College",
    url: "http://www.dne.edu/",
  },
  {
    country: "US",
    name: "Deaconess College of Nursing",
    url: "http://www.deaconess.edu/",
  },
  {
    country: "US",
    name: "Delaware State University",
    url: "http://www.dsc.edu/",
  },
  {
    country: "US",
    name: "Delaware Valley College",
    url: "http://www.devalcol.edu/",
  },
  {
    country: "US",
    name: "Delta International University",
    url: "http://www.delta-university.org/",
  },
  {
    country: "US",
    name: "Delta State University",
    url: "http://www.deltast.edu/",
  },
  {
    country: "US",
    name: "Denison University",
    url: "http://www.denison.edu/",
  },
  {
    country: "US",
    name: "Denver Paralegal Institute",
    url: "http://www.paralegal-education.com/campuses/cosprings/",
  },
  {
    country: "US",
    name: "Denver Technical College",
    url: "http://www.dtc.edu/",
  },
  {
    country: "US",
    name: "DePaul University",
    url: "http://www.depaul.edu/",
  },
  {
    country: "US",
    name: "DePauw University",
    url: "http://www.depauw.edu/",
  },
  {
    country: "US",
    name: "DeSales University",
    url: "http://www.desales.edu/",
  },
  {
    country: "US",
    name: "Design Institute of San Diego",
    url: "http://www.disd.edu/",
  },
  {
    country: "US",
    name: "Detroit College of Business",
    url: "http://www.dcb.edu/",
  },
  {
    country: "US",
    name: "Detroit College of Business - Flint",
    url: "http://www.davenport.edu/e3front.dll?durki=1283",
  },
  {
    country: "US",
    name: "Detroit College of Business - Warren",
    url: "http://www.davenport.edu/e3front.dll?durki=108",
  },
  {
    country: "US",
    name: "Detroit College of Law",
    url: "http://www.dcl.edu/",
  },
  {
    country: "US",
    name: "DeVry Institute of Technology, Chicago",
    url: "http://www.chi.devry.edu/",
  },
  {
    country: "US",
    name: "DeVry Institute of Technology, Columbus",
    url: "http://www.devrycols.edu/",
  },
  {
    country: "US",
    name: "DeVry Institute of Technology, Decatur",
    url: "http://www.atl.devry.edu/",
  },
  {
    country: "US",
    name: "DeVry Institute of Technology, DuPage",
    url: "http://www.dpg.devry.edu/",
  },
  {
    country: "US",
    name: "DeVry Institute of Technology, Irving",
    url: "http://www.dal.devry.edu/",
  },
  {
    country: "US",
    name: "DeVry Institute of Technology, Kansas City",
    url: "http://www.kc.devry.edu/",
  },
  {
    country: "US",
    name: "DeVry Institute of Technology, Phoenix",
    url: "http://www.phx.devry.edu/",
  },
  {
    country: "US",
    name: "DeVry Institute of Technology, Pomona",
    url: "http://www.pom.devry.edu/",
  },
  {
    country: "US",
    name: "Dickinson College",
    url: "http://www.dickinson.edu/",
  },
  {
    country: "US",
    name: "Dickinson State University",
    url: "http://www.dsu.nodak.edu/",
  },
  {
    country: "US",
    name: "Dillard University",
    url: "http://www.dillard.edu/",
  },
  {
    country: "US",
    name: "Divine Word College",
    url: "http://www.svd.org",
  },
  {
    country: "US",
    name: "Dixie College",
    url: "http://www.dixie.edu/",
  },
  {
    country: "US",
    name: "Doane College",
    url: "http://www.doane.edu/",
  },
  {
    country: "US",
    name: "Dominican College",
    url: "http://www.dc.edu/",
  },
  {
    country: "US",
    name: "Dominican College of San Rafael",
    url: "http://www.dominican.edu/",
  },
  {
    country: "US",
    name: "Dominican School of Philosophy and Theology",
    url: "http://www.dspt.edu/",
  },
  {
    country: "US",
    name: "Dominican University",
    url: "http://www.dom.edu/",
  },
  {
    country: "US",
    name: "Dominion College",
    url: "http://www.dominion.edu/",
  },
  {
    country: "US",
    name: "Dordt College",
    url: "http://www.dordt.edu/",
  },
  {
    country: "US",
    name: "Dowling College",
    url: "http://www.dowling.edu/",
  },
  {
    country: "US",
    name: "Drake University",
    url: "http://www.drake.edu/",
  },
  {
    country: "US",
    name: "Drew University",
    url: "http://www.drew.edu/",
  },
  {
    country: "US",
    name: "Drexel University",
    url: "http://www.drexel.edu/",
  },
  {
    country: "US",
    name: "Drury College",
    url: "http://www.drury.edu/",
  },
  {
    country: "US",
    name: "Duke University",
    url: "http://www.duke.edu/",
  },
  {
    country: "US",
    name: "Duluth Business University",
    url: "http://www.dbumn.edu/",
  },
  {
    country: "US",
    name: "Duquesne University",
    url: "http://www.duq.edu/",
  },
  {
    country: "US",
    name: "D'Youville College",
    url: "http://www.dyc.edu/",
  },
  {
    country: "US",
    name: "Earlham College",
    url: "http://www.earlham.edu/",
  },
  {
    country: "US",
    name: "EarthNet Institute",
    url: "http://www.eni.edu/",
  },
  {
    country: "US",
    name: "East Carolina University",
    url: "http://www.ecu.edu/",
  },
  {
    country: "US",
    name: "East Central University",
    url: "http://www.ecok.edu/",
  },
  {
    country: "US",
    name: "Eastern College",
    url: "http://www.eastern.edu/",
  },
  {
    country: "US",
    name: "Eastern Connecticut State University",
    url: "http://www.ecsu.ctstateu.edu/",
  },
  {
    country: "US",
    name: "Eastern Conservatory of Music",
    url: "http://easternconservatory-music.org/",
  },
  {
    country: "US",
    name: "Eastern Illinois University",
    url: "http://www.eiu.edu/",
  },
  {
    country: "US",
    name: "Eastern Kentucky University",
    url: "http://www.eku.edu/",
  },
  {
    country: "US",
    name: "Eastern Mennonite University",
    url: "http://www.emu.edu/",
  },
  {
    country: "US",
    name: "Eastern Michigan University",
    url: "http://www.emich.edu/",
  },
  {
    country: "US",
    name: "Eastern Nazarene College",
    url: "http://www.enc.edu/",
  },
  {
    country: "US",
    name: "Eastern New Mexico University",
    url: "http://www.enmu.edu/",
  },
  {
    country: "US",
    name: "Eastern Oregon University",
    url: "http://www.eou.edu/",
  },
  {
    country: "US",
    name: "Eastern Virginia Medical School",
    url: "http://www.evms.edu/",
  },
  {
    country: "US",
    name: "Eastern Washington University",
    url: "http://www.ewu.edu/",
  },
  {
    country: "US",
    name: "East Stroudsburg State University",
    url: "http://www.esu.edu/",
  },
  {
    country: "US",
    name: "East Tennessee State University",
    url: "http://www.etsu.edu/",
  },
  {
    country: "US",
    name: "East Texas Baptist University",
    url: "http://www.etbu.edu/",
  },
  {
    country: "US",
    name: "East-West University",
    url: "http://www.eastwest.edu/",
  },
  {
    country: "US",
    name: "Eckerd College",
    url: "http://www.eckerd.edu/",
  },
  {
    country: "US",
    name: "Edgewood College",
    url: "http://www.edgewood.edu/",
  },
  {
    country: "US",
    name: "Edinboro University of Pennsylvania",
    url: "http://www.edinboro.edu/",
  },
  {
    country: "US",
    name: "Edison Community College",
    url: "http://www.edison.edu/",
  },
  {
    country: "US",
    name: "Edward Waters College",
    url: "http://www.ewc.edu/",
  },
  {
    country: "US",
    name: "Elizabeth City State University",
    url: "http://www.ecsu.edu/",
  },
  {
    country: "US",
    name: "Elizabethtown College",
    url: "http://www.etown.edu/",
  },
  {
    country: "US",
    name: "Elmhurst College",
    url: "http://www.elmhurst.edu/",
  },
  {
    country: "US",
    name: "Elmira College",
    url: "http://www.elmira.edu/",
  },
  {
    country: "US",
    name: "Elms College",
    url: "http://www.elms.edu/",
  },
  {
    country: "US",
    name: "Elon College",
    url: "http://www.elon.edu/",
  },
  {
    country: "US",
    name: "Embry-Riddle Aeronautical University",
    url: "http://www.embryriddle.edu/",
  },
  {
    country: "US",
    name: "Embry Riddle Aeronautical University, Prescott",
    url: "http://www.prescott.erau.edu/",
  },
  {
    country: "US",
    name: "Emerson College",
    url: "http://www.emerson.edu/",
  },
  {
    country: "US",
    name: "Emmanuel College",
    url: "http://www.emmanuel.edu/",
  },
  {
    country: "US",
    name: "Emmanuel College Georgia",
    url: "http://www.emmanuel-college.edu/",
  },
  {
    country: "US",
    name: "Emmaus Bible College",
    url: "http://www.emmaus.edu/",
  },
  {
    country: "US",
    name: "Emory & Henry College",
    url: "http://www.ehc.edu/",
  },
  {
    country: "US",
    name: "Emory University",
    url: "http://www.emory.edu/",
  },
  {
    country: "US",
    name: "Emporia State University",
    url: "http://www.emporia.edu/",
  },
  {
    country: "US",
    name: "Erskine College",
    url: "http://www.erskine.edu/",
  },
  {
    country: "US",
    name: "Eugene Bible College",
    url: "http://www.ebc.edu/",
  },
  {
    country: "US",
    name: "Eureka College",
    url: "http://www.eureka.edu/",
  },
  {
    country: "US",
    name: "Evangel University",
    url: "http://www.evangel.edu/",
  },
  {
    country: "US",
    name: "Evergreen State College",
    url: "http://www.evergreen.edu/",
  },
  {
    country: "US",
    name: "Excel College",
    url: "http://www.excelcollege.org/",
  },
  {
    country: "US",
    name: "Excelsior College",
    url: "http://www.regents.edu/",
  },
  {
    country: "US",
    name: "Fairfield University",
    url: "http://www.fairfield.edu/",
  },
  {
    country: "US",
    name: "Fairleigh Dickinson University, Florham-Madison Campus",
    url: "http://www.fdu.edu/visitorcenter/fmwelcome.html",
  },
  {
    country: "US",
    name: "Fairleigh Dickinson University, Teaneck-Hackensack Campus",
    url: "http://www.fdu.edu/visitorcenter/thwelcome.html",
  },
  {
    country: "US",
    name: "Fairmont State College",
    url: "http://www.fscwv.edu/",
  },
  {
    country: "US",
    name: "Fashion Institute of New York",
    url: "http://www.fitnyc.suny.edu/",
  },
  {
    country: "US",
    name: "Faulkner University",
    url: "http://www.faulkner.edu/",
  },
  {
    country: "US",
    name: "Fayetteville State University",
    url: "http://www.uncfsu.edu/",
  },
  {
    country: "US",
    name: "Felician College",
    url: "http://www.felician.edu/",
  },
  {
    country: "US",
    name: "Ferris State University",
    url: "http://www.ferris.edu/",
  },
  {
    country: "US",
    name: "Ferrum College",
    url: "http://www.ferrum.edu/",
  },
  {
    country: "US",
    name: "Fielding Institute",
    url: "http://www.fielding.edu/",
  },
  {
    country: "US",
    name: "Finch University of Health Sciences - The Chicago Medical School",
    url: "http://www.finchcms.edu/",
  },
  {
    country: "US",
    name: "Finlandia University",
    url: "http://www.finlandia.edu/",
  },
  {
    country: "US",
    name: "Fisher College",
    url: "http://www.fisher.edu/",
  },
  {
    country: "US",
    name: "Fisk University",
    url: "http://www.fisk.edu/",
  },
  {
    country: "US",
    name: "Fitchburg State College",
    url: "http://www.fsc.edu/",
  },
  {
    country: "US",
    name: "Flagler College",
    url: "http://www.flagler.edu/",
  },
  {
    country: "US",
    name: "Florida Agricultural and Mechanical University",
    url: "http://www.famu.edu/",
  },
  {
    country: "US",
    name: "Florida Atlantic University",
    url: "http://www.fau.edu/",
  },
  {
    country: "US",
    name: "Florida Christian College",
    url: "http://www.fcc.edu/",
  },
  {
    country: "US",
    name: "Florida Community College at Jacksonville",
    url: "http://www.fccj.org/",
  },
  {
    country: "US",
    name: "Florida Gulf Coast University",
    url: "http://www.fgcu.edu/",
  },
  {
    country: "US",
    name: "Florida Institute of Technology",
    url: "http://www.fit.edu/",
  },
  {
    country: "US",
    name: "Florida International University",
    url: "http://www.fiu.edu/",
  },
  {
    country: "US",
    name: "Florida Memorial College",
    url: "http://www.fmc.edu/",
  },
  {
    country: "US",
    name: "Florida Metropolitan University, Fort Lauderdale College",
    url: "http://www.fmu.edu/784/f-784.htm",
  },
  {
    country: "US",
    name: "Florida Metropolitan University, Tampa College",
    url: "http://www.fmu.edu/762/f-762.htm",
  },
  {
    country: "US",
    name: "Florida Metropolitan University, Tampa College Brandon",
    url: "http://www.fmu.edu/764/f-764.htm",
  },
  {
    country: "US",
    name: "Florida Metropolitan University, Tampa College Lakeland",
    url: "http://www.fmu.edu/765/f-765.htm",
  },
  {
    country: "US",
    name: "Florida Southern College",
    url: "http://www.flsouthern.edu/",
  },
  {
    country: "US",
    name: "Florida State University",
    url: "http://www.fsu.edu/",
  },
  {
    country: "US",
    name: "Florida University of Medicine",
    url: "http://www.fuom.us/",
  },
  {
    country: "US",
    name: "Fontbonne College",
    url: "http://www.fontbonne.edu/",
  },
  {
    country: "US",
    name: "Foothill-De Anza Community College District",
    url: "http://www.fhda.edu/",
  },
  {
    country: "US",
    name: "Fordham University",
    url: "http://www.fordham.edu/",
  },
  {
    country: "US",
    name: "Forest Institute of Professional Psychology",
    url: "http://www.forestinstitute.org/",
  },
  {
    country: "US",
    name: "Fort Hays State University",
    url: "http://www.fhsu.edu/",
  },
  {
    country: "US",
    name: "Fort Lewis College",
    url: "http://www.fortlewis.edu/",
  },
  {
    country: "US",
    name: "Fort Valley State College",
    url: "http://www.fvsc.peachnet.edu/",
  },
  {
    country: "US",
    name: "Framingham State College",
    url: "http://www.framingham.edu/",
  },
  {
    country: "US",
    name: "Franciscan School of Theology",
    url: "http://www.fst.edu/",
  },
  {
    country: "US",
    name: "Franciscan University of Steubenville",
    url: "http://www.franuniv.edu/",
  },
  {
    country: "US",
    name: "Francis Marion University",
    url: "http://www.fmarion.edu/",
  },
  {
    country: "US",
    name: "Franklin and Marshall College",
    url: "http://www.fandm.edu/",
  },
  {
    country: "US",
    name: "Franklin College",
    url: "http://www.franklincoll.edu/",
  },
  {
    country: "US",
    name: "Franklin Pierce College",
    url: "http://www.fpc.edu/",
  },
  {
    country: "US",
    name: "Franklin Pierce Law Center",
    url: "http://www.fplc.edu/",
  },
  {
    country: "US",
    name: "Franklin University",
    url: "http://www.franklin.edu/",
  },
  {
    country: "US",
    name: "Franklin W. Olin College of Engineering",
    url: "http://www.olin.edu/",
  },
  {
    country: "US",
    name: "Frank Lloyd Wright School of Architecture",
    url: "http://www.taliesin.edu/",
  },
  {
    country: "US",
    name: "Fred Hutchinson Cancer Research Center",
    url: "http://www.fhcrc.org/",
  },
  {
    country: "US",
    name: "Freed-Hardeman University",
    url: "http://www.fhu.edu/",
  },
  {
    country: "US",
    name: "Free University (Virginia Theological University)",
    url: "http://www.vtu.edu/",
  },
  {
    country: "US",
    name: "Free Will Baptist Bible College",
    url: "http://www.fwbbc.edu/",
  },
  {
    country: "US",
    name: "Fresno City College",
    url: "http://www.fresnocitycollege.edu/",
  },
  {
    country: "US",
    name: "Fresno Pacific University",
    url: "http://www.fresno.edu/",
  },
  {
    country: "US",
    name: "Friends University",
    url: "http://www.friends.edu/",
  },
  {
    country: "US",
    name: "Frostburg State University",
    url: "http://www.fsu.umd.edu/",
  },
  {
    country: "US",
    name: "Full Sail University",
    url: "http://www.fullsail.com/",
  },
  {
    country: "US",
    name: "Furman University",
    url: "http://www.furman.edu/",
  },
  {
    country: "US",
    name: "Gallaudet University",
    url: "http://www.gallaudet.edu/",
  },
  {
    country: "US",
    name: "Gannon University",
    url: "http://www.gannon.edu/",
  },
  {
    country: "US",
    name: "Gardner Webb University",
    url: "http://www.gardner-webb.edu/",
  },
  {
    country: "US",
    name: "Gemological Institute of America",
    url: "http://www.gia.edu/",
  },
  {
    country: "US",
    name: "Geneva College",
    url: "http://www.geneva.edu/",
  },
  {
    country: "US",
    name: "George Fox University",
    url: "http://www.georgefox.edu/",
  },
  {
    country: "US",
    name: "George Mason University",
    url: "http://www.gmu.edu/",
  },
  {
    country: "US",
    name: "Georgetown College",
    url: "http://www.georgetowncollege.edu/",
  },
  {
    country: "US",
    name: "Georgetown University",
    url: "http://www.georgetown.edu/",
  },
  {
    country: "US",
    name: "George Washington University",
    url: "http://www.gwu.edu/",
  },
  {
    country: "US",
    name: "George Wythe College",
    url: "http://www.gwc.edu/",
  },
  {
    country: "US",
    name: "Georgia Baptist College of Nursing",
    url: "http://www.gbcn.edu/",
  },
  {
    country: "US",
    name: "Georgia College & State University",
    url: "http://www.gcsu.edu/",
  },
  {
    country: "US",
    name: "Georgia Health Sciences University",
    url: "http://www.georgiahealth.edu/",
  },
  {
    country: "US",
    name: "Georgia Institute of Technology",
    url: "http://www.gatech.edu/",
  },
  {
    country: "US",
    name: "Georgian Court College",
    url: "http://www.georgian.edu/",
  },
  {
    country: "US",
    name: "Georgia School of Professional Psychology",
    url: "http://www.aspp.edu/ga.html",
  },
  {
    country: "US",
    name: "Georgia Southern University",
    url: "http://www.georgiasouthern.edu/",
  },
  {
    country: "US",
    name: "Georgia Southwestern University",
    url: "http://www.gsw.edu/",
  },
  {
    country: "US",
    name: "Georgia State University",
    url: "http://www.gsu.edu/",
  },
  {
    country: "US",
    name: "Gettysburg College",
    url: "http://www.gettysburg.edu/",
  },
  {
    country: "US",
    name: "Glendale University College of Law",
    url: "http://www.glendalelaw.edu/",
  },
  {
    country: "US",
    name: "Glenville State College",
    url: "http://www.glenville.wvnet.edu/",
  },
  {
    country: "US",
    name: "Goddard College",
    url: "http://www.goddard.edu/",
  },
  {
    country: "US",
    name: "God's Bible School and College",
    url: "http://www.gbs.edu/",
  },
  {
    country: "US",
    name: "Golden Gate University",
    url: "http://www.ggu.edu/",
  },
  {
    country: "US",
    name: "Goldey-Beacom College",
    url: "http://goldey.gbc.edu/",
  },
  {
    country: "US",
    name: "Gonzaga University",
    url: "http://www.gonzaga.edu/",
  },
  {
    country: "US",
    name: "Gordon College",
    url: "http://www.gordon.edu/",
  },
  {
    country: "US",
    name: "Gordon Conwell Theological Seminary",
    url: "http://www.gcts.edu/",
  },
  {
    country: "US",
    name: "Goshen College",
    url: "http://www.goshen.edu/",
  },
  {
    country: "US",
    name: "Goucher College",
    url: "http://www.goucher.edu/",
  },
  {
    country: "US",
    name: "Governors State University",
    url: "http://www.govst.edu/",
  },
  {
    country: "US",
    name: "Grace Bible College",
    url: "http://www.gbcol.edu/",
  },
  {
    country: "US",
    name: "Grace College",
    url: "http://www.grace.edu/",
  },
  {
    country: "US",
    name: "Graceland College",
    url: "http://www.graceland.edu/",
  },
  {
    country: "US",
    name: "Grace University",
    url: "http://www.graceu.edu/",
  },
  {
    country: "US",
    name: "Graduate Theological Union",
    url: "http://www.gtu.edu/",
  },
  {
    country: "US",
    name: "Grambling State University",
    url: "http://www.gram.edu/",
  },
  {
    country: "US",
    name: "Grand Canyon University",
    url: "http://www.gcu.edu/",
  },
  {
    country: "US",
    name: "Grand Valley State University",
    url: "http://www.gvsu.edu/",
  },
  {
    country: "US",
    name: "Grand View College",
    url: "http://www.gvc.edu/",
  },
  {
    country: "US",
    name: "Grantham University",
    url: "http://www.grantham.edu/",
  },
  {
    country: "US",
    name: "Gratz College",
    url: "http://www.gratzcollege.edu/",
  },
  {
    country: "US",
    name: "Great Lakes Christian College",
    url: "http://www.glcc.edu/",
  },
  {
    country: "US",
    name: "Green Mountain College",
    url: "http://www.greenmtn.edu/",
  },
  {
    country: "US",
    name: "Greensboro College",
    url: "http://www.gborocollege.edu/",
  },
  {
    country: "US",
    name: "Greenville College",
    url: "http://www.greenville.edu/",
  },
  {
    country: "US",
    name: "Grinnell College",
    url: "http://www.grinnell.edu/",
  },
  {
    country: "US",
    name: "Grove City College",
    url: "http://www.gcc.edu/",
  },
  {
    country: "US",
    name: "Guilford College",
    url: "http://www.guilford.edu/",
  },
  {
    country: "US",
    name: "Gustavus Adolphus College",
    url: "http://www.gac.edu/",
  },
  {
    country: "US",
    name: "Gwynedd-Mercy College",
    url: "http://www.gmc.edu/",
  },
  {
    country: "US",
    name: "Hagerstown Community College",
    url: "http://www.hcc.cc.md.us/",
  },
  {
    country: "US",
    name: "Hamilton College",
    url: "http://www.hamilton.edu/",
  },
  {
    country: "US",
    name: "Hamilton Technical College",
    url: "http://www.vca1.com/hamiltontech/",
  },
  {
    country: "US",
    name: "Hamline University",
    url: "http://www.hamline.edu/",
  },
  {
    country: "US",
    name: "Hampden-Sydney College",
    url: "http://www.hsc.edu/",
  },
  {
    country: "US",
    name: "Hampshire College",
    url: "http://www.hampshire.edu/",
  },
  {
    country: "US",
    name: "Hampton College",
    url: "http://www.hamptoncollege.org/",
  },
  {
    country: "US",
    name: "Hampton University",
    url: "http://www.hamptonu.edu/",
  },
  {
    country: "US",
    name: "Hannibal-Lagrange College",
    url: "http://www.hlg.edu/",
  },
  {
    country: "US",
    name: "Hanover College",
    url: "http://www.hanover.edu/",
  },
  {
    country: "US",
    name: "Harding University",
    url: "http://www.harding.edu/",
  },
  {
    country: "US",
    name: "Harding University Graduate School of Religion",
    url: "http://www.hugsr.edu/",
  },
  {
    country: "US",
    name: "Hardin-Simmons University",
    url: "http://www.hsutx.edu/",
  },
  {
    country: "US",
    name: "Harrington Institute of Interior Design",
    url: "http://www.interiordesign.edu/",
  },
  {
    country: "US",
    name: "Harris-Stowe State University",
    url: "http://www.hssu.edu/",
  },
  {
    country: "US",
    name: "Hartford College for Women",
    url: "http://www.hartford.edu/SchoolsColleges/HCW/HCW.html",
  },
  {
    country: "US",
    name: "Hartford Graduate Center (Rensselaer at Hartford)",
    url: "http://www.hgc.edu/",
  },
  {
    country: "US",
    name: "Hartwick College",
    url: "http://www.hartwick.edu/",
  },
  {
    country: "US",
    name: "Harvard University",
    url: "http://www.harvard.edu/",
  },
  {
    country: "US",
    name: "Harvey Mudd College",
    url: "http://www.hmc.edu/",
  },
  {
    country: "US",
    name: "Hastings College",
    url: "http://www.hastings.edu/",
  },
  {
    country: "US",
    name: "Haverford College",
    url: "http://www.haverford.edu/",
  },
  {
    country: "US",
    name: "Hawaii Pacific University",
    url: "http://www.hpu.edu/",
  },
  {
    country: "US",
    name: "Heartland Baptist Bible College",
    url: "http://www.hbbc.edu/",
  },
  {
    country: "US",
    name: "Hebrew College",
    url: "http://www.hebrewcollege.edu/",
  },
  {
    country: "US",
    name: "Heidelberg College",
    url: "http://www.heidelberg.edu/",
  },
  {
    country: "US",
    name: "Henderson State Univerisity",
    url: "http://www.hsu.edu/",
  },
  {
    country: "US",
    name: "Hendrix College",
    url: "http://www.hendrix.edu/",
  },
  {
    country: "US",
    name: "Heritage College",
    url: "http://www.heritage.edu/",
  },
  {
    country: "US",
    name: "Hesser College",
    url: "http://www.hesser.edu/",
  },
  {
    country: "US",
    name: "High Point University",
    url: "http://www.highpoint.edu/",
  },
  {
    country: "US",
    name: "Hilbert College",
    url: "http://www.hilbert.edu/",
  },
  {
    country: "US",
    name: "Hillsdale College",
    url: "http://www.hillsdale.edu/",
  },
  {
    country: "US",
    name: "Hiram College",
    url: "http://www.hiram.edu/",
  },
  {
    country: "US",
    name: "Hobart and William Smith Colleges",
    url: "http://www.hws.edu/",
  },
  {
    country: "US",
    name: "Hobe Sound Bible College",
    url: "http://www.hsbc.edu/",
  },
  {
    country: "US",
    name: "Hodges University",
    url: "http://www.hodges.edu/",
  },
  {
    country: "US",
    name: "Hofstra University",
    url: "http://www.hofstra.edu/",
  },
  {
    country: "US",
    name: "Hollins University",
    url: "http://www.hollins.edu/",
  },
  {
    country: "US",
    name: "Holy Apostles College",
    url: "http://www.holy-apostles.org/",
  },
  {
    country: "US",
    name: "Holy Cross College",
    url: "http://www.hcc-nd.edu/",
  },
  {
    country: "US",
    name: "Holy Family College",
    url: "http://www.hfc.edu/",
  },
  {
    country: "US",
    name: "Holy Names College",
    url: "http://www.hnc.edu/",
  },
  {
    country: "US",
    name: "Hood College",
    url: "http://www.hood.edu/",
  },
  {
    country: "US",
    name: "Hope College",
    url: "http://www.hope.edu/",
  },
  {
    country: "US",
    name: "Hope International University",
    url: "http://www.hiu.edu/",
  },
  {
    country: "US",
    name: "Houghton College",
    url: "http://www.houghton.edu/",
  },
  {
    country: "US",
    name: "Houston Baptist University",
    url: "http://www.hbu.edu/",
  },
  {
    country: "US",
    name: "Howard Payne University",
    url: "http://www.hputx.edu/",
  },
  {
    country: "US",
    name: "Howard University",
    url: "http://www.howard.edu/",
  },
  {
    country: "US",
    name: "Humboldt State University",
    url: "http://www.humboldt.edu/",
  },
  {
    country: "US",
    name: "Humphreys College",
    url: "http://www.humphreys.edu/",
  },
  {
    country: "US",
    name: "Huntington College",
    url: "http://www.huntcol.edu/",
  },
  {
    country: "US",
    name: "Huron University",
    url: "http://www.huron.edu/",
  },
  {
    country: "US",
    name: "Husson College",
    url: "http://www.husson.edu/",
  },
  {
    country: "US",
    name: "Huston-Tillotson College",
    url: "http://www.htc.edu/",
  },
  {
    country: "US",
    name: "ICI University",
    url: "http://www.ici.edu/",
  },
  {
    country: "US",
    name: "ICT College",
    url: "http://www.ictcollege.edu/",
  },
  {
    country: "US",
    name: "Idaho State University",
    url: "http://www.isu.edu/",
  },
  {
    country: "US",
    name: "Iglobal University",
    url: "http://www.iglobal.edu/",
  },
  {
    country: "US",
    name: "Illinois Benedictine University",
    url: "http://www.ben.edu/",
  },
  {
    country: "US",
    name: "Illinois College",
    url: "http://www.ic.edu/",
  },
  {
    country: "US",
    name: "Illinois College of Optometry",
    url: "http://www.ico.edu/",
  },
  {
    country: "US",
    name: "Illinois Institute of Technology",
    url: "http://www.iit.edu/",
  },
  {
    country: "US",
    name: "Illinois School of Professional Psychology - Chicago Campus",
    url: "http://www.aspp.edu/ilch.html",
  },
  {
    country: "US",
    name: "Illinois School of Professional Psychology - Meadows Campus",
    url: "http://www.aspp.edu/ilrm.html",
  },
  {
    country: "US",
    name: "Illinois State University",
    url: "http://www.ilstu.edu/",
  },
  {
    country: "US",
    name: "Illinois Valley Community College",
    url: "http://www.ivcc.edu/",
  },
  {
    country: "US",
    name: "Illinois Wesleyan University",
    url: "http://www.iwu.edu/",
  },
  {
    country: "US",
    name: "Immaculata University",
    url: "http://www.immaculata.edu/",
  },
  {
    country: "US",
    name: "IMPAC University",
    url: "http://www.impacu.edu/",
  },
  {
    country: "US",
    name: "Indiana Institute of Technologyy",
    url: "http://www.indtech.edu/",
  },
  {
    country: "US",
    name: "Indiana State University",
    url: "http://www.indstate.edu/",
  },
  {
    country: "US",
    name: "Indiana University at Bloomington",
    url: "http://www.iub.edu/",
  },
  {
    country: "US",
    name: "Indiana University at Kokomo",
    url: "http://www.iuk.edu/",
  },
  {
    country: "US",
    name: "Indiana University at South Bend",
    url: "http://www.iusb.edu/",
  },
  {
    country: "US",
    name: "Indiana University - East",
    url: "http://www.iue.indiana.edu/",
  },
  {
    country: "US",
    name: "Indiana University - Northwest",
    url: "http://www.iun.indiana.edu/",
  },
  {
    country: "US",
    name: "Indiana University of Pennsylvania",
    url: "http://www.iup.edu/",
  },
  {
    country: "US",
    name: "Indiana University-Purdue University at Columbus",
    url: "http://www.columbus.iupui.edu/",
  },
  {
    country: "US",
    name: "Indiana University-Purdue University at Fort Wayne",
    url: "http://www.ipfw.edu/",
  },
  {
    country: "US",
    name: "Indiana University-Purdue University at Indianapolis",
    url: "http://www.iupui.edu/",
  },
  {
    country: "US",
    name: "Indiana University - Southeast",
    url: "http://www.ius.indiana.edu/",
  },
  {
    country: "US",
    name: "Indiana University (System)",
    url: "http://www.indiana.edu/",
  },
  {
    country: "US",
    name: "Indiana Wesleyan University",
    url: "http://www.indwes.edu/",
  },
  {
    country: "US",
    name: "Institute of Clinical Social Work",
    url: "http://www.icsw.com/",
  },
  {
    country: "US",
    name: "Institute of Paper Science and Technology",
    url: "http://www.ipst.edu/",
  },
  {
    country: "US",
    name: "Institute of Textile Technology",
    url: "http://www.itt.edu/",
  },
  {
    country: "US",
    name: "Institute of Transpersonal Psychology",
    url: "http://www.itp.edu/",
  },
  {
    country: "US",
    name: "IntelliTec College - Grand Junction",
    url: "http://www.intelliteccollege.edu/",
  },
  {
    country: "US",
    name: "International Academy of  Merchandising and Design Chicago",
    url: "http://www.iamd.edu/",
  },
  {
    country: "US",
    name: "International Academy of  Merchandising and Design Tampa",
    url: "http://www.academy.edu/",
  },
  {
    country: "US",
    name: "International Bible College",
    url: "http://www.i-b-c.edu/",
  },
  {
    country: "US",
    name: "International College",
    url: "http://www.internationalcollege.edu/",
  },
  {
    country: "US",
    name: "Iona College",
    url: "http://www.iona.edu/",
  },
  {
    country: "US",
    name: "Iowa State University of Science and Technology",
    url: "http://www.iastate.edu/",
  },
  {
    country: "US",
    name: "Iowa Wesleyan College",
    url: "http://www.iwc.edu/",
  },
  {
    country: "US",
    name: "Ithaca College",
    url: "http://www.ithaca.edu/",
  },
  {
    country: "US",
    name: "ITT Technical Institute Fort Wayne",
    url: "http://www.itttech.edu/campus/",
  },
  {
    country: "US",
    name: "ITT Technical Institute Indianapolis",
    url: "http://www.itttech.edu/campus/",
  },
  {
    country: "US",
    name: "ITT Technical Institute Maitland",
    url: "http://www.itttech.edu/campus/",
  },
  {
    country: "US",
    name: "ITT Technical Institute Portland",
    url: "http://www.itttech.edu/campus/",
  },
  {
    country: "US",
    name: "ITT Technical Institute West Covina",
    url: "http://www.itttech.edu/campus/",
  },
  {
    country: "US",
    name: "Jackson State University",
    url: "http://www.jsums.edu/",
  },
  {
    country: "US",
    name: "Jacksonville State University",
    url: "http://www.jsu.edu/",
  },
  {
    country: "US",
    name: "Jacksonville University",
    url: "http://www.ju.edu/",
  },
  {
    country: "US",
    name: "James Madison University",
    url: "http://www.jmu.edu/",
  },
  {
    country: "US",
    name: "Jamestown College",
    url: "http://www.jc.edu/",
  },
  {
    country: "US",
    name: "Jarvis Christian College",
    url: "http://www.jarvis.edu/",
  },
  {
    country: "US",
    name: "John Brown University",
    url: "http://www.jbu.edu/",
  },
  {
    country: "US",
    name: "John Carroll University",
    url: "http://www.jcu.edu/",
  },
  {
    country: "US",
    name: "John F. Kennedy University",
    url: "http://www.jfku.edu/",
  },
  {
    country: "US",
    name: "John Marshall Law School",
    url: "http://www.jmls.edu/",
  },
  {
    country: "US",
    name: "John Paul the Great Catholic University",
    url: "http://www.jpcatholic.com/",
  },
  {
    country: "US",
    name: "Johns Hopkins University",
    url: "http://www.jhu.edu/",
  },
  {
    country: "US",
    name: "Johnson Bible College",
    url: "http://www.jbc.edu/",
  },
  {
    country: "US",
    name: "Johnson County Community College",
    url: "http://www.jccc.edu/",
  },
  {
    country: "US",
    name: "Johnson C. Smith University",
    url: "http://www.jcsu.edu/",
  },
  {
    country: "US",
    name: "Johnson State College",
    url: "http://www.jsc.vsc.edu/",
  },
  {
    country: "US",
    name: "Johnson & Wales University",
    url: "http://www.jwu.edu/",
  },
  {
    country: "US",
    name: "Johnson & Wales University, Charleston",
    url: "http://www.jwu.edu/charles/camp_charles.htm",
  },
  {
    country: "US",
    name: "John Wesley College",
    url: "http://www.johnwesley.edu/",
  },
  {
    country: "US",
    name: "Jones College",
    url: "http://www.jones.edu/",
  },
  {
    country: "US",
    name: "Jones International University",
    url: "http://www.jonesinternational.edu/",
  },
  {
    country: "US",
    name: "Judson College Elgin",
    url: "http://www.judson-il.edu/",
  },
  {
    country: "US",
    name: "Judson College Marion",
    url: "http://home.judson.edu/",
  },
  {
    country: "US",
    name: "Juniata College",
    url: "http://www.juniata.edu/",
  },
  {
    country: "US",
    name: "Kalamazoo College",
    url: "http://www.kzoo.edu/",
  },
  {
    country: "US",
    name: "Kankakee Community College",
    url: "http://www.kankakeecc.org/",
  },
  {
    country: "US",
    name: "Kansas City Art Institute",
    url: "http://www.kcai.edu/",
  },
  {
    country: "US",
    name: "Kansas State University",
    url: "http://www.ksu.edu/",
  },
  {
    country: "US",
    name: "Kansas Wesleyan University",
    url: "http://www.kwu.edu/",
  },
  {
    country: "US",
    name: "Kaplan University",
    url: "http://www.kaplan.edu/",
  },
  {
    country: "US",
    name: "Katharine Gibbs School",
    url: "http://www.kgibbs.com/",
  },
  {
    country: "US",
    name: "Kean University of New Jersey",
    url: "http://www.kean.edu/",
  },
  {
    country: "US",
    name: "Keck Graduate Institute of Applied Life Sciences",
    url: "http://www.kgi.edu/",
  },
  {
    country: "US",
    name: "Keene State College",
    url: "http://www.keene.edu/",
  },
  {
    country: "US",
    name: "Keller Graduate School of Management",
    url: "http://www.keller.edu/",
  },
  {
    country: "US",
    name: "Kendall College",
    url: "http://www.kendall.edu/",
  },
  {
    country: "US",
    name: "Kendall College of Art and Design",
    url: "http://www.kcad.edu/",
  },
  {
    country: "US",
    name: "Kennesaw State University",
    url: "http://www.kennesaw.edu/",
  },
  {
    country: "US",
    name: "Kent State University",
    url: "http://www.kent.edu/",
  },
  {
    country: "US",
    name: "Kent State University - Ashtabula",
    url: "http://www.ashtabula.kent.edu/",
  },
  {
    country: "US",
    name: "Kent State University - East Liverpool",
    url: "http://www.kenteliv.kent.edu/",
  },
  {
    country: "US",
    name: "Kent State University - Salem",
    url: "http://www.salem.kent.edu/",
  },
  {
    country: "US",
    name: "Kent State University - Stark",
    url: "http://www.stark.kent.edu/",
  },
  {
    country: "US",
    name: "Kent State University - Trumbull",
    url: "http://www.trumbull.kent.edu/",
  },
  {
    country: "US",
    name: "Kent State University - Tuscarawas",
    url: "http://www.tusc.kent.edu/",
  },
  {
    country: "US",
    name: "Kentucky Christian College",
    url: "http://www.kcc.edu/",
  },
  {
    country: "US",
    name: "Kentucky State University",
    url: "http://www.kysu.edu/",
  },
  {
    country: "US",
    name: "Kentucky Wesleyan College",
    url: "http://www.kwc.edu/",
  },
  {
    country: "US",
    name: "Kenyon College",
    url: "http://www.kenyon.edu/",
  },
  {
    country: "US",
    name: "Kettering University (GMI)",
    url: "http://www.kettering.edu/",
  },
  {
    country: "US",
    name: "Keuka College",
    url: "http://www.keuka.edu/",
  },
  {
    country: "US",
    name: "King College",
    url: "http://www.king.edu/",
  },
  {
    country: "US",
    name: "King's College",
    url: "http://www.kings.edu/",
  },
  {
    country: "US",
    name: "Kirksville College of Osteopathic Medicine",
    url: "http://www.kcom.edu/",
  },
  {
    country: "US",
    name: "Kirkwood Community College",
    url: "http://www.kirkwood.edu/",
  },
  {
    country: "US",
    name: "Knox College",
    url: "http://www.knox.edu/",
  },
  {
    country: "US",
    name: "Knoxville College",
    url: "http://www.knoxvillecollege.edu/",
  },
  {
    country: "US",
    name: "Kutztown University of Pennsylvania",
    url: "http://www.kutztown.edu/",
  },
  {
    country: "US",
    name: "Laboratory Institute of Merchandising",
    url: "http://www.limcollege.edu/",
  },
  {
    country: "US",
    name: "Lafayette College",
    url: "http://www.lafayette.edu/",
  },
  {
    country: "US",
    name: "Lagrange College",
    url: "http://www.lgc.edu/",
  },
  {
    country: "US",
    name: "Lake Erie College",
    url: "http://www.lakeerie.edu/",
  },
  {
    country: "US",
    name: "Lake Forest College",
    url: "http://www.lfc.edu/",
  },
  {
    country: "US",
    name: "Lake Forest Graduate School of Management",
    url: "http://www.lfgsm.edu/",
  },
  {
    country: "US",
    name: "Lakeland College",
    url: "http://www.lakeland.edu/",
  },
  {
    country: "US",
    name: "Lake Superior State University",
    url: "http://www.lssu.edu/",
  },
  {
    country: "US",
    name: "Lakeview College of Nursing",
    url: "http://www.lakeviewcol.edu/",
  },
  {
    country: "US",
    name: "Lamar University",
    url: "http://www.lamar.edu/",
  },
  {
    country: "US",
    name: "Lamar University - Port Arthur",
    url: "http://www.pa.lamar.edu/",
  },
  {
    country: "US",
    name: "Lambuth University",
    url: "http://www.lambuth.edu/",
  },
  {
    country: "US",
    name: "Lancaster Bible College",
    url: "http://www.lbc.edu/",
  },
  {
    country: "US",
    name: "Lander University",
    url: "http://www.lander.edu/",
  },
  {
    country: "US",
    name: "Lane College",
    url: "http://www.lanecollege.edu/",
  },
  {
    country: "US",
    name: "Langston University",
    url: "http://www.lunet.edu/",
  },
  {
    country: "US",
    name: "La Roche College",
    url: "http://www.laroche.edu/",
  },
  {
    country: "US",
    name: "La Salle University",
    url: "http://www.lasalle.edu/",
  },
  {
    country: "US",
    name: "Lasell College",
    url: "http://www.lasell.edu/",
  },
  {
    country: "US",
    name: "La Sierra University",
    url: "http://www.lasierra.edu/",
  },
  {
    country: "US",
    name: "Laurus Technical Institute",
    url: "http://www.laurus.edu/",
  },
  {
    country: "US",
    name: "Lawrence Technological University",
    url: "http://www.ltu.edu/",
  },
  {
    country: "US",
    name: "Lawrence University",
    url: "http://www.lawrence.edu/",
  },
  {
    country: "US",
    name: "Lebanon Valley College",
    url: "http://www.lvc.edu/",
  },
  {
    country: "US",
    name: "Lees-McRae College",
    url: "http://www.lmc.edu/",
  },
  {
    country: "US",
    name: "Lee University",
    url: "http://www.leeuniversity.edu/",
  },
  {
    country: "US",
    name: "Lehigh Univervsity",
    url: "http://www.lehigh.edu/",
  },
  {
    country: "US",
    name: "Le Moyne College",
    url: "http://www.lemoyne.edu/",
  },
  {
    country: "US",
    name: "Le Moyne-Owen College",
    url: "http://www.lemoyne-owen.edu/",
  },
  {
    country: "US",
    name: "Lenoir-Rhyne College",
    url: "http://www.lrc.edu/",
  },
  {
    country: "US",
    name: "Lesley University",
    url: "http://www.lesley.edu/",
  },
  {
    country: "US",
    name: "Letourneau University",
    url: "http://www.letu.edu/",
  },
  {
    country: "US",
    name: "Lewis and Clark College",
    url: "http://www.lclark.edu/",
  },
  {
    country: "US",
    name: "Lewis & Clark Community College",
    url: "http://www.lc.cc.il.us/",
  },
  {
    country: "US",
    name: "Lewis-Clark State College",
    url: "http://www.lcsc.edu/",
  },
  {
    country: "US",
    name: "Lewis University",
    url: "http://www.lewisu.edu/",
  },
  {
    country: "US",
    name: "Liberty University",
    url: "http://www.liberty.edu/",
  },
  {
    country: "US",
    name: "Life Chiropractic College West",
    url: "http://www.lifewest.edu/",
  },
  {
    country: "US",
    name: "Life University",
    url: "http://www.life.edu/",
  },
  {
    country: "US",
    name: "Limestone College",
    url: "http://www.limestone.edu/",
  },
  {
    country: "US",
    name: "Lincoln Memorial University",
    url: "http://www.lmunet.edu/",
  },
  {
    country: "US",
    name: "Lincoln University Missouri",
    url: "http://www.lincolnu.edu/",
  },
  {
    country: "US",
    name: "Lincoln University Pennsylvania",
    url: "http://www.lincoln.edu/",
  },
  {
    country: "US",
    name: "Lincoln University San Francisco",
    url: "http://www.lincolnuca.edu/",
  },
  {
    country: "US",
    name: "Lindenwood University",
    url: "http://www.lindenwood.edu/",
  },
  {
    country: "US",
    name: "Lindsey Wilson College",
    url: "http://www.lindsey.edu/",
  },
  {
    country: "US",
    name: "Linfield College",
    url: "http://www.linfield.edu/",
  },
  {
    country: "US",
    name: "Lipscomb University",
    url: "http://www.lipscomb.edu/",
  },
  {
    country: "US",
    name: "Livingstone College",
    url: "http://www.livingstone.edu/",
  },
  {
    country: "US",
    name: "Lock Haven University of Pennsylvania",
    url: "http://www.lhup.edu/",
  },
  {
    country: "US",
    name: "Logan College of Chiropractic",
    url: "http://www.logan.edu/",
  },
  {
    country: "US",
    name: "Loma Linda University",
    url: "http://www.llu.edu/",
  },
  {
    country: "US",
    name: "Long Island University",
    url: "http://www.liunet.edu/",
  },
  {
    country: "US",
    name: "Long Island University, C.W. Post Campus",
    url: "http://www.cwpost.liunet.edu/cwis/cwp/post.html",
  },
  {
    country: "US",
    name: "Long Island University, Southampton College",
    url: "http://www.southampton.liunet.edu/",
  },
  {
    country: "US",
    name: "Longwood College",
    url: "http://www.lwc.edu/",
  },
  {
    country: "US",
    name: "Loras College",
    url: "http://www.loras.edu/",
  },
  {
    country: "US",
    name: "Los Angeles College of Chiropractic",
    url: "http://www.lacc.edu/",
  },
  {
    country: "US",
    name: "Louisiana Baptist University",
    url: "http://www.lbu.edu/",
  },
  {
    country: "US",
    name: "Louisiana College",
    url: "http://www.lacollege.edu/",
  },
  {
    country: "US",
    name: "Louisiana State University and Agricultural and Mechanical College",
    url: "http://www.lsu.edu/",
  },
  {
    country: "US",
    name: "Louisiana State University at Alexandria",
    url: "http://www.lsua.edu/",
  },
  {
    country: "US",
    name: "Louisiana State University at Eunice",
    url: "http://www.lsue.edu/",
  },
  {
    country: "US",
    name: "Louisiana State University Health Sciences Center New Orleans",
    url: "http://www.lsuhsc.edu/",
  },
  {
    country: "US",
    name: "Louisiana State University in Shreveport",
    url: "http://www.lsus.edu/",
  },
  {
    country: "US",
    name: "Louisiana Tech University",
    url: "http://www.latech.edu/",
  },
  {
    country: "US",
    name: "Lourdes College",
    url: "http://www.lourdes.edu/",
  },
  {
    country: "US",
    name: "Loyola College in Maryland",
    url: "http://www.loyola.edu/",
  },
  {
    country: "US",
    name: "Loyola Marymount University",
    url: "http://www.lmu.edu/",
  },
  {
    country: "US",
    name: "Loyola University New Orleans",
    url: "http://www.loyno.edu/",
  },
  {
    country: "US",
    name: "Loyola University of Chicago",
    url: "http://www.luc.edu/",
  },
  {
    country: "US",
    name: "Lubbock Christian University",
    url: "http://www.lcu.edu/",
  },
  {
    country: "US",
    name: "Lutheran Bible Institute of Seattle",
    url: "http://www.lbi.edu/",
  },
  {
    country: "US",
    name: "Luther College",
    url: "http://www.luther.edu/",
  },
  {
    country: "US",
    name: "Lycoming College",
    url: "http://www.lycoming.edu/",
  },
  {
    country: "US",
    name: "Lynchburg College",
    url: "http://www.lynchburg.edu/",
  },
  {
    country: "US",
    name: "Lyndon State College",
    url: "http://www.lsc.vsc.edu/",
  },
  {
    country: "US",
    name: "Lynn University",
    url: "http://www.lynn.edu/",
  },
  {
    country: "US",
    name: "Lyon College",
    url: "http://www.lyon.edu/",
  },
  {
    country: "US",
    name: "Macalester College",
    url: "http://www.macalstr.edu/",
  },
  {
    country: "US",
    name: "MacMurray College",
    url: "http://www.mac.edu/",
  },
  {
    country: "US",
    name: "Macon State College",
    url: "http://www.maconstate.edu/",
  },
  {
    country: "US",
    name: "Madison University (Distance Education)",
    url: "http://www.madisonu.com/",
  },
  {
    country: "US",
    name: "Madonna University",
    url: "http://madonna2.siteobjects.com/pages/index.cfm",
  },
  {
    country: "US",
    name: "Maharishi University of Management",
    url: "http://www.mum.edu/",
  },
  {
    country: "US",
    name: "Maine College of Art",
    url: "http://www.meca.edu/",
  },
  {
    country: "US",
    name: "Maine Maritime Academy",
    url: "http://www.mainemaritime.edu/",
  },
  {
    country: "US",
    name: "Malone College",
    url: "http://www.malone.edu/",
  },
  {
    country: "US",
    name: "Manchester College",
    url: "http://www.manchester.edu/",
  },
  {
    country: "US",
    name: "Manhattan Christian College",
    url: "http://www.mccks.edu/",
  },
  {
    country: "US",
    name: "Manhattan College",
    url: "http://www.mancol.edu/",
  },
  {
    country: "US",
    name: "Manhattan School of Music",
    url: "http://www.msmnyc.edu/",
  },
  {
    country: "US",
    name: "Manhattanville College",
    url: "http://www.mville.edu/",
  },
  {
    country: "US",
    name: "Mankato State University",
    url: "http://www.mankato.msus.edu/",
  },
  {
    country: "US",
    name: "Mansfield University of Pennsylvania",
    url: "http://www.mnsfld.edu/",
  },
  {
    country: "US",
    name: "Maranatha Baptist Bible College",
    url: "http://www.mbbc.edu/",
  },
  {
    country: "US",
    name: "Marian College",
    url: "http://www.marian.edu/",
  },
  {
    country: "US",
    name: "Marian College of Fond Du Lac",
    url: "http://www.mariancoll.edu/",
  },
  {
    country: "US",
    name: "Marietta College",
    url: "http://www.marietta.edu/",
  },
  {
    country: "US",
    name: "Marist College",
    url: "http://www.marist.edu/",
  },
  {
    country: "US",
    name: "Marlboro College",
    url: "http://www.marlboro.edu/",
  },
  {
    country: "US",
    name: "Marquette University",
    url: "http://www.mu.edu/",
  },
  {
    country: "US",
    name: "Marshall University",
    url: "http://www.marshall.edu/",
  },
  {
    country: "US",
    name: "Mars Hill College",
    url: "http://www.mhc.edu/",
  },
  {
    country: "US",
    name: "Martin Luther College",
    url: "http://www.mlc-wels.edu/",
  },
  {
    country: "US",
    name: "Martin Methodist College",
    url: "http://www.martinmethodist.edu/",
  },
  {
    country: "US",
    name: "Martin University",
    url: "http://www.martin.edu/",
  },
  {
    country: "US",
    name: "Mary Baldwin College",
    url: "http://www.mbc.edu/",
  },
  {
    country: "US",
    name: "Marycrest International University",
    url: "http://www.mcrest.edu/",
  },
  {
    country: "US",
    name: "Marygrove College",
    url: "http://www.marygrove.edu/",
  },
  {
    country: "US",
    name: "Marylhurst University",
    url: "http://www.marylhurst.edu/",
  },
  {
    country: "US",
    name: "Marymount College New York",
    url: "http://www.marymt.edu/",
  },
  {
    country: "US",
    name: "Marymount Manhattan College",
    url: "http://marymount.mmm.edu/",
  },
  {
    country: "US",
    name: "Marymount University",
    url: "http://www.marymount.edu/",
  },
  {
    country: "US",
    name: "Maryville College",
    url: "http://www.maryvillecollege.edu/",
  },
  {
    country: "US",
    name: "Maryville University of St. Louis",
    url: "http://www.maryvillestl.edu/",
  },
  {
    country: "US",
    name: "Mary Washington College",
    url: "http://www.mwc.edu/",
  },
  {
    country: "US",
    name: "Marywood University",
    url: "http://www.marywood.edu/",
  },
  {
    country: "US",
    name: "Massachusetts College of Art",
    url: "http://www.massart.edu/",
  },
  {
    country: "US",
    name: "Massachusetts College of Liberal Arts",
    url: "http://www.mcla.mass.edu/",
  },
  {
    country: "US",
    name: "Massachusetts College of Pharmacy and Allied Health Sciences",
    url: "http://www.mcp.edu/",
  },
  {
    country: "US",
    name: "Massachusetts Institute of Technology",
    url: "http://www.mit.edu/",
  },
  {
    country: "US",
    name: "Massachusetts Maritime Academy",
    url: "http://www.mma.mass.edu/",
  },
  {
    country: "US",
    name: "Massachusetts School of Professional Psychology",
    url: "http://www.mspp.edu/",
  },
  {
    country: "US",
    name: "Mayo Graduate School",
    url: "http://www.mayo.edu/mgs/gs.html",
  },
  {
    country: "US",
    name: "Mayo Medical School",
    url: "http://www.mayo.edu/education/mms/",
  },
  {
    country: "US",
    name: "Mayville State University",
    url: "http://www.masu.nodak.edu/",
  },
  {
    country: "US",
    name: "McKendree College",
    url: "http://www.mckendree.edu/",
  },
  {
    country: "US",
    name: "McMurry University",
    url: "http://www.mcm.edu/",
  },
  {
    country: "US",
    name: "McNeese State University",
    url: "http://www.mcneese.edu/",
  },
  {
    country: "US",
    name: "McPherson College",
    url: "http://www.mcpherson.edu/",
  },
  {
    country: "US",
    name: "Medaille College",
    url: "http://www.medaille.edu/",
  },
  {
    country: "US",
    name: "Medcenter One College of Nursing",
    url: "http://www.medcenterone.com/nursing/nursing.htm",
  },
  {
    country: "US",
    name: "Medical College of Georgia",
    url: "http://www.mcg.edu/",
  },
  {
    country: "US",
    name: "Medical College of Ohio",
    url: "http://www.mco.edu/",
  },
  {
    country: "US",
    name: "Medical College of Pennsylvania and Hahnemann University",
    url: "http://www.mcphu.edu/",
  },
  {
    country: "US",
    name: "Medical College of Wisconsin",
    url: "http://www.mcw.edu/",
  },
  {
    country: "US",
    name: "Medical University of South Carolina",
    url: "http://www.musc.edu/",
  },
  {
    country: "US",
    name: "Meharry Medical College",
    url: "http://www.mmc.edu/",
  },
  {
    country: "US",
    name: "Memphis College of Art",
    url: "http://www.mca.edu/",
  },
  {
    country: "US",
    name: "Menlo College",
    url: "http://www.menlo.edu/",
  },
  {
    country: "US",
    name: "Mennonite College of Nursing",
    url: "http://www.mcn.ilstu.edu/",
  },
  {
    country: "US",
    name: "Mercer University",
    url: "http://www.mercer.edu/",
  },
  {
    country: "US",
    name: "Mercer University, Cecil B. Day Campus",
    url: "http://www.mercer.edu/cbd/",
  },
  {
    country: "US",
    name: "Mercy College",
    url: "http://www.mercynet.edu/",
  },
  {
    country: "US",
    name: "Mercyhurst College",
    url: "http://www.mercyhurst.edu/",
  },
  {
    country: "US",
    name: "Meredith College",
    url: "http://www.meredith.edu/",
  },
  {
    country: "US",
    name: "Merrimack College",
    url: "http://www.merrimack.edu/",
  },
  {
    country: "US",
    name: "Mesa State College",
    url: "http://www.mesastate.edu/",
  },
  {
    country: "US",
    name: "Messiah College",
    url: "http://www.messiah.edu/",
  },
  {
    country: "US",
    name: "Methodist College",
    url: "http://www.methodist.edu/",
  },
  {
    country: "US",
    name: "Metropolitan State College of Denver",
    url: "http://www.mscd.edu/",
  },
  {
    country: "US",
    name: "Metropolitan State University",
    url: "http://www.metro.msus.edu/",
  },
  {
    country: "US",
    name: "MGH Institute of Health Professions",
    url: "http://www.mghihp.edu/",
  },
  {
    country: "US",
    name: "Miami Dade College",
    url: "http://www.mdc.edu/",
  },
  {
    country: "US",
    name: "Miami University of Ohio",
    url: "http://www.muohio.edu/",
  },
  {
    country: "US",
    name: "Miami University of Ohio - Hamilton",
    url: "http://www.ham.muohio.edu/",
  },
  {
    country: "US",
    name: "Miami University of Ohio - Middletown",
    url: "http://www.mid.muohio.edu/",
  },
  {
    country: "US",
    name: "Michigan School of Professional Psychology",
    url: "http://www.mispp.edu/",
  },
  {
    country: "US",
    name: "Michigan State University",
    url: "http://www.msu.edu/",
  },
  {
    country: "US",
    name: "Michigan Technological University",
    url: "http://www.mtu.edu/",
  },
  {
    country: "US",
    name: "Mid-America Nazarene University",
    url: "http://www.mnu.edu/",
  },
  {
    country: "US",
    name: "Mid-American Bible College",
    url: "http://www.concentric.net/~rkriesel/MBC/MBC.shtml",
  },
  {
    country: "US",
    name: "Mid-Continent Baptist Bible College",
    url: "http://www.mcbc.edu/",
  },
  {
    country: "US",
    name: "Middlebury College",
    url: "http://www.middlebury.edu/",
  },
  {
    country: "US",
    name: "Middle Tennessee State University",
    url: "http://www.mtsu.edu/",
  },
  {
    country: "US",
    name: "Midland Lutheran College",
    url: "http://www.mlc.edu/",
  },
  {
    country: "US",
    name: "Midway College",
    url: "http://www.midway.edu/",
  },
  {
    country: "US",
    name: "Midwestern State University",
    url: "http://www.mwsu.edu/",
  },
  {
    country: "US",
    name: "Midwestern University",
    url: "http://www.midwestern.edu/",
  },
  {
    country: "US",
    name: "Miles College",
    url: "http://www.miles.edu/",
  },
  {
    country: "US",
    name: "Millennia Atlantic University",
    url: "http://www.maufl.edu/",
  },
  {
    country: "US",
    name: "Millersville University of Pennsylvania",
    url: "http://www.millersv.edu/",
  },
  {
    country: "US",
    name: "Milligan College",
    url: "http://www.milligan.edu/",
  },
  {
    country: "US",
    name: "Millikin University",
    url: "http://www.millikin.edu/",
  },
  {
    country: "US",
    name: "Millsaps College",
    url: "http://www.millsaps.edu/",
  },
  {
    country: "US",
    name: "Mills College",
    url: "http://www.mills.edu/",
  },
  {
    country: "US",
    name: "Mills Grae University",
    url: "http://www.mgu.edu/",
  },
  {
    country: "US",
    name: "Milwaukee Institute of Art and Design",
    url: "http://www.miad.edu/",
  },
  {
    country: "US",
    name: "Milwaukee School of Engineering",
    url: "http://www.msoe.edu/",
  },
  {
    country: "US",
    name: "Minneapolis College of Art and Design",
    url: "http://www.mcad.edu/",
  },
  {
    country: "US",
    name: "Minnesota Bible College",
    url: "http://www.mnbc.edu/",
  },
  {
    country: "US",
    name: "Minnesota School of Professional Psychology",
    url: "http://www.aspp.edu/mn.html",
  },
  {
    country: "US",
    name: "Minot State University",
    url: "http://www.misu.nodak.edu/",
  },
  {
    country: "US",
    name: "Mississippi College",
    url: "http://www.mc.edu/",
  },
  {
    country: "US",
    name: "Mississippi State University",
    url: "http://www.msstate.edu/",
  },
  {
    country: "US",
    name: "Mississippi University for Women",
    url: "http://www.muw.edu/",
  },
  {
    country: "US",
    name: "Mississippi Valley State University",
    url: "http://www.mvsu.edu/",
  },
  {
    country: "US",
    name: "Missouri Baptist College",
    url: "http://www.mobap.edu/",
  },
  {
    country: "US",
    name: "Missouri Southern State College",
    url: "http://www.mssc.edu/",
  },
  {
    country: "US",
    name: "Missouri Tech",
    url: "http://www.motech.edu/",
  },
  {
    country: "US",
    name: "Missouri University of Science and Technology",
    url: "http://www.mst.edu/",
  },
  {
    country: "US",
    name: "Missouri Valley College",
    url: "http://www.murlin.com/~webfx/mvc/",
  },
  {
    country: "US",
    name: "Missouri Western State College",
    url: "http://www.mwsc.edu/",
  },
  {
    country: "US",
    name: "Molloy College",
    url: "http://www.molloy.edu/",
  },
  {
    country: "US",
    name: "Monmouth University",
    url: "http://www.monmouth.edu/",
  },
  {
    country: "US",
    name: "Montana State University",
    url: "http://www.montana.edu/",
  },
  {
    country: "US",
    name: "Montana State University - Billings",
    url: "http://www.msubillings.edu/",
  },
  {
    country: "US",
    name: "Montana State University - Northern",
    url: "http://www.msun.edu/",
  },
  {
    country: "US",
    name: "Montana Tech",
    url: "http://www.mtech.edu/",
  },
  {
    country: "US",
    name: "Montclair State University",
    url: "http://www.montclair.edu/",
  },
  {
    country: "US",
    name: "Monterey Institute of International Studies",
    url: "http://www.miis.edu/",
  },
  {
    country: "US",
    name: "Montreat College",
    url: "http://www.montreat.edu/",
  },
  {
    country: "US",
    name: "Montserrat College of Art",
    url: "http://www.montserrat.edu/",
  },
  {
    country: "US",
    name: "Moody Bible Institute",
    url: "http://www.moody.edu/",
  },
  {
    country: "US",
    name: "Moore College of Art and Design",
    url: "http://www.moore.edu/",
  },
  {
    country: "US",
    name: "Moorhead State University",
    url: "http://www.moorhead.msus.edu/",
  },
  {
    country: "US",
    name: "Moraine Valley Community College",
    url: "http://www.morainevalley.edu/",
  },
  {
    country: "US",
    name: "Moravian College",
    url: "http://www.moravian.edu/",
  },
  {
    country: "US",
    name: "Morehead State University",
    url: "http://www.morehead-st.edu/",
  },
  {
    country: "US",
    name: "Morehouse College",
    url: "http://www.morehouse.edu/",
  },
  {
    country: "US",
    name: "Morehouse School of Medicine",
    url: "http://www.msm.edu/",
  },
  {
    country: "US",
    name: "Morgan State University",
    url: "http://www.morgan.edu/",
  },
  {
    country: "US",
    name: "Morningside College",
    url: "http://www.morningside.edu/",
  },
  {
    country: "US",
    name: "Morris Brown College",
    url: "http://www.morrisbrown.edu/",
  },
  {
    country: "US",
    name: "Morris College",
    url: "http://www.scicu.org/morris/",
  },
  {
    country: "US",
    name: "Morrison College",
    url: "http://www.morrison.neumont.edu/",
  },
  {
    country: "US",
    name: "Mountain State University",
    url: "http://www.mountainstate.edu/",
  },
  {
    country: "US",
    name: "Mount Aloysius College",
    url: "http://www.mtaloy.edu/",
  },
  {
    country: "US",
    name: "Mount Carmel College of Nursing",
    url: "http://www.mccn.edu/",
  },
  {
    country: "US",
    name: "Mount Holyoke College",
    url: "http://www.mtholyoke.edu/",
  },
  {
    country: "US",
    name: "Mount Ida College",
    url: "http://www.mountida.edu/",
  },
  {
    country: "US",
    name: "Mount Marty College",
    url: "http://www.mtmc.edu/",
  },
  {
    country: "US",
    name: "Mount Mary College",
    url: "http://www.mtmary.edu/",
  },
  {
    country: "US",
    name: "Mount Mercy College",
    url: "http://www.mtmercy.edu/",
  },
  {
    country: "US",
    name: "Mount Olive College",
    url: "http://www.mountolivecollege.edu/",
  },
  {
    country: "US",
    name: "Mount Senario College",
    url: "http://www.mscfs.edu/",
  },
  {
    country: "US",
    name: "Mount Sinai School of Medicine",
    url: "http://www.mssm.edu/",
  },
  {
    country: "US",
    name: "Mount St. Clare College",
    url: "http://www.clare.edu/",
  },
  {
    country: "US",
    name: "Mount St. Mary College",
    url: "http://www.msmc.edu/",
  },
  {
    country: "US",
    name: "Mount St. Mary's College California",
    url: "http://www.msmc.la.edu/",
  },
  {
    country: "US",
    name: "Mount St. Mary's College Maryland",
    url: "http://www.msmary.edu/",
  },
  {
    country: "US",
    name: "Mount Union College",
    url: "http://www.mountunion.edu/",
  },
  {
    country: "US",
    name: "Mount Vernon College",
    url: "http://www.gwu.edu/~mvcgw/",
  },
  {
    country: "US",
    name: "Mount Vernon Nazarene College",
    url: "http://www.mvnc.edu/",
  },
  {
    country: "US",
    name: "Muhlenberg College",
    url: "http://www.muhlberg.edu/",
  },
  {
    country: "US",
    name: "Multnomah Bible College",
    url: "http://www.multnomah.edu/",
  },
  {
    country: "US",
    name: "Murray State University",
    url: "http://www.mursuky.edu/",
  },
  {
    country: "US",
    name: "Muskingum College",
    url: "http://www.muskingum.edu/",
  },
  {
    country: "US",
    name: "NAES College",
    url: "http://www.naes.indian.com/",
  },
  {
    country: "US",
    name: "National American University",
    url: "http://www.national.edu/",
  },
  {
    country: "US",
    name: "National American University, Albuquerque",
    url: "http://www.national.edu/albuquerque.html",
  },
  {
    country: "US",
    name: "National American University, Colorado Springs",
    url: "http://www.national.edu/col_springs.html",
  },
  {
    country: "US",
    name: "National American University, Denver",
    url: "http://www.national.edu/denver.html",
  },
  {
    country: "US",
    name: "National American University, Kansas City",
    url: "http://www.national.edu/kansas_city.html",
  },
  {
    country: "US",
    name: "National American University, Roseville",
    url: "http://www.national.edu/roseville.html",
  },
  {
    country: "US",
    name: "National American University, Sioux Falls",
    url: "http://www.national.edu/sioux_falls.html",
  },
  {
    country: "US",
    name: "National College of Chiropractic",
    url: "http://www.national.chiropractic.edu/",
  },
  {
    country: "US",
    name: "National College of Naturopathic Medicine",
    url: "http://www.ncnm.edu/",
  },
  {
    country: "US",
    name: "National Defense University",
    url: "http://www.ndu.edu/",
  },
  {
    country: "US",
    name: "National Hispanic University",
    url: "http://www.nhu.edu/",
  },
  {
    country: "US",
    name: "National-Louis University",
    url: "http://www.nl.edu/",
  },
  {
    country: "US",
    name: "National Technological University",
    url: "http://www.ntu.edu/",
  },
  {
    country: "US",
    name: "National Theatre Conservatory",
    url: "http://www.denvercenter.org/education/ed_ntc.htm",
  },
  {
    country: "US",
    name: "National University",
    url: "http://www.nu.edu/",
  },
  {
    country: "US",
    name: "Naval Postgraduate School",
    url: "http://www.nps.navy.mil/",
  },
  {
    country: "US",
    name: "Nazarene Bible College",
    url: "http://www.nbc.edu/",
  },
  {
    country: "US",
    name: "Nazareth College",
    url: "http://www.naz.edu/",
  },
  {
    country: "US",
    name: "Nebraska Christian College",
    url: "http://www.nechristian.edu/",
  },
  {
    country: "US",
    name: "Nebraska Methodist College of Nursing and Allied Health",
    url: "http://www.methodistcollege.edu/nurseover.html",
  },
  {
    country: "US",
    name: "Nebraska Wesleyan University",
    url: "http://www.nebrwesleyan.edu/",
  },
  {
    country: "US",
    name: "Neumann College",
    url: "http://www.neumann.edu/",
  },
  {
    country: "US",
    name: "Newberry College",
    url: "http://www.newberry.edu/",
  },
  {
    country: "US",
    name: "New College of California",
    url: "http://www.newcollege.edu/",
  },
  {
    country: "US",
    name: "New College of Florida",
    url: "http://www.ncf.edu/",
  },
  {
    country: "US",
    name: "New England College",
    url: "http://www.nec.edu/",
  },
  {
    country: "US",
    name: "New England College of Optometry",
    url: "http://www.ne-optometry.edu/",
  },
  {
    country: "US",
    name: "New England Conservatory of Music",
    url: "http://www.newenglandconservatory.edu/",
  },
  {
    country: "US",
    name: "New England Institute of Technology",
    url: "http://www.neit.edu/",
  },
  {
    country: "US",
    name: "New England School of Art and Design",
    url: "http://www.suffolk.edu/nesad/",
  },
  {
    country: "US",
    name: "New England School of Communications",
    url: "http://www.nescom.edu/",
  },
  {
    country: "US",
    name: "New England School of Law",
    url: "http://www.nesl.edu/",
  },
  {
    country: "US",
    name: "New Hampshire College",
    url: "http://www.nhc.edu/",
  },
  {
    country: "US",
    name: "New Jersey City University",
    url: "http://www.njcu.edu/",
  },
  {
    country: "US",
    name: "New Jersey Institute of Technology",
    url: "http://www.njit.edu/",
  },
  {
    country: "US",
    name: "Newman University",
    url: "http://www.ksnewman.edu/",
  },
  {
    country: "US",
    name: "New Mexico Highlands University",
    url: "http://www.nmhu.edu/",
  },
  {
    country: "US",
    name: "New Mexico Institute of Mining and Technology",
    url: "http://www.nmt.edu/",
  },
  {
    country: "US",
    name: "New Mexico State University",
    url: "http://www.nmsu.edu/",
  },
  {
    country: "US",
    name: "Newschool of Architecture and Design",
    url: "http://www.newschoolarch.edu/",
  },
  {
    country: "US",
    name: "New York Academy of Art, Graduate School of Figurative Art",
    url: "http://www.nyaa.edu/",
  },
  {
    country: "US",
    name: "New York Chiropractic College",
    url: "http://www.nycc.edu/",
  },
  {
    country: "US",
    name: "New York College of Podiatric Medicine",
    url: "http://www.nycpm.edu/",
  },
  {
    country: "US",
    name: "New York Film Academy",
    url: "http://www.nyfa.edu/",
  },
  {
    country: "US",
    name: "New York Institute of Technology",
    url: "http://www.nyit.edu/",
  },
  {
    country: "US",
    name: "New York Law School",
    url: "http://www.nyls.edu/",
  },
  {
    country: "US",
    name: "New York Medical College",
    url: "http://www.nymc.edu/",
  },
  {
    country: "US",
    name: "New York School of Interior Design",
    url: "http://www.nysid.edu/",
  },
  {
    country: "US",
    name: "New York University",
    url: "http://www.nyu.edu/",
  },
  {
    country: "US",
    name: "Niagara University",
    url: "http://www.niagara.edu/",
  },
  {
    country: "US",
    name: "Nicholls State University",
    url: "http://www.nicholls.edu/",
  },
  {
    country: "US",
    name: "Nichols College",
    url: "http://www.nichols.edu/",
  },
  {
    country: "US",
    name: "Norfolk State University",
    url: "http://www.nsu.edu/",
  },
  {
    country: "US",
    name: "North Carolina Agricultural and Technical State University",
    url: "http://www.ncat.edu/",
  },
  {
    country: "US",
    name: "North Carolina Central University",
    url: "http://www.nccu.edu/",
  },
  {
    country: "US",
    name: "North Carolina School of the Arts",
    url: "http://www.ncarts.edu/",
  },
  {
    country: "US",
    name: "North Carolina State University",
    url: "http://www.ncsu.edu/",
  },
  {
    country: "US",
    name: "North Carolina Wesleyan College",
    url: "http://www.ncwc.edu/",
  },
  {
    country: "US",
    name: "North Central Bible College",
    url: "http://www.ncbc.edu/",
  },
  {
    country: "US",
    name: "North Central College",
    url: "http://www.noctrl.edu/",
  },
  {
    country: "US",
    name: "Northcentral University",
    url: "http://www.ncu.edu/",
  },
  {
    country: "US",
    name: "North Dakota State University",
    url: "http://www.ndsu.nodak.edu/",
  },
  {
    country: "US",
    name: "Northeastern Illinois University",
    url: "http://www.neiu.edu/",
  },
  {
    country: "US",
    name: "Northeastern Ohio University College of Medicine",
    url: "http://www.neoucom.edu/",
  },
  {
    country: "US",
    name: "Northeastern State University",
    url: "http://www.nsuok.edu/",
  },
  {
    country: "US",
    name: "Northeastern University",
    url: "http://www.neu.edu/",
  },
  {
    country: "US",
    name: "Northern Arizona University",
    url: "http://www.nau.edu/",
  },
  {
    country: "US",
    name: "Northern Illinois University",
    url: "http://www.niu.edu/",
  },
  {
    country: "US",
    name: "Northern Kentucky University",
    url: "http://www.nku.edu/",
  },
  {
    country: "US",
    name: "Northern Michigan University",
    url: "http://www.nmu.edu/",
  },
  {
    country: "US",
    name: "Northern State University",
    url: "http://www.northern.edu/",
  },
  {
    country: "US",
    name: "Northern Virginia Community College",
    url: "http://www.nv.cc.va.us/",
  },
  {
    country: "US",
    name: "Northface University",
    url: "http://www.northface.edu/",
  },
  {
    country: "US",
    name: "North Georgia College",
    url: "http://www.ngc.peachnet.edu/",
  },
  {
    country: "US",
    name: "North Greenville College",
    url: "http://www.ngc.edu/",
  },
  {
    country: "US",
    name: "Northland College",
    url: "http://www.northland.edu/",
  },
  {
    country: "US",
    name: "North Park University",
    url: "http://www.northpark.edu/",
  },
  {
    country: "US",
    name: "Northwest Christian College",
    url: "http://www.nwcc.edu/",
  },
  {
    country: "US",
    name: "Northwest College of Art",
    url: "http://www.nca.edu/",
  },
  {
    country: "US",
    name: "Northwestern College Iowa",
    url: "http://www.nwciowa.edu/",
  },
  {
    country: "US",
    name: "Northwestern College of Chiropractic",
    url: "http://www.nwchiro.edu/",
  },
  {
    country: "US",
    name: "Northwestern College St. Paul",
    url: "http://www.nwc.edu/",
  },
  {
    country: "US",
    name: "Northwestern Oklahoma State University",
    url: "http://www.nwalva.edu/",
  },
  {
    country: "US",
    name: "Northwestern State University of Louisiana",
    url: "http://www.nsula.edu/",
  },
  {
    country: "US",
    name: "Northwestern University",
    url: "http://www.nwu.edu/",
  },
  {
    country: "US",
    name: "Northwest Missouri State University",
    url: "http://www.nwmissouri.edu/",
  },
  {
    country: "US",
    name: "Northwest Nazarene University",
    url: "http://www.nnu.edu/",
  },
  {
    country: "US",
    name: "Northwest University",
    url: "http://www.northwestu.edu/",
  },
  {
    country: "US",
    name: "Northwood University",
    url: "http://www.northwood.edu/",
  },
  {
    country: "US",
    name: "Northwood University, Florida Campus",
    url: "http://www.northwood.edu/campuses/florida/",
  },
  {
    country: "US",
    name: "Northwood University, Texas Campus",
    url: "http://www.northwood.edu/campuses/texas/",
  },
  {
    country: "US",
    name: "Norwich University",
    url: "http://www.norwich.edu/",
  },
  {
    country: "US",
    name: "Notre Dame College",
    url: "http://www.notredame.edu/",
  },
  {
    country: "US",
    name: "Notre Dame College of Ohio",
    url: "http://www.ndc.edu/",
  },
  {
    country: "US",
    name: "Notre Dame de Namur University",
    url: "http://www.ndnu.edu/",
  },
  {
    country: "US",
    name: "Nova Southeastern University",
    url: "http://www.nova.edu/",
  },
  {
    country: "US",
    name: "Nyack College",
    url: "http://www.nyackcollege.edu/",
  },
  {
    country: "US",
    name: "Oakland City University",
    url: "http://www.oak.edu/",
  },
  {
    country: "US",
    name: "Oakland University",
    url: "http://www.oakland.edu/",
  },
  {
    country: "US",
    name: "Oakton Community College",
    url: "http://www.oakton.edu/",
  },
  {
    country: "US",
    name: "Oakwood College",
    url: "http://www.oakwood.edu/",
  },
  {
    country: "US",
    name: "Oberlin College",
    url: "http://www.oberlin.edu/",
  },
  {
    country: "US",
    name: "Occidental College",
    url: "http://www.oxy.edu/",
  },
  {
    country: "US",
    name: "Oglala Lakota College",
    url: "http://www.olc.edu/",
  },
  {
    country: "US",
    name: "Oglethorpe University",
    url: "http://www.oglethorpe.edu/",
  },
  {
    country: "US",
    name: "Ohio College of Podiatric Medicine",
    url: "http://www.ocpm.edu/",
  },
  {
    country: "US",
    name: "Ohio Dominican College",
    url: "http://www.odc.edu/",
  },
  {
    country: "US",
    name: "Ohio Northern University",
    url: "http://www.onu.edu/",
  },
  {
    country: "US",
    name: "Ohio State University",
    url: "http://www.ohio-state.edu/",
  },
  {
    country: "US",
    name: "Ohio State University - Lima",
    url: "http://www.lima.ohio-state.edu/",
  },
  {
    country: "US",
    name: "Ohio State University - Mansfield",
    url: "http://www.mansfield.ohio-state.edu/",
  },
  {
    country: "US",
    name: "Ohio State University - Marion",
    url: "http://www.marion.ohio-state.edu/",
  },
  {
    country: "US",
    name: "Ohio State University - Newark",
    url: "http://www.newark.ohio-state.edu/",
  },
  {
    country: "US",
    name: "Ohio University",
    url: "http://www.ohiou.edu/",
  },
  {
    country: "US",
    name: "Ohio University - Chillicothe",
    url: "http://www.ohiou.edu/chillicothe/",
  },
  {
    country: "US",
    name: "Ohio University - Eastern",
    url: "http://www.eastern.ohiou.edu/",
  },
  {
    country: "US",
    name: "Ohio University - Lancaster",
    url: "http://www.lancaster.ohiou.edu/",
  },
  {
    country: "US",
    name: "Ohio University - Southern",
    url: "http://www.ohiou.edu/southern/",
  },
  {
    country: "US",
    name: "Ohio University - Zanesville",
    url: "http://www.zanesville.ohiou.edu/",
  },
  {
    country: "US",
    name: "Ohio Valley College",
    url: "http://www.ovc.edu/",
  },
  {
    country: "US",
    name: "Ohio Wesleyan University",
    url: "http://www.owu.edu/",
  },
  {
    country: "US",
    name: "Oklahoma Baptist University",
    url: "http://www.okbu.edu/",
  },
  {
    country: "US",
    name: "Oklahoma Christian University",
    url: "http://www.oc.edu/",
  },
  {
    country: "US",
    name: "Oklahoma City University",
    url: "http://www.okcu.edu/",
  },
  {
    country: "US",
    name: "Oklahoma Panhandle State University",
    url: "http://www.opsu.edu/",
  },
  {
    country: "US",
    name: "Oklahoma State University",
    url: "http://www.okstate.edu/",
  },
  {
    country: "US",
    name: "Oklahoma State University Center for Health Sciences",
    url: "http://osu.com.okstate.edu/",
  },
  {
    country: "US",
    name: "Oklahoma State University - Institute of Technology",
    url: "http://www.osuit.edu/",
  },
  {
    country: "US",
    name: "Oklahoma State University - Oklahoma City",
    url: "http://www.osuokc.edu/",
  },
  {
    country: "US",
    name: "Oklahoma State University - Tulsa",
    url: "http://www.osu-tulsa.okstate.edu/",
  },
  {
    country: "US",
    name: "Old Dominion University",
    url: "http://www.odu.edu/",
  },
  {
    country: "US",
    name: "Olive-Harvey College",
    url: "http://www.ccc.edu/oliveharvey/",
  },
  {
    country: "US",
    name: "Olivet College",
    url: "http://www.olivetcollege.edu/",
  },
  {
    country: "US",
    name: "Olivet Nazarene University",
    url: "http://www.olivet.edu/",
  },
  {
    country: "US",
    name: "O'More College of Design",
    url: "http://www.omorecollege.edu/",
  },
  {
    country: "US",
    name: "Oral Roberts University",
    url: "http://www.oru.edu/",
  },
  {
    country: "US",
    name: "Oregon College of Arts and Crafts",
    url: "http://www.ocac.edu/",
  },
  {
    country: "US",
    name: "Oregon Graduate Institute of Science and Technology",
    url: "http://www.ogi.edu/",
  },
  {
    country: "US",
    name: "Oregon Health Sciences University",
    url: "http://www.ohsu.edu/",
  },
  {
    country: "US",
    name: "Oregon Institute of Technology",
    url: "http://www.oit.edu/",
  },
  {
    country: "US",
    name: "Oregon State University",
    url: "http://www.orst.edu/",
  },
  {
    country: "US",
    name: "Otis College of Art & Design",
    url: "http://www.otisart.edu/",
  },
  {
    country: "US",
    name: "Ottawa University",
    url: "http://www.ottawa.edu/",
  },
  {
    country: "US",
    name: "Otterbein College",
    url: "http://www.otterbein.edu/",
  },
  {
    country: "US",
    name: "Ouachita Baptist University",
    url: "http://www.obu.edu/",
  },
  {
    country: "US",
    name: "Our Lady of Holy Cross College",
    url: "http://www.olhcc.edu/",
  },
  {
    country: "US",
    name: "Our Lady of the Lake University",
    url: "http://www.ollusa.edu/",
  },
  {
    country: "US",
    name: "Ozark Christian College",
    url: "http://www.occ.edu/",
  },
  {
    country: "US",
    name: "Pace University",
    url: "http://www.pace.edu/",
  },
  {
    country: "US",
    name: "Pace University  Pleasantville/Briarcliff",
    url: "http://www.pace.edu/campus/pville.html",
  },
  {
    country: "US",
    name: "Pacifica Graduate Institute",
    url: "http://www.pacifica.edu/",
  },
  {
    country: "US",
    name: "Pacific College of Oriental Medicine",
    url: "http://www.ormed.edu/",
  },
  {
    country: "US",
    name: "Pacific Graduate School of Psychology",
    url: "http://www.pgsp.edu/",
  },
  {
    country: "US",
    name: "Pacific Lutheran University",
    url: "http://www.plu.edu/",
  },
  {
    country: "US",
    name: "Pacific Northwest College of Art",
    url: "http://www.pnca.edu/",
  },
  {
    country: "US",
    name: "Pacific Oaks College",
    url: "http://www.pacificoaks.edu/",
  },
  {
    country: "US",
    name: "Pacific Union College",
    url: "http://www.puc.edu/",
  },
  {
    country: "US",
    name: "Pacific University",
    url: "http://www.pacificu.edu/",
  },
  {
    country: "US",
    name: "Paier College of Art",
    url: "http://www.paierart.com/",
  },
  {
    country: "US",
    name: "Paine College",
    url: "http://www.paine.edu/",
  },
  {
    country: "US",
    name: "Palm Beach Atlantic University",
    url: "http://www.pba.edu/",
  },
  {
    country: "US",
    name: "Palm Beach State College",
    url: "http://www.palmbeachstate.edu/",
  },
  {
    country: "US",
    name: "Palmer College of Chiropractic",
    url: "http://www.palmer.edu/",
  },
  {
    country: "US",
    name: "Palmer College of Chiropractic West",
    url: "http://www.palmer.edu/PCCW/pcwmain.htm",
  },
  {
    country: "US",
    name: "Park College",
    url: "http://www.park.edu/",
  },
  {
    country: "US",
    name: "Parsons School of Design",
    url: "http://www.parsons.edu/",
  },
  {
    country: "US",
    name: "Paul Quinn College",
    url: "http://www.pqc.edu/",
  },
  {
    country: "US",
    name: "Peace College",
    url: "http://www.peace.edu/",
  },
  {
    country: "US",
    name: "Pebble Hills University",
    url: "http://www.pebblehills.edu/",
  },
  {
    country: "US",
    name: "Pennsylvania Academy of the Fine Arts",
    url: "http://www.pafa.org/",
  },
  {
    country: "US",
    name: "Pennsylvania College of Optometry",
    url: "http://www.pco.edu/",
  },
  {
    country: "US",
    name: "Pennsylvania Institute of Technology",
    url: "http://www.pit.edu/",
  },
  {
    country: "US",
    name: "Pennsylvania State University",
    url: "http://www.psu.edu/",
  },
  {
    country: "US",
    name: "Pennsylvania State University - Abington",
    url: "http://www.abington.psu.edu/",
  },
  {
    country: "US",
    name: "Pennsylvania State University - Altoona",
    url: "http://www.aa.psu.edu/",
  },
  {
    country: "US",
    name: "Pennsylvania State University at Erie - Behrend College",
    url: "http://www.pserie.psu.edu/",
  },
  {
    country: "US",
    name: "Pennsylvania State University at Harrisburg - The Capital College",
    url: "http://www.hbg.psu.edu/",
  },
  {
    country: "US",
    name: "Pennsylvania State University - Berks-Lehigh Valley College",
    url: "http://www.bk.psu.edu/",
  },
  {
    country: "US",
    name: "Pennsylvania State University Delaware County",
    url: "http://www.de.psu.edu/",
  },
  {
    country: "US",
    name: "Pennsylvania State University Great Valley",
    url: "http://www.gv.psu.edu/",
  },
  {
    country: "US",
    name: "Pennsylvania State University - Lehigh Valley",
    url: "http://www.an.psu.edu/",
  },
  {
    country: "US",
    name: "Pennsylvania State University - Milton S.Hershey Medical Center",
    url: "http://www.collmed.psu.edu/",
  },
  {
    country: "US",
    name: "Pennsylvania State University - Schuylkill",
    url: "http://www.sl.psu.edu/",
  },
  {
    country: "US",
    name: "Pepperdine University",
    url: "http://www.pepperdine.edu/",
  },
  {
    country: "US",
    name: "Peru State College",
    url: "http://www.peru.edu/",
  },
  {
    country: "US",
    name: "Pfeiffer University",
    url: "http://www.pfeiffer.edu/",
  },
  {
    country: "US",
    name: "Philadelphia College of Bible",
    url: "http://www.pcb.edu/",
  },
  {
    country: "US",
    name: "Philadelphia College of Osteopathic Medicine",
    url: "http://www.pcom.edu/",
  },
  {
    country: "US",
    name: "Philadelphia University",
    url: "http://www.philau.edu/",
  },
  {
    country: "US",
    name: "Philander Smith College",
    url: "http://www.philander.edu/",
  },
  {
    country: "US",
    name: "Phillips Graduate Institute",
    url: "http://www.pgi.edu/",
  },
  {
    country: "US",
    name: "Phillips University",
    url: "http://www.phillips.edu/",
  },
  {
    country: "US",
    name: "Piedmont Baptist College",
    url: "http://www.pbc.edu/",
  },
  {
    country: "US",
    name: "Piedmont College",
    url: "http://www.piedmont.edu/",
  },
  {
    country: "US",
    name: "Pikeville College",
    url: "http://www.pc.edu/",
  },
  {
    country: "US",
    name: "Pillsbury Baptist Bible College",
    url: "http://www.pillsbury.edu/",
  },
  {
    country: "US",
    name: "Pittsburg State University",
    url: "http://www.pittstate.edu/",
  },
  {
    country: "US",
    name: "Pitzer College",
    url: "http://www.pitzer.edu/",
  },
  {
    country: "US",
    name: "Plymouth State College",
    url: "http://www.plymouth.edu/",
  },
  {
    country: "US",
    name: "Point Loma Nazarene College",
    url: "http://www.ptloma.edu/",
  },
  {
    country: "US",
    name: "Point Park College",
    url: "http://www.ppc.edu/",
  },
  {
    country: "US",
    name: "Polytechnic University",
    url: "http://www.poly.edu/",
  },
  {
    country: "US",
    name: "Polytechnic University, Long Island Campus",
    url: "http://www.poly.edu/li/",
  },
  {
    country: "US",
    name: "Polytechnic University, Westchester Graduate Center",
    url: "http://www.poly.edu/west/",
  },
  {
    country: "US",
    name: "Pomona College",
    url: "http://www.pomona.edu/",
  },
  {
    country: "US",
    name: "Portland Community College",
    url: "http://www.pcc.edu/",
  },
  {
    country: "US",
    name: "Portland State University",
    url: "http://www.pdx.edu/",
  },
  {
    country: "US",
    name: "Post University of Waterbury",
    url: "http://www.post.edu/",
  },
  {
    country: "US",
    name: "Prairie View Agricultural and Mechanical University",
    url: "http://www.pvamu.edu/",
  },
  {
    country: "US",
    name: "Pratt Institute",
    url: "http://www.pratt.edu/",
  },
  {
    country: "US",
    name: "Presbyterian College",
    url: "http://www.presby.edu/",
  },
  {
    country: "US",
    name: "Prescott College",
    url: "http://www.prescott.edu/",
  },
  {
    country: "US",
    name: "Preston University",
    url: "http://www.preston.edu/",
  },
  {
    country: "US",
    name: "Princeton University",
    url: "http://www.princeton.edu/",
  },
  {
    country: "US",
    name: "Principia College",
    url: "http://www.prin.edu/",
  },
  {
    country: "US",
    name: "Providence College",
    url: "http://www.providence.edu/",
  },
  {
    country: "US",
    name: "Puget Sound Christian College",
    url: "http://members.aa.net/~bluvase/pscchome.html",
  },
  {
    country: "US",
    name: "Purdue University",
    url: "http://www.purdue.edu/",
  },
  {
    country: "US",
    name: "Purdue University Calumet",
    url: "http://www.calumet.purdue.edu/",
  },
  {
    country: "US",
    name: "Purdue University North Central",
    url: "http://www.pnc.edu/",
  },
  {
    country: "US",
    name: "Quantum-Veritas International University",
    url: "http://www.qvius.edu/",
  },
  {
    country: "US",
    name: "Queens College",
    url: "http://www.queens.edu/",
  },
  {
    country: "US",
    name: "Quincy University",
    url: "http://www.quincy.edu/",
  },
  {
    country: "US",
    name: "Quinnipiac College",
    url: "http://www.quinnipiac.edu/",
  },
  {
    country: "US",
    name: "Radford University",
    url: "http://www.runet.edu/",
  },
  {
    country: "US",
    name: "Ramapo College of New Jersey",
    url: "http://www.ramapo.edu/",
  },
  {
    country: "US",
    name: "Rand Graduate School of Policy Studies",
    url: "http://www.rgs.edu/",
  },
  {
    country: "US",
    name: "Randolph-Macon College",
    url: "http://www.rmc.edu/",
  },
  {
    country: "US",
    name: "Randolph-Macon Woman's College",
    url: "http://www.rmwc.edu/",
  },
  {
    country: "US",
    name: "Rasmussen College",
    url: "http://www.rasmussen.edu/",
  },
  {
    country: "US",
    name: "Rasmussen College, Florida Campuses",
    url: "http://www.rasmussen.edu/locations/florida/",
  },
  {
    country: "US",
    name: "Rasmussen College, Illinois Campuses",
    url: "http://www.rasmussen.edu/locations/illinois/",
  },
  {
    country: "US",
    name: "Rasmussen College, Minnesota Campuses",
    url: "http://www.rasmussen.edu/locations/minnesota/",
  },
  {
    country: "US",
    name: "Rasmussen College, North Dakota Campuses",
    url: "http://www.rasmussen.edu/locations/north-dakota/",
  },
  {
    country: "US",
    name: "Rasmussen College, Wisconsin Campuses",
    url: "http://www.rasmussen.edu/locations/wisconsin/",
  },
  {
    country: "US",
    name: "Reed College",
    url: "http://www.reed.edu/",
  },
  {
    country: "US",
    name: "Reformed Bible College",
    url: "http://www.reformed.edu/",
  },
  {
    country: "US",
    name: "Regent International University",
    url: "http://www.regentinternational.net/",
  },
  {
    country: "US",
    name: "Regent University",
    url: "http://www.regent.edu/",
  },
  {
    country: "US",
    name: "Regis College",
    url: "http://www.regiscollege.edu/",
  },
  {
    country: "US",
    name: "Regis University",
    url: "http://www.regis.edu/",
  },
  {
    country: "US",
    name: "Reinhardt College",
    url: "http://www.reinhardt.edu/",
  },
  {
    country: "US",
    name: "Rensselaer Polytechnic Institute",
    url: "http://www.rpi.edu/",
  },
  {
    country: "US",
    name: "Research College of Nursing - Rockhurst University",
    url: "http://www.rockhurst.edu/3.0/academic_programs/nursing/admis3.html",
  },
  {
    country: "US",
    name: "Rhode Island College",
    url: "http://www.ric.edu/",
  },
  {
    country: "US",
    name: "Rhode Island School of Design",
    url: "http://www.risd.edu/",
  },
  {
    country: "US",
    name: "Rhodes College",
    url: "http://www.rhodes.edu/",
  },
  {
    country: "US",
    name: "Rice University",
    url: "http://www.rice.edu/",
  },
  {
    country: "US",
    name: "Richard Stockton College of New Jersey",
    url: "http://www.stockton.edu/",
  },
  {
    country: "US",
    name: "Rider University",
    url: "http://www.rider.edu/",
  },
  {
    country: "US",
    name: "Ringling College of Art and Design",
    url: "http://www.ringling.edu/",
  },
  {
    country: "US",
    name: "Ripon College",
    url: "http://www.ripon.edu/",
  },
  {
    country: "US",
    name: "Rivier College",
    url: "http://www.rivier.edu/",
  },
  {
    country: "US",
    name: "Roanoke Bible College",
    url: "http://www.roanokebible.edu/",
  },
  {
    country: "US",
    name: "Roanoke College",
    url: "http://www.roanoke.edu/",
  },
  {
    country: "US",
    name: "Robert Morris College",
    url: "http://www.robert-morris.edu/",
  },
  {
    country: "US",
    name: "Robert Morris College of Chicago",
    url: "http://www.rmcil.edu/",
  },
  {
    country: "US",
    name: "Roberts Wesleyan College",
    url: "http://www.rwc.edu/",
  },
  {
    country: "US",
    name: "Rochester College",
    url: "http://www.rc.edu/",
  },
  {
    country: "US",
    name: "Rochester Institute of Technology",
    url: "http://www.rit.edu/",
  },
  {
    country: "US",
    name: "Rockford College",
    url: "http://www.rockford.edu/",
  },
  {
    country: "US",
    name: "Rockhurst College",
    url: "http://www.rockhurst.edu/",
  },
  {
    country: "US",
    name: "Rock Valley College",
    url: "http://www.rvc.cc.il.us/",
  },
  {
    country: "US",
    name: "Rocky Mountain College",
    url: "http://www.rocky.edu/",
  },
  {
    country: "US",
    name: "Rocky Mountain College of Art and Design",
    url: "http://www.rmcad.edu/",
  },
  {
    country: "US",
    name: "Rogers State University",
    url: "http://www.rsu.edu/",
  },
  {
    country: "US",
    name: "Roger Williams University",
    url: "http://www.rwu.edu/",
  },
  {
    country: "US",
    name: "Rollins College",
    url: "http://www.rollins.edu/",
  },
  {
    country: "US",
    name: "Roosevelt University",
    url: "http://www.roosevelt.edu/",
  },
  {
    country: "US",
    name: "Rose-Hulman Institute of Technology",
    url: "http://www.rose-hulman.edu/",
  },
  {
    country: "US",
    name: "Rosemont College",
    url: "http://www.rosemont.edu/",
  },
  {
    country: "US",
    name: "Ross University, School of Medicine",
    url: "http://www.rossmed.edu/",
  },
  {
    country: "US",
    name: "Rowan University",
    url: "http://www.rowan.edu/",
  },
  {
    country: "US",
    name: "Rush University",
    url: "http://www.rushu.rush.edu/",
  },
  {
    country: "US",
    name: "Russel Sage College",
    url: "http://www.sage.edu/RSC/",
  },
  {
    country: "US",
    name: "Rust College",
    url: "http://www.rustcollege.edu/",
  },
  {
    country: "US",
    name: "Rutgers, The State University of New Jersey",
    url: "http://www.rutgers.edu/",
  },
  {
    country: "US",
    name: "Rutgers, The State University of New Jersey - Camden",
    url: "http://camden-www.rutgers.edu/",
  },
  {
    country: "US",
    name: "Rutgers, The State University of New Jersey - Newark",
    url: "http://rutgers-newark.rutgers.edu/",
  },
  {
    country: "US",
    name: "Sacred Heart University",
    url: "http://www.sacredheart.edu/",
  },
  {
    country: "US",
    name: "Sage Graduate School",
    url: "http://www.sage.edu/SGS/",
  },
  {
    country: "US",
    name: "Saginaw Valley State University",
    url: "http://www.svsu.edu/",
  },
  {
    country: "US",
    name: "Salem College",
    url: "http://www.salem.edu/",
  },
  {
    country: "US",
    name: "Salem International University",
    url: "http://www.salemiu.edu/",
  },
  {
    country: "US",
    name: "Salem State College",
    url: "http://www.salem.mass.edu/",
  },
  {
    country: "US",
    name: "Salem Teikyo University",
    url: "http://www.salem-teikyo.wvnet.edu/",
  },
  {
    country: "US",
    name: "Salisbury State University",
    url: "http://www.ssu.edu/",
  },
  {
    country: "US",
    name: "Salve Regina University",
    url: "http://www.salve.edu/",
  },
  {
    country: "US",
    name: "Samford University",
    url: "http://www.samford.edu/",
  },
  {
    country: "US",
    name: "Sam Houston State University",
    url: "http://www.shsu.edu/",
  },
  {
    country: "US",
    name: "Samuel Merritt College",
    url: "http://www.samuelmerritt.edu/",
  },
  {
    country: "US",
    name: "San Diego State University",
    url: "http://www.sdsu.edu/",
  },
  {
    country: "US",
    name: "San Diego University for Integrative Studies",
    url: "http://www.sduis.edu/",
  },
  {
    country: "US",
    name: "Sanford-Brown Institute",
    url: "http://www.sbjacksonville.com/",
  },
  {
    country: "US",
    name: "San Francisco Art Institute",
    url: "http://www.sfai.edu/",
  },
  {
    country: "US",
    name: "San Francisco Conservatory of Music",
    url: "http://www.sfcm.edu/",
  },
  {
    country: "US",
    name: "San Francisco State University",
    url: "http://www.sfsu.edu/",
  },
  {
    country: "US",
    name: "San Joaquin College of Law",
    url: "http://www.sjcl.org/",
  },
  {
    country: "US",
    name: "San Jose Christian College",
    url: "http://www.sjchristiancol.edu/",
  },
  {
    country: "US",
    name: "San Jose State University",
    url: "http://www.sjsu.edu/",
  },
  {
    country: "US",
    name: "Santa Clara University",
    url: "http://www.scu.edu/",
  },
  {
    country: "US",
    name: "Sarah Lawrence College",
    url: "http://www.slc.edu/",
  },
  {
    country: "US",
    name: "Savannah College of Art and Design",
    url: "http://www.scad.edu/",
  },
  {
    country: "US",
    name: "Savannah State University",
    url: "http://www.savstate.edu/",
  },
  {
    country: "US",
    name: "Saybrook Institute",
    url: "http://www.saybrook.org/",
  },
  {
    country: "US",
    name: "Schiller International University",
    url: "http://www.schiller.edu/",
  },
  {
    country: "US",
    name: "Scholl College of Podiatric Medicine",
    url: "http://www.scholl.edu/",
  },
  {
    country: "US",
    name: "School for International Training",
    url: "http://www.sit.edu/",
  },
  {
    country: "US",
    name: "School of the Museum of Fine Arts",
    url: "http://www.smfa.edu/",
  },
  {
    country: "US",
    name: "School of the Visual Arts",
    url: "http://www.schoolofvisualarts.edu/",
  },
  {
    country: "US",
    name: "Schreiner College",
    url: "http://www.schreiner.edu/",
  },
  {
    country: "US",
    name: "Scripps College",
    url: "http://www.scrippscol.edu/",
  },
  {
    country: "US",
    name: "Seattle Pacific University",
    url: "http://www.spu.edu/",
  },
  {
    country: "US",
    name: "Seattle University",
    url: "http://www.seattleu.edu/",
  },
  {
    country: "US",
    name: "Seton Hall University",
    url: "http://www.shu.edu/",
  },
  {
    country: "US",
    name: "Seton Hill College",
    url: "http://www.setonhill.edu/",
  },
  {
    country: "US",
    name: "Shawnee Community College",
    url: "http://www.shawnee.cc.il.us/",
  },
  {
    country: "US",
    name: "Shawnee State University",
    url: "http://www.shawnee.edu/",
  },
  {
    country: "US",
    name: "Shaw University",
    url: "http://www.shawuniversity.edu/",
  },
  {
    country: "US",
    name: "Sheldon Jackson College",
    url: "http://www.sheldonjackson.edu/",
  },
  {
    country: "US",
    name: "Shenandoah University",
    url: "http://www.su.edu/",
  },
  {
    country: "US",
    name: "Shepherd College",
    url: "http://www.shepherd.edu/",
  },
  {
    country: "US",
    name: "Sherman College of Straight Chiropractic",
    url: "http://www.sherman.edu/",
  },
  {
    country: "US",
    name: "Shimer College",
    url: "http://www.shimer.edu/",
  },
  {
    country: "US",
    name: "Shippensburg University of Pennsylvania",
    url: "http://www.ship.edu/",
  },
  {
    country: "US",
    name: "Shoreline Community College",
    url: "http://www.shoreline.edu/",
  },
  {
    country: "US",
    name: "Shorter College",
    url: "http://www.shorter.edu/",
  },
  {
    country: "US",
    name: "Siena College",
    url: "http://www.siena.edu/",
  },
  {
    country: "US",
    name: "Siena Heights University",
    url: "http://www.sienahts.edu/",
  },
  {
    country: "US",
    name: "Sierra Nevada College",
    url: "http://www.sierranevada.edu/",
  },
  {
    country: "US",
    name: "Silver Lake College",
    url: "http://www.sl.edu/",
  },
  {
    country: "US",
    name: "Simmons College",
    url: "http://www.simmons.edu/",
  },
  {
    country: "US",
    name: "Simon's Rock College",
    url: "http://www.simons-rock.edu/",
  },
  {
    country: "US",
    name: "Simpson College",
    url: "http://www.simpsonca.edu/",
  },
  {
    country: "US",
    name: "Simpson College Iowa",
    url: "http://www.simpson.edu/",
  },
  {
    country: "US",
    name: "Sinte Gleska University",
    url: "http://sinte.indian.com/",
  },
  {
    country: "US",
    name: "Skadron College",
    url: "http://skadron.com/",
  },
  {
    country: "US",
    name: "Skidmore College",
    url: "http://www.skidmore.edu/",
  },
  {
    country: "US",
    name: "Slippery Rock University",
    url: "http://www.sru.edu/",
  },
  {
    country: "US",
    name: "Smith College",
    url: "http://www.smith.edu/",
  },
  {
    country: "US",
    name: "Sojourner-Douglass College",
    url: "http://www.sdc.edu/",
  },
  {
    country: "US",
    name: "Soka University of America",
    url: "http://www.soka.edu",
  },
  {
    country: "US",
    name: "Sonoma State University",
    url: "http://www.sonoma.edu/",
  },
  {
    country: "US",
    name: "South Carolina State University",
    url: "http://www.scsu.edu/",
  },
  {
    country: "US",
    name: "South Dakota School of Mines and Technology",
    url: "http://www.sdsmt.edu/",
  },
  {
    country: "US",
    name: "South Dakota State University",
    url: "http://www.sdstate.edu/",
  },
  {
    country: "US",
    name: "Southeastern Baptist College",
    url: "http://www.southeasternbaptist.edu/",
  },
  {
    country: "US",
    name: "Southeastern Bible College",
    url: "http://www.sebc.edu/",
  },
  {
    country: "US",
    name: "Southeastern College of the Assemblies of God",
    url: "http://www.secollege.edu/",
  },
  {
    country: "US",
    name: "Southeastern Louisiana University",
    url: "http://www.southeastern.edu/",
  },
  {
    country: "US",
    name: "Southeastern Oklahoma State University",
    url: "http://www.sosu.edu/",
  },
  {
    country: "US",
    name: "Southeastern University",
    url: "http://www.seu.edu/",
  },
  {
    country: "US",
    name: "Southeast Missouri State University",
    url: "http://www.semo.edu/",
  },
  {
    country: "US",
    name: "Southern Adventist University",
    url: "http://www.southern.edu/",
  },
  {
    country: "US",
    name: "Southern Arkansas University",
    url: "http://www.saumag.edu/",
  },
  {
    country: "US",
    name: "Southern California College",
    url: "http://www.sccu.edu/",
  },
  {
    country: "US",
    name: "Southern California College of Optometry",
    url: "http://www.scco.edu/",
  },
  {
    country: "US",
    name: "Southern California Institute of Architecture",
    url: "http://www.sciarc.edu/",
  },
  {
    country: "US",
    name: "Southern College of Optometry",
    url: "http://www.sco.edu/",
  },
  {
    country: "US",
    name: "Southern Connecticut State University",
    url: "http://www.scsu.ctstateu.edu/",
  },
  {
    country: "US",
    name: "Southern Illinois University at Carbondale",
    url: "http://www.siu.edu/",
  },
  {
    country: "US",
    name: "Southern Illinois University at Edwardsville",
    url: "http://www.siue.edu/",
  },
  {
    country: "US",
    name: "Southern Methodist University",
    url: "http://www.smu.edu/",
  },
  {
    country: "US",
    name: "Southern Nazarene University",
    url: "http://www.snu.edu/",
  },
  {
    country: "US",
    name: "Southern New Hampshire University",
    url: "http://www.snhu.edu/",
  },
  {
    country: "US",
    name: "Southern Oregon University",
    url: "http://www.sou.edu/",
  },
  {
    country: "US",
    name: "Southern Polytechnic State Univerisity",
    url: "http://www.spsu.edu/",
  },
  {
    country: "US",
    name: "Southern University - Baton Rouge",
    url: "http://www.subr.edu/",
  },
  {
    country: "US",
    name: "Southern University - New Orleans",
    url: "http://www.suno.edu/",
  },
  {
    country: "US",
    name: "Southern University - Shreveport",
    url: "http://www.susla.edu/",
  },
  {
    country: "US",
    name: "Southern Utah University",
    url: "http://www.suu.edu/",
  },
  {
    country: "US",
    name: "Southern Vermont College",
    url: "http://www.svc.edu/",
  },
  {
    country: "US",
    name: "Southern Wesleyan University",
    url: "http://www.swu.edu/",
  },
  {
    country: "US",
    name: "South Florida Bible College & Theological Seminary",
    url: "http://www.sfbc.edu/",
  },
  {
    country: "US",
    name: "South Texas College of Law",
    url: "http://www.stcl.edu/",
  },
  {
    country: "US",
    name: "Southwest Baptist University",
    url: "http://www.sbuniv.edu/",
  },
  {
    country: "US",
    name: "Southwestern Adventist University",
    url: "http://www.swau.edu/",
  },
  {
    country: "US",
    name: "Southwestern Assemblies of God University",
    url: "http://www.sagu.edu/",
  },
  {
    country: "US",
    name: "Southwestern Baptist Theological Seminary",
    url: "http://www.swbts.edu/",
  },
  {
    country: "US",
    name: "Southwestern Christian College",
    url: "http://www.soulsociety.com/swcc.html",
  },
  {
    country: "US",
    name: "Southwestern Christian University",
    url: "http://www.swcu.edu/",
  },
  {
    country: "US",
    name: "Southwestern College Kansas",
    url: "http://www.sckans.edu/",
  },
  {
    country: "US",
    name: "Southwestern College Santa Fe",
    url: "http://www.swc.edu/",
  },
  {
    country: "US",
    name: "Southwestern Oklahoma State University",
    url: "http://www.swosu.edu/",
  },
  {
    country: "US",
    name: "Southwestern University",
    url: "http://www.southwestern.edu/",
  },
  {
    country: "US",
    name: "Southwestern University School of Law",
    url: "http://www.swlaw.edu/",
  },
  {
    country: "US",
    name: "Southwest Missouri State University",
    url: "http://www.smsu.edu/",
  },
  {
    country: "US",
    name: "Southwest Missouri State University - West Plains",
    url: "http://www.wp.smsu.edu/",
  },
  {
    country: "US",
    name: "Southwest State University",
    url: "http://www.southwest.msus.edu/",
  },
  {
    country: "US",
    name: "Southwest Texas State University",
    url: "http://www.swt.edu/",
  },
  {
    country: "US",
    name: "Southwest University",
    url: "http://www.southwest.edu/",
  },
  {
    country: "US",
    name: "Spalding University",
    url: "http://www.spalding.edu/",
  },
  {
    country: "US",
    name: "Spelman College",
    url: "http://www.spelman.edu/",
  },
  {
    country: "US",
    name: "Spertus Institute of Jewish Studies",
    url: "http://www.spertus.edu/",
  },
  {
    country: "US",
    name: "Spring Arbor College",
    url: "http://www.arbor.edu/",
  },
  {
    country: "US",
    name: "Springfield College",
    url: "http://www.spfldcol.edu/",
  },
  {
    country: "US",
    name: "Spring Hill College",
    url: "http://www.shc.edu/",
  },
  {
    country: "US",
    name: "St. Ambrose University",
    url: "http://www.sau.edu/",
  },
  {
    country: "US",
    name: "Standford Online University",
    url: "http://www.standford-university.cjb.net/",
  },
  {
    country: "US",
    name: "Standford University",
    url: "http://standford-university.edu.tf/",
  },
  {
    country: "US",
    name: "St. Andrews Presbyterian College",
    url: "http://www.sapc.edu/",
  },
  {
    country: "US",
    name: "Stanford University",
    url: "http://www.stanford.edu/",
  },
  {
    country: "US",
    name: "St. Anselm College",
    url: "http://www.anselm.edu/",
  },
  {
    country: "US",
    name: "St. Anthony College of Nursing",
    url: "http://www.sacn.edu/",
  },
  {
    country: "US",
    name: "State University of New York at Albany",
    url: "http://www.albany.edu/",
  },
  {
    country: "US",
    name: "State University of New York at Binghamton",
    url: "http://www.binghamton.edu/",
  },
  {
    country: "US",
    name: "State University of New York at Buffalo",
    url: "http://www.buffalo.edu/",
  },
  {
    country: "US",
    name: "State University of New York at New Paltz",
    url: "http://www.newpaltz.edu/",
  },
  {
    country: "US",
    name: "State University of New York at Oswego",
    url: "http://www.oswego.edu/",
  },
  {
    country: "US",
    name: "State University of New York at Stony Brook",
    url: "http://www.sunysb.edu/",
  },
  {
    country: "US",
    name: "State University of New York College at Brockport",
    url: "http://www.brockport.edu/",
  },
  {
    country: "US",
    name: "State University of New York College at Cortland",
    url: "http://www.cortland.edu/",
  },
  {
    country: "US",
    name: "State University of New York College at Fredonia",
    url: "http://www.fredonia.edu/",
  },
  {
    country: "US",
    name: "State University of New York College at Geneseo",
    url: "http://www.geneseo.edu/",
  },
  {
    country: "US",
    name: "State University of New York College at Old Westbury",
    url: "http://www.oldwestbury.edu/",
  },
  {
    country: "US",
    name: "State University of New York College at Oneonta",
    url: "http://www.oneonta.edu/",
  },
  {
    country: "US",
    name: "State University of New York College at Plattsburgh",
    url: "http://www.plattsburgh.edu/",
  },
  {
    country: "US",
    name: "State University of New York College at Potsdam",
    url: "http://www.potsdam.edu/",
  },
  {
    country: "US",
    name: "State University of New York College at Purchase",
    url: "http://www.purchase.edu/",
  },
  {
    country: "US",
    name: "State University of New York College of Agriculture and Technology at Cobleskill",
    url: "http://www.cobleskill.edu/",
  },
  {
    country: "US",
    name: "State University of New York College of Environmental Science and Forestry",
    url: "http://www.esf.edu/",
  },
  {
    country: "US",
    name: "State University of New York College of Optometry",
    url: "http://www.sunyopt.edu/",
  },
  {
    country: "US",
    name: "State University of New York College of Technology at Alfred",
    url: "http://www.alfredtech.edu/",
  },
  {
    country: "US",
    name: "State University of New York College of Technology at Farmingdale",
    url: "http://www.farmingdale.edu/",
  },
  {
    country: "US",
    name: "State University of New York Downstate Medical Center",
    url: "http://www.hscbklyn.edu/",
  },
  {
    country: "US",
    name: "State University of New York Empire State College",
    url: "http://www.esc.edu/",
  },
  {
    country: "US",
    name: "State University of New York Health Sience Centre Syracuse",
    url: "http://www.hscsyr.edu/",
  },
  {
    country: "US",
    name: "State University of New York Institute of Technology at Utica/Rome",
    url: "http://www.sunyit.edu/",
  },
  {
    country: "US",
    name: "State University of New York Maritime College",
    url: "http://www.sunymaritime.edu/",
  },
  {
    country: "US",
    name: "State University of New York School of Engineering and Applied Sciences",
    url: "http://www.eng.buffalo.edu/",
  },
  {
    country: "US",
    name: "State University of New York (SUNY)",
    url: "http://www.sunycentral.edu/",
  },
  {
    country: "US",
    name: "State University of New York Upstate Medical University",
    url: "http://www.upstate.edu/",
  },
  {
    country: "US",
    name: "State University of West Georgia",
    url: "http://www.westga.edu/",
  },
  {
    country: "US",
    name: "St. Augustine's College North Carolina",
    url: "http://www.st-aug.edu/",
  },
  {
    country: "US",
    name: "St. Bernard's Institute",
    url: "http://www.sbi.edu/",
  },
  {
    country: "US",
    name: "St. Bonaventure University",
    url: "http://www.sbu.edu/",
  },
  {
    country: "US",
    name: "St. Cloud State University",
    url: "http://www.stcloudstate.edu/",
  },
  {
    country: "US",
    name: "St. Edwards University",
    url: "http://www.stedwards.edu/",
  },
  {
    country: "US",
    name: "Stefan University",
    url: "http://www.stefan-university.edu/",
  },
  {
    country: "US",
    name: "Stephen F. Austin State University",
    url: "http://www.sfasu.edu/",
  },
  {
    country: "US",
    name: "Stephens College",
    url: "http://www.stephens.edu/",
  },
  {
    country: "US",
    name: "Sterling College",
    url: "http://www.sterling.edu/",
  },
  {
    country: "US",
    name: "Stetson University",
    url: "http://www.stetson.edu/",
  },
  {
    country: "US",
    name: "Stevens Institute of Technology",
    url: "http://www.stevens-tech.edu/",
  },
  {
    country: "US",
    name: "St. Francis College, Brooklyn Heights",
    url: "http://www.stfranciscollege.edu/",
  },
  {
    country: "US",
    name: "St. Francis College, Fort Wayne",
    url: "http://www.sfc.edu/",
  },
  {
    country: "US",
    name: "St. Francis College, Loretto",
    url: "http://www.sfcpa.edu/",
  },
  {
    country: "US",
    name: "St. Francis Medical Center College of Nursing",
    url: "http://www.osfsaintfrancis.org/",
  },
  {
    country: "US",
    name: "St. George's University",
    url: "http://www.sgu.edu/",
  },
  {
    country: "US",
    name: "Stillman College",
    url: "http://www.stillman.edu/",
  },
  {
    country: "US",
    name: "St. John Fisher College",
    url: "http://www.sjfc.edu/",
  },
  {
    country: "US",
    name: "St. John's College Maryland",
    url: "http://www.sjca.edu/",
  },
  {
    country: "US",
    name: "St. John's College New Mexico",
    url: "http://www.sjcsf.edu/",
  },
  {
    country: "US",
    name: "St. John's Seminary",
    url: "http://www.stjohnsem.edu/",
  },
  {
    country: "US",
    name: "St. John's University",
    url: "http://www.stjohns.edu/",
  },
  {
    country: "US",
    name: "St. Joseph College",
    url: "http://www.sjc.edu/",
  },
  {
    country: "US",
    name: "St. Joseph College of Nursing",
    url: "http://www.stfrancis.edu/sjcn/sjcnhome.htm",
  },
  {
    country: "US",
    name: "St. Joseph's College",
    url: "http://www.saintjoe.edu/",
  },
  {
    country: "US",
    name: "St. Joseph's College New York",
    url: "http://www.sjcny.edu/",
  },
  {
    country: "US",
    name: "St. Joseph's College New York, Suffolk Campus",
    url: "http://www.sjcny.edu/patchogue/",
  },
  {
    country: "US",
    name: "St. Joseph's College of Maine",
    url: "http://www.sjcme.edu/",
  },
  {
    country: "US",
    name: "St. Joseph's University",
    url: "http://www.sju.edu/",
  },
  {
    country: "US",
    name: "St. Lawrence University",
    url: "http://www.stlawu.edu/",
  },
  {
    country: "US",
    name: "St. Leo College",
    url: "http://www.saintleo.edu/",
  },
  {
    country: "US",
    name: "St. Louis Christian College",
    url: "http://www.slcc4ministry.edu/",
  },
  {
    country: "US",
    name: "St. Louis College of Pharmacy",
    url: "http://www.stlcop.edu/",
  },
  {
    country: "US",
    name: "St. Louis University",
    url: "http://www.slu.edu/",
  },
  {
    country: "US",
    name: "St. Luke's College",
    url: "http://www.saint-lukes.org/about/slc/",
  },
  {
    country: "US",
    name: "St. Martin's College",
    url: "http://www.stmartin.edu/",
  },
  {
    country: "US",
    name: "St. Mary College",
    url: "http://www.smcks.edu/",
  },
  {
    country: "US",
    name: "St. Mary-of-the-Woods College",
    url: "http://www.smwc.edu/",
  },
  {
    country: "US",
    name: "St. Mary's College Indiana",
    url: "http://www.saintmarys.edu/",
  },
  {
    country: "US",
    name: "St. Mary's College of California",
    url: "http://www.stmarys-ca.edu/",
  },
  {
    country: "US",
    name: "St. Mary's College of Maryland",
    url: "http://www.smcm.edu/",
  },
  {
    country: "US",
    name: "St. Mary's University of Minnesota",
    url: "http://www.smumn.edu/",
  },
  {
    country: "US",
    name: "St. Mary's University of San Antonio",
    url: "http://www.stmarytx.edu/",
  },
  {
    country: "US",
    name: "St. Meinrad College",
    url: "http://www.saintmeinrad.edu/",
  },
  {
    country: "US",
    name: "St. Michael's College",
    url: "http://www.smcvt.edu/",
  },
  {
    country: "US",
    name: "St. Norbert College",
    url: "http://www.snc.edu/",
  },
  {
    country: "US",
    name: "St. Olaf College",
    url: "http://www.stolaf.edu/",
  },
  {
    country: "US",
    name: "Stonehill College",
    url: "http://www.stonehill.edu/",
  },
  {
    country: "US",
    name: "St. Paul's College",
    url: "http://www.saintpauls.edu/",
  },
  {
    country: "US",
    name: "St. Petersburg College",
    url: "http://www.spcollege.edu/",
  },
  {
    country: "US",
    name: "St. Peter's College",
    url: "http://www.spc.edu/",
  },
  {
    country: "US",
    name: "Strayer University",
    url: "http://www.strayer.edu/",
  },
  {
    country: "US",
    name: "St. Thomas Aquinas College",
    url: "http://www.stac.edu/",
  },
  {
    country: "US",
    name: "St. Thomas University",
    url: "http://www.stu.edu/",
  },
  {
    country: "US",
    name: "St. Vincent College",
    url: "http://www.stvincent.edu/",
  },
  {
    country: "US",
    name: "St. Xavier University",
    url: "http://www.sxu.edu/",
  },
  {
    country: "US",
    name: "Suffolk University",
    url: "http://www.suffolk.edu/",
  },
  {
    country: "US",
    name: "Sullivan College",
    url: "http://www.sullivan.edu/",
  },
  {
    country: "US",
    name: "Sul Ross State University",
    url: "http://www.sulross.edu/",
  },
  {
    country: "US",
    name: "Susquehanna University",
    url: "http://www.susqu.edu/",
  },
  {
    country: "US",
    name: "Swarthmore College",
    url: "http://www.swarthmore.edu/",
  },
  {
    country: "US",
    name: "Sweet Briar College",
    url: "http://www.sbc.edu/",
  },
  {
    country: "US",
    name: "Syracuse University",
    url: "http://www.syr.edu/",
  },
  {
    country: "US",
    name: "Tabor College",
    url: "http://www.tabor.edu/",
  },
  {
    country: "US",
    name: "Talladega College",
    url: "http://www.talladega.edu/",
  },
  {
    country: "US",
    name: "Tarleton State University",
    url: "http://www.tarleton.edu/",
  },
  {
    country: "US",
    name: "Taylor University",
    url: "http://www.tayloru.edu/",
  },
  {
    country: "US",
    name: "Taylor University, Fort Wayne Campus",
    url: "http://www.tayloru.edu/fw/",
  },
  {
    country: "US",
    name: "Teachers College, Columbia University",
    url: "http://www.tc.columbia.edu/",
  },
  {
    country: "US",
    name: "Temple University",
    url: "http://www.temple.edu/",
  },
  {
    country: "US",
    name: "Temple University School of Podiatric Medicine",
    url: "http://www.pcpm.edu/",
  },
  {
    country: "US",
    name: "Tennessee State University",
    url: "http://www.tnstate.edu/",
  },
  {
    country: "US",
    name: "Tennessee Technological University",
    url: "http://www.tntech.edu/",
  },
  {
    country: "US",
    name: "Tennessee Temple University",
    url: "http://www.tntemple.edu/",
  },
  {
    country: "US",
    name: "Tennessee Wesleyan College",
    url: "http://www.twcnet.edu/",
  },
  {
    country: "US",
    name: "Texas A&M International University",
    url: "http://www.tamiu.edu/",
  },
  {
    country: "US",
    name: "Texas A&M University",
    url: "http://www.tamu.edu/",
  },
  {
    country: "US",
    name: "Texas A&M University - Commerce",
    url: "http://www.tamu-commerce.edu/",
  },
  {
    country: "US",
    name: "Texas A&M University - Corpus Christi",
    url: "http://www.tamucc.edu/",
  },
  {
    country: "US",
    name: "Texas A&M University - Galveston",
    url: "http://www.tamug.tamu.edu/",
  },
  {
    country: "US",
    name: "Texas A&M University - Kingsville",
    url: "http://www.tamuk.edu/",
  },
  {
    country: "US",
    name: "Texas A&M University - Texarkana",
    url: "http://www.tamut.edu/",
  },
  {
    country: "US",
    name: "Texas Chiropractic College",
    url: "http://www.txchiro.edu/",
  },
  {
    country: "US",
    name: "Texas Christian University",
    url: "http://www.tcu.edu/",
  },
  {
    country: "US",
    name: "Texas College",
    url: "http://www.texascollege.edu/",
  },
  {
    country: "US",
    name: "Texas College of Osteopathic Medicine",
    url: "http://www.hsc.unt.edu/education/tcom/",
  },
  {
    country: "US",
    name: "Texas Lutheran University",
    url: "http://www.txlutheran.edu/",
  },
  {
    country: "US",
    name: "Texas Southern University",
    url: "http://www.tsu.edu/",
  },
  {
    country: "US",
    name: "Texas Tech University",
    url: "http://www.ttu.edu/",
  },
  {
    country: "US",
    name: "Texas Tech University Health Science Center",
    url: "http://www.ttuhsc.edu/",
  },
  {
    country: "US",
    name: "Texas Wesleyan University",
    url: "http://www.txwesleyan.edu/",
  },
  {
    country: "US",
    name: "Texas Woman's University",
    url: "http://www.twu.edu/",
  },
  {
    country: "US",
    name: "The American College",
    url: "http://www.amercoll.edu/",
  },
  {
    country: "US",
    name: "The Art Institute of Boston",
    url: "http://www.aiboston.edu/",
  },
  {
    country: "US",
    name: "The Art Institutes International Portland",
    url: "http://www.aipd.aii.edu/",
  },
  {
    country: "US",
    name: "The Art Institutes International San Francisco",
    url: "http://www.aisf.aii.edu/",
  },
  {
    country: "US",
    name: "The Boston Conservatory",
    url: "http://www.bostonconservatory.edu/",
  },
  {
    country: "US",
    name: "The Catholic University of America",
    url: "http://www.cua.edu/",
  },
  {
    country: "US",
    name: "The Chicago School of Professional Psychology",
    url: "http://www.thechicagoschool.edu/",
  },
  {
    country: "US",
    name: "The College of Insurance",
    url: "http://www.tci.edu/",
  },
  {
    country: "US",
    name: "The College of New Jersey",
    url: "http://www.tcnj.edu/",
  },
  {
    country: "US",
    name: "The College of Santa Fe",
    url: "http://www.csf.edu/",
  },
  {
    country: "US",
    name: "The College of St. Scholastica",
    url: "http://www.css.edu/",
  },
  {
    country: "US",
    name: "The College of Westchester",
    url: "http://www.cw.edu/",
  },
  {
    country: "US",
    name: "The College of Wooster",
    url: "http://www.wooster.edu/",
  },
  {
    country: "US",
    name: "The Cooper Union for the Advancement of Science and Art",
    url: "http://www.cooper.edu/",
  },
  {
    country: "US",
    name: "The Corcoran College of Art",
    url: "http://www.corcoran.edu/college/",
  },
  {
    country: "US",
    name: "The Curtis Institute of Music",
    url: "http://www.curtis.edu/",
  },
  {
    country: "US",
    name: "The Defiance College",
    url: "http://www.defiance.edu/",
  },
  {
    country: "US",
    name: "The Dickinson School of Law",
    url: "http://www.dsl.edu/",
  },
  {
    country: "US",
    name: "The Illinois Institute of Art-Chicago",
    url: "http://www.ilic.artinstitutes.edu/",
  },
  {
    country: "US",
    name: "The Johns Hopkins University",
    url: "http://www.jhu.edu/",
  },
  {
    country: "US",
    name: "The Juilliard School",
    url: "http://www.juilliard.edu/",
  },
  {
    country: "US",
    name: "The Leadership Institute of Seattle",
    url: "http://www.lios.org/",
  },
  {
    country: "US",
    name: "The Maryland Institute, College of Art",
    url: "http://www.mica.edu/",
  },
  {
    country: "US",
    name: "The Master's College",
    url: "http://www.masters.edu/",
  },
  {
    country: "US",
    name: "The McGregor School of Antioch University",
    url: "http://www.mcgregor.edu/",
  },
  {
    country: "US",
    name: "The Naropa Institute",
    url: "http://www.naropa.edu/",
  },
  {
    country: "US",
    name: "The New School",
    url: "http://www.newschool.edu/",
  },
  {
    country: "US",
    name: "The Rockefeller University",
    url: "http://www.rockefeller.edu/",
  },
  {
    country: "US",
    name: "The School of the Art Institute of Chicago",
    url: "http://www.artic.edu/",
  },
  {
    country: "US",
    name: "The Scripps Research Institute",
    url: "http://www.scripps.edu/",
  },
  {
    country: "US",
    name: "The Southern Christian University",
    url: "http://www.southernchristian.edu/",
  },
  {
    country: "US",
    name: "The Tulane University of New Orleans",
    url: "http://www.tulane.edu/",
  },
  {
    country: "US",
    name: "The Union Institute",
    url: "http://www.tui.edu/",
  },
  {
    country: "US",
    name: "Thiel College",
    url: "http://www.thiel.edu/",
  },
  {
    country: "US",
    name: "Thomas A. Edison State College",
    url: "http://www.tesc.edu/",
  },
  {
    country: "US",
    name: "Thomas Aquinas College",
    url: "http://www.thomasaquinas.edu/",
  },
  {
    country: "US",
    name: "Thomas College Maine",
    url: "http://www.thomas.edu/",
  },
  {
    country: "US",
    name: "Thomas Jefferson University",
    url: "http://www.tju.edu/",
  },
  {
    country: "US",
    name: "Thomas More College",
    url: "http://www.thomasmore.edu/",
  },
  {
    country: "US",
    name: "Thomas More College of Liberal Arts",
    url: "http://www.thomasmorecollege.edu/",
  },
  {
    country: "US",
    name: "Thomas University",
    url: "http://www.thomasu.edu/",
  },
  {
    country: "US",
    name: "Thunderbird School of Global Management",
    url: "http://www.thunderbird.edu/",
  },
  {
    country: "US",
    name: "Tiffin University",
    url: "http://www.tiffin.edu/",
  },
  {
    country: "US",
    name: "Toccoa Falls College",
    url: "http://www.toccoafalls.edu/",
  },
  {
    country: "US",
    name: "Tomball College",
    url: "http://wwwtc.nhmccd.edu/",
  },
  {
    country: "US",
    name: "Tougaloo College",
    url: "http://www.tougaloo.edu/",
  },
  {
    country: "US",
    name: "Touro College",
    url: "http://www.touro.edu/",
  },
  {
    country: "US",
    name: "Touro University",
    url: "http://www.tu.edu/",
  },
  {
    country: "US",
    name: "Towson University",
    url: "http://www.towson.edu/",
  },
  {
    country: "US",
    name: "Transylvania University",
    url: "http://www.transy.edu/",
  },
  {
    country: "US",
    name: "Trevecca Nazarene University",
    url: "http://www.trevecca.edu/",
  },
  {
    country: "US",
    name: "Tri-College University",
    url: "http://www.ndsu.nodak.edu/tricollege/",
  },
  {
    country: "US",
    name: "Trident University",
    url: "http://www.trident.edu/",
  },
  {
    country: "US",
    name: "Trinity Bible College",
    url: "http://www.tbc2day.edu/",
  },
  {
    country: "US",
    name: "Trinity Christian College",
    url: "http://www.trnty.edu/",
  },
  {
    country: "US",
    name: "Trinity College Connecticut",
    url: "http://www.trincoll.edu/",
  },
  {
    country: "US",
    name: "Trinity College of Florida",
    url: "http://www.trinitycollege.edu/",
  },
  {
    country: "US",
    name: "Trinity College of Vermont",
    url: "http://www.trinityvt.edu/",
  },
  {
    country: "US",
    name: "Trinity International University",
    url: "http://www.trin.edu/",
  },
  {
    country: "US",
    name: "Trinity International University (Excel), Miami",
    url: "http://www.tiu.edu/excel/index.html",
  },
  {
    country: "US",
    name: "Trinity University",
    url: "http://www.trinity.edu/",
  },
  {
    country: "US",
    name: "Tri-State University",
    url: "http://www.tristate.edu/",
  },
  {
    country: "US",
    name: "Triton College",
    url: "http://www.triton.cc.il.us/",
  },
  {
    country: "US",
    name: "Troy University",
    url: "http://www.troy.edu/",
  },
  {
    country: "US",
    name: "Troy University, Dothan",
    url: "http://dothan.troy.edu/",
  },
  {
    country: "US",
    name: "Troy University, Montgomery",
    url: "http://montgomery.troy.edu/",
  },
  {
    country: "US",
    name: "Troy University, Phenix City",
    url: "http://phenix.troy.edu/",
  },
  {
    country: "US",
    name: "Troy University, Troy",
    url: "http://troy.troy.edu/",
  },
  {
    country: "US",
    name: "Truman College",
    url: "http://www.trumancollege.net/",
  },
  {
    country: "US",
    name: "Truman State University",
    url: "http://www.truman.edu/",
  },
  {
    country: "US",
    name: "Tufts University",
    url: "http://www.tufts.edu/",
  },
  {
    country: "US",
    name: "Tui Online University",
    url: "http://www.tuiu.edu/",
  },
  {
    country: "US",
    name: "Tusculum College",
    url: "http://www.tusculum.edu/",
  },
  {
    country: "US",
    name: "Tuskegee University",
    url: "http://www.tusk.edu/",
  },
  {
    country: "US",
    name: "Uniformed Services Universty of the Health Sciences",
    url: "http://www.usuhs.mil/",
  },
  {
    country: "US",
    name: "Union College",
    url: "http://www.union.edu/",
  },
  {
    country: "US",
    name: "Union College Kentucky",
    url: "http://www.unionky.edu/",
  },
  {
    country: "US",
    name: "Union College Nebraska",
    url: "http://www.ucollege.edu/",
  },
  {
    country: "US",
    name: "Union Theological Seminary (UTS)",
    url: "http://www.union-psce.edu/",
  },
  {
    country: "US",
    name: "Union University",
    url: "http://www.uu.edu/",
  },
  {
    country: "US",
    name: "United States Air Force Academy",
    url: "http://www.usafa.af.mil/",
  },
  {
    country: "US",
    name: "United States Coast Guard Academy",
    url: "http://www.cga.edu/",
  },
  {
    country: "US",
    name: "United States International University",
    url: "http://www.usiu.edu/",
  },
  {
    country: "US",
    name: "United States Merchant Marine Academy",
    url: "http://www.usmma.edu/",
  },
  {
    country: "US",
    name: "United States Military Academy",
    url: "http://www.usma.edu/",
  },
  {
    country: "US",
    name: "United States Naval Academy",
    url: "http://www.usna.edu/",
  },
  {
    country: "US",
    name: "United States Sports Academy",
    url: "http://www.sport.ussa.edu/",
  },
  {
    country: "US",
    name: "Unity College",
    url: "http://www.unity.edu/",
  },
  {
    country: "US",
    name: "University of Advancing Technology (UAT)",
    url: "http://www.uat.edu/",
  },
  {
    country: "US",
    name: "University of Akron",
    url: "http://www.uakron.edu/",
  },
  {
    country: "US",
    name: "University of Alabama - Birmingham",
    url: "http://www.uab.edu/",
  },
  {
    country: "US",
    name: "University of Alabama - Huntsville",
    url: "http://www.uah.edu/",
  },
  {
    country: "US",
    name: "University of Alabama - Tuscaloosa",
    url: "http://www.ua.edu/",
  },
  {
    country: "US",
    name: "University of Alanta",
    url: "http://www.uofa.edu/",
  },
  {
    country: "US",
    name: "University of Alaska - Anchorage",
    url: "http://www.uaa.alaska.edu/",
  },
  {
    country: "US",
    name: "University of Alaska - Fairbanks",
    url: "http://www.uaf.edu/",
  },
  {
    country: "US",
    name: "University of Alaska - Southeast",
    url: "http://www.uas.alaska.edu/",
  },
  {
    country: "US",
    name: "University of Alaska (System)",
    url: "http://www.alaska.edu/",
  },
  {
    country: "US",
    name: "University of Arizona",
    url: "http://www.arizona.edu/",
  },
  {
    country: "US",
    name: "University of Arkansas at Fayetteville",
    url: "http://www.uark.edu/",
  },
  {
    country: "US",
    name: "University of Arkansas at Little Rock",
    url: "http://www.ualr.edu/",
  },
  {
    country: "US",
    name: "University of Arkansas at Monticello",
    url: "http://www.uamont.edu/",
  },
  {
    country: "US",
    name: "University of Arkansas at Pine Bluff",
    url: "http://www.uapb.edu/",
  },
  {
    country: "US",
    name: "University of Arkansas for Medical Sciences",
    url: "http://www.uams.edu/",
  },
  {
    country: "US",
    name: "University of Arkansas (System)",
    url: "http://www.uasys.edu/",
  },
  {
    country: "US",
    name: "University of Baltimore",
    url: "http://www.ubalt.edu/",
  },
  {
    country: "US",
    name: "University of Bridgeport",
    url: "http://www.bridgeport.edu/",
  },
  {
    country: "US",
    name: "University of California, Berkeley",
    url: "http://www.berkeley.edu/",
  },
  {
    country: "US",
    name: "University of California, Davis",
    url: "http://www.ucdavis.edu/",
  },
  {
    country: "US",
    name: "University of California, Hastings College of Law",
    url: "http://www.uchastings.edu/",
  },
  {
    country: "US",
    name: "University of California, Irvine",
    url: "http://www.uci.edu/",
  },
  {
    country: "US",
    name: "University of California, Los Angeles",
    url: "http://www.ucla.edu/",
  },
  {
    country: "US",
    name: "University of California, Merced",
    url: "http://www.ucmerced.edu/",
  },
  {
    country: "US",
    name: "University of California, Oakland",
    url: "http://www.ucop.edu/",
  },
  {
    country: "US",
    name: "University of California, Riverside",
    url: "http://www.ucr.edu/",
  },
  {
    country: "US",
    name: "University of California, San Diego",
    url: "http://www.ucsd.edu/",
  },
  {
    country: "US",
    name: "University of California, San Francisco",
    url: "http://www.ucsf.edu/",
  },
  {
    country: "US",
    name: "University of California, Santa Barbara",
    url: "http://www.ucsb.edu/",
  },
  {
    country: "US",
    name: "University of California, Santa Cruz",
    url: "http://www.ucsc.edu/",
  },
  {
    country: "US",
    name: "University of California System",
    url: "http://www.universityofcalifornia.edu/",
  },
  {
    country: "US",
    name: "University of Central Arkansas",
    url: "http://www.uca.edu/",
  },
  {
    country: "US",
    name: "University of Central Florida",
    url: "http://www.ucf.edu/",
  },
  {
    country: "US",
    name: "University of Central Missouri",
    url: "http://www.ucmo.edu/",
  },
  {
    country: "US",
    name: "University of Central Oklahoma",
    url: "http://www.ucok.edu/",
  },
  {
    country: "US",
    name: "University of Central Texas",
    url: "http://www.vvm.com/uct/",
  },
  {
    country: "US",
    name: "University of Charleston",
    url: "http://www.uchaswv.edu/",
  },
  {
    country: "US",
    name: "University of Charleston South Carolina",
    url: "http://univchas.cofc.edu/",
  },
  {
    country: "US",
    name: "University of Chicago",
    url: "http://www.uchicago.edu/",
  },
  {
    country: "US",
    name: "University of Cincinnati",
    url: "http://www.uc.edu/",
  },
  {
    country: "US",
    name: "University of Colorado at Boulder",
    url: "http://www.colorado.edu/",
  },
  {
    country: "US",
    name: "University of Colorado at Colorado Springs",
    url: "http://www.uccs.edu/",
  },
  {
    country: "US",
    name: "University of Colorado at Denver",
    url: "http://www.cudenver.edu/",
  },
  {
    country: "US",
    name: "University of Colorado Health Sciences Center",
    url: "http://www.uchsc.edu/",
  },
  {
    country: "US",
    name: "University of Connecticut",
    url: "http://www.uconn.edu/",
  },
  {
    country: "US",
    name: "University of Connecticut at Avery Point",
    url: "http://www.averypoint.uconn.edu/",
  },
  {
    country: "US",
    name: "University of Connecticut at Hartford",
    url: "http://vm.uconn.edu/~wwwhtfd/ugrad/",
  },
  {
    country: "US",
    name: "University of Connecticut at Stamford",
    url: "http://www.stamford.uconn.edu/",
  },
  {
    country: "US",
    name: "University of Connecticut at Waterbury",
    url: "http://www.waterbury.uconn.edu/",
  },
  {
    country: "US",
    name: "University of Connecticut Health Center",
    url: "http://www.uchc.edu/",
  },
  {
    country: "US",
    name: "University of Dallas",
    url: "http://www.udallas.edu/",
  },
  {
    country: "US",
    name: "University of Dayton",
    url: "http://www.udayton.edu/",
  },
  {
    country: "US",
    name: "University of Delaware",
    url: "http://www.udel.edu/",
  },
  {
    country: "US",
    name: "University of Denver",
    url: "http://www.du.edu/",
  },
  {
    country: "US",
    name: "University of Detroit Mercy",
    url: "http://www.udmercy.edu/",
  },
  {
    country: "US",
    name: "University of Dubuque",
    url: "http://www.dbq.edu/",
  },
  {
    country: "US",
    name: "University of Evansville",
    url: "http://www.evansville.edu/",
  },
  {
    country: "US",
    name: "University of Findlay",
    url: "http://www.findlay.edu/",
  },
  {
    country: "US",
    name: "University of Florida",
    url: "http://www.ufl.edu/",
  },
  {
    country: "US",
    name: "University of Georgia",
    url: "http://www.uga.edu/",
  },
  {
    country: "US",
    name: "University of Great Falls",
    url: "http://www.ugf.edu/",
  },
  {
    country: "US",
    name: "University of Hartford",
    url: "http://www.hartford.edu/",
  },
  {
    country: "US",
    name: "University of Hawaii - Hilo",
    url: "http://www.uhh.hawaii.edu/",
  },
  {
    country: "US",
    name: "University of Hawaii - Manoa",
    url: "http://www.uhm.hawaii.edu/",
  },
  {
    country: "US",
    name: "University Of Hawaii - System",
    url: "http://www.hawaii.edu/",
  },
  {
    country: "US",
    name: "University of Hawaii - West Oahu",
    url: "http://www.uhwo.hawaii.edu/",
  },
  {
    country: "US",
    name: "University of Health Sciences",
    url: "http://www.uhs.edu/",
  },
  {
    country: "US",
    name: "University of Houston",
    url: "http://www.uh.edu/",
  },
  {
    country: "US",
    name: "University of Houston, Clear Lake",
    url: "http://www.cl.uh.edu/",
  },
  {
    country: "US",
    name: "University of Houston, Downtown",
    url: "http://www.dt.uh.edu/",
  },
  {
    country: "US",
    name: "University of Houston, Victoria",
    url: "http://www.vic.uh.edu/",
  },
  {
    country: "US",
    name: "University of Idaho",
    url: "http://www.uidaho.edu/",
  },
  {
    country: "US",
    name: "University of Illinois",
    url: "http://www.uillinois.edu/",
  },
  {
    country: "US",
    name: "University of Illinois at Chicago",
    url: "http://www.uic.edu/",
  },
  {
    country: "US",
    name: "University of Illinois at Springfield",
    url: "http://www.uis.edu/",
  },
  {
    country: "US",
    name: "University of Illinois at Urbana-Champaign",
    url: "http://www.uiuc.edu/",
  },
  {
    country: "US",
    name: "University of Indianapolis",
    url: "http://www.uindy.edu/",
  },
  {
    country: "US",
    name: "University of Iowa",
    url: "http://www.uiowa.edu/",
  },
  {
    country: "US",
    name: "University of Kansas",
    url: "http://www.ku.edu/",
  },
  {
    country: "US",
    name: "University of Kentucky",
    url: "http://www.uky.edu/",
  },
  {
    country: "US",
    name: "University of La Verne",
    url: "http://www.ulaverne.edu/",
  },
  {
    country: "US",
    name: "University of Louisiana at Lafayette",
    url: "http://www.louisiana.edu/",
  },
  {
    country: "US",
    name: "University of Louisiana at Monroe",
    url: "http://www.ulm.edu/",
  },
  {
    country: "US",
    name: "University of Louisville",
    url: "http://www.louisville.edu/",
  },
  {
    country: "US",
    name: "University of Maine, Augusta",
    url: "http://www.uma.maine.edu/",
  },
  {
    country: "US",
    name: "University of Maine, Farmington",
    url: "http://www.umf.maine.edu/",
  },
  {
    country: "US",
    name: "University of Maine, Fort Kent",
    url: "http://www.umfk.maine.edu/",
  },
  {
    country: "US",
    name: "University of Maine, Machias",
    url: "http://www.umm.maine.edu/",
  },
  {
    country: "US",
    name: "University of Maine, Orono",
    url: "http://www.umaine.edu/",
  },
  {
    country: "US",
    name: "University of Maine, Presque Isle",
    url: "http://www.umpi.maine.edu/",
  },
  {
    country: "US",
    name: "University of Maine (System)",
    url: "http://www.maine.edu/",
  },
  {
    country: "US",
    name: "University of Management & Technology",
    url: "http://www.umtweb.edu/",
  },
  {
    country: "US",
    name: "University of Mary",
    url: "http://www.umary.edu/",
  },
  {
    country: "US",
    name: "University of Mary Hardin-Baylor",
    url: "http://www.umhb.edu/",
  },
  {
    country: "US",
    name: "University of Maryland at Baltimore",
    url: "http://www.umbc.edu/",
  },
  {
    country: "US",
    name: "University of Maryland at College Park",
    url: "http://www.umd.edu/",
  },
  {
    country: "US",
    name: "University of Maryland Baltimore County",
    url: "http://www.umbc.edu/",
  },
  {
    country: "US",
    name: "University of Maryland Eastern Shore",
    url: "http://www.umes.edu/",
  },
  {
    country: "US",
    name: "University of Maryland Medicine",
    url: "http://www.umm.edu/",
  },
  {
    country: "US",
    name: "University of Maryland (System)",
    url: "http://www.ums.edu/",
  },
  {
    country: "US",
    name: "University of Maryland University College",
    url: "http://www.umuc.edu/",
  },
  {
    country: "US",
    name: "University of Massachusetts at Amherst",
    url: "http://www.umass.edu/",
  },
  {
    country: "US",
    name: "University of Massachusetts at Boston",
    url: "http://www.umb.edu/",
  },
  {
    country: "US",
    name: "University of Massachusetts at Dartmouth",
    url: "http://www.umassd.edu/",
  },
  {
    country: "US",
    name: "University of Massachusetts at Lowell",
    url: "http://www.uml.edu/",
  },
  {
    country: "US",
    name: "University of Massachusetts Medical Center at Worcester",
    url: "http://www.ummed.edu/",
  },
  {
    country: "US",
    name: "University of Massachusetts (System)",
    url: "http://www.massachusetts.edu/",
  },
  {
    country: "US",
    name: "University of Medicine and Dentistry of New Jersey",
    url: "http://www.umdnj.edu/",
  },
  {
    country: "US",
    name: "University of Memphis",
    url: "http://www.memphis.edu/",
  },
  {
    country: "US",
    name: "University of Miami",
    url: "http://www.miami.edu/",
  },
  {
    country: "US",
    name: "University of Michigan - Ann Arbor",
    url: "http://www.umich.edu/",
  },
  {
    country: "US",
    name: "University of Michigan - Dearborn",
    url: "http://www.umd.umich.edu/",
  },
  {
    country: "US",
    name: "University of Michigan - Flint",
    url: "http://www.flint.umich.edu/",
  },
  {
    country: "US",
    name: "University of Minnesota - Crookston",
    url: "http://www.crk.umn.edu/",
  },
  {
    country: "US",
    name: "University of Minnesota - Duluth",
    url: "http://www.d.umn.edu/",
  },
  {
    country: "US",
    name: "University of Minnesota - Morris",
    url: "http://www.mrs.umn.edu/",
  },
  {
    country: "US",
    name: "University of Minnesota - Twin Cities Campus",
    url: "http://www1.umn.edu/twincities/",
  },
  {
    country: "US",
    name: "University of Mississippi",
    url: "http://www.olemiss.edu/",
  },
  {
    country: "US",
    name: "University of Mississippi Medical Center",
    url: "http://www.umc.edu/",
  },
  {
    country: "US",
    name: "University of Missouri - Columbia",
    url: "http://www.missouri.edu/",
  },
  {
    country: "US",
    name: "University of Missouri - Kansas City",
    url: "http://www.umkc.edu/",
  },
  {
    country: "US",
    name: "University of Missouri - Saint Louis",
    url: "http://www.umsl.edu/",
  },
  {
    country: "US",
    name: "University of Mobile",
    url: "http://www.umobile.edu/",
  },
  {
    country: "US",
    name: "University of Montana",
    url: "http://www.umt.edu/",
  },
  {
    country: "US",
    name: "University of Montana Western",
    url: "http://www.umwestern.edu/",
  },
  {
    country: "US",
    name: "University of Montevallo",
    url: "http://www.montevallo.edu/",
  },
  {
    country: "US",
    name: "University of Nebraska - Kearney",
    url: "http://www.unk.edu/",
  },
  {
    country: "US",
    name: "University of Nebraska - Lincoln",
    url: "http://www.unl.edu/",
  },
  {
    country: "US",
    name: "University of Nebraska Medical Center",
    url: "http://www.unmc.edu/",
  },
  {
    country: "US",
    name: "University of Nebraska - Omaha",
    url: "http://www.unomaha.edu/",
  },
  {
    country: "US",
    name: "University of Nebraska (System)",
    url: "http://www.nebraska.edu/",
  },
  {
    country: "US",
    name: "University of Nevada - Las Vegas",
    url: "http://www.unlv.edu/",
  },
  {
    country: "US",
    name: "University of Nevada - Reno",
    url: "http://www.unr.edu/",
  },
  {
    country: "US",
    name: "University of New England",
    url: "http://www.une.edu/",
  },
  {
    country: "US",
    name: "University of New England, Westbrook College Campus",
    url: "http://www.une.edu/wcdirctn.html",
  },
  {
    country: "US",
    name: "University of New Hampshire",
    url: "http://www.unh.edu/",
  },
  {
    country: "US",
    name: "University of New Hampshire - Manchester",
    url: "http://www.unh.edu/unhm/",
  },
  {
    country: "US",
    name: "University of New Haven",
    url: "http://www.newhaven.edu/",
  },
  {
    country: "US",
    name: "University of New Mexico",
    url: "http://www.unm.edu/",
  },
  {
    country: "US",
    name: "University of New Orleans",
    url: "http://www.uno.edu/",
  },
  {
    country: "US",
    name: "University of North Alabama",
    url: "http://www.una.edu/",
  },
  {
    country: "US",
    name: "University of North America",
    url: "http://www.universityofnorthamerica.org/",
  },
  {
    country: "US",
    name: "University of North Carolina at Asheville",
    url: "http://www.unca.edu/",
  },
  {
    country: "US",
    name: "University of North Carolina at Chapel Hill",
    url: "http://www.unc.edu/",
  },
  {
    country: "US",
    name: "University of North Carolina at Charlotte",
    url: "http://www.uncc.edu/",
  },
  {
    country: "US",
    name: "University of North Carolina at Greensboro",
    url: "http://www.uncg.edu/",
  },
  {
    country: "US",
    name: "University of North Carolina at Pembroke",
    url: "http://www.uncp.edu/",
  },
  {
    country: "US",
    name: "University of North Carolina at Wilmington",
    url: "http://www.uncwil.edu/",
  },
  {
    country: "US",
    name: "University of North Dakota",
    url: "http://www.und.nodak.edu/",
  },
  {
    country: "US",
    name: "University of Northern Colorado",
    url: "http://www.univnorthco.edu/",
  },
  {
    country: "US",
    name: "University of Northern Iowa",
    url: "http://www.uni.edu/",
  },
  {
    country: "US",
    name: "University of Northern Virginia",
    url: "http://www.unva.edu/",
  },
  {
    country: "US",
    name: "University of Northern Washington",
    url: "http://www.unw.edu/",
  },
  {
    country: "US",
    name: "University of North Florida",
    url: "http://www.unf.edu/",
  },
  {
    country: "US",
    name: "University of North Texas",
    url: "http://www.unt.edu/",
  },
  {
    country: "US",
    name: "University of North Texas Health Science Center at Fort Worth",
    url: "http://www.hsc.unt.edu/",
  },
  {
    country: "US",
    name: "University of NorthWest",
    url: "http://www.unw.ac/",
  },
  {
    country: "US",
    name: "University of Notre Dame",
    url: "http://www.nd.edu/",
  },
  {
    country: "US",
    name: "University of Oklahoma",
    url: "http://www.ou.edu/",
  },
  {
    country: "US",
    name: "University of Oklahoma Health Sciences Center",
    url: "http://www.ouhsc.edu/",
  },
  {
    country: "US",
    name: "University of Oregon",
    url: "http://www.uoregon.edu/",
  },
  {
    country: "US",
    name: "University of Osteopathic Medicine and Health Science",
    url: "http://www.uomhs.edu/",
  },
  {
    country: "US",
    name: "University of Pennsylvania",
    url: "http://www.upenn.edu/",
  },
  {
    country: "US",
    name: "University of Phoenix",
    url: "http://www.phoenix.edu/",
  },
  {
    country: "US",
    name: "University of Pittsburgh",
    url: "http://www.pitt.edu/",
  },
  {
    country: "US",
    name: "University of Pittsburgh at Bradford",
    url: "http://www.upb.pitt.edu/",
  },
  {
    country: "US",
    name: "University of Pittsburgh at Greensburg",
    url: "http://www.pitt.edu/~upg/",
  },
  {
    country: "US",
    name: "University of Pittsburgh at Johnstown",
    url: "http://www.pitt.edu/~upjweb/",
  },
  {
    country: "US",
    name: "University of Portland",
    url: "http://www.uofport.edu/",
  },
  {
    country: "US",
    name: "University of Puget Sound",
    url: "http://www.ups.edu/",
  },
  {
    country: "US",
    name: "University of Redlands",
    url: "http://www.redlands.edu/",
  },
  {
    country: "US",
    name: "University of Rhode Island",
    url: "http://www.uri.edu/",
  },
  {
    country: "US",
    name: "University of Richmond",
    url: "http://www.urich.edu/",
  },
  {
    country: "US",
    name: "University of Rio Grande",
    url: "http://www.urgrgcc.edu/",
  },
  {
    country: "US",
    name: "University of Rochester",
    url: "http://www.rochester.edu/",
  },
  {
    country: "US",
    name: "University of San Diego",
    url: "http://www.sandiego.edu/",
  },
  {
    country: "US",
    name: "University of San Francisco",
    url: "http://www.usfca.edu/",
  },
  {
    country: "US",
    name: "University of Science and Arts of Oklahoma",
    url: "http://www.usao.edu/",
  },
  {
    country: "US",
    name: "University of Scranton",
    url: "http://www.uofs.edu/",
  },
  {
    country: "US",
    name: "University of Sioux Falls",
    url: "http://www.thecoo.edu/",
  },
  {
    country: "US",
    name: "University of South Alabama",
    url: "http://www.usouthal.edu/",
  },
  {
    country: "US",
    name: "University of South Carolina",
    url: "http://www.sc.edu/",
  },
  {
    country: "US",
    name: "University of South Carolina - Aiken",
    url: "http://www.usca.sc.edu/",
  },
  {
    country: "US",
    name: "University of South Carolina - Beaufort",
    url: "http://www.sc.edu/beaufort/",
  },
  {
    country: "US",
    name: "University of South Carolina - Lancaster",
    url: "http://www.sc.edu/lancaster/",
  },
  {
    country: "US",
    name: "University of South Carolina - Salkehatchie",
    url: "http://www.rcce.sc.edu/salkehatchie/",
  },
  {
    country: "US",
    name: "University of South Carolina - Spartanburg",
    url: "http://www.uscs.edu/",
  },
  {
    country: "US",
    name: "University of South Carolina - Sumter",
    url: "http://www.uscsumter.edu/",
  },
  {
    country: "US",
    name: "University of South Carolina - Union",
    url: "http://www.sc.edu/union/",
  },
  {
    country: "US",
    name: "University of South Dakota",
    url: "http://www.usd.edu/",
  },
  {
    country: "US",
    name: "University of Southern California",
    url: "http://www.usc.edu/",
  },
  {
    country: "US",
    name: "University of Southern Indiana",
    url: "http://www.usi.edu/",
  },
  {
    country: "US",
    name: "University of Southern Maine",
    url: "http://www.usm.maine.edu/",
  },
  {
    country: "US",
    name: "University of Southern Mississippi",
    url: "http://www.usm.edu/",
  },
  {
    country: "US",
    name: "University of South Florida",
    url: "http://www.usf.edu/",
  },
  {
    country: "US",
    name: "University of St. Francis",
    url: "http://www.stfrancis.edu/",
  },
  {
    country: "US",
    name: "University of St. Thomas, Houston",
    url: "http://www.stthom.edu/",
  },
  {
    country: "US",
    name: "University of St. Thomas, St. Paul",
    url: "http://www.stthomas.edu/",
  },
  {
    country: "US",
    name: "University of Tampa",
    url: "http://www.utampa.edu/",
  },
  {
    country: "US",
    name: "University of Tennessee - Chattanooga",
    url: "http://www.utc.edu/",
  },
  {
    country: "US",
    name: "University of Tennessee - Knoxville",
    url: "http://www.utk.edu/",
  },
  {
    country: "US",
    name: "University of Tennessee - Martin",
    url: "http://www.utm.edu/",
  },
  {
    country: "US",
    name: "University of Tennessee - Memphis",
    url: "http://www.utmem.edu/",
  },
  {
    country: "US",
    name: "University of Tennessee Space Institute",
    url: "http://www.utsi.edu/",
  },
  {
    country: "US",
    name: "University of Texas",
    url: "http://www.utsystem.edu/",
  },
  {
    country: "US",
    name: "University of Texas at Arlington",
    url: "http://www.uta.edu/",
  },
  {
    country: "US",
    name: "University of Texas at Austin",
    url: "http://www.utexas.edu/",
  },
  {
    country: "US",
    name: "University of Texas at Brownsville",
    url: "http://www.utb.edu/",
  },
  {
    country: "US",
    name: "University of Texas at Dallas",
    url: "http://www.utdallas.edu/",
  },
  {
    country: "US",
    name: "University of Texas at El Paso",
    url: "http://www.utep.edu/",
  },
  {
    country: "US",
    name: "University of Texas at San Antonio",
    url: "http://www.utsa.edu/",
  },
  {
    country: "US",
    name: "University of Texas at Tyler",
    url: "http://www.uttyl.edu/",
  },
  {
    country: "US",
    name: "University of Texas Health Center at Houston",
    url: "http://www.uthouston.edu/",
  },
  {
    country: "US",
    name: "University of Texas Health Center at Tyler",
    url: "http://www.uthct.edu/",
  },
  {
    country: "US",
    name: "University of Texas Health Science Center at San Antonio",
    url: "http://www.uthscsa.edu/",
  },
  {
    country: "US",
    name: "University of Texas M.D. Anderson Cancer Center",
    url: "http://www.mdanderson.org/",
  },
  {
    country: "US",
    name: "University of Texas Medical Branch Galveston",
    url: "http://www.utmb.edu/",
  },
  {
    country: "US",
    name: "University of Texas of the Permian Basin",
    url: "http://www.utpb.edu/",
  },
  {
    country: "US",
    name: "University of Texas Pan American",
    url: "http://www.panam.edu/",
  },
  {
    country: "US",
    name: "University of Texas Southwestern Medical Center at Dallas",
    url: "http://www.utsouthwestern.edu/",
  },
  {
    country: "US",
    name: "University of the Arts",
    url: "http://www.uarts.edu/",
  },
  {
    country: "US",
    name: "University of the District of Columbia",
    url: "http://www.udc.edu/",
  },
  {
    country: "US",
    name: "University of the Incarnate World",
    url: "http://www.uiw.edu/",
  },
  {
    country: "US",
    name: "University of the Ozarks",
    url: "http://www.ozarks.edu/",
  },
  {
    country: "US",
    name: "University of the Pacific",
    url: "http://www.pacific.edu/",
  },
  {
    country: "US",
    name: "University of the Sciences in Philadelphia",
    url: "http://www.usip.edu/",
  },
  {
    country: "US",
    name: "University of the South",
    url: "http://www.sewanee.edu/",
  },
  {
    country: "US",
    name: "University of the Southwest",
    url: "http://www.usw.edu/",
  },
  {
    country: "US",
    name: "University of Toledo",
    url: "http://www.utoledo.edu/",
  },
  {
    country: "US",
    name: "University of Tulsa",
    url: "http://www.utulsa.edu/",
  },
  {
    country: "US",
    name: "University of Utah",
    url: "http://www.utah.edu/",
  },
  {
    country: "US",
    name: "University of Vermont",
    url: "http://www.uvm.edu/",
  },
  {
    country: "US",
    name: "University of Virginia",
    url: "http://www.virginia.edu/",
  },
  {
    country: "US",
    name: "University of Virginia, College at Wise",
    url: "http://www.wise.virginia.edu/",
  },
  {
    country: "US",
    name: "University of Washington",
    url: "http://www.washington.edu/",
  },
  {
    country: "US",
    name: "University of Washington, Tacoma",
    url: "http://www.tacoma.washington.edu/",
  },
  {
    country: "US",
    name: "University of West Alabama",
    url: "http://www.uwa.edu/",
  },
  {
    country: "US",
    name: "University of West Florida",
    url: "http://www.uwf.edu/",
  },
  {
    country: "US",
    name: "University of West Los Angeles",
    url: "http://www.uwla.edu/",
  },
  {
    country: "US",
    name: "University of Wisconsin - Eau Claire",
    url: "http://www.uwec.edu/",
  },
  {
    country: "US",
    name: "University of Wisconsin - Green Bay",
    url: "http://www.uwgb.edu/",
  },
  {
    country: "US",
    name: "University of Wisconsin - La Crosse",
    url: "http://www.uwlax.edu/",
  },
  {
    country: "US",
    name: "University of Wisconsin - Madison",
    url: "http://www.wisc.edu/",
  },
  {
    country: "US",
    name: "University of Wisconsin - Milwaukee",
    url: "http://www.uwm.edu/",
  },
  {
    country: "US",
    name: "University of Wisconsin - Oshkosh",
    url: "http://www.uwosh.edu/",
  },
  {
    country: "US",
    name: "University of Wisconsin - Parkside",
    url: "http://www.uwp.edu/",
  },
  {
    country: "US",
    name: "University of Wisconsin - Platteville",
    url: "http://www.uwplatt.edu/",
  },
  {
    country: "US",
    name: "University of Wisconsin - River Falls",
    url: "http://www.uwrf.edu/",
  },
  {
    country: "US",
    name: "University of Wisconsin - Stevens Point",
    url: "http://www.uwsp.edu/",
  },
  {
    country: "US",
    name: "University of Wisconsin - Stout",
    url: "http://www.uwstout.edu/",
  },
  {
    country: "US",
    name: "University of Wisconsin - Superior",
    url: "http://www.uwsuper.edu/",
  },
  {
    country: "US",
    name: "University of Wisconsin - Whitewater",
    url: "http://www.uww.edu/",
  },
  {
    country: "US",
    name: "University of Wyoming",
    url: "http://www.uwyo.edu/",
  },
  {
    country: "US",
    name: "Upper Iowa University",
    url: "http://www.uiu.edu/",
  },
  {
    country: "US",
    name: "Urbana University",
    url: "http://www.urbana.edu/",
  },
  {
    country: "US",
    name: "Ursinus College",
    url: "http://www.ursinus.edu/",
  },
  {
    country: "US",
    name: "Ursuline College",
    url: "http://www.ursuline.edu/",
  },
  {
    country: "US",
    name: "Utah State University",
    url: "http://www.usu.edu/",
  },
  {
    country: "US",
    name: "Utah Valley State College",
    url: "http://www.uvsc.edu/",
  },
  {
    country: "US",
    name: "Utica College",
    url: "http://www.ucsu.edu/",
  },
  {
    country: "US",
    name: "Valdosta State University",
    url: "http://www.valdosta.edu/",
  },
  {
    country: "US",
    name: "Valley City State University",
    url: "http://www.vcsu.nodak.edu/",
  },
  {
    country: "US",
    name: "Valley Forge Christian College",
    url: "http://www.vfcc.edu/",
  },
  {
    country: "US",
    name: "Valparaiso University",
    url: "http://www.valpo.edu/",
  },
  {
    country: "US",
    name: "Vanderbilt University",
    url: "http://www.vanderbilt.edu/",
  },
  {
    country: "US",
    name: "VanderCook College of Music",
    url: "http://www.vandercook.edu/",
  },
  {
    country: "US",
    name: "Vanguard University of Southern California",
    url: "http://www.vanguard.edu/",
  },
  {
    country: "US",
    name: "Vassar College",
    url: "http://www.vassar.edu/",
  },
  {
    country: "US",
    name: "Vennard College",
    url: "http://www.vennard.edu/",
  },
  {
    country: "US",
    name: "Vermont Law School",
    url: "http://www.vermontlaw.edu/",
  },
  {
    country: "US",
    name: "Vermont Technical College",
    url: "http://www.vtc.vsc.edu/",
  },
  {
    country: "US",
    name: "Villa Julie College",
    url: "http://www.vjc.edu/",
  },
  {
    country: "US",
    name: "Villanova University",
    url: "http://www.villanova.edu/",
  },
  {
    country: "US",
    name: "Virginia College",
    url: "http://www.vc.edu/",
  },
  {
    country: "US",
    name: "Virginia Commonwealth University",
    url: "http://www.vcu.edu/",
  },
  {
    country: "US",
    name: "Virginia Intermont College",
    url: "http://www.vic.edu/",
  },
  {
    country: "US",
    name: "Virginia International University",
    url: "http://www.viu.edu/",
  },
  {
    country: "US",
    name: "Virginia Military Institute",
    url: "http://www.vmi.edu/",
  },
  {
    country: "US",
    name: "Virginia Polytechnic Institute and State University (Virginia Tech)",
    url: "http://www.vt.edu/",
  },
  {
    country: "US",
    name: "Virginia State University",
    url: "http://www.vsu.edu/",
  },
  {
    country: "US",
    name: "Virginia Union University",
    url: "http://www.vuu.edu/",
  },
  {
    country: "US",
    name: "Virginia Wesleyan College",
    url: "http://www.vwc.edu/",
  },
  {
    country: "US",
    name: "Viterbo College",
    url: "http://www.viterbo.edu/",
  },
  {
    country: "US",
    name: "Voorhees College",
    url: "http://www.voorhees.edu/",
  },
  {
    country: "US",
    name: "Wabash College",
    url: "http://www.wabash.edu/",
  },
  {
    country: "US",
    name: "Wagner College",
    url: "http://www.wagner.edu/",
  },
  {
    country: "US",
    name: "Wake Forest University",
    url: "http://www.wfu.edu/",
  },
  {
    country: "US",
    name: "Walden University",
    url: "http://www.waldenu.edu/",
  },
  {
    country: "US",
    name: "Walla Walla College",
    url: "http://www.wwc.edu/",
  },
  {
    country: "US",
    name: "Walsh College of Accountancy and Business Administration",
    url: "http://www.walshcol.edu/",
  },
  {
    country: "US",
    name: "Walsh University",
    url: "http://www.walsh.edu/",
  },
  {
    country: "US",
    name: "Warner Pacific College",
    url: "http://www.warnerpacific.edu/",
  },
  {
    country: "US",
    name: "Warner Southern College",
    url: "http://www.warner.edu/",
  },
  {
    country: "US",
    name: "Warren Wilson College",
    url: "http://www.warren-wilson.edu/",
  },
  {
    country: "US",
    name: "Wartburg College",
    url: "http://www.wartburg.edu/",
  },
  {
    country: "US",
    name: "Washburn University",
    url: "http://www.washburn.edu/",
  },
  {
    country: "US",
    name: "Washington and Lee University",
    url: "http://www.wlu.edu/",
  },
  {
    country: "US",
    name: "Washington Bible College",
    url: "http://www.bible.edu/",
  },
  {
    country: "US",
    name: "Washington College",
    url: "http://www.washcoll.edu/",
  },
  {
    country: "US",
    name: "Washington State University",
    url: "http://www.wsu.edu/",
  },
  {
    country: "US",
    name: "Washington State University, Spokane",
    url: "http://www.spokane.wsu.edu/",
  },
  {
    country: "US",
    name: "Washington State University, Tri-Cities",
    url: "http://www.tricity.wsu.edu/",
  },
  {
    country: "US",
    name: "Washington State University, Vancouver",
    url: "http://www.vancouver.wsu.edu/",
  },
  {
    country: "US",
    name: "Washington University in St. Louis",
    url: "http://www.wustl.edu/",
  },
  {
    country: "US",
    name: "Wayland Baptist University",
    url: "http://www.wbu.edu/",
  },
  {
    country: "US",
    name: "Waynesburg College",
    url: "http://www.waynesburg.edu/",
  },
  {
    country: "US",
    name: "Wayne State College",
    url: "http://www.wsc.edu/",
  },
  {
    country: "US",
    name: "Wayne State University",
    url: "http://www.wayne.edu/",
  },
  {
    country: "US",
    name: "Webber College",
    url: "http://www.webber.edu/",
  },
  {
    country: "US",
    name: "Webb Institute",
    url: "http://www.webb-institute.edu/",
  },
  {
    country: "US",
    name: "Weber State University",
    url: "http://www.weber.edu/",
  },
  {
    country: "US",
    name: "Webster University",
    url: "http://www.webster.edu/",
  },
  {
    country: "US",
    name: "Webster University North Florida",
    url: "http://www.webster.edu/jack/",
  },
  {
    country: "US",
    name: "Weill Medical College of Cornell University",
    url: "http://www.med.cornell.edu/",
  },
  {
    country: "US",
    name: "Wellesley College",
    url: "http://www.wellesley.edu/",
  },
  {
    country: "US",
    name: "Wells College",
    url: "http://www.wells.edu/",
  },
  {
    country: "US",
    name: "Wentworth Institute of Technology",
    url: "http://www.wit.edu/",
  },
  {
    country: "US",
    name: "Wesleyan College",
    url: "http://www.wesleyan-college.edu/",
  },
  {
    country: "US",
    name: "Wesleyan University",
    url: "http://www.wesleyan.edu/",
  },
  {
    country: "US",
    name: "Wesley College",
    url: "http://www.wesley.edu/",
  },
  {
    country: "US",
    name: "Wesley College Mississippi",
    url: "http://www.wesleycollege.com/",
  },
  {
    country: "US",
    name: "Westbrook University",
    url: "http://www.westbrooku.edu/",
  },
  {
    country: "US",
    name: "West Chester University of Pennsylvania",
    url: "http://www.wcupa.edu/",
  },
  {
    country: "US",
    name: "West Coast University",
    url: "http://www.westcoastuniversity.com/",
  },
  {
    country: "US",
    name: "Western Baptist College",
    url: "http://www.wbc.edu/",
  },
  {
    country: "US",
    name: "Western Bible College",
    url: "http://www.westernbible.edu/",
  },
  {
    country: "US",
    name: "Western Carolina University",
    url: "http://www.wcu.edu/",
  },
  {
    country: "US",
    name: "Western Connecticut State University",
    url: "http://www.wcsu.ctstateu.edu/",
  },
  {
    country: "US",
    name: "Western Governors University",
    url: "http://www.wgu.edu/",
  },
  {
    country: "US",
    name: "Western Illinois University",
    url: "http://www.wiu.edu/",
  },
  {
    country: "US",
    name: "Western International University",
    url: "http://www.west.edu/",
  },
  {
    country: "US",
    name: "Western Kentucky University",
    url: "http://www.wku.edu/",
  },
  {
    country: "US",
    name: "Western Maryland College",
    url: "http://www.wmdc.edu/",
  },
  {
    country: "US",
    name: "Western Michigan University",
    url: "http://www.wmich.edu/",
  },
  {
    country: "US",
    name: "Western New England College",
    url: "http://www.wnec.edu/",
  },
  {
    country: "US",
    name: "Western New Mexico University",
    url: "http://www.wnmu.edu/",
  },
  {
    country: "US",
    name: "Western Oregon University",
    url: "http://www.wou.edu/",
  },
  {
    country: "US",
    name: "Western State College",
    url: "http://www.western.edu/",
  },
  {
    country: "US",
    name: "Western States Chiropractic College",
    url: "http://www.wschiro.edu/",
  },
  {
    country: "US",
    name: "Western State University College of Law",
    url: "http://www.wsulaw.edu/",
  },
  {
    country: "US",
    name: "Western State University College of Law - Orange County",
    url: "http://www.wsulaw.edu/",
  },
  {
    country: "US",
    name: "Western Washington University",
    url: "http://www.wwu.edu/",
  },
  {
    country: "US",
    name: "Westfield State College",
    url: "http://www.wsc.mass.edu/",
  },
  {
    country: "US",
    name: "West Liberty State College",
    url: "http://www.wlsc.wvnet.edu/",
  },
  {
    country: "US",
    name: "Westminster College Fulton",
    url: "http://www.wcmo.edu/",
  },
  {
    country: "US",
    name: "Westminster College New Wilmington",
    url: "http://www.westminster.edu/",
  },
  {
    country: "US",
    name: "Westminster College of Salt Lake City",
    url: "http://www.wcslc.edu/",
  },
  {
    country: "US",
    name: "Westmont College",
    url: "http://www.westmont.edu/",
  },
  {
    country: "US",
    name: "West Suburban College of Nursing",
    url: "http://www.curf.edu/~wscasseyp/wscn.htm",
  },
  {
    country: "US",
    name: "West Texas A&M University",
    url: "http://www.wtamu.edu/",
  },
  {
    country: "US",
    name: "West Virginia School of Osteopathic Medicine",
    url: "http://www.wvsom.edu/",
  },
  {
    country: "US",
    name: "West Virginia State College",
    url: "http://www.wvsc.edu/",
  },
  {
    country: "US",
    name: "West Virginia University",
    url: "http://www.wvu.edu/",
  },
  {
    country: "US",
    name: "West Virginia University Institute of Technology",
    url: "http://wvit.wvnet.edu/",
  },
  {
    country: "US",
    name: "West Virginia Wesleyan College",
    url: "http://www.wvwc.edu/",
  },
  {
    country: "US",
    name: "Westwood College",
    url: "http://www.westwood.edu/",
  },
  {
    country: "US",
    name: "Wheaton College Massachusetts",
    url: "http://www.wheatonma.edu/",
  },
  {
    country: "US",
    name: "Wheeling Jesuit University",
    url: "http://www.wju.edu/",
  },
  {
    country: "US",
    name: "Wheelock College",
    url: "http://www.wheelock.edu/",
  },
  {
    country: "US",
    name: "Whitman College",
    url: "http://www.whitman.edu/",
  },
  {
    country: "US",
    name: "Whittier College",
    url: "http://www.whittier.edu/",
  },
  {
    country: "US",
    name: "Whitworth College",
    url: "http://www.whitworth.edu/",
  },
  {
    country: "US",
    name: "Wichita State University",
    url: "http://www.twsu.edu/",
  },
  {
    country: "US",
    name: "Widener University",
    url: "http://www.widener.edu/",
  },
  {
    country: "US",
    name: "Wilberforce University",
    url: "http://www.wilberforce.edu/",
  },
  {
    country: "US",
    name: "Wilbur Wright College",
    url: "http://www.ccc.edu/wright/",
  },
  {
    country: "US",
    name: "Wiley College",
    url: "http://www.wileyc.edu/",
  },
  {
    country: "US",
    name: "Wilkes University",
    url: "http://www.wilkes.edu/",
  },
  {
    country: "US",
    name: "Willamette University",
    url: "http://www.willamette.edu/",
  },
  {
    country: "US",
    name: "William Carey College",
    url: "http://www.wmcarey.edu/",
  },
  {
    country: "US",
    name: "William Jewell College",
    url: "http://www.jewell.edu/",
  },
  {
    country: "US",
    name: "William Mitchell College of Law",
    url: "http://www.wmitchell.edu/",
  },
  {
    country: "US",
    name: "William Paterson University",
    url: "http://www.wpunj.edu/",
  },
  {
    country: "US",
    name: "William Penn College",
    url: "http://www.wmpenn.edu/",
  },
  {
    country: "US",
    name: "Williams Baptist College",
    url: "http://www.wbcoll.edu/",
  },
  {
    country: "US",
    name: "Williams College",
    url: "http://www.williams.edu/",
  },
  {
    country: "US",
    name: "William Tyndale College",
    url: "http://www.williamtyndale.edu/",
  },
  {
    country: "US",
    name: "William Woods University",
    url: "http://www.wmwoods.edu/",
  },
  {
    country: "US",
    name: "Wilmington College",
    url: "http://www.wilmcoll.edu/",
  },
  {
    country: "US",
    name: "Wilson College",
    url: "http://www.wilson.edu/",
  },
  {
    country: "US",
    name: "Wingate University",
    url: "http://www.wingate.edu/",
  },
  {
    country: "US",
    name: "Winona State University",
    url: "http://www.winona.msus.edu/",
  },
  {
    country: "US",
    name: "Winston-Salem State University",
    url: "http://www.wssu.edu/",
  },
  {
    country: "US",
    name: "Winthrop University",
    url: "http://www.winthrop.edu/",
  },
  {
    country: "US",
    name: "Wisconsin Lutheran College",
    url: "http://www.wlc.edu/",
  },
  {
    country: "US",
    name: "Wisconsin School of Professional Psychology",
    url: "http://www.execpc.com/~wspp/",
  },
  {
    country: "US",
    name: "Wittenberg University",
    url: "http://www.wittenberg.edu/",
  },
  {
    country: "US",
    name: "Wofford College",
    url: "http://www.wofford.edu/",
  },
  {
    country: "US",
    name: "Woodbury University",
    url: "http://www.woodburyu.edu/",
  },
  {
    country: "US",
    name: "Worcester Polytechnic Institute",
    url: "http://www.wpi.edu/",
  },
  {
    country: "US",
    name: "Worcester State College",
    url: "http://www.worc.mass.edu/",
  },
  {
    country: "US",
    name: "Wright Institute",
    url: "http://www.wrightinst.edu/",
  },
  {
    country: "US",
    name: "Wright State University",
    url: "http://www.wright.edu/",
  },
  {
    country: "US",
    name: "Xavier University",
    url: "http://www.xu.edu/",
  },
  {
    country: "US",
    name: "Xavier University of Louisiana",
    url: "http://www.xula.edu/",
  },
  {
    country: "US",
    name: "Yale University",
    url: "http://www.yale.edu/",
  },
  {
    country: "US",
    name: "Yeshiva University",
    url: "http://www.yu.edu/",
  },
  {
    country: "US",
    name: "York College Nebraska",
    url: "http://www.york.edu/",
  },
  {
    country: "US",
    name: "York College of Pennsylvania",
    url: "http://www.yorkcol.edu/",
  },
  {
    country: "US",
    name: "Yorker International University",
    url: "http://www.nyuniversity.net/",
  },
  {
    country: "US",
    name: "York University",
    url: "http://www.yorkuniversity.us/",
  },
  {
    country: "US",
    name: "Youngstown State University",
    url: "http://www.ysu.edu/",
  },
  {
    country: "UY",
    name: "Insituto Universitario - Centro Latinoamericano de Economia Humana - IU Claeh",
    url: "http://www.claeh.edu.uy/",
  },
  {
    country: "UY",
    name: "Universidad Católica del Uruguay",
    url: "http://www.ucu.edu.uy/",
  },
  {
    country: "UY",
    name: "Universidad de la Empresa (UDE)",
    url: "http://www.ude.edu.uy/",
  },
  {
    country: "UY",
    name: "Universidad de la República",
    url: "http://www.universidad.edu.uy/",
  },
  {
    country: "UY",
    name: "Universidad de Montevideo",
    url: "http://www.um.edu.uy/",
  },
  {
    country: "UY",
    name: "Universidad ORT Uruguay",
    url: "http://www.ort.edu.uy/",
  },
  {
    country: "UZ",
    name: "Ferghana Politechnical Institute",
    url: "http://ferpi.dem.ru/",
  },
  {
    country: "UZ",
    name: "International Business School Kelajak ILMI",
    url: "http://www.ibs.uz/",
  },
  {
    country: "UZ",
    name: "Kokand State Pedagogical Institute",
    url: "http://www.qdpi.uz/",
  },
  {
    country: "UZ",
    name: "Management Development Institute of Singapore, Tashkent",
    url: "http://www.mdis.uz/",
  },
  {
    country: "UZ",
    name: "National University of Uzbekistan",
    url: "http://www.nuu.uz/",
  },
  {
    country: "UZ",
    name: "Nukus State Teachers Training Institute",
    url: "http://www.ndpi.uz/",
  },
  {
    country: "UZ",
    name: "Samarkand State University",
    url: "http://www.samdu.uz/",
  },
  {
    country: "UZ",
    name: "Tashkent Automobile and Roads Institute",
    url: "http://www.tayi.uz/",
  },
  {
    country: "UZ",
    name: "Tashkent Institute of Irrigation and Melioration",
    url: "http://www.tiim.uz/",
  },
  {
    country: "UZ",
    name: "Tashkent Islam University",
    url: "http://www.tiu.uz/",
  },
  {
    country: "UZ",
    name: "Tashkent Medical Academy",
    url: "http://www.tma.uz/",
  },
  {
    country: "UZ",
    name: "Tashkent Pharmaceutical Institute",
    url: "http://www.pharmi.uz/",
  },
  {
    country: "UZ",
    name: "Tashkent School of Finance",
    url: "http://www.tfi.uz/",
  },
  {
    country: "UZ",
    name: "Tashkent State Agrarian University",
    url: "http://www.agrar.uz/",
  },
  {
    country: "UZ",
    name: "Tashkent State Technical University",
    url: "http://www.tdtu.uz/",
  },
  {
    country: "UZ",
    name: "Tashkent State University of Economics",
    url: "http://www.tdiu.uz/",
  },
  {
    country: "UZ",
    name: "Tashkent State University of Oriental Studies",
    url: "http://www.tashgiv.uz/",
  },
  {
    country: "UZ",
    name: "Tashkent University of Information Technologies",
    url: "http://www.tuit.uz/",
  },
  {
    country: "UZ",
    name: "Turin Polytechnic University In Tashkent",
    url: "http://www.polito.uz/",
  },
  {
    country: "UZ",
    name: "University of World Economy and Diplomacy",
    url: "http://www.uwed.uz/",
  },
  {
    country: "UZ",
    name: "Uzbek State World Languages University",
    url: "http://www.uzswlu.uz/",
  },
  {
    country: "UZ",
    name: "Westminster International University in Tashkent",
    url: "http://www.wiut.uz/",
  },
  {
    country: "VA",
    name: "Athenaeum Pontificium Regina Apostolorum",
    url: "http://www.upra.org/",
  },
  {
    country: "VA",
    name: "Pontifcia Università Gregoriana",
    url: "http://www.unigre.urbe.it/",
  },
  {
    country: "VA",
    name: "Pontifcia Universitas a S.Thomas Aquinate in Urbe",
    url: "http://www.pust.urbe.it/",
  },
  {
    country: "VA",
    name: "Pontifcia Universitas Lateranensis",
    url: "http://www.pul.it/",
  },
  {
    country: "VA",
    name: "Pontifcia Università Urbaniana",
    url: "http://www.urbaniana.edu/",
  },
  {
    country: "VA",
    name: "Università Pontifcia Salesiana",
    url: "http://www.ups.urbe.it/",
  },
  {
    country: "VC",
    name: "Trinity University School of Medicine",
    url: "http://www.tusom.org/",
  },
  {
    country: "VE",
    name: "Universidad Bicentenaria de Aragua",
    url: "http://www.uba.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Bolivariana de Venezuela",
    url: "http://www.ubv.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Católica Andres Bello",
    url: "http://www.ucab.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Católica Cecilio Acosta",
    url: "http://www.unica.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Católica del Táchira",
    url: "http://www.ucat.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Central de Venezuela",
    url: "http://www.ucv.ve/",
  },
  {
    country: "VE",
    name: "Universidad Centro Occidental Lisandro Alvarado",
    url: "http://www.ucla.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad de Carabobo",
    url: "http://www.uc.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad de Los Andes",
    url: "http://www.ula.ve/",
  },
  {
    country: "VE",
    name: "Universidad del Zulia",
    url: "http://www.luz.ve/index2.html",
  },
  {
    country: "VE",
    name: "Universidad de Oriente",
    url: "http://www.udo.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Dr. Rafael Belloso Chacín",
    url: "http://www.urbe.edu/",
  },
  {
    country: "VE",
    name: "Universidad Fermin Toro",
    url: "http://www.uft.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Gran Mariscal de Ayacucho",
    url: "http://www.ugma.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad José Antonio Páez",
    url: "http://www.ujap.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad José Maria Vargas",
    url: "http://www.ujmv.edu/",
  },
  {
    country: "VE",
    name: "Universidad Metropolitana",
    url: "http://www.unimet.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Monteávila",
    url: "http://www.universidad-monteavila.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Nacional Abierta",
    url: "http://www.una.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Nacional Experimental de Guayana",
    url: "http://www.uneg.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Nacional Experimental de la Fuerza Armada",
    url: "http://www.unefa.edu.ve/",
  },
  {
    country: "VE",
    name: 'Universidad Nacional Experimental de los Llanos Occidentales "Ezequiel Zamora',
    url: "http://www.unellez.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Nacional Experimental del Táchira",
    url: "http://www.unet.edu.ve/",
  },
  {
    country: "VE",
    name: 'Universidad Nacional Experimental "Francisco de Miranda',
    url: "http://www.unefm.edu.ve/",
  },
  {
    country: "VE",
    name: 'Universidad Nacional Experimental Politécnica "Antonio José de Sucre',
    url: "http://www.unexpo.edu.ve/",
  },
  {
    country: "VE",
    name: 'Universidad Nacional Experimental "Rafael Maria Baralt',
    url: "http://www.unermb.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Nacional Experimental Romulo Gallegos",
    url: "http://www.unerg.edu.ve/",
  },
  {
    country: "VE",
    name: 'Universidad Nacional Experimental "Simón Rodriguez',
    url: "http://www.unesr.edu.ve/",
  },
  {
    country: "VE",
    name: 'Universidad Nacional Experimental Sur del Lago "Jesús Maria Semprum',
    url: "http://www.unesur.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Nueva Esparta",
    url: "http://www.une.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Pedagógica Experimental Libertador",
    url: "http://www.upel.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Rafael Urdaneta",
    url: "http://www.uru.edu/",
  },
  {
    country: "VE",
    name: "Universidad Santa Maria Caracas",
    url: "http://usm.trompo.com/",
  },
  {
    country: "VE",
    name: "Universidad Simón Bolivar",
    url: "http://www.usb.ve/",
  },
  {
    country: "VE",
    name: "Universidad Tecnológica del Centro",
    url: "http://www.unitec.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Valle del Momboy",
    url: "http://www.uvm.edu.ve/",
  },
  {
    country: "VE",
    name: "Universidad Yacambu",
    url: "http://www.yacambu.edu.ve/",
  },
  {
    country: "VI",
    name: "University of the Virgin Islands",
    url: "http://www.uvi.edu/",
  },
  {
    country: "VN",
    name: "Banking University of Ho Chi Minh City",
    url: "http://www.buh.edu.vn/",
  },
  {
    country: "VN",
    name: "Binh Duong University",
    url: "http://www.bdu.edu.vn/",
  },
  {
    country: "VN",
    name: "Can-Tho University",
    url: "http://www.ctu.edu.vn/",
  },
  {
    country: "VN",
    name: "Danang College Of Technology",
    url: "http://www.dct.udn.vn/",
  },
  {
    country: "VN",
    name: "Foreign Trade University",
    url: "http://www.ftu.edu.vn/",
  },
  {
    country: "VN",
    name: "FPT University",
    url: "http://www.fpt.edu.vn/",
  },
  {
    country: "VN",
    name: "Hai Duong University",
    url: "http://www.uhd.edu.vn/",
  },
  {
    country: "VN",
    name: "Hanoi Medical University",
    url: "http://www.hmu.edu.vn/",
  },
  {
    country: "VN",
    name: "Hanoi National Economics University",
    url: "http://www.neu.edu.vn/",
  },
  {
    country: "VN",
    name: "Hanoi Open University",
    url: "http://www.hou.edu.vn/",
  },
  {
    country: "VN",
    name: "Hanoi University of Architecture",
    url: "http://www.hau.edu.vn/",
  },
  {
    country: "VN",
    name: "Hanoi University of Civil Engineering",
    url: "http://www.dhxd.edu.vn/",
  },
  {
    country: "VN",
    name: "Hanoi University of Mining and Geology",
    url: "http://www.humg.edu.vn/",
  },
  {
    country: "VN",
    name: "Hanoi University of Science",
    url: "http://www.hus.edu.vn/",
  },
  {
    country: "VN",
    name: "Hanoi University of Science & Technology",
    url: "http://www.hust.edu.vn/",
  },
  {
    country: "VN",
    name: "Ho Chi Minh City Open University",
    url: "http://www.ou.edu.vn/",
  },
  {
    country: "VN",
    name: "Ho Chi Minh City University of Agriculture and Forestry",
    url: "http://www.hcmuaf.edu.vn/",
  },
  {
    country: "VN",
    name: "Ho Chi Minh City University of Architecture",
    url: "http://www.uah.edu.vn/",
  },
  {
    country: "VN",
    name: "Ho Chi Minh City University of Economics",
    url: "http://www.ueh.edu.vn/",
  },
  {
    country: "VN",
    name: "Ho Chi Minh City University of Foreign Languages and Information Technology",
    url: "http://www.huflit.edu.vn/",
  },
  {
    country: "VN",
    name: "Ho Chi Minh City University of Law",
    url: "http://www.hcmulaw.edu.vn/",
  },
  {
    country: "VN",
    name: "Ho Chi Minh City University of Medicine and Pharmacy",
    url: "http://www.yds.edu.vn/",
  },
  {
    country: "VN",
    name: "Ho Chi Minh City University of Natural Sciences",
    url: "http://www.hcmuns.edu.vn/",
  },
  {
    country: "VN",
    name: "Ho Chi Minh City University of Pedagogics",
    url: "http://www.hcmup.edu.vn/",
  },
  {
    country: "VN",
    name: "Ho Chi Minh City University of Social Sciences and Humanities",
    url: "http://www.hcmussh.edu.vn/",
  },
  {
    country: "VN",
    name: "Ho Chi Minh City University of Technology",
    url: "http://www.hcmut.edu.vn/",
  },
  {
    country: "VN",
    name: "Ho Chi Minh City University of Transport",
    url: "http://www.hcmutrans.edu.vn/",
  },
  {
    country: "VN",
    name: "Hong Bang University International",
    url: "http://www.hbu.edu.vn/",
  },
  {
    country: "VN",
    name: "Hue University",
    url: "http://www.hueuni.edu.vn/",
  },
  {
    country: "VN",
    name: "Hue University of Agriculture and Forestry",
    url: "http://www.huaf.edu.vn/",
  },
  {
    country: "VN",
    name: "Institute of Finance",
    url: "http://www.hvtc.edu.vn/",
  },
  {
    country: "VN",
    name: "Posts & Telecommunications Institute of Technology",
    url: "http://www.ptit.edu.vn/",
  },
  {
    country: "VN",
    name: "RMIT International University Vietnam",
    url: "http://www.rmit.edu.vn/",
  },
  {
    country: "VN",
    name: "Saigon University",
    url: "http://www.sgu.edu.vn/",
  },
  {
    country: "VN",
    name: "Tay Nguyen University",
    url: "http://www.taynguyenuni.edu.vn/",
  },
  {
    country: "VN",
    name: "Thainguyen University of Agriculture and Forestry",
    url: "http://www.tuaf.edu.vn/",
  },
  {
    country: "VN",
    name: "University of Da Lat",
    url: "http://www.dlu.edu.vn/",
  },
  {
    country: "VN",
    name: "University of Da Nang",
    url: "http://www.udn.vn/",
  },
  {
    country: "VN",
    name: "University of Technical Education Ho Chi Minh City",
    url: "http://www.hcmute.edu.vn/",
  },
  {
    country: "VN",
    name: "University of Transport and Communications",
    url: "http://www.utc.edu.vn/",
  },
  {
    country: "VN",
    name: "Vietnam Maritime University",
    url: "http://www.vimaru.edu.vn/",
  },
  {
    country: "VN",
    name: "Vietnam National University Hanoi",
    url: "http://www.vnu.edu.vn/",
  },
  {
    country: "VN",
    name: "Vietnam National University Ho Chi Minh City",
    url: "http://www.vnuhcm.edu.vn/",
  },
  {
    country: "VN",
    name: "Vietnam National University of Agriculture",
    url: "http://www.vnua.edu.vn/",
  },
  {
    country: "VN",
    name: "Water Resources University",
    url: "http://www.hwru.edu.vn/",
  },
  {
    country: "WS",
    name: "National University of Samoa",
    url: "http://www.nus.edu.ws/",
  },
  {
    country: "WS",
    name: "University of the South Pacific School of Agriculture",
    url: "http://www.usp.ac.fj/wwwsoa/",
  },
  {
    country: "YE",
    name: "Alahgaff University",
    url: "http://www.ahgaff.edu/",
  },
  {
    country: "YE",
    name: "Al-Eman University",
    url: "http://www.jameataleman.org/",
  },
  {
    country: "YE",
    name: "Al-Nasser University",
    url: "http://www.al-edu.com/",
  },
  {
    country: "YE",
    name: "Hadhramout University of Science and Technology",
    url: "http://www.hust.edu.ye/",
  },
  {
    country: "YE",
    name: "Hodeidah University",
    url: "http://www.hoduniv.net.ye/",
  },
  {
    country: "YE",
    name: "Queen Arwa University",
    url: "http://www.arwauniversity.org/",
  },
  {
    country: "YE",
    name: "Saba University",
    url: "http://www.sabauni.net/",
  },
  {
    country: "YE",
    name: "Sana'a University",
    url: "http://www.su.edu.ye/",
  },
  {
    country: "YE",
    name: "Taiz University",
    url: "http://www.taiz.edu.ye/",
  },
  {
    country: "YE",
    name: "Thamar University",
    url: "http://www.thuniv.net/",
  },
  {
    country: "YE",
    name: "University of Aden",
    url: "http://www.aden-univ.net/",
  },
  {
    country: "YE",
    name: "University of Modern Sciences",
    url: "http://www.ums-edu.com/",
  },
  {
    country: "YE",
    name: "University of Science and Technology Sana'a",
    url: "http://www.ust.edu/",
  },
  {
    country: "YE",
    name: "Yemenia University",
    url: "http://www.yuniv.net/",
  },
  {
    country: "ZA",
    name: "Cape Peninsula University of Technology",
    url: "http://www.cput.ac.za/",
  },
  {
    country: "ZA",
    name: "Central University of Technology, Free State",
    url: "http://www.cut.ac.za/",
  },
  {
    country: "ZA",
    name: "Durban Institute of Technology",
    url: "http://www.dit.ac.za/",
  },
  {
    country: "ZA",
    name: "Nelson Mandela Metropolitan University",
    url: "http://www.nmmu.ac.za/",
  },
  {
    country: "ZA",
    name: "Rhodes University",
    url: "http://www.ru.ac.za/",
  },
  {
    country: "ZA",
    name: "Tshwane University of Technology",
    url: "http://www.tut.ac.za/",
  },
  {
    country: "ZA",
    name: "University of Cape Town",
    url: "http://www.uct.ac.za/",
  },
  {
    country: "ZA",
    name: "University of Fort Hare",
    url: "http://www.ufh.ac.za/",
  },
  {
    country: "ZA",
    name: "University of Johannesburg",
    url: "http://www.uj.ac.za/",
  },
  {
    country: "ZA",
    name: "University of KwaZulu-Natal",
    url: "http://www.ukzn.ac.za/",
  },
  {
    country: "ZA",
    name: "University of Limpopo",
    url: "http://www.ul.ac.za/",
  },
  {
    country: "ZA",
    name: "University of North West",
    url: "http://www.uniwest.ac.za/",
  },
  {
    country: "ZA",
    name: "University of Pretoria",
    url: "http://www.up.ac.za/",
  },
  {
    country: "ZA",
    name: "University of South Africa",
    url: "http://www.unisa.ac.za/",
  },
  {
    country: "ZA",
    name: "University of Stellenbosch",
    url: "http://www.sun.ac.za/",
  },
  {
    country: "ZA",
    name: "University of the Free State",
    url: "http://www.uovs.ac.za/",
  },
  {
    country: "ZA",
    name: "University of the Western Cape",
    url: "http://www.uwc.ac.za/",
  },
  {
    country: "ZA",
    name: "University of Venda",
    url: "http://www.univen.ac.za/",
  },
  {
    country: "ZA",
    name: "University of Witwatersrand",
    url: "http://www.wits.ac.za/",
  },
  {
    country: "ZA",
    name: "University of Zululand",
    url: "http://www.uzulu.ac.za/",
  },
  {
    country: "ZA",
    name: "Vaal University of Technology",
    url: "http://www.vut.ac.za/",
  },
  {
    country: "ZA",
    name: "Walter Sisulu University for Technology and Science",
    url: "http://www.wsu.ac.za/",
  },
  {
    country: "ZM",
    name: "Cavendish University",
    url: "http://www.cavendishza.org/",
  },
  {
    country: "ZM",
    name: "Copperbelt University",
    url: "http://www.cbu.edu.zm/",
  },
  {
    country: "ZM",
    name: "Mulungushi University",
    url: "http://www.mu.ac.zm/",
  },
  {
    country: "ZM",
    name: "Northrise University",
    url: "http://www.northrise.org/",
  },
  {
    country: "ZM",
    name: "Rusangu University",
    url: "http://www.rusangu-university.edu.zm/",
  },
  {
    country: "ZM",
    name: "University of Lusaka",
    url: "http://www.unilus.ac.zm/",
  },
  {
    country: "ZM",
    name: "University of Zambia",
    url: "http://www.unza.zm/",
  },
  {
    country: "ZM",
    name: "Zambian Open University",
    url: "http://www.zaou.ac.zm/",
  },
  {
    country: "ZW",
    name: "Africa University",
    url: "http://www.africau.edu/",
  },
  {
    country: "ZW",
    name: "Bindura University of Science Education",
    url: "http://www.buse.ac.zw/",
  },
  {
    country: "ZW",
    name: "Catholic University in Zimbabwe",
    url: "http://www.cuz.ac.zw/",
  },
  {
    country: "ZW",
    name: "Chinhoyi University of Technology",
    url: "http://www.cut.ac.zw/",
  },
  {
    country: "ZW",
    name: "Great Zimbabwe University",
    url: "http://www.gzu.ac.zw/",
  },
  {
    country: "ZW",
    name: "Harare Institute of Technology",
    url: "http://www.hit.ac.zw/",
  },
  {
    country: "ZW",
    name: "Lupane State University",
    url: "http://www.lsu.ac.zw/",
  },
  {
    country: "ZW",
    name: "Midlands State University",
    url: "http://www.msu.ac.zw/",
  },
  {
    country: "ZW",
    name: "National University of Science and Technology Bulawayo",
    url: "http://www.nust.ac.zw/",
  },
  {
    country: "ZW",
    name: "Reformed Church University",
    url: "http://www.rcu.ac.zw/",
  },
  {
    country: "ZW",
    name: "Solusi University",
    url: "http://www.solusi.ac.zw/",
  },
  {
    country: "ZW",
    name: "University of Zimbabwe",
    url: "http://www.uz.ac.zw/",
  },
  {
    country: "ZW",
    name: "Women's University in Africa",
    url: "http://www.wua.ac.zw/",
  },
  {
    country: "ZW",
    name: "Zimbabwe Ezekiel Guti University",
    url: "http://www.zegu.ac.zw/",
  },
  {
    country: "ZW",
    name: "Zimbabwe Open University",
    url: "http://www.zou.ac.zw/",
  },
];

export function getInstitutionsList() {
  let institutions = [];
  let institutionalNames = [];
  institutionsData.forEach((institution) => {
    institutionalNames.push(`${institution.name} (${institution.country})`);
  });

  institutionalNames.sort();
  institutionalNames.forEach((institution) => {
    institutions.push({ name: institution, id: institution });
  });

  // console.log(institutions);
  return institutions;
}

export function getInstitutionsByCountry(country) {
  const countryCode = country.split(" - ")[1];
  // console.log(countryCode);

  let institutionalNames = [];
  let institutions = [];
  const countryInstitutions = institutionsData.filter(
    (institution) => institution.country === countryCode
  );
  // console.log(countryInstitutions);
  if (countryInstitutions.length < 1) {
    return getInstitutionsList();
  } else {
    countryInstitutions.forEach((institution) => {
      institutionalNames.push(`${institution.name} (${institution.country})`);
    });
    institutionalNames.sort();
    institutionalNames.forEach((institution) => {
      institutions.push({ name: institution, id: institution });
    });

    return institutions;
  }
}
