import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { toast } from "react-toastify";
import { getRobotResponse } from "../utils/getRobotResponse";
import Form from "./common/form";
import Joi from "joi-browser";
import { getHistorySections } from "../utils/getHistorySections";
import Timer from "./common/timer";
import firebase from "firebase/app";

class RobotTest extends Form {
  state = {
    data: {
      addSection: "",
    },
    userInput: "",
    currentSection: "",
    errors: {},
    startClerking: false,
    showAddSection: false,
    clerkingTime: 0,
    timed: false,
    chatOverview: [],
    showChatOverView: false,
    showDiagnosis: false,
    users: [],
    osces: [],
  };

  componentDidMount() {
    let questions = [];
    const getQestions = firebase
      .firestore()
      .collection("questions")
      .where("subject", "==", "pediatrics")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          questions.push(doc.data());
        });
      });

    Promise.all([getQestions]).then(() => {
      console.log(questions);
    });
  }

  schema = {
    addSection: Joi.string().required().label("Add a Section"),
  };

  handleUserInputChange = (event) => {
    const userInput = event.currentTarget.value;
    this.setState({ userInput });
  };

  handleUserInputSubmit = (event) => {
    event.preventDefault();
    const { userInput, currentSection, chatOverview } = this.state;

    if (userInput.length < 1) {
      toast.error("Please enter a message");
      return;
    }

    if (getRobotResponse(userInput, currentSection) === "") {
      return;
    }

    const chatChunk = document.createElement("div");
    chatChunk.className = "chat-chunk";

    const userParagraph = document.createElement("p");
    userParagraph.className = "user-paragraph";
    userParagraph.innerText = userInput;
    userParagraph.style.color = "white";
    chatChunk.appendChild(userParagraph);

    const chatBreak = document.createElement("br");
    chatChunk.appendChild(chatBreak);

    const robotResParagraph = document.createElement("p");
    robotResParagraph.className = "robot-paragraph";
    const robotParaInnerText = getRobotResponse(userInput, currentSection);
    robotResParagraph.innerText = robotParaInnerText;
    robotResParagraph.style.color = "white";
    robotResParagraph.style.marginTop = "5px";
    robotResParagraph.style.marginBottom = "20px";
    chatChunk.appendChild(robotResParagraph);

    const chatContainer = document.getElementById("chat-container");
    chatContainer.appendChild(chatChunk);

    const currentSectionNo = chatOverview.length;
    console.log(currentSectionNo);
    const newData = {
      input: userInput,
      response: robotParaInnerText,
    };

    chatOverview[currentSectionNo - 1].data.push(newData);

    console.log(chatOverview);

    this.setState({ userInput: "" });
  };

  handleAddSectionSubmit = (event) => {
    //  console.log("adding current section")
    event.preventDefault();
    const { addSection } = this.state.data;
    const { chatOverview } = this.state;
    const currentSecName = this.historySections.filter(
      (section) => section.id === addSection
    )[0].name;

    const sectionNo = chatOverview.length + 1;
    chatOverview.push({
      id: addSection,
      data: [],
      sectionNo: sectionNo,
    });

    const chatContainer = document.getElementById("chat-container");

    const currentSec = document.createElement("div");
    currentSec.className = "current-sec";
    currentSec.style.textAlign = "center";
    currentSec.innerHTML = `<br/><br/> <h5>${currentSecName.toUpperCase()}</h5>`;
    chatContainer.appendChild(currentSec);

    this.setState({
      currentSection: addSection,
      data: { addSection: "" },
      showAddSection: false,
      chatOverview,
    });
  };

  watchTimer;

  startClerking = () => {
    const { clerkingTime } = this.state;
    if (clerkingTime > 0) {
      this.setState({ timed: true });
    }

    this.watchTimer = setInterval(this.checkTimerEnd, 1000);
    this.setState({ startClerking: true });
  };

  showAddSection = () => {
    this.setState({ showAddSection: true });
  };

  showChatOverView = () => {
    this.setState({ showChatOverView: true });

    clearInterval(this.watchTimer);
  };

  historySections = getHistorySections();

  handleClerkingTime = (event) => {
    const clerkingTime = event.currentTarget.value;
    this.setState({ clerkingTime });
  };

  renderClerkingTime = () => {
    const { clerkingTime } = this.state;

    return clerkingTime;
  };

  showDiagnosis = () => {
    this.setState({ showDiagnosis: true });
  };

  checkTimerEnd = () => {
    if (sessionStorage.getItem("timeUp")) {
      console.log("timeUp");
      this.setState({ showChatOverView: true });

      clearInterval(this.watchTimer);
      sessionStorage.removeItem("timeUp");
    }
  };

  render() {
    const {
      startClerking,
      showAddSection,
      clerkingTime,
      timed,
      showChatOverView,
      chatOverview,
      showDiagnosis,
    } = this.state;

    return (
      <React.Fragment>
        {!startClerking && !showChatOverView && (
          <Grid
            container
            id="robot-container"
            item
            style={{
              display: "flex",
              margin: "20px auto",
              width: "80%",
            }}
          >
            <Typography variant="body1">
              Hi there. This is <strong>Robotient</strong> (Robot Patient). An
              ongoing project to build a SMART DIGITAL PATIENT that students can
              use to practice their history taking and diagnostics skills.
            </Typography>
            <br />
            <Typography variant="body1">
              {" "}
              This is just a prototype to show you how Robotient would work.
              Robotient would be much smarter and more intuitive by the time
              it's officially released.
            </Typography>
            <br />

            <div>
              <Typography variant="body1">A few points to note:</Typography>
              <Typography variant="caption">
                <strong>-</strong> Use the "Add Section" to organize your
                history like you would normally do. If you ask a question under
                the wrong section, Robotient might get confused.
              </Typography>
              <br />
              <Typography variant="caption">
                <strong>-</strong> Type your message or question in the text box
                to speak to Robotient (After you press start).
              </Typography>
              <br />
              <Typography variant="caption">
                <strong>-</strong> This prototype can only interact with you up
                to On Direct Questioning.
              </Typography>
              <br />
              <Typography variant="caption">
                <strong>-</strong> A little specificity helps. Example, ask
                "when did the
                <strong> abdominal pain</strong> start" instead of "when did{" "}
                <strong>it</strong> start". Robotient might not be sure what you
                are asking about.
              </Typography>
              <br />
              <Typography variant="caption">
                <strong>-</strong> If Robotient's response doesn't make sense,
                rephrase your question. If he still doesn't understand you, use
                fewer words to ask your question. You can use terminology like
                "site of pain" or "onset of pain" as a last resort.
              </Typography>
              <br />
              <br />
            </div>
            <div>
              <Typography variant="h6">
                Let's see if you can diagnose this patient.
              </Typography>
              <TextField
                disabled={Boolean(startClerking)}
                style={{ width: "100px", margin: "0 30px 0 0" }}
                label="time(mins)-optional"
                value={clerkingTime}
                onChange={this.handleClerkingTime}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                variant="contained"
                size="large"
                color="secondary"
                onClick={this.startClerking}
              >
                Start
              </Button>
            </div>
          </Grid>
        )}

        {startClerking && !showChatOverView && (
          <div id="robot-container">
            {timed && <Timer timeLength={this.renderClerkingTime()} />}
            <div id="chat-container"></div>

            {showAddSection && (
              <form
                onSubmit={this.handleAddSectionSubmit}
                style={{
                  border: "2px solid red",
                  padding: "5px",
                  margin: "20px 0",
                }}
              >
                {this.renderSelect(
                  "addSection",
                  "Add A Section",
                  this.historySections
                )}
                {this.renderSubmitButton("Add")}
              </form>
            )}

            <form onSubmit={this.handleUserInputSubmit}>
              {/* {this.renderTextField("userInput", "Enter your message")} */}
              <input
                type="text"
                id="user-input"
                onChange={this.handleUserInputChange}
                value={this.state.userInput}
                placeholder="Enter message here"
              />
            </form>

            <Button
              variant="outlined"
              size="small"
              color="secondary"
              id="submit-clerk"
              onClick={this.showChatOverView}
            >
              Finish & Submit
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              id="show-add-section"
              onClick={this.showAddSection}
            >
              Add Section
            </Button>
          </div>
        )}

        {showChatOverView && (
          <div id="chat-overview">
            <p>
              <strong>
                Use the History Overview Below to write your Summary
              </strong>
            </p>
            <br />
            <br />

            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={this.showDiagnosis}
            >
              See the Diagnosis
            </Button>
            {showDiagnosis && (
              <div>
                <p>
                  DIAGNOSIS:{" "}
                  <strong>
                    Intestinal Obstruction secondary to an obstructed incisional
                    Hernia
                  </strong>
                </p>
                <p>Did You get it Right?</p>
              </div>
            )}
            <br />
            <br />

            <h4>HISTORY OVERVIEW</h4>
            {chatOverview.map((chatChunk) => (
              <div key={chatChunk.id}>
                <h6 className="section-name">
                  {
                    this.historySections.filter(
                      (sec) => sec.id === chatChunk.id
                    )[0].name
                  }
                </h6>
                {chatChunk.data.map((data) => (
                  <div key={`${data.input}-${data.response}`}>
                    <p className="user-paragraph">{data.input}</p>
                    <p className="robot-paragraph">{data.response}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default RobotTest;
