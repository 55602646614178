import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import { toast } from "react-toastify";
import Timer from "../components/common/timer";
import firebase from "firebase/app";
import PreLoader from "./common/preLoader";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ReactHtmlParser from "react-html-parser";
import { CheckBox } from "@material-ui/icons";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import {
  getSubdivisions,
  getSubjects,
  getTopics,
} from "../utils/getCategories";
import { removeEmptySpaces } from "../utils/removeEmptySpaces";

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

export default function FullWidthTabs({ match }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  // const [goToValue, setGoToValue] = React.useState("");
  const [quizNoQues, setQuizNoQues] = React.useState("");
  const [timed, setTimed] = React.useState(false);
  const [quizStart, setQuizStart] = React.useState(false);
  const [chosenOption, setChosenOption] = React.useState("");
  const [PracticeChosenOption, setPracticeChosenOption] = React.useState("");
  const [checkedOptions, setCheckedOptions] = React.useState([
    "F",
    "F",
    "F",
    "F",
    "F",
  ]);
  const [practiceCheckedOptions, setPracticeCheckedOptions] = React.useState([
    "F",
    "F",
    "F",
    "F",
    "F",
  ]);
  const [currentQuizList, setCurrentQuizList] = React.useState([]);
  const [queIndex, setQueIndex] = React.useState(0);
  const [practiceQueIndex, setPracticeQueIndex] = React.useState(0);
  const [disableNext, setDisableNext] = React.useState(false);
  const [practiceDisabledNext, setPracticeDisableNext] = React.useState(false);
  const [practiceEnablePrevious, setPracticeEnablePrevious] =
    React.useState(false);
  const [practiceShowAnsExp, setPracticeShowAnsExp] = React.useState(false);
  const [finished, setFinished] = React.useState(false);
  const [score, setScore] = React.useState(0);
  const [maxScore, setMaxScore] = React.useState(0);
  const [questionsWrong, setQuestionsWrong] = React.useState([]);
  const [quesWrongCorrespondent, setQuestionsWrongCorrespondent] =
    React.useState([]);
  const [wrongToDisplayIndex, setWrongToDisplayIndex] = React.useState("");
  const [questionsCorrect, setQuestionsCorrect] = React.useState([]);
  const [quesCorrectCorrespondent, setQuestionsCorrectCorrespondent] =
    React.useState([]);
  const [correctToDisplayIndex, setCorrectToDisplayIndex] = React.useState("");
  const [unansweredToDisplayIndex, setUnansweredToDisplayIndex] =
    React.useState("");
  const [questionsUnanswered, setQuestionsUnanswered] = React.useState([]);
  const [quizPack, setQuizPack] = React.useState({});
  const [timePerQue, setTimePerQue] = React.useState(0);
  const [simulatedExamMode, setSimulatedExamMode] = React.useState(false);
  const [allQuestions, setAllQuestions] = React.useState({});
  const [unansweredQuesList, setUnansweredQuesList] = React.useState({});
  const [user, setUser] = React.useState({});
  const [forceSignIn, setForceSignIn] = React.useState(false);
  const [forceSignInForQuiz, setForceSignInForQuiz] = React.useState(false);
  const [spacedRepetition, setSpacedRepetition] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openCreateFlashCard, setOpenCreateFlashCard] = React.useState(false);
  // const [openInstructions, setOpenInstructions] = React.useState(false);
  // const [open3d, setOpen3d] = React.useState(false);
  const [openEditQuestion, setOpenEditQuestion] = React.useState(false);
  const [openOptionsAfterSession, setOpenOptionsAfterSession] =
    React.useState(false);
  const [openSureToDelete, setOpenSureToDelete] = React.useState(false);
  const [issueToReport, setIssueToReport] = React.useState("");
  const [flashcardData, setFlashcardData] = React.useState({
    cardFrontText: "",
    cardBackText: "",
    subject: "",
    subdivision: "",
    topic: "",
    allowSharing: true,
  });
  // const [threeDModelUrl, setThreeDModelUrl] = React.useState("");
  const [practiceQueScore, setPracticeQueScore] = React.useState(0);
  const [editedQueData, seteditedQueData] = React.useState({
    question: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    optionE: "",
    answer: "",
    answerExplanation: "",
    subject: "",
    subdivision: "",
    topic: "",
    queId: "",
  });
  const [submitting, setSubmitting] = React.useState(false);
  const [permitQuestionEdit, setPermitQuestionEdit] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [makeGoPremium, setMakeGoPremium] = React.useState(false);
  const [isPremiumUser, setIsPremiumUser] = React.useState(true);
  const [subjects, setSubjects] = React.useState([]);
  const [subjectsWithRelevantContent, setSubjectsWithRelevantContent] =
    React.useState([]);

  useEffect(() => {
    const quizId = match.params.quizid;
    const userId = localStorage.getItem("currentUserId");
    const userLevel = localStorage.getItem("userLevel");
    const statistics = JSON.parse(localStorage.getItem("statistics"));
    sessionStorage.removeItem("timeUp");
    sessionStorage.removeItem("questionsUnanswered");
    sessionStorage.removeItem("allQuestions");
    sessionStorage.removeItem("maxScore");

    // GETTING THE SUBJECTS LIST
    const subjects = getSubjects(statistics.subjects, userLevel);
    const findSubjectsWithRelevantContent = subjects.filter(
      (subject) => subject.noOfQuestions > 0
    );
    const subjectsWithRelevantContent = getSubjects(
      findSubjectsWithRelevantContent,
      userLevel
    );
    setSubjects(subjects);
    setSubjectsWithRelevantContent(subjectsWithRelevantContent);

    if (userId) {
      firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .onSnapshot((doc) => {
          // console.log(doc.data().seenQuestions);
          const user = doc.data();
          setUser(user);

          if (user.volunteer && user.volunteer.length > 0) {
            setIsPremiumUser(true);
          }

          firebase
            .firestore()
            .collection("premiumUsers")
            .doc(user.email)
            .onSnapshot((doc) => {
              const premiumUser = doc.data();
              if (
                (premiumUser &&
                  premiumUser.email.length > 3 &&
                  premiumUser.subscription.type === "basic") ||
                hasAccesLevel
              ) {
                setIsPremiumUser(true);
              } else {
                setIsPremiumUser(false);
              }
            });
        });
    }

    let hasAccesLevel = false;

    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.email) {
        const quizPack = JSON.parse(localStorage.getItem("currentCustom"));
        //FOR CHECKING CUSTOM STATES
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            // console.log(idTokenResult.claims)
            if (
              idTokenResult.claims.admin &&
              idTokenResult.claims.admin === true
            ) {
              setPermitQuestionEdit(true);
              setIsAdmin(true);
              hasAccesLevel = true;
            } else if (
              idTokenResult.claims.editor &&
              idTokenResult.claims.editor.length > 0
            ) {
              const features = idTokenResult.claims.editor;
              // console.log(features)
              if (features.includes("questionbank")) {
                setPermitQuestionEdit(true);
                hasAccesLevel = true;
              }
            } else if (
              idTokenResult.claims.moderator &&
              idTokenResult.claims.moderator.features &&
              idTokenResult.claims.moderator.features.length > 0
            ) {
              const features = idTokenResult.claims.moderator.features;
              const subjects = idTokenResult.claims.moderator.subjects;
              // console.log(features)
              // console.log(subjects)
              const question = quizPack.questions[practiceQueIndex];
              if (
                features.includes("questionbank") &&
                subjects.includes(question.subject)
              ) {
                // console.log("question included")
                setPermitQuestionEdit(true);
                hasAccesLevel = true;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
    // let seenQues = [];
    // if(user && user.seenQuestions) {
    //   seenQues = user.seenQuestions;
    // } else {
    //   seenQues = [];
    // }
    // console.log(seenQues);

    if (quizId === "custom") {
      const quizPack = JSON.parse(localStorage.getItem("currentCustom"));
      // console.log(quizPack);
      // for(let i=0; i<quizPack.questions.length; i++) {
      //   if(!seenQues.includes(quizPack.questions[i].id)) {
      //     seenQues.push(quizPack.questions[i].id);
      //   }
      // }
      setQuizPack(quizPack);

      const allQuestions = JSON.parse(localStorage.getItem("currentCustom"));
      setAllQuestions(allQuestions);
      sessionStorage.setItem("allQuestions", JSON.stringify(allQuestions));
      // const timePerQue = quizPack.quizTime / quizPack.questions.length;
      setTimePerQue(1.5);

      // if(user) {
      //   firebase.firestore().collection("users").doc(userId)
      //   .update({
      //     seenQuestions: seenQues
      //   })
      // }
    } else if (quizId === "scheduled") {
      const quizPack = JSON.parse(localStorage.getItem("currentScheduled"));
      // for(let i=0; i<quizPack.questions.length; i++) {
      //   if(!seenQues.includes(quizPack.questions[i].id)) {
      //     seenQues.push(quizPack.questions[i].id);
      //   }
      // }
      setQuizPack(quizPack);

      const allQuestions = JSON.parse(localStorage.getItem("currentScheduled"));
      setAllQuestions(allQuestions);
      sessionStorage.setItem("allQuestions", JSON.stringify(allQuestions));
      // const timePerQue = quizPack.quizTime / quizPack.questions.length;
      setTimePerQue(1.5);

      // if(user) {
      //   firebase.firestore().collection("users").doc(userId)
      //   .update({
      //     seenQuestions: seenQues
      //   })
      // }
    } else if (quizId === "simulated-exam") {
      setSimulatedExamMode(true);
      const quizPack = JSON.parse(localStorage.getItem("currentSimulatedExam"));
      // for(let i=0; i<quizPack.questions.length; i++) {
      //   if(!seenQues.includes(quizPack.questions[i].id)) {
      //     seenQues.push(quizPack.questions[i].id);
      //   }
      // }
      setQuizPack(quizPack);
      setQuizNoQues(quizPack.questions.length);

      const allQuestions = JSON.parse(
        localStorage.getItem("currentSimulatedExam")
      );
      setAllQuestions(allQuestions);
      sessionStorage.setItem("allQuestions", JSON.stringify(allQuestions));
      const timePerQue = quizPack.quizTime / quizPack.questions.length;
      setTimePerQue(timePerQue);

      // if(user){
      //   firebase.firestore().collection("users").doc(userId)
      //   .update({
      //     seenQuestions: seenQues
      //   })
      // }
    } else if (quizId === "spaced-repetition") {
      setSimulatedExamMode(true);
      setSpacedRepetition(true);
      const quizPack = JSON.parse(
        localStorage.getItem("currentSpacedRepetition")
      );
      // for(let i=0; i<quizPack.questions.length; i++) {
      //   if(!seenQues.includes(quizPack.questions[i].id)) {
      //     seenQues.push(quizPack.questions[i].id);
      //   }
      // }
      setQuizPack(quizPack);
      setQuizNoQues(quizPack.questions.length);

      const allQuestions = JSON.parse(
        localStorage.getItem("currentSpacedRepetition")
      );
      setAllQuestions(allQuestions);
      sessionStorage.setItem("allQuestions", JSON.stringify(allQuestions));
      const timePerQue = quizPack.quizTime / quizPack.questions.length;
      setTimePerQue(timePerQue);

      // if(user){
      //   firebase.firestore().collection("users").doc(userId)
      //   .update({
      //     seenQuestions: seenQues
      //   })
      // }
    } else {
      firebase
        .firestore()
        .collection("quizzes")
        .where("quizId", "==", quizId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const quizPack = doc.data();
            setQuizPack(quizPack);
            setAllQuestions(doc.data());
            sessionStorage.setItem("allQuestions", JSON.stringify(doc.data()));
            // console.log(quizPack);

            // const timePerQue = quizPack.quizTime / quizPack.questions.length;
            setTimePerQue(1.2);
          });
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }

    // setUser(user);
  }, []);

  const systems = () => {
    const { subject } = flashcardData;
    const subjectData = subjectsWithRelevantContent.filter(
      (relevantSubject) => relevantSubject.id === subject
    )[0];
    const relevantSubdivisions = subjectData.subdivisions.filter(
      (theSubdivision) => theSubdivision.noOfQuestions > 0
    );
    subjectData.subdivisions = relevantSubdivisions;
    return getSubdivisions([subject], [subjectData]);
  };

  const topics = () => {
    const { subject, subdivision } = flashcardData;

    let subjectData = subjectsWithRelevantContent.filter(
      (relevantSubject) => relevantSubject.id === subject
    )[0];
    let subdivisionData = subjectData.subdivisions.filter(
      (theSubdivision) => theSubdivision.id === subdivision
    )[0] || { topics: [] };
    const relevantTopics = subdivisionData.topics.filter(
      (theTopic) => theTopic.noOfQuestions > 0
    );
    subdivisionData.topics = relevantTopics;
    const newSubjectData = {
      ...subjectData,
      subdivisions: [subdivisionData],
    };

    return getTopics(subject, subdivision, [newSubjectData]);
  };

  function handleChange(event, newValue) {
    event.preventDefault();
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  let randQuesList = [];
  let quizQuesList = quizPack.questions;
  let watchTimer = setInterval(checkTimerEnd, 1000);
  const answerOptions = [
    { name: "A" },
    { name: "B" },
    { name: "C" },
    { name: "D" },
    { name: "E" },
    // {name: "TT"},
    // {name: "FF"},
    // {name: "TF"},
    // {name: "FT"},
    // {name: "TTT"},
    // {name: "TTF"},
    // {name: "TFF"},
    // {name: "FFF"},
    // {name: "FTT"},
    // {name: "FFT"},
    // {name: "FFF"},
    // {name: "TFT"},
    // {name: "FTF"},
    { name: "TTTT" },
    { name: "TTTF" },
    { name: "TTFF" },
    { name: "TFFF" },
    { name: "FFFF" },
    { name: "FTTT" },
    { name: "FTFT" },
    { name: "FTFF" },
    { name: "FFTF" },
    { name: "FTTF" },
    { name: "TFFT" },
    { name: "TTTTT" },
    { name: "TTTFT" },
    { name: "TTFTT" },
    { name: "TFTTT" },
    { name: "FTTTT" },
    { name: "TTTFF" },
    { name: "TTFFT" },
    { name: "TFFTT" },
    { name: "FFTTT" },
    { name: "TFTFT" },
    { name: "FTFTT" },
    { name: "TTFTF" },
    { name: "TTFFF" },
    { name: "TFFFT" },
    { name: "FFFTT" },
    { name: "TFTFF" },
    { name: "FTFFT" },
    { name: "TFFTF" },
    { name: "TFFFF" },
    { name: "FFFFT" },
    { name: "FTFFF" },
    { name: "FFFTF" },
    { name: "FFFFF" },
    { name: "TTTTF" },
    { name: "FTTFF" },
    { name: "FTTTF" },
    { name: "FFTFF" },
  ];

  function handleQuizStart(e) {
    e.preventDefault();
    setTimed(true);
    // console.log(quizNoQues);

    if (quizNoQues < 2) {
      toast.error("Sorry the minimum number of questions allowed is 2");
      return;
    }

    if (quizNoQues > quizPack.questions.length) {
      toast.error(
        `Sorry the maximum number of questions available is ${quizPack.questions.length}`
      );
      return;
    }

    if (timed) {
      toast.info(
        `time for this quiz was calculated based on the number of questions you entered`
      );
    }

    setQuizStart(true);

    for (let x = 0; x < quizNoQues; x++) {
      const index = Math.floor(Math.random() * quizQuesList.length);
      randQuesList.push(quizQuesList[index]);
      quizQuesList.splice(index, 1);
    }

    localStorage.setItem("currentQuizList", JSON.stringify(randQuesList));

    setCurrentQuizList(JSON.parse(localStorage.getItem("currentQuizList")));
    setQuestionsUnanswered(JSON.parse(localStorage.getItem("currentQuizList")));
    sessionStorage.setItem("questionsUnanswered", JSON.stringify(randQuesList));
  }

  function handlePracticeStart(e) {
    e.preventDefault();
  }

  function checkTimerEnd() {
    if (sessionStorage.getItem("timeUp")) {
      // console.log("timeUp");
      timeUp();
      clearInterval(watchTimer);
      sessionStorage.removeItem("timeUp");
    }
  }

  if (finished) {
    clearInterval(watchTimer);
  }

  function handleChosenOption(event) {
    let options = checkedOptions;
    if (event.currentTarget.name === "t/f") {
      switch (event.currentTarget.value) {
        case "a":
          options[0] = event.currentTarget.checked;
          break;
        case "b":
          options[1] = event.currentTarget.checked;
          break;
        case "c":
          options[2] = event.currentTarget.checked;
          break;
        case "d":
          options[3] = event.currentTarget.checked;
          break;
        case "e":
          options[4] = event.currentTarget.checked;
          break;
        default:
        // console.log("something went wrong");
      }
      const option = options;
      setChosenOption(option);
    } else {
      const option = event.currentTarget.value;
      setChosenOption(option);
    }
  }

  function getScore() {
    const userId = localStorage.getItem("currentUserId");

    // for updating the seen questions
    let userScore = 0;
    let expectedScore = 0;
    // console.log("chosen option", chosenOption);

    if (chosenOption.length > 1) {
      const correctAns = currentQuizList[queIndex].answer.id
        .toUpperCase()
        .split("");
      // console.log(correctAns);

      // console.log(chosenOption);
      let questionScore = 0;
      for (let i = 0; i < correctAns.length; i++) {
        if (
          (chosenOption[i] === true && correctAns[i] === "T") ||
          (chosenOption[i] === false && correctAns[i] === "F") ||
          (chosenOption[i] === "F" && correctAns[i] === "F")
        ) {
          questionScore += Number((1 / correctAns.length).toFixed(2));
        } else {
          questionScore += 0;
        }
      }

      if (questionScore === 1) {
        let oldCorrectList = questionsCorrect;
        let oldCorrectCorresponding = quesCorrectCorrespondent;
        let oldQuestionsUnanswered = questionsUnanswered;
        oldCorrectList.push(queIndex + 1);
        quesCorrectCorrespondent.push(currentQuizList[queIndex].queNo);
        oldQuestionsUnanswered.splice(currentQuizList[queIndex + 1], 1);

        setQuestionsCorrect(oldCorrectList);
        setQuestionsCorrectCorrespondent(oldCorrectCorresponding);
        setQuestionsUnanswered(oldQuestionsUnanswered);
        sessionStorage.setItem(
          "questionsUnanswered",
          JSON.stringify(questionsUnanswered)
        );
      } else {
        let oldWrongList = questionsWrong;
        let oldWrongCorresponding = quesWrongCorrespondent;
        let oldQuestionsUnanswered = questionsUnanswered;
        oldWrongList.push(queIndex + 1);
        quesWrongCorrespondent.push(currentQuizList[queIndex].queNo);
        oldQuestionsUnanswered.splice(currentQuizList[queIndex + 1], 1);

        setQuestionsWrong(oldWrongList);
        setQuestionsUnanswered(oldQuestionsUnanswered);
        setQuestionsWrongCorrespondent(oldWrongCorresponding);
        sessionStorage.setItem(
          "questionsUnanswered",
          JSON.stringify(questionsUnanswered)
        );
      }

      userScore = questionScore;
      expectedScore = 1;

      setScore(score + questionScore);
      // console.log(questionScore);
      let maxScoreSoFar = maxScore + 1;
      sessionStorage.setItem("maxScore", maxScoreSoFar);
      setMaxScore(maxScoreSoFar);
    } else {
      if (chosenOption === currentQuizList[queIndex].answer.id.toLowerCase()) {
        setScore(score + 1);
        userScore = 1;
        expectedScore = 1;

        let oldCorrectList = questionsCorrect;
        let oldCorrectCorresponding = quesCorrectCorrespondent;
        let oldQuestionsUnanswered = questionsUnanswered;
        oldCorrectList.push(queIndex + 1);
        quesCorrectCorrespondent.push(currentQuizList[queIndex].queNo);
        oldQuestionsUnanswered.splice(currentQuizList[queIndex + 1], 1);

        setQuestionsCorrect(oldCorrectList);
        setQuestionsCorrectCorrespondent(oldCorrectCorresponding);
        setQuestionsUnanswered(oldQuestionsUnanswered);
        sessionStorage.setItem(
          "questionsUnanswered",
          JSON.stringify(questionsUnanswered)
        );

        let maxScoreSoFar = maxScore + 1;
        sessionStorage.setItem("maxScore", maxScoreSoFar);
        setMaxScore(maxScoreSoFar);
      } else {
        userScore = 0;
        expectedScore = 1;

        let oldWrongList = questionsWrong;
        let oldWrongCorresponding = quesWrongCorrespondent;
        let oldQuestionsUnanswered = questionsUnanswered;
        oldWrongList.push(queIndex + 1);
        quesWrongCorrespondent.push(currentQuizList[queIndex].queNo);
        oldQuestionsUnanswered.splice(currentQuizList[queIndex + 1], 1);

        setQuestionsWrong(oldWrongList);
        setQuestionsUnanswered(oldQuestionsUnanswered);
        setQuestionsWrongCorrespondent(oldWrongCorresponding);
        sessionStorage.setItem(
          "questionsUnanswered",
          JSON.stringify(questionsUnanswered)
        );

        let maxScoreSoFar = maxScore + 1;
        sessionStorage.setItem("maxScore", maxScoreSoFar);
        setMaxScore(maxScoreSoFar);
      }
    }

    // console.log(userScore, expectedScore);

    let seenQues = [];

    if (user) {
      if (user.seenQuestions) {
        seenQues = user.seenQuestions;
      } else {
        seenQues = [];
      }
      let seenQuesIds = [];
      seenQues.forEach((question) => {
        seenQuesIds.push(question.id);
      });

      if (!seenQuesIds.includes(currentQuizList[queIndex].id)) {
        // console.log(seenQues);
        seenQues.push({
          id: currentQuizList[queIndex].id,
          topic: currentQuizList[queIndex].topic,
          subject: currentQuizList[queIndex].subject,
          system: currentQuizList[queIndex].system,
          userScore: userScore,
          expectedScore: expectedScore,
        });
        firebase.firestore().collection("users").doc(userId).update({
          seenQuestions: seenQues,
        });
      }
    }
  }

  function handleNext(e) {
    // console.log("next clicked");

    // e.preventDefault();
    // const userId = localStorage.getItem("currentUserId");

    if (!user && queIndex > 3) {
      toast.error("Please Sign In to Continue using Quiz Mode");
      setForceSignInForQuiz(true);
    }

    if (
      user &&
      user.seenQuestions &&
      user.seenQuestions.length > 48 &&
      !isPremiumUser
    ) {
      toast.warning(
        "You have reached your free questions Limit. Get a PREMIUM account to Continue."
      );
      setMakeGoPremium(true);
      return;
    }

    getScore();
    setCheckedOptions(["F", "F", "F", "F", "F"]);
    setChosenOption("");
    setQueIndex(queIndex + 1);

    if (queIndex === currentQuizList.length - 2) {
      setDisableNext(true);
    }
  }

  function handlePracticeNext(e) {
    e.preventDefault();
    const userId = localStorage.getItem("currentUserId");

    if (quizPack.questions.length === 1) {
      setPracticeDisableNext(true);
      return;
    }

    if (
      user &&
      user.seenQuestions &&
      user.seenQuestions.length > 48 &&
      !isPremiumUser
    ) {
      toast.warning(
        "You have reached your free questions Limit. Get a PREMIUM account to Continue."
      );
      setMakeGoPremium(true);
      return;
    }

    // for updating the seen questions
    let userScore = Number(practiceQueScore.toFixed(2));
    let expectedScore = 1;
    // console.log(userScore, expectedScore);

    let seenQues = [];

    if (user) {
      if (user.seenQuestions) {
        seenQues = user.seenQuestions;
      } else {
        seenQues = [];
      }
      let seenQuesIds = [];
      seenQues.forEach((question) => {
        seenQuesIds.push(question.id);
      });

      if (!seenQuesIds.includes(quizPack.questions[practiceQueIndex].id)) {
        // console.log(seenQues);
        seenQues.push({
          id: quizPack.questions[practiceQueIndex].id,
          topic: quizPack.questions[practiceQueIndex].topic,
          subject: quizPack.questions[practiceQueIndex].subject,
          system: quizPack.questions[practiceQueIndex].system,
          userScore: userScore,
          expectedScore: expectedScore,
        });
        firebase.firestore().collection("users").doc(userId).update({
          seenQuestions: seenQues,
        });
      }
    }

    if (!user && practiceQueIndex > 3) {
      toast.error("Please Sign In to continue");
      setForceSignIn(true);
      return;
    }

    setPracticeQueIndex(practiceQueIndex + 1);
    setPracticeChosenOption("");
    setPracticeShowAnsExp(false);

    if (practiceQueIndex === quizPack.questions.length - 2) {
      setPracticeDisableNext(true);
    }

    if (practiceQueIndex >= 0) {
      setPracticeEnablePrevious(true);
    }

    setPracticeCheckedOptions(["F", "F", "F", "F", "F"]);
  }

  function handlePracticePrevious(e) {
    e.preventDefault();
    setPracticeChosenOption("");
    setPracticeShowAnsExp(false);

    if (practiceQueIndex < 2) {
      setPracticeEnablePrevious(false);
    }

    setPracticeQueIndex(practiceQueIndex - 1);
    setPracticeDisableNext(false);
    setPracticeCheckedOptions(["F", "F", "F", "F", "F"]);
  }

  function handlePracticeOptionChange(event) {
    // console.dir(event.currentTarget.parentNode)
    const question = quizPack.questions[practiceQueIndex];
    // console.log("answer id", question.answer.id.trim())
    const options = document.getElementsByClassName("practice-options");
    let checkedOption = practiceCheckedOptions;
    // console.log(PracticeChosenOption)

    if (event.currentTarget.name === "t/f") {
      switch (event.currentTarget.value) {
        case "a":
          checkedOption[0] = event.currentTarget.checked;
          break;
        case "b":
          checkedOption[1] = event.currentTarget.checked;
          break;
        case "c":
          checkedOption[2] = event.currentTarget.checked;
          break;
        case "d":
          checkedOption[3] = event.currentTarget.checked;
          break;
        case "e":
          checkedOption[4] = event.currentTarget.checked;
          break;
        default:
        // console.log("something went wrong");
      }
      const option = checkedOption;
      setPracticeChosenOption(option);
    } else {
      setPracticeShowAnsExp(true);
      for (let i = 0; i < options.length; i++) {
        const option = options[i].firstChild;
        // console.log("option value", option.value)
        option.disabled = true;
        if (option.checked) {
          if (option.value === question.answer.id.toLowerCase().trim()) {
            // console.log("You got it right");
            option.parentElement.style.cssText =
              "color: white; background-color: green; padding: 2px 5px; margin: 5px 0; border-radius: 5px";
            setPracticeQueScore(1);
          } else {
            // console.log("You got it wrong")
            option.parentElement.style.cssText =
              "color: white; background-color: red; padding: 2px 5px; margin: 5px 0; border-radius: 5px";
            setPracticeQueScore(0);
          }
        } else if (
          !option.checked &&
          option.value === question.answer.id.toLowerCase().trim()
        ) {
          option.parentElement.style.cssText =
            "color: white; background-color: green; padding: 2px 5px; margin: 5px 0; border-radius: 5px";
          setPracticeQueScore(0);
        }
      }

      setPracticeChosenOption(true);
    }
  }

  function handlePracticeCheck() {
    // console.log(practiceCheckedOptions);
    const question = quizPack.questions[practiceQueIndex];
    // console.log("answer id", question.answer.id.trim())
    const options = document.getElementsByClassName("practice-options");
    // console.log(options);
    setPracticeShowAnsExp(true);

    let score = 0;

    const answerList = question.answer.id.toUpperCase().trim().split("");
    // console.log("answer list", answerList)
    let answerValueList = [];
    for (let i = 0; i < answerList.length; i++) {
      if (answerList[i] === "T") {
        answerValueList[i] = true;
      } else if (answerList[i] === "F") {
        answerValueList[i] = false;
      }
    }

    // console.log("answer value", answerValueList);

    for (let i = 0; i < options.length; i++) {
      const option = options[i].firstChild;
      // console.log("option value", option.value)
      option.disabled = true;

      if (option.checked) {
        if (answerValueList[i] === true) {
          option.parentElement.style.cssText =
            "color: white; background-color: green; padding: 2px 5px; margin: 5px 0; border-radius: 5px";
          option.nextSibling.innerHTML = `${question.options[i].content} <strong style="background-color: #3f51b5; color: white; padding: 5px; margin-left: 5px; border-radius: 3px">True<strong>`;
          score += Number((1 / options.length).toFixed(2));
        } else {
          option.parentElement.style.cssText =
            "color: white; background-color: red; padding: 2px 5px; margin: 5px 0; border-radius: 5px";
          option.nextSibling.innerHTML = `${question.options[i].content} <strong style="background-color: #3f51b5; color: white; padding: 5px; margin-left: 5px; border-radius: 3px">False<strong>`;
          score += 0;
        }
      } else {
        if (answerValueList[i] === false) {
          option.parentElement.style.cssText =
            "color: white; background-color: green; padding: 2px 5px; margin: 5px 0; border-radius: 5px";
          option.nextSibling.innerHTML = `${question.options[i].content} <strong style="background-color: #3f51b5; color: white; padding: 5px; margin-left: 5px; border-radius: 3px">False<strong>`;
          score += Number((1 / options.length).toFixed(2));
        } else if (answerValueList[i] === true) {
          option.parentElement.style.cssText =
            "color: white; background-color: red; padding: 2px 5px; margin: 5px 0; border-radius: 5px";
          option.nextSibling.innerHTML = `${question.options[i].content} <strong style="background-color: #3f51b5; color: white; padding: 5px; margin-left: 5px; border-radius: 3px">True<strong>`;
          score += 0;
        } else {
          toast.error("Something went wrong");
        }
      }
    }

    setPracticeQueScore(score);
  }

  function timeUp() {
    sessionStorage.removeItem("timeUp");
    const newUnanswered = JSON.parse(
      sessionStorage.getItem("questionsUnanswered")
    );
    let allQuestions = JSON.parse(sessionStorage.getItem("allQuestions"));
    setAllQuestions(allQuestions);

    // console.log("unanswered", questionsUnanswered);
    // console.log("newUnanswered", newUnanswered);
    // console.log("questions wrong", questionsWrong)

    let unansweredQuesList = [];
    for (let i = 0; i < newUnanswered.length; i++) {
      unansweredQuesList.push(newUnanswered[i].queNo);
    }
    // console.log("unasnwer list", unansweredQuesList);

    // const quesWrongPlusUnanswered = quesWrongCorrespondent.concat(unansweredQuesList);
    // console.log("wrong plus unans", quesWrongPlusUnanswered)

    let unansweredScore = 0;
    // console.log(allQuestions.questions)
    for (let i = 0; i < unansweredQuesList.length; i++) {
      let question = allQuestions.questions[unansweredQuesList[i] - 1];
      if (question.answer.id.trim().length > 1) {
        // console.log(question.answer);
        unansweredScore += 1;
      } else {
        unansweredScore += 1;
      }
    }

    let maxScore = Number(sessionStorage.getItem("maxScore"));
    let maxScoreSoFar = maxScore + unansweredScore;
    // console.log("max score", maxScore);
    // console.log("unanswered score", unansweredScore)
    // console.log("max score so far", maxScoreSoFar)
    setMaxScore(maxScoreSoFar);
    setUnansweredQuesList(unansweredQuesList);

    //   setQuestionsWrong(quesWrongPlusUnanswered);
    setFinished(true);
    // console.log("questions unanswered", questionsUnanswered);
    //   console.log("questions wrong", questionsWrong);
    //   console.log("questions wrong plus unanswered", quesWrongPlusUnanswered);
  }

  function getResults() {
    const userId = localStorage.getItem("currentUserId");
    let finalScore = score; // for spaced repetition
    let finalMaxScore = 0; // for spaced repetition

    // for updating the seen questions
    let userScore = 0;
    let expectedScore = 0;

    if (chosenOption.length > 1) {
      const correctAns = currentQuizList[queIndex].answer.id
        .toUpperCase()
        .split("");
      // console.log(correctAns);

      // console.log(chosenOption);
      let questionScore = 0;
      for (let i = 0; i < correctAns.length; i++) {
        if (
          (chosenOption[i] === true && correctAns[i] === "T") ||
          (chosenOption[i] === false && correctAns[i] === "F") ||
          (chosenOption[i] === "F" && correctAns[i] === "F")
        ) {
          questionScore += Number((1 / correctAns.length).toFixed(2));
        } else {
          questionScore += 0;
        }
      }

      if (questionScore === 1) {
        let oldCorrectList = questionsCorrect;
        let oldCorrectCorresponding = quesCorrectCorrespondent;
        oldCorrectList.push(queIndex + 1);
        quesCorrectCorrespondent.push(currentQuizList[queIndex].queNo);

        setQuestionsCorrect(oldCorrectList);
        setQuestionsCorrectCorrespondent(oldCorrectCorresponding);
      } else {
        let oldWrongList = questionsWrong;
        let oldWrongCorresponding = quesWrongCorrespondent;
        oldWrongList.push(queIndex + 1);
        quesWrongCorrespondent.push(currentQuizList[queIndex].queNo);

        setQuestionsWrong(oldWrongList);
        setQuestionsWrongCorrespondent(oldWrongCorresponding);
      }

      userScore = questionScore;
      expectedScore = 1;

      setScore(score + questionScore);
      finalScore += questionScore;
      // console.log("question score", questionScore);

      let maxScoreSoFar = maxScore + 1;
      setMaxScore(maxScoreSoFar);
      finalMaxScore = maxScoreSoFar;
    } else {
      if (chosenOption === currentQuizList[queIndex].answer.id.toLowerCase()) {
        setScore(score + 1);
        userScore = 1;
        expectedScore = 1;

        finalScore += 1;
        let oldCorrectList = questionsCorrect;
        let oldCorrectCorresponding = quesCorrectCorrespondent;
        oldCorrectList.push(queIndex + 1);
        quesCorrectCorrespondent.push(currentQuizList[queIndex].queNo);

        setQuestionsCorrect(oldCorrectList);
        setQuestionsCorrectCorrespondent(oldCorrectCorresponding);

        let maxScoreSoFar = maxScore + 1;
        setMaxScore(maxScoreSoFar);
        finalMaxScore = maxScoreSoFar;
      } else {
        userScore = 0;
        expectedScore = 1;

        let oldWrongList = questionsWrong;
        let oldWrongCorresponding = quesWrongCorrespondent;
        oldWrongList.push(queIndex + 1);
        quesWrongCorrespondent.push(currentQuizList[queIndex].queNo);

        setQuestionsWrong(oldWrongList);
        setQuestionsWrongCorrespondent(oldWrongCorresponding);

        let maxScoreSoFar = maxScore + 1;
        setMaxScore(maxScoreSoFar);
        finalMaxScore = maxScoreSoFar;
      }
    }

    let seenQues = [];
    if (user) {
      if (user.seenQuestions) {
        seenQues = user.seenQuestions;
      } else {
        seenQues = [];
      }
      let seenQuesIds = [];
      seenQues.forEach((question) => {
        seenQuesIds.push(question.id);
      });

      if (!seenQuesIds.includes(currentQuizList[queIndex].id)) {
        // console.log(seenQues);
        seenQues.push({
          id: currentQuizList[queIndex].id,
          topic: currentQuizList[queIndex].topic,
          subject: currentQuizList[queIndex].subject,
          system: currentQuizList[queIndex].system,
          userScore: userScore,
          expectedScore: expectedScore,
        });
        firebase.firestore().collection("users").doc(userId).update({
          seenQuestions: seenQues,
        });
      }
    }

    if (spacedRepetition) {
      let repetitionTopics = user.repetitionTopics;
      // console.log(user, repetitionTopics);
      let currentTopic = quizPack.currentTopic;

      //Remove the current topic before adding the updated one
      const newRepetitionTopics = repetitionTopics.filter((topic) => {
        return topic.id !== currentTopic.id;
      });
      // console.log("repetition topics", repetitionTopics);
      // console.log("new repetition topics", newRepetitionTopics);

      // if(currentTopic.dueDate < Date.now() || currentTopic.progress > 0) {
      //   return;
      // }

      // console.log(currentTopic);
      let progress = 0;

      if (currentTopic.progress === 0) {
        progress = 1;
      } else if (currentTopic.progress === 1) {
        progress = 2;
      } else {
        progress = 3;
      }

      if (currentTopic.progress < 3) {
        let dateObj = new Date();
        dateObj.setUTCHours(0, 0, 0, 0);
        const midNight = +dateObj;

        let interval = 0;
        // console.log("final score", finalScore);
        // console.log("final max score", finalMaxScore);
        const scorePercentage = Math.round((finalScore / finalMaxScore) * 100);
        // console.log(scorePercentage);
        if (scorePercentage > 80) {
          interval += 4;
        } else if (scorePercentage > 60) {
          interval += 3;
        } else if (scorePercentage > 40) {
          interval += 2;
        } else {
          interval += 1;
        }
        const dueDate = midNight + interval * 86400000;
        currentTopic.dueDate = dueDate;
        currentTopic.progress = progress;
        currentTopic.dueDateInWords = new Date(dueDate).toString();
        currentTopic.lastScore = scorePercentage;
      }

      newRepetitionTopics.push(currentTopic);
      // console.log(currentTopic, repetitionTopics);
      firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .update({ repetitionTopics: newRepetitionTopics })
        .then(() => {
          localStorage.setItem(
            "currentSpacedRepetition",
            JSON.stringify({
              title: "Test is over",
              questions: [],
            })
          );
          toast.success(
            "This topic has been rescheduled for revision and quizing based on your score."
          );
        })
        .catch((err) => {
          console.log(err.message);
          return;
        });
    }

    clearInterval(watchTimer);
    setFinished(true);
  }

  function handleDisplayWrongQue(e, questionNo) {
    // console.log("wrong clicked");
    e.preventDefault();
    // window.scrollTo(0, 500);
    setWrongToDisplayIndex(questionNo - 1);
  }

  function handleDisplayCorrectQue(questionNo) {
    setCorrectToDisplayIndex(questionNo - 1);
  }

  function handleDisplayUnansweredQue(questionNo) {
    setUnansweredToDisplayIndex(questionNo - 1);
  }

  function renderQuizLength() {
    const timeForQuiz = timePerQue * quizNoQues;
    // console.log("time for quiz", timeForQuiz);

    return timeForQuiz;
  }

  function renderQuiz() {
    return (
      <TabContainer dir={theme.direction}>
        <div>
          {makeGoPremium && (
            <div style={{ textAlign: "center" }}>
              {" "}
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to={`/pricing`}
              >
                Go Premium
              </Button>
              <br />
              <Typography variant="caption">
                Get a premium account to see the rest of the questions.
              </Typography>
              <br />
              <br />
            </div>
          )}
          {!quizStart && !forceSignInForQuiz && (
            <div>
              <form
                style={{ margin: "0px 0px 8px 40px" }}
                onSubmit={handleQuizStart}
              >
                {!simulatedExamMode && (
                  <TextField
                    disabled={Boolean(quizStart)}
                    style={{ width: "100px", margin: "0 30px 0 0" }}
                    id="quiz-no-questions"
                    label="How many Questions?"
                    value={quizNoQues}
                    onChange={handleQuizQuesNo}
                    type="number"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}

                {!simulatedExamMode && (
                  <Button
                    disabled={Boolean(
                      quizStart ||
                        (user &&
                          user.seenQuestions &&
                          user.seenQuestions.length > 49 &&
                          !isPremiumUser)
                    )}
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ margin: "10px 10px", width: "0px" }}
                  >
                    Start
                  </Button>
                )}
                {simulatedExamMode && (
                  <Button
                    disabled={Boolean(
                      quizStart ||
                        (user &&
                          user.seenQuestions &&
                          user.seenQuestions.length > 49 &&
                          !isPremiumUser)
                    )}
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ margin: "10px 10px", width: "0px" }}
                  >
                    Start
                  </Button>
                )}
              </form>
            </div>
          )}
          {!makeGoPremium && (
            <Grid
              item
              container
              style={{
                display: "flex",
                justifyContent: "space-around",
                margin: "0 0 10px 0",
              }}
            >
              {quizStart && !finished && timed && (
                <Timer timeLength={renderQuizLength()} />
              )}
            </Grid>
          )}
        </div>

        {quizStart && quizNoQues && !forceSignInForQuiz && (
          <TransitionGroup>
            <CSSTransition key={queIndex} classNames="slide" timeout={300}>
              <div>
                {!finished && !makeGoPremium && (
                  <div>
                    <div>
                      <Button
                        variant="outlined"
                        color="secondary"
                        style={{
                          fontSize: "10px",
                          padding: "2px 5px",
                          margin: "0px 5px 10px 5px",
                        }}
                        onClick={() => handleClickOpen("issue-report")}
                      >
                        Report an Issue
                      </Button>
                    </div>

                    <div style={{ display: "flex" }}>
                      {queIndex + 1}.{" "}
                      {ReactHtmlParser(
                        removeEmptySpaces(currentQuizList[queIndex].question)
                      )}
                    </div>
                    {currentQuizList[queIndex].answer.id.length > 1 && (
                      <div
                        style={{
                          display: "flex",
                          marginTop: "0",
                          marginBottom: "10px",
                          marginLeft: "15px",
                          color: "#756c6c",
                        }}
                      >
                        (<CheckBox style={{ color: "#3f51b58c" }} />
                        Check all "True" options)
                      </div>
                    )}
                    <form
                      style={{ margin: "0px 0px 8px 40px" }}
                      onSubmit={handleQuizStart}
                    >
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        {currentQuizList[queIndex].answer.id.length === 1 && (
                          <RadioGroup
                            aria-label="options"
                            name="options"
                            className={classes.group}
                            value={chosenOption}
                            onChange={handleChosenOption}
                          >
                            {currentQuizList[queIndex].options.map(
                              (option) =>
                                option.content && (
                                  <FormControlLabel
                                    key={option.id}
                                    value={option.id}
                                    control={<Radio />}
                                    label={
                                      <div
                                        className="quiz-options"
                                        style={{ display: "inline-flex" }}
                                      >
                                        <strong>
                                          {option.id.toUpperCase()}.
                                        </strong>{" "}
                                        &nbsp;
                                        {ReactHtmlParser(
                                          removeEmptySpaces(option.content)
                                        )}
                                      </div>
                                    }
                                  />
                                )
                            )}
                          </RadioGroup>
                        )}

                        {currentQuizList[queIndex].answer.id.length > 1 && (
                          <FormGroup>
                            {currentQuizList[queIndex].options.map(
                              (option) =>
                                option.content && (
                                  <FormControlLabel
                                    key={option.id}
                                    control={
                                      <Checkbox
                                        // checked={jason}
                                        onChange={handleChosenOption}
                                        name="t/f"
                                        value={option.id}
                                      />
                                    }
                                    label={
                                      <div
                                        className="quiz-options"
                                        style={{ display: "inline-flex" }}
                                      >
                                        <strong>
                                          {option.id.toUpperCase()}.
                                        </strong>{" "}
                                        &nbsp;
                                        {ReactHtmlParser(
                                          removeEmptySpaces(option.content)
                                        )}
                                      </div>
                                    }
                                  />
                                )
                            )}
                          </FormGroup>
                        )}
                      </FormControl>

                      <Grid item sm={8} xs={9} style={{ margin: "auto" }}>
                        {!disableNext && (
                          <Button
                            disabled={!Boolean(chosenOption)}
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleNext}
                            className={classes.button}
                          >
                            Next
                          </Button>
                        )}
                        {disableNext && (
                          <Button
                            disabled={!Boolean(chosenOption)}
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={getResults}
                            className={classes.button}
                          >
                            Finish
                          </Button>
                        )}
                      </Grid>
                    </form>
                  </div>
                )}

                {finished && (
                  <div>
                    {!spacedRepetition && (
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={reset}
                          style={{ margin: "10px" }}
                        >
                          Reset
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          component={Link}
                          to="/questionbank"
                          style={{ margin: "10px" }}
                        >
                          Solve more questions
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          component={Link}
                          to="/dashboard"
                          style={{ margin: "10px" }}
                        >
                          Go to dashboard
                        </Button>
                      </div>
                    )}
                    {spacedRepetition && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => window.location.assign("/dashboard")}
                      >
                        Go To Dashboard
                      </Button>
                    )}
                    <Typography variant="h4">
                      {Number(score.toFixed(2))} out of {maxScore} Correct
                    </Typography>
                    <Typography variant="h4" style={{ marginBottom: "10px" }}>
                      Score{": "}
                      {Math.round((score / maxScore) * 100)}%
                    </Typography>
                    <Divider />
                    <Typography variant="h4">Details</Typography>
                    <em>
                      <strong>Note:</strong> Click on the question number to
                      view the Question and correct Answer
                    </em>
                    <br />
                    <br />
                    <div>
                      {questionsWrong && (
                        <Typography
                          variant="subtitle1"
                          style={{ fontSize: "24px" }}
                        >
                          You got the following questions wrong (or partly
                          wrong):
                          <br />
                        </Typography>
                      )}
                      {questionsWrong.map((question) => (
                        <Fab
                          key={question}
                          onClick={(e) => handleDisplayWrongQue(e, question)}
                          color="secondary"
                          style={{ fontWeight: "bold" }}
                        >
                          {question}
                        </Fab>
                      ))}

                      {wrongToDisplayIndex !== "" && (
                        <div style={{ marginTop: "15px" }}>
                          <div>
                            {ReactHtmlParser(
                              removeEmptySpaces(
                                currentQuizList[wrongToDisplayIndex].question
                              )
                            )}
                          </div>
                          <div>
                            {currentQuizList[wrongToDisplayIndex].options.map(
                              (option) =>
                                option.content && (
                                  <div
                                    key={option.id}
                                    style={{ display: "flex" }}
                                  >
                                    <strong>{option.id.toUpperCase()}.</strong>{" "}
                                    &nbsp;
                                    {ReactHtmlParser(
                                      removeEmptySpaces(option.content)
                                    )}
                                  </div>
                                )
                            )}
                          </div>
                          <br />
                          <Typography variant="h5">Answer</Typography>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                          >
                            {currentQuizList[
                              wrongToDisplayIndex
                            ].answer.id.toUpperCase()}
                            {"."}
                          </Typography>
                          {ReactHtmlParser(
                            removeEmptySpaces(
                              currentQuizList[wrongToDisplayIndex].answer
                                .explanation
                            )
                          )}
                        </div>
                      )}
                    </div>
                    <Divider />
                    <div>
                      {questionsCorrect && (
                        <Typography
                          variant="subtitle1"
                          style={{ fontSize: "24px" }}
                        >
                          You got the following questions Right:
                          <br />
                        </Typography>
                      )}
                      {questionsCorrect.map((question) => (
                        <Fab
                          key={question}
                          onClick={() => handleDisplayCorrectQue(question)}
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "green",
                            color: "#ffffff",
                          }}
                        >
                          {question}
                        </Fab>
                      ))}

                      {correctToDisplayIndex !== "" && (
                        <div style={{ marginTop: "15px" }}>
                          <div>
                            {ReactHtmlParser(
                              removeEmptySpaces(
                                currentQuizList[correctToDisplayIndex].question
                              )
                            )}
                          </div>
                          <div>
                            {currentQuizList[correctToDisplayIndex].options.map(
                              (option) =>
                                option.content && (
                                  <div
                                    key={option.id}
                                    style={{ display: "flex" }}
                                  >
                                    <strong>{option.id.toUpperCase()}.</strong>{" "}
                                    &nbsp;
                                    {ReactHtmlParser(
                                      removeEmptySpaces(option.content)
                                    )}
                                  </div>
                                )
                            )}
                          </div>
                          <br />
                          <Typography variant="h5">Answer</Typography>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                          >
                            {currentQuizList[
                              correctToDisplayIndex
                            ].answer.id.toUpperCase()}
                            {"."}
                          </Typography>
                          {ReactHtmlParser(
                            removeEmptySpaces(
                              currentQuizList[correctToDisplayIndex].answer
                                .explanation
                            )
                          )}
                        </div>
                      )}
                    </div>

                    <Divider />
                    {questionsUnanswered.length > 1 && (
                      <div>
                        <Typography
                          variant="subtitle1"
                          style={{ fontSize: "24px" }}
                        >
                          You were unable to reach these questions due to time:
                          <br />
                        </Typography>

                        {unansweredQuesList.map((question) => (
                          <Fab
                            key={question}
                            onClick={() => handleDisplayUnansweredQue(question)}
                            style={{
                              fontWeight: "bold",
                              backgroundColor: "blue",
                              color: "#ffffff",
                            }}
                          >
                            Q
                          </Fab>
                        ))}

                        {unansweredToDisplayIndex !== "" && (
                          <div style={{ marginTop: "15px" }}>
                            <div>
                              {ReactHtmlParser(
                                removeEmptySpaces(
                                  allQuestions.questions[
                                    unansweredToDisplayIndex
                                  ].question
                                )
                              )}
                            </div>
                            <ul>
                              {allQuestions.questions[
                                unansweredToDisplayIndex
                              ].options.map(
                                (option) =>
                                  option.content && (
                                    <p key={option.id}>
                                      <strong>
                                        {option.id.toUpperCase()}.
                                      </strong>{" "}
                                      &nbsp;
                                      {ReactHtmlParser(
                                        removeEmptySpaces(option.content)
                                      )}
                                    </p>
                                  )
                              )}
                            </ul>
                            <br />
                            <Typography variant="h5">Answer</Typography>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: "bold" }}
                            >
                              {allQuestions.questions[
                                unansweredToDisplayIndex
                              ].answer.id.toUpperCase()}
                              {"."}
                            </Typography>
                            {ReactHtmlParser(
                              removeEmptySpaces(
                                allQuestions.questions[unansweredToDisplayIndex]
                                  .answer.explanation
                              )
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                <br />
              </div>
            </CSSTransition>
          </TransitionGroup>
        )}
        {forceSignInForQuiz && (
          <div>
            <Typography>Please Sign In to continue using Quiz Mode</Typography>
            <Link
              to={`/login/quiz-preview/${quizPack.subject}-${quizPack.system}-${quizPack.school}`}
            >
              <Button variant="contained" color="secondary">
                Sign In
              </Button>
            </Link>
            <br />
            <br />
            <Typography>
              If you don't have an account, Click here to Register
            </Typography>
            <Link
              to={`/student-register/quiz-preview/${quizPack.subject}-${quizPack.system}-${quizPack.school}`}
            >
              <Button variant="contained" color="secondary">
                Register
              </Button>
            </Link>
          </div>
        )}
      </TabContainer>
    );
  }

  const handleClickOpen = (type) => {
    if (type === "issue-report") {
      setOpen(true);
    } else if (type === "create-flashcard") {
      setOpenCreateFlashCard(true);
    }
    // else if (type === "instructions") {
    //   setOpenInstructions(true);
    // }
    // else if (type === "view-3d-model") {
    //   get3DModel();
    //   setOpen3d(true);
    // }
    else if (type === "edit-question") {
      const question = quizPack.questions[practiceQueIndex];
      // console.log(question);
      seteditedQueData({
        question: question.question,
        optionA: question.options[0].content,
        optionB: question.options[1].content,
        optionC: question.options[2].content,
        optionD: question.options[3].content,
        optionE: question.options[4].content,
        answer: question.answer.id.toUpperCase(),
        answerExplanation: question.answer.explanation,
        subject: question.subject,
        subdivision: question.system,
        topic: question.topic,
        queId: question.id,
      });
      setOpenEditQuestion(true);
    } else if (type === "options-after-session") {
      setOpenOptionsAfterSession(true);
    } else if (type === "sure-to-delete") {
      setOpenSureToDelete(true);
    }
  };

  const handleClose = (type) => {
    if (type === "issue-report") {
      setOpen(false);
    } else if (type === "create-flashcard") {
      setOpenCreateFlashCard(false);
    }
    // else if (type === "instructions") {
    //   setOpenInstructions(false);
    // }
    // else if (type === "view-3d-model") {
    //   setOpen3d(false);
    // }
    else if (type === "edit-question") {
      setOpenEditQuestion(false);
    } else if (type === "options-after-session") {
      setOpenOptionsAfterSession(false);
    } else if (type === "sure-to-delete") {
      setOpenSureToDelete(false);
    }
  };

  function handleEditQueSubmit() {
    console.log("submitting the edited que");
    // console.log(editedQueData);
    let currentCustom = JSON.parse(localStorage.getItem("currentCustom"));
    const lastModified = Date.now();

    const {
      queId,
      question,
      optionA,
      optionB,
      optionC,
      optionD,
      optionE,
      answer,
      answerExplanation,
    } = editedQueData;

    if (
      !answer ||
      question.length < 8 ||
      optionA.length < 8 ||
      optionB.length < 8 ||
      answerExplanation.length < 8
    ) {
      toast.error(
        "Question, Correct Answer, Option A, Option B and Answer Explanation must not be empty"
      );
      return;
    }

    //Launch the preloader while submitting
    setSubmitting(true);

    let newQue = {
      question: question,
      lastModified: lastModified,
      options: [
        { content: optionA, id: "a" },
        { content: optionB, id: "b" },
        { content: optionC, id: "c" },
        { content: optionD, id: "d" },
        { content: optionE, id: "e" },
      ],
      answer: {
        explanation: answerExplanation,
        id: answer,
      },
      id: queId,
    };

    currentCustom.questions[practiceQueIndex] = newQue;
    localStorage.setItem("currentCustom", JSON.stringify(currentCustom));

    // console.log(newQue);
    // return;
    firebase
      .firestore()
      .collection("questions")
      .doc(queId)
      .update(newQue)
      .then(() => {
        setSubmitting(false);
        toast.success(
          "Successfully Updated Question. Reload page to see changes!"
        );
        setOpenEditQuestion(false);
      })
      .catch((error) => {
        console.log(error.message);
        toast.error("Something went wrong");
        setOpenEditQuestion(false);
      });
  }

  // function handleReportIssue(event) {
  //   // console.log("handling the report issue")
  //   setIssueToReport(event.currentTarget.value);
  // }

  function reportIssue() {
    if (issueToReport.length < 8) {
      toast.error("Please input your message in the space provided");
      return;
    }

    let question = {};
    if (quizStart) {
      question = currentQuizList[queIndex];
    } else {
      question = quizPack.questions[practiceQueIndex];
    }
    const { subject, system, topic, id } = question;
    const dateUploaded = Date.now().toString();
    const reportedBy = { email: user.email, firstName: user.firstName };
    // console.log("issue to report", issueToReport);
    // console.log("question no", practiceQueIndex + 1);
    // console.log("question Id", id)
    // console.log("subject, subdivision, topic", subject, system, topic)

    //Launch the preloader while submitting
    setSubmitting(true);

    firebase
      .firestore()
      .collection("issues")
      .doc(dateUploaded)
      .set({
        content: issueToReport,
        from: "questionbank",
        subject: subject,
        system: system,
        topic: topic,
        itemId: id,
        issueId: dateUploaded,
        reportedBy: reportedBy,
      })
      .then(() => {
        toast.success(
          "The issue has been reported. Thank You for helping to make Acabest better."
        );
        handleClose("issue-report");
        setSubmitting(false);
      })
      .catch((error) => {
        console.log(error.message);
        setSubmitting(false);
      });
  }

  function createFlashcard() {
    // console.log("creating your card");
    // console.log(flashcardData);
    const {
      cardFrontText,
      cardBackText,
      subject,
      subdivision,
      topic,
      allowSharing,
    } = flashcardData;
    const creator = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    };
    const cardId = Date.now().toString();
    const userId = localStorage.getItem("currentUserId");

    if (!cardFrontText || !cardBackText || !subject || !subdivision) {
      toast.error(
        "Card front text, Card back text, Subject and Subdivision are required!"
      );
      return;
    }
    // console.log(cardId);

    //Launch the preloader while submitting
    setSubmitting(true);

    let userCards = [];
    let dateObj = new Date();
    dateObj.setUTCHours(0, 0, 0, 0);
    const midNight = +dateObj;

    let updateUserData = {};
    if (user) {
      if (user.flashcards) {
        userCards = user.flashcards;
      } else {
        userCards = [];
      }
      let userCardsIds = [];
      userCards.forEach((card) => {
        userCardsIds.push(card.id);
      });

      if (!userCardsIds.includes(cardId)) {
        // console.log(userCards);
        userCards.push({
          frontText: cardFrontText,
          backText: cardBackText,
          subject: subject,
          system: subdivision,
          topic: topic,
          creator: creator,
          id: cardId,
          upvotes: 0,
          reports: [],
          allowSharing: allowSharing,
          phase: "new",
          interval: 86400000,
          ease: 2.5,
          dueDate: midNight + 86400000,
          dueDateInWords: new Date(midNight + 86400000).toString(),
          lapses: 0,
        });
        updateUserData = firebase
          .firestore()
          .collection("users")
          .doc(userId)
          .update({
            flashcards: userCards,
          });
      }
    }

    const sendCardToCommunity = firebase
      .firestore()
      .collection("flashcards")
      .doc(cardId)
      .set({
        frontText: cardFrontText,
        backText: cardBackText,
        subject: subject,
        system: subdivision,
        topic: topic,
        creator: creator,
        id: cardId,
        upvotes: 0,
        reports: [],
        allowSharing: allowSharing,
      });

    Promise.all([updateUserData, sendCardToCommunity])
      .then(() => {
        // console.log("everything went smoothly");
        setOpenCreateFlashCard(false);
        setFlashcardData({
          cardFrontText: "",
          cardBackText: "",
          subject: "",
          subdivision: "",
          topic: "",
          allowSharing: true,
        });
        setSubmitting(false);
        toast.success("Flashcard successfully created");
      })
      .catch((error) => {
        console.log(error.message);
        setSubmitting(false);
      });
  }

  // function get3DModel() {
  //   // console.log("obtaining 3d Model url");
  //   const { subject, system, topic } = quizPack.questions[practiceQueIndex];
  //   console.log(subject, system, topic);
  //   let url = "";

  //   firebase
  //     .firestore()
  //     .collection("threeD-models")
  //     .where("subject", "==", subject)
  //     .where("subdivision", "==", system)
  //     .where("topic", "==", topic)
  //     .where("type", "==", "regular")
  //     .get()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach((doc) => {
  //         // console.log(doc.data().url)
  //         url = doc.data().url;
  //       });
  //       // console.log(url);
  //       setThreeDModelUrl(url);
  //     });
  // }

  function getlastModifiedFormatedDate() {
    const fullDate = new Date(
      quizPack.questions[practiceQueIndex].lastModified
    ).toString();

    const formatedDate = fullDate.split("GMT")[0];
    return formatedDate;
  }

  function renderPractice() {
    return (
      <TabContainer dir={theme.direction}>
        {
          <TransitionGroup>
            <CSSTransition
              key={practiceQueIndex}
              classNames="slide"
              timeout={300}
            >
              <div>
                {makeGoPremium && (
                  <div style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      component={Link}
                      to={`/pricing`}
                    >
                      Go Premium
                    </Button>
                    <br />
                    <Typography variant="caption">
                      Get a premium account to see the rest of this OSCE.
                    </Typography>
                    <br />
                    <br />
                  </div>
                )}
                {quizStart && !simulatedExamMode && (
                  <div>
                    <p>
                      You have an ongoing quiz. Reset to continue with the
                      Practice mode.
                    </p>
                    <Button variant="contained" color="primary" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                )}

                <div>
                  {simulatedExamMode && (
                    <div>
                      <p>
                        Practice Mode is not available for Simulated Exams.
                        Swipe to the Exam Mode.
                      </p>
                    </div>
                  )}
                </div>
                {!quizStart &&
                  !simulatedExamMode &&
                  !forceSignIn &&
                  !makeGoPremium && (
                    <div>
                      <div>
                        <Button
                          variant="outlined"
                          color="secondary"
                          style={{
                            fontSize: "10px",
                            padding: "2px 5px",
                            margin: "0px 5px 10px 5px",
                          }}
                          onClick={() => handleClickOpen("issue-report")}
                        >
                          Report an Issue
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            fontSize: "10px",
                            padding: "2px 5px",
                            margin: "0px 5px 10px 5px",
                          }}
                          onClick={() => handleClickOpen("create-flashcard")}
                        >
                          Create Flashcard
                        </Button>
                        {/* <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          fontSize: "10px",
                          padding: "2px 5px",
                          margin: "0px 5px 10px 5px",
                        }}
                        onClick={() => handleClickOpen("instructions")}
                      >
                        instructions
                      </Button> */}
                        {/* <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          fontSize: "10px",
                          padding: "2px 0px",
                          margin: "0px 0px 10px 5px",
                        }}
                        onClick={() => handleClickOpen("view-3d-model")}
                      >
                        view 3D
                      </Button> */}
                        {isAdmin && (
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            style={{
                              fontSize: "11px",
                              padding: "2px 5px",
                              margin: "0px 0px 10px 5px",
                            }}
                            onClick={() => setOpenSureToDelete(true)}
                          >
                            Delete this question
                          </Button>
                        )}
                        {permitQuestionEdit && (
                          <div>
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{
                                fontSize: "14px",
                                padding: "2px 5px",
                                margin: "0px 0px 10px 5px",
                              }}
                              onClick={() => handleClickOpen("edit-question")}
                            >
                              Edit this question
                            </Button>
                            <br />
                            {quizPack.questions[practiceQueIndex]
                              .lastModified && (
                              <Typography variant="body2">
                                last Modified: {getlastModifiedFormatedDate()}
                              </Typography>
                            )}
                            <br />
                          </div>
                        )}
                      </div>

                      <div>
                        <Dialog
                          open={openCreateFlashCard}
                          onClose={() => handleClose("create-flashcard")}
                          aria-labelledby="create-flashcard-dialog"
                        >
                          <DialogTitle id="create-flashcard-dialog">
                            Create A Flashcard
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Enter details for your card below. Unless you "Do
                              not allow sharing", your card will be added to the
                              community cards so that other users may benefit
                              from it.
                            </DialogContentText>
                            <div
                              style={{
                                border: "1px solid #969aa2",
                                marginBottom: "20px",
                                borderRadius: "5px 5px 0px 0px",
                              }}
                            >
                              <p style={{ padding: "0 10px", margin: "0" }}>
                                Front of Card
                              </p>
                              <Divider />
                              <CKEditor
                                editor={InlineEditor}
                                data={""}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  const cardData = { ...flashcardData };
                                  cardData.cardFrontText = data;
                                  setFlashcardData(cardData);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                border: "1px solid #969aa2",
                                marginBottom: "20px",
                                borderRadius: "5px 5px 0px 0px",
                              }}
                            >
                              <p style={{ padding: "0 10px", margin: "0" }}>
                                Back of Card
                              </p>
                              <Divider />
                              <CKEditor
                                editor={InlineEditor}
                                data={""}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  const cardData = { ...flashcardData };
                                  cardData.cardBackText = data;
                                  setFlashcardData(cardData);
                                }}
                              />
                            </div>
                            <div>
                              <FormControl
                                style={{ width: "100%", margin: "12px auto" }}
                                variant="outlined"
                                className={classes.formControl}
                              >
                                <InputLabel>Selct Subject</InputLabel>
                                <Select
                                  native
                                  value={flashcardData.subject}
                                  onChange={(event) => {
                                    const cardData = { ...flashcardData };
                                    cardData.subject =
                                      event.currentTarget.value;
                                    cardData.subdivision = ""; // to reset the subdivisions
                                    setFlashcardData(cardData);
                                  }}
                                  id="create-flashcard-subject"
                                >
                                  <option value="" />
                                  {subjects.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                              {flashcardData.subject && (
                                <FormControl
                                  style={{ width: "100%", margin: "12px auto" }}
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel>Selct Subdivision</InputLabel>
                                  <Select
                                    native
                                    value={flashcardData.subdivision}
                                    onChange={(event) => {
                                      const cardData = { ...flashcardData };
                                      cardData.subdivision =
                                        event.currentTarget.value;
                                      cardData.topic = ""; // to reset the topic
                                      setFlashcardData(cardData);
                                    }}
                                    id="create-flashcard-subdivision"
                                  >
                                    <option value="" />
                                    {systems().map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </Select>
                                </FormControl>
                              )}
                              {flashcardData.subject &&
                                flashcardData.subdivision && (
                                  <FormControl
                                    style={{
                                      width: "100%",
                                      margin: "12px auto",
                                    }}
                                    variant="outlined"
                                    className={classes.formControl}
                                  >
                                    <InputLabel>
                                      Select Topic(optional) - recommended
                                    </InputLabel>
                                    <Select
                                      native
                                      value={flashcardData.topic}
                                      onChange={(event) => {
                                        const cardData = { ...flashcardData };
                                        cardData.topic =
                                          event.currentTarget.value;
                                        setFlashcardData(cardData);
                                      }}
                                      id="create-flashcard-topic"
                                    >
                                      <option value="" />
                                      {topics().map((option) => (
                                        <option
                                          key={option.id}
                                          value={option.id}
                                        >
                                          {option.name}
                                        </option>
                                      ))}
                                    </Select>
                                  </FormControl>
                                )}

                              <div>
                                <input
                                  value="allow"
                                  type="checkbox"
                                  id="do-not-allow-card-sharing"
                                  onChange={(event) => {
                                    const cardData = { ...flashcardData };
                                    const allow = !event.currentTarget.checked;
                                    cardData.allowSharing = allow;
                                    setFlashcardData(cardData);
                                  }}
                                />
                                <label
                                  htmlFor="do-not-allow-card-sharing"
                                  style={{
                                    marginLeft: "10px",
                                    // display: "flex",
                                  }}
                                >
                                  Do not allow sharing!
                                </label>
                              </div>
                            </div>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={() => handleClose("create-flashcard")}
                              color="primary"
                            >
                              Cancel
                            </Button>
                            <Button onClick={createFlashcard} color="primary">
                              Submit
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>

                      <div>
                        <Dialog
                          open={openEditQuestion}
                          onClose={() => handleClose("edit-question")}
                          aria-labelledby="edit-question"
                        >
                          <DialogTitle id="edit-question-dialog">
                            Edit this question
                          </DialogTitle>
                          <DialogContent>
                            <form className={classes.form}>
                              <br />
                              <div
                                style={{
                                  border: "1px solid #969aa2",
                                  marginBottom: "20px",
                                  borderRadius: "5px 5px 0px 0px",
                                }}
                              >
                                <p style={{ padding: "0 10px", margin: "0" }}>
                                  Question
                                </p>
                                <Divider />
                                <CKEditor
                                  editor={InlineEditor}
                                  data={
                                    quizPack.questions[practiceQueIndex]
                                      .question
                                  }
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    const quesData = { ...editedQueData };
                                    quesData.question = data;
                                    seteditedQueData(quesData);
                                  }}
                                />
                              </div>

                              {quizPack.questions[practiceQueIndex].options.map(
                                (option) => (
                                  <div
                                    key={option.id}
                                    style={{
                                      border: "1px solid #969aa2",
                                      marginBottom: "20px",
                                      borderRadius: "5px 5px 0px 0px",
                                    }}
                                  >
                                    <p
                                      style={{ padding: "0 10px", margin: "0" }}
                                    >{`option ${option.id.toUpperCase()}`}</p>
                                    <Divider />
                                    <CKEditor
                                      editor={InlineEditor}
                                      data={option.content}
                                      onChange={(event, editor) => {
                                        const data = editor.getData();
                                        const quesData = { ...editedQueData };
                                        quesData[
                                          `option${option.id.toUpperCase()}`
                                        ] = data;
                                        seteditedQueData(quesData);
                                      }}
                                    />
                                  </div>
                                )
                              )}
                              <Autocomplete
                                id="answer-options"
                                options={answerOptions}
                                getOptionLabel={(option) => option.name}
                                style={{ width: 300 }}
                                onChange={(event, newValue) => {
                                  // console.log(newValue);
                                  const quesData = { ...editedQueData };
                                  quesData.answer = newValue.name.toLowerCase();
                                  seteditedQueData(quesData);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Correct Answer"
                                    variant="outlined"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                              <br />
                              <div
                                style={{
                                  border: "1px solid #969aa2",
                                  marginBottom: "20px",
                                  borderRadius: "5px 5px 0px 0px",
                                }}
                              >
                                <p style={{ padding: "0 10px", margin: "0" }}>
                                  Answer Explanation
                                </p>
                                <Divider />
                                <CKEditor
                                  editor={InlineEditor}
                                  data={
                                    quizPack.questions[practiceQueIndex].answer
                                      .explanation
                                  }
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    const quesData = { ...editedQueData };
                                    quesData.answerExplanation = data;
                                    seteditedQueData(quesData);
                                  }}
                                  onReady={() => {
                                    // console.log("editor is ready")
                                  }}
                                />
                              </div>
                            </form>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={() => handleClose("edit-question")}
                              color="primary"
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={handleEditQueSubmit}
                              color="primary"
                            >
                              Submit
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>

                      <div style={{ display: "flex" }}>
                        <span style={{ marginRight: "5px" }}>
                          {practiceQueIndex + 1}
                          {"."}
                        </span>
                        {ReactHtmlParser(
                          removeEmptySpaces(
                            quizPack.questions[practiceQueIndex].question
                          )
                        )}
                      </div>
                      {quizPack.questions[practiceQueIndex].answer.id.length >
                        1 && (
                        <div
                          style={{
                            display: "flex",
                            marginTop: "0",
                            marginBottom: "10px",
                            marginLeft: "15px",
                            color: "#756c6c",
                          }}
                        >
                          (<CheckBox style={{ color: "#3f51b58c" }} />
                          Check all "True" options)
                        </div>
                      )}
                      <form
                        style={{ margin: "0px 0px 8px 40px" }}
                        onSubmit={handlePracticeStart}
                      >
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          {quizPack.questions[practiceQueIndex].answer.id
                            .length === 1 && (
                            <div>
                              {quizPack.questions[practiceQueIndex].options.map(
                                (option) =>
                                  option.content && (
                                    <div
                                      key={option.id}
                                      className="practice-options"
                                    >
                                      <input
                                        value={option.id}
                                        type="radio"
                                        name="option"
                                        id={option.id}
                                        onChange={handlePracticeOptionChange}
                                      />
                                      <label
                                        htmlFor={option.id}
                                        style={{
                                          marginLeft: "20px",
                                          display: "flex",
                                        }}
                                      >
                                        {ReactHtmlParser(
                                          removeEmptySpaces(option.content)
                                        )}
                                      </label>
                                    </div>
                                  )
                              )}
                              <div>
                                {practiceShowAnsExp &&
                                  ReactHtmlParser(
                                    removeEmptySpaces(
                                      quizPack.questions[practiceQueIndex]
                                        .answer.explanation
                                    )
                                  )}
                              </div>
                            </div>
                          )}

                          {quizPack.questions[practiceQueIndex].answer.id
                            .length > 1 && (
                            <div>
                              {quizPack.questions[practiceQueIndex].options.map(
                                (option) =>
                                  option.content && (
                                    <div
                                      key={option.id}
                                      className="practice-options"
                                    >
                                      <input
                                        value={option.id}
                                        type="checkbox"
                                        name="t/f"
                                        id={option.id}
                                        onChange={handlePracticeOptionChange}
                                      />
                                      <label
                                        htmlFor={option.id}
                                        style={{
                                          marginLeft: "20px",
                                          display: "flex",
                                        }}
                                      >
                                        {ReactHtmlParser(
                                          removeEmptySpaces(option.content)
                                        )}
                                      </label>
                                    </div>
                                  )
                              )}

                              <div>
                                {practiceShowAnsExp &&
                                  ReactHtmlParser(
                                    removeEmptySpaces(
                                      quizPack.questions[practiceQueIndex]
                                        .answer.explanation
                                    )
                                  )}
                              </div>
                            </div>
                          )}
                        </FormControl>

                        <Grid item sm={8} xs={9} style={{ margin: "auto" }}>
                          {quizPack.questions[practiceQueIndex].answer.id
                            .length > 1 && (
                            <Button
                              // disabled={!Boolean(PracticeChosenOption)}
                              variant="contained"
                              color="primary"
                              fullWidth
                              onClick={(event) => handlePracticeCheck(event)}
                              className={classes.button}
                              style={{ marginBottom: "20px" }}
                            >
                              Check Answers
                            </Button>
                          )}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ paddingRight: "10px" }}>
                              {
                                <Button
                                  disabled={Boolean(!practiceEnablePrevious)}
                                  variant="contained"
                                  color="secondary"
                                  fullWidth
                                  onClick={handlePracticePrevious}
                                  className={classes.button}
                                >
                                  Previous
                                </Button>
                              }
                            </div>
                            <div>
                              {!practiceDisabledNext && (
                                <Button
                                  disabled={!practiceShowAnsExp}
                                  variant="contained"
                                  color="primary"
                                  onClick={handlePracticeNext}
                                  className={classes.button}
                                  style={{ padding: "6.5px 30px" }}
                                >
                                  Next
                                </Button>
                              )}
                              {practiceDisabledNext && (
                                <Button
                                  disabled={!Boolean(PracticeChosenOption)}
                                  variant="contained"
                                  color="default"
                                  // style={{ backgroundColor: "#28a745", color: "fff !important"}}
                                  fullWidth
                                  onClick={() =>
                                    setOpenOptionsAfterSession(true)
                                  }
                                  className={classes.button}
                                >
                                  End Session
                                </Button>
                              )}
                            </div>
                          </div>
                        </Grid>
                      </form>
                    </div>
                  )}
                {forceSignIn && (
                  <div>
                    <Typography>
                      Please Sign In to continue this session
                    </Typography>
                    <Link
                      to={`/login/quiz-preview/${quizPack.subject}-${quizPack.system}-${quizPack.school}`}
                    >
                      <Button variant="contained" color="secondary">
                        Sign In
                      </Button>
                    </Link>
                    <br />
                    <br />
                    <Typography>
                      If you don't have an account, Click here to Register
                    </Typography>
                    <Link
                      to={`/student-register/quiz-preview/${quizPack.subject}-${quizPack.system}-${quizPack.school}`}
                    >
                      <Button variant="contained" color="secondary">
                        Register
                      </Button>
                    </Link>
                  </div>
                )}

                <br />
              </div>
            </CSSTransition>
          </TransitionGroup>
        }
      </TabContainer>
    );
  }

  function handleQuizQuesNo(event) {
    const noQues = event.currentTarget.value;
    setQuizNoQues(noQues);
  }

  function reset() {
    window.location.reload();
  }

  function renderTitle() {
    const quizId = match.params.quizid;

    if (quizId === "scheduled") {
      return `Scheduled Session - ${quizPack.title}`;
    } else {
      return quizPack.title;
    }
  }

  function doDeleteQuestion(question) {
    // console.log(question);

    if (!isAdmin) {
      toast.error("You do not have the permission to delete a question");
      return;
    }

    setSubmitting(true);
    setPracticeQueIndex(practiceQueIndex + 1);

    // return;

    const deleteQuestion = firebase
      .firestore()
      .collection("questions")
      .doc(question.id)
      .delete();

    const decrementSubjectStats = firebase
      .firestore()
      .collection(`statistics/subjects/subjectData`)
      .doc(question.subject)
      .update({
        noOfQuestions: firebase.firestore.FieldValue.increment(-1),
      });
    const decrementSystemStats = firebase
      .firestore()
      .collection(`statistics/subjects/subjectData/${question.subject}/systems`)
      .doc(question.system)
      .update({
        noOfQuestions: firebase.firestore.FieldValue.increment(-1),
      });
    const decrementTopicStats = firebase
      .firestore()
      .collection(
        `statistics/subjects/subjectData/${question.subject}/systems/${question.system}/topics`
      )
      .doc(question.topic)
      .update({
        noOfQuestions: firebase.firestore.FieldValue.increment(-1),
      });
    const decrementGeneralStats = firebase
      .firestore()
      .collection(`statistics`)
      .doc("general")
      .update({
        questions: firebase.firestore.FieldValue.increment(-1),
      });

    Promise.all([
      deleteQuestion,
      decrementSubjectStats,
      decrementSystemStats,
      decrementTopicStats,
      decrementGeneralStats,
    ])
      .then(() => {
        setSubmitting(false);
        toast.success("Question Deleted Successfully");
      })
      .catch((error) => {
        toast.error("Something went wrong!");
        setSubmitting(false);
        console.log(error.message);
      });
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{quizPack.title}</title>
        {/* <meta name="description" content={quizPack.shortDescription} /> */}
      </Helmet>

      {!quizPack.questions || submitting ? (
        <PreLoader
        // timeout={3000} //3 secs
        />
      ) : (
        <div>
          <Grid>
            {!isPremiumUser && (
              <Paper
                style={{
                  textAlign: "center",
                  backgroundColor:
                    user && user.seenQuestions && user.seenQuestions.length < 38
                      ? "green"
                      : "#ae0f48",
                  color: "#fff",
                  borderRadius: "0px",
                  padding: "3px",
                }}
              >
                Free Questions Limit:{" "}
                <strong>
                  {user && user.seenQuestions && user.seenQuestions.length}
                </strong>{" "}
                out of <strong>50</strong>
              </Paper>
            )}
            <Paper style={{ backgroundColor: "#9ea8dd" }}>
              <Typography
                color="primary"
                style={{
                  textTransform: "capitalize",
                  textAlign: "center",
                }}
                variant="h6"
                component="h6"
              >
                {renderTitle()}
              </Typography>
            </Paper>
          </Grid>
          <Grid item lg={8} style={{ margin: "auto" }}>
            <div className={classes.root}>
              <AppBar position="sticky" color="default">
                {!simulatedExamMode && (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                  >
                    <Tab
                      label="PRACTICE"
                      style={{ fontWeight: "bold" }}
                      // onClick={window.scroll(0, 0)}
                    />
                    {/* <Tab label="ANSWERS" style={{ fontWeight: "bold" }} /> */}
                    <Tab label="QUIZ MODE" style={{ fontWeight: "bold" }} />
                  </Tabs>
                )}
                {simulatedExamMode && (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                  >
                    <Tab label="QUIZ MODE" style={{ fontWeight: "bold" }} />
                    {/* <Tab
                    label="PRACTICE"
                    style={{ fontWeight: "bold" }}
                    // onClick={window.scroll(0, 0)}
                  /> */}
                    {/* <Tab label="ANSWERS" style={{ fontWeight: "bold" }} /> */}
                  </Tabs>
                )}
              </AppBar>
              {!simulatedExamMode && (
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.06)" }}
                >
                  {/* {renderQuestions()} */}
                  {renderPractice()}
                  {/* {renderAnswers()} */}
                  {renderQuiz()}
                </SwipeableViews>
              )}

              {simulatedExamMode && (
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.06)" }}
                >
                  {renderQuiz()}
                  {/* {renderPractice()} */}
                </SwipeableViews>
              )}
            </div>
          </Grid>

          <div>
            <Dialog
              open={open}
              onClose={() => handleClose("issue-report")}
              aria-labelledby="issue-report-dialog"
            >
              <DialogTitle id="issue-report-dialog">
                Report An Issue
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Explain the issue to us in the space below. Thank You!
                </DialogContentText>
                <div
                  style={{
                    border: "1px solid #969aa2",
                    marginBottom: "20px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  <p style={{ padding: "0 10px", margin: "0" }}>
                    Enter Issue Below
                  </p>
                  <Divider />
                  <CKEditor
                    editor={InlineEditor}
                    data={""}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setIssueToReport(data);
                    }}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleClose("issue-report")}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button onClick={reportIssue} color="primary">
                  Submit
                </Button>
              </DialogActions>
            </Dialog>

            <div>
              <Dialog
                open={openOptionsAfterSession}
                onClose={() => handleClose("options-after-session")}
                aria-labelledby="options-after-session-dialog"
              >
                <DialogTitle id="options-after-session-dialog">
                  End of Session.
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    What would you like to do next?
                  </DialogContentText>
                  <Button
                    component={Link}
                    to="/questionbank"
                    variant="contained"
                    color="primary"
                  >
                    Solve more questions
                  </Button>
                  <br />
                  <br />
                  <Button
                    component={Link}
                    to="/dashboard"
                    variant="contained"
                    color="primary"
                  >
                    Go to dashboard
                  </Button>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => handleClose("options-after-session")}
                    color="primary"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            <div>
              <Dialog
                open={openSureToDelete}
                onClose={() => handleClose("sure-to-delete")}
                aria-labelledby="sure-to-delete-dialog"
              >
                <DialogTitle id="sure-to-delete-dialog">
                  Are you sure?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    This process cannot be reversed. Are you sure you want to
                    proceeed?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => handleClose("sure-to-delete")}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() =>
                      doDeleteQuestion(quizPack.questions[practiceQueIndex])
                    }
                    color="primary"
                  >
                    Yes, Delete!
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
