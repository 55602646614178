import React from "react";
import Grid from "@material-ui/core/Grid";
import HomePaper from "./homePaper";
import { getCategories } from "../utils/getCategories";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Form from "./common/form";
import Joi from "joi-browser";
import questionBankImg from "../img/questionBankImg.png";
import osceImg from "../img/osceImg.png";
import { Divider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import { Paper } from "@material-ui/core";
// import Anatomy3D2 from "../img/anatomy3D2.jpg";

class Home extends Form {
  state = {
    quizPacks: [],
    categories: getCategories(),
    myQuizAdded: "",
    quizzes: {},
    user: {},
    data: {
      school: "",
      subject: "",
    },
    errors: {},
  };

  schema = {
    school: Joi.string().required().label("School"),
    subject: Joi.string().required().label("Subject"),
  };

  componentWillMount() {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    this.setState({ user });
  }

  removeLoader = () => {
    this.setState({ removeLoader: true });
  };

  render() {
    const { user } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>Home - Acabest</title>
          <meta
            name="description"
            content="Ace your Medical School exams. All the resources and tools you need."
          />
        </Helmet>
        <Paper
          style={{
            textAlign: "center",
            // height: "40px",
            borderRadius: "0px",
            // lineHeight: "3em",
            color: "#fff",
            backgroundColor: "#ffc107",
            fontWeight: "bolder",
            fontSize: "15px",
            padding: "5px 5px",
            height: "45px",
            alignContent: "center",
          }}
        >
          Are you an MDC OCTOBER exam candidate?{" "}
          <Link to="/accme" style={{ textDecoration: "underline" }}>
            Click here!
          </Link>
        </Paper>
        <HomePaper />

        <Grid
          container
          id="images"
          spacing={0}
          style={{ backgroundColor: "#f4f8f9" }}
        >
          <Grid item md={6} xs={12} className="feature-image">
            <img src={questionBankImg} alt="Question Bank" />
          </Grid>
          <Grid item md={6} xs={12} className="feature-texts">
            <div className="inner-feature-text-container">
              <Typography variant="h4" component="h2" className="images-header">
                Question Bank
              </Typography>
              <Typography variant="body1" className="images-details">
                Access 3200+ questions, sorted by subject, system and TOPIC. Use
                the Quiz Mode to practice in a timed exams fashion. Questions
                come with easy to understand answer explanations.
              </Typography>
              <Button
                component={Link}
                to="/questionbank"
                variant="contained"
                color="primary"
                style={{ margin: "10px auto" }}
              >
                Get Started with the Question Bank
              </Button>
            </div>
            <br />
            <br />
            <br />
            <br />
            <Divider />
          </Grid>

          <Grid item md={6} xs={12} className="feature-texts hide-on-phone">
            <div className="inner-feature-text-container">
              <Typography variant="h4" component="h2" className="images-header">
                OSCE Guides
              </Typography>
              <Typography variant="body1" className="images-details">
                Read OSCE guides, and use our Practice mode feature with your
                friends to improve your speed and precision. Over 120 History,
                Examination and Procedure Guides at your disposal.
              </Typography>
              <Button
                component={Link}
                to="/osce"
                variant="contained"
                color="primary"
                style={{ margin: "10px auto" }}
              >
                Get Started with the OSCE
              </Button>
            </div>
          </Grid>
          <Grid item md={6} xs={12} className="feature-image hide-on-phone">
            <img src={osceImg} alt="OSCE" />
          </Grid>

          <Grid item md={6} xs={12} className="feature-image hide-on-pc">
            <img src={osceImg} alt="OSCE" />
          </Grid>
          <Grid item md={6} xs={12} className="feature-texts hide-on-pc">
            <Typography variant="h4" component="h2" className="images-header">
              OSCE Guides
            </Typography>
            <Typography variant="body1" className="images-details">
              Read OSCE guides, and use our Practice mode feature with your
              friends to improve your speed and precision. Over 120 History,
              Examination and Procedure Guides at your disposal.
            </Typography>
            <Button
              component={Link}
              to="/osce"
              variant="contained"
              color="primary"
              style={{ margin: "10px auto" }}
            >
              Get Started with the OSCE
            </Button>
          </Grid>
        </Grid>

        <Grid container id="spaced-repetition">
          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              className="repetition-header"
            >
              SPACED REPETITION
            </Typography>
            <Typography
              variant="body1"
              component="p"
              className="repetition-main-text"
            >
              Spaced repetition is a memory efficient algorithm that aids
              memorization and recall. This algorithm is woven into our flash
              cards and our unique Automated Revision feature.
            </Typography>
          </Grid>

          <Grid container className="repetition-types">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h3">
                FlashCards
              </Typography>
              <Typography variant="body1" component="p">
                Create Flashcards at will and enjoy the power of spaced
                repetition. Flashcards can also be created from Quiz and OSCE
                pages.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                style={{ maxWidth: "430px" }}
                component={Link}
                to="/flashcards"
              >
                Get Started
              </Button>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h3">
                Automated Revision
              </Typography>
              <Typography variant="body1" component="p">
                Based on your performace on questions from a specific topic, the
                topic is automatically rescheduled by the spaced repetition
                algorithm for revision.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                style={{ maxWidth: "430px" }}
                component={Link}
                to="/spaced-repetition"
              >
                Get Started
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid container id="threeD-models">
              <Grid item md={6} xs={12} className="feature-image" >
                    <img src={Anatomy3D2} alt="Anatomy 3D" />
              </Grid>
              <Grid item md={6} xs={12} className="feature-texts" >
                <div className="inner-feature-text-container">
                  <Typography variant="h4" component="h2" className="images-header">
                    3D Anatomy Playground and Quizzes
                  </Typography>
                  <Typography variant="body1" className="images-details" >
                   Explore our rich library of 3D anatomy models. The models are customised to suit your needs. 
                   You can take a quiz after studying the model to test your anatomy skills. 
                  </Typography>
                  <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to="/questionbank"
                        >
                        Get Started with the 3D Models
                  </Button>
                </div>
              </Grid> 
            </Grid> */}

        <Grid container id="action-area">
          <Grid container>
            <Grid item md={6}>
              <Typography variant="body1" className="action-area-text">
                Our content is produced by doctors and medical students who have
                a track of performing well during exams and are passionate about
                helping other students to perform well too. Start preparing for
                your exams with us.
              </Typography>
              <br />
              <br />
              {!user && <Typography variant="h3">Register Now!</Typography>}
              <br />
              {!user && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  component={Link}
                  to="/student-register"
                >
                  <HowToRegIcon /> Register
                </Button>
              )}
            </Grid>
            <Grid item md={6} style={{ width: "100%", padding: "10px 10px" }}>
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/VabmcPp3a1g"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
              ></iframe>
            </Grid>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Home;
