import React, { Component } from "react";
import NavBar from "./components/navBar";
import { Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from "./components/home";
import LoginForm from "./components/loginForm";
import StudentRegisterForm from "./components/studentRegisterForm";
import PreviewQuizPack from "./components/quizPackPreview";
import QuizUpload from "./components/quizUpload";
import NotFound from "./components/notFound";
import MyProfile from "./components/myProfile";
import Footer from "./components/footer";
import FinishPayment from "./components/finishPayment";
import PrivacyPolicy from "./components/privacy";
import Terms from "./components/terms";
import QuestionBank from "./components/questionbank";
import About from "./components/about";
import Contact from "./components/contact";
import Osce from "./components/osce";
import OsceUpload from "./components/osceUpload";
import OsceResults from "./components/osceResults";
import OscePractice from "./components/oscePractice";
import ForgotPassword from "./components/forgotPassword";
import ScheduledQuiz from "./components/scheduledQuiz";
import MyScheduled from "./components/myScheduled";
import EditOsce from "./components/editOsce";
import RobotTest from "./components/robotTest";
import Questions from "./components/questions";
import Repetition from "./components/repetition";
import SpacedRepetition from "./components/spacedRepetition";
import ThreeDModelsUpload from "./components/ThreeDModelsUpload";
import ThreeDQuiz from "./components/threeDQuiz";
import ThreeDQuizzesResults from "./components/threeDQuizzesResults";
import Dashboard from "./components/dashboard";
import Issues from "./components/issues";
import Edit3dModel from "./components/edit3dModel";
import Flashcards from "./components/flashcards";
import Pricing from "./components/pricing";
// import Maintainance from "./components/maintainance";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Statistics from "./components/statistics";
import Accme from "./components/accme";
import Aecme from "./components/aecme";

class App extends Component {
  state = {
    betaTesterCode: "",
  };

  // componentDidMount() {
  //   const betaTesterCode = localStorage.getItem("betaTesterCode");
  //   this.setState({ betaTesterCode });
  // }

  render() {
    // const { betaTesterCode } = this.state;

    return (
      <React.Fragment>
        <ToastContainer position="top-center" newestOnTop />
        {/* {betaTesterCode && ( */}
        <div>
          <NavBar />
          <Switch>
            <Route path="/home" exact component={Home} />
            <Route
              path="/quiz-preview/:quizid"
              exact
              component={PreviewQuizPack}
            />
            <Route path="/login" exact component={LoginForm} />
            <Route path="/login/:urlTo" exact component={LoginForm} />
            <Route path="/login/:urlTo/:id" exact component={LoginForm} />
            <Route
              path="/student-register"
              exact
              component={StudentRegisterForm}
            />
            <Route
              path="/student-register/:urlTo/:id"
              exact
              component={StudentRegisterForm}
            />
            <Route path="/quiz-upload" exact component={QuizUpload} />
            <Route path="/not-found" exact component={NotFound} />
            <Route path="/my-profile/:userid" exact component={MyProfile} />
            <Route path="/finish-payment" exact component={FinishPayment} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/terms" exact component={Terms} />
            <Route path="/questionbank" exact component={QuestionBank} />
            <Route path="/about" exact component={About} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/osce" exact component={Osce} />
            <Route path="/osce-upload" exact component={OsceUpload} />
            <Route
              path="/osce-results/:subject/:type"
              exact
              component={OsceResults}
            />
            <Route
              path="/osce-practice/:osceId"
              exact
              component={OscePractice}
            />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route
              path="/scheduled-quiz/:quizid"
              exact
              component={ScheduledQuiz}
            />
            <Route path="/my-scheduled" exact component={MyScheduled} />
            <Route path="/edit-osce/:osceId" exact component={EditOsce} />
            <Route path="/robot-test" exact component={RobotTest} />
            <Route path="/questions/:subject" exact component={Questions} />
            <Route path="/repetition" exact component={Repetition} />
            <Route
              path="/spaced-repetition"
              exact
              component={SpacedRepetition}
            />
            <Route
              path="/upload-3d-model"
              exact
              component={ThreeDModelsUpload}
            />
            <Route path="/3d-playground" exact component={ThreeDQuiz} />
            <Route
              path="/3d-playground/:subject/:subdivision/:topic"
              exact
              component={ThreeDQuizzesResults}
            />
            <Route path="/dashboard" exact component={Dashboard} />
            <Route path="/issues" exact component={Issues} />
            <Route
              path="/edit-3d-model/:modelId"
              exact
              component={Edit3dModel}
            />
            <Route path="/flashcards" exact component={Flashcards} />
            <Route path="/pricing" exact component={Pricing} />
            <Route path="/statistics" exact component={Statistics} />
            <Route path="/statistics" exact component={Statistics} />
            <Route path="/accme" exact component={Accme} />
            <Route path="/aecme" exact component={Aecme} />
            <Redirect from="/" exact to="/home" />
            <Redirect to="/not-found" />
          </Switch>
          <Footer />
        </div>
        {/* )} */}
        {/* {!betaTesterCode && <Maintainance />} */}
      </React.Fragment>
    );
  }
}

export default App;
