import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { makeStyles } from "@material-ui/core/styles";
import { getCategories } from "../utils/getCategories";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import firebase from "firebase/app";
import { toast } from "react-toastify";
import PreLoader from "./common/preLoader";
import { Helmet } from "react-helmet";
// import { getRegisterationSchools } from "../utils/getSchools";
import * as firebaseui from "firebaseui";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

class StudentRegisterForm extends Form {
  state = {
    data: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    errors: {},
    submitting: false,
  };

  schema = {
    password: Joi.string().min(6).label("Password"),
    confirmPassword: Joi.any()
      .valid(Joi.ref("password"))
      .required()
      .options({ language: { any: { allowOnly: "must match password" } } })
      .label("Confirm Password"),
    access_token: [Joi.string(), Joi.number()],
    email: Joi.string().email({ minDomainAtoms: 2 }).label("Email Address"),
  };

  componentDidMount() {
    let prevPath = "/dashboard";
    if (this.props.match.params.urlTo) {
      const from = this.props.match.params.urlTo;
      const id = this.props.match.params.id;
      prevPath = `/${from}/${id}`;

      console.log(prevPath);
      this.setState({ prevPath: prevPath });
    }

    var uiConfig = {
      signInSuccessUrl: `${prevPath}`,
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
      // tosUrl and privacyPolicyUrl accept either url string or a callback
      // function.
      // Terms of service url/callback.
      // tosUrl: "/terms-of-service",
      // Privacy policy url/callback.
      privacyPolicyUrl: function () {
        window.location.assign("/privacy-policy");
      },
    };

    // Initialize the FirebaseUI Widget using Firebase.
    var ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());
    // The start method will wait until the DOM is loaded.
    ui.start("#firebaseui-auth-container", uiConfig);
  }

  doSubmit = () => {
    const { data, prevPath } = this.state;
    const email = data.email.trim();
    const password = data.password.trim();

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    const promise = firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    promise.then((data) => {
      //Remove the preloader when submitting is done
      const submitting = this.state.submitting;
      this.setState({ submitting: !submitting });
      toast.success("Successfully Signed Up, Welcome!");
      setTimeout(() => {
        window.location = prevPath || "/dashboard";
      }, 2000);
    });
    promise.catch((error) => {
      //Remove the preloader when submitting is done
      const submitting = this.state.submitting;
      this.setState({ submitting: !submitting });
      var errorMessage = error.message;
      console.log(errorMessage);
      toast.error(errorMessage);
      return;
    });
  };

  categories = getCategories();
  // schools = getRegisterationSchools();

  render() {
    const classes = useStyles;
    const { submitting } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>Register - Acabest</title>
          <meta
            name="description"
            content="Register and Start your Journey into Enjoyable Academia with thousands 
            of Answered Practice questions."
          />
        </Helmet>

        {submitting ? (
          <PreLoader />
        ) : (
          <Container
            component="main"
            maxWidth="sm"
            style={{
              margin: "30px auto",
              width: "95%",
              backgroundColor: "#fff",
              boxShadow:
                "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
              padding: "4rem 2rem",
              borderRadius: "10px",
            }}
          >
            <CssBaseline />
            <div className={classes.paper}>
              <Grid justifyContent="center" alignItems="center" item container>
                <Avatar
                  className={classes.avatar}
                  style={{ backgroundColor: "#f50057" }}
                >
                  <LockOutlinedIcon />
                </Avatar>
                <br />
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ marginLeft: "10px" }}
                >
                  Register
                </Typography>
              </Grid>
              <div
                // className="container"
                id="firebaseui-auth-container"
              ></div>

              <form className={classes.form} onSubmit={this.handleSubmit}>
                {this.renderTextField("email", "Email Address")}
                {this.renderTextField("password", "Password")}
                {this.renderConfirmPassword(
                  "confirmPassword",
                  "Confirm Password"
                )}
                <br />
                <Typography variant="caption">
                  By registering, you confirm that you have read our{" "}
                  <a href="/privacy-policy" style={{ color: "blue" }}>
                    Privacy Policy
                  </a>{" "}
                  and agree to the{" "}
                  <a href="/terms" style={{ color: "blue" }}>
                    Terms of Use
                  </a>
                </Typography>
                <br />
                <br />
                {this.renderSubmitButton("Register")}
                <Grid container>
                  <Grid item>
                    <Link to="/login" variant="body2" style={{ color: "blue" }}>
                      {"Already have an account? Sign In"}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default StudentRegisterForm;
