import { toast } from "react-toastify";

export function getRobotResponse(userInput, currentSection) {
  const robotData = [
    {
      id: "rapport",
      data: [
        {
          input: "hello",
          response: ["hi", "hey"],
        },
        {
          input: "hi",
          response: ["hello", "hey"],
        },
        {
          input: "sorry",
          response: ["Thank you", "Sure"],
        },
        {
          input: "sorry about that",
          response: ["Thank you", "Sure"],
        },
        {
          input: "I'm sorry",
          response: ["Thank you", "Sure"],
        },
        {
          input: "my name is , I am a , do I have your permisssion",
          response: [
            "Yes you have my permission",
            "Sure. Go ahead",
            "Yes please, go ahead",
          ],
        },
        {
          input: "I'm, I'm a year student, may I go on",
          response: [
            "Yes you have my permission",
            "Sure. Go ahead",
            "Yes please, go ahead",
          ],
        },
        {
          input: "good morning",
          response: ["hello", "hey", "hi"],
        },
        {
          input: "good afternoon",
          response: ["hello", "hey", "hi"],
        },
        {
          input: "good evening",
          response: ["hello", "hey", "hi"],
        },
        {
          input: "how are you",
          response: [
            "I'm sick ",
            "I'm not feeling well",
            "I'm unwell",
            "I don't feel good",
          ],
        },
      ],
    },
    {
      id: "demographics",
      data: [
        {
          input: "what is your name",
          response: ["my name is Kwame", "I'm Kwame", "Kwame", "I go by Kwame"],
        },
        {
          input: "your name",
          response: ["My name is Kwame", "I'm Kwame", "Kwame", "I go by Kwame"],
        },
        {
          input: "name",
          response: ["My name is Kwame", "I'm Kwame", "Kwame", "I go by Kwame"],
        },
        {
          input: "how old are you",
          response: [
            "I'm 35 years old",
            "I'm 35",
            "35 years old",
            "I will be 35 in 4 months",
          ],
        },
        {
          input: "what is your age",
          response: [
            "I'm 35 years old",
            "I'm 35",
            "35 years old",
            "I will be 36 in 4 months",
          ],
        },
        {
          input: "your age",
          response: [
            "I'm 35 years old",
            "I'm 35",
            "35 years old",
            "I will be 36 in 4 months",
          ],
        },
        {
          input: "age",
          response: [
            "I'm 35 years old",
            "I'm 35",
            "35 years old",
            "I will be 36 in 4 months",
          ],
        },
        {
          input: "gender",
          response: ["Male", "I'm a Male"],
        },
        {
          input: "what is your gender",
          response: ["Male", "I'm a Male"],
        },
        {
          input: "male or female",
          response: ["Male", "I'm a Male"],
        },
        {
          input: "are you male or female",
          response: ["Male", "I'm a Male"],
        },
        {
          input: "your residence",
          response: ["Korle Gonno", "I live at Korle Gonno"],
        },
        {
          input: "where do you live",
          response: ["Korle Gonno", "I live at Korle Gonno"],
        },
        {
          input: "where do you stay",
          response: ["Korle Gonno", "I live at Korle Gonno"],
        },
        {
          input: "married",
          response: [
            "No I'm not married",
            "never married",
            "I hate marriage",
            "I don't need a wife",
          ],
        },
        {
          input: "are you married or single",
          response: [
            "No I'm not married",
            "never married",
            "I hate marriage",
            "I don't need a wife",
          ],
        },
        {
          input: "do you have a wife",
          response: [
            "No I'm not married",
            "never married",
            "I hate marriage",
            "I don't need a wife",
          ],
        },
        {
          input: "occupation",
          response: ["I'm a carpenter", "carpenter"],
        },
        {
          input: "what is your occupation",
          response: ["I'm a carpenter", "carpenter"],
        },
        {
          input: "profession",
          response: ["I'm a carpenter", "carpenter"],
        },
        {
          input: "what is your profession",
          response: ["I'm a carpenter", "carpenter"],
        },
        {
          input: "what work do you do",
          response: ["I'm a carpenter", "carpenter"],
        },
        {
          input: "insurance",
          response: [
            "Yes I have an active National health insurance",
            "Yes my National health insurance is active",
          ],
        },
        {
          input: "health insurance",
          response: [
            "Yes I have an active National health insurance",
            "Yes my National health insurance is active",
          ],
        },
        {
          input: "do you have health insurance",
          response: [
            "Yes I have an active National health insurance",
            "Yes my National health insurance is active",
          ],
        },
        {
          input: "do you have nhis",
          response: [
            "Yes I have an active National health insurance",
            "Yes my National health insurance is active",
          ],
        },
        {
          input: "nhis",
          response: [
            "Yes I have an active National health insurance",
            "Yes my National health insurance is active",
          ],
        },
      ],
    },
    {
      id: "pc",
      data: [
        {
          input: "presenting complaint",
          response: ["My abdomen hurts", "I feel pains in my abdomen"],
        },
        {
          input: "complaint",
          response: ["My abdomen hurts", "I feel pains in my abdomen"],
        },
        {
          input: "what brings you here",
          response: ["My abdomen hurts", "I feel pains in my abdomen"],
        },
        {
          input: "what is the problem",
          response: ["My abdomen hurts", "I feel pains in my abdomen"],
        },
        {
          input: "what brings you to the hospital",
          response: ["My abdomen hurts", "I feel pains in my abdomen"],
        },
        {
          input: "what is your complaint",
          response: ["My abdomen hurts", "I feel pains in my abdomen"],
        },
        {
          input: "what is wrong with you",
          response: ["My abdomen hurts", "I feel pains in my abdomen"],
        },
        {
          input: "what is wrong",
          response: ["My abdomen hurts", "I feel pains in my abdomen"],
        },
        {
          input: "duration of pain",
          response: [
            "It started yesterday in the morning",
            "somewhere in the morning yesterday",
          ],
        },
        {
          input: "when did you first experience this pain",
          response: [
            "It started yesterday in the morning",
            "somewhere in the morning yesterday",
          ],
        },
        {
          input: "when did the pain start",
          response: [
            "It started yesterday in the morning",
            "somewhere in the morning yesterday",
          ],
        },
        {
          input: "how long have you had this pain",
          response: [
            "It started yesterday in the morning",
            "somewhere in the morning yesterday",
          ],
        },
        {
          input: "since when have you been experiencing this pain",
          response: [
            "It started yesterday in the morning",
            "somewhere in the morning yesterday",
          ],
        },
      ],
    },
    {
      id: "hpc",
      data: [
        {
          input: "duration of pain",
          response: [
            "It started yesterday in the morning",
            "somewhere in the morning yesterday",
          ],
        },
        {
          input: "when did you first experience this pain",
          response: [
            "It started yesterday in the morning",
            "somewhere in the morning yesterday",
          ],
        },
        {
          input: "when did the pain start",
          response: [
            "It started yesterday in the morning",
            "somewhere in the morning yesterday",
          ],
        },
        {
          input: "how long have you had this pain",
          response: [
            "It started yesterday in the morning",
            "somewhere in the morning yesterday",
          ],
        },
        {
          input: "what did you do when the pain came",
          response: ["I took paracetamol, and it felt better for just a while"],
        },
        {
          input: "site of pain",
          response: [
            "It's like it's everywhere in my abdomen",
            "It is actually all around my abdomen",
          ],
        },
        {
          input: "where in your abdomen",
          response: [
            "It's like it's everywhere in my abdomen",
            "It is actually all around my abdomen",
          ],
        },
        {
          input: "where in your abdomen is the pain",
          response: [
            "It's like it's everywhere in my abdomen",
            "It is actually all around my abdomen",
          ],
        },
        {
          input: "where exactly do you feel the pain",
          response: [
            "It's like it's everywhere in my abdomen",
            "It is actually all around my abdomen",
          ],
        },
        {
          input: "which part of you abdomen",
          response: [
            "It's like it's everywhere in my abdomen",
            "It is actually all around my abdomen",
          ],
        },
        {
          input: "onset of pain",
          response: [
            "It started gradually early morning",
            "It was quite gradual and then become very painful",
          ],
        },
        {
          input: "how did the pain start",
          response: [
            "It started gradually early morning",
            "It was quite gradual and then become very painful",
          ],
        },
        {
          input: "Did the pain start gradually or suddenly",
          response: [
            "It started gradually early morning",
            "It was quite gradual and then become very painful",
          ],
        },
        {
          input: "character of pain",
          response: [
            "It was very painful, it comes and then it goes withing a short time, and it keeps doing that. Like someone is squeezing and releasing my intestines",
          ],
        },
        {
          input: "how was the pain like",
          response: [
            "It was very painful, it comes and then it goes withing a short time, and it keeps doing that. Like someone is squeezing and releasing my intestines",
          ],
        },
        {
          input: "about the pain",
          response: [
            "It was very pain ful, it comes and then it goes withing a short time, and it keeps doing that. Like someone is squeezing and releasing my intestines",
          ],
        },
        {
          input: "describe the pain",
          response: [
            "It was very painful, it comes and then it goes withing a short time, and it keeps doing that. Like someone is squeezing and releasing my intestines",
          ],
        },
        {
          input: "can you describe the pain",
          response: [
            "It was very painful, it comes and then it goes withing a short time, and it keeps doing that. Like someone is squeezing and releasing my intestines",
          ],
        },
        {
          input: "tell me about the pain",
          response: [
            "It was very painful, it comes and then it goes withing a short time, and it keeps doing that. Like someone is squeezing and releasing my intestines",
          ],
        },
        {
          input: "Does the pain radiate to any part of you body",
          response: ["No the pain doesn't move to any part of my body"],
        },
        {
          input: "radiation of pain",
          response: ["No the pain doesn't got to any other part of my body"],
        },
        {
          input: "migration of pain",
          response: ["No the pain doesn't move to any part of my body"],
        },
        {
          input: "does the pain move to any part of your body",
          response: ["No the pain doesn't move to any part of my body"],
        },
        {
          input: "where does the pain radiate to",
          response: ["No the pain doesn't move to any part of my body"],
        },
        {
          input: "association of pain",
          response: ["Errmm no please"],
        },
        {
          input: "any other symptoms",
          response: ["Errmm no please"],
        },
        {
          input: "was the pain associated with anything else",
          response: ["Errmm no please"],
        },
        {
          input: "what else happened with the pain",
          response: ["Errmm no please"],
        },
        {
          input: "exacerbation factor of pain",
          response: [
            "I'm not sure there was something I do that made my pain worse",
          ],
        },
        {
          input: "was there something that made the pain worse",
          response: [
            "I'm not sure there was something I do that made my pain worse",
          ],
        },
        {
          input: "did the pain worsen when you did something",
          response: [
            "I'm not sure there was something I do that made my pain worse",
          ],
        },
        {
          input: "What did you do to relief the pain",
          response: [
            "I just took some paracetamol, it only helped for a while",
          ],
        },
        {
          input: "What did you do to feel better",
          response: [
            "I just took some paracetamol, it only helped for a while",
          ],
        },
        {
          input: "severity of pain",
          response: [
            "It's almost like the worst abdominal pain I've experienced",
          ],
        },
        {
          input: "how severe was the pain",
          response: [
            "It's almost like the worst abdominal pain I've experienced",
          ],
        },
        {
          input: "on a scale of 0 to 10, how severe is the pain",
          response: [
            "I would say 7/10",
            "ohh I think about 7/10",
            "Maybe like 7/10",
          ],
        },
        {
          input:
            "on a scale of 0 to 10, how severe is the pain, 10 being the worst pain you've experienced",
          response: [
            "I would say 7/10 with 10",
            "ohh I think about 7/10",
            "Maybe like 7/10",
          ],
        },
      ],
    },
    {
      id: "odqsys",
      data: [
        {
          input: "vomitting",
          response: [
            "Yes I vomitted a lot",
            "I did vomit a number of times",
            "Yes a lot of vomit",
          ],
        },
        {
          input: "did you vomit",
          response: [
            "Yes I vomitted a lot",
            "I did vomit a number of times",
            "Yes a lot of vomit",
          ],
        },
        {
          input: "have you vomitted",
          response: [
            "Yes I vomitted a lot",
            "I did vomit a number of times",
            "Yes a lot of vomit",
          ],
        },
        {
          input: "have you been vomitting",
          response: [
            "Yes I vomitted a lot",
            "I did vomit a number of times",
            "Yes a lot of vomit",
          ],
        },
        {
          input: "quantity of vomit",
          response: [
            "Errmm the vomit would fill 1 500ml bottle of water",
            "I would say about 500ml because it can fill one water bottle",
          ],
        },
        {
          input: "how much did you vomit",
          response: [
            "Errmm the vomit would fill 1 500ml bottle of water",
            "I would say about 500ml because it can fill one water bottle",
          ],
        },
        {
          input: "what is the quantity of your vomit",
          response: [
            "Errmm the vomit would fill 1 500ml bottle of water",
            "I would say about 500ml because it can fill one water bottle",
          ],
        },
        {
          input: "color of vomit",
          response: ["It looks greenish", "It's like greenish"],
        },
        {
          input: "what is the color of the vomit",
          response: ["It looks greenish", "It's like greenish"],
        },
        {
          input: "what color was the vomit",
          response: ["It looks greenish", "It's like greenish"],
        },
        {
          input: "number of times vomitted",
          response: [
            "I vomiteed about 4 times",
            "I think I vomitted about 4 times",
          ],
        },
        {
          input: "how many times have you vomitted",
          response: [
            "I vomiteed about 4 times",
            "I think I vomitted about 4 times",
          ],
        },
        {
          input: "content of vomit",
          response: ["The vomit just contained some greenish fluid"],
        },
        {
          input: "what was the content of vomit",
          response: ["The vomit just contained some greenish fluid"],
        },
        {
          input: "what did the vomit contain",
          response: ["The vomit just contained some greenish fluid"],
        },
        {
          input: "what did the vomit look like",
          response: ["The vomit just contained some greenish fluid"],
        },
        {
          input: "Did the vomit contain previously eaten food",
          response: ["The vomit just contained some greenish fluid"],
        },
        {
          input: "Did the vomit it contain food you had eaten before",
          response: ["The vomit just contained some greenish fluid"],
        },
        {
          input: "any constipation",
          response: [
            "I haven't been able to visit the toilet since it started even though I would normally go everyday",
          ],
        },
        {
          input: "have you been able to move your bowel",
          response: [
            "I haven't been able to visit the toilet since it started even though I would normally go everyday",
          ],
        },
        {
          input: "have you been constipated",
          response: [
            "I haven't been able to visit the toilet since it started even though I would normally go everyday",
          ],
        },
        {
          input: "have you been able to pass gas",
          response: ["Yeahh maybe I've been passing gas"],
        },
        {
          input: "are you able to pass gas, flatulate",
          response: ["Yeahh maybe I've been passing gas"],
        },
        {
          input: "Abdominal distension",
          response: [
            "No my abdomen didn't get bigger but I did notice some swelling on a scar of surgery",
          ],
        },
        {
          input: "did your abdomen get bigger",
          response: [
            "No my abdomen didn't get bigger but I did notice some swelling on a scar of surgery",
          ],
        },
        {
          input: "did your belly get bigger",
          response: [
            "No my abdomen didn't get bigger but I did notice some swelling on a scar of surgery",
          ],
        },
        {
          input: "did your belly get bigger",
          response: [
            "No my abdomen didn't get bigger but I did notice some swelling on a scar of surgery",
          ],
        },
        {
          input: "previous surgery",
          response: [
            "Yes I had severe abdominal pain with fever and had an abdominal surgery",
          ],
        },
        {
          input: "have you had a surgery before",
          response: [
            "Yes I had severe abdominal pain with fever and had an abdominal surgery",
          ],
        },
        {
          input: "tell me about your surgery",
          response: [
            "Yes I had severe abdominal pain with fever and had an abdominal surgery",
          ],
        },
        {
          input: "the swelling",
          response: [
            "Yes I had severe abdominal pain with fever and had an abdominal surgery",
          ],
        },
        {
          input: "Tell me about the swelling on your abdomen",
          response: [
            "It used to come and go but now it doesn't go back anymore, it wasn't painful but now it is",
          ],
        },
        {
          input: "does the swelling on your abdomen go and come",
          response: [
            "It used to come and go but now it doesn't go back anymore, it wasn't painful but now it is",
          ],
        },
        {
          input: "how is the swelling in your abdomen like",
          response: [
            "It used to come and go but now it doesn't go back anymore, it wasn't painful but now it is",
          ],
        },
        {
          input: "change in bowel habit",
          response: ["No there hasn't been any change in my stool"],
        },
        {
          input: "any change in your stool",
          response: ["No there hasn't been any change in my stool"],
        },
        {
          input: "mucoid stools",
          response: ["No mucus in my stools"],
        },
        {
          input: "mucus in stools",
          response: ["No mucus in my stools"],
        },
        {
          input: "mucus in toilet",
          response: ["No mucus in my stools"],
        },
        {
          input: "blood in stool",
          response: ["No there has been no blood in my stools"],
        },
        {
          input: "blood in your stools",
          response: ["No there has been no blood in my stools"],
        },
        {
          input: "blood in your toilet",
          response: ["No there has been no blood in my stools"],
        },
        {
          input: "Is there blood in your stools",
          response: ["No there has been no blood in my stools"],
        },
      ],
    },
    {
      id: "",
      data: [
        {
          input: "",
          response: "",
        },
      ],
    },
  ];

  // const checkOtherSections = () => {
  //     const data = [];

  //     for(let i=0; i<robotData.length; i++) {
  //         for(let x=0; x<robotData[i].data.length; x++) {
  //             data.push(robotData[i].data[x])
  //         }
  //     }

  //     console.log(data);

  //     let scores = [];
  //     for(let i=0; i<data.length; i++) {
  //         scores[i] = similarity(userInput, data[i].input)
  //     }

  //     console.log("scores", scores)
  //     console.log("max score", Math.max(...scores))

  //     const maxScore = Math.max(...scores);
  //     const indexOfMax = scores.indexOf(maxScore);
  //     console.log("index of max", indexOfMax);
  //     const bestResponseList = data[indexOfMax].response;
  //     console.log("best response list", bestResponseList);

  //     const bestResponse = bestResponseList[Math.floor(Math.random() * bestResponseList.length)];
  //     console.log("best response", bestResponse)

  //     if( maxScore < 0.5 ) {
  //         let topScores = []
  //         console.log("max score is less than 0.5")
  //         topScores = scores.sort(function(a, b){return b - a}).slice(0,5)
  //         console.log(topScores)

  //         let responseScores = [];
  //         let inputPlusResponseScoresList = [];
  //         for (let i=0; i<topScores.length; i++) {
  //             const index = scores.indexOf(topScores[i]);
  //             console.log("index", index);

  //             const bestResponseList = data[index].response;
  //             responseScores[i] = similarity(userInput, bestResponseList[Math.floor(Math.random() * bestResponseList.length)]);
  //             const inputPlusResponseScore = responseScores[i] + topScores[i];
  //             inputPlusResponseScoresList[i] = inputPlusResponseScore;

  //         }

  //         if(Math.max(...inputPlusResponseScoresList) < 0.5 ) {
  //             console.log("the score is still less than .5")

  //             return "I'm not sure I understand you, could you rephrase your question?"

  //         } else if (Math.max(...inputPlusResponseScoresList) >= 0.5 ) {
  //             const maxScore = Math.max(...inputPlusResponseScoresList)
  //             const indexOfMaxScore = inputPlusResponseScoresList.indexOf(maxScore)
  //             const bestResponseScore = responseScores[indexOfMaxScore];
  //             const bestResponseOldScore = scores.indexOf(bestResponseScore);
  //             console.log(bestResponseOldScore);
  //             const bestResponseList = data[indexOfMax].response;
  //             const bestResponse = bestResponseList[Math.floor(Math.random() * bestResponseList.length)];
  //             return bestResponse;
  //         }

  //     console.log(inputPlusResponseScoresList)
  //     }

  //     return bestResponse;

  // }

  if (currentSection) {
    const filteredData = robotData.filter(
      (data) => data.id === currentSection
    )[0].data;
    console.log("filtered data", filteredData);

    let scores = [];
    for (let i = 0; i < filteredData.length; i++) {
      scores[i] = similarity(userInput, filteredData[i].input);
    }

    // console.log("scores", scores)
    console.log("max score", Math.max(...scores));

    const maxScore = Math.max(...scores);
    const indexOfMax = scores.indexOf(maxScore);
    // console.log("index of max", indexOfMax);
    const bestResponseList = filteredData[indexOfMax].response;
    // console.log("best response list", bestResponseList);

    const bestResponse =
      bestResponseList[Math.floor(Math.random() * bestResponseList.length)];
    // console.log("best response", bestResponse)

    if (maxScore < 0.5) {
      let topScores = [];
      console.log("max score is less than 0.5");
      topScores = scores
        .sort(function (a, b) {
          return b - a;
        })
        .slice(0, 5);
      console.log(topScores);

      let responseScores = [];
      let inputPlusResponseScoresList = [];
      for (let i = 0; i < topScores.length; i++) {
        const index = scores.indexOf(topScores[i]);
        console.log("index", index);

        const bestResponseList = filteredData[index].response;
        responseScores[i] = similarity(
          userInput,
          bestResponseList[Math.floor(Math.random() * bestResponseList.length)]
        );
        const inputPlusResponseScore = responseScores[i] + topScores[i];
        inputPlusResponseScoresList[i] = inputPlusResponseScore;
      }

      if (Math.max(...inputPlusResponseScoresList) < 0.2) {
        console.log("the score is still less than .5");

        toast.error("Are you sure this question belongs in this section?");
        return "I'm not sure I understand you, could you rephrase your question?";

        // return;
      } else if (Math.max(...inputPlusResponseScoresList) >= 0.2) {
        const maxScore = Math.max(...inputPlusResponseScoresList);
        const indexOfMaxScore = inputPlusResponseScoresList.indexOf(maxScore);
        const bestResponseScore = responseScores[indexOfMaxScore];
        const bestResponseOldScore = scores.indexOf(bestResponseScore);
        console.log(bestResponseOldScore);
        const bestResponseList = filteredData[indexOfMax].response;
        const bestResponse =
          bestResponseList[Math.floor(Math.random() * bestResponseList.length)];
        return bestResponse;
      }

      console.log(inputPlusResponseScoresList);
    }

    return bestResponse;
  } else {
    toast.error("Please add a section to begin your history taking");
    return "";
    // return checkOtherSections()
  }

  // function checkSimilarity(){
  //     var str1 = document.getElementById("lhsInput").value;
  //     var str2 = document.getElementById("rhsInput").value;
  //     document.getElementById("output").innerHTML = similarity(str1, str2);
  //   }

  function similarity(s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }
    return (
      (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
    );
  }

  function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = [];
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i === 0) costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) !== s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }
}
