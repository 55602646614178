import React, { Component } from "react";
import firebase from "firebase/app";
import { CardContent, Card, CardActions } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { getSubjectNameById, getTopicTitle } from "../utils/getCategories";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PreLoader from "./common/preLoader";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import "firebase/auth";

class Issues extends Component {
  state = {
    issues: [],
    openEditQuestion: false,
    submitting: false,
    editedQueData: {
      question: "",
      optionA: "",
      optionB: "",
      optionC: "",
      optionD: "",
      optionE: "",
      answer: "",
      answerExplanation: "",
      subject: "",
      subdivision: "",
      topic: "",
      queId: "",
    },
    questionToEdit: {
      question: "",
      options: [
        { content: "", id: "a" },
        { content: "", id: "b" },
        { content: "", id: "c" },
        { content: "", id: "d" },
        { content: "", id: "e" },
      ],
      answer: {
        id: "",
        explanation: "",
      },
      subject: "",
      system: "",
      topic: "",
    },
    sendResponse: false,
    responseToUser: "",
    issueToShowResponse: "",
  };

  componentDidMount() {
    let issues = [];
    const user = JSON.parse(localStorage.getItem("currentUser"));
    // const userId = localStorage.getItem("currentUserId");

    if (!user || (user && user.userType !== "tutor")) {
      this.setState({ notPermitted: true });
    }

    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.email) {
        //FOR CHECKING CUSTOM STATES
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            // console.log(idTokenResult.claims)
            if (
              idTokenResult.claims.admin &&
              idTokenResult.claims.admin === true
            ) {
              this.setState({ isAdmin: true });
            } else if (
              idTokenResult.claims.editor &&
              idTokenResult.claims.editor.length > 0
            ) {
              const features = idTokenResult.claims.moderator.features;
              // console.log(features)
              this.setState({
                isEditor: true,
                editorDetails: {
                  features: features,
                },
              });
            } else if (
              idTokenResult.claims.moderator &&
              idTokenResult.claims.moderator.features &&
              idTokenResult.claims.moderator.features.length > 0
            ) {
              const features = idTokenResult.claims.moderator.features;
              const subjects = idTokenResult.claims.moderator.subjects;
              console.log(features);
              console.log(subjects);
              this.setState({
                isModerator: true,
                moderatorDetails: {
                  features: features,
                  subjects: subjects,
                },
              });
            } else if (
              idTokenResult.claims.tracker &&
              idTokenResult.claims.tracker === true
            ) {
              this.setState({ isTracker: true });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });

    const getIssues = firebase
      .firestore()
      .collection("issues")
      .orderBy("issueId", "desc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log(doc.data());
          issues.push(doc.data());
        });
      })
      .catch((error) => {
        console.log(error.message);
      });

    Promise.all([getIssues]).then(() => {
      const { isModerator, isAdmin, isEditor, isTracker } = this.state;
      let filteredIssues = [];

      if (isAdmin || isTracker) {
        this.setState({ issues });
      } else if (isEditor) {
        const { features } = this.state.editorDetails;
        for (let x = 0; x < issues.length; x++) {
          if (features.includes(issues[x].from)) {
            filteredIssues.push(issues[x]);
          }
        }
        // console.log(filteredIssues);
        this.setState({ issues: filteredIssues });
      } else if (isModerator) {
        const { features, subjects } = this.state.moderatorDetails;
        for (let x = 0; x < issues.length; x++) {
          if (
            subjects.includes(issues[x].subject) &&
            features.includes(issues[x].from)
          ) {
            filteredIssues.push(issues[x]);
          }
        }
        // console.log(filteredIssues);
        this.setState({ issues: filteredIssues });
      }
    });
  }

  setIssueResolved = (currentIssue) => {
    // console.log(issueId);
    // console.log("resolving the issue");
    const issueId = currentIssue.issueId.toString();
    // for adding a document to mail
    const mailDocumentId = Date.now().toString();
    const reporter = currentIssue.reportedBy;
    const { responseToUser, sendResponse } = this.state;
    const editedResponse = `<div>${responseToUser}<p>Your Report: ${currentIssue.content}</p></div>`;
    const reportDate = new Date(
      Number(currentIssue.issueId)
    ).toLocaleDateString();

    if (sendResponse && responseToUser.length < 10) {
      toast.error(
        "Click 'User Response' to hide the textbox if you don't intend to send user response."
      );
      return;
    }

    firebase
      .firestore()
      .collection("issues")
      .doc(issueId)
      .delete()
      .then(() => {
        toast.success(
          "Issue has been resolved and removed!. Refresh page to reflect changes"
        );
        this.setState({ sendResponse: false, responseToUser: "" });
        if (sendResponse) {
          firebase
            .firestore()
            .collection("mail")
            .doc(mailDocumentId)
            .set({
              to: reporter.email,
              message: {
                subject: `Response to Issue reported on ${reportDate}.`,
                html: editedResponse,
              },
            });
        }
      });
  };

  answerOptions = [
    { name: "A" },
    { name: "B" },
    { name: "C" },
    { name: "D" },
    { name: "E" },
    { name: "TT" },
    { name: "FF" },
    { name: "TF" },
    { name: "FT" },
    { name: "TTT" },
    { name: "TTF" },
    { name: "TFF" },
    { name: "FFF" },
    { name: "FTT" },
    { name: "FFT" },
    { name: "FFF" },
    { name: "TFT" },
    { name: "FTF" },
    { name: "TTTT" },
    { name: "TTTF" },
    { name: "TTFF" },
    { name: "TFFF" },
    { name: "FFFF" },
    { name: "FTTT" },
    { name: "FTFT" },
    { name: "FTFF" },
    { name: "FFTF" },
    { name: "FTTF" },
    { name: "TFFT" },
    // {name: ""},
  ];

  handleClickOpen = (type, itemId) => {
    if (type === "edit-question") {
      //Launch the preloader while submitting
      this.setState({ submitting: true });

      firebase
        .firestore()
        .collection("questions")
        .doc(itemId)
        .get()
        .then((doc) => {
          console.log(doc.data());
          const questionToEdit = doc.data();
          this.setState({
            questionToEdit,
            openEditQuestion: true,
            editedQueData: {
              question: questionToEdit.question,
              optionA: questionToEdit.options[0].content,
              optionB: questionToEdit.options[1].content,
              optionC: questionToEdit.options[2].content,
              optionD: questionToEdit.options[3].content,
              optionE: questionToEdit.options[4].content,
              answer: questionToEdit.answer.id.toUpperCase(),
              answerExplanation: questionToEdit.answer.explanation,
              subject: questionToEdit.subject,
              subdivision: questionToEdit.system,
              topic: questionToEdit.topic,
              queId: questionToEdit.id,
            },
          });
          this.setState({ submitting: false });
        })
        .catch((error) => {
          console.log(error.message);
          this.setState({ submitting: false });
        });
    }
  };

  handleClose = (type) => {
    if (type === "edit-question") {
      this.setState({ openEditQuestion: false });
    }
  };

  handleEditQueSubmit = () => {
    console.log("submitting the edited que");
    const { editedQueData } = this.state;

    console.log(editedQueData);
    const {
      queId,
      question,
      optionA,
      optionB,
      optionC,
      optionD,
      optionE,
      answer,
      answerExplanation,
    } = editedQueData;

    if (
      !answer ||
      question.length < 8 ||
      optionA.length < 8 ||
      optionB.length < 8 ||
      answerExplanation.length < 8
    ) {
      toast.error(
        "Question, Correct Answer, Option A, Option B and Answer Explanation must not be empty"
      );
      return;
    }

    //Launch the preloader while submitting
    this.setState({ submitting: true });

    let newQue = {
      question: question,
      options: [
        { content: optionA, id: "a" },
        { content: optionB, id: "b" },
        { content: optionC, id: "c" },
        { content: optionD, id: "d" },
        { content: optionE, id: "e" },
      ],
      answer: {
        explanation: answerExplanation,
        id: answer,
      },
      id: queId,
    };

    firebase
      .firestore()
      .collection("questions")
      .doc(queId)
      .update(newQue)
      .then(() => {
        this.setState({ submitting: false });
        toast.success(
          "Successfully Updated Question. Reload page to see changes!"
        );
        this.setState({ openEditQuestion: false });
      })
      .catch((error) => {
        console.log(error.message);
        toast.error("Something went wrong");
        this.setState({ openEditQuestion: false });
      });
  };

  render() {
    const {
      issues,
      submitting,
      openEditQuestion,
      editedQueData,
      questionToEdit,
      notPermitted,
      responseToUser,
      sendResponse,
      issueToShowResponse,
    } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>Issues - Acabest</title>
        </Helmet>

        {submitting ? (
          <PreLoader />
        ) : (
          <div id="issue-cards">
            {!notPermitted &&
              issues.map((issue) => (
                <Container
                  key={issue.issueId}
                  component="main"
                  maxWidth="sm"
                  style={{
                    margin: "20px auto",
                    width: "95%",
                    // backgroundColor: "#3f51b580",
                    boxShadow:
                      "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                    background:
                      "linear-gradient(42deg, rgb(63, 81, 181), #007bff33 70.71%)",
                    padding: "4rem 2rem",
                    borderRadius: "10px",
                  }}
                >
                  <Card variant="outlined" style={{ margin: "1%" }}>
                    <CardContent>
                      <Typography
                        variant="body1"
                        style={{ fontFamily: "cursive", fontWeight: "bold" }}
                      >
                        Issue ID: {issue.issueId}
                      </Typography>
                      <Divider /> <br />
                      <Grid>
                        <p>
                          Reported on:{" "}
                          {new Date(Number(issue.issueId)).toLocaleDateString()}
                        </p>
                        <p>Issue from: {issue.from}</p>
                        {issue.title && <p>Title: {issue.title}</p>}
                        {issue.subject && (
                          <p>Subject: {getSubjectNameById(issue.subject)}</p>
                        )}
                        {issue.system && <p>System: {issue.system}</p>}
                        {/* {issue.topic && <p>
                                        Topic: {getTopicTitle(issue.topic)}</p>} */}
                        {/* {issue.itemId && <p>
                                        ItemId: {issue.itemId}</p>} */}
                        {issue.reportedBy && (
                          <p>
                            Reported By:{" "}
                            {`${issue.reportedBy.firstName} (${issue.reportedBy.email})`}
                          </p>
                        )}
                        {issue.messageSubject && (
                          <p>Message Subject: {issue.messageSubject}</p>
                        )}
                        <p>
                          <strong>Issue Content:</strong>
                        </p>
                        <div>{ReactHtmlParser(issue.content)}</div>
                      </Grid>
                      {issue.from === "questionbank" && (
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            fontSize: "16px",
                            padding: "2px 5px",
                            margin: "0px 5px 10px 5px",
                          }}
                          onClick={() =>
                            this.handleClickOpen("edit-question", issue.itemId)
                          }
                        >
                          EDIT THIS QUESTION
                        </Button>
                      )}
                      {issue.from === "osce" && (
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            fontSize: "16px",
                            padding: "2px 5px",
                            margin: "0px 5px 10px 5px",
                          }}
                          onClick={() =>
                            (window.location = `/edit-osce/${issue.itemId}`)
                          }
                        >
                          EDIT THIS OSCE
                        </Button>
                      )}
                      <br />
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          const { sendResponse, issueToShowResponse } =
                            this.state;
                          if (issueToShowResponse !== issue.issueId) {
                            this.setState({
                              sendResponse: true,
                              issueToShowResponse: issue.issueId,
                            });
                          } else {
                            this.setState({
                              sendResponse: !sendResponse,
                              issueToShowResponse: issue.issueId,
                            });
                          }
                        }}
                      >
                        User Response
                      </Button>
                      {sendResponse && issueToShowResponse === issue.issueId && (
                        <div
                          style={{
                            border: "1px solid #969aa2",
                            marginBottom: "20px",
                            borderRadius: "5px 5px 0px 0px",
                          }}
                        >
                          <p style={{ padding: "0 10px", margin: "0" }}>
                            Response to Reporter
                          </p>
                          <Divider />
                          <CKEditor
                            editor={InlineEditor}
                            data={responseToUser}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              this.setState({ responseToUser: data });
                            }}
                          />
                        </div>
                      )}
                    </CardContent>
                    <CardActions>
                      <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => this.setIssueResolved(issue)}
                      >
                        Resolved
                      </Button>
                    </CardActions>
                  </Card>

                  <div></div>
                </Container>
              ))}
          </div>
        )}

        {notPermitted && (
          <div style={{ margin: "30px" }}>
            <Typography variant="body1">
              Please{" "}
              <Button
                component={Link}
                variant="contained"
                color="primary"
                to="/login"
              >
                Sign In
              </Button>{" "}
              to Continue.
            </Typography>
            <Typography variant="caption">
              If you are alredy signed in, then simply refresh the page
            </Typography>
          </div>
        )}

        <Dialog
          open={openEditQuestion}
          onClose={() => this.handleClose("edit-question")}
          aria-labelledby="edit-question"
        >
          <DialogTitle id="edit-question-dialog">
            Edit this question
          </DialogTitle>
          <DialogContent>
            <form>
              <br />
              <div
                style={{
                  border: "1px solid #969aa2",
                  marginBottom: "20px",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <p style={{ padding: "0 10px", margin: "0" }}>Question</p>
                <Divider />
                <CKEditor
                  editor={InlineEditor}
                  data={questionToEdit.question}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    const quesData = { ...editedQueData };
                    quesData.question = data;
                    this.setState({ editedQueData: quesData });
                  }}
                />
              </div>

              {questionToEdit.options.map((option) => (
                <div
                  key={option.id}
                  style={{
                    border: "1px solid #969aa2",
                    marginBottom: "20px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  <p
                    style={{ padding: "0 10px", margin: "0" }}
                  >{`option ${option.id.toUpperCase()}`}</p>
                  <Divider />
                  <CKEditor
                    editor={InlineEditor}
                    data={option.content}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      const quesData = { ...editedQueData };
                      quesData[`option${option.id.toUpperCase()}`] = data;
                      this.setState({ editedQueData: quesData });
                    }}
                  />
                </div>
              ))}
              <Autocomplete
                id="answer-options"
                options={this.answerOptions}
                getOptionLabel={(option) => option.name}
                style={{ width: 300 }}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  const quesData = { ...editedQueData };
                  quesData.answer = newValue.name.toLowerCase();
                  this.setState({ editedQueData: quesData });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Correct Answer"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <br />
              <div
                style={{
                  border: "1px solid #969aa2",
                  marginBottom: "20px",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <p style={{ padding: "0 10px", margin: "0" }}>
                  Answer Explanation
                </p>
                <Divider />
                <CKEditor
                  editor={InlineEditor}
                  data={questionToEdit.answer.explanation}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    const quesData = { ...editedQueData };
                    quesData.answerExplanation = data;
                    this.setState({ editedQueData: quesData });
                  }}
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleClose("edit-question")}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={this.handleEditQueSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default Issues;
