import React, { Component } from "react";
import firebase from "firebase/app";
import { toast } from "react-toastify";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import HeaderPaper from "./common/headerPaper";
import PreLoader from "./common/preLoader";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#f50057",
  },
}));

class MyScheduled extends Component {
  state = {
    schedules: [],
    foundNoSchedules: false,
  };

  componentDidMount() {
    const userId = localStorage.getItem("currentUserId");
    //    console.log(userId);

    let schedules = [];
    let getSchedules = {};

    getSchedules = firebase
      .firestore()
      .collection("scheduled")
      .where("schedulerId", "==", userId)
      .orderBy("startDate", "desc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const quizPack = doc.data();
          //  console.log(new Date(quizPack.startDate).toString())
          const expiryDate = quizPack.startDate + 86400000;
          //  console.log(new Date(expiryDate).toString())
          if (Date.now() < expiryDate) {
            schedules.push(quizPack);
          }
        });
      })
      .catch((error) => {
        console.error(error.message);
        toast.error(error.message);
      });

    Promise.all([getSchedules]).then(() => {
      if (schedules.length === 0) {
        this.setState({ foundNoSchedules: true });
      }
      this.setState({ schedules });
    });
  }

  goToQuiz = (schedule) => {
    // console.log("scheduled", schedule);

    // localStorage.setItem("currentScheduled", JSON.stringify(schedule));
    window.location = `/scheduled-quiz/${schedule.scheduleId}`;
  };

  render() {
    const classes = useStyles;
    const { schedules, foundNoSchedules } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>Scheduled Quizzes - Acabest</title>
        </Helmet>

        {!schedules.length > 0 && foundNoSchedules === false ? (
          <PreLoader />
        ) : (
          <div>
            <HeaderPaper headerTitle={"My Schedules"} />
            <Typography
              style={{
                padding: "15px 0px 0px 30px",
                textTransform: "capitalize",
              }}
              variant="body1"
            ></Typography>

            <div style={{ width: "90%", margin: "auto", marginTop: "30px" }}>
              <Typography
                style={{
                  padding: "15px 0px 20px 30px",
                  textTransform: "capitalize",
                }}
                variant="body1"
              >
                <em>Note: Schedules dissapear 24hrs after they are due.</em>
              </Typography>
              <Grid container spacing={4}>
                {schedules.map((schedule) => (
                  <Grid item key={schedule.scheduleId}>
                    <Card className={classes.card}>
                      <CardHeader
                        title={
                          <Button onClick={() => this.goToQuiz(schedule)}>
                            <Typography
                              variant="body1"
                              style={{ fontWeight: "bold" }}
                            >
                              {schedule.title}
                            </Typography>
                          </Button>
                        }
                      />
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        )}
        {foundNoSchedules && (
          <Typography
            style={{
              padding: "15px 0px 20px 30px",
              textTransform: "capitalize",
            }}
            variant="body1"
          >
            <em>
              You have no Sessions sheduled.{" "}
              <a href="/questionbank" style={{ color: "blue" }}>
                Click here
              </a>{" "}
              to schedule one!
            </em>
          </Typography>
        )}
      </React.Fragment>
    );
  }
}

export default MyScheduled;
