import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// import underConstruction from "../img/underConstruction.svg";
import {
  Button,
  Typography,
  CardContent,
  Card,
  CardActions,
  Divider,
} from "@material-ui/core";
import { getSubjectNameById } from "../utils/getCategories";
import ReactHtmlParser from "react-html-parser";
import Pagination from "@material-ui/lab/Pagination";
import firebase from "firebase/app";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import {
  getSubdivisions,
  getSubjects,
  getTopics,
} from "../utils/getCategories";
import FormControl from "@material-ui/core/FormControl";
import PreLoader from "./common/preLoader";
import { Link } from "react-router-dom";
import { removeEmptySpaces } from "../utils/removeEmptySpaces";

class Flashcards extends React.Component {
  state = {
    userFlashcards: [],
    dueCards: [],
    currentCardIndex: 0,
    isCardFront: true,
    openCreateFlashCard: false,
    openSeeCommunityCards: false,
    flashcardData: {
      cardFrontText: "",
      cardBackText: "",
      subject: "",
      subdivision: "",
      topic: "",
      allowSharing: true,
    },
    submitting: false,
    seeAllCards: false,
    user: {},
    subjects: [],
    subjectsWithRelevantContent: [],
  };

  componentDidMount() {
    const userId = localStorage.getItem("currentUserId");

    const statistics = JSON.parse(localStorage.getItem("statistics"));

    // GETTING THE SUBJECTS LIST
    const subjects = getSubjects(statistics.subjects);
    const findSubjectsWithRelevantContent = subjects.filter(
      (subject) => subject.noOfQuestions > 0
    );
    const subjectsWithRelevantContent = getSubjects(
      findSubjectsWithRelevantContent
    );

    this.setState({ subjects, subjectsWithRelevantContent });

    if (!userId) {
      this.setState({ notSignedIn: true });
      return;
    }

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .onSnapshot((doc) => {
        const user = doc.data();
        const userFlashcards = user.flashcards || [];
        if (userFlashcards.length > 0) {
          this.setState({ userFlashcards });
        }

        const dueCards = userFlashcards.filter(
          (card) =>
            card.dueDate < Date.now() ||
            card.phase === "new" ||
            (card.phase === "relearn" && card.dueDate < Date.now())
        );

        this.setState({ dueCards, user });
        // console.log(userFlashcards);
      });
  }

  systems = () => {
    const { subject } = this.state.flashcardData;
    const { subjects } = this.state;

    const subjectData = subjects.filter(
      (relevantSubject) => relevantSubject.id === subject
    )[0];
    const subdivisions = subjectData.subdivisions;
    subjectData.subdivisions = subdivisions;
    return getSubdivisions([subject], [subjectData]);
  };

  topics = () => {
    const { subject, subdivision } = this.state.flashcardData;
    const { subjects } = this.state;

    let subjectData = subjects.filter(
      (relevantSubject) => relevantSubject.id === subject
    )[0];
    let subdivisionData = subjectData.subdivisions.filter(
      (theSubdivision) => theSubdivision.id === subdivision
    )[0] || { topics: [] };
    const relevantTopics = subdivisionData.topics;
    subdivisionData.topics = relevantTopics;
    const newSubjectData = {
      ...subjectData,
      subdivisions: [subdivisionData],
    };

    return getTopics(subject, subdivision, [newSubjectData]);
  };

  doCardFlip = () => {
    const { isCardFront } = this.state;
    this.setState({ isCardFront: !isCardFront });
  };

  goToCard = (event, page) => {
    // console.log(page);
    let { currentCardIndex } = this.state;
    currentCardIndex = page - 1;

    this.setState({ currentCardIndex, isCardFront: true });
  };

  setGood = (card) => {
    const { user } = this.state;
    const userId = localStorage.getItem("currentUserId");
    const cardId = card.id;
    let cards = user.flashcards;
    let newUserCards = cards.filter((card) => card.id !== cardId);
    // const { dueCards } = this.state;
    // console.log(newUserCards);
    // console.log(card);

    card.phase = "reviewing";
    card.interval = card.interval * card.ease;
    card.intervalInDays = card.interval / 86400000;

    const dueDate = Date.now() + card.interval;
    card.dueDate = dueDate;
    card.dueDateInWords = new Date(card.dueDate).toString();

    // const newDueCards = dueCards.filter((card) => card.id !== cardId);
    this.setState({ isCardFront: true });
    newUserCards.push(card);
    user.flashcards = newUserCards;
    // console.log(card);
    // console.log(user.flashcards);

    // return;

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update({
        flashcards: user.flashcards,
      })
      .then(() => {
        toast.success("This flashcard has been rescheduled for review");
      });
  };

  setAgain = (card) => {
    const { user } = this.state;
    const userId = localStorage.getItem("currentUserId");
    const cardId = card.id;
    let cards = user.flashcards;
    let newUserCards = cards.filter((card) => card.id !== cardId);
    let dateObj = new Date();
    dateObj.setUTCHours(0, 0, 0, 0);
    const midNight = +dateObj;
    // const { dueCards } = this.state;

    card.phase = "relearn";
    card.interval = 1;
    card.ease = card.ease * 0.85;
    card.grade = 1;
    card.lapses += 1;
    card.dueDate = midNight + 86400000;
    card.dueDateInWords = new Date(card.dueDate).toString();

    // const newDueCards = dueCards.filter((card) => card.id !== cardId);
    this.setState({ isCardFront: true });
    newUserCards.push(card);
    user.flashcards = newUserCards;
    // console.log(card);
    // console.log(user.flashcards);

    // return;

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update({
        flashcards: user.flashcards,
      })
      .then(() => {
        toast.success("This flashcard has been rescheduled for review");
      });
  };

  setHard = (card) => {
    const { user } = this.state;
    const userId = localStorage.getItem("currentUserId");
    const cardId = card.id;
    let cards = user.flashcards;
    let newUserCards = cards.filter((card) => card.id !== cardId);
    // const { dueCards } = this.state;

    card.phase = "review";
    card.ease = card.ease * 0.8;
    let newInterval = card.interval * card.ease;
    if (newInterval > 1.2 * 86400000) {
      card.interval = newInterval;
    } else {
      card.interval = card.interval * 1.2;
    }
    card.intervalInDays = card.interval / 86400000;

    const dueDate = Date.now() + card.interval;
    card.dueDate = dueDate;
    card.dueDateInWords = new Date(card.dueDate).toString();

    // const newDueCards = dueCards.filter((card) => card.id !== cardId);
    this.setState({ isCardFront: true });
    newUserCards.push(card);
    user.flashcards = newUserCards;
    // console.log(card);
    // console.log(user.flashcards);

    // return;

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update({
        flashcards: user.flashcards,
      })
      .then(() => {
        toast.success("This flashcard has been rescheduled for review");
      });
  };

  setEasy = (card) => {
    const { user } = this.state;
    const userId = localStorage.getItem("currentUserId");
    const cardId = card.id;
    let cards = user.flashcards;
    let newUserCards = cards.filter((card) => card.id !== cardId);
    // const { dueCards } = this.state;

    card.phase = "review";
    card.ease = card.ease * 1.15;
    card.interval = card.interval * card.ease;
    card.intervalInDays = card.interval / 86400000;

    const dueDate = Date.now() + card.interval;
    card.dueDate = dueDate;
    card.dueDateInWords = new Date(card.dueDate).toString();

    // const newDueCards = dueCards.filter((card) => card.id !== cardId);
    this.setState({ isCardFront: true });
    newUserCards.push(card);
    user.flashcards = newUserCards;
    // console.log(card);
    // console.log(user.flashcards);

    // return;

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update({
        flashcards: user.flashcards,
      })
      .then(() => {
        toast.success("This flashcard has been rescheduled for review");
      });
  };

  handleClickOpen = (type) => {
    if (this.state.notSignedIn) {
      toast.error("Kindly sign in to continue");
      return;
    }
    if (type === "create-flashcard") {
      this.setState({ openCreateFlashCard: true });
    } else if (type === "see-community-cards") {
      this.setState({ openSeeCommunityCards: true });
    }
  };

  handleClose = (type) => {
    if (type === "create-flashcard") {
      this.setState({ openCreateFlashCard: false });
    } else if (type === "see-community-cards") {
      this.setState({ openSeeCommunityCards: false });
    }
  };

  createFlashcard = () => {
    // console.log("creating your card");
    // console.log(flashcardData);
    const { user } = this.state;
    const {
      cardFrontText,
      cardBackText,
      subject,
      subdivision,
      topic,
      allowSharing,
    } = this.state.flashcardData;
    const creator = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    };
    const cardId = Date.now().toString();
    const userId = localStorage.getItem("currentUserId");

    if (!cardFrontText || !cardBackText || !subject || !subdivision) {
      toast.error(
        "Card front text, Card back text, Subject and Subdivision are required!"
      );
      return;
    }
    // console.log(cardId);

    //Launch the preloader while submitting
    this.setState({ submitting: true });

    let userCards = [];
    let dateObj = new Date();
    dateObj.setUTCHours(0, 0, 0, 0);
    const midNight = +dateObj;

    let updateUserData = {};
    if (user) {
      if (user.flashcards) {
        userCards = user.flashcards;
      } else {
        userCards = [];
      }
      let userCardsIds = [];
      userCards.forEach((card) => {
        userCardsIds.push(card.id);
      });

      if (!userCardsIds.includes(cardId)) {
        // console.log(userCards);
        userCards.push({
          frontText: cardFrontText,
          backText: cardBackText,
          subject: subject,
          system: subdivision,
          topic: topic,
          creator: creator,
          id: cardId,
          upvotes: 0,
          reports: [],
          allowSharing: allowSharing,
          phase: "new",
          interval: 86400000,
          ease: 2.5,
          dueDate: midNight + 86400000,
          dueDateInWords: new Date(midNight + 86400000).toString(),
          lapses: 0,
        });
        updateUserData = firebase
          .firestore()
          .collection("users")
          .doc(userId)
          .update({
            flashcards: userCards,
          });
      }
    }

    const sendCardToCommunity = firebase
      .firestore()
      .collection("flashcards")
      .doc(cardId)
      .set({
        frontText: cardFrontText,
        backText: cardBackText,
        subject: subject,
        system: subdivision,
        topic: topic,
        creator: creator,
        id: cardId,
        upvotes: 0,
        reports: [],
        allowSharing: allowSharing,
      });

    const incrementSubjectStats = firebase
      .firestore()
      .collection(`statistics/subjects/subjectData`)
      .doc(subject)
      .update({
        noOfFlashcards: firebase.firestore.FieldValue.increment(1),
      })
      .catch((error) => {
        console.log(error.message);
      });
    const incrementSystemStats = firebase
      .firestore()
      .collection(`statistics/subjects/subjectData/${subject}/systems`)
      .doc(subdivision)
      .update({
        noOfFlashcards: firebase.firestore.FieldValue.increment(1),
      })
      .catch((error) => {
        console.log(error.message);
      });
    const incrementTopicStats = firebase
      .firestore()
      .collection(
        `statistics/subjects/subjectData/${subject}/systems/${subdivision}/topics`
      )
      .doc(topic)
      .update({
        noOfFlashcards: firebase.firestore.FieldValue.increment(1),
      })
      .catch((error) => {
        console.log(error.message);
      });
    const incrementGeneralStats = firebase
      .firestore()
      .collection(`statistics`)
      .doc("general")
      .update({
        flashcards: firebase.firestore.FieldValue.increment(1),
      })
      .catch((error) => {
        console.log(error.message);
      });

    Promise.all([
      updateUserData,
      sendCardToCommunity,
      incrementSubjectStats,
      incrementSystemStats,
      incrementTopicStats,
      incrementGeneralStats,
    ])
      .then(() => {
        // console.log("everything went smoothly");
        this.setState({
          openCreateFlashCard: false,
          flashcardData: {
            cardFrontText: "",
            cardBackText: "",
            subject: "",
            subdivision: "",
            topic: "",
            allowSharing: true,
          },
        });
        this.setState({ submitting: false });
        toast.success("Flashcard successfully created");
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({ submitting: false });
      });
  };

  showAllMyFlashcards = () => {
    if (this.state.notSignedIn) {
      toast.error("Kindly sign in to continue");
      return;
    }

    const { user } = this.state;
    const userCards = user.flashcards || [];
    if (userCards.length < 1) {
      toast.error(
        "You have no cards in your deck. Go to 'Create a Flashcard' to begin adding flash cards"
      );
      return;
    }
    this.setState({
      seeAllCards: true,
      dueCards: userCards,
      isCardFront: true,
      currentCardIndex: 0,
      flashcardData: {
        cardFrontText: "",
        cardBackText: "",
        subject: "",
        subdivision: "",
        topic: "",
        allowSharing: true,
      },
    });
  };

  showOnlyDueCards = () => {
    if (this.state.notSignedIn) {
      toast.error("Kindly sign in to continue");
      return;
    }
    const { user } = this.state;
    const userFlashcards = user.flashcards || [];

    const dueCards = userFlashcards.filter(
      (card) =>
        card.dueDate < Date.now() ||
        card.phase === "new" ||
        (card.phase === "relearn" && card.dueDate < Date.now())
    );
    this.setState({
      seeAllCards: false,
      dueCards,
      isCardFront: true,
      currentCardIndex: 0,
      flashcardData: {
        cardFrontText: "",
        cardBackText: "",
        subject: "",
        subdivision: "",
        topic: "",
        allowSharing: true,
      },
    });
  };

  showCommunityCards = () => {
    if (this.state.notSignedIn) {
      toast.error("Kindly sign in to continue");
      return;
    }
    this.setState({ openSeeCommunityCards: true });
  };

  doFilterBySubject = (subject) => {
    const { seeAllCards, userFlashcards } = this.state;
    let cardsInSubject = [];
    if (seeAllCards) {
      if (!subject) {
        cardsInSubject = userFlashcards;
      } else {
        cardsInSubject = userFlashcards.filter(
          (card) => card.subject === subject
        );
      }
    } else {
      if (!subject) {
        const dueUserCards = userFlashcards.filter(
          (card) =>
            card.dueDate < Date.now() ||
            card.phase === "new" ||
            (card.phase === "relearn" && card.dueDate < Date.now())
        );
        cardsInSubject = dueUserCards;
      } else {
        const dueUserCards = userFlashcards.filter(
          (card) =>
            card.dueDate < Date.now() ||
            card.phase === "new" ||
            (card.phase === "relearn" && card.dueDate < Date.now())
        );
        cardsInSubject = dueUserCards.filter(
          (card) => card.subject === subject
        );
      }
    }
    // console.log(cardsInSubject);
    this.setState({ dueCards: cardsInSubject });
  };

  doFilterBySystem = (system) => {
    const { seeAllCards, userFlashcards } = this.state;
    const { subject } = this.state.flashcardData;
    let cardsInSystem = [];
    if (seeAllCards) {
      if (!system) {
        cardsInSystem = userFlashcards.filter(
          (card) => card.subject === subject
        );
      } else {
        cardsInSystem = userFlashcards.filter(
          (card) => card.subject === subject && card.system === system
        );
      }
    } else {
      if (!system) {
        const dueUserCards = userFlashcards.filter(
          (card) =>
            card.dueDate < Date.now() ||
            card.phase === "new" ||
            (card.phase === "relearn" && card.dueDate < Date.now())
        );
        cardsInSystem = dueUserCards.filter((card) => card.subject === subject);
      } else {
        const dueUserCards = userFlashcards.filter(
          (card) =>
            card.dueDate < Date.now() ||
            card.phase === "new" ||
            (card.phase === "relearn" && card.dueDate < Date.now())
        );
        cardsInSystem = dueUserCards.filter(
          (card) => card.subject === subject && card.system === system
        );
      }
    }

    this.setState({ dueCards: cardsInSystem });
  };

  doFilterByTopic = (topic) => {
    const { seeAllCards, userFlashcards } = this.state;
    const { subject, subdivision: system } = this.state.flashcardData;
    let cardsInTopic = [];
    if (seeAllCards) {
      if (!topic) {
        cardsInTopic = userFlashcards.filter(
          (card) => card.subject === subject && card.system === system
        );
      } else {
        cardsInTopic = userFlashcards.filter(
          (card) =>
            card.subject === subject &&
            card.system === system &&
            card.topic === topic
        );
      }
    } else {
      if (!topic) {
        const dueUserCards = userFlashcards.filter(
          (card) =>
            card.dueDate < Date.now() ||
            card.phase === "new" ||
            (card.phase === "relearn" && card.dueDate < Date.now())
        );
        cardsInTopic = dueUserCards.filter(
          (card) => card.subject === subject && card.system === system
        );
      } else {
        const dueUserCards = userFlashcards.filter(
          (card) =>
            card.dueDate < Date.now() ||
            card.phase === "new" ||
            (card.phase === "relearn" && card.dueDate < Date.now())
        );
        cardsInTopic = dueUserCards.filter(
          (card) =>
            card.subject === subject &&
            card.system === system &&
            card.topic === topic
        );
      }
    }

    this.setState({ dueCards: cardsInTopic });
  };

  render() {
    // const classes = useStyles;
    const {
      dueCards,
      currentCardIndex,
      isCardFront,
      openCreateFlashCard,
      openSeeCommunityCards,
      flashcardData,
      submitting,
      seeAllCards,
      notSignedIn,
      subjects,
    } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>Flashcards - Acabest</title>
          {/* <meta name="description" content={quizPack.shortDescription} /> */}
        </Helmet>

        <br />
        <Typography variant="h4" component="h1" style={{ textAlign: "center" }}>
          Flashcards
        </Typography>

        {submitting || !subjects.length > 0 ? (
          <PreLoader />
        ) : (
          <div>
            {notSignedIn && (
              <div style={{ margin: "30px" }}>
                <Typography variant="body1">
                  Please{" "}
                  <Button
                    component={Link}
                    variant="contained"
                    color="primary"
                    to={`/login/flashcards`}
                  >
                    Sign In
                  </Button>{" "}
                  to Continue.
                </Typography>
                <Typography variant="caption">
                  If you are alredy signed in, then simply refresh the page
                </Typography>
              </div>
            )}
            <Container component="main" style={{ marginTop: "20px" }}>
              <Grid container id="flashcards-action-buttons">
                <Grid item xs={4} className="button-grids">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => this.handleClickOpen("create-flashcard")}
                  >
                    Create A Flashcard
                  </Button>
                </Grid>
                {!seeAllCards && (
                  <Grid item xs={4} className="button-grids">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={this.showAllMyFlashcards}
                    >
                      See all my Flashcards
                    </Button>
                  </Grid>
                )}
                {seeAllCards && (
                  <Grid item xs={4} className="button-grids">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={this.showOnlyDueCards}
                    >
                      See Only Due Cards
                    </Button>
                  </Grid>
                )}
                <Grid item xs={4} className="button-grids">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={this.showCommunityCards}
                  >
                    Find from Community
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                  }}
                >
                  <Typography variant="body2">Filter:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    style={{ padding: "0px" }}
                    variant="standard"
                    size="small"
                  >
                    <InputLabel>Subject</InputLabel>
                    <Select
                      native
                      value={flashcardData.subject}
                      style={{
                        width: "80px",
                        display: "inline",
                        fontSize: "12px",
                      }}
                      onChange={(event) => {
                        const cardData = { ...flashcardData };
                        cardData.subject = event.currentTarget.value;
                        cardData.subdivision = ""; // to reset the subdivisions
                        this.setState({ flashcardData: cardData });
                        this.doFilterBySubject(event.currentTarget.value);
                      }}
                      id="create-flashcard-subject"
                    >
                      <option value="" />
                      {subjects.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  {/* {flashcardData.subject && ( */}
                  <FormControl
                    disabled={!flashcardData.subject}
                    style={{ padding: "0px" }}
                    variant="standard"
                    size="small"
                  >
                    <InputLabel>System</InputLabel>
                    <Select
                      native
                      value={flashcardData.subdivision}
                      style={{
                        width: "80px",
                        display: "inline",
                        fontSize: "12px",
                      }}
                      onChange={(event) => {
                        const cardData = { ...flashcardData };
                        cardData.subdivision = event.currentTarget.value;
                        cardData.topic = ""; // to reset the topic
                        this.setState({ flashcardData: cardData });
                        this.doFilterBySystem(event.currentTarget.value);
                      }}
                      id="create-flashcard-subdivision"
                    >
                      <option value="" />
                      {flashcardData.subject &&
                        this.systems().map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                  {/* )} */}
                </Grid>
                <Grid item xs={3}>
                  {/* {flashcardData.subdivision && ( */}
                  <FormControl
                    disabled={!flashcardData.subdivision}
                    style={{ padding: "0px" }}
                    variant="standard"
                    size="small"
                  >
                    <InputLabel>Topic</InputLabel>
                    <Select
                      native
                      value={flashcardData.topic}
                      style={{
                        width: "80px",
                        display: "inline",
                        fontSize: "12px",
                      }}
                      onChange={(event) => {
                        const cardData = { ...flashcardData };
                        cardData.topic = event.currentTarget.value;
                        this.setState({ flashcardData: cardData });
                        this.doFilterByTopic(event.currentTarget.value);
                      }}
                      id="create-flashcard-topic"
                    >
                      <option value="" />
                      {flashcardData.subdivision &&
                        this.topics().map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                  {/* )} */}
                </Grid>
              </Grid>

              {!seeAllCards && (
                <Grid container id="due-cards">
                  <Container
                    component="main"
                    maxWidth="sm"
                    style={{
                      margin: "30px auto",
                      width: "95%",
                      backgroundColor: "#fff",
                      background:
                        "linear-gradient(42deg, rgb(63, 81, 181), #007bff33 70.71%)",
                      boxShadow:
                        "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                      padding: "1rem 1rem",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography variant="h6" style={{ textAlign: "center" }}>
                      Cards due for Review
                    </Typography>
                    {dueCards.length < 1 && (
                      <Typography style={{ textAlign: "center" }}>
                        You have no cards due for Review
                      </Typography>
                    )}
                    {dueCards.length > 0 && (
                      <Card
                        variant="outlined"
                        style={{
                          margin: "20px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                          backgroundColor: isCardFront ? "#13324e" : "#213546",
                          color: "#fff",
                        }}
                      >
                        <CardContent>
                          <div
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            {getSubjectNameById(
                              dueCards[currentCardIndex].subject
                            )}{" "}
                            <strong
                              style={{ fontWeight: "bolder", fontSize: "25px" }}
                            >
                              |
                            </strong>{" "}
                            {isCardFront ? "Front" : "Back"}
                          </div>
                          <Divider style={{ color: "#fff !important" }} />{" "}
                          <br />
                          {isCardFront && (
                            <div>
                              {ReactHtmlParser(
                                removeEmptySpaces(
                                  dueCards[currentCardIndex].frontText
                                )
                              )}
                            </div>
                          )}
                          {!isCardFront && (
                            <div>
                              {ReactHtmlParser(
                                removeEmptySpaces(
                                  dueCards[currentCardIndex].backText
                                )
                              )}
                            </div>
                          )}
                        </CardContent>
                        {isCardFront && (
                          <CardActions style={{ justifyContent: "center" }}>
                            <Button
                              onClick={this.doCardFlip}
                              size="small"
                              variant="contained"
                              color="primary"
                              fullWidth
                              style={{ width: "80%" }}
                            >
                              Flip Card
                            </Button>
                          </CardActions>
                        )}
                        {!isCardFront && (
                          <CardActions>
                            <Grid container>
                              <Grid item xs={6} style={{ padding: "5px 10px" }}>
                                <Button
                                  onClick={() =>
                                    this.setAgain(dueCards[currentCardIndex])
                                  }
                                  size="small"
                                  variant="contained"
                                  fullWidth
                                  style={{
                                    backgroundColor: "red",
                                    color: "#fff",
                                  }}
                                >
                                  Again
                                </Button>
                              </Grid>
                              <Grid item xs={6} style={{ padding: "5px 10px" }}>
                                <Button
                                  onClick={() =>
                                    this.setHard(dueCards[currentCardIndex])
                                  }
                                  size="small"
                                  variant="contained"
                                  fullWidth
                                  style={{
                                    backgroundColor: "orange",
                                    color: "#fff",
                                  }}
                                >
                                  Hard
                                </Button>
                              </Grid>
                              <Grid item xs={6} style={{ padding: "5px 10px" }}>
                                <Button
                                  onClick={() =>
                                    this.setGood(dueCards[currentCardIndex])
                                  }
                                  size="small"
                                  variant="contained"
                                  fullWidth
                                  style={{
                                    backgroundColor: "green",
                                    color: "#fff",
                                  }}
                                >
                                  Good
                                </Button>
                              </Grid>
                              <Grid item xs={6} style={{ padding: "5px 10px" }}>
                                <Button
                                  onClick={() =>
                                    this.setEasy(dueCards[currentCardIndex])
                                  }
                                  size="small"
                                  variant="contained"
                                  fullWidth
                                  style={{
                                    backgroundColor: "#7070dc",
                                    color: "#fff",
                                  }}
                                >
                                  Easy
                                </Button>
                              </Grid>
                              {!isCardFront && (
                                <Button
                                  onClick={() => {
                                    this.setState({ isCardFront: true });
                                  }}
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  // style={{ width: "80%" }}
                                >
                                  Flip To Front
                                </Button>
                              )}
                            </Grid>
                          </CardActions>
                        )}
                      </Card>
                    )}
                    <Pagination
                      count={dueCards.length}
                      // size="small"
                      variant="outlined"
                      shape="rounded"
                      onChange={this.goToCard}
                    />
                  </Container>
                </Grid>
              )}

              {seeAllCards && (
                <Grid container id="see-all-cards">
                  <Container
                    component="main"
                    maxWidth="sm"
                    style={{
                      margin: "30px auto",
                      width: "95%",
                      backgroundColor: "#fff",
                      background:
                        "linear-gradient(42deg, rgb(63, 81, 181), #007bff33 70.71%)",
                      boxShadow:
                        "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                      padding: "1rem 1rem",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography variant="h6" style={{ textAlign: "center" }}>
                      All My Flashcards
                    </Typography>
                    {dueCards.length > 0 && (
                      <Card
                        variant="outlined"
                        style={{
                          margin: "20px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                          backgroundColor: isCardFront ? "#13324e" : "#213546",
                          color: "#fff",
                        }}
                      >
                        <CardContent>
                          <div
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            {getSubjectNameById(
                              dueCards[currentCardIndex].subject
                            )}{" "}
                            <strong
                              style={{ fontWeight: "bolder", fontSize: "25px" }}
                            >
                              |
                            </strong>{" "}
                            {isCardFront ? "Front" : "Back"}
                          </div>
                          <Divider style={{ color: "#fff !important" }} />{" "}
                          <br />
                          {isCardFront && (
                            <div>
                              {ReactHtmlParser(
                                removeEmptySpaces(
                                  dueCards[currentCardIndex].frontText
                                )
                              )}
                            </div>
                          )}
                          {!isCardFront && (
                            <div>
                              {ReactHtmlParser(
                                removeEmptySpaces(
                                  dueCards[currentCardIndex].backText
                                )
                              )}
                            </div>
                          )}
                        </CardContent>
                        {isCardFront && (
                          <CardActions style={{ justifyContent: "center" }}>
                            <Button
                              onClick={this.doCardFlip}
                              size="small"
                              variant="contained"
                              color="primary"
                              fullWidth
                              style={{ width: "80%" }}
                            >
                              Flip Card
                            </Button>
                          </CardActions>
                        )}
                        {!isCardFront && (
                          <CardActions style={{ justifyContent: "center" }}>
                            <Button
                              onClick={() => {
                                this.setState({ isCardFront: true });
                              }}
                              size="small"
                              variant="contained"
                              color="primary"
                              fullWidth
                              style={{ width: "80%" }}
                            >
                              Flip To Front
                            </Button>
                          </CardActions>
                        )}
                      </Card>
                    )}
                    <Pagination
                      count={dueCards.length}
                      // size="small"
                      variant="outlined"
                      shape="rounded"
                      onChange={this.goToCard}
                    />
                  </Container>
                </Grid>
              )}

              <div>
                <Dialog
                  open={openCreateFlashCard}
                  onClose={() => this.handleClose("create-flashcard")}
                  aria-labelledby="create-flashcard-dialog"
                >
                  <DialogTitle id="create-flashcard-dialog">
                    Create A Flashcard
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Enter details for your card below. Unless you "Do not
                      allow sharing", your card will be added to the community
                      cards so that other users may benefit from it.
                    </DialogContentText>
                    <div
                      style={{
                        border: "1px solid #969aa2",
                        marginBottom: "20px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      <p style={{ padding: "0 10px", margin: "0" }}>
                        Front of Card
                      </p>
                      <Divider />
                      <CKEditor
                        editor={InlineEditor}
                        data={""}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          const cardData = { ...flashcardData };
                          cardData.cardFrontText = data;
                          this.setState({ flashcardData: cardData });
                        }}
                      />
                    </div>
                    <div
                      style={{
                        border: "1px solid #969aa2",
                        marginBottom: "20px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      <p style={{ padding: "0 10px", margin: "0" }}>
                        Back of Card
                      </p>
                      <Divider />
                      <CKEditor
                        editor={InlineEditor}
                        data={""}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          const cardData = { ...flashcardData };
                          cardData.cardBackText = data;
                          this.setState({ flashcardData: cardData });
                        }}
                      />
                    </div>
                    <div>
                      <FormControl
                        style={{ width: "100%", margin: "12px auto" }}
                        variant="outlined"
                      >
                        <InputLabel>Selct Subject</InputLabel>
                        <Select
                          native
                          value={flashcardData.subject}
                          onChange={(event) => {
                            const cardData = { ...flashcardData };
                            cardData.subject = event.currentTarget.value;
                            cardData.subdivision = ""; // to reset the subdivisions
                            this.setState({ flashcardData: cardData });
                          }}
                          id="create-flashcard-subject"
                        >
                          <option value="" />
                          {subjects.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                      {flashcardData.subject && (
                        <FormControl
                          style={{ width: "100%", margin: "12px auto" }}
                          variant="outlined"
                        >
                          <InputLabel>Selct Subdivision</InputLabel>
                          <Select
                            native
                            value={flashcardData.subdivision}
                            onChange={(event) => {
                              const cardData = { ...flashcardData };
                              cardData.subdivision = event.currentTarget.value;
                              cardData.topic = ""; // to reset the topic
                              this.setState({ flashcardData: cardData });
                            }}
                            id="create-flashcard-subdivision"
                          >
                            <option value="" />
                            {this.systems().map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      {flashcardData.subject && flashcardData.subdivision && (
                        <FormControl
                          style={{ width: "100%", margin: "12px auto" }}
                          variant="outlined"
                        >
                          <InputLabel>
                            Select Topic(optional) - recommended
                          </InputLabel>
                          <Select
                            native
                            value={flashcardData.topic}
                            onChange={(event) => {
                              const cardData = { ...flashcardData };
                              cardData.topic = event.currentTarget.value;
                              this.setState({ flashcardData: cardData });
                            }}
                            id="create-flashcard-topic"
                          >
                            <option value="" />
                            {this.topics().map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                      <div>
                        <input
                          value="allow"
                          type="checkbox"
                          id="do-not-allow-card-sharing"
                          onChange={(event) => {
                            const cardData = { ...flashcardData };
                            const allow = !event.currentTarget.checked;
                            cardData.allowSharing = allow;
                            this.setState({ flashcardData: cardData });
                          }}
                        />
                        <label
                          htmlFor="do-not-allow-card-sharing"
                          style={{
                            marginLeft: "10px",
                            // display: "flex",
                          }}
                        >
                          Do not allow sharing!
                        </label>
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => this.handleClose("create-flashcard")}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button onClick={this.createFlashcard} color="primary">
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>

              <div>
                <Dialog
                  open={openSeeCommunityCards}
                  onClose={() => this.handleClose("see-community-cards")}
                  aria-labelledby="see-community-cards-dialog"
                >
                  <DialogTitle id="see-community-cards-dialog">
                    Flashcards By Acabest User Community
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      This feature will be available when there is enough
                      FlashCards. You can help get it ready soon by creating and
                      sharing flashcards on your favorite topics.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => this.handleClose("see-community-cards")}
                      color="primary"
                    >
                      Okay
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Container>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Flashcards;
