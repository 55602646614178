import React, { Component } from "react";
import HeaderPaper from "./common/headerPaper";
import Grid from "@material-ui/core/Grid";
import { Helmet } from "react-helmet";

class Terms extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Terms of Use - Acabest</title>\{" "}
        </Helmet>

        <HeaderPaper headerTitle="Terms of Use" />

        <Grid
          container
          item
          xs={9}
          lg={6}
          style={{ margin: "30px auto", textAlign: "justify" }}
        >
          <div>
            <p>
              Thank you for joining Acabest. We are dedicated to making sure we
              provide you with the most relevant materials.
            </p>

            <p>
              The Acabest Platform (website) is a copyrighted work belonging to
              Acabest. Certain features of the application may be subject to
              additional guidelines, terms, or rules, which will be posted on
              the website in connection with such features.
            </p>
            <p>
              All such additional terms, guidelines, and rules are incorporated
              by reference into these Terms.
            </p>

            <p>
              These Terms of Use describes the legally binding terms and
              conditions that oversee your use of the Application. BY USING THE
              Platform, YOU AGREE TO COMPLY BY THESE TERMS and you represent
              that you have the authority and capacity to enter into these
              Terms. YOU SHOULD BE AT LEAST 18 YEARS OF AGE TO ACCESS THE
              Platform. IF YOU DISAGREE WITH ANY OF THE PROVISION OF THESE
              TERMS, DO NOT USE THE Application.
            </p>

            <h4 style={{ textAlign: "center" }}>Access to the Application</h4>

            <h6>
              <strong>Subject to these Terms.</strong>
            </h6>

            <p>
              You have a non-transferable, non-exclusive, revocable, limited
              license to access the Platform solely for your own personal,
              noncommercial use.
            </p>

            <h6>
              <strong>Certain Restrictions.</strong>
            </h6>

            <p>
              The rights approved to you in these Terms are subject to the
              following restrictions: (a) you shall not sell, rent, lease,
              transfer, assign, distribute, host, or otherwise commercially
              exploit the Platform; (b) you shall not change, make derivative
              works of, disassemble, reverse compile or reverse engineer any
              part of the Platform; (c) you shall not access the Platform in
              order to build a similar or competitive Platform; and (d) except
              as expressly stated herein, no part of the Platform may be copied,
              reproduced, distributed, republished, downloaded, displayed,
              posted or transmitted in any form or by any means unless otherwise
              indicated, any future release, update, or other addition to
              functionality of the Platform shall be subject to these Terms. All
              copyright and other proprietary notices on the Platform must be
              retained on all copies thereof.
            </p>

            <h4 style={{ textAlign: "center" }}>Disclaimers</h4>

            <p>
              Acabest is not a replacement for your being present at lectures,
              clinic rotations, tutorials or any other requirements from your
              medical school. Although we try to make sure the contents on this
              platform are accurate, up-to date and comprehensive, we cannot
              guarantee that the contents are always accurate, up-to date and
              comprehensive. You use the content at your own risk. The content
              on Acabest is for study and information purposes only. The
              information provided should not be used for making a diagnosis or
              treating any medical problem or disease.* *The information does
              not constitute medical advice, and should not be used as a
              replacement for consulting a qualified professional on any medical
              concerns
            </p>

            <h4 style={{ textAlign: "center" }}>Limitation on Liability</h4>

            <p>
              To the maximum extent permitted by law, in no event shall Acabest
              be liable to you or any third-party for any lost profits, lost
              data, costs of procurement of substitute products, or any
              indirect, consequential, exemplary, incidental, special or
              punitive damages arising from or relating to these terms or your
              use of, or incapability to use the Platform even if we have been
              advised of the possibility of such damages. Access to and use of
              the Platform is at your own discretion and risk, and you will be
              solely responsible for any damage to your device or computer
              system, or loss of data resulting therefrom.
            </p>

            <p>
              To the maximum extent permitted by law, notwithstanding anything
              to the contrary contained herein, our liability to you for any
              damages arising from or related to this agreement, will at all
              times be limited to a maximum of 1 Ghana cedi (GHS 1). The
              existence of more than one claim will not enlarge this limit. You
              agree that our suppliers will have no liability of any kind
              arising from or relating to this agreement.
            </p>

            <p>
              Some jurisdictions do not allow the limitation or exclusion of
              liability for incidental or consequential damages, so the above
              limitation or exclusion may not apply to you.
            </p>

            <h6>
              <strong>Term and Termination.</strong>
            </h6>

            <p>
              Subject to this Section, these Terms will remain in full force and
              effect while you use the Application. We may suspend or terminate
              your rights to use the Platform in violation of these Terms. Upon
              termination of your rights under these Terms, your right to access
              and use the Application will terminate immediately. The company
              will not have any liability whatsoever to you for any termination
              of your rights under these Terms. Even after your rights under
              these Terms are terminated, the following provisions of these
              Terms will remain in effect: Sections 2 through 2.5.
            </p>

            <h4 style={{ textAlign: "center" }}>General</h4>

            <p>
              These Terms are subject to occasional revision. You will be
              notified in case of material changes. Any changes to these Terms
              will be effective immediately. Continued use of the Platform
              following such changes shall indicate your acknowledgment of such
              changes and agreement to be bound by the terms and conditions of
              such changes.
            </p>

            <h6>
              <strong>Entire Terms.</strong>
            </h6>

            <p>
              These Terms constitute the entire agreement between you and us
              regarding the use of the Acabest.com. Our failure to exercise or
              enforce any right or provision of these Terms shall not operate as
              a waiver of such right or provision. The section titles in these
              Terms are for convenience only and have no legal or contractual
              effect. The word "including" means "including without limitation".
              If any provision of these Terms is held to be invalid or
              unenforceable, the other provisions of these Terms will be
              unimpaired and the invalid or unenforceable provision will be
              deemed modified so that it is valid and enforceable to the maximum
              extent permitted by law. Your relationship with us is that of an
              independent contractor, and neither party is an agent or partner
              of the other. These Terms, and your rights and obligations herein,
              may not be assigned, subcontracted, delegated, or otherwise
              transferred by you without our prior written consent, and any
              attempted assignment, subcontract, delegation, or transfer in
              violation of the foregoing will be null and void. We may freely
              assign these Terms. The terms and conditions set forth in these
              Terms shall be binding upon assignees.
            </p>

            <h6>
              <strong>Copyright/Trademark Information.</strong>
            </h6>

            <p>
              Copyright ©. All rights reserved. All trademarks, logos, and
              service marks displayed on the Platform are our property or the
              property of other third-parties. You are not permitted to use
              these Marks without our prior written consent or the consent of
              such third party which may own the Marks.
            </p>

            <h4 style={{ textAlign: "center" }}>Contact Information</h4>

            <p>Email: acabestmail@gmail.com</p>
          </div>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Terms;
