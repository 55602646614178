const userLevel = localStorage.getItem("userLevel");

const categories = [
  {
    name: "Medicine",
    id: "medicine",
    level: "clinical",
    subdivisions: [
      {
        name: "Neurology",
        id: "cns",
        topics: [
          {
            name: "Delirium",
            id: "delirium",
            system: "cns",
          },
          {
            name: "Dementia",
            id: "dementia",
            system: "cns",
          },
          {
            name: "Epilepsy",
            id: "epilepsy",
            system: "cns",
          },
          {
            name: "Guillain-Barre Syndrome",
            id: "guillain-Barre_syndrome",
            system: "cns",
          },
          {
            name: "Headache",
            id: "headache",
            system: "cns",
          },
          {
            name: "Motor Neuron Diseases",
            id: "motor_neuron_diseases",
            system: "cns",
          },
          {
            name: "Multiple Sclerosis",
            id: "multiple_sclerosis",
            system: "cns",
          },
          {
            name: "Myasthenia Gravis",
            id: "myasthenia_gravis",
            system: "cns",
          },
          {
            name: "Myopathies",
            id: "myopathies",
            system: "cns",
          },
          {
            name: "Neuropathies",
            id: "neuropathies",
            system: "cns",
          },
          {
            name: "Optic Pathway lesions",
            id: "optic_pathway_lesions",
            system: "cns",
          },
          {
            name: "Parkinson Disease",
            id: "parkinson_disease",
            system: "cns",
          },
          {
            name: "Stroke",
            id: "stroke",
            system: "cns",
          },
        ],
      },
      {
        name: "Cardiology",
        id: "cvs",
        topics: [
          {
            name: "Arrhythmias Overview",
            id: "arrhythmias_overview",
            system: "cvs",
          },
          {
            name: "Atrial Fibrillation",
            id: "atrial_fibrillation",
            system: "cvs",
          },
          {
            name: "cardiomyopathies",
            id: "cardiomyopathies",
            system: "cvs",
          },
          {
            name: "Coronary Artery Diseases (Angina & MI)",
            id: "coronary_artery_diseases",
            system: "cvs",
          },
          {
            name: "Deep Vein Thrombosis",
            id: "deep_vein_thrombosis",
            system: "cvs",
          },
          {
            name: "Heart failure",
            id: "heart_failure",
            system: "cvs",
          },
          {
            name: "Hypertension",
            id: "hypertension",
            system: "cvs",
          },
          {
            name: "Infective Endocarditis",
            id: "infective_endocarditis",
            system: "cvs",
          },
          {
            name: "Rheumatic Heart Disease",
            id: "rheumatic_heart_disease",
            system: "cvs",
          },
          {
            name: "Valvular Diseases",
            id: "valvular_diseases",
            system: "cvs",
          },
        ],
      },
      {
        name: "Pulmonology",
        id: "res",
        topics: [
          {
            name: "Asthma",
            id: "asthma",
            system: "res",
          },
          {
            name: "Bronchiectasis",
            id: "bronchiectasis",
            system: "res",
          },
          {
            name: "COPD",
            id: "copd",
            system: "res",
          },
          {
            name: "Cystic Fibrosis",
            id: "cystic_fibrosis",
            system: "res",
          },
          {
            name: "Interstitial Lung Diseases",
            id: "interstitial_lung_diseases",
            system: "res",
          },
          {
            name: "Lung Tumours",
            id: "lung_tumours",
            system: "res",
          },
          {
            name: "Pleural Effusion",
            id: "pleural_effusion",
            system: "res",
          },
          {
            name: "Pneumonia",
            id: "pneumonia",
            system: "res",
          },
          {
            name: "Pulmonary embolism",
            id: "pulmonary_embolism",
            system: "res",
          },
          {
            name: "Respiratory Failure",
            id: "respiratory_failure",
            system: "res",
          },
        ],
      },
      {
        name: "Endocrinology",
        id: "end",
        topics: [
          {
            name: "Adrenal insufficiency",
            id: "adrenal_insufficiency",
            system: "end",
          },
          {
            name: "Cushing’s syndrome & disease",
            id: "cushings_syndrome_and_disease",
            system: "end",
          },
          {
            name: "Diabetes Mellitus",
            id: "diabetes_mellitus",
            system: "end",
          },
          {
            name: "DKA & HHS",
            id: "dka_and_hhs",
            system: "end",
          },
          {
            name: "Hyperaldosteronism",
            id: "hyperaldosteronism",
            system: "end",
          },
          {
            name: "Hyperthyroidism",
            id: "hyperthyroidism",
            system: "end",
          },
          {
            name: "Hypothyroidism",
            id: "hypothyroidism",
            system: "end",
          },
          {
            name: "Pheochromocytoma",
            id: "pheochromocytoma",
            system: "end",
          },
          {
            name: "Pituitary disorders",
            id: "pituitary_disorders",
            system: "end",
          },
        ],
      },
      {
        name: "Nephrology",
        id: "gut",
        topics: [
          {
            name: "Acute Kidney Injury",
            id: "acute_kidney_injury",
            system: "gut",
          },
          {
            name: "Chronic Kidney Disease",
            id: "chronic_kidney_disease",
            system: "gut",
          },
          {
            name: "Nephritic / Nephrotic syndromes",
            id: "nephritic_nephrotic_syndromes",
            system: "gut",
          },
          {
            name: "Polycystic Kidney Disease",
            id: "polycystic_kidney_disease",
            system: "gut",
          },
        ],
      },
      {
        name: "Hematology",
        id: "hem",
        topics: [
          {
            name: "Anemias",
            id: "anemias",
            system: "hem",
          },
          {
            name: "Coagulopathies",
            id: "coagulopathies",
            system: "hem",
          },
          {
            name: "Leukemias",
            id: "leukemias",
            system: "hem",
          },
          {
            name: "Lymphomas",
            id: "lymphomas",
            system: "hem",
          },
          {
            name: "Multiple myeloma",
            id: "multiple_myeloma",
            system: "hem",
          },
          {
            name: "Myeloproliferative disorders",
            id: "myeloproliferative_disorders",
            system: "hem",
          },
        ],
      },
      {
        name: "Infectious Disease",
        id: "infectious_disease",
        topics: [
          {
            name: "HIV",
            id: "hiv",
            system: "mis",
          },
          {
            name: "Malaria",
            id: "malaria",
            system: "mis",
          },
          {
            name: "Meningitis",
            id: "meningitis",
            system: "mis",
          },
          {
            name: "Neglected Tropical Diseases",
            id: "neglected_tropical_diseases",
            system: "mis",
          },
          {
            name: "Sexually Transmitted Infections",
            id: "sexually_transmitted_infections",
            system: "mis",
          },
          {
            name: "Tuberculosis",
            id: "tuberculosis",
            system: "mis",
          },
          {
            name: "Viral Hemorrhagic Fevers",
            id: "viral_hemorrhagic_fevers",
            system: "mis",
          },
        ],
      },
      {
        name: "Rheumatology",
        id: "rheumatology",
        topics: [
          {
            name: "Connective Tissue Diseases",
            id: "connective_tissue_diseases",
            system: "rhe",
          },
          {
            name: "Gout",
            id: "gout",
            system: "rhe",
          },
          {
            name: "Rheumatoid Arthritis",
            id: "rheumatoid_arthritis",
            system: "rhe",
          },
          {
            name: "Septic Arthritis",
            id: "septic_arthritis",
            system: "rhe",
          },
          {
            name: "Vasculitides",
            id: "vasculitides",
            system: "rhe",
          },
        ],
      },
      {
        name: "Gastroenterology",
        id: "git",
        topics: [
          {
            name: "Autoimmune Hepatitis",
            id: "autoimmune_hepatitis",
            system: "git",
          },
          {
            name: "Hepatomegaly & Splenomegaly",
            id: "hepatomegaly_and_splenomegaly",
            system: "git",
          },
          {
            name: "Inflammatory Bowel Diseases",
            id: "inflammatory_bowel_diseases",
            system: "git",
          },
          {
            name: "Liver Cirrhosis",
            id: "liver_cirrhosis",
            system: "git",
          },
          {
            name: "Liver tumours",
            id: "liver_tumours",
            system: "git",
          },
          {
            name: "Metabolic liver diseases",
            id: "metabolic_liver_diseases",
            system: "git",
          },
          {
            name: "Non-Alcoholic Fatty Liver",
            id: "non-alcoholic_fatty_liver",
            system: "git",
          },
          {
            name: "Nutritional disorders",
            id: "nutritional_disorders",
            system: "git",
          },
          {
            name: "Pancreas disorders",
            id: "pancreas_disorders",
            system: "git",
          },
          {
            name: "Peptic Ulcer Disease",
            id: "peptic_ulcer_disease",
            system: "git",
          },
          {
            name: "Upper GI bleeding",
            id: "upper_gi_bleeding",
            system: "git",
          },
          {
            name: "Viral Hepatitis",
            id: "viral_hepatitis",
            system: "git",
          },
        ],
      },
    ],
  },
  {
    name: "Surgery",
    id: "surgery",
    level: "clinical",
    subdivisions: [
      {
        name: "General Surgery",
        id: "general_surgery",
        topics: [
          {
            name: "Acute Abdomen",
            id: "acute_abdomen",
            system: "git",
          },
          {
            name: "Appendicitis",
            id: "appendicitis",
            system: "git",
          },
          {
            name: "Blood Transfusions",
            id: "blood_transfusions",
            system: "hem",
          },
          {
            name: "Breast diseases (Surgery)",
            id: "breast_diseases_surgery",
            system: "msk",
          },
          {
            name: "Gastric Outlet Obstruction",
            id: "gastric_outlet_obstruction",
            system: "git",
          },
          {
            name: "Gastric Surgery",
            id: "gastric_surgery",
            system: "git",
          },
          {
            name: "Goitres (Surgery)",
            id: "goitres_Surgery",
            system: "end",
          },
          {
            name: "Hernias (Surgery)",
            id: "hernias_surgery",
            system: "git",
          },
          {
            name: "Inguinoscrotal Swellings",
            id: "inguinoscrotal_swellings",
            system: "rep",
          },
          {
            name: "Intestinal Obstruction",
            id: "intestinal_obstruction",
            system: "git",
          },
          {
            name: "Lymphatic diseases (Surgery)",
            id: "lymphatic_diseases_surgery",
            system: "cvs",
          },
          {
            name: "Post-Operative Complications",
            id: "post-operative_complications",
            system: "mis",
          },
          {
            name: "Stomas",
            id: "stomas",
            system: "mis",
          },
          {
            name: "Venous Insufficiency",
            id: "venous insufficiency",
            system: "cvs",
          },
        ],
      },
      {
        name: "Urology",
        id: "urology",
        topics: [
          {
            name: "Bladder diseases (Surgery)",
            id: "bladder_diseases_surgery",
            system: "gut",
          },
          {
            name: "Fournier’s Gangrene",
            id: "fourniers_gangrene",
            system: "gut",
          },
          {
            name: "Kidney diseases (Surgery)",
            id: "kidney_diseases_surgery",
            system: "gut",
          },
          {
            name: "Priapism",
            id: "priapism",
            system: "gut",
          },
          {
            name: "Prostate diseases (Surgery)",
            id: "prostate_diseases_surgery",
            system: "gut",
          },
          {
            name: "Testes diseases (Surgery)",
            id: "testes_diseases_surgery",
            system: "rep",
          },
          {
            name: "Urinary Tract Obstruction",
            id: "urinary_tract_obstruction",
            system: "gut",
          },
          {
            name: "Urological Trauma",
            id: "urological_trauma",
            system: "gut",
          },
        ],
      },
      {
        name: "Plastics",
        id: "plastics",
        topics: [
          {
            name: "Burns",
            id: "burns",
            system: "mis",
          },
          {
            name: "Flaps and Skin Grafts",
            id: "flaps_and_skin_grafts",
            system: "mis",
          },
          {
            name: "Ganglion Cyst",
            id: "ganglion_cyst",
            system: "mis",
          },
          {
            name: "Lipoma",
            id: "lipoma",
            system: "mis",
          },
          {
            name: "Lumps",
            id: "lumps",
            system: "mis",
          },
          {
            name: "Sebaceous Cyst",
            id: "sebaceous_cyst",
            system: "mis",
          },
          {
            name: "Ulcers",
            id: "ulcers",
            system: "mis",
          },
          {
            name: "Wound Healing and Management",
            id: "wound_healing_and_management",
            system: "mis",
          },
        ],
      },
      {
        name: "Trauma and Orthopedics",
        id: "trauma_and_orthopedics",
        topics: [
          {
            name: "Abdominal Injuries",
            id: "abdominal_injuries",
            system: "msk",
          },
          {
            name: "Ankle and Foot Injuries",
            id: "ankle_and_foot_injuries",
            system: "msk",
          },
          {
            name: "Arm and Shoulder Injuries",
            id: "arm_and_shoulder_injuries",
            system: "msk",
          },
          {
            name: "ATLS",
            id: "atls",
            system: "msk",
          },
          {
            name: "Bone Tumours",
            id: "bone_tumours",
            system: "msk",
          },
          {
            name: "Chest Injuries",
            id: "chest_njuries",
            system: "msk",
          },
          {
            name: "Elbow, Forearm and Wrist Injuries",
            id: "elbow_forearm_and_wrist_injuries",
            system: "msk",
          },
          {
            name: "Fractures: Overview & Complications",
            id: "Fractures_overview_and_omplications",
            system: "msk",
          },
          {
            name: "Glasgow Coma Scale",
            id: "glasgow_coma_scale",
            system: "msk",
          },
          {
            name: "Hand Injuries",
            id: "hand_injuries",
            system: "msk",
          },
          {
            name: "Head Injuries",
            id: "head_injuries",
            system: "msk",
          },
          {
            name: "Hip and Femur Fractures",
            id: "hip_and_femur_fractures",
            system: "msk",
          },
          {
            name: "Lower Leg Injuries",
            id: "lower_leg_injuries",
            system: "msk",
          },
          {
            name: "Neck Injuries",
            id: "neck_injuries",
            system: "msk",
          },
          {
            name: "Open Fractures",
            id: "open_fractures",
            system: "msk",
          },
          {
            name: "The Back and Spine",
            id: "the_back_and_spine",
            system: "msk",
          },
        ],
      },
      {
        name: "Pediatric Surgery",
        id: "pediatric_surgery",
        topics: [
          {
            name: "Anorectal Malformations",
            id: "anorectal_malformations",
            system: "git",
          },
          {
            name: "Biliary Atresia",
            id: "biliary_atresia",
            system: "git",
          },
          {
            name: "Congenital Heart Diseases",
            id: "congenital_heart_diseases",
            system: "cvs",
          },
          {
            name: "Congenital Hydroceles",
            id: "congenital_hydroceles",
            system: "rep",
          },
          {
            name: "Congenital Oesophageal Atresia",
            id: "congenital_oesophageal_atresia",
            system: "git",
          },
          {
            name: "Hirschsprung’s Disease",
            id: "hirschsprungs_disease",
            system: "git",
          },
          {
            name: "Intussusception in children",
            id: "intussusception_in_children",
            system: "git",
          },
          {
            name: "Neonatal Intestinal Obstruction",
            id: "neonatal_intestinal_obstruction",
            system: "git",
          },
          {
            name: "Testicular Mal-descent",
            id: "testicular_mal-descent",
            system: "rep",
          },
        ],
      },
      {
        name: "Anaesthesia",
        id: "Anaesthesia",
        topics: [
          {
            name: "Airway Management",
            id: "airway_management",
            system: "mis",
          },
          {
            name: "Conduct of Anaesthesia",
            id: "conduct_of_anaesthesia",
            system: "mis",
          },
          {
            name: "Diabetes and Anaesthesiaa",
            id: "diabetes_and_anaesthesiaa",
            system: "mis",
          },
          {
            name: "Hypertension and Anaesthesia",
            id: "hypertension_and_anaesthesia",
            system: "mis",
          },
          {
            name: "Inhalational Anaesthetic Agents",
            id: "inhalational_anaesthetic_agents",
            system: "mis",
          },
          {
            name: "IV Anaesthetic Agents",
            id: "iv_anaesthetic_agents",
            system: "mis",
          },
          {
            name: "Local Anaesthesia",
            id: "local_anaesthesia",
            system: "mis",
          },
          {
            name: "Monitoring",
            id: "monitoring",
            system: "mis",
          },
          {
            name: "Muscle Relaxants",
            id: "muscle_relaxants",
            system: "mis",
          },
          {
            name: "Obstetric Anaesthesia",
            id: "obstetric_anaesthesia",
            system: "mis",
          },
          {
            name: "Pain Management",
            id: "pain_management",
            system: "mis",
          },
          {
            name: "Pediatric Anaesthesia",
            id: "pediatric_anaesthesia",
            system: "mis",
          },
          {
            name: "Pre-Operative Assessment",
            id: "pre-operative_assessment",
            system: "mis",
          },
          {
            name: "Regional Anaesthesia",
            id: "regional_anaesthesia",
            system: "mis",
          },
          {
            name: "Sickle Cell and Anaesthesia",
            id: "sickle_cell_and_anaesthesia",
            system: "mis",
          },
        ],
      },
    ],
  },
  {
    name: "Pediatrics",
    id: "pediatrics",
    level: "clinical",
    subdivisions: [
      {
        name: "All Pediatrics Systems",
        id: "pediatrics-all-topics",
        topics: [
          {
            name: "Asthma",
            id: "asthma-pedics",
            system: "res",
          },
          {
            name: "Birth Defects",
            id: "Birth_defects",
            system: "mis",
          },
          {
            name: "Birth Injuries",
            id: "birth_injuries",
            system: "mis",
          },
          {
            name: "Bronchiolitis",
            id: "bronchiolitis-pedics",
            system: "res",
          },
          {
            name: "Burkitt Lymphoma",
            id: "burkitt_lymphoma",
            system: "mis",
          },
          {
            name: "Cerebral Palsy",
            id: "cerebral_palsy",
            system: "cns",
          },
          {
            name: "Congenital Heart Diseases",
            id: "Congenital_heart_diseases-pedics",
            system: "cvs",
          },
          {
            name: "Diarrhea in children",
            id: "diarrhea_in_children",
            system: "git",
          },
          {
            name: "Hemophilia",
            id: "hemophilia",
            system: "hem",
          },
          {
            name: "HIV in children",
            id: "hiv_in_children",
            system: "mis",
          },
          {
            name: "Leukemias",
            id: "leukemias-pedics",
            system: "hem",
          },
          {
            name: "Malaria",
            id: "malaria-pedics",
            system: "mis",
          },
          {
            name: "Neonatal Jaundice",
            id: "neonatal_jaundice",
            system: "mis",
          },
          {
            name: "Nephrotic Syndrome",
            id: "nephrotic_syndrome-pedics",
            system: "gut",
          },
          {
            name: "Prematurity & Low birth weight",
            id: "prematurity_)and_Low_birth_weight",
            system: "mis",
          },
          {
            name: "Seizure Disorders",
            id: "seizure_disorders-pedics",
            system: "cns",
          },
          {
            name: "Severe Acute Malnutrition",
            id: "severe_acute_malnutrition-pedics",
            system: "mis",
          },
          {
            name: "Sickle Cell Disease",
            id: "sickle_cell_disease-pedics",
            system: "mis",
          },
          {
            name: "TB in children",
            id: "tb_in_children",
            system: "res",
          },
          {
            name: "Upper airway diseases",
            id: "upper_airway_diseases-pedics",
            system: "res",
          },
        ],
      },
    ],
  },
  {
    name: "Obstetrics & Gynecology",
    id: "obstetrics_and_gynecology",
    level: "clinical",
    subdivisions: [
      {
        name: "Obstetrics",
        id: "obstetrics",
        topics: [
          {
            name: "Abnormal lies",
            id: "abnormal_lies",
            system: "mis",
          },
          {
            name: "Antenatal Care",
            id: "antenatal_care",
            system: "mis",
          },
          {
            name: "Diabetic States in Pregnancy",
            id: "diabetic_states_in_pregnancy",
            system: "mis",
          },
          {
            name: "Diagnosing Pregnancy",
            id: "diagnosing_pregnancy",
            system: "mis",
          },
          {
            name: "Ectopic Pregnancy",
            id: "ectopic_pregnancy",
            system: "mis",
          },
          {
            name: "Fertilization, Embryo and Fetal development",
            id: "fertilization_embryo_and_fetal_development",
            system: "mis",
          },
          {
            name: "Fetal State Assessments",
            id: "Fetal_state_assessments",
            system: "mis",
          },
          {
            name: "Feto-Maternal Relationships",
            id: "feto-maternal_relationships",
            system: "mis",
          },
          {
            name: "Gestational Trophoblastic Diseases",
            id: "gestational_trophoblastic_diseases",
            system: "mis",
          },
          {
            name: "Hypertensive States in Pregnancy",
            id: "hypertensive_states_in_pregnancy",
            system: "mis",
          },
          {
            name: "Inducing and Augmenting labour",
            id: "inducing_and_augmenting_labour",
            system: "mis",
          },
          {
            name: "IUGR, Fetal Deaths and Still Births",
            id: "iugr_fetal_deaths_and_still_births",
            system: "mis",
          },
          {
            name: "Late Pregnancy Bleeding: Overview",
            id: "late_pregnancy_bleeding_overview",
            system: "mis",
          },
          {
            name: "Malpresentations",
            id: "malpresentations",
            system: "mis",
          },
          {
            name: "Maternal Deaths ",
            id: "maternal_deaths ",
            system: "mis",
          },
          {
            name: "Multiple Pregnancy",
            id: "multiple_pregnancy",
            system: "mis",
          },
          {
            name: "Normal Labour & Delivery",
            id: "normal_labour_and_delivery",
            system: "mis",
          },
          {
            name: "Normal Puerperium",
            id: "normal_puerperium",
            system: "mis",
          },
          {
            name: "Partograph",
            id: "partograph",
            system: "mis",
          },
          {
            name: "Placenta Abruptio",
            id: "placenta_abruptio",
            system: "mis",
          },
          {
            name: "Placenta Previa",
            id: "placenta_previa",
            system: "mis",
          },
          {
            name: "Post-natal Care",
            id: "post-natal_care",
            system: "mis",
          },
          {
            name: "Postpartum Depression, Blues and Psychosis",
            id: "postpartum_depression_blues_and_psychosis",
            system: "mis",
          },
          {
            name: "Postpartum Hemorrhage",
            id: "postpartum_hemorrhage",
            system: "premature_rupture_of_embranes",
          },
          {
            name: "Preterm Labour",
            id: "preterm_labour",
            system: "mis",
          },
          {
            name: "Prolonged & Obstructed Labour",
            id: "prolonged_and_obstructed_labour",
            system: "mis",
          },
          {
            name: "Prolonged Pregnancy",
            id: "prolonged_pregnancy",
            system: "mis",
          },
          {
            name: "Puerperal Pyrexia & Sepsis",
            id: "puerperal_pyrexia_and_sepsis",
            system: "mis",
          },
          {
            name: "Reproductive Organs and Hormones",
            id: "reproductive_organs_and_hormones",
            system: "mis",
          },
          {
            name: "The Female Bony Pelvis",
            id: "the_female_bony_pelvis",
            system: "mis",
          },
          {
            name: "The Menstrual Cycle",
            id: "the_menstrual_cycle",
            system: "mis",
          },
          {
            name: "Uterine Rupture",
            id: "uterine_rupture",
            system: "mis",
          },
        ],
      },
      {
        name: "Gynecology",
        id: "gynecology",
        topics: [
          {
            name: "Abnormal Menstrual Bleeding",
            id: "abnormal_menstrual_bleeding",
            system: "rep",
          },
          {
            name: "Abnormal Uterine Bleeding: Overview",
            id: "abnormal_uterine_bleeding_overview",
            system: "rep",
          },
          {
            name: "Adolescent Sexual and Reproductive Health",
            id: "adolescent_sexual_and_reproductive_health",
            system: "rep",
          },
          {
            name: "Amenorrhea",
            id: "amenorrhea",
            system: "rep",
          },
          {
            name: "Assisted Reproductive Technologies",
            id: "assisted_reproductive_technologies",
            system: "rep",
          },
          {
            name: "Bacterial Vaginosis",
            id: "bacterial_vaginosis",
            system: "rep",
          },
          {
            name: "Bartholin Cyst",
            id: "bartholin_cyst",
            system: "rep",
          },
          {
            name: "Benign Cervical Neoplasia",
            id: "benign_cervical_neoplasia",
            system: "rep",
          },
          {
            name: "Cervical Cancer",
            id: "cervical_cancer",
            system: "rep",
          },
          {
            name: "Endometrial Cancer",
            id: "endometrial_cancer",
            system: "rep",
          },
          {
            name: "Endometriosis & Adenomyosis",
            id: "endometriosis_and_adenomyosis",
            system: "rep",
          },
          {
            name: "Family Planning",
            id: "family_planning",
            system: "rep",
          },
          {
            name: "Hormonal Contraceptives",
            id: "hormonal_contraceptives",
            system: "rep",
          },
          {
            name: "Infertility",
            id: "infertility",
            system: "rep",
          },
          {
            name: "Menopause",
            id: "menopause",
            system: "rep",
          },
          {
            name: "Ovarian Cancer",
            id: "ovarian_cancer",
            system: "rep",
          },
          {
            name: "Pediatric Gynecological Problems",
            id: "pediatric_gynecological_problems",
            system: "rep",
          },
          {
            name: "Pelvic Inflammatory disease",
            id: "pelvic_inflammatory_disease",
            system: "rep",
          },
          {
            name: "Pelvic Organ Prolapse: Overview",
            id: "pelvic_organ_prolapse_overview",
            system: "rep",
          },
          {
            name: "Sexual Assault",
            id: "sexual_assault",
            system: "rep",
          },
          {
            name: "Sexuality, Sexual Response and Dysfunction",
            id: "sexuality_sexual_response_and_dysfunction",
            system: "rep",
          },
          {
            name: "Urinary Incontinence",
            id: "urinary_incontinence",
            system: "rep",
          },
          {
            name: "Uterine Fibroids",
            id: "uterine_fibroids",
            system: "rep",
          },
          {
            name: "Uterovaginal Prolapse",
            id: "uterovaginal_prolapse",
            system: "rep",
          },
          {
            name: "Vaginitis Overview",
            id: "vaginitis_overview",
            system: "rep",
          },
          {
            name: "Violence Against Women",
            id: "violence_against_women",
            system: "rep",
          },
        ],
      },
    ],
  },
  {
    name: "Dermatology",
    id: "dermatology",
    level: "clinical",
    exempt: [],
    subdivisions: [
      {
        name: "All Dermatology Systems",
        id: "dermatology-all-topics",
        topics: [
          {
            name: "Acne",
            id: "acne",
            system: "mis",
          },
          {
            name: "Cutaneous manifestations of connective tissue diseases",
            id: "cutaneous_manifestations_of_connective_tissue_diseases",
            system: "mis",
          },
          {
            name: "Cutaneous manifestations of HIV",
            id: "cutaneous_manifestations_of_hiv",
            system: "mis",
          },
          {
            name: "Cutaneous manifestations of systemic diseases",
            id: "cutaneous_manifestations_of_systemic_diseases",
            system: "mis",
          },
          {
            name: "Dermatitis (Eczemas)",
            id: "dermatitis_eczemas",
            system: "mis",
          },
          {
            name: "Erythema multiforme, SJS and TEN",
            id: "erythema_multiforme_sjs_and_ten",
            system: "mis",
          },
          {
            name: "Erythroderma",
            id: "erythroderma",
            system: "mis",
          },
          {
            name: "Fungal diseases of the skin",
            id: "fungal_diseases_of_the_skin",
            system: "mis",
          },
          {
            name: "Neurofibromatosis",
            id: "neurofibromatosis",
            system: "mis",
          },
          {
            name: "Pemphigus and Pemphigoid",
            id: "pemphigus_and_pemphigoid",
            system: "mis",
          },
          {
            name: "Pityriasis Rosea, Alba & Versicolor",
            id: "pityriasis_rosea_alba_and_versicolor",
            system: "mis",
          },
          {
            name: "Psoriasis & lichen planus",
            id: "psoriasis_and_lichen_planus",
            system: "mis",
          },
          {
            name: "Scabies",
            id: "scabies",
            system: "mis",
          },
          {
            name: "Tuberous Sclerosis",
            id: "tuberous_sclerosis",
            system: "mis",
          },
          {
            name: "Urticaria and angioedema",
            id: "urticaria_and_angioedema",
            system: "mis",
          },
        ],
      },
    ],
  },
  {
    name: "Psychiatry",
    id: "psychiatry",
    level: "clinical",
    exempt: [],
    subdivisions: [
      {
        name: "All Psychiatry Subdivisions",
        id: "psychiatry-all-topics",
        topics: [
          {
            name: "Anxiety Disorders",
            id: "anxiety_disorders",
            system: "cns",
          },
          {
            name: "Mood disorders",
            id: "mood_disorders",
            system: "cns",
          },
          {
            name: "Personality Disorders",
            id: "personality_disorders",
            system: "cns",
          },
          {
            name: "Schizophrenia and other Psychoses",
            id: "schizophrenia_and_other_psychoses",
            system: "cns",
          },
          {
            name: "Substance Use Disorders",
            id: "Substance_use_disorders",
            system: "cns",
          },
        ],
      },
    ],
  },
  {
    name: "ENT",
    id: "ent",
    level: "clinical",
    subdivisions: [
      {
        name: "All ENT Systems",
        id: "ent-all-topics",
        topics: [
          {
            name: "Ear Disorders",
            id: "ear_disorders",
            system: "mis",
          },
          {
            name: "Facial Palsy",
            id: "facial_palsy",
            system: "mis",
          },
          {
            name: "Nose & Paranasal Sinus Disorders",
            id: "nose_and_paranasal_sinus_disorders",
            system: "mis",
          },
          {
            name: "Salivary Gland Disorders",
            id: "salivary_gland_disorders",
            system: "mis",
          },
          {
            name: "Throat Disorders",
            id: "throat_disorders",
            system: "mis",
          },
        ],
      },
    ],
  },
  {
    name: "Ophthalmology",
    id: "ophthalmology",
    level: "clinical",
    subdivisions: [
      {
        name: "All Ophthalmology Subdivisions",
        id: "ophthalmology-all-topics",
        topics: [
          {
            name: "Cataract",
            id: "cataract",
            system: "mis",
          },
          {
            name: "Eye Assessments",
            id: "eye_assessments",
            system: "mis",
          },
          {
            name: "Eye Emergencies",
            id: "eye_emergencies",
            system: "mis",
          },
          {
            name: "Glaucoma",
            id: "glaucoma",
            system: "mis",
          },
          {
            name: "Lacrimal system, Orbit and Eyelids",
            id: "lacrimal_system_orbit_and_eyelids",
            system: "mis",
          },
          {
            name: "Refractive Errors",
            id: "refractive_errors",
            system: "mis",
          },
          {
            name: "Strabismus",
            id: "strabismus",
            system: "mis",
          },
          {
            name: "Systemic Diseases Affecting The Eye",
            id: "systemic_diseases_affecting_the_eye",
            system: "mis",
          },
        ],
      },
    ],
  },

  //PRECLINICAL SUBJECTS

  {
    name: "Gross Anatomy",
    id: "anatomy",
    level: "preclinical",
    subdivisions: [
      {
        name: "Musculoskeletal & Skin",
        id: "msk",
        topics: [
          {
            name: "Musculoskeletal Overview",
            id: "musculoskeletal_overview",
            system: "msk",
          },
          {
            name: "Abdomen wall",
            id: "abdomen_wall",
            system: "msk",
          },
          {
            name: "Breasts",
            id: "breasts",
            system: "msk",
          },
          {
            name: "Chest wall",
            id: "chest_wall",
            system: "msk",
          },
          {
            name: "Head and Neck",
            id: "head_and_neck",
            system: "msk",
          },
          {
            name: "Lower Limbs",
            id: "lower_limbs",
            system: "msk",
          },
          {
            name: "The Back",
            id: "the_back",
            system: "msk",
          },
          {
            name: "Upper Limbs",
            id: "upper_limbs",
            system: "msk",
          },
        ],
      },
      {
        name: "Nervous System",
        id: "cns",
        topics: [
          {
            name: "Autonomic System",
            id: "autonomic_system",
            system: "cns",
          },
          {
            name: "Brachial Plexus",
            id: "brachial_plexus",
            system: "cns",
          },
          {
            name: "Brain",
            id: "brain",
            system: "cns",
          },
          {
            name: "Cranial Nerves",
            id: "cranial_nerves",
            system: "cns",
          },
          {
            name: "Lumbar Plexus",
            id: "lumbar_plexus",
            system: "cns",
          },
          {
            name: "Sacral Plexus",
            id: "sacral_plexus",
            system: "cns",
          },
          {
            name: "Spinal Cord",
            id: "spinal_cord",
            system: "cns",
          },
        ],
      },
      {
        name: "Reproductive System",
        id: "rep",
        topics: [
          {
            name: "Female Genitals",
            id: "female_genitals",
            system: "rep",
          },
          {
            name: "Male Genitals",
            id: "male_genitals",
            system: "rep",
          },
        ],
      },
      {
        name: "Urogenital System",
        id: "gut",
        topics: [
          {
            name: "Bladder",
            id: "bladder",
            system: "gut",
          },
          {
            name: "Kidneys",
            id: "kidneys",
            system: "gut",
          },
          {
            name: "Ureter",
            id: "ureter",
            system: "gut",
          },
          {
            name: "Urethra",
            id: "urethra",
            system: "gut",
          },
        ],
      },
      {
        name: "GI System",
        id: "git",
        topics: [
          {
            name: "Anterior abdomen wall",
            id: "anterior_abdomen_wall",
            system: "git",
          },
          {
            name: "Gall Bladder",
            id: "gall_bladder",
            system: "git",
          },
          {
            name: "Large Bowel",
            id: "large_bowel",
            system: "git",
          },
          {
            name: "Liver",
            id: "liver",
            system: "git",
          },
          {
            name: "Oesophagus",
            id: "oesophagus",
            system: "git",
          },
          {
            name: "Pancreas",
            id: "pancreas",
            system: "git",
          },
          {
            name: "Rectum and Anus",
            id: "rectum_and_anus",
            system: "git",
          },
          {
            name: "Small Bowel",
            id: "small_bowel",
            system: "git",
          },
          {
            name: "Stomach",
            id: "stomach",
            system: "git",
          },
        ],
      },
      {
        name: "Cardiovascular System",
        id: "cvs",
        topics: [
          {
            name: "Circulation Overview",
            id: "circulation_overview",
            system: "cvs",
          },
          {
            name: "Arteries",
            id: "arteries",
            system: "cvs",
          },
          {
            name: "Heart",
            id: "heart",
            system: "cvs",
          },
          {
            name: "Lymphatics",
            id: "lymphatics",
            system: "cvs",
          },
          {
            name: "Veins",
            id: "veins",
            system: "cvs",
          },
        ],
      },
      {
        name: "Endocrine System",
        id: "end",
        topics: [
          {
            name: "Adrenal Glands",
            id: "adrenal_glands",
            system: "end",
          },
          {
            name: "Endocrine Pancreas",
            id: "endocrine_pancreas",
            system: "end",
          },
          {
            name: "Gonads",
            id: "gonads",
            system: "end",
          },
          {
            name: "Parathyroid Glands",
            id: "parathyroid_glands",
            system: "end",
          },
          {
            name: "Pineal Gland",
            id: "pineal_gland",
            system: "end",
          },
          {
            name: "Pituitary Gland",
            id: "pituitary_gland",
            system: "end",
          },
          {
            name: "Thyroid Gland",
            id: "thyroid_gland",
            system: "end",
          },
        ],
      },
      {
        name: "Respiratory System",
        id: "res",
        topics: [
          {
            name: "Lungs",
            id: "lungs",
            system: "res",
          },
          {
            name: "Trachea, bronchi and bronchioles",
            id: "trachea_bronchi_and_bronchioles",
            system: "res",
          },
        ],
      },
      {
        name: "Hematology and Immunology",
        id: "hem",
        topics: [
          {
            name: "Lymph nodes",
            id: "lymph_nodes",
            system: "hem",
          },
          {
            name: "Spleen",
            id: "spleen",
            system: "hem",
          },
          {
            name: "Thymus",
            id: "thymus",
            system: "hem",
          },
        ],
      },
    ],
  },
  {
    name: "Histology",
    id: "histology",
    level: "preclinical",
    subdivisions: [
      {
        name: "Miscellaneous",
        id: "mis",
        topics: [
          {
            name: "Cells",
            id: "cells",
            system: "mis",
          },
          {
            name: "Connective Tissue Overview",
            id: "connective_tissue_overview",
            system: "mis",
          },
        ],
      },
      {
        name: "Musculoskeletal & Skin",
        id: "msk",
        topics: [
          {
            name: "Bone and Cartilage",
            id: "bone_and_cartilage",
            system: "msk",
          },
          {
            name: "Muscles",
            id: "muscles",
            system: "msk",
          },
          {
            name: "Skin and Accessories",
            id: "skin_and_accessories",
            system: "msk",
          },
        ],
      },
      {
        name: "Nervous System",
        id: "cns",
        topics: [
          {
            name: "Eye",
            id: "eye",
            system: "cns",
          },
          {
            name: "Specialized Diffuse Receptors",
            id: "specialized_diffuse_receptors",
            system: "cns",
          },
          {
            name: "Vestibulocochlear Apparatus",
            id: "vestibulocochlear_apparatus",
            system: "cns",
          },
        ],
      },
      {
        name: "Urological System",
        id: "gut",
        topics: [
          {
            name: "Bladder",
            id: "bladder",
            system: "gut",
          },
          {
            name: "Kidneys",
            id: "kidneys",
            system: "gut",
          },
          {
            name: "Ureter",
            id: "ureter",
            system: "gut",
          },
          {
            name: "Urethra",
            id: "urethra",
            system: "gut",
          },
        ],
      },
      {
        name: "Reproductive System",
        id: "rep",
        topics: [
          {
            name: "Female genitals",
            id: "female_genitals",
            system: "rep",
          },
          {
            name: "Male genitals",
            id: "male_genitals",
            system: "rep",
          },
          {
            name: "Mammary glands",
            id: "mammary_glands",
            system: "rep",
          },
        ],
      },
      {
        name: "GI System",
        id: "git",
        topics: [
          {
            name: "Gall Bladder",
            id: "gall_bladder",
            system: "git",
          },
          {
            name: "Large Bowel",
            id: "large_bowel",
            system: "git",
          },
          {
            name: "Liver",
            id: "liver",
            system: "git",
          },
          {
            name: "Mouth",
            id: "mouth",
            system: "git",
          },
          {
            name: "Oesophagus",
            id: "oesophagus",
            system: "git",
          },
          {
            name: "Pancreas",
            id: "pancreas",
            system: "git",
          },
          {
            name: "Rectum and Anus",
            id: "rectum_and_anus",
            system: "git",
          },
          {
            name: "Small Bowel",
            id: "small_bowel",
            system: "git",
          },
          {
            name: "Stomach",
            id: "stomach",
            system: "git",
          },
        ],
      },
      {
        name: "Cardiovascular System",
        id: "cvs",
        topics: [
          {
            name: "Arteries",
            id: "arteries",
            system: "cvs",
          },
          {
            name: "Heart",
            id: "heart",
            system: "cvs",
          },
          {
            name: "Lymphatics",
            id: "lymphatics",
            system: "cvs",
          },
          {
            name: "Veins",
            id: "veins",
            system: "cvs",
          },
        ],
      },
      {
        name: "Hematology and Immunology",
        id: "hem",
        topics: [
          {
            name: "Blood",
            id: "blood",
            system: "hem",
          },
          {
            name: "Lymph nodes",
            id: "lymph_nodes",
            system: "hem",
          },
          {
            name: "Other Lymphoid Tissues",
            id: "other_lymphoid_tissues",
            system: "hem",
          },
          {
            name: "Spleen",
            id: "spleen",
            system: "hem",
          },
          {
            name: "Thymus",
            id: "thymus",
            system: "hem",
          },
        ],
      },
      {
        name: "Endocrine System",
        id: "end",
        topics: [
          {
            name: "Adrenal Medulla and Cortex",
            id: "adrenal_medulla_and_cortex",
            system: "end",
          },
          {
            name: "Parathyroid Glands",
            id: "parathyroid_glands",
            system: "end",
          },
          {
            name: "Pineal Gland",
            id: "pineal_gland",
            system: "end",
          },
          {
            name: "Pituitary Gland",
            id: "pituitary_gland",
            system: "end",
          },
          {
            name: "Thyroid Gland",
            id: "thyroid_gland",
            system: "end",
          },
        ],
      },
      {
        name: "Respiratory System",
        id: "res",
        topics: [
          {
            name: "Alveoli",
            id: "alveoli",
            system: "res",
          },
          {
            name: "Bronchi",
            id: "bronchi",
            system: "res",
          },
          {
            name: "Bronchioles",
            id: "bronchioles",
            system: "res",
          },
          {
            name: "Larynx",
            id: "larynx",
            system: "res",
          },
          {
            name: "Nasal Cavity and Nasopharynx",
            id: "nasal_cavity_and_nasopharynx",
            system: "res",
          },
          {
            name: "Trachea",
            id: "trachea",
            system: "res",
          },
        ],
      },
    ],
  },
  {
    name: "Embryology",
    id: "embryology",
    level: "preclinical",
    subdivisions: [
      {
        name: "Miscellaneous",
        id: "mis",
        topics: [
          {
            name: "Gametogenesis",
            id: "gametogenesis",
            system: "mis",
          },
          {
            name: "Pharyngeal Arches, Pouches and Clefts",
            id: "pharyngeal_arches_pouches_and_clefts",
            system: "mis",
          },
          {
            name: "The 1st Week of Development",
            id: "the_1st_week_of_development",
            system: "mis",
          },
          {
            name: "The 2nd Week of Development",
            id: "the_2nd_week_of_development",
            system: "mis",
          },
          {
            name: "The 3rd to 8th Week of Development",
            id: "the_3rd_to_8th_week_of_development",
            system: "mis",
          },
          {
            name: "The 3rd Week of Development",
            id: "the_3rd_week_of_development",
            system: "mis",
          },
        ],
      },
      {
        name: "Musculoskeletal & Skin",
        id: "msk",
        topics: [
          {
            name: "Limb Development",
            id: "limb_development",
            system: "msk",
          },
          {
            name: "Skin, Hair and Sweat Gland Development",
            id: "skin_hair_and_sweat_gland_development",
            system: "msk",
          },
          {
            name: "Skull Development",
            id: "skull_development",
            system: "msk",
          },
          {
            name: "Vertebra Development",
            id: "vertebra_development",
            system: "msk",
          },
        ],
      },
      {
        name: "Nervous System",
        id: "cns",
        topics: [
          {
            name: "Brain and Spinal Cord Development",
            id: "brain_and_spinal_cord_development",
            system: "cns",
          },
          {
            name: "Ear Development",
            id: "ear_development",
            system: "cns",
          },
          {
            name: "Eye Development",
            id: "eye_development",
            system: "cns",
          },
        ],
      },
      {
        name: "Cardiovascular System",
        id: "cvs",
        topics: [
          {
            name: "Heart Development",
            id: "heart_development",
            system: "cvs",
          },
          {
            name: "Vascular Development",
            id: "vascular_development",
            system: "cvs",
          },
        ],
      },
      {
        name: "Urological System",
        id: "gut",
        topics: [
          {
            name: "Bladder and Ureter Development",
            id: "bladder_and_ureter_development",
            system: "gut",
          },
          {
            name: "Kidney Development",
            id: "kidney_development",
            system: "gut",
          },
        ],
      },
      {
        name: "Reproductive System",
        id: "rep",
        topics: [
          {
            name: "Genitals Development",
            id: "genitals_development",
            system: "rep",
          },
          {
            name: "Mammary Glands Development",
            id: "mammary_glands_development",
            system: "rep",
          },
        ],
      },
      {
        name: "GI System",
        id: "git",
        topics: [
          {
            name: "Foregut Development",
            id: "foregut_development",
            system: "git",
          },
          {
            name: "Hindgut Development",
            id: "hindgut_development",
            system: "git",
          },
          {
            name: "Midgut Development",
            id: "midgut_development",
            system: "git",
          },
        ],
      },
      {
        name: "Respiratory System",
        id: "res",
        topics: [
          {
            name: "Lung Development",
            id: "lung_development",
            system: "res",
          },
        ],
      },
    ],
  },
  {
    name: "Physiology",
    id: "physiology",
    level: "preclinical",
    subdivisions: [
      {
        name: "Miscellaneous",
        id: "mis",
        topics: [
          {
            name: "Body Fluids",
            id: "body_fluids",
            system: "mis",
          },
          {
            name: "Cellular Physiology",
            id: "cellular_physiology",
            system: "mis",
          },
          {
            name: "Excitable Tissues Overview",
            id: "excitable_tissues_overview",
            system: "mis",
          },
          {
            name: "Excitable Tissues: Cardiac Muscles",
            id: "excitable_tissues_cardiac_muscles",
            system: "mis",
          },
          {
            name: "Excitable Tissues: Nerves",
            id: "excitable_tissues_nerves",
            system: "mis",
          },
          {
            name: "Excitable Tissues: Skeletal Muscles",
            id: "excitable_tissues_skeletal_uscles",
            system: "mis",
          },
          {
            name: "Excitable Tissues: Smooth Muscles",
            id: "excitable_tissues_smooth_muscles",
            system: "mis",
          },
        ],
      },
      {
        name: "Nervous System",
        id: "cns",
        topics: [
          {
            name: "Auditory & Vestibular Systems",
            id: "auditory_and_vestibular_systems",
            system: "cns",
          },
          {
            name: "Motor Control: Basal Ganglia",
            id: "motor_control_basal_ganglia",
            system: "cns",
          },
          {
            name: "Motor Control: Brain Stem",
            id: "motor_control_brain_stem",
            system: "cns",
          },
          {
            name: "Motor Control: Cerebellum",
            id: "motor_control_cerebellum",
            system: "cns",
          },
          {
            name: "Motor Control: Cortex",
            id: "motor_control_cortex",
            system: "cns",
          },
          {
            name: "Somatosensory Pathways",
            id: "somatosensory_pathways",
            system: "cns",
          },
          {
            name: "Taste and Olfaction",
            id: "taste_and_olfaction",
            system: "cns",
          },
          {
            name: "Visual System",
            id: "visual_system",
            system: "cns",
          },
        ],
      },
      {
        name: "Cardiovascular System",
        id: "cvs",
        topics: [
          {
            name: "Circulation for Special Regions",
            id: "circulation_for_special_regions",
            system: "cvs",
          },
          {
            name: "Circulation Overview",
            id: "circulation_overview",
            system: "cvs",
          },
          {
            name: "Electrical Activity of the Heart",
            id: "electrical_activity_of_the_heart",
            system: "cvs",
          },
          {
            name: "The Heart as a Pump",
            id: "the_heart_as_a_pump",
            system: "cvs",
          },
        ],
      },
      {
        name: "Urological System",
        id: "gut",
        topics: [
          {
            name: "Renal Function",
            id: "renal_function",
            system: "",
          },
        ],
      },
      {
        name: "Reproductive System",
        id: "rep",
        topics: [
          {
            name: "Female Reproductive System",
            id: "female_rep",
            system: "rep",
          },
          {
            name: "Human Sexual Response",
            id: "human_sexual_response",
            system: "rep",
          },
          {
            name: "Male Reproductive System",
            id: "male_rep",
            system: "rep",
          },
        ],
      },
      {
        name: "GI System",
        id: "git",
        topics: [
          {
            name: "Digestion & Absorption",
            id: "digestion_and_absorption",
            system: "git",
          },
          {
            name: "Liver Function",
            id: "liver_function",
            system: "git",
          },
          {
            name: "Motility of the GI Tract",
            id: "motility_of_the_gi_tract",
            system: "git",
          },
        ],
      },
      {
        name: "Respiratory System",
        id: "res",
        topics: [
          {
            name: "Blood, Lymph",
            id: "blood_lymph",
            system: "res",
          },
          {
            name: "Lung Function",
            id: "lung_function",
            system: "res",
          },
          {
            name: "Regulation of Respiration",
            id: "regulation_of_respiration",
            system: "res",
          },
        ],
      },
      {
        name: "Endocrine System",
        id: "end",
        topics: [
          {
            name: "Adrenal Medulla and Cortex Function",
            id: "adrenal_medulla_and_cortex_function",
            system: "end",
          },
          {
            name: "Calcium Metabolism",
            id: "calcium_metabolism",
            system: "end",
          },
          {
            name: "Endocrine Functions of Pancreas",
            id: "endocrine_functions_of_pancreas",
            system: "end",
          },
          {
            name: "Pituitary Function",
            id: "pituitary_function",
            system: "end",
          },
          {
            name: "Thyroid Function",
            id: "thyroid_function",
            system: "end",
          },
        ],
      },
    ],
  },
  {
    name: "Biochemistry",
    id: "biochemistry",
    level: "preclinical",
    subdivisions: [
      {
        name: "Miscellaneous",
        id: "mis",
        topics: [
          {
            name: "Metabolism overview",
            id: "metabolism_overview",
            system: "mis",
          },
          {
            name: "Electron Transport Chain",
            id: "electron_transport_chain",
            system: "mis",
          },
          {
            name: "TCA cycle",
            id: "tca_cycle",
            system: "mis",
          },
        ],
      },
      {
        name: "Amino acids & Proteins",
        id: "amino_acids_and_proteins",
        topics: [
          {
            name: "Proteins: Overview",
            id: "proteins_overview",
            system: "mis",
          },
          {
            name: "Amino acids: Overview",
            id: "amino_acids_overview",
            system: "mis",
          },
          {
            name: "Amino acid metabolism",
            id: "amino_acid_metabolism",
            system: "mis",
          },
          {
            name: "Collagen",
            id: "collagen",
            system: "mis",
          },
          {
            name: "Elastin",
            id: "elastin",
            system: "mis",
          },
          {
            name: "Enzymes",
            id: "enzymes",
            system: "mis",
          },
          {
            name: "Globular Hemeproteins (Oxygen Carriers)",
            id: "globular_hemeproteins_oxygen_carriers",
            system: "mis",
          },
          {
            name: "Heme Metabolism",
            id: "heme_metabolism",
            system: "mis",
          },
          {
            name: "Hemoglobinopathies: Sickle Cell",
            id: "hemoglobinopathies_sickle_cell",
            system: "mis",
          },
          {
            name: "Hemoglobinopathies: Thalassemias",
            id: "hemoglobinopathies_thalassemias",
            system: "mis",
          },
        ],
      },
      {
        name: "Carbohydrates",
        id: "carbohydrates",
        topics: [
          {
            name: "Carbohydrates: overview",
            id: "carbohydrates_overview",
            system: "mis",
          },
          {
            name: "Gluconeogenesis",
            id: "gluconeogenesis",
            system: "mis",
          },
          {
            name: "Glycogen metabolism",
            id: "glycogen_metabolism",
            system: "mis",
          },
          {
            name: "Glycolysis",
            id: "glycolysis",
            system: "mis",
          },
          {
            name: "Pentose Phosphate Pathway",
            id: "pentose_phosphate_pathway",
            system: "mis",
          },
        ],
      },
      {
        name: "Lipids",
        id: "lipids",
        topics: [
          {
            name: "Lipids overview",
            id: "lipids_overview",
            system: "mis",
          },
          {
            name: "Beta Oxidation",
            id: "beta_oxidation",
            system: "mis",
          },
          {
            name: "Cholesterol, Bile and Plasma Lipoproteins",
            id: "cholesterol_bile_and_plasma_lipoproteins",
            system: "mis",
          },
          {
            name: "Fatty Acid Synthesis",
            id: "fatty_acid_synthesis",
            system: "mis",
          },
          {
            name: "Ketone Bodies",
            id: "ketone_bodies",
            system: "mis",
          },
        ],
      },
      {
        name: "Nucleic acids",
        id: "nucleic_acids",
        topics: [
          {
            name: "DNA overview",
            id: "dna_overview",
            system: "mis",
          },
          {
            name: "Nucleotide metabolism",
            id: "nucleotide_metabolism",
            system: "mis",
          },
          {
            name: "PCR",
            id: "pcr",
            system: "mis",
          },
          {
            name: "Protein synthesis",
            id: "protein_synthesis",
            system: "mis",
          },
          {
            name: "Regulation of gene expression",
            id: "regulation_of_gene_expression",
            system: "mis",
          },
          {
            name: "RNA overview",
            id: "rna_overview",
            system: "mis",
          },
        ],
      },
    ],
  },
  {
    name: "Microbiology",
    id: "microbiology",
    level: "preclinical",
    subdivisions: [
      {
        name: "Bacteria",
        id: "bacteria",
        topics: [
          {
            name: "Bacteria overview",
            id: "bacteria_overview",
            system: "mis",
          },
          {
            name: "Normal flora",
            id: "normal_flora",
            system: "mis",
          },
          {
            name: "Acinetobacter",
            id: "acinetobacter",
            system: "mis",
          },
          {
            name: "Actinomycetes",
            id: "actinomycetes",
            system: "mis",
          },
          {
            name: "Bacillus",
            id: "bacillus",
            system: "mis",
          },
          {
            name: "Bacteroides",
            id: "bacteroides",
            system: "mis",
          },
          {
            name: "Bartonella",
            id: "bartonella",
            system: "mis",
          },
          {
            name: "Bordetella",
            id: "bordetella",
            system: "mis",
          },
          {
            name: "Brucella",
            id: "brucella",
            system: "mis",
          },
          {
            name: "Campylobacter",
            id: "campylobacter",
            system: "mis",
          },
          {
            name: "Clostridia",
            id: "clostridia",
            system: "mis",
          },
          {
            name: "Corynebacterium",
            id: "corynebacterium",
            system: "mis",
          },
          {
            name: "Escherichia coli",
            id: "escherichia_coli",
            system: "mis",
          },
          {
            name: "Francisella",
            id: "francisella",
            system: "mis",
          },
          {
            name: "Gram negative rods overview",
            id: "gram_negative_rods_overview",
            system: "mis",
          },
          {
            name: "Gram positive rods overview",
            id: "gram_positive_rods_overview",
            system: "mis",
          },
          {
            name: "HACEK organisms",
            id: "hacek_organisms",
            system: "mis",
          },
          {
            name: "Helicobacter",
            id: "helicobacter",
            system: "mis",
          },
          {
            name: "Hemophilus",
            id: "hemophilus",
            system: "mis",
          },
          {
            name: "Klebsiella",
            id: "klebsiella",
            system: "mis",
          },
          {
            name: "Legionella",
            id: "legionella",
            system: "mis",
          },
          {
            name: "Listeria",
            id: "listeria",
            system: "mis",
          },
          {
            name: "Mycobacterium",
            id: "mycobacterium",
            system: "mis",
          },
          {
            name: "Mycoplasma",
            id: "mycoplasma",
            system: "mis",
          },
          {
            name: "Neisseria",
            id: "neisseria",
            system: "mis",
          },
          {
            name: "Pasteurella",
            id: "pasteurella",
            system: "mis",
          },
          {
            name: "Prevotella",
            id: "prevotella",
            system: "mis",
          },
          {
            name: "Proteus",
            id: "proteus",
            system: "mis",
          },
          {
            name: "Pseudomonas",
            id: "pseudomonas",
            system: "mis",
          },
          {
            name: "Rickettsiae",
            id: "rickettsiae",
            system: "mis",
          },
          {
            name: "Salmonella",
            id: "salmonella",
            system: "mis",
          },
          {
            name: "Shigella",
            id: "shigella",
            system: "mis",
          },
          {
            name: "Spirochetes",
            id: "spirochetes",
            system: "mis",
          },
          {
            name: "Staphylococci",
            id: "staphylococci",
            system: "mis",
          },
          {
            name: "Streptococci",
            id: "streptococci",
            system: "mis",
          },
          {
            name: "Vibrio cholera",
            id: "vibrio_cholera",
            system: "mis",
          },
          {
            name: "Yersinia",
            id: "yersinia",
            system: "mis",
          },
        ],
      },
      {
        name: "Fungi",
        id: "fungi",
        topics: [
          {
            name: "Fungi overview",
            id: "fungi_overview",
            system: "mis",
          },
          {
            name: "Aspergillus",
            id: "aspergillus",
            system: "mis",
          },
          {
            name: "Candida",
            id: "candida",
            system: "mis",
          },
          {
            name: "Coccidioides",
            id: "coccidioides",
            system: "mis",
          },
          {
            name: "Cryptococcus",
            id: "cryptococcus",
            system: "mis",
          },
          {
            name: "Dermatophytes",
            id: "dermatophytes",
            system: "mis",
          },
          {
            name: "Histoplasma",
            id: "histoplasma",
            system: "mis",
          },
          {
            name: "Malassezia",
            id: "malassezia",
            system: "mis",
          },
          {
            name: "Mycetoma",
            id: "mycetoma",
            system: "mis",
          },
          {
            name: "Pneumocystis",
            id: "pneumocystis",
            system: "mis",
          },
          {
            name: "Sporothrix",
            id: "sporothrix",
            system: "mis",
          },
        ],
      },
      {
        name: "Viruses",
        id: "viruses",
        topics: [
          {
            name: "Viruses overview",
            id: "viruses_overview",
            system: "mis",
          },
          {
            name: "Adenoviruses",
            id: "adenoviruses",
            system: "mis",
          },
          {
            name: "Arboviruses",
            id: "arboviruses",
            system: "mis",
          },
          {
            name: "CMV",
            id: "cmv",
            system: "mis",
          },
          {
            name: "Corona viruses",
            id: "corona_viruses",
            system: "mis",
          },
          {
            name: "Covid-19",
            id: "covid-19",
            system: "mis",
          },
          {
            name: "Ebola virus",
            id: "ebola_virus",
            system: "mis",
          },
          {
            name: "EBV",
            id: "ebv",
            system: "mis",
          },
          {
            name: "Enteroviruses",
            id: "enteroviruses",
            system: "mis",
          },
          {
            name: "Hepatitis A virus",
            id: "hepatitis_a_virus",
            system: "mis",
          },
          {
            name: "Hepatitis B virus",
            id: "hepatitis_b_virus",
            system: "mis",
          },
          {
            name: "Hepatitis C virus",
            id: "hepatitis_c_virus",
            system: "mis",
          },
          {
            name: "Hepatitis D virus",
            id: "hepatitis_d_virus",
            system: "mis",
          },
          {
            name: "Hepatitis E virus",
            id: "hepatitis_e_virus",
            system: "mis",
          },
          {
            name: "Hepatitis viruses overview",
            id: "hepatitis_viruses_overview",
            system: "mis",
          },
          {
            name: "Herpes viruses overview",
            id: "Herpes_viruses_overview",
            system: "mis",
          },
          {
            name: "HHV6",
            id: "hhv6",
            system: "mis",
          },
          {
            name: "HHV8",
            id: "hhv8",
            system: "mis",
          },
          {
            name: "HIV & lentiviruses",
            id: "hiv_and_lentiviruses",
            system: "mis",
          },
          {
            name: "HSV",
            id: "hsv",
            system: "mis",
          },
          {
            name: "Influenza, Parainfluenza and Respiratory syncitial viruses",
            id: "influenza_parainfluenza_and_respiratory_syncitial_viruses",
            system: "mis",
          },
          {
            name: "Lassa Virus",
            id: "lassa_virus",
            system: "mis",
          },
          {
            name: "Marburg virus",
            id: "marburg_virus",
            system: "mis",
          },
          {
            name: "Measles virus",
            id: "measles_virus",
            system: "mis",
          },
          {
            name: "Mumps virus",
            id: "mumps_virus",
            system: "mis",
          },
          {
            name: "Norovirus",
            id: "norovirus",
            system: "mis",
          },
          {
            name: "Papilloma viruses",
            id: "papilloma_viruses",
            system: "mis",
          },
          {
            name: "Parvoviruses",
            id: "parvoviruses",
            system: "mis",
          },
          {
            name: "Polyoma viruses",
            id: "polyoma_viruses",
            system: "mis",
          },
          {
            name: "Pox viruses",
            id: "pox_viruses",
            system: "mis",
          },
          {
            name: "Prions",
            id: "prions",
            system: "mis",
          },
          {
            name: "Reoviruses",
            id: "reoviruses",
            system: "mis",
          },
          {
            name: "Rhinoviruses",
            id: "rhinoviruses",
            system: "mis",
          },
          {
            name: "Rotavirus",
            id: "rotavirus",
            system: "mis",
          },
          {
            name: "Rubella virus",
            id: "rubella_virus",
            system: "mis",
          },
          {
            name: "Tumour causing viruses",
            id: "tumour_causing_viruses",
            system: "mis",
          },
          {
            name: "VZV",
            id: "vzv",
            system: "mis",
          },
        ],
      },
      {
        name: "Parasites",
        id: "parasites",
        topics: [
          {
            name: "Parasites overview",
            id: "parasites_overview",
            system: "mis",
          },
          {
            name: "Amoeba",
            id: "amoeba",
            system: "mis",
          },
          {
            name: "Ascaris",
            id: "ascaris",
            system: "mis",
          },
          {
            name: "Cestodes (Tapeworms) overview",
            id: "cestodes_tapeworms_overview",
            system: "mis",
          },
          {
            name: "Chlonorchis",
            id: "chlonorchis",
            system: "mis",
          },
          {
            name: "Cryptosporidium",
            id: "cryptosporidium",
            system: "mis",
          },
          {
            name: "Diphyllobothrium",
            id: "diphyllobothrium",
            system: "mis",
          },
          {
            name: "Dracunculus (guinea worm)",
            id: "dracunculus_guinea_worm",
            system: "mis",
          },
          {
            name: "Echinococcus",
            id: "echinococcus",
            system: "mis",
          },
          {
            name: "Fasciola",
            id: "fasciola",
            system: "mis",
          },
          {
            name: "Giardia",
            id: "giardia",
            system: "mis",
          },
          {
            name: "Hookworms",
            id: "hookworms",
            system: "mis",
          },
          {
            name: "Isospora",
            id: "isospora",
            system: "mis",
          },
          {
            name: "Leishmania",
            id: "leishmania",
            system: "mis",
          },
          {
            name: "Loa loa",
            id: "loa_loa",
            system: "mis",
          },
          {
            name: "Onchocerca",
            id: "onchocerca",
            system: "mis",
          },
          {
            name: "Paragonimus",
            id: "paragonimus",
            system: "mis",
          },
          {
            name: "Plasmodium",
            id: "Plasmodium",
            system: "mis",
          },
          {
            name: "Round worms overview",
            id: "round_worms_overview",
            system: "mis",
          },
          {
            name: "Schistosoma",
            id: "schistosoma",
            system: "mis",
          },
          {
            name: "Taenia",
            id: "taenia",
            system: "mis",
          },
          {
            name: "Toxocara",
            id: "toxocara",
            system: "mis",
          },
          {
            name: "Toxoplasma",
            id: "toxoplasma",
            system: "mis",
          },
          {
            name: "Trematodes (Flukes) overview",
            id: "trematodes_flukes_overview",
            system: "mis",
          },
          {
            name: "Trichomonas",
            id: "trichomonas",
            system: "mis",
          },
          {
            name: "Trichuris",
            id: "trichuris",
            system: "mis",
          },
          {
            name: "Trypanosoma",
            id: "trypanosoma",
            system: "mis",
          },
          {
            name: "Wuchereria",
            id: "wuchereria",
            system: "mis",
          },
        ],
      },
      {
        name: "Ectoparasites (Entomology)",
        id: "ectoparasites_entomology",
        topics: [
          {
            name: "Overview of ectoparasites",
            id: "overview_of_ectoparasites",
            system: "mis",
          },
          {
            name: "Bedbugs",
            id: "bedbugs",
            system: "mis",
          },
          {
            name: "Blackflies",
            id: "blackflies",
            system: "mis",
          },
          {
            name: "Fleas",
            id: "fleas",
            system: "mis",
          },
          {
            name: "Flies and Myasis",
            id: "flies_and_myasis",
            system: "mis",
          },
          {
            name: "Houseflies",
            id: "houseflies",
            system: "mis",
          },
          {
            name: "Lice",
            id: "lice",
            system: "mis",
          },
          {
            name: "Mites",
            id: "mites",
            system: "mis",
          },
          {
            name: "Mosquitoes",
            id: "mosquitoes",
            system: "mis",
          },
          {
            name: "Sandflies",
            id: "sandflies",
            system: "mis",
          },
          {
            name: "Ticks",
            id: "ticks",
            system: "mis",
          },
          {
            name: "Tsetse flies",
            id: "tsetse_flies",
            system: "mis",
          },
        ],
      },
    ],
  },
  {
    name: "Pharmacology",
    id: "pharmacology",
    level: "preclinical",
    exempt: [],
    subdivisions: [
      {
        name: "Miscellaneous",
        id: "mis",
        topics: [
          {
            name: "Alcohol",
            id: "alcohol",
            system: "mis",
          },
          {
            name: "Anti-inflammatory drugs",
            id: "anti-inflammatory_drugs",
            system: "mis",
          },
          {
            name: "Anticancer drugs",
            id: "anticancer_drugs",
            system: "mis",
          },
          {
            name: "Drug abuse and addiction",
            id: "drug_abuse_and_addiction",
            system: "mis",
          },
          {
            name: "Drug-receptor interactions",
            id: "drug-receptor_interactions",
            system: "mis",
          },
          {
            name: "Lipid lowering drugs",
            id: "lipid_lowering_drugs",
            system: "mis",
          },
          {
            name: "Local anaesthetics",
            id: "local_anaesthetics",
            system: "mis",
          },
          {
            name: "Pain control overview",
            id: "pain_control_overview",
            system: "mis",
          },
          {
            name: "Pharmacokinetics (ADME)",
            id: "pharmacokinetics_adme",
            system: "mis",
          },
        ],
      },
      {
        name: "Reproductive System",
        id: "rep",
        topics: [
          {
            name: "Androgens & antagonists",
            id: "androgens_and_antagonists",
            system: "rep",
          },
          {
            name: "Contraceptives: overview",
            id: "contraceptives_overview",
            system: "rep",
          },
          {
            name: "Drugs for Erectile dysfunction",
            id: "drugs_for_erectile_dysfunction",
            system: "rep",
          },
          {
            name: "Estrogens & antagonists",
            id: "estrogens_and_antagonists",
            system: "rep",
          },
          {
            name: "Progestogens & antagonists",
            id: "progestogens_and_antagonists",
            system: "rep",
          },
          {
            name: "Tocolytics & abortifacients",
            id: "tocolytics_and_abortifacients",
            system: "rep",
          },
        ],
      },
      {
        name: "GI System",
        id: "git",
        topics: [
          {
            name: "Acid reducing & neutralizing drugs",
            id: "acid_reducing_and_neutralizing_drugs",
            system: "git",
          },
          {
            name: "Antidiarrheals",
            id: "antidiarrheals",
            system: "git",
          },
          {
            name: "Antiemetics",
            id: "antiemetics",
            system: "git",
          },
          {
            name: "Drugs for protecting the GI mucosa",
            id: "drugs_for_protecting_the_gi_mucosa",
            system: "git",
          },
          {
            name: "GI pharmacology overview",
            id: "gi_pharmacology_overview",
            system: "git",
          },
          {
            name: "Purgatives",
            id: "purgatives",
            system: "git",
          },
        ],
      },
      {
        name: "Respiratory System",
        id: "res",
        topics: [
          {
            name: "Bronchodilators",
            id: "bronchodilators",
            system: "res",
          },
          {
            name: "Cough & antitussives",
            id: "cough_and_antitussives",
            system: "res",
          },
          {
            name: "Mucolytics",
            id: "mucolytics",
            system: "res",
          },
        ],
      },
      {
        name: "Endocrine System",
        id: "end",
        topics: [
          {
            name: "DM drugs",
            id: "dm_drugs",
            system: "end",
          },
          {
            name: "GnRH and antagonists",
            id: "gnrh_and_antagonists",
            system: "end",
          },
          {
            name: "Gonadotropins and antagonists",
            id: "gonadotropins_and_antagonists",
            system: "end",
          },
          {
            name: "Insulin",
            id: "insulin",
            system: "end",
          },
          {
            name: "Pituitary & drugs",
            id: "pituitary_and_drugs",
            system: "end",
          },
          {
            name: "Thyroid drugs",
            id: "thyroid_drugs",
            system: "end",
          },
        ],
      },
      {
        name: "Nervous System",
        id: "cns",
        topics: [
          {
            name: "Adrenergic system",
            id: "adrenergic_system",
            system: "cns",
          },
          {
            name: "Anticonvulsants",
            id: "anticonvulsants",
            system: "cns",
          },
          {
            name: "Antidepressants & mood stabilizers",
            id: "antidepressants_and_mood_stabilizers",
            system: "cns",
          },
          {
            name: "Antipsychotics",
            id: "antipsychotics",
            system: "cns",
          },
          {
            name: "Anxiolytics & Hypnotics",
            id: "anxiolytics_and_hypnotics",
            system: "cns",
          },
          {
            name: "Cholinergic system",
            id: "cholinergic_system",
            system: "cns",
          },
          {
            name: "Opioids",
            id: "opioids",
            system: "cns",
          },
        ],
      },
      {
        name: "Cardiovascular System",
        id: "cvs",
        topics: [
          {
            name: "Antiarrhythmics",
            id: "antiarrhythmics",
            system: "cvs",
          },
          {
            name: "Antihypertensives",
            id: "antihypertensives",
            system: "cvs",
          },
          {
            name: "Nitric oxide",
            id: "nitric_oxide",
            system: "cvs",
          },
        ],
      },
      {
        name: "Urological System",
        id: "gut",
        topics: [
          {
            name: "Diuretics",
            id: "diuretics",
            system: "gut",
          },
        ],
      },
      {
        name: "Musculoskeletal System",
        id: "musculoskeletal_system",
        topics: [
          {
            name: "Bone metabolism and drugs",
            id: "bone_metabolism_and_drugs",
            system: "msk",
          },
        ],
      },
      {
        name: "Hematology and Immunology",
        id: "hem",
        topics: [
          {
            name: "Anticoagulants",
            id: "anticoagulants",
            system: "hem",
          },
          {
            name: "Antiplatelets",
            id: "antiplatelets",
            system: "hem",
          },
          {
            name: "Arachidonic acid metabolism overview",
            id: "arachidonic_acid_metabolism_overview",
            system: "hem",
          },
          {
            name: "Aspirin and NSAIDs",
            id: "aspirin_and_nsaids",
            system: "hem",
          },
          {
            name: "Clotting promoters",
            id: "clotting_promoters",
            system: "hem",
          },
          {
            name: "DMARDs",
            id: "dmards",
            system: "hem",
          },
          {
            name: "Drugs for clotting disorders overview",
            id: "drugs_for_clotting_disorders_overview",
            system: "hem",
          },
          {
            name: "Histamine and antihistamines",
            id: "histamine_and_antihistamines",
            system: "hem",
          },
          {
            name: "Inflammation and immunosuppression overview",
            id: "inflammation_and_immunosuppression_overview",
            system: "hem",
          },
          {
            name: "Kinins and antagonists",
            id: "kinins_and_antagonists",
            system: "hem",
          },
          {
            name: "Leukotriene antagonists",
            id: "leukotriene_antagonists",
            system: "hem",
          },
          {
            name: "Mast-cell stabilizers",
            id: "mast-cell_stabilizers",
            system: "hem",
          },
          {
            name: "Monoclonal antibodies",
            id: "monoclonal_antibodies",
            system: "hem",
          },
          {
            name: "Paracetamol",
            id: "paracetamol",
            system: "hem",
          },
          {
            name: "Serotonin",
            id: "serotonin",
            system: "hem",
          },
          {
            name: "Steroids",
            id: "steroids",
            system: "hem",
          },
          {
            name: "Therapeutic prostaglandins",
            id: "therapeutic_prostaglandins",
            system: "hem",
          },
          {
            name: "Thrombolytics",
            id: "thrombolytics",
            system: "hem",
          },
        ],
      },
      {
        name: "Antimicrobials",
        id: "antimicrobials",
        topics: [
          {
            name: "Anti-helminthics",
            id: "anti-helminthics",
            system: "mis",
          },
          {
            name: "Antibiotics",
            id: "antibiotics",
            system: "mis",
          },
          {
            name: "Antifungals",
            id: "antifungals",
            system: "mis",
          },
          {
            name: "Antimalarials",
            id: "antimalarials",
            system: "mis",
          },
          {
            name: "Antiprotozoals",
            id: "antiprotozoals",
            system: "mis",
          },
          {
            name: "Antivirals",
            id: "antivirals",
            system: "mis",
          },
          {
            name: "Disinfectants and Antiseptics",
            id: "disinfectants_and_antiseptics",
            system: "mis",
          },
        ],
      },
    ],
  },
  {
    name: "Pathology",
    id: "pathology",
    level: "preclinical",
    subdivisions: [
      {
        name: "Miscellaneous",
        id: "mis",
        topics: [
          {
            name: "Amyloidosis",
            id: "amyloidosis",
            system: "mis",
          },
          {
            name: "Edema",
            id: "edema",
            system: "mis",
          },
          {
            name: "Environmental injuries",
            id: "environmental_injuries",
            system: "mis",
          },
          {
            name: "Genetic Disorders",
            id: "genetic_disorders",
            system: "mis",
          },
          {
            name: "Healing and repair",
            id: "healing_and_repair",
            system: "mis",
          },
          {
            name: "Neoplasia: General",
            id: "neoplasia_general",
            system: "mis",
          },
          {
            name: "Obesity",
            id: "obesity",
            system: "mis",
          },
          {
            name: "Protein-Calorie Malnutrition",
            id: "protein-calorie_malnutrition",
            system: "mis",
          },
          {
            name: "Reaction to injury (cells)",
            id: "reaction_to_injury_cells",
            system: "mis",
          },
          {
            name: "Shock",
            id: "shock",
            system: "mis",
          },
          {
            name: "Thrombosis and Embolism",
            id: "thrombosis_and_embolism",
            system: "mis",
          },
          {
            name: "Vitamin Deficiencies",
            id: "vitamin_deficiencies",
            system: "mis",
          },
        ],
      },
      {
        name: "Musculoskeletal & Skin",
        id: "msk",
        topics: [
          {
            name: "Bone disorders",
            id: "bone_disorders",
            system: "msk",
          },
          {
            name: "Skeletal muscle disorders",
            id: "Sseletal_muscle_disorders",
            system: "msk",
          },
          {
            name: "Skin disorders",
            id: "skin_disorders",
            system: "msk",
          },
        ],
      },
      {
        name: "Nervous System",
        id: "cns",
        topics: [
          {
            name: "Cerebrovascular diseases",
            id: "cerebrovascular_diseases",
            system: "cns",
          },
          {
            name: "Congenital diseases of the nervous system",
            id: "congenital_diseases_of_the_cns",
            system: "cns",
          },
          {
            name: "Demyelinating diseases",
            id: "demyelinating_diseases",
            system: "cns",
          },
          {
            name: "Head injuries",
            id: "head_injuries",
            system: "cns",
          },
          {
            name: "Nervous system infections",
            id: "cns_infections",
            system: "cns",
          },
          {
            name: "Nervous system tumours",
            id: "cns_tumours",
            system: "cns",
          },
          {
            name: "Ocular disorders",
            id: "ocular_disorders",
            system: "cns",
          },
        ],
      },
      {
        name: "Cardiovascular System",
        id: "cvs",
        topics: [
          {
            name: "Disorders of arteries",
            id: "disorders_of_arteries",
            system: "cvs",
          },
          {
            name: "Disorders of the heart",
            id: "disorders_of_the_heart",
            system: "cvs",
          },
          {
            name: "Disorders of veins",
            id: "disorders_of_veins",
            system: "cvs",
          },
          {
            name: "Hypertension",
            id: "hypertension",
            system: "cvs",
          },
          {
            name: "Vasculitides",
            id: "vasculitides",
            system: "cvs",
          },
        ],
      },
      {
        name: "Endocrine System",
        id: "end",
        topics: [
          {
            name: "Adrenal Gland disorders",
            id: "adrenal_gland_disorders",
            system: "end",
          },
          {
            name: "Endocrine Pancreas disorders",
            id: "endocrine_pancreas_disorders",
            system: "end",
          },
          {
            name: "Multiple Endocrine Neoplasia",
            id: "multiple_endocrine_neoplasia",
            system: "end",
          },
          {
            name: "Parathyroid disorders",
            id: "parathyroid_disorders",
            system: "end",
          },
          {
            name: "Pituitary Disorders",
            id: "pituitary_disorders",
            system: "end",
          },
          {
            name: "Thyroid disorders",
            id: "thyroid_disorders",
            system: "end",
          },
        ],
      },
      {
        name: "Hematology and Immunology",
        id: "hem",
        topics: [
          {
            name: "Immune system overview",
            id: "immune_system_overview",
            system: "hem",
          },
          {
            name: "Acute inflammation",
            id: "acute_inflammation",
            system: "hem",
          },
          {
            name: "Anemias",
            id: "anemias",
            system: "hem",
          },
          {
            name: "Autoimmunity",
            id: "autoimmunity",
            system: "hem",
          },
          {
            name: "Chronic inflammation",
            id: "chronic_inflammation",
            system: "hem",
          },
          {
            name: "Complement system",
            id: "complement_system",
            system: "hem",
          },
          {
            name: "Immunodeficient disorders",
            id: "immunodeficient_disorders",
            system: "hem",
          },
          {
            name: "Leukemias",
            id: "leukemias",
            system: "hem",
          },
          {
            name: "Lymphomas",
            id: "lymphomas",
            system: "hem",
          },
          {
            name: "Mechanisms of hypersensitivity",
            id: "mechanisms_of_hypersensitivity",
            system: "hem",
          },
          {
            name: "Myeloproliferative diseases",
            id: "myeloproliferative_diseases",
            system: "hem",
          },
          {
            name: "Plasma cell disorders",
            id: "plasma_cell_disorders",
            system: "hem",
          },
        ],
      },
      {
        name: "Urological System",
        id: "gut",
        topics: [
          {
            name: "Acute & Chronic Pyelonephritis",
            id: "acute_and_chronic_pyelonephritis",
            system: "gut",
          },
          {
            name: "Congenital & cystic renal disorders",
            id: "congenital_and_cystic_renal_disorders",
            system: "gut",
          },
          {
            name: "Glomerular disorders",
            id: "glomerular_disorders",
            system: "gut",
          },
          {
            name: "Renal & Urinary tract stones",
            id: "renal_and_urinary_tract_stones",
            system: "gut",
          },
          {
            name: "Renal failure",
            id: "renal_failure",
            system: "gut",
          },
          {
            name: "Renal Tumours",
            id: "renal_tumours",
            system: "gut",
          },
          {
            name: "Tubulointerstitial disorders",
            id: "tubulointerstitial_disorders",
            system: "gut",
          },
          {
            name: "Urinary Tract Infections",
            id: "urinary_tract_infections",
            system: "gut",
          },
        ],
      },
      {
        name: "Reproductive System",
        id: "rep",
        topics: [
          {
            name: "Breast disorders",
            id: "breast_disorders",
            system: "rep",
          },
          {
            name: "Disorders of the Penis",
            id: "disorders_of_the_penis",
            system: "rep",
          },
          {
            name: "Disorders of the testis",
            id: "disorders_of_the_testis",
            system: "rep",
          },
          {
            name: "Fallopian tube disorders",
            id: "fallopian_tube_disorders",
            system: "rep",
          },
          {
            name: "Pregnancy disorders",
            id: "pregnancy_disorders",
            system: "rep",
          },
          {
            name: "Prostate disorders",
            id: "prostate_disorders",
            system: "rep",
          },
          {
            name: "Uterus (and cervix) disorders",
            id: "uterus_and_cervix_disorders",
            system: "rep",
          },
          {
            name: "Vulva and vagina disorders",
            id: "Vulva_and_vagina_disorders",
            system: "rep",
          },
        ],
      },
      {
        name: "GI System",
        id: "git",
        topics: [
          {
            name: "Esophageal disorders",
            id: "esophageal_disorders",
            system: "git",
          },
          {
            name: "Exocrine Pancreas disorders",
            id: "exocrine_pancreas_disorders",
            system: "git",
          },
          {
            name: "Gallbladder disorders",
            id: "gallbladder_disorders",
            system: "git",
          },
          {
            name: "Large bowel disorders",
            id: "large_bowel_disorders",
            system: "git",
          },
          {
            name: "Liver disorders",
            id: "liver_disorders",
            system: "git",
          },
          {
            name: "Salivary gland disorders",
            id: "salivary_gland_disorders",
            system: "git",
          },
          {
            name: "Small bowel disorders",
            id: "small_bowel_disorders",
            system: "git",
          },
          {
            name: "Stomach disorders",
            id: "stomach_disorders",
            system: "git",
          },
        ],
      },
      {
        name: "Respiratory System",
        id: "res",
        topics: [
          {
            name: "Asthma",
            id: "asthma",
            system: "res",
          },
          {
            name: "COPD: Chronic bronchitis & Emphysema",
            id: "copd_chronic_bronchitis_and_Emphysema",
            system: "res",
          },
          {
            name: "Lung cancers",
            id: "lung_cancers",
            system: "res",
          },
          {
            name: "Pneumonias",
            id: "pneumonias",
            system: "res",
          },
          {
            name: "Upper airway disorders",
            id: "upper_airway_disorders",
            system: "res",
          },
        ],
      },
    ],
  },
  {
    name: "Public Health",
    id: "public_health",
    level: "preclinical",
    subdivisions: [
      {
        name: "All Public Health Subdivisions",
        id: "public-health-all-topics",
        topics: [
          {
            name: "Biostatistics",
            id: "biostatistics",
            system: "mis",
          },
          {
            name: "Demography",
            id: "demography",
            system: "mis",
          },
          {
            name: "Disease control",
            id: "disease_control",
            system: "mis",
          },
          {
            name: "Disease prevention",
            id: "disease_prevention",
            system: "mis",
          },
          {
            name: "Epidemiology",
            id: "epidemiology",
            system: "mis",
          },
          {
            name: "Health administration and planning",
            id: "health_administration_and_planning",
            system: "mis",
          },
          {
            name: "Nutrition",
            id: "nutrition",
            system: "mis",
          },
          {
            name: "Occupational health",
            id: "occupational_health",
            system: "mis",
          },
          {
            name: "Port health",
            id: "port_health",
            system: "mis",
          },
          {
            name: "reproductive_health",
            id: "school_health",
            system: "mis",
          },
          {
            name: "School health",
            id: "school_health",
            system: "mis",
          },
          {
            name: "Water and sanitation",
            id: "water_and_sanitation",
            system: "mis",
          },
        ],
      },
    ],
  },
  {
    name: "Psychology",
    id: "psychology",
    level: "preclinical",
    exempt: [],
    subdivisions: [
      {
        name: "All Psychology Subdivisions",
        id: "psychology-all-topics",
        topics: [
          {
            name: "Behaviour",
            id: "behaviour",
            system: "mis",
          },
          {
            name: "Intelligence",
            id: "intelligence",
            system: "mis",
          },
          {
            name: "Learning theories",
            id: "learning_theories",
            system: "mis",
          },
          {
            name: "Medical ethics",
            id: "medical_ethics",
            system: "mis",
          },
          {
            name: "Memory",
            id: "memory",
            system: "mis",
          },
          {
            name: "Personality",
            id: "personality",
            system: "mis",
          },
          {
            name: "Psychological disorders",
            id: "psychological_disorders",
            system: "mis",
          },
          {
            name: "Schools of psychology",
            id: "schools_of_psychology",
            system: "mis",
          },
        ],
      },
    ],
  },
  // {
  //   name: "",
  //   id: "",
  //   level: "preclinical",
  //   exempt: [],
  //   subdivisions: [
  //     {
  //       name: "",
  //       id: "",
  //       topics: [
  //         {
  //           name: "",
  //           id: "",
  //           system: "",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export function getCategories() {
  // return categories;
}

export function getSubjects(data) {
  if (data === "all") {
    // for getting all subjects irrespective of userLevel
    return categories;
  }
  if (data) {
    if (
      (userLevel && userLevel === "clinical") ||
      (userLevel && userLevel === "preclinical")
    ) {
      return data.filter((category) => category.level === userLevel);
    }
    return data;
  } else {
    if (
      (userLevel && userLevel === "clinical") ||
      (userLevel && userLevel === "preclinical")
    ) {
      return categories.filter((category) => category.level === userLevel);
    }
    return categories;
  }
}

export function getSubdivisions(currentSubjects, categories) {
  // console.log(currentSubjects)
  let subdivisions = [];
  let uniqueSystems = new Set();

  for (let x = 0; x < currentSubjects.length; x++) {
    const subject = categories.filter(
      (category) => category.id === currentSubjects[x]
    );
    subdivisions = subdivisions.concat(subject[0].subdivisions);
    for (let i = 0; i < subject[0].subdivisions.length; i++) {
      // uniqueSystems.add(subject[0].subdivisions[i].id)
      uniqueSystems.add(subject[0].subdivisions[i].id);
    }
  }
  const uniqueSystemsArray = Array.from(uniqueSystems);
  // console.log(uniqueSystemsArray.length);
  // console.log("unique systems", uniqueSystemsArray);
  // console.log(subdivisions);

  const uniqueSubdivisions = [];
  for (let x = 0; x < uniqueSystemsArray.length; x++) {
    const uniqueSys = subdivisions.filter(
      (division) => division.id === uniqueSystemsArray[x]
    )[0];
    // console.log("unique sys", uniqueSys);
    uniqueSubdivisions.push(uniqueSys);
  }
  // console.log("unique subdivisions", uniqueSubdivisions)

  return uniqueSubdivisions;
}

export function getTopics(currentSubject, currentSubdivision, categories) {
  const subject = categories.filter(
    (category) => category.id === currentSubject
  )[0];
  // console.log(subject);
  const subdivision = subject.subdivisions.filter(
    (subdivision) => subdivision.id === currentSubdivision
  )[0];
  // console.log(subdivision);
  if (subdivision) {
    return subdivision.topics;
  } else {
    return [{ name: "", id: "" }];
  }
}

export function getTopicTitle(topicId) {
  let allTopics = [];
  const subjects = getSubjects("all");
  for (let x = 0; x < subjects.length; x++) {
    let subdivisions = subjects[x].subdivisions;
    for (let i = 0; i < subdivisions.length; i++) {
      let topics = subdivisions[i].topics;
      for (let y = 0; y < topics.length; y++) {
        allTopics.push(topics[y]);
      }
    }
  }

  // console.log(topicId)
  const topicTitle = allTopics.filter((topic) => topic.id === topicId)[0].name;

  // console.log(topicTitle)
  // console.log(allTopics);

  return topicTitle;
}

export function getSubjectNameById(subjectId) {
  // console.log(subjectId)
  const subject = categories.filter((category) => category.id === subjectId);
  // console.log(subject)
  return subject[0].name;
}

export function getOsceSubjects() {
  return [
    {
      name: "Medicine",
      id: "medicine",
    },
    {
      name: "Surgery",
      id: "surgery",
    },
    {
      name: "Obstetrics & Gynecology",
      id: "obstetrics_and_gynecology",
    },
    {
      name: "Pediatrics",
      id: "pediatrics",
    },
  ];
}

export function getOsceTypes() {
  return [
    {
      name: "History Taking",
      id: "history",
    },
    {
      name: "Physical Examination",
      id: "examination",
    },
    {
      name: "Viva, Procedures, Instruments",
      id: "summary",
    },
    // {
    //     name: "Procedure",
    //     id: "procedure"
    // },
    // {
    //     name: "Instruments",
    //     id: "instruments"
    // },
    // {
    //     name: "Viva",
    //     id: "viva"
    // },
    // {
    //     name: "Drugs",
    //     id: "drugs"
    // }
  ];
}
