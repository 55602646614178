import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import HeaderPaper from "./common/headerPaper";
import firebase from "firebase/app";
import { toast } from "react-toastify";
import PreLoader from "./common/preLoader";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Helmet } from "react-helmet";
import { getSubjectNameById } from "../utils/getCategories";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#f50057",
  },
}));

class OsceResults extends Component {
  state = {
    osces: [],
    foundNoOsces: false,
    user: {},
    subject: "",
    type: "",
    submitting: false,
  };

  componentDidMount() {
    const { subject, type } = this.props.match.params;
    console.log(subject, type);
    const userId = localStorage.getItem("currentUserId");

    // console.log(subject, type)

    let osces = [];
    // let getResults = {};
    if (userId) {
      firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .onSnapshot((doc) => {
          this.setState({ user: doc.data() });
        });
    }

    this.setState({ osces, subject, type });

    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.email) {
        //FOR CHECKING CUSTOM STATES
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            // console.log(idTokenResult.claims)
            if (
              idTokenResult.claims.admin &&
              idTokenResult.claims.admin === true
            ) {
              this.setState({ permitEditOsce: true });
            }
            if (
              idTokenResult.claims.editor &&
              idTokenResult.claims.editor.length > 0
            ) {
              const features = idTokenResult.claims.editor;
              // console.log(features)
              if (features.includes("osce")) {
                // console.log("question included")
                this.setState({ permitEditOsce: true });
              }
            }
            if (
              idTokenResult.claims.moderator &&
              idTokenResult.claims.moderator.features &&
              idTokenResult.claims.moderator.features.length > 0
            ) {
              const features = idTokenResult.claims.moderator.features;
              const subjects = idTokenResult.claims.moderator.subjects;
              // console.log(features)
              // console.log(subjects)
              if (features.includes("osce") && subjects.includes(subject)) {
                // console.log("question included")
                this.setState({ permitEditOsce: true });
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });

    firebase
      .firestore()
      .collection("osces")
      .where("subject", "==", subject)
      .where("type", "==", type)
      .orderBy("dateUploaded", "desc")
      .get()
      .then((querySnapshot) => {
        const osces = [];
        querySnapshot.forEach((doc) => {
          // console.log(doc.data());
          const file = doc.data();
          osces.push(file);
        });
        const sortedOsces = osces.sort((a, b) =>
          a.title
            .trim()
            .toLowerCase()
            .localeCompare(b.title.trim().toLowerCase())
        );
        this.setState({ osces: sortedOsces });
      })
      .catch((error) => {
        // console.error(error.message);
        toast.error(error.message);
      });
  }

  updateSeenOsces = (osce) => {
    console.log("update seen osces called");
    const { user } = this.state;
    const userId = localStorage.getItem("currentUserId");

    // //Launch the preloader while submitting
    // const submitting = this.state.submitting;
    // this.setState({ submitting: !submitting });

    let seenOsces = [];
    if (user) {
      if (user.seenOsces) {
        seenOsces = user.seenOsces;
        console.log(seenOsces);
      } else {
        seenOsces = [];
      }
      let seenOscesIds = [];
      seenOsces.forEach((osce) => {
        seenOscesIds.push(osce.id);
      });

      if (!seenOscesIds.includes(osce.osceId)) {
        // console.log(seenOsces);
        seenOsces.push({
          id: osce.osceId,
          type: osce.type,
          subject: osce.subject,
        });

        console.log(seenOsces);
        // return;
        firebase
          .firestore()
          .collection("users")
          .doc(userId)
          .update({
            seenOsces: seenOsces,
          })
          .then(() => {
            window.location.assign(`/osce-practice/${osce.osceId}`);
            // const submitting = this.state.submitting;
            // this.setState({ submitting: !submitting });
          });
      } else {
        window.location.assign(`/osce-practice/${osce.osceId}`);
      }
    }
  };

  goToOsce = (osce) => {
    window.location.assign(`/osce-practice/${osce.osceId}`);
    // this.updateSeenOsces(osce);
  };

  render() {
    const classes = useStyles;
    const {
      osces,
      foundNoOsces,
      user,
      subject,
      type,
      permitEditOsce,
      submitting,
    } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>OSCE Results</title>
        </Helmet>
        {(!osces.length > 0 && foundNoOsces === false) || submitting ? (
          <PreLoader />
        ) : (
          <div>
            <HeaderPaper
              headerTitle={`OSCEs - ${getSubjectNameById(subject)}, ${type}`}
            />

            <div style={{ width: "90%", margin: "auto", marginTop: "30px" }}>
              <Grid container spacing={4}>
                {osces.map((osce) => (
                  <Grid item key={osce.osceId} xs={10} md={6} lg={4}>
                    {/* <Button
                      style={{ textTransform: "capitalize", width: "100%" }}
                    >
                      {osce.title}
                    </Button> */}
                    <Card className={classes.card}>
                      <CardHeader
                        style={{ padding: "0px" }}
                        title={
                          // <Link to={`/osce-practice/${osce.osceId}`}>
                          //   <Typography
                          //     variant="body1"
                          //     style={{ fontWeight: "bold" }}
                          //   >
                          //     {osce.title}
                          //   </Typography>
                          // </Link>
                          <Button
                            style={{
                              textTransform: "capitalize",
                              width: "100%",
                              justifyContent: "start",
                              padding: "10px",
                              backgroundColor: "#b8cee1",
                            }}
                            onClick={() => this.goToOsce(osce)}
                          >
                            {osce.title}
                          </Button>
                        }
                      />
                    </Card>
                    {user && permitEditOsce && (
                      <Button
                        component={Link}
                        to={`/edit-osce/${osce.osceId}`}
                        fullWidth
                        variant="contained"
                        color="secondary"
                      >
                        Edit OSCE
                      </Button>
                    )}
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        )}

        {foundNoOsces && (
          <Typography
            style={{
              padding: "15px 0px 20px 30px",
              textTransform: "capitalize",
            }}
            variant="body1"
          >
            <em>
              There are no osces matching your selection available yet. Check
              again soon.
            </em>
          </Typography>
        )}
      </React.Fragment>
    );
  }
}

export default OsceResults;
