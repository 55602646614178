import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "firebase/app";
import PreLoader from "./common/preLoader";
import { Helmet } from "react-helmet";
import * as firebaseui from "firebaseui";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

class LoginForm extends Form {
  state = {
    data: { email: "", password: "" },
    errors: {},
    user: {},
    submitting: false,
  };

  schema = {
    password: Joi.string().required().label("Password"),
    email: Joi.string().required().label("Email Address"),
  };

  componentDidMount() {
    let prevPath = "/dashboard";

    if (this.props.match.params.urlTo && this.props.match.params.id) {
      const from = this.props.match.params.urlTo;
      const id = this.props.match.params.id;
      prevPath = `/${from}/${id}`;

      this.setState({ prevPath: prevPath });
    } else if (this.props.match.params.urlTo) {
      const from = this.props.match.params.urlTo;
      prevPath = `/${from}`;

      // console.log(prevPath);
      this.setState({ prevPath: prevPath });
    }

    var uiConfig = {
      signInSuccessUrl: `https://acabest.com${prevPath}`,
      // signInSuccessUrl: `${prevPath}`,
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
      // tosUrl and privacyPolicyUrl accept either url string or a callback
      // function.
      // Terms of service url/callback.
      // tosUrl: "/terms-of-service",
      // Privacy policy url/callback.
      privacyPolicyUrl: function () {
        window.location.assign("/privacy-policy");
      },
    };

    // Initialize the FirebaseUI Widget using Firebase.
    var ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());
    // The start method will wait until the DOM is loaded.
    ui.start("#firebaseui-auth-container", uiConfig);
  }

  doSubmit = () => {
    const { data, prevPath } = this.state;

    const email = data.email.trim();
    const password = data.password.trim();
    let user = {};

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    // Call the server
    const promise = firebase.auth().signInWithEmailAndPassword(email, password);
    promise.then((data) => {
      // const userId = data.user.uid;

      const submitting = this.state.submitting;
      this.setState({ submitting: !submitting });
      toast.success(`Successfully Signed In. Welcome`);
      // this.props.history.push("/");
      setTimeout(() => {
        window.location = prevPath || "/dashboard";
      }, 2000);

      // console.log("user", user);
    });
    promise.catch((error) => {
      const submitting = this.state.submitting;
      this.setState({ submitting: !submitting });
      var errorMessage = error.message;
      toast.error(errorMessage);
      return;
    });
    this.setState({ user });

    // console.log(typeof user);
  };

  render() {
    const classes = useStyles;
    const { submitting } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>Login - Acabest</title>
          <meta
            name="description"
            content="Login to continue solving questions."
          />
        </Helmet>
        {submitting ? (
          <PreLoader />
        ) : (
          <Container
            component="main"
            maxWidth="sm"
            style={{
              margin: "30px auto",
              width: "95%",
              backgroundColor: "#fff",
              boxShadow:
                "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
              padding: "4rem 2rem",
              borderRadius: "10px",
            }}
          >
            <CssBaseline />
            <div className={classes.paper}>
              <Grid justifyContent="center" alignItems="center" item container>
                <Avatar
                  className={classes.avatar}
                  style={{ backgroundColor: "#f50057" }}
                >
                  <LockOutlinedIcon />
                </Avatar>
                <br />

                <Typography
                  component="h1"
                  variant="h5"
                  style={{ marginLeft: "10px" }}
                >
                  Sign in
                </Typography>
              </Grid>
              <div
                // className="container"
                id="firebaseui-auth-container"
              ></div>
              <form className={classes.form} onSubmit={this.handleSubmit}>
                {this.renderTextField("email", "Email Address")}
                {this.renderTextField("password", "Password")}

                {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                /> */}
                {this.renderSubmitButton("Sign In")}
                <Grid container>
                  <Grid item xs>
                    <Link
                      to="/forgot-password"
                      variant="body2"
                      style={{ color: "blue" }}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      to="/student-register"
                      variant="body2"
                      style={{ color: "blue" }}
                    >
                      {"Don't have an account? Register"}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default LoginForm;
