import React from "react";
import { hydrate, render } from "react-dom";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import ScrollToTop from "././components/scrollToTop";
import * as serviceWorker from "./serviceWorker";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import "firebase/functions";
import "./index.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

var firebaseConfig = {
  apiKey: "AIzaSyCmfHshYM2TwaUGVT7svcF1BBtNbbo8_h0",
  authDomain: "acabest-6fe74.firebaseapp.com",
  databaseURL: "https://acabest-6fe74.firebaseio.com",
  projectId: "acabest-6fe74",
  storageBucket: "acabest-6fe74.appspot.com",
  messagingSenderId: "526743478293",
  appId: "1:526743478293:web:d27b865661b5c99846ee1c",
  measurementId: "G-1MY6DC5813",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// firebase.firestore().enablePersistence().then(()=> {
//   // console.log("persistence has been enabled")

// }).catch(error => {
//   if (error.code === "unimplemented") {
//     console.log("This browser does not support offline. Please connect to internet")
//   }

//   if (error.code === "failed-precondition") {
//     console.log("This app is already opened in another tab. Please use that tab instead")
//   }
// })

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    // console.log("user", user);
    const userId = user.uid;
    const docRef = firebase.firestore().collection("users").doc(userId);
    localStorage.setItem("currentUserId", userId);

    //FOR CHECKING CUSTOM STATES
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        // console.log(idTokenResult.claims);
        // Confirm the user is an Admin.
        if (idTokenResult.claims.admin === true) {
          // console.log("this user is an admin");
        } else {
          // console.log("not an admin");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    docRef
      .get()
      .then((doc) => {
        // console.log(doc.data())

        // //ADD MAIN ADMIN CUSTOM CLAIM
        // const makeAdmin = firebase.functions().httpsCallable("makeAdmin")
        // makeAdmin({email: doc.data().email}).then(result => {
        //   console.log(result.data)
        // }).catch(error => {
        //   console.log(error)
        // })

        if (doc.data() === undefined) {
          localStorage.setItem("currentUser", JSON.stringify({}));
          localStorage.setItem("userLevel", "");
        } else {
          localStorage.setItem("currentUser", JSON.stringify(doc.data()));
          localStorage.setItem("userLevel", doc.data().level);
        }
      })
      .catch((error) => {
        console.log("error getting document", error);
      });
    // ...
  } else {
    // User is signed out.
    // console.log("user signed out");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentUserId");
  }
});
firebase
  .firestore()
  .collection("statistics")
  .doc("all")
  .onSnapshot((doc) => {
    localStorage.setItem("statistics", JSON.stringify(doc.data()));
  });

// ReactDOM.render(
//   <BrowserRouter>
//     <ScrollToTop>
//       <App />
//     </ScrollToTop>
//   </BrowserRouter>,
//   document.getElementById("root")
// );
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>,
    rootElement
  );
} else {
  render(
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
