import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import HeaderPaper from "./common/headerPaper";
import firebase from "firebase/app";
// import { toast } from "react-toastify";
import PreLoader from "./common/preLoader";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { Link } from "react-router-dom";
// import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Helmet } from "react-helmet";

class Questions extends Component {
  state = {
    quizzes: [],
    foundNoQuizzes: false,
    user: {},
    subject: "",
    school: "",
  };

  componentDidMount() {
    const { subject, school } = this.props.match.params;
    const user = JSON.parse(localStorage.getItem("currentUser"));

    // console.log(subject, type)

    let quizzes = [];
    // let getResults = {};

    const getResults = firebase
      .firestore()
      .collection("quizzes")
      .where("subject", "==", subject)
      // .where("school", "==", school)
      .orderBy("dateUploaded", "desc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log(doc.data());
          const file = doc.data();
          quizzes.push(file);
        });
      })
      .catch((error) => {
        console.error(error.message);
        //   toast.error(error.message);
      });

    Promise.all([getResults])
      .then(() => {
        // console.log(quizzes)
        if (quizzes.length === 0) {
          this.setState({ foundNoQuizzes: true });
        } else {
          this.setState({ foundNoQuizzes: false });
        }
        this.setState({ quizzes, user, subject, school });
      })
      .catch(() => {
        console.log("something went wrong");
      });
  }

  render() {
    const { quizzes, foundNoQuizzes, user, subject } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>Questions - Acabest</title>
        </Helmet>

        {!quizzes.length > 0 && foundNoQuizzes === false ? (
          <PreLoader />
        ) : (
          <div>
            <HeaderPaper headerTitle={`Questions - ${subject}`} />
            <Typography
              style={{
                padding: "15px 0px 0px 30px",
                textTransform: "capitalize",
              }}
              variant="body1"
            ></Typography>

            <div style={{ width: "90%", margin: "auto", marginTop: "30px" }}>
              <Grid container spacing={4}>
                {quizzes.map((quiz) => (
                  <Grid item key={quiz.quizId}>
                    <Card>
                      <CardHeader
                        title={
                          <Link to={`/quiz-preview/${quiz.quizId}`}>
                            <Typography
                              variant="body1"
                              style={{ fontWeight: "bold" }}
                            >
                              {quiz.title}
                            </Typography>
                          </Link>
                        }
                      />
                    </Card>
                    {user && user.userType === "tutor" && (
                      <Button
                        component={Link}
                        to={`/edit-quiz/${quiz.quizId}`}
                        fullWidth
                        variant="contained"
                        color="secondary"
                      >
                        Edit Quiz
                      </Button>
                    )}
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        )}

        {foundNoQuizzes && (
          <Typography
            style={{
              padding: "15px 0px 20px 30px",
              textTransform: "capitalize",
            }}
            variant="body1"
          >
            <em>
              There are no questions matching your selection available yet.
              Check again soon.
            </em>
          </Typography>
        )}
      </React.Fragment>
    );
  }
}

export default Questions;
