import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const HeaderPaper = ({ headerTitle }) => {
  return (
    <Grid>
      <Paper style={{ backgroundColor: "#9ea8dd" }}>
        <Typography
          color="primary"
          style={{
            padding: "20px 5px 20px 10px",
            textTransform: "capitalize",
            textAlign: "center",
          }}
          variant="h5"
          component="h5"
        >
          {headerTitle}
        </Typography>
      </Paper>
    </Grid>
  );
};

export default HeaderPaper;
