import React from "react";
import Form from "./common/form";
import Joi from "joi-browser";
import { getSubjectNameById, getSubjects } from "../utils/getCategories";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import firebase from "firebase/app";
import { toast } from "react-toastify";
import PreLoader from "./common/preLoader";
import { Helmet } from "react-helmet";
import { getCountriesList } from "../utils/getCountriesInfo";
import {
  getInstitutionsByCountry,
  getInstitutionsList,
} from "../utils/getUniversitiesInfo";
import { Divider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { CardContent, Card, CardActions } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class Dashboard extends Form {
  state = {
    data: {
      firstName: "",
      lastName: "",
      country: "",
      institution: "",
      level: "",
    },
    errors: {},
    submitting: false,
    freshUser: false,
    takeSubjects: false,
    userSubjects: [],
    subjectAnalytics: [],
    osceSubjects: [],
    user: {},
    isAdmin: false,
    isEditor: false,
    isModerator: false,
    isTracker: false,
    openMakeAdmin: false,
    openMakeEditor: false,
    openMakeModerator: false,
    openMakeTracker: false,
    revokeAdminStatus: false,
    revokeEditorStatus: false,
    revokeModeratorStatus: false,
    revokeTrackerStatus: false,
    incomingAdmin: "",
    incomingEditor: {
      email: "",
      features: [],
    },
    incomingModerator: {
      email: "",
      features: [],
      subjects: [],
    },
    schedules: [],
    grantAccess: true,
    openInvoiceDialog: false,
    referenceNumberToActivate: "",
    subjects: [],
  };

  schema = {
    firstName: Joi.string().min(2).max(30).required().label("First Name"),
    lastName: Joi.string().min(2).max(30).required().label("Last Name"),
    country: Joi.string().required().label("Country"),
    institution: Joi.string().required().label("Institution"),
    level: Joi.string().required().label("Level"),
  };

  componentDidMount() {
    const userId = localStorage.getItem("currentUserId");

    if (!userId) {
      console.log("there is no user id");
      return;
    }

    const statistics = JSON.parse(localStorage.getItem("statistics"));

    // GETTING THE SUBJECTS LIST
    const subjects = getSubjects(statistics.subjects);
    const findSubjectsWithRelevantContent = subjects.filter(
      (subject) => subject.noOfQuestions > 0
    );
    const subjectsWithRelevantContent = getSubjects(
      findSubjectsWithRelevantContent
    );

    this.setState({ subjects, subjectsWithRelevantContent });

    firebase.auth().onAuthStateChanged((user) => {
      const userEmail = user.email;
      this.setState({ userEmail });
    });

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .onSnapshot((doc) => {
        // console.log(doc.data());
        const user = doc.data();
        this.setState({ user: doc.data(), userId });

        if (!user) {
          // console.log("this is a fresh user");
          this.setState({ freshUser: true });
        }

        if (user && user.email) {
          this.setState({ freshUser: false });

          firebase
            .firestore()
            .collection("premiumUsers")
            .doc(user.email)
            .onSnapshot((doc) => {
              // console.log(doc.data());
              const user = doc.data();

              if (
                user &&
                user.email.length > 3 &&
                user.subscription.type === "basic"
              ) {
                // console.log("give this one permission");
                this.setState({ grantAccess: true });
              } else {
                this.setState({ grantAccess: false });
              }
            });

          //FOR CHECKING CUSTOM STATES
          firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((idTokenResult) => {
              console.log(idTokenResult.claims);
              // Confirm the user is an Admin.
              if (
                idTokenResult.claims.admin &&
                idTokenResult.claims.admin === true
              ) {
                // console.log("an admin")
                this.setState({ isAdmin: true, grantAccess: true });
              } else if (
                idTokenResult.claims.tracker &&
                idTokenResult.claims.tracker === true
              ) {
                this.setState({ isTracker: true });
              } else if (
                idTokenResult.claims.editor &&
                idTokenResult.claims.editor.length > 0
              ) {
                this.setState({ isEditor: true, grantAccess: true });
              } else if (
                idTokenResult.claims.moderator &&
                idTokenResult.claims.moderator.features &&
                idTokenResult.claims.moderator.features.length > 0
              ) {
                this.setState({ isModerator: true });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }

        if (user && user.userSubjects && user.userSubjects.length > 0) {
          this.setState({ accountCompleted: true });
        } else if (
          (user && !user.userSubjects) ||
          (user && user.userSubjects && user.userSubjects.length < 1)
        ) {
          this.setState({ showTakeSubjects: true });
        }

        if (user && user.userSubjects && user.userSubjects.length > 0) {
          firebase
            .firestore()
            .collection("statistics")
            .doc("all")
            .onSnapshot((doc) => {
              const subjects = doc.data().subjects;
              // console.log(subjects);

              //get subjects analytics
              let subjectAnalytics = [];
              const userSubjects = user.userSubjects || [];
              // console.log(userSubjects);

              for (let x = 0; x < userSubjects.length; x++) {
                subjectAnalytics[userSubjects[x]] = {};
                subjectAnalytics[userSubjects[x]].name = userSubjects[x];
                subjectAnalytics[userSubjects[x]].noOfQuestions = 0;
                subjectAnalytics[userSubjects[x]].noOfQuestionSeen = 0;
                subjectAnalytics[userSubjects[x]].accuracyRate = 0;
                subjectAnalytics[userSubjects[x]].noOfOsces = 0;

                let seenQuestions = user.seenQuestions;
                let userScore = 0;
                let expectedScore = 0;
                for (let y = 0; y < seenQuestions.length; y++) {
                  if (seenQuestions[y].subject === userSubjects[x]) {
                    subjectAnalytics[userSubjects[x]].noOfQuestionSeen += 1;
                    userScore += seenQuestions[y].userScore;
                    expectedScore += seenQuestions[y].expectedScore;
                  }
                }
                // console.log(userScore, expectedScore);
                let accuracyRate;
                if (userScore > 0) {
                  accuracyRate = (userScore / expectedScore) * 100;
                } else {
                  accuracyRate = 100;
                }
                subjectAnalytics[userSubjects[x]].accuracyRate = accuracyRate;
              }

              userSubjects.forEach((userSubject) => {
                const currentSubject = subjects.filter(
                  (subject) => subject.id === userSubject
                )[0];
                // console.log(currentSubject);

                subjectAnalytics[userSubject].noOfQuestions =
                  currentSubject.noOfQuestions;

                if (
                  [
                    "medicine",
                    "surgery",
                    "pediatrics",
                    "obstetrics_and_gynecology",
                  ].includes(userSubject)
                ) {
                  subjectAnalytics[userSubject].noOfOsces =
                    currentSubject.noOfOsces;
                }
              });

              const subjectAnalyticsValues = Object.values(subjectAnalytics);
              // console.log(subjectAnalyticsValues);
              const osceSubjects = subjectAnalyticsValues.filter(
                (subject) => subject.noOfOsces > 0
              );

              this.setState({
                subjectAnalytics: subjectAnalyticsValues,
                osceSubjects,
              });
            });

          firebase
            .firestore()
            .collection("scheduled")
            .where("schedulerId", "==", userId)
            .orderBy("startDate", "asc")
            .get()
            .then((querySnapshot) => {
              let schedules = [];
              querySnapshot.forEach((doc) => {
                const schedule = doc.data();
                schedules.push(schedule);
              });
              this.setState({
                schedules: schedules,
              });
            })
            .catch((error) => {
              //// console.error(error.message);
              toast.error(error.message);
            });
        }
      });
  }

  countries = getCountriesList();
  institutions = () => {
    const { country } = this.state.data;

    if (country) {
      return getInstitutionsByCountry(country);
    } else {
      return getInstitutionsList();
    }
  };
  levels = [
    { name: "PreClinical", id: "preclinical" },
    { name: "Clinical", id: "clinical" },
    { name: "Non-specific", id: "non-specific" },
  ];

  features = [
    { name: "Question Bank", id: "questionbank" },
    { name: "OSCE", id: "osce" },
    { name: "3D Models", id: "threeD-Models" },
    { name: "Spaced Repetition", id: "spaced-repetition" },
  ];

  doSubmit = () => {
    // console.log("submitting the account completion")
    const userId = localStorage.getItem("currentUserId");

    const { data, userEmail } = this.state;
    const firstName = data.firstName.trim();
    const lastName = data.lastName.trim();
    const country = data.country;
    const institution = data.institution;
    const level = data.level;
    const registrationDate = Date.now();

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    const addUserDetails = firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .set({
        firstName: firstName,
        lastName: lastName,
        email: userEmail,
        country: country,
        institution: institution,
        level: level,
        userSubjects: [],
        userType: "student",
        favoriteQuizzes: {},
        seenQuestions: [],
        repetitionTopics: [],
        registrationDate: registrationDate,
        userId: userId,
      });

    const addToMailchimp = firebase
      .firestore()
      .collection("mailchimp")
      .doc(userId)
      .set({
        firstName: firstName,
        lastName: lastName,
        email: userEmail,
      });

    Promise.all([addUserDetails, addToMailchimp])
      .then(() => {
        //Remove the preloader when submitting is done
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        toast.success("Your account has been updated.");

        this.setState({
          //   data: {
          //     firstName: "",
          //     lastName: "",
          //     country: "",
          //     institution: "",
          //     level: "",
          //   },
          showTakeSubjects: true,
        });
      })
      .catch((error) => {
        //Remove the preloader when submitting is done
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        var errorMessage = error.message;
        console.log(errorMessage);
      });
  };

  takeSubjects = () => {
    const { takeSubjects } = this.state;

    this.setState({ takeSubjects: !takeSubjects });
    if (!takeSubjects) {
      this.setState({ userSubjects: [] });
    }
  };

  handleSubjectChange = (event) => {
    let userSubjects = [...this.state.userSubjects];
    const { openMakeModerator } = this.state;
    const incomingModerator = { ...this.state.incomingModerator };
    console.log(incomingModerator);
    const subject = event.currentTarget;

    if (openMakeModerator) {
      userSubjects = incomingModerator.subjects;
    }

    if (subject.checked) {
      if (openMakeModerator && userSubjects.length === 4) {
        toast.error("You may select a maximum of 4 subjects");
        subject.checked = false;
        return;
      }
      if (userSubjects.length === 5) {
        toast.error("You may select a maximum of 5 subjects");
        subject.checked = false;
        return;
      }

      userSubjects.push(subject.value);
      if (openMakeModerator) {
        incomingModerator.subjects = userSubjects;
        this.setState({ incomingModerator });
      } else {
        this.setState({ userSubjects });
      }
    } else if (!subject.checked) {
      const index = userSubjects.indexOf(subject.value);
      if (index > -1) {
        userSubjects.splice(index, 1);
        if (openMakeModerator) {
          incomingModerator.subjects = userSubjects;
          this.setState({ incomingModerator });
        } else {
          this.setState({ userSubjects });
        }
      }
    }
    // console.log(userSubjects);
  };

  addSubjects = (event) => {
    event.preventDefault();
    const userId = localStorage.getItem("currentUserId");
    const { userSubjects } = this.state;
    // console.log(userSubjects)

    if (userSubjects.length < 1) {
      toast.error("You must select at least one Subject");
      return;
    }

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update({
        userSubjects,
      })
      .then(() => {
        //Remove the preloader when submitting is done
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        toast.success("Your subjects have been added");
        const { data } = { ...this.state };
        data.userSubjects = userSubjects;
        this.setState({ user: data });
        this.setState({ accountCompleted: true });
      })
      .catch((error) => {
        //Remove the preloader when submitting is done
        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
        console.log(error.message);
      });
  };

  handleClickOpen = (type, subject) => {
    if (type === "make-admin") {
      if (subject === "revoke") {
        this.setState({ revokeAdminStatus: true, openMakeAdmin: true });
      } else {
        this.setState({ revokeAdminStatus: false, openMakeAdmin: true });
      }
    } else if (type === "make-editor") {
      if (subject === "revoke") {
        this.setState({ revokeEditorStatus: true, openMakeEditor: true });
      } else {
        this.setState({ revokeEditorStatus: false, openMakeEditor: true });
      }
    } else if (type === "make-moderator") {
      if (subject === "revoke") {
        this.setState({ revokeModeratorStatus: true, openMakeModerator: true });
      } else {
        this.setState({
          revokeModeratorStatus: false,
          openMakeModerator: true,
        });
      }
    } else if (type === "make-tracker") {
      if (subject === "revoke") {
        this.setState({ revokeTrackerStatus: true, openMakeTracker: true });
      } else {
        this.setState({
          revokeTrackerStatus: false,
          openMakeTracker: true,
        });
      }
    } else if (type === "invoice") {
      this.setState({ openInvoiceDialog: true });
    }
  };

  handleClose = (type) => {
    if (type === "make-admin") {
      this.setState({
        openMakeAdmin: false,
        revokeAdminStatus: false,
        incomingAdmin: "",
      });
    } else if (type === "make-editor") {
      this.setState({
        openMakeEditor: false,
        revokeEditorStatus: false,
        incomingEditor: {
          email: "",
          features: [],
        },
      });
    } else if (type === "make-moderator") {
      this.setState({
        openMakeModerator: false,
        revokeModeratorStatus: false,
        incomingModerator: {
          email: "",
          features: [],
          subjects: [],
        },
      });
    } else if (type === "make-tracker") {
      this.setState({
        openMakeTracker: false,
        revokeTrackerStatus: false,
        incomingTracker: "",
      });
    } else if (type === "invoice") {
      this.setState({ openInvoiceDialog: false });
    }
  };

  // FOR MAKING OR REVOKING ADMIN STATUS
  makeAdmin = () => {
    const { incomingAdmin: email, revokeAdminStatus } = this.state;

    if (!email) {
      toast.error("Please add a valid email address");
      return;
    }

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    if (revokeAdminStatus === true) {
      // console.log("unmaking an admin");
      // return;
      const revokeAdminStatus = firebase
        .functions()
        .httpsCallable("revokeAdminStatus");
      revokeAdminStatus({ email })
        .then((result) => {
          // console.log(result.data);
          // console.log("unmade the user admin");
          this.setState({ openMakeAdmin: false });
          toast.success(`${email} admin rights have been revoked.`);

          const submitting = this.state.submitting;
          this.setState({ submitting: !submitting });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ openMakeAdmin: false });
          toast.error("Failed. Something went wrong");

          const submitting = this.state.submitting;
          this.setState({ submitting: !submitting });
        });
      return;
    }

    // console.log("making an admin")
    // return;
    //ADD ADMIN CUSTOM CLAIM
    const makeAdmin = firebase.functions().httpsCallable("makeAdmin");
    makeAdmin({ email })
      .then((result) => {
        // console.log(result.data);
        this.setState({ openMakeAdmin: false });
        toast.success(`${email} is now an admin`);

        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ openMakeAdmin: false });
        toast.error("Failed. Something went wrong");
        //   toast.error(error.message)

        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
      });
  };

  // FOR MAKING OR REVOKING EDITOR STATUS
  makeEditor = () => {
    // console.log("make editor function called");
    const { incomingEditor, revokeEditorStatus } = this.state;

    if (!incomingEditor.email) {
      toast.error("Please add a valid email address");
      return;
    } else if (incomingEditor.features.length < 1 && !revokeEditorStatus) {
      toast.error("Please select at least one feature to proceed");
      return;
    }

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    if (revokeEditorStatus === true) {
      // console.log("unmaking an editor");
      // return;
      const email = incomingEditor.email;
      const revokeEditorStatus = firebase
        .functions()
        .httpsCallable("revokeEditorStatus");
      revokeEditorStatus({ email })
        .then((result) => {
          // console.log(result.data);
          console.log("unmade the user editor");
          this.setState({ openMakeEditor: false });
          toast.success(`${email} editor rights have been revoked.`);

          const submitting = this.state.submitting;
          this.setState({ submitting: !submitting });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ openMakeEditor: false });
          toast.error("Failed. Something went wrong");

          const submitting = this.state.submitting;
          this.setState({ submitting: !submitting });
        });
      return;
    }

    // console.log("making an editor")
    // return;
    //ADD EDITOR CUSTOM CLAIM
    const { email, features } = incomingEditor;
    const makeEditor = firebase.functions().httpsCallable("makeEditor");
    makeEditor({ email, features })
      .then((result) => {
        // console.log(result.data);
        toast.success(`${email} is now an Editor`);
        this.setState({ openMakeEditor: false });

        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed. Something went wrong");
        this.setState({ openMakeEditor: false });

        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
      });
  };

  makeModerator = () => {
    // console.log("make moderator function called");
    const { incomingModerator, revokeModeratorStatus } = this.state;

    if (!incomingModerator.email) {
      toast.error("Please add a valid email address");
      return;
    } else if (
      incomingModerator.features.length < 1 &&
      !revokeModeratorStatus
    ) {
      toast.error("Please select at least one feature to proceed");
      return;
    } else if (
      incomingModerator.subjects.length < 1 &&
      !revokeModeratorStatus
    ) {
      toast.error(
        "Please select the subjects you want moderator to have access to."
      );
    }

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    if (revokeModeratorStatus === true) {
      // console.log("unmaking an editor");
      // return;
      const email = incomingModerator.email;
      const revokeModeratorStatus = firebase
        .functions()
        .httpsCallable("revokeModeratorStatus");
      revokeModeratorStatus({ email })
        .then((result) => {
          // console.log(result.data);
          // console.log("unmade the user moderator");
          this.setState({ openMakeModerator: false });
          toast.success(`${email} moderator rights have been revoked.`);

          const submitting = this.state.submitting;
          this.setState({ submitting: !submitting });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ openMakeModerator: false });
          toast.error("Failed. Something went wrong");

          const submitting = this.state.submitting;
          this.setState({ submitting: !submitting });
        });
      return;
    }

    const { email, features, subjects } = incomingModerator;
    const makeModerator = firebase.functions().httpsCallable("makeModerator");
    makeModerator({ email, features, subjects })
      .then((result) => {
        // console.log(result.data);
        toast.success(`${email} is now a Moderator`);
        this.setState({ openMakeModerator: false });

        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
      })
      .catch((error) => {
        // console.log(error);
        toast.error("Failed. Something went wrong");
        this.setState({ openMakeModerator: false });

        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
      });
  };

  makeTracker = () => {
    console.log("making user a tracker");

    const { incomingTracker: email, revokeTrackerStatus } = this.state;
    console.log(email, revokeTrackerStatus);

    if (!email) {
      toast.error("Please add a valid email address");
      return;
    }

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    if (revokeTrackerStatus === true) {
      // return;
      const revokeTrackerStatus = firebase
        .functions()
        .httpsCallable("revokeTrackerStatus");
      revokeTrackerStatus({ email })
        .then((result) => {
          // console.log(result.data);
          this.setState({ openMakeTracker: false });
          toast.success(`${email} tracker rights have been revoked.`);

          const submitting = this.state.submitting;
          this.setState({ submitting: !submitting });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ openMakeTracker: false });
          toast.error("Failed. Something went wrong");

          const submitting = this.state.submitting;
          this.setState({ submitting: !submitting });
        });
      return;
    }

    const makeTracker = firebase.functions().httpsCallable("makeTracker");
    makeTracker({ email })
      .then((result) => {
        // console.log(result.data);
        this.setState({ openmakeTracker: false });
        toast.success(`${email} is now a tracker`);

        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ openmakeTracker: false });
        toast.error("Failed. Something went wrong");
        //   toast.error(error.message)

        const submitting = this.state.submitting;
        this.setState({ submitting: !submitting });
      });
  };

  handleMakeAdmin = (event) => {
    const incomingAdmin = event.currentTarget.value;
    this.setState({ incomingAdmin });
  };

  handleMakeTracker = (event) => {
    const incomingTracker = event.currentTarget.value;
    this.setState({ incomingTracker });
  };

  handleMakeEditor = ({ currentTarget: input }) => {
    const incomingEditor = { ...this.state.incomingEditor };
    if (input.name === "email") {
      incomingEditor[input.name] = input.value;
      this.setState({ incomingEditor });
    }
  };

  handleMakeModerator = ({ currentTarget: input }) => {
    const incomingModerator = { ...this.state.incomingModerator };
    if (input.name === "email") {
      incomingModerator[input.name] = input.value;
      this.setState({ incomingModerator });
    }
  };

  handleFeatureChange = (event) => {
    let incoming;
    if (this.state.openMakeEditor) {
      incoming = "incomingEditor";
    } else if (this.state.openMakeModerator) {
      incoming = "incomingModerator";
    }

    let incomingRole = { ...this.state[incoming] };
    const feature = event.currentTarget;

    if (feature.checked) {
      if (incomingRole.features.length === 2) {
        toast.error("You may select a maximum of 2 features");
        feature.checked = false;
        return;
      }

      incomingRole.features.push(feature.value);
      this.state.openMakeEditor
        ? this.setState({ incomingEditor: incomingRole })
        : this.setState({ incomingModerator: incomingRole });
    } else if (!feature.checked) {
      const index = incomingRole.features.indexOf(feature.value);
      if (index > -1) {
        incomingRole.features.splice(index, 1);
        this.state.openMakeEditor
          ? this.setState({ incomingEditor: incomingRole })
          : this.setState({ incomingModerator: incomingRole });
      }
    }
    // console.log(incomingEditor);
  };

  checkIfTopicsDue = (user) => {
    const userTopics = user.repetitionTopics;

    const dueCards = userTopics.filter(
      (card) => card.dueDate < Date.now() && card.progress < 3
    );
    if (dueCards.length < 1) {
      return (
        <Grid item style={{ margin: "0 auto" }}>
          You have no topics due for revision and quizing
        </Grid>
      );
    } else {
      return (
        <Grid item style={{ margin: "0 auto" }}>
          You have {dueCards.length} {dueCards.length > 1 ? "topics" : "topic"}{" "}
          due for revision and quizing
        </Grid>
      );
    }
  };

  checkIfScheduleDue = () => {
    const { schedules } = this.state;

    const dueSchedules = schedules.filter(
      (schedule) =>
        schedule.startDate < Date.now() &&
        Date.now() < schedule.startDate + 86400000
    );
    // console.log(dueSchedules.length)
    if (dueSchedules.length < 1) {
      return (
        <Typography variant="body1">
          You have no schedules that are due!
        </Typography>
      );
    }
    return (
      <Grid>
        <Typography variant="body1">
          You have {dueSchedules.length}{" "}
          {dueSchedules.length > 1
            ? "schedules that are due"
            : "schedule that is due"}
          !
        </Typography>
        <br />
        <Button
          fullWidth
          size="small"
          variant="outlined"
          color="primary"
          component={Link}
          to="/my-scheduled"
        >
          Go To My Schedules
        </Button>
      </Grid>
    );
  };

  checkIfFlaschCardsDue = (user) => {
    const userCards = user.flashcards;

    const dueCards = userCards.filter(
      (card) =>
        card.dueDate < Date.now() ||
        card.phase === "new" ||
        card.phase === "relearning"
    );
    if (dueCards.length < 1) {
      return (
        <Grid item style={{ margin: "0 auto" }}>
          You have no Flashcards that are due for review.
        </Grid>
      );
    } else {
      return (
        <Grid item style={{ margin: "0 auto" }}>
          {dueCards.length > 1
            ? `You have ${dueCards.length} Flashcards that are due for review.`
            : `You have ${dueCards.length} Flashcard that is due for review.`}
        </Grid>
      );
    }
  };

  handleReferenceNoChange = (event) => {
    const referenceNumberToActivate = event.currentTarget.value;
    this.setState({ referenceNumberToActivate });
  };

  activateUserWithInvoice = () => {
    const { referenceNumberToActivate } = this.state;
    const referenceNumber = String(referenceNumberToActivate);
    console.log(referenceNumber);
    if (referenceNumber.length !== 6) {
      toast.error("Reference Number must be a 6 digit number");
      return;
    }

    //Launch the preloader while submitting
    const submitting = this.state.submitting;
    this.setState({ submitting: !submitting });

    firebase
      .firestore()
      .collection("invoices")
      .doc(referenceNumber)
      .get()
      .then((doc) => {
        const invoice = doc.data();

        const processInvoicePayment = firebase
          .functions()
          .httpsCallable("processInvoicePayment");
        processInvoicePayment(invoice)
          .then((result) => {
            if (result.data.error) {
              toast.error(result.data.error);
              const submitting = this.state.submitting;
              this.setState({
                submitting: !submitting,
                referenceNumberToActivate: "",
              });
              return;
            }
            console.log(result.data);
            toast.success(
              `user account ${invoice.user.email} has been activated`
            );

            const submitting = this.state.submitting;
            this.setState({ submitting: !submitting });
          })
          .catch((error) => {
            console.log(error);
            toast.error("Failed. Something went wrong");
            //   toast.error(error.message)

            const submitting = this.state.submitting;
            this.setState({ submitting: !submitting });
          });
      });
  };

  render() {
    const {
      submitting,
      freshUser,
      takeSubjects,
      accountCompleted,
      showTakeSubjects,
      user,
      subjectAnalytics,
      userEmail,
      userId,
      isAdmin,
      isTracker,
      openMakeAdmin,
      openMakeEditor,
      openMakeModerator,
      openMakeTracker,
      revokeAdminStatus,
      revokeEditorStatus,
      revokeModeratorStatus,
      revokeTrackerStatus,
      osceSubjects,
      openInvoiceDialog,
      subjects,
      grantAccess,
    } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Dashboard - Acabest</title>
          <meta
            name="description"
            content="Get insight into your progress on Acabest and take your next steps"
          />
        </Helmet>

        <br />
        <Typography variant="h4" component="h1" style={{ textAlign: "center" }}>
          Dashboard
        </Typography>

        {userEmail && (
          <div>
            {!showTakeSubjects && freshUser && !accountCompleted && (
              <div>
                {submitting ? (
                  <PreLoader />
                ) : (
                  <Container
                    component="main"
                    maxWidth="sm"
                    style={{
                      margin: "30px auto",
                      width: "95%",
                      backgroundColor: "#fff",
                      boxShadow:
                        "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                      padding: "4rem 2rem",
                      borderRadius: "10px",
                    }}
                  >
                    <CssBaseline />
                    <div>
                      <Grid
                        justifyContent="center"
                        alignItems="center"
                        item
                        container
                      >
                        <Typography variant="body1">
                          Please provide the following information to complete
                          your account setup!
                        </Typography>
                        <Avatar style={{ backgroundColor: "#f50057" }}>
                          <LockOutlinedIcon />
                        </Avatar>
                        <br />
                        <Typography
                          component="h1"
                          variant="h5"
                          style={{ marginLeft: "10px" }}
                        >
                          Complete your Account
                        </Typography>
                      </Grid>
                      <form onSubmit={this.handleSubmit}>
                        {this.renderTextField("firstName", "First Name")}
                        {this.renderTextField("lastName", "Last Name")}
                        {this.renderSelect(
                          "country",
                          "Select Country",
                          this.countries
                        )}
                        {this.renderSelect(
                          "institution",
                          "Select Institution",
                          this.institutions()
                        )}
                        {this.renderSelect(
                          "level",
                          "Select Level",
                          this.levels
                        )}
                        {this.renderSubmitButton("Submit")}
                      </form>
                    </div>
                  </Container>
                )}
              </div>
            )}

            {!accountCompleted && showTakeSubjects && (
              <div>
                {submitting ? (
                  <PreLoader />
                ) : (
                  <Container
                    component="main"
                    maxWidth="sm"
                    style={{
                      marginTop: "5vh",
                      backgroundColor: "#eee",
                      padding: "4rem 2rem",
                      boxShadow:
                        "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                      borderRadius: "6px",
                    }}
                  >
                    <CssBaseline />
                    <div>
                      <Grid
                        justifyContent="center"
                        alignItems="center"
                        item
                        container
                      >
                        <Typography variant="body1">
                          One final step to complete your account setup. Which
                          subjects are you doing in class now? You will not be
                          limited to these subjects. This is just to guide us to
                          save you time and give you better analytics. You may
                          change your subjects Preferences later in your Profile
                          page.
                        </Typography>
                      </Grid>
                      <form onSubmit={this.addSubjects}>
                        <div style={{ marginBottom: "20px" }}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.takeSubjects}
                          >
                            Select Subject(s){" "}
                            {takeSubjects ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </Button>
                          {takeSubjects &&
                            subjects.map((subject) => (
                              <div
                                key={subject.id}
                                style={{
                                  margin: "5px auto",
                                  padding: "5px auto",
                                }}
                              >
                                <input
                                  style={{ marginRight: "10px" }}
                                  type="checkbox"
                                  name={subject.id}
                                  id={`${subject.name}-${subject.id}`}
                                  value={subject.id}
                                  onChange={this.handleSubjectChange}
                                />
                                <label
                                  htmlFor={`${subject.name}-${subject.id}`}
                                >
                                  {subject.name}
                                </label>
                              </div>
                            ))}
                        </div>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                        >
                          Add subjects
                        </Button>
                        `
                      </form>
                    </div>
                  </Container>
                )}
              </div>
            )}

            {accountCompleted && user && (
              <Grid container spacing={0}>
                <Container
                  component="main"
                  maxWidth="sm"
                  style={{
                    margin: "20px auto",
                    width: "95%",
                    // backgroundColor: "#3f51b580",
                    background:
                      "linear-gradient(42deg, rgb(63, 81, 181), #007bff33 70.71%)",
                    padding: "4rem 2rem",
                    boxShadow:
                      "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                    borderRadius: "10px",
                  }}
                >
                  {(isTracker || isAdmin) && (
                    <Card variant="outlined" style={{ marginBottom: "70px" }}>
                      <CardContent>
                        <Typography
                          variant="body2"
                          style={{ fontFamily: "Helvetica" }}
                        >
                          Enter Reference number to activate a user account.
                        </Typography>
                        <TextField
                          autoFocus
                          margin="dense"
                          name="referenceNumber"
                          label="Ref No"
                          type="number"
                          onChange={this.handleReferenceNoChange}
                          fullWidth
                        />
                      </CardContent>
                      <CardActions>
                        <Button
                          fullWidth
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={this.activateUserWithInvoice}
                        >
                          Activate User
                        </Button>
                      </CardActions>
                    </Card>
                  )}

                  {user.invoice &&
                    user.invoice.status === "Not Paid" &&
                    !grantAccess && (
                      <Card variant="outlined" style={{ marginBottom: "70px" }}>
                        <CardContent>
                          <Typography
                            variant="h6"
                            style={{ fontFamily: "Helvetica" }}
                          >
                            An Invoice has been generated for you!
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button
                            fullWidth
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => this.handleClickOpen("invoice")}
                          >
                            Pay Now
                          </Button>
                        </CardActions>
                      </Card>
                    )}

                  <Card variant="outlined" style={{ marginBottom: "70px" }}>
                    <CardContent>
                      <Typography
                        variant="h6"
                        style={{ fontFamily: "Helvetica", fontWeight: "bold" }}
                      >
                        Hi, {user.firstName}.
                      </Typography>
                      <Divider /> <br />
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <em>Level</em> - {user.level}
                        </Grid>
                        <Grid item md={6}>
                          <em>
                            {user.userSubjects.length === 1
                              ? `Subject:`
                              : "Subjects:"}
                          </em>
                          <ul>
                            {user.userSubjects.map((subject) => (
                              <li key={subject}>
                                {getSubjectNameById(subject)}
                              </li>
                            ))}
                          </ul>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={`/my-profile/${userId}`}
                      >
                        Change Preferences
                      </Button>
                    </CardActions>
                  </Card>
                  {/* </Container> */}

                  <Card variant="outlined" style={{ marginBottom: "70px" }}>
                    <CardContent>
                      <Typography
                        variant="h6"
                        style={{
                          fontFamily: "Helvetica",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        Questions
                      </Typography>
                      <Divider /> <br />
                      <Grid container spacing={2}>
                        <Grid item>
                          <em>You have answered:</em>
                          <ul>
                            {subjectAnalytics.map((subject) => (
                              <li key={subject.name}>
                                {subject.noOfQuestionSeen} out of{" "}
                                {subject.noOfQuestions} available Questions on{" "}
                                <strong>
                                  {getSubjectNameById(subject.name)}
                                </strong>
                                {/* with an Accuracy Rate of {subject.accuracyRate.toFixed(2)}% */}
                                <br />
                                <br />
                              </li>
                            ))}
                          </ul>
                          <Divider />
                        </Grid>
                        <Grid item>
                          <Typography variant="h6">
                            SCHEDULED SESSIONS
                          </Typography>
                          <Grid style={{ margin: "20px" }}>
                            {this.checkIfScheduleDue()}
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        component={Link}
                        to="/questionbank"
                      >
                        Solve some questions
                      </Button>
                    </CardActions>
                  </Card>

                  {(user.level === "clinical" ||
                    user.level === "non-specific") && (
                    <Card variant="outlined" style={{ marginBottom: "70px" }}>
                      <CardContent>
                        <Typography
                          variant="h6"
                          style={{
                            fontFamily: "Helvetica",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          OSCE Guides
                        </Typography>
                        <Divider /> <br />
                        <Grid container spacing={2}>
                          <Grid item>
                            <em>There are: </em>
                            <ul>
                              {osceSubjects.map((subject) => (
                                <li key={subject.name}>
                                  {subject.noOfOsces} OSCE Guides on{" "}
                                  <strong>
                                    {getSubjectNameById(subject.name)}
                                  </strong>{" "}
                                  available
                                  <br />
                                  <br />
                                </li>
                              ))}
                            </ul>
                          </Grid>
                          {/* <Grid item md={6}>
                                            Topics:
                                            <p>
                                                You have answered
                                            </p>
                                        </Grid> */}
                        </Grid>
                      </CardContent>
                      <CardActions>
                        <Button
                          fullWidth
                          size="small"
                          variant="contained"
                          color="primary"
                          component={Link}
                          to="/osce"
                        >
                          Go to OSCE Guides
                        </Button>
                      </CardActions>
                    </Card>
                  )}

                  <Card variant="outlined" style={{ marginBottom: "70px" }}>
                    <CardContent>
                      <Typography
                        variant="h6"
                        style={{
                          fontFamily: "Helvetica",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        Automated Revision
                      </Typography>
                      <Divider /> <br />
                      <Grid container spacing={2}>
                        <Grid style={{ margin: "20px" }}>
                          {user.repetitionTopics && this.checkIfTopicsDue(user)}
                          {!user.repetitionTopics && (
                            <Typography>
                              Seems you have not started using Automated
                              Revision. You should try it.
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        component={Link}
                        to="/spaced-repetition"
                      >
                        Go To Automated Revision
                      </Button>
                    </CardActions>
                  </Card>

                  <Card variant="outlined" style={{ marginBottom: "70px" }}>
                    <CardContent>
                      <Typography
                        variant="h6"
                        style={{
                          fontFamily: "Helvetica",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        Flashcards
                      </Typography>
                      <Divider /> <br />
                      <Grid container spacing={2}>
                        <Grid style={{ margin: "20px" }}>
                          {user.flashcards && this.checkIfFlaschCardsDue(user)}
                          {!user.flashcards && (
                            <Typography>
                              It seems you have not created any flashcards.
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        component={Link}
                        to="/flashcards"
                      >
                        Go to Flashcards
                      </Button>
                    </CardActions>
                  </Card>

                  {isAdmin && (
                    <Card variant="outlined" style={{ marginBottom: "20px" }}>
                      <CardContent>
                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          style={{
                            padding: "2px 5px",
                            margin: "0px 5px 10px 5px",
                          }}
                          onClick={() => this.handleClickOpen("make-admin")}
                        >
                          Make A User Admin
                        </Button>
                        <Button
                          variant="contained"
                          fullWidth
                          color="secondary"
                          style={{
                            padding: "2px 5px",
                            margin: "0px 5px 10px 5px",
                          }}
                          onClick={() =>
                            this.handleClickOpen("make-admin", "revoke")
                          }
                        >
                          Revoke A User's Admin status
                        </Button>

                        <br />
                        <br />
                        <Divider />
                        <br />
                        <br />

                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          style={{
                            padding: "2px 5px",
                            margin: "0px 5px 10px 5px",
                          }}
                          onClick={() => this.handleClickOpen("make-editor")}
                        >
                          Make A User Editor
                        </Button>
                        <Button
                          variant="contained"
                          fullWidth
                          color="secondary"
                          style={{
                            padding: "2px 5px",
                            margin: "0px 5px 10px 5px",
                          }}
                          onClick={() =>
                            this.handleClickOpen("make-editor", "revoke")
                          }
                        >
                          Revoke A User's Editor status
                        </Button>

                        <br />
                        <br />
                        <Divider />
                        <br />
                        <br />

                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          style={{
                            padding: "2px 5px",
                            margin: "0px 5px 10px 5px",
                          }}
                          onClick={() => this.handleClickOpen("make-moderator")}
                        >
                          Make A User Moderator
                        </Button>
                        <Button
                          variant="contained"
                          fullWidth
                          color="secondary"
                          style={{
                            padding: "2px 5px",
                            margin: "0px 5px 10px 5px",
                          }}
                          onClick={() =>
                            this.handleClickOpen("make-moderator", "revoke")
                          }
                        >
                          Revoke A User's Moderator status
                        </Button>

                        <br />
                        <br />
                        <Divider />
                        <br />
                        <br />

                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          style={{
                            padding: "2px 5px",
                            margin: "0px 5px 10px 5px",
                          }}
                          onClick={() => this.handleClickOpen("make-tracker")}
                        >
                          Make a user a Tracker
                        </Button>
                        <Button
                          variant="contained"
                          fullWidth
                          color="secondary"
                          style={{
                            padding: "2px 5px",
                            margin: "0px 5px 10px 5px",
                          }}
                          onClick={() =>
                            this.handleClickOpen("make-tracker", "revoke")
                          }
                        >
                          Revoke A User's Tracker status
                        </Button>
                        <br />
                        <br />
                        <Divider />
                        <br />
                        <br />

                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          style={{
                            padding: "2px 5px",
                            margin: "0px 5px 10px 5px",
                          }}
                          onClick={() => window.location.assign("/statistics")}
                        >
                          Content Statistics
                        </Button>
                      </CardContent>
                      <CardActions></CardActions>
                    </Card>
                  )}
                </Container>
              </Grid>
            )}
          </div>
        )}

        {!userEmail && (
          <div style={{ margin: "30px" }}>
            <Typography variant="body1">
              Please{" "}
              <Button
                component={Link}
                variant="contained"
                color="primary"
                to="/login"
              >
                Sign In
              </Button>{" "}
              to Continue.
            </Typography>
            <Typography variant="caption">
              If you are alredy signed in, then simply refresh the page
            </Typography>
          </div>
        )}

        {submitting ? (
          <PreLoader />
        ) : (
          <div id="dialogs">
            <div id="admin-dialog">
              <Dialog
                open={openMakeAdmin}
                onClose={() => this.handleClose("make-admin")}
                aria-labelledby="make-admin-dialog"
              >
                <DialogTitle id="make-admin-dialog">
                  {revokeAdminStatus
                    ? "Revoke Admin Status"
                    : "Grant Admin Status"}
                </DialogTitle>
                <DialogContent style={{ minWidth: "100px%" }}>
                  <DialogContentText>
                    {revokeAdminStatus
                      ? "Add a user's email here to revoke their admin rights"
                      : "Add a user's email here to grant them admin rights"}
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="email"
                    label="Email of User"
                    type="text"
                    onChange={this.handleMakeAdmin}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => this.handleClose("make-admin")}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button onClick={this.makeAdmin} color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            <div id="editor-dialog">
              <Dialog
                open={openMakeEditor}
                onClose={() => this.handleClose("make-editor")}
                aria-labelledby="make-editor-dialog"
              >
                <DialogTitle id="make-editor-dialog">
                  {revokeEditorStatus
                    ? "Revoke Editor Status"
                    : "Grant Editor Status"}
                </DialogTitle>
                <DialogContent style={{ minWidth: "100px%" }}>
                  <DialogContentText>
                    {revokeEditorStatus
                      ? "Add a user's email to revoke their editor rights."
                      : "Add a user's email and select the features to which you want to grant them editor rights. Note that this will overwrite any previous editor rights."}
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="email"
                    label="Email of User"
                    type="text"
                    onChange={this.handleMakeEditor}
                    fullWidth
                  />

                  <br />
                  <br />

                  {!revokeEditorStatus &&
                    this.features.map((feature) => (
                      <div
                        key={feature.id}
                        style={{ margin: "5px auto", padding: "5px auto" }}
                      >
                        <input
                          style={{ marginRight: "10px" }}
                          type="checkbox"
                          name={feature.id}
                          id={`${feature.name}-${feature.id}`}
                          value={feature.id}
                          onChange={this.handleFeatureChange}
                        />
                        <label htmlFor={`${feature.name}-${feature.id}`}>
                          {feature.name}
                        </label>
                      </div>
                    ))}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => this.handleClose("make-editor")}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button onClick={this.makeEditor} color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            <div id="moderator-dialog">
              <Dialog
                open={openMakeModerator}
                onClose={() => this.handleClose("make-moderator")}
                aria-labelledby="make-moderator-dialog"
              >
                <DialogTitle id="make-moderator-dialog">
                  {revokeModeratorStatus
                    ? "Revoke Moderator Status"
                    : "Grant Moderator Status"}
                </DialogTitle>
                <DialogContent style={{ minWidth: "100px%" }}>
                  <DialogContentText>
                    {revokeModeratorStatus
                      ? "Add a user's email here to revoke their moderator rights"
                      : "Add a user's email here and select features and subjects to grant them moderator rights to. Note that this will overwrite any previous moderator rights."}
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Email of User"
                    type="text"
                    name="email"
                    onChange={this.handleMakeModerator}
                    fullWidth
                  />

                  <br />
                  <br />

                  {!revokeModeratorStatus &&
                    this.features.map((feature) => (
                      <div
                        key={feature.id}
                        style={{ margin: "5px auto", padding: "5px auto" }}
                      >
                        <input
                          style={{ marginRight: "10px" }}
                          type="checkbox"
                          name={feature.id}
                          id={`${feature.name}-${feature.id}`}
                          value={feature.id}
                          onChange={this.handleFeatureChange}
                        />
                        <label htmlFor={`${feature.name}-${feature.id}`}>
                          {feature.name}
                        </label>
                      </div>
                    ))}

                  <br />
                  <Divider />
                  <br />

                  {!revokeModeratorStatus &&
                    subjects.map((subject) => (
                      <div
                        key={subject.id}
                        style={{ margin: "5px auto", padding: "5px auto" }}
                      >
                        <input
                          style={{ marginRight: "10px" }}
                          type="checkbox"
                          name={subject.id}
                          id={`${subject.name}-${subject.id}`}
                          value={subject.id}
                          onChange={this.handleSubjectChange}
                        />
                        <label htmlFor={`${subject.name}-${subject.id}`}>
                          {subject.name}
                        </label>
                      </div>
                    ))}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => this.handleClose("make-moderator")}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button onClick={this.makeModerator} color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            <div id="tracker-dialog">
              <Dialog
                open={openMakeTracker}
                onClose={() => this.handleClose("make-tracker")}
                aria-labelledby="make-tracker-dialog"
              >
                <DialogTitle id="make-tracker-dialog">
                  {revokeTrackerStatus
                    ? "Revoke Tracker Status"
                    : "Grant Tracker Status"}
                </DialogTitle>
                <DialogContent style={{ minWidth: "100px%" }}>
                  <DialogContentText>
                    {revokeTrackerStatus
                      ? "Add a user's email here to revoke their tracker rights"
                      : "Add a user's email here to grant them tracker rights"}
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="email"
                    label="Email of User"
                    type="text"
                    onChange={this.handleMakeTracker}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => this.handleClose("make-tracker")}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button onClick={this.makeTracker} color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            {user && user.invoice && (
              <div id="invoice-dialog">
                <Dialog
                  open={openInvoiceDialog}
                  onClose={() => this.handleClose("invoice-dialog")}
                  aria-labelledby="show-invoice-dialog"
                >
                  <DialogTitle id="show-invoice-dialog">
                    Your Invoice ({user.invoice.network}) -{" "}
                    {user.invoice.status === "Not Paid" ? (
                      <span style={{ color: "red" }}>Not Paid</span>
                    ) : (
                      <span style={{ color: "green" }}>Paid</span>
                    )}
                  </DialogTitle>
                  <DialogContent>
                    <Typography variant="body2">
                      Merchant ID: ------ <br />
                      Account Name: ACABEST MEDICAL LTD <br />
                      Reference: {user.invoice.referenceNumber} <br />
                      Amount: GHC {user.invoice.amount}
                    </Typography>
                    <br />
                    {user.invoice.network === "MTN" && (
                      <Typography variant="body2">
                        <strong>How To Pay via MoMo Pay:</strong> <br />
                        Dial *172# and select 2.(Note: Enter "Merchant ID" at
                        Merchant ID/Payment Reference. There will be another
                        prompt to enter "Reference"). <br />
                        You may also select "MoMo Pay" on the MTN app.
                      </Typography>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => this.handleClose("invoice")}
                      color="primary"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Dashboard;
