import { Divider } from "@material-ui/core";
import React, { Component } from "react";
import { Helmet } from "react-helmet";

class Repetition extends Component {
  state = {
    currentCardIndex: 0,
    reviewingCardIndex: 0,
  };

  deck = {
    id: "1",
    title: "My first Deck",
    cards: [
      {
        id: "a",
        phase: "learning",
        grade: 0,
        front: "What is my name",
        back: "My name is Duudu",
        dueDate: 0,
        lapses: 0,
        ease: 2.5,
        interval: 1,
      },
      {
        id: "b",
        phase: "learning",
        grade: 0,
        front: "what is my profession",
        back: "I'm a student",
        dueDate: 0,
        lapses: 0,
        ease: 2.5,
        interval: 1,
      },
      {
        id: "c",
        phase: "learning",
        grade: 0,
        front: "Where do I stay",
        back: "I stay at Korle Bu",
        dueDate: 0,
        lapses: 0,
        ease: 2.5,
        interval: 1,
      },
      {
        id: "d",
        phase: "learning",
        grade: 0,
        front: "Do I eat",
        back: "Yes sometimes I eat",
        dueDate: 0,
        lapses: 0,
        ease: 2.5,
        interval: 1,
      },
      {
        id: "e",
        phase: "learning",
        grade: 0,
        front: "Do I have friends",
        back: "I have many friends",
        dueDate: 0,
        lapses: 0,
        ease: 2.5,
        interval: 1,
      },
    ],
  };

  startLearning = () => {
    console.log("started learning");
    // localStorage.setItem("deck", JSON.stringify(this.deck));

    let { currentCardIndex } = this.state;
    const deck = JSON.parse(localStorage.getItem("deck"));
    console.log("deck before", deck);
    let currentCard = deck.cards[currentCardIndex];
    console.log("card before", currentCard);

    // currentCard.phase = "reviewing";
    // console.log("deck after", deck);
    // console.log("card after", currentCard);

    localStorage.setItem("deck", JSON.stringify(deck));
  };

  startReviewing = () => {
    let { reviewingCardIndex } = this.state;
    const deck = JSON.parse(localStorage.getItem("deck"));
    console.log("deck before", deck);

    const currentCard = deck.cards[reviewingCardIndex];
    console.log("card", currentCard);
  };

  nextCard = () => {
    console.log("going to next card");
    let { currentCardIndex } = this.state;
    const deck = JSON.parse(localStorage.getItem("deck"));
    let currentCard = deck.cards[currentCardIndex];
    console.log("card before", currentCard);

    currentCard.interval = 24 * 3600000;
    currentCard.dueDate = currentCard.interval + Date.now();
    currentCard.dueDateInWords = new Date(currentCard.dueDate).toString();
    currentCard.intervalInDays = currentCard.interval / 86400000;

    currentCard.phase = "reviewing";
    console.log("card after", currentCard);

    localStorage.setItem("deck", JSON.stringify(deck));

    currentCardIndex += 1;
    this.setState({ currentCardIndex });
  };

  reviewNextCard = () => {
    let { reviewingCardIndex } = this.state;
    reviewingCardIndex += 1;

    const deck = JSON.parse(localStorage.getItem("deck"));
    console.log("deck before", deck);

    const currentCard = deck.cards[reviewingCardIndex];
    console.log("card", currentCard);

    this.setState({ reviewingCardIndex });
  };

  setAgain = () => {
    let { reviewingCardIndex } = this.state;
    const deck = JSON.parse(localStorage.getItem("deck"));
    let currentCard = deck.cards[reviewingCardIndex];
    currentCard.grade = 1;
    console.log("card", deck.cards[reviewingCardIndex]);

    currentCard.phase = "learning";
    currentCard.interval = 1;
    currentCard.ease = currentCard.ease * 0.85;
    currentCard.grade = 1;
    currentCard.lapses += 1;

    // const dueDate = Date.now() + 10*60000;
    currentCard.dueDate = 0;

    localStorage.setItem("deck", JSON.stringify(deck));
  };

  setHard = () => {
    let { reviewingCardIndex } = this.state;
    const deck = JSON.parse(localStorage.getItem("deck"));
    let currentCard = deck.cards[reviewingCardIndex];
    currentCard.grade = 2;
    console.log("card", deck.cards[reviewingCardIndex]);

    currentCard.ease = currentCard.ease * 0.8;
    let newInterval = currentCard.interval * currentCard.ease;
    if (newInterval > 1.2) {
      currentCard.interval = newInterval;
    } else {
      currentCard.interval = currentCard.interval * 1.2;
    }

    currentCard.intervalInDays = currentCard.interval / 86400000;

    const dueDate = Date.now() + currentCard.interval;
    currentCard.dueDate = dueDate;
    currentCard.dueDateInWords = new Date(currentCard.dueDate).toString();

    localStorage.setItem("deck", JSON.stringify(deck));
  };

  setGood = () => {
    let { reviewingCardIndex } = this.state;
    const deck = JSON.parse(localStorage.getItem("deck"));
    let currentCard = deck.cards[reviewingCardIndex];
    currentCard.grade = 3;
    console.log("card", deck.cards[reviewingCardIndex]);

    currentCard.interval = currentCard.interval * currentCard.ease;
    currentCard.intervalInDays = currentCard.interval / 86400000;

    const dueDate = Date.now() + currentCard.interval;
    currentCard.dueDate = dueDate;
    currentCard.dueDateInWords = new Date(currentCard.dueDate).toString();

    localStorage.setItem("deck", JSON.stringify(deck));
  };

  setEasy = () => {
    let { reviewingCardIndex } = this.state;
    const deck = JSON.parse(localStorage.getItem("deck"));
    let currentCard = deck.cards[reviewingCardIndex];
    currentCard.grade = 4;
    console.log("card", deck.cards[reviewingCardIndex]);

    currentCard.ease = currentCard.ease * 1.15;
    currentCard.interval = currentCard.interval * currentCard.ease;
    currentCard.intervalInDays = currentCard.interval / 86400000;

    const dueDate = Date.now() + currentCard.interval;
    currentCard.dueDate = dueDate;
    currentCard.dueDateInWords = new Date(currentCard.dueDate).toString();

    localStorage.setItem("deck", JSON.stringify(deck));
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Spaced Repetition - Acabest</title>
        </Helmet>

        <button onClick={this.startLearning}>Learn</button>
        <br />
        <br />
        <button onClick={this.nextCard}>Next Card</button>
        <br />
        <br />
        <Divider />
        <br />
        <br />

        <button onClick={this.startReviewing}>Review</button>
        <br />
        <br />
        <button onClick={this.setAgain}>Again</button>
        <button onClick={this.setHard}>Hard</button>
        <button onClick={this.setGood}>Good</button>
        <button onClick={this.setEasy}>Easy</button>
        <br />
        <br />
        <button onClick={this.reviewNextCard}>Next Card</button>
      </React.Fragment>
    );
  }
}

export default Repetition;
